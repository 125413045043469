import React, { Fragment, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
const RedirectOptimizedPlan = props => {
  const [show, setShow] = useState(props.isOpen);
    const handleClose = () => {
        setShow(false);
      }
      const redirect = () => {
        setShow(false);
        window.open(props.optimizationLink, "_self");
      }
  return (
    <>
      <Modal show={show} onHide={handleClose} >
        <Modal.Header closeButton>
          <Modal.Title>Plan Optimization</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>Your Plan is ready. Please click Ok to view</p>
        {/* <p>Please click <a href={props.optimizationLink} target="_self"><span className="text-primary">here</span></a> to redirect budget optimization plan</p> */}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={redirect}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default RedirectOptimizedPlan