import React, { useState, useEffect } from "react";
import Select from "react-select";
import _ from 'lodash';

const TagRenderComponent = props => {
  const tag = {
    label: props.value ? "Active" : "Inactive",
    value: props.value,
  };
  const [selectedTag, setSelectedTag] = useState(tag);
    useEffect(() => {
    if (props.value != selectedTag.value) {
        const label = props.value? "Active": "Inactive";
        setSelectedTag({label: label, value: props.value})
        }
    }, [props.value])

    const handleSelectChange = event => {
        const feild = props.colDef.field;
        const clonedPropsData = _.cloneDeep(props.data);
        clonedPropsData[feild] = event
        props.changeTagStatus(clonedPropsData);
        setSelectedTag(event);

    }
    const tagOptions = [
        { label: "Active", value: true},
        { label: "Inactive", value: false}
    ]
    return (
        <div className="tag-renderer">
            
            <Select
               className="select-option"
               placeholderButtonLabel="select tag"
               options={tagOptions}
               value={selectedTag}
               isDisabled={props.isTagDisable}
               menuPortalTarget={document.querySelector("body")}
               onChange={e => {handleSelectChange(e)}}
              />
        </div>
    );
};
export default TagRenderComponent;