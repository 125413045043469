
import { GET_CHART_DATA, GET_CHART_DATA_FILTERS, DATA_LOADING, CLEAR_CHART_DATA } from '../actions/types';

const initialState = {
    chartData: {},
    chartDataFilters: {}, 
    loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {

        case DATA_LOADING:
            return {
                ...state, 
                loading: true
            };

        case GET_CHART_DATA:
            return { 
                ...state,
                chartData: action.payload,
                loading:false
            };
        case GET_CHART_DATA_FILTERS:
        state = {
            chartData: {},
    chartDataFilters: {},
    loading: false
        }
            return {
                ...state,
                chartDataFilters: action.payload,
                // loading:false
            };

        case CLEAR_CHART_DATA:
            return {
                ...state,
                chartData: {},

            };

        default:
            return state;
    }
}

