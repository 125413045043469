export const ranges = [
  { divider: 1e9, suffix: 'B' },
  { divider: 1e6, suffix: 'M' },
  { divider: 1e3, suffix: 'k' }
];
export const budgetDataPayload = {
  Years: '2019',
  category: 'FROZEN ENTREES'
};

export const vendorOptions = [
  { value: 1, label: 'POPCHIPS INC' },
  { value: 2, label: 'CLABBER GIRL CORP' },
  { value: 3, label: 'DOMINO SUGAR CORP' },
  { value: 4, label: 'EAGLE FAMILY FOODS GROUP LLC' },
  { value: 5, label: 'QUAKER OATS CO' },
  { value: 6, label: 'MAYA KAIMAL FINE INDIAN FOODS LLC' }
];
