import {
    SET_CATEGORY_ROLES,
    DATA_LOADING,
    SET_USER_DATA,
    GET_ALL_USERS,
    SET_USER_ROLE_INFO,
    SESSION_EXPIRED,
    SET_VIDEOS_LIST
  } from './types';
  import { getUserCategoryRoles, getAllUsers, saveCategoryAdmin ,loggeduserInfo, downloadUserDetails ,getVideos ,updateSession} from '../apis/userManagement.api';
  import { toast } from "react-toastify";
  import environmentModule from "../environments/environment";

  export const UserRoles = reqParams => async dispatch => {
    dispatch({ type: DATA_LOADING, payload: true });
  
    try {
      const result = await getUserCategoryRoles(reqParams);
      dispatch({ type: SET_CATEGORY_ROLES, payload: result });
      dispatch({ type: DATA_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: DATA_LOADING, payload: false });
    }
  };

  export const setUserDetails = payload => dispatch => {
    dispatch({type: SET_USER_DATA, payload});
  }

  export const actionGetAllUsers = () => async dispatch => {
    dispatch({ type: DATA_LOADING, payload: true });
  
    try {
      const result = await getAllUsers();
      dispatch({ type: GET_ALL_USERS, payload: result });
      dispatch({ type: DATA_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: DATA_LOADING, payload: false });
    }
  };

  export const actionSaveCategoryAdmin = reqObj => async dispatch => {
    dispatch({ type: DATA_LOADING, payload: true });
  
    try {
      const result = await saveCategoryAdmin(reqObj);
      dispatch({ type: DATA_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: DATA_LOADING, payload: false });
    }
  };

  export const userInfo = reqObj => async dispatch => {
    dispatch({ type: DATA_LOADING, payload: true });
  
    try {
      const result = await loggeduserInfo();
      if(Object.keys(result).length > 0){
        localStorage.setItem("isAuthenticated",true)
        dispatch({ type: SET_USER_ROLE_INFO, payload: result  });
        // localStorage.setItem('isAuthenticated',true)
      }
      dispatch({ type: DATA_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: DATA_LOADING, payload: false });
    }
  };

  export const actionDownloadUserDetails = () => async dispatch => {
    try {
      dispatch({ type: DATA_LOADING, payload: true });
      const result = await downloadUserDetails();
      window.open(result.url, "_self");
      dispatch({ type: DATA_LOADING, payload: false });
    } catch (err) {
      dispatch({ type: DATA_LOADING, payload: false });
    }
  };

  export const SessionExpiredRedirection = () => async dispatch => {
      dispatch({ type: SESSION_EXPIRED, payload: true });
     
  };
  export const sessionExtending = () => async dispatch => {
      dispatch({ type: SESSION_EXPIRED, payload: false });
     
  };
  
  export const getVidosList = reqParams => async dispatch => {
    dispatch({ type: DATA_LOADING, payload: true });
  
    try {
      const result = await getVideos(reqParams);
      let data =[];
      if(result){

        data = result.map(obj=>{
          return {
            title: obj.split("/training-videos/")[1].split(".")[0].replace("%20"," "),
            url: obj
          }
        })
      }
      dispatch({ type: SET_VIDEOS_LIST, payload: data });
      dispatch({ type: DATA_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: DATA_LOADING, payload: false });
    }
  };


  export const updateUserSession = reqObj => async dispatch => {
      const result = await updateSession();
  }