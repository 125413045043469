import { AgGridReact } from "ag-grid-react";
import React, { Component } from "react";
import ReactDOM from "react-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import PageLoader from "../../utils/Loader";
import Alert from "../../utils/AlertMessage/alertDanger";
import BarChart from "../../components/support/Charts/Barchart";
import axios from "axios";
import environment from "../../environments/environment";
import OfferViewCharts from "../../components/AnalyzeEvent/OfferViewCharts";
import {Tabs ,Tab,Nav, Row,Col} from 'react-bootstrap'
import {
  getAnalyzeEventDetails,
  getOffersList,
  closeLoader,
  openLoader,
  resetCampaingsList
} from "./analyzeEventAction";
import { difference } from "lodash";
import _ from "underscore";
import DynamicFilters from "../DynamicFilters";
import classNames from "classnames";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import {
  convertMillionsFormatter,
  convertMillionsFormatterWithoutPrecision,
  precisionFormatter
} from "../../utils/AgGridFormatters";
import MultiSelectCheckboxes from "../../components/MultiSelectCheckboxes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { AnalyzeBooksGA } from '../../common/GAUtils';
import './index.css';
let minRowHeight = 25;
let currentRowHeight = minRowHeight;

const SCREEN_NAME = 'analyzeBooks';
let chartOptions1 = {
  plugins: {
    datalabels: {
      display: false,
      color: "white"
    }
  },
  // events: [],
  hover: {
    mode: 'nearest',
    "animationDuration": 0
    // intersect: false
 },
  tooltips: {
    yAlign: "top",
    mode: 'index',
    intersect: false,
    callbacks: {
      title: function(tooltipItem, data) {
        return data.completeLabels[tooltipItem[0].index];
      },
      label: function(tooltipItem, data) {
        var label = data.datasets[tooltipItem.datasetIndex].label || "";
        if (label) {
          label += ": ";
        }
        var no = tooltipItem.yLabel;
        // no =
        //   Math.abs(Number(tooltipItem.yLabel)) >= 1.0e9
        //     ? (Math.abs(Number(tooltipItem.yLabel)) / 1.0e3).toFixed(2) + "K"
        //     : // Six Zeroes for Millions
        //     Math.abs(Number(tooltipItem.yLabel)) >= 1.0e6
        //     ? (Math.abs(Number(tooltipItem.yLabel)) / 1.0e3).toFixed(2) + "K"
        //     : // Three Zeroes for Thousands
        //     Math.abs(Number(tooltipItem.yLabel)) >= 1.0e3
        //     ? (Math.abs(Number(tooltipItem.yLabel)) / 1.0e3).toFixed(2) + "K"
        //     : Math.abs(Number(tooltipItem.yLabel)).toFixed(2);
        no = Math.abs(Number(no)) >= 1000 ? (new Intl.NumberFormat('en-us', {currency: 'USD'}).format((Math.sign(no)*(Math.abs(Number(no)) / 1.0e3).toFixed(2) || 0)) + ' K' ) : new Intl.NumberFormat('en-us', {currency: 'USD'}).format((Math.sign(no)*(Math.abs(Number(no))).toFixed(2) || 0));
        if (
          ["# Of Offers"].indexOf(
            data.datasets[tooltipItem.datasetIndex].label
          ) !== -1
        ) {
          no = parseInt(no);
        }
        if (
          ["ROI (Sales)"].indexOf(
            data.datasets[tooltipItem.datasetIndex].label
          ) !== -1
        ) {
          no = precisionFormatter({value: parseFloat(no)});
        }
        if (
          ["Net Units", "Incremental Units", "# Of Offers", "ROI (Sales)"].indexOf(
            data.datasets[tooltipItem.datasetIndex].label
          ) == -1
        ) {
          label += "$" + no;
        } else {
          label = label + no;
        }

        return label;
      }
    }
  },
  scales: {
    xAxes: [
      {
        barPercentage: 0.4,
        gridLines: {
          lineWidth: 0
        },
        ticks: {
          fontSize: 13,
          opacity: 0.7
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          lineWidth: 0
        },
        ticks: {
          callback: function(value, index, values) {
            var no =
              Math.abs(parseFloat(value)) >= 1.0e9
                ? (Math.abs(parseFloat(value)) / 1.0e3).toFixed(1) + "K"
                : // Six Zeroes for Millions
                Math.abs(parseFloat(value)) >= 1.0e6
                ? (Math.abs(parseFloat(value)) / 1.0e3).toFixed(1) + "K"
                : // Three Zeroes for Thousands
                Math.abs(parseFloat(value)) >= 1.0e3
                ? (Math.abs(parseFloat(value)) / 1.0e3).toFixed(1) + "K"
                : Math.abs(parseFloat(value)).toFixed(1);

            let convertPrecisionValue = no.split(".");

            if (convertPrecisionValue[1][0] === "0") {
              no =
                convertPrecisionValue[0] +
                (convertPrecisionValue[1][1]
                  ? convertPrecisionValue[1][1]
                  : "");
            }
            if (value < 0) {
              no = "-" + no;
            }
            return no;
          },
          fontSize: 11,
          maxTicksLimit: 5,
          fontColor: "#9B9B9B",
          beginAtZero: true
        }
      }
    ]
  },
  legend: {
    display: false,
    position: "bottom",
    labels: {
      boxWidth: 12
    },
    fontFamily: "poppins",
    fontSize: "12px"
  },
  /*
  animation: {
    onComplete: function(animation) {
      // progress.value = animation.animationObject.currentStep / animation.animationObject.numSteps;
      var ctx = document.getElementById("display_numbers");

      var chartInstance = animation.chart,
        ctx = chartInstance.ctx;

      let len = Math.floor(animation.chart.data.datasets[0].data.length / 15);

      //   ctx.font = `${8-len}px Arial`;
      if (animation.chart.data.datasets[0].data.length < 12) {
        ctx.font = "10px Arial";
        ctx.fillStyle = "rgba(117, 109, 109, 0.52)";

      ctx.textAlign = "center";
      ctx.textBaseline = "bottom";
      } else {
        // ctx.font = `${8 - len}px Arial`;
        ctx.font = "0px Arial";
        // ctx.fillStyle = "rgba(255, 255, 255, 0)";
      }
      animation.chart.data.datasets.forEach(function(dataset, i) {
        var meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach(function(bar, index) {
          var data = dataset.data[index];
          let formattedValue =
            dataset.label === "# Of Offers"
              ? data
              : convertMillionsFormatter({ value: data });
          // ctx.fillText(formattedValue, bar._model.x, bar._model.y - 5);
          
            if (data > 0) {
              console.log("value above bar")
              ctx.fillText(formattedValue, bar._model.x, bar._model.y - 3);
            } else {
              console.log("tooltips")
              ctx.fillText(formattedValue, bar._model.x, bar._model.y + 15);
            }
        });
      });
    }
  },
  */
  responsive: true,
  maintainAspectRatio: true
};
/******************Chart Option 2 ************/
let chartOptions2 = {
  plugins: {
    datalabels: {
      display: false,
      color: "white"
    }
  },
  // events: [],
  hover: {
    mode: "nearest",
    "animationDuration": 0
  },
  tooltips: {
    yAlign: "top",
    mode: 'index',
    intersect: false,
    callbacks: {
      title: function(tooltipItem, data) {
        return data.completeLabels[tooltipItem[0].index];
      },
      label: function(tooltipItem, data) {
        var label = data.datasets[tooltipItem.datasetIndex].label || "";
        if (label) {
          label += ": ";
        }
        var no = tooltipItem.yLabel;
        if (parseFloat(tooltipItem.yLabel) / 1000000 >= 1) {
          //add m
          no = parseFloat(tooltipItem.yLabel / 1000).toFixed(0) + "K";
        } else if (parseFloat(tooltipItem.yLabel) / 1000 >= 1) {
          //add k
          no = (parseFloat(tooltipItem.yLabel) / 1000).toFixed(0) + "K";
        }
        label += no + "%";
        return label;
      }
    }
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          lineWidth: 0
        },
        ticks: {
          fontSize: 13,
          opacity: 0.7
        },

        barPercentage: 0.4
      }
    ],
    yAxes: [
      {
        gridLines: {
          lineWidth: 0
        },
        ticks: {
          callback: function(value, index, values) {
            var no = value;
            if (parseFloat(value) / 1000000 >= 1) {
              //add m
              no = parseFloat(value / 1000).toFixed(2) + "K";
            } else if (parseFloat(value) / 1000 >= 1) {
              //add k
              no = (parseFloat(value) / 1000).toFixed(2) + "K";
            }
            // return value/1000000+'M';
            return no;
          },
          fontSize: 11,
          maxTicksLimit: 5,
          fontColor: "#9B9B9B",
          beginAtZero: true,
          // min: -100,
          // max: 100
        }
      }
    ]
  },
  legend: {
    display: false,
    position: "top",
    labels: {
      boxWidth: 12
    },
    fontFamily: "poppins",
    fontSize: "12px"
  },
  /*
  animation: {
    onComplete: function(animation) {
      // progress.value = animation.animationObject.currentStep / animation.animationObject.numSteps;
      var ctx = document.getElementById("display_numbers");

      var chartInstance = animation.chart,
        ctx = chartInstance.ctx;

      let len = Math.floor(animation.chart.data.datasets[0].data.length / 15);

      if (animation.chart.data.datasets[0].data.length < 12) {
        ctx.font = "10px Arial";
        ctx.fillStyle = "rgba(117, 109, 109, 0.52)";
        ctx.textAlign = "center";
        ctx.textBaseline = "bottom";
      } else {
        ctx.font = "0px Arial";
        // ctx.fillStyle = "rgba(255, 255, 255, 0)";
        
      }
      animation.chart.data.datasets.forEach(function(dataset, i) {
        var meta = chartInstance.controller.getDatasetMeta(i);
        meta.data.forEach(function(bar, index) {
          var data = dataset.data[index] + "%";
          console.log(bar, "bar");
          console.log(bar._chart.options.events = true, "tooltips");
          console.log(index, "indexxxxxxxxx");
          
            if (data > "0%") {
              console.log("value above barc2")
              ctx.fillText(data, bar._model.x, bar._model.y - 3);
            } else {
              console.log("tooltipsc2")
              ctx.fillText(data, bar._model.x, bar._model.y + 15);
            }
        
          // var data = dataset.data[index] + "%";
          console.log(data, "graphadat");
          console.log(data > 0, "datatatat");
          if (data > "0%") {
            // ctx.fillText(data, bar._model.x, bar._model.y - 3);
          } else {
            // ctx.fillText(data, bar._model.x, bar._model.y + 15);
          }
        });
      });
    }
  },
  */
  responsive: true,
  maintainAspectRatio: true
};


class AnalyzeCampaign extends React.PureComponent {
  table;
  pdfExportComponent;
  constructor(props) {
    super(props);
    this.state = {
      selectedYear: [],
      campaignsList: [],
      offersList: [],
      hierarchies: [],
      actualMargin: [],
      actualSales: [],
      actualUnits: [],
      actualDataLabels: [],
      incrementalDataLabels: [],
      incrementalMargin: [],
      incrementalSales: [],
      incrementalUnits: [],
      spend: [],
      spendLabels: [],
      roiSales: [],
      roiSalesLabels: [],
      offer: [],
      totalCouponValue: [],
      offerLabels: [],
      liftMargin: [],
      liftSales: [],
      liftUnits: [],
      showAlertDanger: false,
      errorMessage: "",
      stateDate: null,
      endDate: null,
      merchcat: [],
      vendor: [],
      month: [],
      ah6: [],
      selectedView: "Charts",
      selectedBookView: "Books",
      columnDefs: [],
      campaign: [],
      rowData: [],
      offers: [],
      offerColDef: [],
      offerRowData: [],
      offerGraphData:{
        actualMargin:[],
        actualSales:[],
        actualUnits:[],
        actualTotalMargin: [],
        actualTotalSales: [],
        actualTotalUnits: [],
        actualDataLabels: [],
        incrementalDataLabels: [],
        incrementalMargin: [],
        incrementalSales: [],
        incrementalUnits: [],
        spend: [],
        spendLabels: [],
        roiSales: [],
      liftMargin:[],
      liftSales:[],
      liftUnits:[],
      totalCouponValue:[],
      message:''
      }
    };
  }
  componentDidMount() {
    let abc = this.requestedParams();
    // this.props.getAnalyzeEventDetails({});
    // this.props.getOffersList({})
    this.props.resetCampaingsList();
  }
  componentDidUpdate = (oldProps, oldState) => {
    if (!_.isEqual(oldProps.campaignsList, this.props.campaignsList)) {
      if (this.state.selectedYear.length > 0 || this.state.merchcat.length > 0 ) {
        this.setState({
            campaign: this.props.campaignsList.filter(object => {
                object.value = `${object.name} - ${object.startDate} to ${object.endDate}`;
                object.label = `${object.name} - ${object.startDate} to ${object.endDate}`;
                return object;
                })
        }, () => {
            this.getCompaignsListDetails();
        });
      }
    }
    if(this.state.offers !== oldState.offers){
      this.callOfferApi();
    }
    if(this.props.offersListView !== oldProps.offersListView) {
      this.setOfferTable(this.props.offersListView);
    }
    if((this.state.selectedView !== oldState.selectedView || this.state.selectedBookView !== oldState.selectedBookView) && this.state.selectedView === 'Table'){
      this.props.openLoader();
      let timer =10;
      if(this.state.selectedBookView === 'Books'){
          timer = Math.ceil(this.state.columnDefs.length/700) *10; 
          
      }else{
        timer = Math.ceil(this.state.offerColDef.length/700) *10;
      }
      setTimeout(() => {
        this.gridResponsive();
        this.props.closeLoader();
      }, timer);
    }
    if((this.state.selectedView !== oldState.selectedView || this.state.selectedBookView !== oldState.selectedBookView) && this.state.selectedView === 'Charts'){
      this.props.openLoader();
      let timer =10;
      if(this.state.selectedBookView === 'Books'){
        timer = Math.ceil(this.state.campaign.length/700) *10; 
        
    }else{
      timer = Math.ceil(this.props.offersListView.length/700) *10;
    }
      setTimeout(() => {
        this.gridResponsive();
        this.props.closeLoader();
      }, timer);
    }
    
  };

  

  setOfferTable = list => {
    const tempData = list;
    let tableData = {};
    const labels = this.props.offersListView.map(f => f.promoId);
    let offerData = {
      actualMargin: this.props.offersListView.map(f => parseInt(f.baselineMargin + f.directIncrementalMargin)),
      actualSales: this.props.offersListView.map(f => parseInt(f.baselineSales + f.directIncrementalSales)),
      actualUnits: this.props.offersListView.map(f => parseInt(f.baselineUnits + f.directIncrementalUnits)),
      actualDataLabels: labels,
      incrementalDataLabels: labels,
      spendLabels: labels,
      roiSalesLabels: labels,
      offerLabels: labels,
      liftMargin: this.props.offersListView.map(f =>
        parseInt((f.actualTotalMargin / f.baselineMargin) * 100 - 100)
      ),
      liftSales: this.props.offersListView.map(f =>
        parseInt((f.actualTotalSales / f.baselineSales) * 100 - 100)
      ),
      liftUnits: this.props.offersListView.map(f =>
        parseInt((f.actualTotalUnits / f.baselineUnits) * 100 - 100)
      ),
      incrementalMargin: this.props.offersListView.map(f =>
        parseInt(f.directIncrementalMargin)
      ),
      incrementalSales: this.props.offersListView.map(f =>
        parseInt(f.directIncrementalSales)
      ),
      incrementalUnits: this.props.offersListView.map(f =>
        parseInt(f.directIncrementalUnits)
      ),
      spend: this.props.offersListView.map(f => parseInt(f.spend)),
      roiSales: this.props.offersListView.map(f => parseFloat(f.roiSales)),
      offer: this.props.offersListView.map(f => parseInt(f.offer)),
      totalCouponValue: this.props.offersListView.map(f =>
        parseFloat(f.actualPromoValue)
      )
    }
    let columnDefs = [{ headerName: "Name", field: "Name" }].concat(
      tempData.map((f,index) => {
        tableData[`${f.promoId}`] = {
          Name: f.promoId,
          actualTotalMargin: convertMillionsFormatter({
            value: parseInt(f.baselineMargin + f.directIncrementalMargin)
          }),
          actualTotalSales: convertMillionsFormatter({
            value: parseInt(f.baselineSales + f.directIncrementalSales)
          }),
          actualTotalUnits: convertMillionsFormatter({
            value: parseInt(f.baselineUnits + f.directIncrementalUnits)
          }),
          actualMarginLift: (f.actualTotalMargin === null || f.baselineMargin === null )? 0 : parseInt(
            (f.actualTotalMargin / f.baselineMargin) * 100 - 100
          ),
          actualSalesLift: (f.actualTotalSales === null || f.baselineSales === null )? 0 :parseInt(
            (f.actualTotalSales / f.baselineSales) * 100 - 100
          ),
          actualUnitsLift: (f.actualTotalUnits === null || f.baselineUnits === null )? 0 :parseInt(
            (f.actualTotalUnits / f.baselineUnits) * 100 - 100
          ),
          directIncrementalMargin: convertMillionsFormatter({
            value: parseInt(f.directIncrementalMargin)
          }),
          directIncrementalSales: convertMillionsFormatter({
            value: parseInt(f.directIncrementalSales)
          }),
          directIncrementalUnits: convertMillionsFormatter({
            value: parseInt(f.directIncrementalUnits)
          }),
          spend: convertMillionsFormatter({
            value: parseInt(f.spend)
          }),
          roiSales: precisionFormatter({
            value: f.roiSales
          }),
          actualCouponValue: convertMillionsFormatter({
            value: parseFloat(f.actualPromoValue)
          })
        };
        // offerData = {
        //   actualMargin: parseInt(f.baselineMargin + f.directIncrementalMargin),
        //   actualSales: parseInt(f.baselineSales + f.directIncrementalSales),
        //   actualUnits: parseInt(f.baselineUnits + f.directIncrementalUnits),
        //   actualDataLabels: f.promoId,
        //   incrementalDataLabels: f.promoId,
        //   spendLabels: f.promoId,
        //   roiSalesLabels: f.promoId,
        //   offerLabels: f.promoId,
        //   liftMargin: 
        //     parseInt((f.actualTotalMargin / f.baselineMargin) * 100 - 100),
        //   liftSales: parseInt((f.actualTotalSales / f.baselineSales) * 100 - 100),
        //   liftUnits: 
        //     parseInt((f.actualTotalUnits / f.baselineUnits) * 100 - 100)
        //   ,
        //   incrementalMargin: 
        //     parseInt(f.directIncrementalMargin)
        //   ,
        //   incrementalSales: 
        //     parseInt(f.directIncrementalSales)
        //   ,
        //   incrementalUnits: 
        //     parseInt(f.directIncrementalUnits)
        //   ,
        //   spend:  parseInt(f.spend),
        //   roiSales:  parseFloat(f.roiSales),
        //   offer:  parseInt(f.offer),
        //   totalCouponValue: 
        //     parseFloat(f.promoValue)
          
        // }
        return { headerName: f.promoId, field: f.promoId };
      })
    );
    let analyticsData = [];
          [
            "actualTotalMargin",
            "actualTotalSales",
            "actualTotalUnits",
            "actualMarginLift",
            "actualSalesLift",
            "actualUnitsLift",
            "directIncrementalMargin",
            "directIncrementalSales",
            "directIncrementalUnits",
            "spend",
            "roiSales",
            "actualCouponValue"
          ].forEach(analyticsObj => {
            let dupObj = {};
            columnDefs.forEach((obj,index) => {
              if (obj.headerName === "Name") {
                let namesList = {
                  actualTotalMargin: "Net Margin (in $)",
                  actualTotalSales: "Net Sales (in $)",
                  actualTotalUnits: "Net Units",
                  actualMarginLift: "Margin Lift %",
                  actualSalesLift: "Sales Lift %",
                  actualUnitsLift: "Unit Lift %",
                  directIncrementalMargin: "Incremental Margin (in $)",
                  directIncrementalSales: "Incremental Sales(in $)",
                  directIncrementalUnits: "Incremental Units",
                  spend: "Spend(in $)",
                  roiSales: "ROI (Sales)",
                  actualCouponValue: "Total $ Coupon Value"
                };
                dupObj[`${obj.headerName}`] = namesList[`${analyticsObj}`];
                return true;
              }
              dupObj[`${obj.headerName}`] =
                tableData[`${obj.headerName}`][`${analyticsObj}`];
            });
            analyticsData.push(dupObj);
          });

          this.setState({
            offerColDef: columnDefs,
            offerRowData: analyticsData,
            offerGraphData:offerData
          },()=>{
            this.gridResponsive();
          })
  }
  onRefresh = () => {
    this.submitGA("Click","Reset Button");
    this.reloadData();
  };
  reloadData = () => {
    this.setState({
      loader: false,
      vendor: [],
      merchcat: [],
      ah6: [],
      selectedYear: [],
      selectedSKU: null,
      selectedSubCategory: null,
      campaign: [],
      actualMargin: [],
      actualSales: [],
      actualUnits: [],
      actualDataLabels: [],
      incrementalDataLabels: [],
      liftMargin: [],
      liftSales: [],
      liftUnits: [],
      incrementalMargin: [],
      incrementalSales: [],
      incrementalUnits: [],
      spend: [],
      roiSales: [],
      spendLabels: [],
      offer: [],
      totalCouponValue: [],
      offerLabels: [],
      rowData: [],
      columnDefs: [],
      offersList:[],
      offers:[],
      offerRowData:[],
      offerColDef:[],
      selectedView: "Charts",
      selectedBookView: "Books" ,
      offerGraphData:{
        actualMargin:[],
        actualSales:[],
        actualUnits:[],
        actualTotalMargin: [],
        actualTotalSales: [],
        actualTotalUnits: [],
        actualDataLabels: [],
        incrementalDataLabels: [],
        incrementalMargin: [],
        incrementalSales: [],
        incrementalUnits: [],
        spend: [],
        spendLabels: [],
        roiSales: [],
        roiSalesLabels: [],
        offer: [],
        actualCouponValue: [],
        offerLabels: [],
        simulatedMarginLift: [],
        simulatedMarginLift: [],
        simulatedUnitLift: [],
        offers: [],
      liftMargin:[],
      liftSales:[],
      liftUnits:[],
      totalCouponValue:[],
      message:''
      }
    });
    this.props.resetCampaingsList();
    // this.props.getAnalyzeEventDetails({});
    // this.props.getOffersList({})
  };

  requestedParams = () => {
    var merchcat =
      this.state.merchcat.length > 0
        ? this.state.merchcat.map(object => object.value)
        : null;
    var vendor =
      this.state.vendor.length > 0
        ? this.state.vendor.map(object => object.value)
        : null;

    var ah6 =
      this.state.ah6.length > 0
        ? this.state.ah6.map(object => object.value)
        : null;
    // var selectedYear =
    //   this.state.selectedYear.length > 0
    //     ? this.state.selectedYear.map(object => {
    //         return { startDate: object.startDate, endDate: object.endDate };
    //       })
    //     : null;
    let yearQuarters = this.state.selectedYear.map(yr => {
      return {fyear: yr.year, quarter: yr.quater}
    })
    let calendarObj = {
      merchcat,
      vendor,
      ah6,
      yearQuarters
      // dates: selectedYear
    };
    let passedObj = _.pick(calendarObj, _.identity);
    return passedObj;
  };

  filterData = async() => {
    this.submitGA("Click","Filter Button");
    this.resetData()
    let params = {
      ...this.requestedParams(),
      screenName: SCREEN_NAME
    };
    if(this.state.selectedYear.length > 0 || this.state.merchcat.length > 0 ){    
    await this.props.getAnalyzeEventDetails(params).then(res => {
      // if(this.state.selectedYear && this.state.selectedYear.length > 0){
      //     this.setState({
      //         campaign: this.props.campaignsList.filter(object => {
      //             object.value = `${object.name} - ${object.startDate} to ${object.endDate}`;
      //             object.label = `${object.name} - ${object.startDate} to ${object.endDate}`;
      //             return object;
      //           })
      //     }, () => {
      //             this.getCompaignsListDetails();
      //     });
      // }
    });
    (this.props.campaignsList>0 ? this.setState({message:''}) : this.setState({message:'No Data Available'}));
  }
    // this.props.getOffersList(params)
  };

  handleSelectChange = (value, type) => {
    this.setState(
      {
        [type.name]: value
      },
      () => {
        if (value.length) {
          this.getCompaignsListDetails();
        } else {
          this.setState({
            actualMargin: [],
            actualSales: [],
            actualUnits: [],
            actualDataLabels: [],
            liftMargin: [],
            liftSales: [],
            liftUnits: [],
            incrementalMargin: [],
            incrementalSales: [],
            incrementalUnits: [],
            spend: [],
            spendLabels: [],
            offer: [],
            offerLabels: [],
            totalCouponValue: []
          });
        }
      }
    );
  };
  spanLables = [
    <div align="center" className="" style={{ marginBottom: "10px" }}>
      {" "}
      <span className="analyse-color-description">Lift &gt; 10%</span>{" "}
      <span className="analyse-color-description"> Lift 0%-10% </span>{" "}
      <span className="analyse-color-description"> Lift &lt; 0 % </span>{" "}
    </div>
  ];
  setChart = (title, data, options, labels) => {
    return (
      <div class="col-md-4 shadow-class mt-2" style={{margin:"20px 0" ,border:"1px solid #e5e7e9"}}>
        <p className="font-bold font-size-13 text-center">{title}</p>{" "}
        {labels && this.spanLables} <BarChart data={data} options={options} />{" "}
      </div>

    );
  };

  setOfferChart = (title, data, options, labels) => {
    return (
      <div class="col-md-4 shadow-class mt-2" style={{margin:"20px 0" ,border:"1px solid #e5e7e9"}}>
        <p className="font-bold font-size-13 text-center">{title}</p>{" "}
        {labels && this.spanLables} <OfferViewCharts data={data} options={options} />{" "}
      </div>

    );
  };

  updateState = params => {
        let keysLength = Object.keys(params).length;
        if(params.hasOwnProperty("merchcat") && keysLength ===1){
            this.submitGA('Filter','Changed merchcat selection');
        }
        if(params.hasOwnProperty("ah6") && keysLength ===1){
            this.submitGA('Filter','Changed ah6 selection');
        }
        if(params.hasOwnProperty("vendor") && keysLength ===1 ){
            this.submitGA('Filter','changed vendor selection');
        } 
        if(params.hasOwnProperty("selectedYear") && keysLength ===1){
            this.submitGA('Filter','changed selected year selection');
        }
    this.setState(params);
  };
  updateBooks = params => {
    this.submitGA("Click","Select campaigns list");
    this.setState({
      ...params
    });
  };

  updateOffers = params => {
    this.submitGA("Click","Select Offers list");
    this.setState({
      ...params
    });
  };

  resetData = () => {
    this.setState({
      campaignsList: [],
      offersList: [],
      campaign: [],
      offers: [],
      actualMargin: [],
      actualSales: [],
      actualUnits: [],
      actualDataLabels: [],
      liftMargin: [],
      liftSales: [],
      liftUnits: [],
      incrementalMargin: [],
      incrementalSales: [],
      incrementalUnits: [],
      spend: [],
      roiSales: [],
      spendLabels: [],
      offer: [],
      offerLabels: [],
      totalCouponValue: [],
      rowData: []
    });
  }
  callApi = () => {
    if (this.state.campaign.length) {
      this.getCompaignsListDetails();
    } else {
      this.resetData()
    }
  };

  getOfferReqBody = () => {
    // const value = this.state.offers; //[0].value;
    const offerId = this.state.offers.map(key => {
      let id = key.value;
      return id.split("-")[0];
    });
    const reqObj = {
      promoId: offerId,
      campaignId: this.state.campaign.map(obj => obj.id),
      ...this.requestedParams(),
    };
    return reqObj;
  }

  callOfferApi = () => {
    if (this.state.offers.length) {
      this.props.getOffersList({
        ...this.getOfferReqBody(),
        // screenName: SCREEN_NAME 
      });
    }
  };

  exportPDFWithComponent = () => {
    this.submitGA("Click",`Download ${this.state.selectedBookView} view pdf file`)
    this.pdfExportComponent.save();
  };

  changedBookView =(viewBook)=> {
    // this.props.openLoader();
    let name = viewBook.target.name;
    this.setState({ selectedBookView: name },()=>{
      this.submitGA("Click",`toggle ${name} view`);
      if(name === 'Offers'){
        this.gridResponsive()
      }
    });
    // setTimeout(() => {
    //   this.props.closeLoader();
    // }, 2000);
  }
  changedView(view) {
    this.setState({ selectedView: view },()=>{
      this.submitGA("Click",`toggle ${view} view`);
      if(view === 'Table'){
        this.gridResponsive()
      }
    });
    
  }

  
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    if (this.state.campaign.length <= 10) {
      params.api.sizeColumnsToFit();

      window.onresize = () => {
        this.gridApi.sizeColumnsToFit();
      };
    } else {
      this.gridColumnApi.autoSizeAllColumns();

      window.onresize = () => {
        this.gridColumnApi.autoSizeAllColumns();
      };
    }

    // this.gridColumnApi.autoSizeAllColumns();
  };
  // onOfferGridReady = params => {
  //   this.gridApi = params.api;
  //   this.gridColumnApi = params.columnApi;
  //   if (this.state.offers.length <= 10) {
  //     params.api.sizeColumnsToFit();

  //     window.onresize = () => {
  //       this.offerGridApi.sizeColumnsToFit();
  //     };
  //   } else {
  //     this.offerGridColumnApi.autoSizeAllColumns();

  //     window.onresize = () => {
  //       this.offerGridColumnApi.autoSizeAllColumns();
  //     };
  //   }

  //   // this.gridColumnApi.autoSizeAllColumns();
  // };

  getCompaignsListDetails = () => {
    let params = this.requestedParams();
    params.campaignId = this.state.campaign.map(obj => obj.id);
    params.screenName = SCREEN_NAME;
    this.props.openLoader();
    // axios.get(environment.baseUrl + `/promo/campaignAnalytics?campaignId=${this.state.campaign.map(obj => obj.id)}`).then(res => {
    axios
      .post(environment.baseUrl + `/promo/campaignAnalytics`, params)
      .then(res => {
        this.props.closeLoader();
        let analysis = res.data.data;
        if (!analysis.length) {
          this.setState({
            showAlertDanger: true,
            errorMessage: "Data not found"
          });
          setTimeout(() => {
            this.setState({ showAlertDanger: false });
          }, 2000);
        } else {
          let tableData = {};
          let columnDefs = [{ headerName: "Name", field: "Name" }].concat(
            analysis.map(f => {
              tableData[`${f.name}`] = {
                Name: f.name,
                actualMargin: convertMillionsFormatter({
                  value: parseInt(f.actualMargin)
                }),
                actualSales: convertMillionsFormatter({
                  value: parseInt(f.actualSales)
                }),
                actualUnits: convertMillionsFormatter({
                  value: parseInt(f.actualUnits)
                }),
                liftMargin: parseInt(
                  (f.actualMargin / f.baselineMargin) * 100 - 100
                ),
                liftSales: parseInt(
                  (f.actualSales / f.baselineSales) * 100 - 100
                ),
                liftUnits: parseInt(
                  (f.actualUnits / f.baselineUnits) * 100 - 100
                ),
                incrementalMargin: convertMillionsFormatter({
                  value: parseInt(f.directIncrementalMargin)
                }),
                incrementalSales: convertMillionsFormatter({
                  value: parseInt(f.directIncrementalSales)
                }),
                incrementalUnits: convertMillionsFormatter({
                  value: parseInt(f.directIncrementalUnits)
                }),
                spend: convertMillionsFormatter({
                  value: parseInt(f.spend)
                }),
                roiSales: precisionFormatter({
                  value: f.roiSales
                }),
                // offer: convertMillionsFormatter({
                //   value: parseInt(f.offer)
                // }),
                offer: parseInt(f.offer),
                totalCouponValue: convertMillionsFormatter({
                  value: parseFloat(f.totalCouponValue)
                })
              };
              return { headerName: f.name, field: f.name };
            })
          );

          let offerListArray = analysis
            .map(book => {
              const offer = book.offerDetails.map(offer => {
                return { label: offer, value: offer };
              });
              return offer;
            })
            .reduce((acc, data) => {
              return acc.concat(data);
            }, []);
          this.setState({ offersList: offerListArray ,offers:  offerListArray});
          let analyticsData = [];
          [
            "actualMargin",
            "actualSales",
            "actualUnits",
            "liftMargin",
            "liftSales",
            "liftUnits",
            "incrementalMargin",
            "incrementalSales",
            "incrementalUnits",
            "spend",
            "roiSales",
            "offer",
            "totalCouponValue"
          ].forEach(analyticsObj => {
            let dupObj = {};
            columnDefs.forEach(obj => {
              if (obj.headerName === "Name") {
                let namesList = {
                  actualMargin: "Net Margin (in $)",
                  actualSales: "Net Sales (in $)",
                  actualUnits: "Net Units",
                  liftMargin: "Margin Lift %",
                  liftSales: "Sales Lift %",
                  liftUnits: "Unit Lift %",
                  incrementalMargin: "Incremental Margin (in $)",
                  incrementalSales: "Incremental Sales(in $)",
                  incrementalUnits: "Incremental Units",
                  spend: "Spend(in $)",
                  roiSales: "ROI (Sales)",
                  offer: "# Of Offers",
                  totalCouponValue: "Total $ Coupon Value"
                };
                dupObj[`${obj.headerName}`] = namesList[`${analyticsObj}`];
                return true;
              }
              dupObj[`${obj.headerName}`] =
                tableData[`${obj.headerName}`][`${analyticsObj}`];
            });
            analyticsData.push(dupObj);
          });

          this.setState(
            {
              columnDefs: columnDefs,
              actualMargin: analysis.map(f => parseInt(f.actualMargin)),
              actualSales: analysis.map(f => parseInt(f.actualSales)),
              actualUnits: analysis.map(f => parseInt(f.actualUnits)),
              actualDataLabels: analysis.map(f => f.name),
              incrementalDataLabels: analysis.map(f => f.name),
              spendLabels: analysis.map(f => f.name),
              roiSalesLabels: analysis.map(f => f.name),
              offerLabels: analysis.map(f => f.name),
              liftMargin: analysis.map(f =>
                parseInt((f.actualMargin / f.baselineMargin) * 100 - 100)
              ),
              liftSales: analysis.map(f =>
                parseInt((f.actualSales / f.baselineSales) * 100 - 100)
              ),
              liftUnits: analysis.map(f =>
                parseInt((f.actualUnits / f.baselineUnits) * 100 - 100)
              ),
              incrementalMargin: analysis.map(f =>
                parseInt(f.directIncrementalMargin)
              ),
              incrementalSales: analysis.map(f =>
                parseInt(f.directIncrementalSales)
              ),
              incrementalUnits: analysis.map(f =>
                parseInt(f.directIncrementalUnits)
              ),
              spend: analysis.map(f => parseInt(f.spend)),
              roiSales: analysis.map(f => f.roiSales),
              offer: analysis.map(f => parseInt(f.offer)),
              totalCouponValue: analysis.map(f =>
                parseFloat(f.totalCouponValue)
              ),
              rowData: analyticsData
            },
            () => {
              if(this.state.selectedBookView === 'Books'){
                if (this.gridApi && this.gridColumnApi) {
                  if (this.state.campaign.length <= 10) {
                    this.gridApi.sizeColumnsToFit();
                  } else {
                    this.gridColumnApi.autoSizeAllColumns();
                  }
                }
              }
            }
          );
        }
        if (analysis.length && analysis.length !== this.state.campaign.length) {
          let missing = difference(
            this.state.compaign.map(f => f.label),
            analysis.map(f => f.eventName)
          );
          this.setState({
            showAlertDanger: true,
            errorMessage: "Data not found for " + missing.join(", \n")
          });
          setTimeout(() => {
            this.setState({ showAlertDanger: false });
          }, missing.length * 2000);
        }
      })
      .catch(err => {
        // this.props.closeLoader();
      });
  };
  getDownloadColumns = () => {
    let columns = {
      "Net Margin (in $)": "actualMargin",
      "Net Sales (in $)": "actualSales",
      "Net Units": "actualUnits",
      "Margin Lift %": "liftMargin",
      "Sales Lift %": "liftSales",
      "Unit Lift %": "liftUnits",
      "Incremental Margin (in $)": "directIncrementalMargin",
      "Incremental Sales(in $)": "directIncrementalSales",
      "Incremental Units": "directIncrementalUnits",
      "Spend(in $)": "spend",
      "ROI (Sales)": "roiSales",
      "# Of Offers": "offer",
      "Total $ Coupon Value": "totalCouponValue"
    };
    return columns;
  }
  downloadOfferLevelExcel = () => {
    const reqBody = {
      ...this.getOfferReqBody(),
      columns: this.getDownloadColumns()
    };
    axios.post(environment.baseUrl + `/promo/downloadAnalyzeEventOffers`, reqBody).then(res => {
      window.open(res.data.data.url, "_self");
      this.props.closeLoader();
    });
  }
  downloadBookLevel = () => {
    const columns = this.getDownloadColumns();
    this.props.openLoader();
    const reqBody = {
        columns: JSON.stringify(columns),
        campaignId: this.state.campaign.map(obj => obj.id),
        ...this.requestedParams()
    }
    axios.post(environment.baseUrl + `/promo/donwloadCampaigns`, reqBody).then(res => {
        window.open(res.data.data.url, "_self");
        this.props.closeLoader();
    });
  }
  downloadExcel = () => {
    this.submitGA("Click",`Download ${this.state.selectedBookView} view excel file`)
    if(this.state.selectedBookView == "Books") {
      this.downloadBookLevel();
    } else {
      this.downloadOfferLevelExcel();
    }
  };
  gridResponsive =()=>{
    if(this.state.selectedBookView === 'Books'){
      if (this.gridApi && this.gridColumnApi) {
        if (this.state.columnDefs.length <= 10) {
          this.gridApi.sizeColumnsToFit();
        } else {
          this.gridColumnApi.autoSizeAllColumns();
        }
      }
    }else{
      if (this.gridApi && this.gridColumnApi) {
        if (this.state.offerColDef.length <= 10) {
          this.gridApi.sizeColumnsToFit();
        } else {
          this.gridColumnApi.autoSizeAllColumns();
        }
        
      }
      // if(this.state.selectedView === 'Table'){
      //   this.changedBookView("Offers")
      // }
    }
    this.setState({ loader: false})
  }
  submitGA(action,label){
    AnalyzeBooksGA({ action, label});
  }
  render() {
    // this.gridResponsive();
    let barData = {
      labels: this.state.actualDataLabels,
      completeLabels: this.state.actualDataLabels,
      datasets: [
        {
          label: "Net Margin",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data: this.state.actualMargin
        }
      ]
    };
    let barData1 = {
      labels: this.state.actualDataLabels,
      completeLabels: this.state.actualDataLabels,
      datasets: [
        {
          label: "Net Sales",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data: this.state.actualSales
        }
      ]
    };
    let barData2 = {
      labels: this.state.actualDataLabels,
      completeLabels: this.state.actualDataLabels,
      datasets: [
        {
          label: "Net Units",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data: this.state.actualUnits
        }
      ]
    };
    let spendBarData = {
      labels: this.state.spendLabels,
      completeLabels: this.state.spendLabels,
      datasets: [
        {
          label: "Spend",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data: this.state.spend
        }
      ]
    };
    let roiSalesData = {
      labels: this.state.roiSalesLabels,
      completeLabels: this.state.roiSalesLabels,
      datasets: [
        {
          label: "ROI (Sales)",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data: this.state.roiSales
        }
      ]
    };
    let offerBarData = {
      labels: this.state.offerLabels,
      completeLabels: this.state.offerLabels,
      datasets: [
        {
          label: "# Of Offers",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data: this.state.offer
        }
      ]
    };
    let couponValueBarData = {
      labels: this.state.offerLabels,
      completeLabels: this.state.offerLabels,
      datasets: [
        {
          label: "Total $ Coupon Value",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data: this.state.totalCouponValue
        }
      ]
    };
    let incrementalBarData = {
      labels: this.state.incrementalDataLabels,
      completeLabels: this.state.incrementalDataLabels,
      datasets: [
        {
          label: "Incremental Margin",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data: this.state.incrementalMargin
        }
      ]
    };
    let incrementalBarData1 = {
      labels: this.state.incrementalDataLabels,
      completeLabels: this.state.incrementalDataLabels,
      datasets: [
        {
          label: "Incremental Sales",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data: this.state.incrementalSales
        }
      ]
    };
    let incrementalBarData2 = {
      labels: this.state.incrementalDataLabels,
      completeLabels: this.state.incrementalDataLabels,
      datasets: [
        {
          label: "Incremental Units",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data: this.state.incrementalUnits
        }
      ]
    };
    //*****************Lift code */
    const setColor = values => {
      var colors = [];
      for (var i = 0; i < values.length; i++) {
        var color;
        if (values[i] > 10) color = "#34BADD";
        if (values[i] >= 0 && values[i] <= 10) color = "#E8B23A";
        if (values[i] < 0) color = "#ce2323";
        colors[i] = color;
      }
      return colors;
    };
    let barData3 = {
      labels: this.state.actualDataLabels,
      completeLabels: this.state.actualDataLabels,
      datasets: [
        {
          borderColor: setColor(this.state.liftMargin),
          borderWidth: 1,
          data: this.state.liftMargin,
          backgroundColor: setColor(this.state.liftMargin)
        }
      ]
    };
    let barData4 = {
      labels: this.state.actualDataLabels,
      completeLabels: this.state.actualDataLabels,
      datasets: [
        {
          backgroundColor: setColor(this.state.liftSales),
          borderColor: setColor(this.state.liftSales),
          borderWidth: 1,
          data: this.state.liftSales
        }
      ]
    };
    let barData5 = {
      labels: this.state.actualDataLabels,
      completeLabels: this.state.actualDataLabels,
      datasets: [
        {
          backgroundColor: setColor(this.state.liftUnits),
          borderColor: setColor(this.state.liftUnits),
          borderWidth: 1,
          data: this.state.liftUnits
        }
      ]
    };


    // offer graphs
    const labels = this.props.offersListView.map(f => f.promoId);
    let offerBarDataGraph = {
      labels: labels,
      completeLabels: this.state.offerGraphData.actualDataLabels,
      datasets: [
        {
          label: "Net Margin",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data : this.state.offerGraphData.actualMargin
         
        }
      ]
    };
    let offerBarData1 = {
      labels: labels,
      completeLabels: this.state.offerGraphData.actualDataLabels,
      datasets: [
        {
          label: "Net Sales",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data : this.state.offerGraphData.actualSales
        }
      ]
    };
    let offerBarData2 = {
      labels:labels,
      completeLabels: this.state.offerGraphData.actualDataLabels,
      datasets: [
        {
          label: "Net Units",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data : this.state.offerGraphData.actualUnits
          // data: this.state.offerGraphData.actualTotalUnits
        }
      ]
    };
    let offerSpendBarData = {
      labels: labels,
      completeLabels: this.state.offerGraphData.spendLabels,
      datasets: [
        {
          label: "Spend",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data: this.state.offerGraphData.spend
        }
      ]
    };
    let offerRoiSalesBarData = {
      labels: labels,
      completeLabels: this.state.offerGraphData.roiSalesLabels,
      datasets: [
        {
          label: "ROI (Sales)",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data: this.state.offerGraphData.roiSales
        }
      ]
    };
   
    let offerCouponValueBarData = {
      labels:labels,
      completeLabels: this.state.offerGraphData.offerLabels,
      datasets: [
        {
          label: "Total $ Coupon Value",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data: this.state.offerGraphData.totalCouponValue
        }
      ]
    };
    let offerIncrementalBarData = {
      labels: labels,
      completeLabels: this.state.offerGraphData.incrementalDataLabels,
      datasets: [
        {
          label: "Incremental Margin",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data : this.state.offerGraphData.incrementalMargin
          // data: this.state.offerGraphData.directIncrementalMargin
        }
      ]
    };
    let offerIncrementalBarData1 = {
      labels: labels,
      completeLabels: this.state.offerGraphData.incrementalDataLabels,
      datasets: [
        {
          label: "Incremental Sales",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data : this.state.offerGraphData.incrementalSales
        }
      ]
    };
    let offerIncrementalBarData2 = {
      labels: labels,
      completeLabels: this.state.offerGraphData.incrementalDataLabels,
      datasets: [
        {
          label: "Incremental Units",
          backgroundColor: "#34BADD",
          borderColor: "#34BADD",
          borderWidth: 1,
          data : this.state.offerGraphData.incrementalUnits
        }
      ]
    };
    //*****************Lift code */
    let offerBarData3 = {
      labels: labels,
      completeLabels: this.state.offerGraphData.actualDataLabels,
      datasets: [
        {
          borderColor: setColor(this.state.offerGraphData.liftMargin),
          borderWidth: 1,
          data : this.state.offerGraphData.liftMargin,
          backgroundColor: setColor(this.state.offerGraphData.liftMargin)
        }
      ]
    };
    let offerBarData4 = {
      labels: labels,
      completeLabels: this.state.offerGraphData.actualDataLabels,
      datasets: [
        {
          backgroundColor: setColor(this.state.offerGraphData.liftSales),
          borderColor: setColor(this.state.offerGraphData.liftSales),
          borderWidth: 1,
          data: this.state.offerGraphData.liftSales
        }
      ]
    };
    let offerBarData5 = {
      labels: labels,
      completeLabels: this.state.offerGraphData.actualDataLabels,
      datasets: [
        {
          backgroundColor: setColor(this.state.offerGraphData.liftUnits),
          borderColor: setColor(this.state.offerGraphData.liftUnits),
          borderWidth: 1,
          data: this.state.offerGraphData.liftUnits
        }
      ]
    };

    
    let DynamicFiltersProps = [
      {
        type: "MultiSelectCheckboxes",
        placeholder: "Select Merchcat",
        state: this.state.merchcat,
        mappingKey: "merchcat",
        onMenuCloseApi: true,
        grid: "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
        label: "Merchcat"
      },
      {
        type: "MultiSelectCheckboxes",
        placeholder: "Select AH6",
        state: this.state.ah6,
        mappingKey: "ah6",
        onMenuCloseApi: true,
        grid: "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
        label: "AH6"
      },
      {
        type: "MultiSelectCheckboxes",
        placeholder: "Select Vendor",
        state: this.state.vendor,
        mappingKey: "vendor",
        onMenuCloseApi: false,
        grid: "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
        label: "Vendor"
      },
      {
        type: "MultiSelectCheckboxes",
        placeholder: "Select Fiscal Year",
        state: this.state.selectedYear,
        mappingKey: "selectedYear",
        onMenuCloseApi: false,
        grid: "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
        label: "Fiscal Year",
        selectionLimit:5
      }
    ];

    let DynamicFiltersOptions = {
      merchcatOptions: [],
      ah6Options: [],
      vendorOptions: [],
      selectedYearOptions: []
    };

    let hierarchyLevel = ["merchcat", "ah6", "vendor"];
    let hierarchyApiCalls = [
      {
        optionsMappingKey: "merchcat",
        UrlName: "merchcat"
      },
      {
        optionsMappingKey: "selectedYear",
        UrlName: "selectedPrevYear"
      }
    ];
    let hierarchyFilterApi = "merchcatFilter";
    return (
      <div className="analyze-event card" style={{ paddingLeft: '10px', paddingRight: '10px' }}>
        <PageLoader loader={this.props.loader || this.state.loader}>
        <Tab.Container id="left-tabs-example" defaultActiveKey={this.state.selectedBookView}>
          <div className="alertDivs">
            {this.state.showAlertDanger && (
              <Alert message={this.state.errorMessage} show={this.state.showAlertDanger} />
            )}
          </div>

          <div
            style={{
              padding: '10px',
            }}>
            <h4 className="promoCal-brdcrm mb-10">
              <span className="font-bold">Analyze Books</span>
            </h4>
            <div class="row filter-block">
              <DynamicFilters
                filtersList={DynamicFiltersProps}
                options={DynamicFiltersOptions}
                hierarchyLevel={hierarchyLevel}
                hierarchyApiCalls={hierarchyApiCalls}
                hierarchyFilterApi={hierarchyFilterApi}
                openLoader={this.props.openLoader}
                closeLoader={this.props.closeLoader}
                updateParentState={this.updateState}
                filterData={this.filterData}
                onReset={this.onRefresh}
                onDatepickerChange={this.handleYearData}
                viewType="verticle"
                isFilterButtonDisabled={
                  this.state.merchcat.length === 0 || this.state.selectedYear.length === 0
                }
                screenName={SCREEN_NAME}
              />
            </div>
            <div className="row">
              <div className="col-md-3" style={{ marginTop: '-23px' }}>
                <label style={{ fontWeight: 'bold', fontSize: '14px' }}>Book (s)</label>
              </div>
              <div className="col-md-3" style={{ marginTop: '-23px' }}>
                <label style={{ fontWeight: 'bold', fontSize: '14px' }}>Offer (s)</label>
              </div>
            </div>
            <div className="row">
              <div className="col-md-3">
                <MultiSelectCheckboxes
                  placeholderButtonLabel="Select Book (s)"
                  options={this.props.campaignsList}
                  value={this.state.campaign}
                  mappingKey="campaign"
                  updateState={this.updateBooks}
                  onMenuCloseApiFun={this.callApi}
                  onMenuCloseApi={true}
                />
              </div>
              <div className="col-md-3">
                <MultiSelectCheckboxes
                  placeholderButtonLabel="Select Offer (s)"
                  options={this.state.offersList}
                  value={this.state.offers}
                  mappingKey="offers"
                  updateState={this.updateOffers}
                  onMenuCloseApiFun={this.callOfferApi}
                  onMenuCloseApi={false}
                />
              </div>
              <div className="col-md-1 offset-md-1">
                {this.state.selectedView === 'Table' ? (
                  <React.Fragment>
                    {this.state.campaign && this.state.campaign.length > 0 ? (
                      <OverlayTrigger
                        placement="bottom"
                        overlay={<Tooltip>Download as excel</Tooltip>}>
                        <button
                          className="btn btn-primary iconbutton download-btn"
                          onClick={this.downloadExcel}>
                          <span>
                            <i className={'fa fa-file-excel-o'} aria-hidden="true"></i> &nbsp;&nbsp;
                            <i class="fa fa-download" aria-hidden="true" />
                          </span>
                        </button>
                      </OverlayTrigger>
                    ) : null}
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {this.state.campaign && this.state.campaign.length > 0 ? (
                      <React.Fragment>
                        <OverlayTrigger
                          placement="bottom"
                          overlay={<Tooltip>Download as pdf</Tooltip>}>
                          <button
                            className="btn btn-primary iconbutton download-btn"
                            onClick={this.exportPDFWithComponent}>
                            <span>
                              <i className={'fa fa-file-pdf-o'} aria-hidden="true"></i> &nbsp;&nbsp;
                              <i class="fa fa-download" aria-hidden="true" />
                            </span>
                          </button>
                        </OverlayTrigger>
                      </React.Fragment>
                    ) : null}
                  </React.Fragment>
                )}
              </div>
              <div className="col-md-2">
                <div className="text-right BtnGroupRow">
                <Nav variant="pills" >
        <Nav.Item>
          <Nav.Link eventKey="Books" name="Books" onClick={this.changedBookView} className="bookView">Books</Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="Offers" name="Offers" onClick={this.changedBookView} className="offerView">Offers</Nav.Link>
        </Nav.Item>
      </Nav>
            {/*       <div
                    id="promoCal"
                    className="divInn btn-group "
                    style={{
                      textAlign: 'center',
                    }}>
                    <button
                      type="button"
                      className={classNames({
                        active: this.state.selectedBookView == 'Books',
                        ' btn': true,
                        'btn-outline-primary cal-btn1': true,
                        btnCalendr: true,
                        calPromo: true,
                      })}
                      onClick={() => this.changedBookView('Books')}
                      style={{
                        width: '90px',
                      }}>
                      Books
                    </button>
                    <button
                      type="button"
                      className={classNames({
                        active: this.state.selectedBookView === 'Offers',
                        ' btn': true,
                        'btn-outline-primary cal-btn1': true,
                        btnCalendr: true,
                        calPromo: true,
                      })}
                      onClick={() => this.changedBookView('Offers')}
                      style={{
                        width: '90px',
                      }}>
                      Offers
                    </button>
                  </div>
             */}    </div>
              </div>
              <div className="col-md-2">
                <div className="text-right BtnGroupRow">
                  <div
                    id="promoCal"
                    className="divInn btn-group "
                    style={{
                      textAlign: 'center',
                    }}>
                    <button
                      type="button"
                      className={classNames({
                        active: this.state.selectedView == 'Charts',
                        ' btn': true,
                        'btn-outline-primary cal-btn': true,
                        btnCalendr: true,
                        calPromo: true,
                      })}
                      onClick={() => this.changedView('Charts')}
                      style={{
                        width: '90px',
                      }}>
                      Charts
                    </button>
                    <button
                      type="button"
                      className={classNames({
                        active: this.state.selectedView == 'Table',
                        ' btn': true,
                        'btn-outline-primary cal-btn': true,
                        btnCalendr: true,
                        calPromo: true,
                      })}
                      onClick={() => this.changedView('Table')}
                      style={{
                        width: '90px',
                      }}>
                      Table
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <hr style={{ marginTop: '30px' }} />
            {/* </div> */}

            {/* 
                                                <div class="spacer"></div>
                        <div className="select-class">
                            <Select
                               placeholder="Select Book (s)"
                                name="campaign"Charts
                                options={this.props.campaignsList}
                                value={this.state.campaign}
                                onChange={this.handleSelectChange}
                                isMulti
                                />
                                
                        </div>
                        */}

            <div
              id="myCanvas"
              className=""
              style={{
                margin: '20px 5px',
              }}>
              <PDFExport
                ref={(component) => (this.pdfExportComponent = component)}
                margin={{ top: 20, left: 10, right: 10, bottom: 40 }}>
                <div
                  className="row "
                  style={{
                    justifyContent: 'center',
                    padding: '1px 20px',
                  }}>
                  {this.props.campaignsList.length ===0 && this.state.message ?(
                    <>{this.state.message}</>
                  ): null}
                  <Tab.Content>
                    <Tab.Pane eventKey="Books">
                    {this.state.selectedView === 'Table' ? null :(
                      <>
                      <div className="row">
                      {!!this.state.actualMargin.length &&
                        this.setChart('Net Margin (in $)', barData, chartOptions1)}
                      {!!this.state.actualSales.length &&
                        this.setChart('Net Sales (in $)', barData1, chartOptions1)}
                      {!!this.state.actualUnits.length &&
                        this.setChart('Net Units', barData2, chartOptions1)}
                      {!!this.state.incrementalMargin.length &&
                        this.setChart(
                          'Incremental  Margin (in $)',
                          incrementalBarData,
                          chartOptions1
                        )}
                      {!!this.state.incrementalSales.length &&
                        this.setChart(
                          'Incremental  Sales (in $)',
                          incrementalBarData1,
                          chartOptions1
                        )}
                      {!!this.state.incrementalUnits.length &&
                        this.setChart('Incremental  Units', incrementalBarData2, chartOptions1)}
                      {!!this.state.liftMargin.length &&
                        this.setChart('Margin Lift Percentage', barData3, chartOptions2, true)}
                      {!!this.state.liftSales.length &&
                        this.setChart('Sales Lift Percentage', barData4, chartOptions2, true)}
                      {!!this.state.liftUnits.length &&
                        this.setChart('Unit Lift Percentage', barData5, chartOptions2, true)}
                      {!!this.state.spend.length &&
                        this.setChart('Spend(in $)', spendBarData, chartOptions1)}
                      {!!this.state.roiSales.length &&
                        this.setChart('ROI (Sales)', roiSalesData, chartOptions1)}
                      {!!this.state.offer.length &&
                        this.setChart('# Of Offers', offerBarData, chartOptions1)}
                      {!!this.state.totalCouponValue.length &&
                        this.setChart(
                          'Total $ Coupon Value',
                          couponValueBarData,
                          chartOptions1
                        )}
                    </div>
                    </>)}
                    </Tab.Pane>
                    <Tab.Pane eventKey="Offers">
                    {this.state.selectedView === 'Charts' ? (
                      <>
                      {Object.keys(this.state.offerGraphData).length > 0 && this.props.offersListView.length > 0 && (
                        <div className="row">
                        {/* <OfferView
                          offerViewData={this.props.offersListView}
                          openLoader={this.props.openLoader}
                          closeLoader={this.props.closeLoader}
                          offerGraphData={this.state.offerGraphData}
                        /> */}
                        <React.Fragment>
                        {this.state.offerGraphData.actualMargin.length > 0 &&
                          this.setOfferChart(
                            "Net Margin (in $)",
                            offerBarDataGraph,
                            chartOptions1
                          )}
                        {this.state.offerGraphData.actualSales.length > 0 &&
                          this.setOfferChart(
                            "Net Sales (in $)",
                            offerBarData1,
                            chartOptions1
                          )}
                        {this.state.offerGraphData.actualUnits.length > 0 &&
                          this.setOfferChart("Net Units", offerBarData2, chartOptions1)}
                        {this.state.offerGraphData.incrementalMargin.length > 0 &&
                          this.setOfferChart(
                            "Incremental  Margin (in $)",
                            offerIncrementalBarData,
                            chartOptions1
                          )}
                        {this.state.offerGraphData.incrementalSales.length > 0 &&
                          this.setOfferChart(
                            "Incremental  Sales (in $)",
                            offerIncrementalBarData1,
                            chartOptions1
                          )}
                        {this.state.offerGraphData.incrementalUnits.length > 0 &&
                          this.setOfferChart(
                            "Incremental  Units",
                            offerIncrementalBarData2,
                            chartOptions1
                          )}
                        {this.state.offerGraphData.liftMargin.length > 0 &&
                          this.setOfferChart(
                            "Margin Lift Percentage",
                            offerBarData3,
                            chartOptions2,
                            true
                          )}
                        {this.state.offerGraphData.liftSales.length > 0 &&
                          this.setOfferChart(
                            "Sales Lift Percentage",
                            offerBarData4,
                            chartOptions2,
                            true
                          )} 
                         {this.state.offerGraphData.liftUnits.length > 0 &&
                          this.setOfferChart(
                            "Unit Lift Percentage",
                            offerBarData5,
                            chartOptions2,
                            true
                          )}
                        {this.state.offerGraphData.spend.length > 0 &&
                          this.setOfferChart(
                            "Spend(in $)",
                            offerSpendBarData,
                            chartOptions1
                          )
                        }
                        {this.state.offerGraphData.roiSales.length > 0 &&
                          this.setOfferChart(
                            "ROI (Sales)",
                            offerRoiSalesBarData,
                            chartOptions1
                          )
                        }
                        {this.state.offerGraphData.totalCouponValue.length > 0 &&
                          this.setOfferChart(
                            "Total $ Coupon Value",
                            offerCouponValueBarData,
                            chartOptions1
                          )}
                        </React.Fragment>
  
                        </div>
                      )}
                      </>
                    ) : null}
                    
                    </Tab.Pane>
                  </Tab.Content>
                  {this.state.selectedView === 'Charts' ? (
                    <>
                      {this.state.selectedBookView === 'Books' ? (
                        <React.Fragment>
                          {/* {!!this.state.actualMargin.length &&
                            this.setChart('Net Margin (in $)', barData, chartOptions1)}
                          {!!this.state.actualSales.length &&
                            this.setChart('Net Sales (in $)', barData1, chartOptions1)}
                          {!!this.state.actualUnits.length &&
                            this.setChart('Net Units', barData2, chartOptions1)}
                          {!!this.state.incrementalMargin.length &&
                            this.setChart(
                              'Incremental  Margin (in $)',
                              incrementalBarData,
                              chartOptions1
                            )}
                          {!!this.state.incrementalSales.length &&
                            this.setChart(
                              'Incremental  Sales (in $)',
                              incrementalBarData1,
                              chartOptions1
                            )}
                          {!!this.state.incrementalUnits.length &&
                            this.setChart('Incremental  Units', incrementalBarData2, chartOptions1)}
                          {!!this.state.liftMargin.length &&
                            this.setChart('Margin Lift Percentage', barData3, chartOptions2, true)}
                          {!!this.state.liftSales.length &&
                            this.setChart('Sales Lift Percentage', barData4, chartOptions2, true)}
                          {!!this.state.liftUnits.length &&
                            this.setChart('Unit Lift Percentage', barData5, chartOptions2, true)}
                          {!!this.state.spend.length &&
                            this.setChart('Spend(in $)', spendBarData, chartOptions1)}
                          {!!this.state.roiSales.length &&
                            this.setChart('ROI (Sales)', roiSalesData, chartOptions1)}
                          {!!this.state.offer.length &&
                            this.setChart('# Of Offers', offerBarData, chartOptions1)}
                          {!!this.state.totalCouponValue.length &&
                            this.setChart(
                              'Total $ Coupon Value',
                              couponValueBarData,
                              chartOptions1
                            )} */}
                        </React.Fragment>
                      ) : (
                        <>
                          {/* {Object.keys(this.state.offerGraphData).length > 0 && this.props.offersListView.length > 0 && (
                            <OfferView
                              offerViewData={this.props.offersListView}
                              openLoader={this.props.openLoader}
                              closeLoader={this.props.closeLoader}
                              offerGraphData={this.state.offerGraphData}
                            />
                          )} */}
                        </>
                      )}
                    </>
                  ) : (
                    <>
                      {this.state.selectedBookView === 'Books' ? (
                        <React.Fragment>
                           {this.state.campaign &&
                          this.state.campaign.length > 0 &&
                          this.state.rowData &&
                          this.state.rowData.length > 0 ? (
                            <React.Fragment>
                              <div
                                style={{
                                  height: '100%',
                                  width: '100%',
                                }}
                                className="ag-theme-balham">
                                <div
                                  style={{
                                    height: '380px',
                                    width: '100%',
                                  }}>
                                  <AgGridReact
                                    // alwaysShowVerticalScroll={false}
                                    // suppressPreventDefaultOnMouseWheel ={false}
                                    defaultColDef={{
                                      resizable: true,
                                    }}
                                    suppressCellSelection={false}
                                    columnDefs={this.state.columnDefs}
                                    rowData={this.state.rowData}
                                    onGridReady={this.onGridReady}
                                    domLayout="autoHeight"
                                    enableCellTextSelection={true}
                                  />
                                </div>
                              </div>
                            </React.Fragment>
                          ) : null} 
                        </React.Fragment>
                      ) : (
                        
                        this.state.offers.length > 0 && this.props.offersListView.length > 0 && (
                          <React.Fragment>
                             <div
                              style={{
                                height: '100%',
                                width: '100%',
                              }}
                              className="ag-theme-balham">
                              <div
                                style={{
                                  height: '380px',
                                  width: '100%',
                                }}>
                                <AgGridReact
                                  // alwaysShowVerticalScroll={false}
                                  // suppressPreventDefaultOnMouseWheel ={false}
                                  defaultColDef={{
                                    resizable: true,
                                  }}
                                  suppressCellSelection={false}
                                  columnDefs={this.state.offerColDef}
                                  rowData={this.state.offerRowData}
                                  onGridReady={this.onGridReady}
                                  enableCellTextSelection={true}
                                />
                              </div>
                            </div> 
                          </React.Fragment>
                        )
                      )}
                    </>
                  )}
                </div>
              </PDFExport>
              <div>
                <div
                  className="row "
                  style={{
                    justifyContent: 'center',
                    padding: '1px 20px',
                  }}></div>
              </div>
            </div>
          </div>
          </Tab.Container>
        </PageLoader>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    loader: state.analyzeEvent.loader,
    campaignsList: state.analyzeEvent.campaignsList,
    offersListView: state.analyzeEvent.offersListView
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAnalyzeEventDetails: val => dispatch(getAnalyzeEventDetails(val)),
    openLoader: val => dispatch(openLoader()),
    closeLoader: val => dispatch(closeLoader()),
    getOffersList: val => dispatch(getOffersList(val)),
    resetCampaingsList: val => dispatch(resetCampaingsList(val)),
  };
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AnalyzeCampaign)
);
