import React, { Fragment, useState, useEffect } from 'react';
import OfferViewCharts from '../AnalyzeEvent/OfferViewCharts';
import { chartOptions1, chartOptions2 } from '../common/chartOptions';
import InfiniteScroll from 'react-infinite-scroller';
// import InfiniteScroll from 'react-infinite-scroll-component';
import {OVERALL_PERFORMANCE,PROMO_PERFORMANCE,PLANNED_VS_OPTIMIZED,OVERALL,PROMO,OPTIMIZED} from './constants'
const Chart = (props) => {
  const [chartData, setChartData] = useState([]);
  const [hasMoreItems, setHasMoreItems] = useState(true);
  const [loader, setLoader] = useState(false);
  useEffect(() => {
    const data = updateData(props.chartData.slice(0, 10));
    setChartData(data);
  }, [props.chartData]);
  const updateData = (loadData) => {
    let lyGraphData = [];
    loadData.forEach((charObj) => {
      let convertedData = [];
      let metricsList = [];
      switch (props.type) {
        case OVERALL:
          metricsList = OVERALL_PERFORMANCE
          break;
          case PROMO:
            metricsList = PROMO_PERFORMANCE
            break;
            case OPTIMIZED:
              metricsList = PLANNED_VS_OPTIMIZED
              break;
        default:
          break;
      }
      metricsList.forEach((obj) => {
        const data = props.type === OPTIMIZED ? [
          charObj[`${obj.key}`].planned,
          charObj[`${obj.key}`].optimized
        ]: [
          charObj[`${obj.key}`].ly,
          charObj[`${obj.key}`].ty,
        ];
        const lables = props.type === OPTIMIZED ? ["TY Planned","TY Optimized"]: ['LY', 'TY'];
        let graphData = {
          label: obj.label,
          value: parseInt(charObj[`${obj.key}`].change * 100) || 0,
          data: {
            labels: lables,
            completeLabels: lables,
            datasets: [
              {
                backgroundColor: ['rgb(165,206,226)', 'rgb(67,103,125)'],
                borderColor: '#34BADD',
                borderWidth: 1,
                data: data,
              },
            ],
          },
        };
        convertedData.push(graphData);
      });
      let consolidateData = {
        data: convertedData,
        name: charObj[`name`],
      };
      lyGraphData.push(consolidateData);
    });
    // setChartData(lyGraphData);
    return lyGraphData;
  };

  const loadMore = () => {
    // setLoader(true)
    if (props.chartData.length === chartData.length) {
      setHasMoreItems(false);
      // setLoader(false)
      return;
    }
    const data = updateData(props.chartData.slice(chartData.length, chartData.length + 10));
    const moreItems = chartData.concat(data);
    setChartData(moreItems);
    setLoader(false);
  };
  return (
    <div>
      <InfiniteScroll
        initialLoad={true}
        threshold={500}
        pageStart={0}
        loadMore={loadMore}
        hasMore={hasMoreItems}
        loader={
          <div className="loader" key={0}>
            <h4>Loading...</h4>
          </div>
        }>
        {chartData.map((chartObj, index) => (
          <div>
            {/* <p> */}
              <b>
                {chartObj.name}
              </b>
            {/* </p> */}
            <div>
              <div className="row">
                {chartObj.data.map((obj, index) => (
                  <div className={props.type === PROMO ? "decrease-width col-3": "col-3"} style={{ border: '2px solid #f0f1f3' }}>
                    <div className="row">
                      <div className="col-8">{obj.label=== "Incremental Revenue"? "Inc. Revenue":obj.label}</div>
                      <div className="col-4 remove_padding">
                        <b>
                          {obj.value >= 0 ? (
                            <>
                              <i
                                class="fa fa-caret-up"
                                aria-hidden="true"
                                style={{ color: '#4a9565' }}></i>
                            </>
                          ) : (
                            <>
                              <i
                                class="fa fa-caret-down"
                                aria-hidden="true"
                                style={{ color: '#a54e4e' }}></i>
                            </>
                          )}{' '}
                          &nbsp;&nbsp;
                          {obj.value >= 0 ? (
                            <>
                              <span style={{ color: '#4a9565' }}>+{obj.value}%</span>
                            </>
                          ) : (
                            <>
                              <span style={{ color: '#a54e4e' }}>{obj.value}%</span>
                            </>
                          )}
                        </b>
                      </div>
                    </div>
                    <div id="dashboard-charts">
                      <OfferViewCharts
                        data={obj.data}
                        options={['Units','ROI'].indexOf(obj.label) !==-1  ? chartOptions2 : chartOptions1}
                      />
                    </div>
                    <div className="spacer"></div>
                  </div>
                ))}
              </div>
            </div>
            <hr className="dotted-line" style={{ marginBottom: '20px' }} />
          </div>
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default Chart;
