import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import BreadCrumb from "../BreadCrumb";
import PageLoader from "../../utils/Loader";
import UsersList from './usersList';
import { UserRoles, setUserDetails, actionGetAllUsers, actionSaveCategoryAdmin, actionDownloadUserDetails} from '../../actions/userManagementAction';
import "./index.css";
import { AdminGA   } from '../../common/GAUtils';
class UserManagement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categoryData: []
    }
  }

  componentDidMount() {

    this.props.UserRoles();
    this.props.actionGetAllUsers();
  }

  updateUserData = () => {
    if (this.props.allUsers.length > 0 && this.props.roles.length > 0) {
      const emailToLabel = {};
      this.props.allUsers.forEach(item => (emailToLabel[item.value] = item.label))
      const updateCategoryData = this.props.roles.map(item => {
        const obj = {};
        Object.keys(item).map(key => {
          if (key != 'category') {
            const value = emailToLabel[item[key]];
            obj[key] = value ? value.toUpperCase() :  item[key] ?  item[key].toUpperCase() : item[key];
          } else {
            obj[key] = item[key]
          }
        })
        return obj;
      })
      this.setState({
        categoryData: updateCategoryData
      })
    }
  }

  componentDidUpdate(oldProps) {
    if (oldProps.allUsers != this.props.allUsers) {
      this.updateUserData();
    }
    if (oldProps.roles != this.props.roles) {
      this.updateUserData();
    }
  }

  componentWillUnmount() {
    this.props.setUserDetails({});
  }
 
  handleDownload = () => {
    this.submitGA("Click","Download admin users list")
    this.props.actionDownloadUserDetails();
  }
  submitGA(action,label){
    AdminGA({ action, label});
  }
  render() {
    const {setUserDetails, userData, allUsers, actionSaveCategoryAdmin} = this.props;
    return (
      <PageLoader loader={this.props.loader}>
        <div>
          <div className='calendarContainer' id='main-calendar-container'>
            <div className='promo'>
              <div
                className='card CalendarMain promoCalendarDiv1'
                id='promocalendar-maincontainer'>
                <h4 className='promoHeading mb-1 color-blue'>
                  <BreadCrumb root='' child='Admin' />
                </h4>
                <hr style={{ margin: "10px" }} />
                <div className="row">
                <div className="promolist-header">
                <label className="pipelineLabel downloadLbel">Download User Details</label>
                </div>
                <button
                  className="btn btn-primary iconbutton download-btn"
                  onClick={this.handleDownload}
                >
                  <span>
                    <i class="fa fa-download" aria-hidden="true" />
                  </span>
                </button>
              </div>
                <div style={{ marginBottom: "30px" }}>
                <UsersList rowData={this.state.categoryData}
                 setUserDetails={setUserDetails}
                 userData={userData}
                 allUsers={allUsers}
                 actionSaveCategoryAdmin={actionSaveCategoryAdmin}
                 UserRoles={this.props.UserRoles}
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </PageLoader>
    );
  }
}

const mapStateToProps = state => ({
  roles: state.userManagementData.roles,
  loader: state.userManagementData.loading,
  userData: state.userManagementData.userData,
  allUsers: state.userManagementData.allUsers,
  userInfo: state.userManagementData.userInfo
});

const mapDispatchToProps = dispatch => {
  return {
    UserRoles: value => dispatch(UserRoles(value)),
    setUserDetails: val => dispatch(setUserDetails(val)),
    actionGetAllUsers: () => dispatch(actionGetAllUsers()),
    actionSaveCategoryAdmin: val => dispatch(actionSaveCategoryAdmin(val)),
    actionDownloadUserDetails: val => dispatch(actionDownloadUserDetails(val))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(UserManagement));


