const VIEW_TYPES = [
  {
    label: 'Consolidated View',
    key: 'consolidated',
  },
  {
    label: 'Buyer View',
    key: 'buyer',
  },
  {
    label: 'Category View',
    key: 'category',
  },
  {
    label: 'Vendor View',
    key: 'vendor',
  },
];

const PERFORMANCE_TYPES = [
  {
    label: 'Overall Performance',
    key: 'overall',
  },
  {
    label: 'Promo Performance',
    key: 'promo',
  },
  {
    label: 'TY Planned v/s Optimised',
    key: 'optimized',
  },
];

const LEVEL_TYPES = [
  {
    label: 'YTD',
    key: 'YTD',
  },
  {
    label: 'Quarter(s)',
    key: 'quarter',
  },
  {
    label: 'Book(s)',
    key: 'cycle',
  },
];

const CONSOLIDATED = 'consolidated';
const BUYER = 'buyer';
const CATEGORY = 'category';
const VENDOR = 'vendor';
const OVERALL = 'overall';
const PROMO = 'promo';
const YTD = 'YTD';
const QUARTER = 'quarter';
const CYCLE = 'cycle';
const OPTIMIZED = 'optimized';

const SORT_BY_PLANNED_OPTIMIZED = [
  {
    value: 'Increasing Promo Revenue',
    label: 'Increasing Revenue',
  },
  {
    value: 'Decreasing Promo Revenue',
    label: 'Decreasing Revenue',
  },
  {
    value: 'Increasing Spend',
    label: 'Increasing Spend',
  },
  {
    value: 'Decreasing Spend',
    label: 'Decreasing Spend',
  },
  {
    value: 'Increasing Incremental Revenue',
    label: 'Increasing Incremental Revenue',
  },
  {
    value: 'Decreasing Incremental Revenue',
    label: 'Decreasing Incremental Revenue',
  },
  {
    value: 'Increasing ROI',
    label: 'Increasing ROI',
  },
  {
    value: 'Decreasing ROI',
    label: 'Decreasing ROI',
  }
];
const SORT_BY_PROMO = [
  {
    value: 'Increasing Promo Revenue',
    label: 'Increasing Revenue',
  },
  {
    value: 'Decreasing Promo Revenue',
    label: 'Decreasing Revenue',
  },
  {
    value: 'Increasing Promo Margin',
    label: 'Increasing Margin',
  },
  {
    value: 'Decreasing Promo Margin',
    label: 'Decreasing Margin',
  },
  {
    value: 'Increasing Spend',
    label: 'Increasing Spend',
  },
  {
    value: 'Decreasing Spend',
    label: 'Decreasing Spend',
  },
  {
    value: 'Increasing ROI',
    label: 'Increasing ROI',
  },
  {
    value: 'Decreasing ROI',
    label: 'Decreasing ROI',
  },
  {
    value: 'Increasing Incremental Revenue',
    label: 'Increasing Incremental Revenue',
  },
  {
    value: 'Decreasing Incremental Revenue',
    label: 'Decreasing Incremental Revenue',
  }
];
const SORT_BY_OVERALL = [
  {
    value: 'Increasing Revenue',
    label: 'Increasing Revenue',
  },
  {
    value: 'Decreasing Revenue',
    label: 'Decreasing Revenue',
  },
  {
    value: 'Increasing Units',
    label: 'Increasing Units',
  },
  {
    value: 'Decreasing Units',
    label: 'Decreasing Units',
  },
  {
    value: 'Increasing Margin',
    label: 'Increasing Margin',
  },
  {
    value: 'Decreasing Margin',
    label: 'Decreasing Margin',
  },
  {
    value: 'Increasing Spend',
    label: 'Increasing Spend',
  },
  {
    value: 'Decreasing Spend',
    label: 'Decreasing Spend',
  },
];
const OVERALL_PERFORMANCE = [
  {
    label: "Revenue",
    key: 'revenue'
  },
  {
    label: "Units",
    key: 'units'
  },
  {
    label: "Margin",
    key: 'margin'
  },
  {
    label: "Spend",
    key: 'spend'
  }
]

const PROMO_PERFORMANCE = [
  {
    label: "Promo Revenue",
    key: 'revenue'
  },
  {
    label: "Promo Margin",
    key: 'margin'
  },
  {
    label: "Spend",
    key: 'spend'
  },
  {
    label: "ROI",
    key: 'roi'
  },
  {
    label: "Incremental Revenue",
    key: 'incremental'
  }
]
const PLANNED_VS_OPTIMIZED = [
  {
    label: "Promo Revenue",
    key: 'revenue'
  },
  {
    label: "Spend",
    key: 'spend'
  },
  {
    label: "Incremental Revenue",
    key: 'incremental'
  },
  {
    label: "ROI",
    key: 'roi'
  }
];
const OVERALL_PERFORMANCE_TABLE = [
    {
    headerName: "Revenue",
    children: [
      { headerName: 'LY ($)', field: `revenue_ly`, minWidth: 120,sortable: true,filter: true },
      { headerName: 'TY ($)', field: `revenue_ty`, minWidth: 120,sortable: true,filter: true },
      {
        headerName: '% Change',
        field: `revenue_change`,
        minWidth: 120,
        sortable: true,
        filter: true,
        cellStyle: function (params) {
          if (params.value && params.value.startsWith('-')) {
            return { color: 'red' };
          } else {
            return { color: 'green' };
          }
        },
      },
    ]
  },
  {
    headerName: "Units",
    children: [
      { headerName: 'LY', field: `units_ly`, minWidth: 120 ,sortable: true,filter: true},
      { headerName: 'TY', field: `units_ty`, minWidth: 120 ,sortable: true,filter: true},
      {
        headerName: '% Change',
        field: `units_change`,
        minWidth: 120,
        sortable: true,
        filter: true,
        cellStyle: function (params) {
          if (params.value && params.value.startsWith('-')) {
            return { color: 'red' };
          } else {
            return { color: 'green' };
          }
        },
      },
    ]
  },
  {
    headerName: "Margin",
    children: [
      { headerName: 'LY ($)', field: `margin_ly`, minWidth: 120 ,sortable: true,filter: true},
      { headerName: 'TY ($)', field: `margin_ty`, minWidth: 120 ,sortable: true,filter: true},
      {
        headerName: '% Change',
        field: `margin_change`,
        minWidth: 120,
        sortable: true,
        filter: true,
        cellStyle: function (params) {
          if (params.value && params.value.startsWith('-')) {
            return { color: 'red' };
          } else {
            return { color: 'green' };
          }
        },
      },
    ]
  },
  {
    headerName: "Spend",
    children: [
      { headerName: 'LY ($)', field: `spend_ly`, minWidth: 120 ,sortable: true,filter: true},
      { headerName: 'TY ($)', field: `spend_ty`, minWidth: 120 ,sortable: true,filter: true},
      {
        headerName: '% Change',
        field: `spend_change`,
        minWidth: 120,
        sortable: true,
        filter: true,
        cellStyle: function (params) {
          if (params.value && params.value.startsWith('-')) {
            return { color: 'red' };
          } else {
            return { color: 'green' };
          }
        },
      },
    ]
  }
];

const PLANNED_VS_OPTIMIZED_TABLE = [
  {
    headerName: "Promo Revenue",
    children: [
      { headerName: 'Optimized ($)', field: `revenue_optimized`, minWidth: 120 ,sortable: true,filter: true},
      { headerName: 'Planned ($)', field: `revenue_planned`, minWidth: 120 ,sortable: true,filter: true},
      {
        headerName: '% Change',
        field: `revenue_change`,
        minWidth: 120,
        sortable: true,
        filter: true,
        cellStyle: function (params) {
          if (params.value && params.value.startsWith('-')) {
            return { color: 'red' };
          } else {
            return { color: 'green' };
          }
        },
      },
    ]
  },
  {
    headerName: "ROI",
    children: [
      { headerName: 'Optimized', field: `roi_optimized`, minWidth: 120 ,sortable: true,filter: true},
      { headerName: 'Planned', field: `roi_planned`, minWidth: 120 ,sortable: true,filter: true},
      {
        headerName: '% Change',
        field: `roi_change`,
        minWidth: 120,
        sortable: true,filter: true,
        cellStyle: function (params) {
          if (params.value && params.value.startsWith('-')) {
            return { color: 'red' };
          } else {
            return { color: 'green' };
          }
        },
      },
    ]
  },
  {
    headerName: "Spend",
    children: [
      { headerName: 'Optimized ($)', field: `spend_optimized`, minWidth: 120 ,sortable: true,filter: true},
      { headerName: 'Planned ($)', field: `spend_planned`, minWidth: 120 ,sortable: true,filter: true},
      {
        headerName: '% Change',
        field: `spend_change`,
        minWidth: 120,
        sortable: true,
        filter: true,
        cellStyle: function (params) {
          if (params.value && params.value.startsWith('-')) {
            return { color: 'red' };
          } else {
            return { color: 'green' };
          }
        },
      },
    ]
  },
  {
    headerName: "Incremental Revenue",
    children: [
      { headerName: 'Optimized ($)', field: `incremental_optimized`, minWidth: 120 ,sortable: true,filter: true},
      { headerName: 'Planned ($)', field: `incremental_planned`, minWidth: 120 ,sortable: true,filter: true},
      {
        headerName: '% Change',
        field: `incremental_change`,
        minWidth: 120,
        sortable: true,
        filter: true,
        cellStyle: function (params) {
          if (params.value && params.value.startsWith('-')) {
            return { color: 'red' };
          } else {
            return { color: 'green' };
          }
        },
      },
    ]
  }
];

const PROMO_PERFORMANCE_TABLE = [
  {
    headerName: "Promo Revenue",
    children: [
      { headerName: 'LY ($)', field: `revenue_ly`, minWidth: 120 ,sortable: true,filter: true},
      { headerName: 'TY ($)', field: `revenue_ty`, minWidth: 120 ,sortable: true,filter: true},
      {
        headerName: '% Change',
        field: `revenue_change`,
        minWidth: 120,
        sortable: true,filter: true,
        cellStyle: function (params) {
          if (params.value && params.value.startsWith('-')) {
            return { color: 'red' };
          } else {
            return { color: 'green' };
          }
        },
      },
    ]
  },
  {
    headerName: "ROI",
    children: [
      { headerName: 'LY', field: `roi_ly`, minWidth: 120 ,sortable: true,filter: true},
      { headerName: 'TY', field: `roi_ty`, minWidth: 120 ,sortable: true,filter: true},
      {
        headerName: '% Change',
        field: `roi_change`,
        minWidth: 120,
        sortable: true,filter: true,
        cellStyle: function (params) {
          if (params.value && params.value.startsWith('-')) {
            return { color: 'red' };
          } else {
            return { color: 'green' };
          }
        },
      },
    ]
  },
  {
    headerName: "Promo Margin",
    children: [
      { headerName: 'LY ($)', field: `margin_ly`, minWidth: 120 ,sortable: true,filter: true},
      { headerName: 'TY ($)', field: `margin_ty`, minWidth: 120 ,sortable: true,filter: true},
      {
        headerName: '% Change',
        field: `margin_change`,
        minWidth: 120,
        sortable: true,filter: true,
        cellStyle: function (params) {
          if (params.value && params.value.startsWith('-')) {
            return { color: 'red' };
          } else {
            return { color: 'green' };
          }
        },
      },
    ]
  },
  {
    headerName: "Spend",
    children: [
      { headerName: 'LY ($)', field: `spend_ly`, minWidth: 120 ,sortable: true,filter: true},
      { headerName: 'TY ($)', field: `spend_ty`, minWidth: 120 ,sortable: true,filter: true},
      {
        headerName: '% Change',
        field: `spend_change`,
        minWidth: 120,
        sortable: true,filter: true,
        cellStyle: function (params) {
          if (params.value && params.value.startsWith('-')) {
            return { color: 'red' };
          } else {
            return { color: 'green' };
          }
        },
      },
    ]
  },
  {
    headerName: "Incremental Revenue",
    children: [
      { headerName: 'LY ($)', field: `incremental_ly`, minWidth: 120 ,sortable: true,filter: true},
      { headerName: 'TY($)', field: `incremental_ty`, minWidth: 120 ,sortable: true,filter: true},
      {
        headerName: '% Change',
        field: `incremental_change`,
        minWidth: 120,
        sortable: true,filter: true,
        cellStyle: function (params) {
          if (params.value && params.value.startsWith('-')) {
            return { color: 'red' };
          } else {
            return { color: 'green' };
          }
        },
      },
    ]
  }
];

export {
  VIEW_TYPES,
  PERFORMANCE_TYPES,
  LEVEL_TYPES,
  CONSOLIDATED,
  BUYER,
  CATEGORY,
  VENDOR,
  OVERALL,
  PROMO,
  QUARTER,
  CYCLE,
  YTD,
  OVERALL_PERFORMANCE,
  PROMO_PERFORMANCE,
  PLANNED_VS_OPTIMIZED,
  OPTIMIZED,
  SORT_BY_OVERALL,
  SORT_BY_PROMO,
  SORT_BY_PLANNED_OPTIMIZED,
  OVERALL_PERFORMANCE_TABLE,
  PLANNED_VS_OPTIMIZED_TABLE,
  PROMO_PERFORMANCE_TABLE
};
