import { callApi } from "./config";
import { MESSAGES } from "../common/app-constants";
import { ToastMessage } from "../common/ToastMessage";


export const fetchCalanderPlotData = (fiscalYear ) => {
  // export const fetchCalanderPlotData = (params ) => {
  return callApi
  // .post(`eda/calendarPlotData`, params)
    .get(`eda/calendarPlotData?fiscalYear=${fiscalYear}`)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return err;
    });
};

export const fetchCalenderData = reqObject =>
  callApi
    .post(`eda/calendarData`, reqObject)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return err;
    });

export const fetchPromoData = val => {
  return callApi
    .post("promo/promoData", val)
    .then(res => {
      return res.data.data.promos_data;
    })
    .catch(err => {
      return err;
    });
}

export const fetchPromoHierarchyData = val =>
  callApi
    .post("/promo/promoHierarchyData", val)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return err;
    });

  export const fetchAnalyzeBookOffers = val =>
    callApi
      .post("/promo/getAnalyzeBookOffers", val)
      .then(res => {
        return res.data.data;
      })
      .catch(err => {
        return err;
      });

export const fetchPromoAndPlanHierarchyData = val =>
  callApi
    .post("/promo/promoAndPlanHierarchyData", val)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return err;
    });
  

export const downloadHistoricalPromos = val =>
  callApi
    .post("/promo/donwload_historical_promos", val)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return err;
    });

export const downloadPromoCalendarData = reqObj => {
  return callApi
  .post("/promo/downloadPromoCalendar", reqObj)
  .then(res => {
    ToastMessage(res.status,MESSAGES.DOWNLOAD_SUCCESS_MESSAGE)
    return res.data;
  })
  .catch(err => {
    throw err;
  });
}
