import { ranges } from '../constants';

export const formatNumber = n => {
  for (var i = 0; i < ranges.length; i++) {
    if (Math.abs(n) >= ranges[i].divider) {
      let newNumber = (n / ranges[i].divider).toFixed(2);
      return newNumber.toString() + ranges[i].suffix;
    }
  }
  return n;
};

export const emptyValueFormatter = value => {
  if (value.value) {
    var toSting = Math.round(value.value);
    if (value.value > 0) {
      return toSting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      var splitAvalue = Math.abs(toSting);
      return (
        '(' + splitAvalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ')'
      );
    }
  } else {
    if (value.value == 0) {
      return 0;
    } else {
      return '-';
    }
  }
};

export const findKeyFromObject = (object, value) => {
  return Object.keys(object).find(key => object[key] === value);
}

export const numberWithCommas = x => {
  if (Number.isInteger(x)) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } else {
    return Number.parseFloat(x)
      .toFixed(2)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }
};
