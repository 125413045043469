import axios from "axios";
import {GET_CAMPAIGNS_LIST, GET_ERRORS, GET_OFFERS_LIST, LOADER ,RESET_CAMPAIGNS_LIST} from "./actionTypes"
import environment from '../../environments/environment';
import { fetchAnalyzeBookOffers } from '../../apis/promo.api';

// export const getOffersList = (val) => dispatch => {

//     dispatch({type: "LOADER", payload: true});
//    return axios
//         .post(environment.baseUrl + `/promo/promoData`, val)
//         .then(res => {
//             dispatch({type: GET_OFFERS_LIST, payload: res.data.data.promos_data});
//             dispatch({type: "LOADER", payload: false});
//         })
//         .catch(err => {

//             dispatch({type: GET_ERRORS, payload: err});
//             dispatch({type: "LOADER", payload: false});
//         });
// };
export const getOffersList = (val) => async dispatch => {
    try {
        dispatch({
            type: "LOADER",
            payload: true
        });
        let result = await fetchAnalyzeBookOffers(val);
        if (result) {
            dispatch({
                type: GET_OFFERS_LIST,
                payload: result
            });
            dispatch({
                type: "LOADER",
                payload: false
            });
        }
    } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err
        });
        dispatch({
            type: "LOADER",
            payload: false
        });
    }
};
export const getAnalyzeEventDetails = (val) => dispatch => {

    dispatch({type: "LOADER", payload: true});
   return axios
        .post(environment.baseUrl + "/promo/campaigns", val)
        .then(res => {

            dispatch({type: "LOADER", payload: false});
            dispatch({type: GET_CAMPAIGNS_LIST, payload: res.data});
        })
        .catch(err => {

            dispatch({type: GET_ERRORS, payload: err});
            dispatch({type: "LOADER", payload: false});
        });
};

export const getCompaignDetails = (val) => dispatch => {
    dispatch({type: "LOADER", payload: true});
    axios
        .get(environment.baseUrl + `/promo/campaignAnalytics?campaignId=${val}`)
        .then(res => {

            dispatch({type: "GET_CAMPAIGNS_LIST", payload: res.data});
            dispatch({type: "LOADER", payload: false});
        })
        .catch(err => {

            dispatch({type: GET_ERRORS, payload: err});
            dispatch({type: "LOADER", payload: false});
        });
};

export const openLoader = (val) => dispatch => {
    dispatch({type: "LOADER", payload: true});
};
export const closeLoader = (val) => dispatch => {
    dispatch({type: "LOADER", payload: false});
};

export const resetCampaingsList = (val) => dispatch => {

dispatch({type: "RESET_CAMPAIGNS_LIST", payload: []});

};