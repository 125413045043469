import React, { Component } from 'react';
import PageLoader from '../../utils/Loader';
import { connect } from 'react-redux';
import PageWrapper from '../common/PageWrapper';
import BreadCrumb from '../BreadCrumb';
import DashboardHeader from './dashboardHeader';
import FiletrOptions from './dashboardFilterOptions';
import Chart from './dashboardCharts';
import DashboardTable from './dashboardTable';
import ConsolidateView from './consoldateView';
import BuyerView from './buyerView';
import CategoryView from './categoryView';
import VendorView from './vendorView';
import {
  CONSOLIDATED,
  BUYER,
  CATEGORY,
  VENDOR,
  OVERALL,
  PROMO,
  QUARTER,
  CYCLE,
  YTD,
  OPTIMIZED,
  SORT_BY_PLANNED_OPTIMIZED,
  SORT_BY_PROMO,
  SORT_BY_OVERALL,
} from './constants';
import {
  actionGetCycles,
  actionGetQuarters,
  actionGetConsolidated,
  actionGetCategoryView,
  actionGetBuyerView,
  actionGetVendorView,
  actionUpdateSorting,
  getCategoryList,
  resetConsolidatedData
} from '../../actions/executiveSummaryAction';
import isUndefined from 'lodash/isUndefined';
import './index.css';
import _ from 'lodash';
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      view: CONSOLIDATED,
      type: PROMO,
      level: YTD,
      value: [],
      viewType: 'Charts',
      showCharts: false,
      selectedMerchcat: [],
      quarterOptions: [
        {
          value: '1',
          label: 'Q1',
        },
        {
          value: '2',
          label: 'Q2',
        },
        {
          value: '3',
          label: 'Q3',
        },
        {
          value: '4',
          label: 'Q4',
        },
      ],
      sortOptions: SORT_BY_PROMO,
      sortBy: []
    };
  }

  componentDidMount() {
    this.getExecutiveData();
    this.props.getCategoryList();
    this.props.actionGetCycles();
    // this.props.actionGetConsolidated({
    //   view: CONSOLIDATED,
    //   type: PROMO,
    //   level: YTD,
    //   value: [],
    // });
  }
  componentDidUpdate(oldProps){

    if(!_.isEqual(this.props.merchcatValues, oldProps.merchcatValues)){
      this.setState({selectedMerchcat: this.props.merchcatValues})
    }

  }
  changeView = (params) => {
    if(params.hasOwnProperty('type')){
      switch (params.type) {
        case OVERALL:
          params.sortOptions = SORT_BY_OVERALL;
          break;
        case PROMO:
          params.sortOptions = SORT_BY_PROMO;
          break;
        case OPTIMIZED:
          params.sortOptions = SORT_BY_PLANNED_OPTIMIZED;
          break;
  
        default:
          break;
      }
    }
    params.sortBy = [];
    this.setState(params, () => {
      this.getExecutiveData();
    });
  };
  requestedParams = () => {
    let params = {
      view: this.state.view,
      type: this.state.type,
      level: this.state.level,
      value: this.state.level === YTD ? [] : this.state.value.map((obj) => obj.value),
    };
    if(this.state.view === VENDOR) {
      params['category']= this.state.selectedMerchcat.map(category => category.value)
    }
    return params;
  };
  getExecutiveData = () => {
    this.props.resetConsolidatedData();
    const requestObj = this.requestedParams();
    switch (this.state.view) {
      case CONSOLIDATED:
        this.props.actionGetConsolidated(requestObj);
        break;
      case BUYER:
        this.props.actionGetBuyerView(requestObj);
        break;
      case CATEGORY:
        this.props.actionGetCategoryView(requestObj);
        break;
      case VENDOR:
        this.props.actionGetVendorView(requestObj);
        break;

      default:
        break;
    }
  };
  updateSelectedOption = (params) => {
    // if (params.hasOwnProperty('level')) {
    //   if (params.level === 'cycle') {
    //     params.value = [];
    //     this.props.actionGetCycles();
    //   }
    // }
    this.setState(params, () => {
      this.getExecutiveData();
    });
  };
  handleSelectedMerchcat = ({ selectedMerchcat }) => {
    this.setState({
      selectedMerchcat, 
    }, () => {
      this.getExecutiveData()}
      );
  };
  changeLevel = (params) => {
    if (params.level === 'cycle') {
      params.value = this.props.cycles
      // if(this.state.level === QUARTER && this.state.value.length >0){
      // }else if(this.state.value.length === 0){
      //   params.value = this.props.cycles;
      // }
      // if (this.props.cycles.length === 0) {
      //   this.props.actionGetCycles();
      // }
    } else if (params.level === 'quarter') {
      params.value = this.state.quarterOptions
      // params.value = [];
      // if(this.state.level === CYCLE && this.state.value.length >0){
      // }else if(this.state.value.length === 0){
      //   params.value = this.state.quarterOptions
      // }
    }
    this.setState(params, () => {
      // if (params.level === 'YTD') {
        this.getExecutiveData();
      // }
    });
  };

  handleToggleChange = (value) => {
    // this.setState({ showCharts: !this.state.showCharts });
    this.setState({ viewType: value });
  };

  sortBy = (selectedObj) => {
    const sortData = selectedObj[0].label.split(' ');
    const sortingKey = sortData[1].toLowerCase();
    const sortBy = sortData[0] === 'Increasing' ? 'asc' : 'desc';
    const data = _.orderBy(
      this.props.consolidatedData,
      [
        function (o) {
          const value = o[`${sortingKey}`].change;
          return value ? parseFloat(o[`${sortingKey}`].change) : 0;
        },
      ],
      [`${sortBy}`]
    );
    this.props.actionUpdateSorting(data);
  };
  renderSwitchView = () => {
    switch (this.state.view) {
      case CONSOLIDATED:
        return (
          <>
            <ConsolidateView
              view={this.state.view}
              consolidatedData={this.props.consolidatedData}
              viewType={this.state.viewType}
              type={this.state.type}
            />
          </>
        );
        break;
      case VENDOR:
        return (
          <>
            <VendorView
              view={this.state.view}
              consolidatedData={this.props.consolidatedData}
              viewType={this.state.viewType}
              type={this.state.type}
            />
          </>
        );
        break;
      case CATEGORY:
        return (
          <>
            <CategoryView
              view={this.state.view}
              consolidatedData={this.props.consolidatedData}
              viewType={this.state.viewType}
              type={this.state.type}
            />
          </>
        );
        break;
      case BUYER:
        return (
          <>
            <BuyerView
              view={this.state.view}
              consolidatedData={this.props.consolidatedData}
              viewType={this.state.viewType}
              type={this.state.type}
            />
          </>
        );
        break;

      default:
        break;
    }
  };
  render() {
    const { consolidatedData } = this.props;
    
    return (
      <PageLoader loader={this.state.loader || this.props.loader}>
        <PageWrapper>
          <div className="row">
          <div className="col-4">
            <h4 className="promoHeading mb-1 color-blue">
              <BreadCrumb root="" child="Executive Summary" />
            </h4>
          </div>
          <div className="col-6"></div>
          {/* {this.props.ty ? (
            <><div className="col-2">
            <h4 className="promoHeading mb-1 color-blue">
            YTD : <span className="data-upload-label">&nbsp;&nbsp;{this.props.ty}</span>
              </h4>
            </div></>
          ):null} */}
          

          </div>
          
          <hr />
          <DashboardHeader
            view={this.state.view}
            type={this.state.type}
            level={this.state.level}
            changeView={this.changeView}
          />
          <hr className="dotted-line" style={{ marginBottom: '20px' }} />
          <FiletrOptions
            level={this.state.level}
            view={this.state.view}
            value={this.state.value.length > 0 ? this.state.value : ( this.state.level === QUARTER ? this.state.quarterOptions :this.props.cycles) }
            selectedOption={this.updateSelectedOption}
            changeLevel={this.changeLevel}
            quarterOptions={this.state.quarterOptions}
            cycleOptions={this.props.cycles}
            viewType={this.state.viewType}
            handleToggleChange={this.handleToggleChange}
            sortByChange={this.sortBy}
            handleSelectedMerchcat={this.handleSelectedMerchcat}
            selectedMerchcat={this.state.selectedMerchcat}
            merchcatValues={this.props.merchcatValues}
            sortByOptions={this.state.sortOptions}
            sortBy={this.state.sortBy}
            ty={this.props.ty}
          />
         
          {this.props.consolidatedData.length > 0 ? this.renderSwitchView() : (<><div className="text-center alert alert-primary">No Data to show</div></>)}
          {/* {
            consolidatedData.length >0 ? (
              <>
              {!this.state.showCharts ? (
                <>
                  <Chart
                    chartData={this.props.consolidatedData}
                    buyerData={this.props.buyerData}
                    vendorData={this.props.vendorData}
                    categoryData={this.props.categoryData}
                  />
                </>
              ) : !isUndefined(consolidatedData) ? (
                consolidatedData.length > 0 ? (
                  <DashboardTable view={this.state.view} tableData={this.props.consolidatedData} />
                ) : (
                  ''
                )
              ) : (
                <div className="text-center alert alert-primary">No Data to show</div>
              )}
              </>
            ) : (<div className="text-center">No Data to show</div>)
          } */}
        </PageWrapper>
      </PageLoader>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cycles: state.dashboardData.cycles,
    quarter: state.dashboardData.quarter,
    consolidatedData: state.dashboardData.consolidatedData,
    categoryData: state.dashboardData.categoryData,
    vendorData: state.dashboardData.vendorData,
    buyerData: state.dashboardData.buyerData,
    loader: state.dashboardData.loader,
    merchcatValues: state.dashboardData.merchcatValues,
    ty: state.dashboardData.ty,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionGetCycles: (val) => dispatch(actionGetCycles(val)),
    actionGetQuarters: (value) => dispatch(actionGetQuarters(value)),
    actionGetConsolidated: (val) => dispatch(actionGetConsolidated(val)),
    actionGetBuyerView: (val) => dispatch(actionGetBuyerView(val)),
    actionGetVendorView: (val) => dispatch(actionGetVendorView(val)),
    actionGetCategoryView: (val) => dispatch(actionGetCategoryView(val)),
    actionUpdateSorting: (val) => dispatch(actionUpdateSorting(val)),
    getCategoryList: val => dispatch(getCategoryList(val)),
    resetConsolidatedData:val =>dispatch(resetConsolidatedData(val))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
