import axios from 'axios';
import  environment from '../environments/environment';

const setAuthToken = (token,frmconf) => {
  //debugger
  if (token && !frmconf) {
    // Apply to every request
    axios.defaults.headers.common['token'] = token;
  }else if(token && !!frmconf){
    //debugger
    axios.defaults.headers.common['token'] = environment.joToken;// frmconf.joToken;
  } else {
    // Delete auth header
    delete axios.defaults.headers.common['token'];
  }
};

export default setAuthToken;
