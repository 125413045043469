import React from "react";
import _ from 'lodash';

const Square = props =>{
    return(
        <React.Fragment>
        <div className="flex-container" style={{ marginLeft: '-14px' }}>
        {_.range(1, 21).map((obj, index) => (
          <>
            <button
              className={
                props.selectedCycles.indexOf(obj) !== -1
                  ? 'btn btn-primary iconbutton download-btn'
                  : 'btn  iconbutton download-btn'
              }
              style={{ marginRight: '-12px' }}>
              {obj}
            </button>
          </>
        ))}
      </div>
        </React.Fragment>
    )
};

export default Square;