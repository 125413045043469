import React,{ useState, useEffect } from "react";
import MultiSelectCheckboxes from "../MultiSelectCheckboxes";

const Filters = (props) => {
    useEffect(() => {
        props.updateState({vendor: props.vendorData})
    },[props.vendorData])
    let Constants = [
        {
            labelName: "Fiscal Year",
            placeholderButtonLabel:"Select Year",
            options: props.fyearData,
            value: props.fYearSelected,
            mappingKey: "fYear",
            isMulti: false,
        },
        {
            labelName: "Quarter",
            placeholderButtonLabel:"Select Quarter",
            options: props.quarterData,
            value: props.quarterListSelected,
            mappingKey: "quarter",
            isMulti: false,
        },
        {
            labelName: "Buyer",
            placeholderButtonLabel:"Select Buyer",
            options: props.buyerData,
            value: props.buyerSelected,
            mappingKey: "buyer",
            isMulti: false,
        },
        {
            labelName: "Category",
            placeholderButtonLabel:"Select Category",
            options: props.categoryData,
            value: props.categorySelected,
            mappingKey: "category",
            isMulti: false,
        },
        {
            labelName: "Vendor",
            placeholderButtonLabel:"Select Vendor",
            options: props.vendorData,
            // value: props.vendorData,
            value: props.vendorSelected,
            mappingKey: "vendor",
            isMulti: true,
            defaultSelectedOptions:true
        },
        {
            labelName: "PlanID",
            placeholderButtonLabel:"Select Plan",
            options: props.planIDData,
            value: props.planIDSelected,
            mappingKey: "planID",
            isMulti: false,
        },
    ]
    return (
        <>
         {Constants.map(filter =>
            <div className="col-md-2">
                 <div className="form-group">
                    <label className="form-label"><b>{filter.labelName}</b></label>
                    <MultiSelectCheckboxes
                        placeholderButtonLabel={filter.placeholderButtonLabel}
                        options={filter.options}
                        value={filter.value}
                        mappingKey={filter.mappingKey}
                        onMenuCloseApiFun={props.onMenuCloseApiFun}
                        updateState={props.updateState}
                        isMulti={filter.isMulti}
                        defaultSelectedOptions={props.defaultSelectedOptions}
                    />
                </div>
            </div>
               )}            
        </>
    );
}
export default Filters;