import React, {Component} from "react";
import CalendarWrapperDataDiv from "./calendarWrapperDataDiv";
import moment from 'moment';
class calendarWrapperBlocks extends Component {
    constructor(props) {
        super(props);

        this.state = {
            styleMargin: [],
            renderList:[]
        };
    }

    componentDidUpdate(old) {
        if (old.styleMargin !== this.props.styleMargin) {
            this.setState({styleMargin: this.props.styleMargin});
        }
        /* if(old.calendarDataArr !== this.props.calendarDataArr || old.calendarWrapperArr !== this.props.calendarWrapperArr){
            console.log(this.props.calendarDataArr ,this.props.calendarWrapperArr)
            let renderList = [];
            this.props.calendarDataArr.forEach(obj=>{
                if(this.props.calendarWrapperArr.length > 0){
                    const startDate = this.props.calendarWrapperArr[0].start_date;
                    const endDate = this.props.calendarWrapperArr[this.props.calendarWrapperArr.length -1].end_date;
                    // if(obj.)
                    let start_date = moment(startDate).format()
                    ,end_date = moment(endDate).format(),

                    isStartSameOrAfter = moment(start_date).isSameOrAfter(obj.start_date),
                    isStartSameOrBefore = moment(start_date).isSameOrBefore(obj.end_date),

                    isEndSameOrAfter = moment(end_date).isSameOrAfter(obj.start_date),
                    isEndSameOrBefore = moment(end_date).isSameOrBefore(obj.end_date);
                    if((isStartSameOrAfter&&isStartSameOrBefore) || (isEndSameOrAfter&&isEndSameOrBefore)){
                        renderList.push(obj)
                    }
                 console.log((isStartSameOrAfter&&isStartSameOrBefore),(isEndSameOrAfter&&isEndSameOrBefore) ,this.props.calendarDataArr.map(obj=> obj.start_date),this.props.calendarDataArr.map(obj=> obj.end_date),obj.start_date,obj.end_date, startDate ,endDate ,this.props.calendarDataArr)
                }
            })
            this.setState({
                renderList: renderList
            })
        } */
    }

    render() {
        let renderBlocks = this.props.calendarDataArr && this.props.calendarDataArr.length && this.props.calendarWrapperArr.length && this.state.styleMargin && this.state.styleMargin.length && this.props.calendarDataArr.length === this.state.styleMargin.length;
        let renderList = [];
        this.props.calendarDataArr.forEach((obj) => {
            if (this.props.calendarWrapperArr.length > 0) {
              const calendarStrtDt = this.props.calendarWrapperArr[0].start_date;
              const calendarEndDt = this.props.calendarWrapperArr[
                this.props.calendarWrapperArr.length - 1
              ].end_date;
              const { start_date: campaignStrtDt, end_date: campaignEndDt } = obj;
      
              const isCampaignInBetweenCalendar =
                moment(campaignStrtDt).isBetween(calendarStrtDt, calendarEndDt, null, '[]') ||
                moment(campaignEndDt).isBetween(calendarStrtDt, calendarEndDt, null, '[]');
      
              const isCalendarInBetweenCampaign =
                moment(calendarStrtDt).isBetween(campaignStrtDt, campaignEndDt, null, '[]') &&
                moment(calendarEndDt).isBetween(campaignStrtDt, campaignEndDt, null, '[]');
      
              if (isCampaignInBetweenCalendar || isCalendarInBetweenCampaign) {
                renderList.push(obj);
              }
            }
          });

        return (
            <div>
                <div
                    style={{
                    maxHeight: "1rem",
                    borderBottom: "1px solid #E0E1E2",
                    marginTop: "5px"
                }}/>
                <div
                    style={renderList.length ===1 ? {
                        maxHeight: "14.9rem",
                        width: "100%"
                    } : {
                        maxHeight: "14.9rem",
                        overflow: "auto",
                        width: "100%"
                    }}>
                    <div
                        className="scrollCalender"
                        style={renderList.length === 1 ? {
                            maxHeight: "14.9rem",
                            width: "100%"
                        } : {
                            maxHeight: "14.9rem",
                            overflow: "auto",
                            width: "100%"
                        }}>
                        {/* -----------------number of rows per data-------------- */}
                        {renderBlocks && renderList.length > 0
                            ? (this.props.calendarDataArr.map((data, indexRow) => {
                                return (
                                    <div
                                        key={indexRow}
                                        className={this.state.styleMargin[indexRow].flexBasisInt && this.state.styleMargin[indexRow].flexBasisInt > 0
                                        ? "RowWrapper"
                                        : ""}>
                                        <div
                                            className="RowBodyWrapper"
                                            style={{
                                            width: "100%"
                                        }}>
                                            {/* --------------number of weeks per row------------- */}
                                            <div className="BgWrapper">
                                                {this
                                                    .props
                                                    .calendarWrapperArr
                                                    .map((data, indextr) => {
                                                        return (
                                                            <div
                                                                key={indextr}
                                                                style={{
                                                                width: "25%"
                                                            }}
                                                                className="CellWrapper row-cell-wrapper">
                                                                <p className="calendarWeekDate"></p>
                                                            </div>
                                                        );
                                                    })}
                                            </div>
                                            {/* --------------------Promotion Div----------------------- */}
                                            {this.state.styleMargin[indexRow].flexBasisInt && this.state.styleMargin[indexRow].flexBasisInt > 0
                                                ? (
                                                    <div
                                                        className="RowContent"
                                                        style={{
                                                        width: "100%"
                                                    }}>
                                                        <div>
                                                            {this.state.styleMargin[indexRow].flexBasisInt && this.state.styleMargin[indexRow].flexBasisInt > 0
                                                                ? (
                                                                    <div>
                                                                        <CalendarWrapperDataDiv
                                                                            indexRow={indexRow}
                                                                            calendarDataArr={this.props.calendarDataArr}
                                                                            styleMargin={this.state.styleMargin[indexRow]}
                                                                            showRecommendedPromoToolTip={this.props.showRecommendedPromoToolTip}
                                                                            tooltipId={this.props.tooltipId}
                                                                            toogleRecommendedPromoToolTip={this.props.toogleRecommendedPromoToolTip}
                                                                            markdownlinkShow={this.props.markdownlinkShow}
                                                                            showPromotionMain={this.props.showPromotionMain}
                                                                            showPromotionMainWithValues={this.props.showPromotionMainWithValues}
                                                                            info={this.props.calendarDataArr[indexRow]}
                                                                            onTabClick={this.props.onTabClick}
                                                                            getRecommendedData={this.props.getRecommendedData}
                                                                            toogleRecommendedPromoMarkdownToolTip={this.props.toogleRecommendedPromoMarkdownToolTip}
                                                                            getRecommendedMarkData={this.props.getRecommendedMarkData}
                                                                            promoButtonClick={this.props.promoButtonClick}
                                                                            promoDetailsHandler={this.props.promoDetailsHandler}
                                                                            showRecommendedPromoMarkToolTip={this.props.showRecommendedPromoMarkToolTip}
                                                                            tooltipMarkId={this.props.tooltipMarkId}/>
                                                                    </div>
                                                                )
                                                                : (
                                                                    <div className="Row" height="200"></div>
                                                                )}
                                                        </div>
                                                    </div>
                                                )
                                                : null}
                                        </div>
                                    </div>
                                );
                            }))
                            : this.props.showLoading === true
                                ? (
                                    <div
                                        className="Row"
                                        height="200"
                                        align="middle"
                                        style={{
                                        justifyContent: "center",
                                        margin: "2rem 0"
                                    }}>
                                        Please wait while data is loading...
                                    </div>
                                )
                                : (
                                    <div
                                        className="Row"
                                        height="200"
                                        align="middle"
                                        style={{
                                        justifyContent: "center",
                                        margin: "2rem 0"
                                    }}>
                                        No data to show!
                                    </div>
                                )}
                    </div>
                </div>
            </div>
        );
    }
}

export default calendarWrapperBlocks;
