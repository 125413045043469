import {
  SET_LOADER,
  SET_SKU_DATA,
  SAVE_SKU_MASTER,
  RESET_SKU_MASTER,
  RESET_FILTERS_DATA,
  SET_FILTERS_DATA,
  SET_BRAND_DATA
} from "./types";
import {
  fetchModifySkuData,
  postSkuMasterSave,
  downloadSkuMasterData
} from "../apis/skuManagement.api";
import environmentModule from "../environments/environment";
import { toast } from "react-toastify";
import { fetchHierarchyData } from "../apis/common.api.";
import _ from "lodash";

export const getSkuDataTable = value => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await fetchModifySkuData(value);
    const { data, totalCount } = result;
    if (data) {
      const sortedData = data.sort(function (a, b) { 
        return b.tag < a.tag ? -1
             : b.tag > a.tag ? 1 
             : 0;                  
    });
      const formattedSkuData = sortedData.map(
        ({
          hierarchyLevel7: skuid = "-",
          vendor = "-",
          description = "-",
          brand = "-",
          lyRevenue = "-",
          tyRevenue = "-",
          tag = "Active",
          lyfunding,
        }) => {
          return {
            skuid,
            description,
            vendor,
            brand,
            lyRevenue,
            tyRevenue,
            tag,
            lyfunding,
            oldLyFunding: lyfunding
          };
        }
      );
      dispatch({
        type: SET_SKU_DATA,
        payload: { data: formattedSkuData, totalCount }
      });
      dispatch({ type: SET_LOADER, payload: false });
    }
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const setSkuData = value => dispatch => {
  dispatch({
    type: SET_SKU_DATA,
    payload: value
  });
};

export const actionSaveUpdatedSkuMaster = data => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await postSkuMasterSave(data);
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionResetSkuMaster = () => dispatch => {
  dispatch({ type: RESET_SKU_MASTER });
};

export const actionDownloadSkuMaster = reqObj => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await downloadSkuMasterData(reqObj);
    window.open(result.url, "_self");
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};


export const setFiltersData = (val) => dispatch => {
  dispatch({ type: SET_FILTERS_DATA , payload: val  });
};

export const resetFiltersData = (val) => dispatch => {
  dispatch({ type: RESET_FILTERS_DATA , payload: val  });
};


export const actionGetBrands = reqObj => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await fetchHierarchyData('brand', reqObj);
    const temp = result.filter(val => {
      return val.length>0;
    })
    const data = temp.map(val => {
      return {label: val, value: val}
    });
    dispatch({type: SET_BRAND_DATA, payload: data});
    dispatch({ type: SET_LOADER, payload: false });

  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
}

export const actionGetVendors = reqObj => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await fetchHierarchyData('vendor', reqObj);
    const temp = result.filter(val => {
      return val.length>0;
    })
    const data = temp.map(val => {
      return {label: val, value: val}
    });
    dispatch({type: 'SET_VENDOR_DATA', payload: data});
    dispatch({ type: SET_LOADER, payload: false });

  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
}
