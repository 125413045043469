import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
// import Modal from "react-modal";
import { Modal, Button } from 'react-bootstrap';
import { AgGridReact } from 'ag-grid-react';
import TableWrapper from '../../../common/TableWrapper';
import { getSkuDataTable, setSkuData } from '../../budgetPlanAction';
// import { Pagination } from "antd";
import { LoadingDiv, FooterBtnDiv } from './styled';
import { map } from '../../../common/lodash-functions';
import PageLoader from '../../../../utils/Loader';
import _ from 'lodash';
import { BudgetPlanningGA } from '../../../../common/GAUtils';
const ModifySkuModal = ({
  loading,
  skuData = {},
  getSkuData,
  merchcat,
  vendor,
  isVisible,
  setIsVisible,
  setSkuData,
  handleModifySaveClick,
  dselectedSKU
}) => {
  // const { pageGroupNo, pageGroupSize, totalCount, data = [] } = skuData;
  const { data = [] } = skuData;
  const [colDefs, setColDefs] = useState(columnDefs);
  const [rowData, setRowData] = useState([]);
  const [deselectedSku, setDeselectedSku] = useState(dselectedSKU);

  // const paginationCount = parseInt(parseInt(totalCount) / pageGroupSize); // no of pages to show in ui

  // useEffect(() => {
  //   getSkuData({
  //     merchcat,
  //     vendor,
  //     pageGroupNo,
  //     pageGroupSize,
  //   });
  // }, [pageGroupNo]);

  useEffect(() => {
    getSkuData({
      merchcat,
      vendor,
      screenName: "budget-planning"
    });
    if (isVisible) {
      document.body.style.position = 'fixed';
    }
    return () => {
      document.body.style.position = '';
    };
  }, []);
  useEffect(() => {
    setRowData(data);
  }, [skuData]);

  function onGridReady(params) {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    // this.gridColumnApi.autoSizeColumns();
    this.gridApi.selectAll();
    if (skuData.deselectedSku.length > 0) {
      const deselectedSkuId = skuData.deselectedSku;
      this.gridApi.forEachNode(function(node) {
        if (deselectedSkuId.includes(`${node.data.skuid}`)) {
          node.setSelected(false);
        }
      });
    }
  }
  const closeModal = isSave => {
    //reset the redux data on modal close

    if (isSave) {
      submitGA("Click","save sku list for finalize budget");
      handleModifySaveClick();
      setSkuData({
        data: [],
        // pageGroupNo: 1,
        // totalCount: 0,
        // pageGroupSize: 500,
        deselectedSku: _.map(deselectedSku, 'skuid')
      });
    }
    setIsVisible(false);
  };
  const onSelectionChanged = evt => {
    const { data: allData } = skuData;
    var selectedRows = evt.api.getSelectedRows();
    let deselectedSkuData = allData.filter(_o => !selectedRows.includes(_o));
    if (dselectedSKU.length) deselectedSkuData = [...deselectedSkuData, ...dselectedSKU];

    if(deselectedSkuData.length > 0){

      submitGA("Click","Deselected skus from sku list ");
    }
    //on selection changed triggers , onGridApiReady too. ideally this should not happen
    setDeselectedSku(deselectedSkuData);
  };

  const defaultColDef = {
    filter: true,
    sortable: true,
    resizable: true
  };
  const submitGA =(action,label)=>{
    BudgetPlanningGA({ action, label});
  }
  return (
    <>

      <Modal show={isVisible} size="lg" onHide={closeModal}>
        <Modal.Header closeButton>
          <Modal.Title>Select SKU </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <PageLoader loader={loading}>
          <div className="row-selection">
            <div id="modifySkuGrid" className="ag-theme-balham" style={{ width: '100%' }}>
              {loading ? null : (<AgGridReact
                onGridReady={onGridReady}
                columnDefs={colDefs}
                rowData={rowData}
                onSelectionChanged={onSelectionChanged}
                rowSelection="multiple"
                defaultColDef={defaultColDef}
                suppressRowClickSelection={true}
                floatingFilter={true}
                enableCellTextSelection={true}
              />)}
            </div>
            </div>
          </PageLoader>
        </Modal.Body>
        <Modal.Footer>
          <button
            class="btn btn-primary saveButton my-2 text-center"
            onClick={() => closeModal(true)}>
            Save
          </button>
        </Modal.Footer>
      </Modal>

    {/*     <Modal isOpen={isVisible} style={customStyles} onRequestClose={closeModal}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <p><b>Select SKU</b></p>
        <i className="fa fa-times fa-1x" onClick={() =>closeModal(false)}></i>
      </div>
        {loading ? (
          <LoadingDiv>loading</LoadingDiv>
        ) : (
          <div className="row-selection">
          <div
                  id='modifySkuGrid'
                  className='ag-theme-balham'
                  style={{ width: '100%' }}>
            <AgGridReact
            onGridReady={onGridReady}
            columnDefs={colDefs}
            rowData={rowData}
            onSelectionChanged={onSelectionChanged}
            rowSelection="multiple"
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            floatingFilter= { true }
            enableCellTextSelection={true}
          />
          </div>
          </div>

        )}
        <FooterBtnDiv>
          <button
            class="btn btn-primary saveButton my-2 text-center"
            onClick={() =>  closeModal(true)}
          >
            Save
          </button>
        </FooterBtnDiv>
    </Modal> */}
    </>
  );
};

const mapStateToProps = state => {
  console.log(state.budgetPlan.loading)
  return {
    loading: state.budgetPlan.loading,
    skuData: state.budgetPlan.skuData
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSkuData: val => dispatch(getSkuDataTable(val)),
    setSkuData: value => dispatch(setSkuData(value))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModifySkuModal);

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minWidth: '70vw',
    overflow: 'hidden',
    height: '500px'
  }
};
const columnDefs = [
  {
    headerName: 'SKU ID',
    field: 'skuid',
    headerCheckboxSelection: true,
    headerCheckboxSelectionFilteredOnly: true,
    checkboxSelection: true
  },
  {
    headerName: 'Description',
    field: 'description'
  },
  {
    headerName: 'AH6',
    field: 'ah6'
  },
  {
    headerName: 'Vendor',
    field: 'vendor'
  },
  {
    headerName: 'Ref.SKU',
    field: 'refSKU',
    width:100
  }
  // {
  //   headerName: "Tag",
  //   field: "tag",
  // },
];
