import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import isEmpty from "lodash/isEmpty";
import { ModalBodyStyled } from "./styled";

function UserInputNodal(props) {
  return (

    <Modal show={props.isShow} onHide={() => { }} centered>
      <ModalHeader/>
      <ModalBodyStyled>
        <ModalBody>
          <div>
            <label>{props.inputLabel}</label>
            <input placeholder={props.inputPlaceholder} value={props.planName} onChange={props.handleChange} />
          </div>
        </ModalBody>
      </ModalBodyStyled>
      <ModalFooter>
        <button onClick={() => props.handleUserInputModel(false)} className="btn btn-primary saveButton">No</button>
        <button 
          onClick={() => props.handleUserInputModel(true)} 
          className="btn btn-primary saveButton"
          disabled={props.planName.trim().length === 0}>
            Yes
        </button>
      </ModalFooter>
    </Modal>
  );
}
export default UserInputNodal