import React, { Component } from 'react';
import { withRouter, Link, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/auth';
import { clearCurrentChartdata } from '../../actions/chart';
import { someAction } from '../../actions/auth';
import './headerStyle.css';
class Header extends Component {
  constructor(props) {
    super();
  }
  onLogout = e => {
    this.props.history.push('/QuIQ');
  };

  onLogoutClick() {
    this.props.history.push('/login');
  }

  render() {
    const { firstName, lastName } = this.props.userInfo;
    return (
      <div className="headerContainer">
        <nav className="navbar navbar-expand-sm navbar-light bg-light" style={{ height: '75px' }}>
          <div className="navbar-brand " id="promoColor">
            Promo$mart
          </div>

          <button className="navbar-toggler" data-toggle="collapse" data-target="#navbarMenu">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbar-menu-right">
            
            <ul className="ml-auto">
              {/* <li className="nav-item">
                <span href="#">
                  <div className="btn HeaderNamebutton"></div>
                </span>
              </li> */}
              <li><div className="row">
              <div className="col-8 text-center header-username">{firstName} &nbsp; {lastName}</div>
              <div className="col-2 text-center"><Link to="/vis/support">
                  <i className="fa fa-question-circle iconHeader" aria-hidden="true"></i>
                </Link></div>
              <div className="col-2 text-center"><i
                    onClick={() => this.props.logoutUser()}
                    className="fa fa-sign-out iconHeader"
                    aria-hidden="true"></i></div>
            </div></li>
            <li><div ><span className="data-upload-label">Last Data Uploaded on {this.props.userInfo.updatedAt}</span></div></li>
              {/* <li className="nav-item">
              <span href="#">
                <p>
                  {' '}
                  <p style={{marginTop:'30px',marginRight:'-220px'}}><span className="data-upload-label">Last Data Uploaded on {this.props.userInfo.updatedAt}</span></p>
                </p>
              </span>
            </li>
              <li className="nav-item">
                <span href="#">
                  <p className="header-username">
                    {' '}
                    {firstName} &nbsp; {lastName}
                  </p>
                </span>
              </li>
              {/* <li title="Settings" className="nav-item">
                <a href="#">
                  <i className="fa fa-cog iconHeader" aria-hidden="true"></i>
                </a>
              </li> */}
              {/* <li title="Support" className="nav-item">
                <Link to="/vis/support">
                  <i className="fa fa-question-circle iconHeader" aria-hidden="true"></i>
                </Link>
              </li> */}
              {/* <li
                title="Logout"
                // onClick={() => {
                //   this.onLogoutClick();
                // }}
                className="nav-item">

                  <i
                    onClick={() => this.props.logoutUser()}
                    className="fa fa-sign-out iconHeader"
                    aria-hidden="true"></i>
              </li> */}
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}

Header.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  authReducer: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => ({
  authReducer: state.authReducer,
  userInfo: state.userManagementData.userInfo,
});

export default connect(mapStateToProps, {
  logoutUser,
  clearCurrentChartdata,
  someAction
})(withRouter(Header));
