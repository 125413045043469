import { config } from '../utils/config';
import setAuthToken from '../utils/setAuthToken';
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  LOGOUT_CURRENT_USER,
  SOME_ACTION ,
  USER_LOGOUT
} from './types';
import axios from 'axios';
import environment from './../environments/environment';
import {
  logout
} from '../apis/common.api.';

// Login - Post User Token
export const loginUser = userData => dispatch => {
  axios
    .post(`${environment.baseUrl}/user/login`, userData)
    .then(res => {
      //
      // Save to localStorage
      const { token } = res.data.data;

      // Set token to ls

      localStorage.setItem('token', token);

      // Set token to Auth header
      setAuthToken(token);
      dispatch({
        type: SET_CURRENT_USER,
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message
      });
    });
};

// Login - Post User Token
export const hidrateUser = () => dispatch => {
  if (localStorage.getItem('token') && localStorage.getItem('user')) {
    dispatch({
      type: SET_CURRENT_USER,
      payload: {
        token: localStorage.getItem('token'),
        user: JSON.parse(localStorage.getItem('user'))
      }
    });
  } else {
    dispatch({
      type: LOGOUT_CURRENT_USER
    });
  }
};

export const logoutUser = token => dispatch => {
  const result =  logout();
  dispatch({
    type: USER_LOGOUT
  });
  // window.open('https://login.microsoftonline.com', '_self')
};

export const someAction = data => (dispatch, getState) => {
  dispatch({
    type: SOME_ACTION,
    pathname: getState().router.pathname
  });
};
