import React, { useState, useEffect } from "react";
import { withRouter ,Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import _ from "lodash";
import TableWrapper from "../../../common/TableWrapper";
import { BudgetPlanTableTitleContent } from "./styled";
import EditBudget from "../../../../common/EditBudgetComponent/index";
import isEmpty from "lodash/isEmpty";
import { convertMillionsFormatter } from "../../../../utils/AgGridFormatters";
import { getDisStateFrmStatus } from "../../../../utils/helperFunctions"
import { BudgetPlanningGA } from '../../../../common/GAUtils';
import OptimizationLevelSelector from './optimizationLevelSelector';
import ExpandCollapse from './expandCollapseComponent'; 
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';
//table column headers
const totalColDefs = [
  {
    headerName: "Vendor",
    field: "vendor",
  },
  {
    headerName: "Brand",
    field: "brand",
  },
  {
    headerName: "Budget Utilised (LLY)",
    field: "budgetUtilisedLLY",
  },
  {
    headerName: "Coupon Redemptions (LLY)",
    field: "tradeSpendLLY",
  },
  {
    headerName: "Insertion Fee (LLY)",
    field: "insertionFeeLLY",
  },
  {
    headerName: "Budget Utilised (LY)",
    field: "budgetUtilisedLY",
  },
  {
    headerName: "Coupon Redemptions (LY)",
    field: "tradeSpendLY",
  },
  {
    headerName: "Insertion Fee (LY)",
    field: "insertionFeeLY",
  },
  {
    headerName: "Budget Available)",
    field: "budgetAvailable",
  },
  {
    headerName: "Budget Utilised (TY)",
    field: "budgetUtilisedTY",
  },
  {
    headerName: "Brand Optimization",
    field: "brandOptimization",
  },
];
const VendorPlanHistoryTable = (props) => {
  const { vendorHistoryData, optimiseBudget, handleUpdateConstraints, planId ,optimizationLink, handleExpandCollapse, handleBrandBudgetModification, handleVendorBudgetModification, brandLevelData} = props
  // let tableData = _.map(vendorHistoryData, item => {
   
  //   item.calculateTotal = handleBudgetChange;
    
  //   return item;
  // });
  const [colDefs, setColDefs] = useState([]);
  const [griData, setGridData] = useState([]);
  const [totalGridData, setTotalGridData] = useState(false);
  const [availableBudget, setAvailableBudget] = useState([]);
  const [vendorHistoryDataCopy, setVendorHistoryDataCopy] = useState(vendorHistoryData);
  const [optimizeLevel, setOptimizeLevel] = useState({});
  const [gridApi, setGridApi] = useState({});
  const [totalgridApi, setTotalGridApi] = useState({});

  useEffect(() => {
    let formattedData = formatNumber(vendorHistoryData);

    setColDefs(columnDef);
    setGridData(formattedData);
   
    if(vendorHistoryData.length>0){
      updateTotalCount(false);

      let itemsToUpdate = [];
    if (Object.keys(gridApi).length > 0) {
      gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index){
        let data = rowNode.data;
        itemsToUpdate.push(data);
      });
      gridApi.updateRowData({ update: itemsToUpdate });
    }
    }
  }, [vendorHistoryData]);

  useEffect(() => {
    updateTotalCount(true);
  }, [vendorHistoryDataCopy]);

  useEffect(() => {
    const budgetAvailable = brandLevelData.map(row => {return row.budgetAvailable});
    setAvailableBudget(budgetAvailable);
    updateTotalCount(true);
  },[brandLevelData]);

  const handleBudgetChange = (data) => {
    submitGA("Click","Budget available changed in vendor plan history table");
    const { currentRow, field, value, rowData} = data;
  
    let temp = [];
    if (currentRow.brand !== 'ALL BRANDS') {

      temp = handleBrandBudgetModification(data);
    
    } else {
      temp = handleVendorBudgetModification(data);
    }
    props.setIsDisableOptimise(false)
    setVendorHistoryDataCopy(temp);
  }

  const handleOptimizeLevelChange = dataObj => {
    const { rowData, selection } = dataObj;
    let temp = optimizeLevel;
    if(temp[rowData.vendor]) {
      temp[rowData.vendor].isvendorlevel = !selection;
    } else {
      temp[rowData.vendor] = {
        vendor: rowData.vendor,
        isvendorlevel: !selection
      }
    }

    setOptimizeLevel(temp);
  }

  const handleCollapse = (data, tableData, type) => {
    let optimizationLevel = optimizeLevel;
    handleExpandCollapse(data, tableData, type, optimizationLevel)
  }
  const columnDef = [
    {
      headerName: "Vendor",
      field: "vendor",
      cellRenderer: 'expandCollapse',
      cellRendererParams: {
        handleExpandCollapse: handleCollapse,
      },
      cellClass: 'cellPadding',
      tooltipField: "vendor"
    },
    {
      headerName: "Brand",
      field: "brand",
      tooltipField: "brand"
    },
    {
      headerName: "Budget Utilised (LLY)",
      field: "budgetUtilisedLLY",
      aggFunc: "budgetUtilisedLLY",
      valueFormatter: params=>{
        if(!params.value && params.value != 0){
           return '-'
         }
     } 
    },
    {
      headerName: "Coupon Redemption (LLY)",
      field: "tradeSpendLLY",
      valueFormatter: params=>{
        if(!params.value && params.value != 0){
           return '-'
         }
     }
    },
    {
      headerName: "Insertion Fee (LLY)",
      field: "insertionFeeLLY",
      valueFormatter: params=>{
        if(!params.value && params.value != 0){
           return '-'
         }
     }
    },
    {
      headerName: "Budget Utilised (LY)",
      field: "budgetUtilisedLY",
      valueFormatter: params=>{
        if(!params.value && params.value != 0){
           return '-'
         }
     }
    },
    {
      headerName: "Coupon Redemption (LY)",
      field: "tradeSpendLY",
      valueFormatter: params=>{
        if(!params.value && params.value != 0){
           return '-'
         }
     }
    },
    {
      headerName: "Insertion Fee (LY)",
      field: "insertionFeeLY",
      valueFormatter: params=>{
        if(!params.value && params.value != 0){
           return '-'
         }
     }
    },
    {
      headerName: "Budget Available(TY)",
      field: "budgetAvailable",
      cellRenderer: "editBudget",
      cellRendererParams: {
        handleBudgetChange,
        isDisabled: getDisStateFrmStatus(props.planStatus)
      }
    },
    {
      headerName: "Budget Utilised (TY)",
      field: "budgetUtilisedTY",
      valueFormatter: params=>{
        if(!params.value && params.value != 0){
           return '-'
         }
     }
    },
    {
      headerName: "Brand Optimization",
      field: "optimizeLevel",
      cellRenderer: "optimizationLevelSelector",
      cellRendererParams: {
        handleOptimizeLevelChange,
      }
    },
    {
      headerName: "total",
      field: "calculateTotal",
      hide: true
    }
  ];
  function onGridReady(params) {
    setGridApi(params.api);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
  }

  function onGridReadyTotal(params) {
    setTotalGridApi(params.api);
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  function formatNumber (arg) {
    let formattedData = [];
    arg.map(data => {
      let formatedRowData = {};
      Object.keys(data).forEach(value => {
        if(typeof data[value] !== "string" && typeof data[value] !== "function" && value !== "budgetAvailable" && typeof data[value] !== "boolean") {
          formatedRowData[value] = convertMillionsFormatter({value:data[value] || 0});
        }
        else {
          value === "budgetAvailable"? formatedRowData[value] = parseFloat(data[value]):
          formatedRowData[value] = data[value];
        }
      }
      );
      formattedData.push(formatedRowData);
    });
    return formattedData;
  }

  function updateTotalCount(isEdit) {
    let total = {};
    let formattedTotal= {};
    // let totalGridData1 = isEdit ? vendorHistoryDataCopy: vendorHistoryData ;
    let totalGridData1 = brandLevelData.length>0?brandLevelData:vendorHistoryData;
    if(totalGridData1.length > 0 ) {
      totalGridData1.map((data, index) => {
      Object.keys(data).forEach(key => {
          if(total[key]) {
            if(typeof data[key] === "string") {
              if(key==="vendor"|| key==="category"|| key==="fYear"){
              total[key] = "TOTAL";
            } else if(key==="brand"){
              total[key] = "";
            }else if(isEdit){
              total[key] = totalGridData[0][key]
            }
            }
            else {
              (isEdit && key === "budgetAvailable" || 0) ? 
              total[key] += brandLevelData[index][key] || 0 :total[key] += data[key]; 
              
            }
          }
          else {
            if(typeof data[key] === "string") {
              if(key==="vendor"|| key==="category" || key==="fYear"){
                total[key] = "TOTAL";
              } else if(key==="brand"){
                total[key] = "";
              } else if(isEdit){
                total[key] = totalGridData[0][key]
              }
            }
            else {
              if(isEdit && key === "budgetAvailable" || 0){
                console.log("Brnad level data",brandLevelData)
                if(brandLevelData.length > 0){
                  total[key] = brandLevelData[index][key] || 0 
                }else{
                  total[key] =  0 
                }
                
              } else{
                if(data && !isNaN(data[key])){
                  total[key] = data[key] || 0;
                }else{
                  total[key] = 0;
                }

              }
                // (isEdit && key === "budgetAvailable" || 0) ? total[key] = brandLevelData[index][key] || 0 :total[key] = data[key];
            }
          }
        });
      });
    }

    Object.keys(total).forEach(value => {
      if(typeof total[value] !== "string" && typeof total[value] !== "function") { // && value !== "budgetAvailable"
        formattedTotal[value] = convertMillionsFormatter({value:total[value]}); 
      }
      else {
        // value === "budgetAvailable"? formattedTotal[value] = Math.round(total[value]):
        formattedTotal[value] = total[value];
      }
    });

    setTotalGridData([formattedTotal]);
   
  }

  const handleOptimizeBudget = budget => {
    submitGA("Click","Optimize budget confirmation modal");
    const dataObj = {
      budget,
      optimizeLevel
    }
    optimiseBudget(dataObj);
  }

  const submitGA =(action,label)=>{
    BudgetPlanningGA({ action, label});
  }
  return (
    <> 
    {!vendorHistoryData.length>0? <p className="data-unavailable">No Data available</p> :
    <div className="vendor-history-table">
      <BudgetPlanTableTitleContent>
        <label className="budget-labels">Available Budget ($)</label>
        <input
          className="form-control catagorySelect"
          placeholder="Enter available budget"
          value={!isEmpty(totalGridData)?totalGridData[0].budgetAvailable : 0 }
          disabled
        />
      </BudgetPlanTableTitleContent>
      <TableWrapper>
      <div style={{ width: '100%' }}>
        <AgGridReact
          singleClickEdit={true}
          headerHeight="60"
          suppressDragLeaveHidesColumns={true}
          suppressHorizontalScroll={false}
          suppressRowTransform={true}
          suppressColumnMoveAnimation={true}
          onGridReady={onGridReady}
          columnDefs={colDefs}
          rowData={griData}
          domLayout="autoHeight"
          frameworkComponents={{
            editBudget: EditBudget,
            optimizationLevelSelector: OptimizationLevelSelector,
            expandCollapse: ExpandCollapse,
          }}
          enableCellTextSelection={true}
          modules={AllCommunityModules}
          // onCellValueChanged={function(params) {
          //     return  params.api.redrawRows()}}
          />
        </div>

      <div className='ag-theme-balham' style={{ width: '100%' ,marginTop: "0px"}}>
      <AgGridReact
        singleClickEdit={true}
        suppressDragLeaveHidesColumns={true}
        suppressHorizontalScroll={false}
        suppressRowTransform={true}
        suppressColumnMoveAnimation={true}
        onGridReady={onGridReadyTotal}
        columnDefs={totalColDefs}
        rowData={totalGridData}
        headerHeight="0"
        domLayout="autoHeight"
        enableCellTextSelection={true}
      />
      </div>
      </TableWrapper>
      
      <div className="text-center">
        <button
          type="button"
          className="btn btn-primary saveButton constraintsButton"
          disabled={props.isDisableOptimise}
          onClick={() => handleUpdateConstraints(availableBudget)}
        >
          Scenario Constraints
        </button>

        <button
          type="button"
          className="btn btn-primary saveButton"
          disabled={props.isDisableOptimise}
          onClick={() => handleOptimizeBudget(availableBudget)}
        >
          Optimize
        </button>
        
        
      </div>
      <div className="spacer"></div>
      {/* <div className="text-center">
      {optimizationLink ? (
        <> <div style={{fontSize: "15px"}}>Please click <a href={optimizationLink} target="_self"><span className="text-primary">here</span></a> after sometime.</div></>
      ) : null}
      </div> */}

      <hr />
    </div>
  }
    </>
  );
};
export default withRouter(VendorPlanHistoryTable);