import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { Row } from 'react-bootstrap';


class DMMLevelTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultColDef: {
                width: 100
            },
        };
    }
    componentDidMount(){
        // this.setWidthAndHeight();
    }
    setWidthAndHeight = ()=> {
        var eGridDiv = document.querySelector('#lyhgrid');
        eGridDiv.style.setProperty('width', "482px");
        eGridDiv.style.setProperty('height', "445px");
        var tGridDiv = document.querySelector('#typgrid');
        tGridDiv.style.setProperty('width', "332px");
        tGridDiv.style.setProperty('height', "445px");
        var tymGridDiv = document.querySelector('#tymgrid');
        tymGridDiv.style.setProperty('width', "330px");
        tymGridDiv.style.setProperty('height', "445px");
    }
    render() {
        let coldef = this.props.DMMcoldef;
        let rowData = this.props.rowData;
        return (
            <div style={{marginTop:'30px'}}>
            <Row >
                <div className="col-5" style={{marginRight:"-38px"}}>
                <div id="defaultGrid" className="ag-theme-balham" style={{ width: '100%',height:"445px"}}>
                    <AgGridReact
                        columnDefs={coldef && coldef.LYH}
                        rowData={rowData && rowData.LYH}
                        defaultColDef={this.state.defaultColDef}
                        rowHeight="30"
                    /></div></div>
                    <div className="col-4" style={{marginRight:"-38px"}}>
                    <div id="defaultGrid" className="ag-theme-balham" style={{ width: '100%',height:"445px"}}>
                     <AgGridReact
                        columnDefs={coldef && coldef.TYP}
                        rowData={rowData && rowData.TYP}
                        defaultColDef={this.state.defaultColDef}
                        rowHeight="30"
                    /></div></div>
                   <div className="col-3">
                   <div id="defaultGrid" className="ag-theme-balham" style={{ width: '100%',height:"445px"}}>
                     <AgGridReact
                        columnDefs={coldef && coldef.TYM}
                        rowData={rowData && rowData.TYM}
                        defaultColDef={this.state.defaultColDef}
                        rowHeight="30"
                    /></div>
                </div>
                </Row>
            </div>
        );
    }
}
export default DMMLevelTable;