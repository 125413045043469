import {
  GET_ERRORS,
  CALENDAR_DATA,
  CALENDAR_STRUCURE,
  LOADER,
  GET_PROMOTION_PIPELINE,
  GET_PROMOTION_ARTICLE_LEVEL,
  DELETE_KEY,
  CALENDAR_EMPTY_DATA
} from "./actionTypes";
import { apiFunctions } from "../../apis/index";
import environmentModule from "./../../environments/environment";
import { toast } from "react-toastify";

const {
  fetchCalanderPlotData,
  fetchCalenderData,
  fetchPromoData,
  fetchPromoHierarchyData,
  fetchPromoAndPlanHierarchyData,
  downloadPromoCalendarData
} = apiFunctions;

export const calendarDatePlot = value => async dispatch => {
// export const calendarDatePlot = reqObject => async dispatch => {
  try {
    dispatch({ type: LOADER, payload: true });
    // const payload = {
    //   fiscal_year: reqObject.fiscal_year + 1,
    //      };
      // const data = await fetchCalanderPlotData(payload);
    const data = await fetchCalanderPlotData(value);
    if (data) {
      // debugger;
      dispatch({ type: LOADER, payload: false });
      dispatch({ type: CALENDAR_STRUCURE, payload: data });
    }
  } catch (err) {
    dispatch({ type: LOADER, payload: false });
    dispatch({
      type: GET_ERRORS,
      payload: err,
    });
  }
};
export const calendarData = reqObject => async dispatch => {
  try {
    dispatch({ type: LOADER, payload: true  });
    // const {
    //   start_date,
    //   end_date
    // } = reqObject;
    // delete reqObject.start_date;
    // delete reqObject.end_date;
    const data = await fetchCalenderData({
      ...reqObject,
      // dates: [{
      //   startDate: start_date,
      //   endDate: end_date
      // }]
    });
    dispatch({ type: CALENDAR_DATA, payload: data });
    dispatch({ type: LOADER, payload: false });
  } catch (err) {
    dispatch({ type: LOADER, payload: false });
    dispatch({
      type: GET_ERRORS,
      payload: err,
    });
  }
};

export const getshowPromotionPipelineData = val => async dispatch => {
  try {
    dispatch({ type: "LOADER", payload: true });
    let data = await fetchPromoData(val);
    data = data.map((obj,index)=>{ obj.id = index; return obj})
    dispatch({ type: GET_PROMOTION_PIPELINE, payload: data });
    dispatch({ type: "LOADER", payload: false });
  } catch (err) {
    dispatch({ type: "LOADER", payload: false });
    dispatch({
      type: GET_ERRORS,
      payload: err,
    });
  }
};

export const getPromoArticleLevel = val => async dispatch => {
  try {
    dispatch({ type: "LOADER", payload: true });
    let data = await fetchPromoAndPlanHierarchyData(val);
    data = data.map((obj,index)=>{ obj.id = index; return obj})
    dispatch({ type: GET_PROMOTION_ARTICLE_LEVEL, payload: data });
    dispatch({ type: "LOADER", payload: false });
  } catch (err) {
    dispatch({ type: "LOADER", payload: false });
    dispatch({
      type: GET_ERRORS,
      payload: err,
    });
  }
};

export const deleteKey = reqObject => async dispatch => {
  dispatch({ type: DELETE_KEY, payload: true });
};

export const actionDownloadPromoCalendar = reqObj => async dispatch => {
  try {
    dispatch({ type:LOADER, payload: true });
    const result = await downloadPromoCalendarData(reqObj);
    window.open(result.data, "_self");
    dispatch({ type: LOADER, payload: false });
  } catch (err) {
    dispatch({ type: LOADER, payload: false });
  }
};


export const resetCalendarData = reqObject => async dispatch => {
  dispatch({ type: CALENDAR_EMPTY_DATA, payload: [] });
};

