import React,{ Component } from "react";
import {connect} from "react-redux";
import { withRouter, Link } from 'react-router-dom';
import PageLoader from "../../utils/Loader";
import { Col } from 'react-bootstrap';
import { DMM_LEVEL, OFFER_LEVEL } from '../../constants';
import { PlanSummaryGA } from '../../common/GAUtils';
import DMMLevelTable from './DMMLevelTable';
import OfferLevelTable from './OfferLevelTable';
import offerTable from "./offerTable";
import _ from 'underscore';
import MultiSelectCheckboxes from "../MultiSelectCheckboxes";
import {getDMMLevelData, getOfferLevelData, getQuarterData, getBuyerData, getCategoryData, 
    getVendorData, getPlanIDData, resetFilters, dmmLevelDownload, //offerLevelDownload
} from "./planSummaryAction";
import './planSummary.css';
import Filters from "./Filters";
import { Dropdown } from 'react-bootstrap';
import {DropdownToggle} from '../BudgetPlanning/Components/BudgetPlanTable/styled';
import { ToastMessage } from "../../common/ToastMessage";

class PlanSummary extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedTab: DMM_LEVEL, // Change back to DMM_LEVEL later
            offerColData:[],
            quarterListSelected:[],
            fYearSelected:[],
            fQuarterSelected:[],
            bookValue:[],
            selectedBookTab:'',
            bookValueSelected:'',
            buyerSelected:[],
            categorySelected:[],
            vendorSelected:[],
            planIDSelected:[],
            reset:false,
            filter:false
        }
    }
    onReset = () => {
        this.submitGA('Click', 'Reset Button');
        this.setState({
          reset:true,
          filter:false,
          quarterListSelected:[],
          bookValue:[],
          selectedBookTab:'',
          bookValueSelected:'',
          fYearSelected:[],
          buyerSelected:[],
          categorySelected:[],
          vendorSelected:[],
          planIDSelected:[],
          fQuarterSelected:[],
          offerColData:[]
        }, this.props.resetFilters());
      };
    submitGA(action, label) {
        PlanSummaryGA({ action, label });
    }
    formatOfferLevelColumnData=(offerLevelColumnData,book)=>{
        let columnData=[];
        offerLevelColumnData.forEach(column=>{
            if(column.headerName=='Article No-OfferId' || column.headerName=='AH6' || column.headerName=='Vendor'|| column.headerName=='Offer ID' || column.headerName==book){
                if(column.headerName =='Article No-OfferId'){
                    let bookNumber = book.split(" ")[1];
                    column.field = `BBM-${bookNumber}_articleNo`;
                    // columnData.push(column);
                }
                  columnData.push(column);
                
            }
        });
        this.setState({
            offerColData:columnData
        });
    }
    getSelectedValue=(value,label)=>{
        switch(label){
            case "quarter":
                this.setState({
                    planIDSelected:[],
                    quarterListSelected:value,
                    bookValue:[],
                    selectedBookTab:'',
                    reset:false
                },()=>this.getPlanData());
                break;
            case "buyer":
                this.setState({
                    categorySelected:[],
                    buyerSelected:value
                },()=> this.getCategoriesData());
                break;
            case "category":
                this.setState({
                    categorySelected:value,
                    vendorSelected:[],
                    planIDSelected:[],
                },()=> this.getData());
                break;
            case "vendor":
                this.setState({
                    planIDSelected:[],
                    vendorSelected:value
                }, ()=>this.getPlanData());
                break;
            case "fYear":
                this.setState({
                    planIDSelected:[],
                    fYearSelected:value
                },()=>this.getPlanData());
                break;
            case "planID":
                this.setState({
                    planIDSelected:value
                });
                break;
        }
    }
     onlyUnique=(value, index, self)=> {
        return self.indexOf(value) === index;
      }
      
    getCategoriesData = () => {
        let buyerList = [];
            let selectedBuyers = this.state.buyerSelected;
            selectedBuyers.map((obj) => {
                buyerList.push(obj.value);
            })
            let reqObj={buyer: buyerList}
            this.props.getCategoryData(reqObj);
    }

    getReqObj = () => {
        let categoryList=[], quarterList=[], yearList=[], buyerList=[];
        this.state.categorySelected.map((obj)=>{
            categoryList.push(obj.value);
        });            
        this.state.buyerSelected.map((obj)=>{
            buyerList.push(obj.value);
        })
        this.state.quarterListSelected.map((obj)=>{
            quarterList.push(obj.value[1]);
        })
        this.state.fYearSelected.map((obj)=>{
            yearList.push(obj.value)
        })
        return {categoryList,  quarterList, yearList, buyerList}
    }

    getPlanData = () => {
        if (this.state.categorySelected.length>0 && this.state.buyerSelected.length>0 && this.state.vendorSelected.length>0) {
            let vendorList = [];
            let selectedVendors = this.state.vendorSelected;
            selectedVendors.map((obj) => {
            vendorList.push(obj.value);
            });        
            let { categoryList, quarterList, yearList, buyerList } = this.getReqObj();
            this.props.getPlanIDData({
                buyer: buyerList,
                category: categoryList,
                quarters: quarterList,
                fYear: yearList,
                vendor: vendorList
            })
        }
    }
    
    getData=()=>{
        let {categoryList,  quarterList, yearList, buyerList} = this.getReqObj();
        let reqObj={merchcat:categoryList, buyer:buyerList,quarters:quarterList,fYear:yearList};
        this.props.getVendorData(reqObj);
         
    }

    componentDidUpdate(oldProps, oldState){
        if(oldProps.offerColData != this.props.offerColData){
            if(this.state.selectedBookTab!='')
                this.formatOfferLevelColumnData(this.props.offerColData, this.state.selectedBookTab);
        }
        if(oldState.selectedBookTab != this.state.selectedBookTab){
            this.formatOfferLevelColumnData(this.props.offerColData, this.state.selectedBookTab);
        }
        if(oldProps.downloadData!= this.props.downloadData){
            console.log(this.props.downloadData)
            ToastMessage(200,'Download Successful');
            window.open(this.props.downloadData.url,'_self');
        }
    }

    componentDidMount(){
        this.props.getQuarterData();
        this.props.getBuyerData({ screenName:"PlanSummary" });
    }

    updateState = (params) => {
        let keysLength = Object.keys(params).length;
        if (params.hasOwnProperty('quarter') && keysLength === 1) {
          this.submitGA('Filter', 'Changed quarter selection');
          if(params.quarter.length)
            this.getSelectedValue(params.quarter,"quarter");
        }
        if (params.hasOwnProperty('buyer') && keysLength === 1) {
          this.submitGA('Filter', 'Changed buyer selection');
          if(params.buyer.length)
            this.getSelectedValue(params.buyer,"buyer");
        }
        if (params.hasOwnProperty('category') && keysLength === 1) {
          this.submitGA('Filter', 'changed category selection');
          if(params.category.length)
            this.getSelectedValue(params.category,"category");
        }  
        if (params.hasOwnProperty('vendor') && keysLength === 1) {
            this.submitGA('Filter', 'changed vendor selection');
            this.getSelectedValue(params.vendor,"vendor");
        } 
        if(params.hasOwnProperty('fYear') && keysLength == 1){
            this.submitGA('Filter','changed year selection');
            this.getSelectedValue(params.fYear,"fYear");
        }   
        if (params.hasOwnProperty('planID') && keysLength === 1) {
            this.submitGA('Filter', 'changed planID selection');
            this.getSelectedValue(params.planID,"planID");
        }
        this.setState(params);
      };
    handleToggle = (type) => {
        this.submitGA('Toggle', 'Toggle DMM and Offer Level');
        // Added getDMMData() here temporarily to test    
        this.setState(
            {
                selectedTab: type,
            }
        );
    };
    handleBookToggle=(book)=>{
        this.submitGA('Toggle', 'Toggle between books tab');
        console.log("Book Selected:",book);
        this.setState({
            selectedBookTab:book
        })
    }
    handleFilterAction = () => {
        this.submitGA('Click', 'Filter Button');
        this.setState({
            filter:true
        })
        let params = this.getSelectedFilters();
        this.props.getDMMData(params,'DMM_LEVEL');
        this.props.getOfferData(params);
        
}
    
    getSelectedFilters = () => {
        let fYear=this.state.fYearSelected ? this.state.fYearSelected.map(obj=>obj.value) : null;
        let quarters=this.state.quarterListSelected ? this.state.quarterListSelected.map(obj=>obj.value[1]) : null;
        let vendor = this.state.vendorSelected ? this.state.vendorSelected.map((object) => object.value) : null;
        let buyer = this.state.buyerSelected ? this.state.buyerSelected.map((object) => object.value) : null;
        let category = this.state.categorySelected? this.state.categorySelected.map((obj) => obj.value) : null;
        let planID = this.state.planIDSelected? this.state.planIDSelected.map((obj) => obj.value) : null;
        let booksArray=["Book 1","Book 2","Book 3","Book 4","Book 5","Book 6","Book 7","Book 8","Book 9","Book 10",
                    "Book 11","Book 12","Book 13","Book 14","Book 15","Book 16","Book 17","Book 18","Book 19","Book 20"];
        let quarterValue= quarters[0];
        let bookValue= quarterValue=="1"?booksArray.slice(0,5):(quarterValue=="2"?booksArray.slice(5,10):(quarterValue=="3"?booksArray.slice(10,15):booksArray.slice(15)));
        this.setState({
            bookValue:bookValue,
            selectedBookTab:bookValue[0]
        });
        let filterObj = {
        fYear,
        quarters,
        vendor,
        buyer,
        category,
        planID,
        //type: this.state.selectedTab,
        screenName: 'PlanSummary',
        };
        return _.pick(filterObj, _.identity);

    };

    handleDownload=(downloadType)=>{
        let params = this.getSelectedFilters();
        params['downloadType']=downloadType;
        params['type']=this.state.selectedTab;
        this.props.dmmLevelDownload(params);
        // if(this.state.selectedTab=='DMM_LEVEL'){
        //     this.props.dmmLevelDownload(params);
        // }
        // else{
        //     this.props.dmmLevelDownload(params);
        // }

    }
    render() {
        return (
            <div>
                <PageLoader loader={this.props.loader}>
                    <div>
                        <div className="card CalendarMain promoCalendarDiv1" id="promocalendar-maincontainer">
                            <div className="row">
                                <div className="col-4">
                                    <h4 className="promoCal-brdcrm mb-10">
                                        <span className="font-bold">Plan Summary</span>
                                    </h4>
                                </div>
                                <div className="col-4"></div>
                            </div>
                            <div className="row filter-block">
                               <div className="col-md-10">
                                   <div className="row">
                                        <Filters
                                        updateState={this.updateState}
                                        quarterData={this.props.quarterData}
                                        buyerData={this.props.buyerData}
                                        categoryData={this.props.categoryData}
                                        vendorData={this.props.vendorData}
                                        planIDData={this.props.planIDData}
                                        fyearData={this.props.fyearData}
                                        quarterListSelected={this.state.quarterListSelected}
                                        fYearSelected={this.state.fYearSelected}
                                        fQuarterSelected={this.state.fQuarterSelected}
                                        buyerSelected={this.state.buyerSelected}
                                        categorySelected={this.state.categorySelected}
                                        vendorSelected={this.state.vendorSelected}
                                        planIDSelected={this.state.planIDSelected}
                                        onMenuCloseApiFun={() => { return; }}
                                         defaultSelectedOptions= {true} />
                                         </div>
                                </div> 
                                <div className="col-md-2" style={{paddingTop:"35px"}}>
                                    <div className="form-row">
                                        <div className="row">
                                            <div className="col-md-4 ">
                                                <button
                                                    className='btn btn-primary filterBtn'
                                                    onClick={this.handleFilterAction}
                                                    disabled={(this.state.quarterListSelected.length == 0 ||
                                                        this.state.buyerSelected.length == 0 ||
                                                        this.state.categorySelected == 0 ||
                                                        this.state.vendorSelected == 0 ||
                                                        this.state.planIDSelected == 0) ? true : false
                                                    }
                                                >
                                                    <span>
                                                        <i className='fa fa-filter' />
                                                                    &nbsp; Filter
                                                            </span>
                                                </button>
                                            </div>
                                            <div className="col-md-4">
                                                <button
                                                    className='btn btn-primary resetButton'
                                                    onClick={() => this.onReset()}
                                                //disabled={}
                                                >
                                                    <span>
                                                        <i className='fa fa-repeat'></i>&nbsp; Reset
                                                            </span>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{borderBottom:"1px solid #E7EEF3", paddingBottom:"20px"}}>
                            <Col md={2} style={{marginRight:'60px'}}>
                                <div className='btn-group analyze-promo-btn-group'>
                                    <button
                                        className={
                                            this.state.selectedTab === DMM_LEVEL
                                                ? 'btn btn-outline-primary btnModal1 active'
                                                : 'btn btn-outline-primary btnModal1'
                                        }
                                        onClick={() => this.handleToggle(DMM_LEVEL)}>
                                        DMM Level
                                    </button>
                                    <button
                                        className={
                                            this.state.selectedTab === OFFER_LEVEL
                                                ? 'btn btn-outline-primary btnModal1 active'
                                                : 'btn btn-outline-primary btnModal1'
                                        }
                                        onClick={() =>
                                            this.handleToggle(OFFER_LEVEL)
                                        }>
                                        Offer Level
                                    </button>
                                </div>
                            </Col>
                            <div className="dropdown">
                                <Dropdown>
                                    <DropdownToggle 
                                        id="dropdown-basic"
                                        className=" downloadBtn btn-primary btn-sm"
                                        disabled={!this.state.filter}
                                        style={{display:"flex", width:'220px'}}
                                        >
                                        <i class="fa fa-download" aria-hidden="true" />
                                        <div>Download Files</div>
                                    </DropdownToggle>

                                    <Dropdown.Menu>
                                        <Dropdown.Item 
                                            onClick={()=>this.handleDownload('full')}
                                        >
                                            Full Download
                                        </Dropdown.Item>
                                        <Dropdown.Item 
                                            onClick={()=>this.handleDownload('partial')}
                                        >
                                            Vendor Version Download
                                        </Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            {
                                this.state.selectedTab === OFFER_LEVEL ? (
                                    <div className='btn-group analyze-promo-btn-group' style={{marginInlineStart:"auto"}}>
                                            {
                                            //this.state.bookValue.length?
                                                 this.state.bookValue.map(book=>
                                                    <button
                                                        className={
                                                            this.state.selectedBookTab=== book
                                                            ? 'btn btn-outline-primary btnModal1 active'
                                                            : 'btn btn-outline-primary btnModal1'
                                                        }
                                                        style={{width:"100px"}}
                                                        onClick={()=>this.handleBookToggle(book)}
                                                        disabled={this.state.selectedTab=='DMM_LEVEL'?true:false}
                                                    >
                                                    {book}
                                                    </button>
                                                )
                                            }
                                    </div>) :null
                            }                        
                            </div>
                            <div>{this.state.filter ?
                                (this.state.selectedTab === DMM_LEVEL ?(!(Object.keys(this.props.DMMrowData).length === 0)?
                                <DMMLevelTable
                                    rowData = {this.props.DMMrowData}
                                    DMMcoldef = {this.props.DMMcolData}
                                    />
                                : null): (Object.keys(this.props.offerRowData).length>0 && this.state.offerColData.length? 
                                <OfferLevelTable
                                tableData={this.props.offerRowData}
                                selectedBookTab={this.state.selectedBookTab}
                                rowData={this.props.offerRowData}
                                offercoldef={this.state.offerColData}
                                />:null )):null}
                            </div>
                        </div>
                    </div>
                </PageLoader>
            </div>
        );
    }
}
const mapStateToProps = (store) => {
    return {
      DMMrowData: store.planSummary.DMMrowData,
      DMMcolData: store.planSummary.DMMcolData,
      offerPinnedData: store.planSummary.offerPinnedData,
      offerRowData: store.planSummary.offerRowData,
      offerColData: store.planSummary.offerColData,
      quarterData: store.planSummary.quarterData,
      buyerData: store.planSummary.buyerData,
      categoryData: store.planSummary.categoryData,
      vendorData : store.planSummary.vendorData,
      planIDData : store.planSummary.planIDData,
      fyearData: store.planSummary.fyearData,
      loader: store.planSummary.loader,
      downloadData :store.planSummary.downloadData
    //   dmmDownloadData: store.planSummary.DMM_downloadData,
    //   offerDownloadData: store.planSummary.Offer_downloadData
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
        getDMMData :(val1,val2)=> dispatch(getDMMLevelData(val1,val2)),
        getOfferData : (val)=> dispatch(getOfferLevelData(val)),
        getQuarterData : ()=> dispatch(getQuarterData()),
        getBuyerData : ()=> dispatch(getBuyerData()),
        getCategoryData : (valueObj)=> dispatch(getCategoryData(valueObj)),
        getVendorData : (value)=> dispatch(getVendorData(value)),
        getPlanIDData : (obj)=> dispatch(getPlanIDData(obj)),
        resetFilters: () => dispatch(resetFilters()),
        dmmLevelDownload: (val)=> dispatch(dmmLevelDownload(val)),
       // offerLevelDownload: (val)=> dispatch(offerLevelDownload(val))
    };
  };
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PlanSummary));