const chartOptions1 = {
  plugins: {
    datalabels: {
      display: false,
      color: 'white',
    },
  },
  // events: [],
  hover: {
    mode: 'nearest',
    animationDuration: 0,
    // intersect: false
  },
  tooltips: {
    yAlign: 'top',
    mode: 'index',
    intersect: false,
    callbacks: {
      title: function (tooltipItem, data) {
        return data.completeLabels[tooltipItem[0].index];
      },
      label: function (tooltipItem, data) {

        var label = data.datasets[tooltipItem.datasetIndex].label || '';
        if (label) {
          label += ': ';
        }
        var no = tooltipItem.yLabel;
        no =
          Math.abs(Number(no)) >= 1000
            ? new Intl.NumberFormat('en-us', { currency: 'USD' }).format(
                (Math.abs(Number(no)) / 1.0e3).toFixed(2) || 0
              ) + ' K'
            : new Intl.NumberFormat('en-us', { currency: 'USD' }).format(
                Math.abs(Number(no)).toFixed(2) || 0
              );
        label += '$' + no;

        return label;
      },
    },
  },
  scales: {
    xAxes: [
      {
        barPercentage: 0.4,
        gridLines: {
          lineWidth: 0,
        },
        ticks: {
          fontSize: 13,
          opacity: 0.7,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          // lineWidth: 0
        },
        ticks: {
          callback: function (value, index, values) {
            var no;
            // Math.abs(parseFloat(value)) >= 1.0e9
            //   ? (Math.abs(parseFloat(value)) / 1.0e3).toFixed(1) + 'K'
            //   : // Six Zeroes for Millions
            //   Math.abs(parseFloat(value)) >= 1.0e6
            //   ? (Math.abs(parseFloat(value)) / 1.0e3).toFixed(1) + 'K'
            //   : // Three Zeroes for Thousands
            //   Math.abs(parseFloat(value)) >= 1.0e3
            //   ? (Math.abs(parseFloat(value)) / 1.0e3).toFixed(1) + 'K'
            //   : Math.abs(parseFloat(value)).toFixed(1);
            no =
              Math.abs(Number(value)) >= 1000
                ? new Intl.NumberFormat('en-us', { currency: 'USD' }).format(
                    (Math.abs(Number(value)) / 1.0e3).toFixed(2) || 0
                  ) + ' K'
                : new Intl.NumberFormat('en-us', { currency: 'USD' }).format(
                    Math.abs(Number(value)).toFixed(2) || 0
                  );

            return no;
          },
          fontSize: 11,
          maxTicksLimit: 5,
          fontColor: '#9B9B9B',
          beginAtZero: true,
        },
        scaleLabel: {
          display: true,
          labelString: 'Value in $',
        },
      },
    ],
  },
  legend: {
    display: false,
    position: 'bottom',
    labels: {
      boxWidth: 12,
    },
    fontFamily: 'poppins',
    fontSize: '12px',
  },

  responsive: true,
  maintainAspectRatio: true,
};

const chartOptions2 = {
  plugins: {
    datalabels: {
      display: false,
      color: 'white',
    },
  },
  // events: [],
  hover: {
    mode: 'nearest',
    animationDuration: 0,
    // intersect: false
  },
  tooltips: {
    yAlign: 'top',
    mode: 'index',
    intersect: false,
    callbacks: {
      title: function (tooltipItem, data) {
        return data.completeLabels[tooltipItem[0].index];
      },
      label: function (tooltipItem, data) {

        var label = data.datasets[tooltipItem.datasetIndex].label || '';
        if (label) {
          label += ': ';
        }
        var no = tooltipItem.yLabel;
        no =
          Math.abs(Number(no)) >= 1000
            ? new Intl.NumberFormat('en-us', { currency: 'USD' }).format(
                (Math.abs(Number(no)) / 1.0e3).toFixed(2) || 0
              ) + ' K'
            : new Intl.NumberFormat('en-us', { currency: 'USD' }).format(
                Math.abs(Number(no)).toFixed(2) || 0
              );
        label +=  no;

        return label;
      },
    },
  },
  scales: {
    xAxes: [
      {
        barPercentage: 0.4,
        gridLines: {
          lineWidth: 0,
        },
        ticks: {
          fontSize: 13,
          opacity: 0.7,
        },
      },
    ],
    yAxes: [
      {
        gridLines: {
          // lineWidth: 0
        },
        ticks: {
          callback: function (value, index, values) {
            var no;
            // Math.abs(parseFloat(value)) >= 1.0e9
            //   ? (Math.abs(parseFloat(value)) / 1.0e3).toFixed(1) + 'K'
            //   : // Six Zeroes for Millions
            //   Math.abs(parseFloat(value)) >= 1.0e6
            //   ? (Math.abs(parseFloat(value)) / 1.0e3).toFixed(1) + 'K'
            //   : // Three Zeroes for Thousands
            //   Math.abs(parseFloat(value)) >= 1.0e3
            //   ? (Math.abs(parseFloat(value)) / 1.0e3).toFixed(1) + 'K'
            //   : Math.abs(parseFloat(value)).toFixed(1);
            no =
              Math.abs(Number(value)) >= 1000
                ? new Intl.NumberFormat('en-us', { currency: 'USD' }).format(
                    (Math.abs(Number(value)) / 1.0e3).toFixed(2) || 0
                  ) + ' K'
                : new Intl.NumberFormat('en-us', { currency: 'USD' }).format(
                    Math.abs(Number(value)).toFixed(2) || 0
                  );

            return no;
          },
          fontSize: 11,
          maxTicksLimit: 5,
          fontColor: '#9B9B9B',
          beginAtZero: true,
        },
        scaleLabel: {
          display: true,
          labelString: 'Values '
        },
      },
    ],
  },
  legend: {
    display: false,
    position: 'bottom',
    labels: {
      boxWidth: 12,
    },
    fontFamily: 'poppins',
    fontSize: '12px',
  },

  responsive: true,
  maintainAspectRatio: true,
};

export { chartOptions1, chartOptions2 };
