import React, {Component} from "react";
import moment from "moment";
import {MONTH_NAMES} from './constants'
class calendarWrapperToggleComponent extends Component {
    constructor(props) {
        super(props);

        this.state = {
            styleHandlePrev: {
                cursor: "pointer"
            },
            styleHandleNext: {
                cursor: "pointer"
            },
            styleGetPrev: {
                bottom: "13%",
                left: "-0.2rem",
                cursor: "pointer"
            },
            styleGetNext: {
                bottom: "13%",
                right: "-0.2rem",
                cursor: "pointer"
            },
            loader: false,
            calendarDataArr: []
        };
        this.Month = MONTH_NAMES;
    }

    //250 lines
    componentDidUpdate(oldProps, oldState) {
        let {calendarDataPerYear} = this.props;

        if (oldProps.calendarDataPerYear !== calendarDataPerYear) {
            if (this.props.markdownlinkShow === 1) {
                this.getMarkdownStyle();
            } else {
                /*------------------weekly navigation enable/disable function------------------ */
                this.getPromoStyle();
            }
        }
    }

    getMarkdownStyle = () => {
        let {calendarStrucure, selectedView, fiscal_monthnum, selectedYear, fiscal_quarter} = this.props;

        let calendarStructerData = calendarStrucure.overall_dates
            ? calendarStrucure.overall_dates[0]
            : null;

        let stylObj = {
            bottom: "13%",
            right: "-0.2rem",
            cursor: "pointer"
        };

        if (selectedView === "Month") {
            if (fiscal_monthnum < 12 && selectedYear <= new Date(calendarStructerData.end_date).getFullYear()) 
                this.setState({
                    styleGetNext: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            else if (fiscal_monthnum === 12 && selectedYear + 1 < new Date(calendarStructerData.end_date).getFullYear()) {
                this.setState({
                    styleGetNext: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            } else {
                this.setState({
                    styleGetNext: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            }

            if (fiscal_monthnum > 1 && selectedYear > new Date(calendarStructerData.start_date).getFullYear()) 
                this.setState({
                    styleGetPrev: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            else if (fiscal_monthnum === 1 && selectedYear - 1 > new Date(calendarStructerData.start_date).getFullYear()) {
                this.setState({
                    styleGetPrev: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            } else {
                this.setState({
                    styleGetPrev: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            }
        } else if (selectedView === "Quarter") {
            if (fiscal_quarter < 4 && selectedYear <= new Date(calendarStructerData.end_date).getFullYear()) 
                this.setState({
                    styleGetNext: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            else if (fiscal_quarter === 4 && selectedYear + 1 < new Date(calendarStructerData.end_date).getFullYear()) {
                this.setState({
                    styleGetNext: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            } else {
                this.setState({
                    styleGetNext: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            }

            if (fiscal_quarter > 1 && selectedYear > new Date(calendarStructerData.start_date).getFullYear()) {
                this.setState({
                    styleGetPrev: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            } else if (fiscal_quarter === 1 && selectedYear - 1 > new Date(calendarStructerData.start_date).getFullYear()) {
                this.setState({
                    styleGetPrev: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            } else {
                this.setState({
                    styleGetPrev: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            }
        }
    };

    getPromoStyle = () => {
        let {calendarStrucure, selectedView, fiscal_monthnum, selectedYear, fiscal_quarter} = this.props;
        let calendarStructerData = calendarStrucure.overall_dates
            ? calendarStrucure.overall_dates[0]
            : null;

        let stylObj = {
            bottom: "13%",
            right: "-0.2rem",
            cursor: "pointer"
        };

        if (selectedView === "Month") {
            if (fiscal_monthnum < 12 && selectedYear <= new Date(calendarStructerData.end_date).getFullYear()) {
                this.setState({
                    styleGetNext: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            } else {
                //SURYYA COMMENTED
                this.setState({
                    styleGetNext: {
                        ...stylObj,
                        // cursor: "not-allowed"
                    }
                });
            }
            if (fiscal_monthnum > 1 && selectedYear >= new Date(calendarStructerData.start_date).getFullYear()) {
                this.setState({
                    styleGetPrev: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            } else {
                //SURYYA COMMENTED
                this.setState({
                    styleGetPrev: {
                        ...stylObj,
                        // cursor: "not-allowed"
                    }
                });
            }
        } else if (selectedView === "Quarter") {
            if (fiscal_quarter < 4 && selectedYear <= new Date(calendarStructerData.end_date).getFullYear()) {
                this.setState({
                    styleGetNext: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            } else {
                this.setState({
                    styleGetNext: {
                        ...stylObj,
                        // cursor: "not-allowed"
                    }
                });
            }

            if (fiscal_quarter > 1 && selectedYear >= new Date(calendarStructerData.start_date).getFullYear()) {
                this.setState({
                    styleGetPrev: {
                        ...stylObj,
                        cursor: "pointer"
                    }
                });
            } else {
                this.setState({
                    styleGetPrev: {
                        ...stylObj,
                        // cursor: "not-allowed"
                    }
                });
            }
        }
    };

    getmarkdownPrevData = () => {
        let {
            calendarStrucure,
            selectedView,
            fiscal_monthnum,
            selectedYear,
            fiscal_quarter,
            navCallBack
        } = this.props;

        let month = {
            1: 2,
            2: 5,
            3: 8,
            4: 11
        };

        let calendarStructerData = calendarStrucure.overall_dates
            ? calendarStrucure.overall_dates[0]
            : null;
        if (selectedView === "Month") {
            if (fiscal_monthnum > 1 && selectedYear > new Date(calendarStructerData.start_date).getFullYear()) {
                let date = new Date(selectedYear,fiscal_monthnum,1),
                    selectedDate = new Date(moment(date).add(0, "years"));

                navCallBack({
                    fiscal_monthnum: Number(fiscal_monthnum) - 1,
                    fiscal_quarter: parseInt((Number(fiscal_monthnum) - 2) / 3 + 1),
                    loader: true,
                    selectedDate: selectedDate,
                    navigated: "prev-month"
                });
            } else if (fiscal_monthnum === 1 && selectedYear - 1 > new Date(calendarStructerData.start_date).getFullYear()) {
                let date = new Date((selectedYear - 1),fiscal_monthnum,1),
                    selectedDate = new Date(moment(date).add(0, "years"));

                navCallBack({
                    fiscal_monthnum: 12,
                    fiscal_quarter: 4,
                    selectedYear: selectedYear,
                    loader: true,
                    selectedDate: selectedDate,
                    navigated: "prev-month"
                });
            }
        } else if (selectedView === "Quarter") {
            if (fiscal_quarter > 1 && selectedYear > new Date(calendarStructerData.start_date).getFullYear()) {
                let quarter = Number(fiscal_quarter) - 1,
                    // date = new Date(`${month[quarter]}-1-${selectedYear}`),
                    date = new Date(selectedYear,month[quarter],1),
                    selectedDate = new Date(moment(date).add(0, "years"));

                navCallBack({
                    fiscal_quarter: Number(fiscal_quarter) - 1,
                    fiscal_monthnum: (Number(fiscal_quarter) - 1) * 3 - 2,
                    loader: true,
                    selectedDate: selectedDate,
                    navigated: "prev-quarter"
                });
            } else if (fiscal_quarter == 1 && selectedYear - 1 > new Date(calendarStructerData.start_date).getFullYear()) {
                // let date = new Date(`11-1-${selectedYear - 1}`),
                let date = new Date((selectedYear - 1),11-1),
                    selectedDate = new Date(moment(date).add(0, "years"));

                navCallBack({
                    fiscal_quarter: 4,
                    fiscal_monthnum: 10,
                    selectedYear: selectedYear,
                    loader: true,
                    selectedDate: selectedDate,
                    navigated: "prev-quarter"
                });
            }
        }
    };

    getmarkdownNextData = () => {
        let {
            calendarStrucure,
            selectedView,
            fiscal_monthnum,
            selectedYear,
            fiscal_quarter,
            navCallBack
        } = this.props;

        let month = {
            1: 2,
            2: 5,
            3: 8,
            4: 11
        };
        let calendarStructerData = calendarStrucure.overall_dates
            ? calendarStrucure.overall_dates[0]
            : null;

        if (selectedView === "Month") {
            // debuggerdebugger
            if (fiscal_monthnum < 12 && selectedYear <= new Date(calendarStructerData.end_date).getFullYear()) {
                let date = fiscal_monthnum + 2 > 12
                        // ? new Date(`1-1-${selectedYear}`)
                        // : new Date(`${fiscal_monthnum + 2}-1-${selectedYear}`),
                        ? new Date(selectedYear,1,1)
                        : new Date(selectedYear,(fiscal_monthnum + 2),1),
                    selectedDate = new Date(moment(date).add(0, "years"));

                navCallBack({
                    fiscal_monthnum: Number(fiscal_monthnum) + 1,
                    fiscal_quarter: parseInt(Number(fiscal_monthnum) / 3 + 1),
                    loader: true,
                    selectedDate: selectedDate,
                    navigated: "next-month"
                });
            } else if (fiscal_monthnum === 12 && selectedYear + 1 < new Date(calendarStructerData.end_date).getFullYear()) {
                let newDate = new Date((selectedYear + 1),2,1);
                let selectednewDate = new Date(moment(newDate).add(0, "years"));

                navCallBack({
                    fiscal_monthnum: 1,
                    fiscal_quarter: 1,
                    selectedYear: selectedYear + 2,
                    loader: true,
                    selectedDate: selectednewDate,
                    navigated: "next-month"
                });
            }
        } else if (selectedView === "Quarter") {
            // debugger
            if (fiscal_quarter < 4 && selectedYear <= new Date(calendarStructerData.end_date).getFullYear()) {
                let quarter = Number(fiscal_quarter) + 1,
                    // date = new Date(`${month[quarter]}-1-${selectedYear}`),
                    date = new Date(selectedYear,month[quarter],1),
                    selectedDate = new Date(moment(date).add(0, "years"));

                navCallBack({
                    fiscal_quarter: Number(fiscal_quarter) + 1,
                    fiscal_monthnum: Number(fiscal_quarter) * 3 + 1,
                    loader: true,
                    selectedDate: selectedDate,
                    navigated: "next-quarter"
                });
            } else if (fiscal_quarter === 4 && selectedYear + 1 < new Date(calendarStructerData.end_date).getFullYear()) {
                let date = new Date((selectedYear + 1),2,1),
                    selectedDate = new Date(moment(date).add(0, "years"));

                navCallBack({
                    fiscal_quarter: 1,
                    fiscal_monthnum: 1,
                    selectedYear: selectedYear + 2,
                    loader: true,
                    selectedDate: selectedDate,
                    navigated: "next-quarter"
                });
            }
        }
    };

    getPrev = () => {
        const {
            navCallBack
        } = this.props;
        navCallBack({
            navigated: "prev-month"
        });
        // let {
        //     calendarStrucure,
        //     selectedView,
        //     fiscal_monthnum,
        //     selectedYear,
        //     fiscal_quarter,
        //     navCallBack
        // } = this.props;

        // let month = {
        //     1: 2,
        //     2: 5,
        //     3: 8,
        //     4: 11
        // };

        // let calendarStructerData = calendarStrucure.overall_dates
        //     ? calendarStrucure.overall_dates[0]
        //     : null;

        // if (this.props.markdownlinkShow === 1) {
        //     this.getmarkdownPrevData();
        // } else {
        //     if (selectedView === "Month") {

        //         if (fiscal_monthnum > 1 && selectedYear >= new Date(calendarStructerData.start_date).getFullYear()) {
        //             let date = moment(`${selectedYear}-${fiscal_monthnum}-1`),
        //             // let date = new Date(selectedYear,fiscal_monthnum,1),
        //                 selectedDate = new Date(moment(date).add(0, "years"));
        //                 console.log(date)
        //             navCallBack({
        //                 fiscal_monthnum: Number(fiscal_monthnum) - 1,
        //                 fiscal_quarter: parseInt((Number(fiscal_monthnum) - 2) / 3 + 1),
        //                 loader: true,
        //                 selectedDate: selectedDate,
        //                 navigated: "prev-month"
        //             });
        //         }
        //     } else if (selectedView === "Quarter") {
        //         if (fiscal_quarter > 1 && selectedYear >= new Date(calendarStructerData.start_date).getFullYear()) {
        //             let quarter = Number(fiscal_quarter) - 1,
        //                 // date = new Date(`${month[quarter]}-1-${selectedYear}`),
        //                date = moment(`${selectedYear}-${month[quarter]}-1`),
        //                 // date = new Date(selectedYear,month[quarter],1),
        //                 selectedDate = new Date(moment(date).add(0, "years"));

        //             navCallBack({
        //                 fiscal_quarter: Number(fiscal_quarter) - 1,
        //                 fiscal_monthnum: (Number(fiscal_quarter) - 1) * 3 - 2,
        //                 loader: true,
        //                 selectedDate: selectedDate,
        //                 navigated: "prev-quarter"
        //             });
        //         }
        //     }
        // }
    };

    //100 lines
    getNext = () => {
        const {
            navCallBack
        } = this.props;
        navCallBack({
            navigated: "next-month"
        });
        // let {
        //     calendarStrucure,
        //     selectedView,
        //     fiscal_monthnum,
        //     selectedYear,
        //     fiscal_quarter,
        //     navCallBack
        // } = this.props;
        // debugger;
        // let month = {
        //     1: 2,
        //     2: 5,
        //     3: 8,
        //     4: 11
        // };
        // let calendarStructerData = calendarStrucure.overall_dates
        //     ? calendarStrucure.overall_dates[0]
        //     : null;

        // if (this.props.markdownlinkShow === 1) {
        //     this.getmarkdownNextData();
        // } else {
        //     if (selectedView === "Month") {
        //         console.log(selectedYear ,fiscal_monthnum ,fiscal_monthnum + 2 > 12)
        //         if (fiscal_monthnum < 12 && selectedYear <= new Date(calendarStructerData.end_date).getFullYear()) {
        //             let date = fiscal_monthnum + 2 > 12
        //                     // ? new Date(`1-1-${selectedYear}`)
        //                     // : new Date(`${fiscal_monthnum + 2}-1-${selectedYear}`),
        //                     ? moment(`${selectedYear+1}-1-1`) :
        //                     moment(`${selectedYear}-${(fiscal_monthnum + 2)}-1`),
        //                 selectedDate = new Date(moment(date).add(0, "years"));

        //             let selectedMonthName = this.Month[Number(fiscal_monthnum) >= 12
        //                     ? 0
        //                     : Number(fiscal_monthnum)];

        //             navCallBack({
        //                 fiscal_monthnum: Number(fiscal_monthnum) + 1,
        //                 fiscal_quarter: parseInt(Number(fiscal_monthnum) / 3 + 1),
        //                 loader: true,
        //                 selectedDate: selectedDate,
        //                 navigated: "next-month",
        //                 selectedMonth: selectedMonthName
        //             });
        //         }
        //     } else if (selectedView === "Quarter") {
        //         // debugger
        //         if (fiscal_quarter < 4 && selectedYear <= new Date(calendarStructerData.end_date).getFullYear()) {
        //             let quarter = Number(fiscal_quarter) + 1,
        //                 // date = new Date(`${month[quarter]}-1-${selectedYear}`),
        //                 // date = new Date(selectedYear,month[quarter],1),
        //                 date = moment(`${selectedYear}-${month[quarter]}-1`),
        //                 selectedDate = new Date(moment(date).add(0, "years"));

        //             navCallBack({
        //                 fiscal_quarter: Number(fiscal_quarter) + 1,
        //                 fiscal_monthnum: Number(fiscal_quarter) * 3 + 1,
        //                 loader: true,
        //                 selectedDate: selectedDate,
        //                 navigated: "next-quarter"
        //             });
        //         }
        //     }
        // }
    };

    render() {
        return (
            <div>
                <p id="caltext">
                    <button
                        type="button"
                        className="btn btn-primery btn-calendar btn-lg"
                        style={this.state.styleGetPrev}
                        onClick={this.getPrev}>
                        <i className="fa fa-angle-left "/>
                    </button>
                    &nbsp;&nbsp;
                    <div className="calendarYear">
                        {this.props.calendarWrapperArr && this.props.calendarWrapperArr.length <= 6
                            ? this.Month[Number(new Date(this.props.calendarWrapperArr[0].end_date).getMonth())] + " " + new Date(this.props.calendarWrapperArr[0].end_date).getFullYear()
                            : `Quarter ${Number(this.props.calendarWrapperArr[0].fiscal_quarter)  }`}{" "}
                        - FY {this.props.selectedYear}
                        {/*this.props.selectedYear + 1*/}
                    </div>
                    &nbsp;&nbsp;
                    <button
                        style={this.state.styleGetNext}
                        type="button"
                        className="btn btn-primery btn-calendar btn-lg"
                        onClick={this.getNext}>
                        <i className="fa fa-angle-right promoIcon"/>
                    </button>
                </p>
            </div>
        );
    }
}

export default calendarWrapperToggleComponent;
