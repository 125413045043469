import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import EditOrDelete from './actionsComponent';

const ConstraintsTable = (props) => {
  const [rowData, setRowData] = useState([]);
  const [header, setHeader] = useState([]);
  const [gridApi, setGridApi] = useState({});

  const AidRenderComponent = props => {
    const onEditClick = () => {
      const args = {
        data: props.data,
        mode: 'edit'
      }
      props.handleViewEditGroup(args);
    };
    const onViewClick = () => {
      const args = {
        data: props.data,
        mode: 'view'
      }
        props.handleViewEditGroup(args);
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          color: " #44677b"
        }}
      >
       {props.data.articleNo !== null?  <span>{props.value} </span>: <> <span onClick={(e) => onViewClick(e)}> {props.value} </span> 
        <i className="fa fa-pencil" onClick={(e) => onEditClick(e)}></i></> }
      </div>
    );
  };
  const tableheader = [
    {
      headerName: '',
      field: 'Select',
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      filter: false,
      resizable: false,
      pinned: 'left',
      width: 30,
      suppressMovable: true
    },
    {
      headerName: 'Article/Group',
      field: 'articleOrGroup',
      pinned: 'left',
      width: 150,
      filter: true,
      suppressMovable: true,
      cellRenderer: 'aidRenderComponent',
      cellRendererParams: {
        handleGroupEdit: props.handleEditGroup,
        handleViewEditGroup: props.handleViewEditGroup
      },
      cellStyle: function (params){ 
         
      if(params.value === null){
        return {
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer"
        }
      } else{
          return {}
        }
      }
    },
    {
      headerName: 'Vendor',
      field: 'vendor',
      pinned: 'left',
      width: 150,
      filter: true,
      suppressMovable: true
    },
    {
      headerName: 'Brand',
      field: 'brand',
      pinned: 'left',
      width: 150,
      filter: true,
      suppressMovable: true
    },
    {
      headerName: 'AH6',
      field: 'ah6',
      pinned: 'left',
      width: 150,
      filter: true,
      suppressMovable: true
    },
    {
      headerName: 'Constraint Level',
      field: 'selectedConstraintType',
      width: 150,
      filter: true,
      suppressMovable: true
    },
    {
      headerName: 'Min Cycles',
      field: 'minCycles',
      filter: true,
      suppressMovable: true
    },
    {
      headerName: 'Max Cycles',
      field: 'maxCycles',
      filter: true,
      suppressMovable: true
    },
    {
      headerName: 'Promo Fatigue Relaxation',
      field: 'promoFatigueRelax',
      filter: true,
      suppressMovable: true
    },
    {
      headerName: 'Mandatory Cycles',
      field: 'mandatoryCycles',
      filter: true,
      suppressMovable: true
    },
    {
      headerName: 'Excluded Cycles',
      field: 'excludedCycles',
      filter: true,
      suppressMovable: true
    },
    {
      headerName: 'Actions ',
      field: 'edit/delete',
      pinned:'right',
      suppressMovable: true,
      hide: (!props.userInfo.action.includes('edit-constraints') && !props.userInfo.action.includes('delete-constraints')),
      cellRenderer: 'editOrDelete',
      cellRendererParams: {
        handleEdit: props.handleEditConstraint,
        handleDelete: props.removeConstraint,
        userInfo: props.userInfo
      },
    },
  ];

  const MODEL_TYPES = [
    {
      model: 'Inactive Article',
      color: '#D3D3D3'
    },   
    {
    model: 'Group Article',
    color: '#e2edf4'
  }
  ]
  
  useEffect(() => {
    const { constraintData } = props;
    const formatData = constraintData;
    const books = [ 'BBM1','BBM2','BBM3','BBM4','BBM5','BBM6','BBM7','BBM8','BBM9','BBM10','BBM11','BBM12','BBM13','BBM14','BBM15','BBM16','BBM17','BBM18','BBM19','BBM20',];
    formatData.forEach((element) => {
      element.cycles
        .filter(item => element.selectedConstraintType == 'QUARTERLY' ? item.quarter != 0 : item.quarter == 0)
        .map((data) => {
          // if (!books.includes(data.name)) {
          //   books.push(data.name);
          // }
          element[`${data.name}_min`] = !data.min? data.min === 0 ?'0.00' : '-': data.min;
          element[`${data.name}_max`] = !data.max? data.max === 0 ?'0.00': '-':data.max;
          element[`${data.name}_fixed`] = !data.fixed? data.fixed === 0 ?'0.00' : '-':data.fixed;
        });
        if (element.selectedConstraintType == 'QUARTERLY') {
          let tempMaxKeys = element.maxquarterly.maxCycleQuarterly
            ? Object.keys(element.maxquarterly.maxCycleQuarterly)
            : [];
          let tempMinKeys = element.minquarterly.minCycleQaurterly
            ? Object.keys(element.minquarterly.minCycleQaurterly)
            : [];
         

          element.maxCycles = tempMaxKeys.reduce((accumulator, currentValue) => {
            return `${accumulator} Q${currentValue}:${element.maxquarterly.maxCycleQuarterly[currentValue]}`;
          }, '');

          element.minCycles = tempMinKeys.reduce((accumulator, currentValue) => {
            return `${accumulator} Q${currentValue}:${element.minquarterly.minCycleQaurterly[currentValue]}`;
          }, '');
        }
    });
    books.forEach((book) => {
      const header = {
        headerName: book,
        field: 'cycles',
        suppressMovable: true,
        width:100,
        children: [
          { headerName: 'Min', field: `${book}_min`, suppressMovable: true,},
          { headerName: 'Max', field: `${book}_max`, suppressMovable: true,},
          { headerName: 'Fixed', field: `${book}_fixed`,suppressMovable: true,},
        ],
      };
      tableheader.splice(tableheader.length - 1, 0, header);
    });
    setHeader(tableheader);
    setRowData(formatData);
  }, props.constraintData);


  function onGridReady(params) {
    this.gridApi = params.api;
    setGridApi(params.api);
    this.gridColumnApi = params.columnApi;
    // this.gridApi.sizeColumnsToFit();
    this.gridColumnApi.autoSizeAllColumns();
  }

  function isRowSelectable(rowNode) {
    return rowNode.data ? !(rowNode.data.groupid) : true;
}

 const getRowStyle = function(params) {
   if(params.data.groupid && params.data.articleNo) {
    return { background: '#e2edf4' };
  }else if (!params.data.active) {
        return { background: '#F1F1F1' };
    } 
}

  return (
    <React.Fragment>
       <div className="row" style={{marginLeft:"1px"}}>
        {MODEL_TYPES.map((obj, index) => (
          <div className={ (MODEL_TYPES.length-1) === index ? "col-6" :"col-4"}>
            <div className="row">
            <div className={(MODEL_TYPES.length-1) === index ? "col-1" :"col-2"} style={{
              boxSizing: "border-box",
              width: "100px",
              height: "15px",
              border:"1px solid", 
              backgroundColor: obj.color,
              marginTop: "10px"}}></div>
            <div className="col-10">{obj.model}</div>
            </div>
          </div>
        ))}
        </div>
        <div className="spacer"></div>
      <div id="defaultGrid" className="ag-theme-balham" style={{ height: rowData.length<5? 250 : 400 , width: '100%'}}>
        <AgGridReact
          columnDefs={header}
          rowData={rowData}
          onGridReady={onGridReady}
          // suppressDragLeaveHidesColumns={true}
          // suppressHorizontalScroll={true}
          // suppressRowTransform={true}
          // suppressColumnMoveAnimation={false}
          defaultColDef={{
            resizable: true
          }}
          onSelectionChanged={props.handleConstraintSelection}
          rowSelection="multiple"
          floatingFilter={true}
          suppressMenu={true}
          suppressRowClickSelection={true}
          frameworkComponents={{
            editOrDelete: EditOrDelete,
            aidRenderComponent: AidRenderComponent
          }}
          enableCellTextSelection={true}
          getRowStyle={getRowStyle}
          isRowSelectable={isRowSelectable}
        />
      </div>
    </React.Fragment>
  );
};

export default ConstraintsTable;
