import React, { Component } from 'react'
import { connect } from 'react-redux'
import { gaUtilsObj } from './common/GAUtils'
import { Route, Switch, Redirect, withRouter } from 'react-router-dom'
import Layout from './components/Layout'
import './App.css'
import {generateUniqueIdFromEmaiId} from "./utils/helperFunctions"
import { userInfo , updateUserSession} from './actions/userManagementAction'
import PageLoader from './utils/Loader'
import environment from './environments/environment';
import SessionExpire from './components/SessionExpire'


class App extends Component {
  constructor(props) {
    super(props);
    let isChrome = !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime);
    this.state = {
      // isNotAChrome: !isChrome
      isNotAChrome: false,
      previousXAxis: 0, 
      previousYAxis: 0,
      activeXAxis: 0, 
      activeYAxis: 0,
      isSessionUpdated: false
    };
}
  async componentDidMount() {
    if(this.state.isNotAChrome){
      window.alert("Please open app in google chrome browser");
    } else {
      await this.props.logginUserInfo()
      if((this.props.location.pathname === "/vis/budget-planning" && this.props.location.search.includes("?planId=")) && this.props.userInfo.email === undefined){
        const emailLink = this.props.location.pathname + this.props.location.search
        localStorage.setItem('emailLink',emailLink)
      }
    }
  const interval =   setInterval(async() => {
      // console.log("=============",this.state ,(this.state.activeXAxis !== this.state.previousXAxis) , (this.state.activeYAxis !== this.state.previousYAxis), this.state.isSessionUpdated)
      if((this.state.activeXAxis !== this.state.previousXAxis) && (this.state.activeYAxis !== this.state.previousYAxis) && this.state.isSessionUpdated){
        // console.log(this.state)
        await this.props.updateUserSession();
        this.setState({
          isSessionUpdated: false
        })
      }
    }, 60000);
  }
  
  componentDidUpdate(oldProps) {
    
    if(oldProps !== this.props) {
      const { email ,id } = this.props.userInfo
      gaUtilsObj.setUserId(id);
      // console.log('=======react ga======', environment.trackingID);
      // console.log(id)
      // ReactGA.initialize({
      //   trackingId: environment.trackingID,
      //   gaOptions: {
      //     // userId: generateUniqueIdFromEmaiId(email)
      //     userId: id
      //   },
      //   debug: true,
      // })
      // ReactGA.pageview(window.location.pathname + window.location.search)
    }
  }

  _onMouseMove(e) {
    this.setState({ 
      previousXAxis: this.state.activeXAxis,
        previousYAxis: this.state.activeYAxis,
         activeXAxis: e.screenX, 
         activeYAxis: e.screenY,
         isSessionUpdated:true
     });
  }

  render() {
    let routesList = (
      <Switch>
        <Route path="/vis" component={Layout} />
        <Redirect from="/" to="/vis" />
      </Switch>
    )
    
    if(this.state.isNotAChrome){
      return (
        <div className="App">
        <div className="text-center text-primary" style={{padding:"15%"}}>
          Please open app in google chrome browser
        </div>
        </div>
      )
    }else{
      
      return (
        <div className="App">
        
          {this.props.isAuthenticated ? (
            <>
            <div onMouseMove={this._onMouseMove.bind(this)}>
              <SessionExpire isSessionExpired={this.props.isSessionExpired}/>
              {routesList}
            </div>
            </>
          ) : (
            <>
              <PageLoader loader={!this.props.isAuthenticated}>
                <div style={{ height: '600px' }}>Loading...</div>
              </PageLoader>
            </>
          )}
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.userManagementData.isAuthenticated,
    userInfo: state.userManagementData.userInfo,
    isSessionExpired: state.userManagementData.isSessionExpired
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    logginUserInfo: (val) => dispatch(userInfo(val)),
    updateUserSession: (val)=> dispatch(updateUserSession(val))
  }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
