import React from 'react';
import { Button, Popover, PopoverHeader, PopoverBody } from 'reactstrap';
import { Route, Redirect } from 'react-router';
import moment from 'moment';
import { connect } from 'react-redux';
import { convertMillionsFormatter } from '../../utils/AgGridFormatters'
import { withRouter } from 'react-router-dom';
import _ from 'underscore';

class PopoverItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popoverOpen: false
    };
  }

  handleClickOutside = () => {
    this.setState({
      popoverOpen: false
    });
  }

  componentDidMount() {
    document.addEventListener('scroll', this.handleClickOutside);
  }

  toggle = e => {
    if (e.target.nodeName.toLowerCase() !== 'button') {
      this.setState({
        popoverOpen: !this.state.popoverOpen
      });
    }
  };
  getData = (e, val) => {
    this.props.onTabClick(val);
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  };
  getRecommendedData = (val, depData, catData, subcatData, skuData) => {
    var campaignID = val.id;
    var startDate = val.start_date;
    var endDate = val.end_date;

    var url =
      '/vis/recommend/promo?campaignID=' +
      campaignID +
      '&startDate=' +
      startDate +
      '&endDate=' +
      endDate;
    var queryArray = [];
    if (depData) {
      queryArray.push('department=' + depData);
    }
    if (catData) {
      queryArray.push('category=' + catData);
    }
    if (subcatData) {
      queryArray.push('subCategory=' + subcatData);
    }
    if (queryArray.length > 0) {
      url = url + '&' + queryArray.join('&');
    }
    window.location.assign(url);
  };
  copyCampaignData = (campaignId, promoId) => {
    this.props.onClickCopyAdgroup(campaignId, promoId);
  };
  render() {
    let promoId = _.uniq(this.props.info.promo_ids);
    let startDate = new Date(this.props.info.start_date),
      todayDate = new Date(),
      copyClassName =
        startDate < todayDate
          ? 'btn btn-outline-primary btnCalendarPopover no-pointer-event'
          : 'btn btn-outline-primary btnCalendarPopover';
    return (
      <div
        id={'Popover-' + this.props.id}
        className="doticonsPopup"
        style={{
          width: '100%',
          position: 'relative',
          zIndex: '100',
          border: 'none !important'
        }}
        //onClick={() => this.getData(this.props.info)}
      >
        <span className="mr-1" style={{ cursor: 'pointer' }}>
          {/* <span style={{ margin: "5px 0 0 0"}}>
            <i className="fa fa-ellipsis-v" aria-hidden="true" />
          </span> */}
        </span>
        <div className="popoverDivMain" style={{ border: 'none !important' }}>
          <Popover
            className="popover33"
            // container={this.props.container}
            placement={this.props.item.placement}
            isOpen={this.state.popoverOpen}
            target={'Popover-' + this.props.id}
            trigger="legacy"
            placement="top"
            toggle={this.toggle}
            modifiers={{ preventOverflow: { enabled: false } }}
            eventsEnabled={true}
            positionFixed={false}
            // boundariesElement={`Popover-${this.props.id}`}
          >
            <div id="popover-content">
              <div className="divPopover">
                <div className="row">
                  <div className="col-md-7 popover-colon px-0">
                    <p className="popovertext">
                      Name
                    </p>
                  </div>
                  <div className="col-md-5">
                    <p className="popovertext1">{this.props.info.name}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7 popover-colon px-0">
                    <p className="popovertext">
                      Start Date
                    </p>
                  </div>
                  <div className="col-md-5">
                    <p className="popovertext1">
                      {moment(this.props.info.start_date).format('MM/DD/YYYY')}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7 popover-colon px-0">
                    <p className="popovertext">
                      End Date
                    </p>
                  </div>
                  <div className="col-md-5">
                    <p className="popovertext1">
                      {moment(this.props.info.end_date).format('MM/DD/YYYY')}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7 popover-colon px-0">
                    <p className="popovertext"># of Offers</p>
                  </div>
                  <div className="col-md-5">
                    <p className="popovertext1">
                      {this.props.info.offersCount}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7 popover-colon px-0">
                    <p className="popovertext">Baseline Revenue</p>
                  </div>
                  <div className="col-md-5">
                    <p className="popovertext1">
                    {`$${convertMillionsFormatter(this.props.info.baselinesales)}`}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7 popover-colon px-0">
                    <p className="popovertext">Incremental Revenue</p>
                  </div>
                  <div className="col-md-5">
                    <p className="popovertext1">
                    {`$${convertMillionsFormatter(this.props.info.directincrementalsales)}`}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-7 popover-colon px-0">
                    <p className="popovertext">Total Revenue</p>
                  </div>
                  <div className="col-md-5">
                    <p className="popovertext1">
                    {`$${convertMillionsFormatter(this.props.info.totalsales)}`}
                    </p>
                  </div>
                </div>

                {/* <div className="row">
                  <div className="col-md-6">
                    <p className="popovertext">No. of Skus:</p>
                  </div>
                  <div className="col-md-6">
                    <p className="popovertext1">
                      {this.props.info.sku_count
                        ? this.props.info.sku_count.toLocaleString()
                        : ""}
                    </p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <p className="popovertext">No. of AdGroups:</p>
                  </div>
                  <div className="col-md-6">
                    <p className="popovertext1">
                      {this.props.info.adgroup_count}
                    </p>
                  </div>
                </div> */}
                {/* <div className="row">
                  <div className="col-md-6">
                    <p className="popovertext">No. of Stores:</p>
                  </div>
                  <div className="col-md-6">
                    <p className="popovertext1">{this.props.info.store}</p>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <p className="popovertext">No. of Offers:</p>
                  </div>
                  <div className="col-md-6">
                    <p className="popovertext1">{this.props.info.offer}</p>
                  </div>
                </div> */}
                <div className="buttonbottom1">
                  <div className="row">
                    <div className="col-md-12 text-middle">
                      <button
                        type="button"
                        className="btn btn-primary btnCalendarPopover"
                        style={{ marginLeft: '-5px' }}
                        // onClick={() => this.props.showPromotionMain()}
                        onClick={e => {
                          e.preventDefault();
                          e.stopPropagation();
                          return this.getData(e, this.props.info);
                        }}
                      >
                        Details
                      </button>
                    </div>
                    {/* <div className="col-md-8">
                      <button
                        type="button"
                        className="btn btn-primary btnCalendarPopover"
                        style={{ marginLeft: "10px" }}
                        onClick={() =>
                          this.getRecommendedData(
                            this.props.info,
                            this.props.selectedDepartment,
                            this.props.selectedCategory,
                            this.props.selectedSubCategory,
                            this.props.selectedSKU
                          )
                        }
                      >
                        <a title="Recommended Promos">Rec. Promos <i class="fa fa-chevron-right"></i></a>
                      </button>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </Popover>
        </div>
      </div>
    );
  }
}

class PopoverExampleMulti extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      popovers: [
        {
          placement: 'bottom',
          text: 'Left'
        }
      ]
    };
  }

  render() {
    return (
      <div>
        {this.state.popovers.map((popover, i) => {
          return (
            <PopoverItem
              key={i}
              item={popover}
              id={this.props.id}
              info={this.props.promoInfo}
              showPromotionMain={this.props.showPromotionMain}
              onTabClick={this.props.onTabClick}
              showPromotionMainWithValues={
                this.props.showPromotionMainWithValues
              }
              onClickCopyAdgroup={(campaignId, promoId) => {
                this.props.onClickCopyAdgroup(campaignId, promoId);
              }}
            />
          );
        })}
      </div>
    );
  }
}

export default connect()(withRouter(PopoverItem));
