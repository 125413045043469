import axios from "axios";
import {
  SET_DMMTABLE_COLDATA, SET_DMM_ROWDATA,
  SET_OFFERTABLE_COLDATA, SET_OFFER_ROWDATA,SET_OFFER_PINNEDDATA, SET_QUARTERDATA, SET_BUYERDATA, SET_FYEARDATA,
  RESET_FILTERS, SET_CATEGORYDATA, SET_VENDORDATA, SET_PLANIDDATA, GET_ERRORS, LOADER
} from "./actionTypes"
import { removePrecisionFormatter, convertMillionsFormatterWithoutPrecision, validateCellValue, formatToTwoDecimals, precisionFormatter, removePrecisionFormatterPlanSummary, convertMillionsFormatterWithoutPrecisionPlanSummary } from "../../utils/AgGridFormatters";
import environment from '../../environments/environment';
import { ToastMessage } from "../../common/ToastMessage";
import { fetchDateMappingFilterData } from '../../apis/common.api.';
import _, { forEach } from 'lodash';
const nameMapping = {
  "offer": "# Offers",
  "offerDepth": "Avg.Offer depth $",
  "totOffer": "Tot.Offer $",
  "vendorFunded": "Vendor Funding $",
  "reedeem": "Redemption $",
  "sales": "Sales $",
  "units": "Volumes",
  "margin": "Margin $",
  "incrementalRevenue": "Incr.Sales $",
  "incrementalMargin": "Incr.Margin $",
  "roi": "ROI",
  "promoted": "# Items Promoted",
};
export const getDMMLevelData = (reqObj, typeSelected) => dispatch => {
  reqObj['type'] = typeSelected;
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(environment.baseUrl + "/planSummary/dmm_level", reqObj)
    .then(res => {
      if (res.data.data.length !== 0) {
        let rowData = getDMMRowData(res.data.data);
        let columnDef = getDMMColData(res.data.data);
        console.log("coldef in action:", columnDef);
        dispatch({
          type: SET_DMMTABLE_COLDATA,
          payload: columnDef
        });
        dispatch({
          type: SET_DMM_ROWDATA,
          payload: rowData
        });
        dispatch({
          type: LOADER,
          payload: false
        });
      } else {
       ToastMessage(403, "No data in DMM level plan selected");
        dispatch({
          type: LOADER,
          payload: false
        });
      }

    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    });
};

export const getQuarterData = () => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  try {
    let res = await fetchDateMappingFilterData({ screenName: "Plan Summary" });
    let fYearList = []; let quarterList = []; let quarterOptions = []; let fYearOptions = [];
    if (res.length > 0) {
      let resData = res;
      resData.forEach(obj => {
        quarterList.push(obj.quater);
        fYearList.push(obj.year);
      });
      quarterList = quarterList.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      quarterList=quarterList.sort();
      quarterList.map((obj) => {
        obj = "Q" + obj;
        quarterOptions.push({ label: obj, value: obj });
      })
      fYearList = fYearList.filter((value, index, self) => {
        return self.indexOf(value) === index;
      });
      fYearList.map((obj) => {
        fYearOptions.push({ label: obj, value: obj });
      })
    }
    dispatch({
      type: SET_QUARTERDATA,
      payload: quarterOptions
    });
    dispatch({
      type: SET_FYEARDATA,
      payload: fYearOptions
    });
    dispatch({
      type: LOADER,
      payload: false
    });
  } catch (err) {
    dispatch({
      type: GET_ERRORS,
      payload: err
    });
    dispatch({
      type: LOADER,
      payload: false
    });
  }
}

export const getBuyerData = (reqObj) => dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .get(environment.baseUrl + "/planSummary/buyer")
    .then(res => {
      let buyerList = [];
      if (res.data.data.length > 0) {
        res.data.data.map((obj) => {
          // let buyers = obj['buyers'];
          // buyers.map((object) => {
            buyerList.push({ label: obj.email, value: obj.email });
          // });
        });
      }
      dispatch({
        type: SET_BUYERDATA,
        payload: buyerList
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    });
}

export const getCategoryData = (reqObj) => dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(environment.baseUrl + "/planSummary/category", reqObj)
    .then(res => {
      let categoryList = [];
      if (res.data.data.length > 0) {
        res.data.data.map((obj) => {
          // let categories = obj['categories'];
          // categories.map((object) => {
            categoryList.push({ label: obj, value: obj });
          // })
        });
      }
      dispatch({
        type: SET_CATEGORYDATA,
        payload: categoryList
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    });
}

export const getVendorData = (reqObj) => dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(environment.baseUrl + "/hierarchy/filters/vendor", reqObj)
    .then(res => {
      let vendorList = [];
      if (res.data.data.length > 0) {
        res.data.data.map((obj) => {
          vendorList.push({ label: obj, value: obj });
        })
      }
      dispatch({
        type: SET_VENDORDATA,
        payload: vendorList
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    });
}

export const getPlanIDData = (reqObj) => dispatch => {
  console.log("req:", reqObj);
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(environment.baseUrl + "/planSummary/plans", reqObj)
    .then(res => {
      let planIDList = [];
      if (res.data.data.length > 0) {
        // if(res.data.data[0]['planids']!=null){
          // res.data.data[0]['planids'].map((obj) => {
          res.data.data.forEach((obj) => {
          planIDList.push({ label: `${obj.planId}_${obj.planName}`, value: `${obj.planId}` });
        })
      // }
    }

      dispatch({
        type: SET_PLANIDDATA,
        payload: planIDList
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    });
}

export const resetFilters = () => dispatch => {
  dispatch({
    type: RESET_FILTERS
  });
}

const formatArticleCampaignLevelToArticleLevel = (planArticleCampaigns,field, year) => {
  const articleNoToArticleLevelFinalized = {};
  const articleNoToArticleLevelLy={};
  const articleNoToArticleLevel={};
  const books = [];
  if(field=='Finalized'){
  planArticleCampaigns.forEach(articleCampaign => {
    const {articleNo} = articleCampaign;
    let len= articleCampaign['campaignId'].length;
    const campaignId= articleCampaign['campaignId'].slice(0,len-4)+year;
    const campaignName = articleCampaign['campaignId'].slice(0,len-5);
    const articleDetails={
      'articleNo':Number(articleCampaign['articleNo']),
      'hierarchyLevel6': articleCampaign['hierarchyLevel6'],
      'vendor': articleCampaign['vendor'],
      'offerIdFinalized':articleCampaign['promoIdFinalized']
    }
     const articleLevelFinalized = articleNoToArticleLevelFinalized[articleNo] || articleDetails;
     articleLevelFinalized[`${campaignName}_articleNo_Finalized`] = articleCampaign['promoIdFinalized'];
     articleLevelFinalized[`${campaignId}_roi`] = articleCampaign['roiFinalized'];
     articleLevelFinalized[`${campaignId}_offerType`]=articleCampaign['mediaTypeFinalized'];
     articleLevelFinalized[`${campaignId}_netSales`]=articleCampaign['revenueFinalized'];
     articleLevelFinalized[`${campaignId}_couponValue`]=articleCampaign['discountFinalized'];
     articleLevelFinalized[`${campaignId}_incrementalSales`] = articleCampaign['incrementalRevenueFinalized'];
     articleLevelFinalized[`${campaignId}_spendInsertionFee`] = articleCampaign['spendFinalized'] + articleCampaign['insertionFeeFinalized'];
     articleNoToArticleLevelFinalized[articleNo] = articleLevelFinalized;
    if (!books.includes(campaignId)) books.push(campaignId);
  });
  const sortedBooksFinalized = books.sort((a, b) => (parseInt(a.split('-')[1]) - parseInt(b.split('-')[1])));
  return({
    articleNoToArticleLevelFinalized:articleNoToArticleLevelFinalized,
    sortedBooksFinalized:sortedBooksFinalized
  })
}
else if(field=='Ly'){
  planArticleCampaigns.forEach(articleCampaign => {
    const {articleNo} = articleCampaign;
    let len= articleCampaign['campaignId'].length;
    const campaignId= articleCampaign['campaignId'].slice(0,len-4)+year;
    const campaignName = articleCampaign['campaignId'].slice(0,len-5);
    const articleDetails={
      'articleNo':Number(articleCampaign['articleNo']),
      'hierarchyLevel6': articleCampaign['hierarchyLevel6'],
      'vendor':articleCampaign['vendor'],
      'offerIdLy':articleCampaign['promoIdLy']
    }
     const articleLevelLy = articleNoToArticleLevelLy[articleNo] || articleDetails;
     articleLevelLy[`${campaignName}_articleNo_ly`] = articleCampaign['promoIdLy'];
     articleLevelLy[`${campaignId}_roi`] = articleCampaign['roiLy'];
     articleLevelLy[`${campaignId}_offerType`]=articleCampaign['mediaTypeLy'];
     articleLevelLy[`${campaignId}_netSales`]=articleCampaign['revenueLy'];
     articleLevelLy[`${campaignId}_couponValue`]=articleCampaign['discountLY'];
     articleLevelLy[`${campaignId}_incrementalSales`] = articleCampaign['incrementalRevenueLy'];
     articleLevelLy[`${campaignId}_spendInsertionFee`] = articleCampaign['spendLy'] + articleCampaign['insertionFeeLy'];
     articleNoToArticleLevelLy[articleNo] = articleLevelLy;
    if (!books.includes(campaignId)) books.push(campaignId);
  });
  const sortedBooksLy = books.sort((a, b) => (parseInt(a.split('-')[1]) - parseInt(b.split('-')[1])));
  return({
    articleNoToArticleLevelLy:articleNoToArticleLevelLy,
    sortedBooksLy:sortedBooksLy
  })
}
else if(field==''){
  planArticleCampaigns.forEach(articleCampaign => {
    const articleNo= articleCampaign['article_no'];
    let len = articleCampaign['campaign_id'].length;
    const campaign_id = articleCampaign['campaign_id'].slice(0,len-4)+year;
    const campaignName = articleCampaign['campaign_id'].slice(0,len-5);
    const articleDetails={
      'articleNo':Number(articleCampaign['article_no']),
      'hierarchyLevel6': articleCampaign['ah6'],
      'vendor':articleCampaign['vendor'],
      'offerId':articleCampaign['promo_id'],
      
    }
     const articleLevel = articleNoToArticleLevel[articleNo] || articleDetails;
     articleLevel[`${campaignName}_articleNo_lly`] = articleCampaign['promo_id'];
     articleLevel[`${campaign_id}_roi`] = articleCampaign['roi'];
     articleLevel[`${campaign_id}_offerType`]=articleCampaign['offer_type'];
     articleLevel[`${campaign_id}_netSales`]=articleCampaign['modeled_net_sales'];
     articleLevel[`${campaign_id}_couponValue`]=articleCampaign['coupon_value'];
     articleLevel[`${campaign_id}_incrementalSales`] = articleCampaign['modeled_inc_sales'];
     articleLevel[`${campaign_id}_spendInsertionFee`] = articleCampaign['reedemed_insertionfees'] ;
     articleNoToArticleLevel[articleNo] = articleLevel;
    if (!books.includes(campaign_id)) books.push(campaign_id);
  
  })
  const sortedBooks = books.sort((a, b) => (parseInt(a.split('-')[1]) - parseInt(b.split('-')[1])));
  return ({
    articleNoToArticleLevel: articleNoToArticleLevel,
    sortedBooks:sortedBooks
  })
}
}
const findROITotal=(tableData,field)=>{
    let incSalesTotal=0, spendInsertionFee=0;
    tableData.forEach(row=>{
      for(const key in row){
        if(key.includes('incrementalSales')){
          let keyVal=field.slice(0, field.length-3)+'incrementalSales';
          if(row[keyVal]!=undefined)
            incSalesTotal+=row[keyVal];
        }
        else if(key.includes('spend')){
          let keyVal= field.slice(0,field.length-3)+'spendInsertionFee';
          if(row[keyVal]!=undefined)
            spendInsertionFee+=row[keyVal];
        }
      }
    });
    return spendInsertionFee>0? incSalesTotal/spendInsertionFee: 0;

}
const findSum=(tableData,field)=>{
  let sum=0;
  tableData.forEach(row=>{
      if(row[field]!='-' && row[field]!=undefined)
          sum+=row[field];
  });
  return sum;
}
const findAvg=(tableData,field)=>{
  let len=0,sum=0,avg;
  tableData.forEach(row=>{
      if(row[field]!='-' && row[field]!=undefined){
          sum+=row[field];
          len++;
      }
  })
  avg= sum>0?sum/len:0;
  return avg;
}
export const createTotalRowData=(tableData, columnDefs, bookIds)=>{
  let columns= columnDefs;
  let pinnedRowData=[], pinnedData={}, spendInsertionFee=0,incSalesTotal=0;
  columns.forEach(column=>{
      if(column.headerName.includes('Book')){
        let bookName = column.headerName.split(" ")[1];
        pinnedData[`BBM-${bookName}_articleNo`]="BBM Total";
          let bookArray= column.children;
          bookArray.forEach(book=>{
              let columnsArray= book.children;
              columnsArray.forEach(colData=>{
                  if(colData.headerName=='Offer Type'){
                      pinnedData[colData.field]="-";
                  }
                  else if(colData.headerName=="Coupon Value $"){
                      pinnedData[colData.field]=findAvg(tableData,colData.field);
                  }
                  else if(colData.headerName.includes('Inc.Sales')){
                      incSalesTotal=findSum(tableData,colData.field);
                      pinnedData[colData.field]=incSalesTotal;
                  }
                  else if(colData.headerName.includes('Net Sales') || 
                      colData.headerName.includes('Redeemed')){
                      pinnedData[colData.field]=findSum(tableData,colData.field);
                  }
                  else if(colData.headerName.includes('ROI')){
                    let roi=findROITotal(tableData,colData.field);
                    pinnedData[colData.field]=roi;
                  }
              })
          })
      }
  })
  bookIds.forEach(bookId=>{
      let field=bookId.slice(0, bookId.length-5)+"_articleNo";
      pinnedData[field]="BBM Total";
  })
  console.log("pinnedData:",pinnedData);
  pinnedRowData.push(pinnedData);
  return pinnedRowData;
}
const mergeRowData=(rowDataObj ,bookId)=>{
    let rowDataMap={};
    for(const row of rowDataObj){
        let key=row['articleNo'];
        rowDataMap[key]=rowDataMap[key] ||{};
        for(const field in row){
          rowDataMap[key][field]=row[field];
        }
    }
    let rowArray=Object.values(rowDataMap);
    rowArray=formatOfferArticleData(rowArray,bookId);
    return rowArray;
}

const formatOfferArticleData=(rowData , bookId)=>{
    let rowDataMap={};
    for(const row of rowData){
      let articleNo= row['articleNo'];
      let offerIdFinalized= (row['offerIdFinalized']!=undefined&& row['offerIdFinalized']!="New" && row['offerIdFinalized']!="")? row['offerIdFinalized']:"-";
      let offerIdLy=(row['offerIdLy']!=undefined && row['offerIdLy']!="New" && row['offerIdLy']!="")?row['offerIdLy']:"-";
      let offerId=(row['offerId']!=undefined && row['offerId']!="New" && row['offerId']!="")? row['offerId']:"-";
      let offerIdFinal="("+offerIdFinalized+","+offerId+","+offerIdLy+")";
      let key=articleNo+" "+offerIdFinal;
      row['articleNo']=key;
      bookId.forEach(bookObj=>{
      // let articleNo= articleNo;
      let campaginName = bookObj.slice(0, bookObj.length-5);
      let book_offerIdFinalized= (row[`${campaginName}_articleNo_Finalized`]!=undefined&& row[`${campaginName}_articleNo_Finalized`]!="New" && row[`${campaginName}_articleNo_Finalized`]!="")? row[`${campaginName}_articleNo_Finalized`]:"-";
      let book_offerIdLy=(row[`${campaginName}_articleNo_ly`]!=undefined && row[`${campaginName}_articleNo_ly`]!="New" && row[`${campaginName}_articleNo_ly`]!="")?row[`${campaginName}_articleNo_ly`]:"-";
      let book_offerId=(row[`${campaginName}_articleNo_lly`]!=undefined && row[`${campaginName}_articleNo_lly`]!="New" && row[`${campaginName}_articleNo_lly`]!="")? row[`${campaginName}_articleNo_lly`]:"-";
      let book_offerIdFinal="("+book_offerIdFinalized+","+book_offerId+","+book_offerIdLy+")";
      let book_key=articleNo+" "+book_offerIdFinal;
        row[`${campaginName}_articleNo`]= book_key;
      })
    }
    return rowData;
}

const formatOfferLevelRowData =(offerData)=>{
  let dataLy= Object.values(offerData.dataLy);
  let dataFinalized=Object.values(offerData.dataFinalized);
  let data=Object.values(offerData.data);
  dataLy=dataLy.concat(dataFinalized);
  let rowData= dataLy.concat(data);
  rowData= mergeRowData(rowData, offerData.bookId)
  return {rowData};
}
const formatOfferData = (offerData) => {
  let defaultBookName = offerData.books[0].slice(0,offerData.books[0].length -5);
  const columnDefs = [
    {
      headerName: "Article No-OfferId",
      suppressSizeToFit: true,
      field: `${defaultBookName}_articleNo`,
      headerTooltip:"Article Number",
      width: 120,
      pinned: "left",
      sortable: true,
      filter: true,
      cellStyle: { color: "#44677b", fontWeight: 600 }
    },
    {
      headerName: "AH6",
      field: 'hierarchyLevel6',
      headerTooltip:"Name of the Category Type",
      width: 80,
      sortable: true,
      filter: true,
      pinned:"left",
      valueFormatter: (params)=>{
          return params.value == undefined? "-": params.value
      }
    },
    {
      headerName: "Vendor",
      field: 'vendor',
      headerTooltip:"Name of the Vendor",
      width: 80,
      sortable: true,
      filter: true,
      pinned:"left",
      valueFormatter: (params)=>{
        return params.value == undefined? "-": params.value
      }
    }
  ];
  const bookIds = []
  let bookTemplate, yearTemplate;
  let yearData= offerData.yearObj;
  yearData=yearData.sort((a,b)=>{
    return a-b;
  });
  yearData=yearData.reverse();
  offerData.yearObj=yearData;
  offerData.books.forEach((book)=>{
    const bookNumber = book.split("-")[1];
      bookTemplate={
          headerName: `Book ${bookNumber}`,
          children:[]
    }
    offerData.yearObj.forEach((obj)=>{
      let year= obj;
      const bookId= book.slice(0, book.length-4)+year;
      bookIds.push(bookId);
        yearTemplate={
        headerName: `FY${year}`,
        children: [
           {
            headerName: "Offer Type",
            field: `${bookId}_offerType`,
            headerTooltip:"Name of the offer",
            width: 80,
            sortable: true,
            filter: true,
            valueFormatter: (params)=>{
              return params.value == undefined? "-": params.value
          }
          },
          {
            headerName:"Coupon Value $",
            field:`${bookId}_couponValue`,
            headerTooltip:"Effective discount offered for the article in the promo",
            width:80,
            sortable:true,
            valueFormatter: (params) => {
              const value = precisionFormatter(params);
              return validateCellValue(value) ? value : ('$' + value);
            }
          },
          {
            headerName:"Net Sales $",
            field:`${bookId}_netSales`,
            headerTooltip:"Net sales",
            width:100,
            sortable:true,
            valueFormatter: (params) => {
              //const value = removePrecisionFormatter(params);
              const value= removePrecisionFormatterPlanSummary(params);
              return validateCellValue(value) ? value : ('$' + value);
            }
          },
          {
            headerName: "Inc.Sales $",
            field: `${bookId}_incrementalSales`,
            headerTooltip:"Incremental Sales",
            width: 100,
            valueFormatter: (params) => {
              //const value = removePrecisionFormatter(params);
              const value= removePrecisionFormatterPlanSummary(params);
              return validateCellValue(value) ? value : ('$' + value);
            }
          },
          {
            headerName: "Redeemed $ + Ins.Fee",
            field: `${bookId}_spendInsertionFee`,
            headerTooltip:"Redemption+InsertionFee",
            width: 100,
            valueFormatter: (params) => {
              //const value = removePrecisionFormatter(params);
              const value= removePrecisionFormatterPlanSummary(params);
              return validateCellValue(value) ? value : ('$' + value);
            }
          },
          {
            headerName: "ROI",
            field: `${bookId}_roi`,
            headerTooltip:"ROI",
            width: 80,
            valueFormatter: (params)=>{
              return precisionFormatter(params);
            }
          }
        ]
      }
      bookTemplate.children.push(yearTemplate);
    });
    columnDefs.push(bookTemplate);      
  })
  offerData.bookId = bookIds;
  const {rowData} = formatOfferLevelRowData(offerData);
  const pinnedRowData=createTotalRowData(rowData, columnDefs, bookIds);
  return { columnDefs, rowData, pinnedRowData};
}
const fetchOfferLevelData=(reqObj)=>{
  return axios
  .post(environment.baseUrl + "/planSummary/offer_level", reqObj)
  .then(res => {
      console.log("response:",res);
      return res;
  });
}
export const getOfferLevelData = (reqObj) => async dispatch => {
  dispatch({
    type: LOADER,
    payload: true
  });
    try{
      let offerLevelResp= await fetchOfferLevelData(reqObj);
      let data, dataLy, dataFinalized, yearLy, year, yearFinalized;
    for(const key in offerLevelResp.data.data){
        if(key.includes('Ly')){
            yearLy=key.split('-')[0];
            dataLy=offerLevelResp.data.data[key];
        }
        else if(key.includes('Finalized')){
          yearFinalized=key.split('-')[0];
          dataFinalized=offerLevelResp.data.data[key];
        }
        else if(key != 'totalData'){
          year=key;
          data=offerLevelResp.data.data[key];
        }
    }
    const {articleNoToArticleLevelLy, sortedBooksLy}= formatArticleCampaignLevelToArticleLevel(dataLy,"Ly",yearLy);
    const {articleNoToArticleLevelFinalized, sortedBooksFinalized}= formatArticleCampaignLevelToArticleLevel(dataFinalized,"Finalized", yearFinalized);
    const {articleNoToArticleLevel, sortedBooks}= formatArticleCampaignLevelToArticleLevel(data,"", year);
    const offerLevelData = {
      yearObj:[yearFinalized,yearLy,year],
      books:sortedBooksFinalized,
      dataFinalized: articleNoToArticleLevelFinalized,
      dataLy:articleNoToArticleLevelLy,
      data:articleNoToArticleLevel,
      bbmTotal:offerLevelResp.data.data.totalData
    }
     let { columnDefs, rowData, pinnedRowData} = formatOfferData(offerLevelData);
    dispatch({
      type: SET_OFFERTABLE_COLDATA,
      payload: columnDefs
    });
    dispatch({
      type: SET_OFFER_ROWDATA,
      payload: rowData
    });
    dispatch({
      type:SET_OFFER_PINNEDDATA,
      payload: pinnedRowData
    });
    dispatch({
      type: LOADER,
      payload: false
    });
  }
  catch (err){
    dispatch({
      type: GET_ERRORS,
      payload: err
    });
    dispatch({
      type: LOADER,
      payload: false
    });
  }
}

const formatter = (value) => {
  let tempVal = removePrecisionFormatterPlanSummary({ value });
  let result = validateCellValue(tempVal) ? tempVal : ('$' + tempVal);
  return result;
}

const formattedValue = (value, mappingKey) => {
  let result;
  if (value == undefined || value == null)
    result = '-';
  if (mappingKey.includes('$')){
    result = formatter(value);
  }
  else if (mappingKey == 'Volumes')
    result = convertMillionsFormatterWithoutPrecisionPlanSummary({ value });
  else if (mappingKey == 'ROI')
    result = formatToTwoDecimals(value);
  return result != undefined ? result : value;
}
/* 
  utility compare function to sort columns based on keys where field is 
  of format Book<Number>
*/
const compare = (a, b) => {
  let num1 = Number(a.field.substring(4));
  let num2 = Number(b.field.substring(4));
  return num1 - num2;
}
const getDMMColData = (response) => {
  let lyColdef, LYH_template, TYP_template, TYM_template;
  lyColdef =
  {
    headerName: " ",
    field: "campaignType",
    pinned: 'left',
    width: 180,
    cellStyle: { color: "#44677b", fontWeight: 600 },
    suppressSizeToFit: true,
  }
  LYH_template=[
    {
      headerName: " ",
      field: "campaignType",
      pinned: 'left',
      width: 180,
      cellStyle: { color: "#44677b", fontWeight: 600 },
      suppressSizeToFit: true,
    }
  ]
  let LY_Historical={
    headerName: "LY historical (based on modeled values)",
    children: []
  }
  TYP_template = {
    headerName: "TY Promosmart Optimized Plan",
    children: []
  }

  TYM_template = {
    headerName: "TY Merchant Revised Plan",
    children: []
  }

  for (const obj of response) {
    let bookNo = obj['campaignId'].split('-')[1];
    let bookDef = {
      headerName: `Book${bookNo}`,
      field: `Book${bookNo}`,
      headerTooltip:`Book${bookNo}`
    };
    LY_Historical.children.push(bookDef);
    TYP_template.children.push(bookDef);
    TYM_template.children.push(bookDef);
  }
  let headers = {
    headerName: 'Quarter Total',
    field: 'quarterTotal',
    headerTooltip:"Quarter Total"
  };
  LY_Historical.children.push(headers);
  LY_Historical.children=LY_Historical.children.sort(compare);
  TYP_template.children.push(headers);
  TYP_template.children = TYP_template.children.sort(compare);
  TYM_template.children.push(headers);
  TYP_template.children = TYM_template.children.sort(compare);
  LYH_template.push(LY_Historical);
  console.log("LY Template:",LYH_template);
  let columnDef = {
    LYH: [],
    TYP: [],
    TYM: []
  };
  columnDef.LYH=LYH_template;
  columnDef.TYP.push(TYP_template);
  columnDef.TYM.push(TYM_template);
  return columnDef;
}

const getDMMRowData = (response) => {
  let LYHMap = {}, TYPMap = {}, TYMMap = {};
  const itr = response[0];
  let keys = Object.keys(itr);
  keys.forEach((key) => {
    if (key.endsWith('Ly')) {
      LYHMap[key] = {};
    }
    else if (key.endsWith('Optimized')) {
      TYPMap[key] = {};
    }
    else if (key.endsWith('Finalized')) {
      TYMMap[key] = {};
    }
  })
  let offerLYquartTot = 0, promotedLYquartTot = 0,
      offerTYPquartTot = 0, promotedTYPquartTot = 0,
      offerTYMquartTot = 0, promotedTYMquartTot = 0;
  for (const res of response) {
    let bookNo = res['campaignId'].split('-')[1], len, mappingKey;
    let lyKeys = Object.keys(LYHMap);
    let typKeys = Object.keys(TYPMap);
    let tymKeys = Object.keys(TYMMap);
    lyKeys.forEach(lyKey => {
      len = lyKey.length;
      mappingKey = nameMapping[lyKey.substring(0, len - 2)];
      let quarterValue = (lyKey == 'salesLy') ? res['quarter_total']['revenueLy'] : 
          (lyKey=='reedeemLy'? res['quarter_total']['budgetPlannedLy']: res['quarter_total'][lyKey]);
      LYHMap[lyKey]['campaignType'] = mappingKey;
      LYHMap[lyKey][`Book${bookNo}`] = formattedValue(res[lyKey], mappingKey);
      if(lyKey == "offerLy")
      {
        offerLYquartTot = +offerLYquartTot + formattedValue(Number(res[lyKey]), mappingKey);
      } 
      else if (lyKey == "promotedLy") {
        if (res[lyKey] !== null) {
          promotedLYquartTot = +promotedLYquartTot + formattedValue(Number(res[lyKey]), mappingKey);
        }
      }
      else {
        LYHMap[lyKey]['quarterTotal'] = formattedValue(quarterValue, mappingKey);
      }
    })
    LYHMap["offerLy"]['quarterTotal'] = offerLYquartTot;
    LYHMap["promotedLy"]['quarterTotal'] = promotedLYquartTot;

    typKeys.forEach(typKey => {
      len = typKey.length;
      mappingKey = nameMapping[typKey.substring(0, len - 9)];
      let quarterValue = (typKey == 'salesOptimized') ? res['quarter_total']['revenueOptimized'] : 
          (typKey=='reedeemOptimized'? res['quarter_total']['budgetPlannedOptimized']: res['quarter_total'][typKey]);
      TYPMap[typKey]['campaignType'] = mappingKey;
      TYPMap[typKey][`Book${bookNo}`] = formattedValue(res[typKey], mappingKey);

      if(typKey == "offerOptimized")
      {
        offerTYPquartTot = +offerTYPquartTot + formattedValue(Number(res[typKey]), mappingKey);
      } 
      else if (typKey == "promotedOptimized") {
        if (res[typKey] !== null) {
          promotedTYPquartTot = +promotedTYPquartTot + formattedValue(Number(res[typKey]), mappingKey);
        }
      }
      else {
        TYPMap[typKey]['quarterTotal'] = formattedValue(quarterValue, mappingKey);
      }
    })
    TYPMap["offerOptimized"]['quarterTotal'] = offerTYPquartTot;
    TYPMap["promotedOptimized"]['quarterTotal'] = promotedTYPquartTot;
    
    tymKeys.forEach(tymKey => {
      len = tymKey.length;
      mappingKey = nameMapping[tymKey.substring(0, len - 9)];
      let quarterValue = (tymKey == 'salesFinalized') ? res['quarter_total']['revenueFinalized'] :
          (tymKey=='reedeemFinalized'? res['quarter_total']['budgetPlannedFinalized']: res['quarter_total'][tymKey]);
      TYMMap[tymKey]['campaignType'] = mappingKey;
      TYMMap[tymKey][`Book${bookNo}`] = formattedValue(res[tymKey], mappingKey);

      if(tymKey == "offerFinalized")
      {
        offerTYMquartTot = +offerTYMquartTot + formattedValue(Number(res[tymKey]), mappingKey);
      } 
      else if (tymKey == "promotedFinalized") {
        if (res[tymKey] !== null) {
          promotedTYMquartTot = +promotedTYMquartTot + formattedValue(Number(res[tymKey]), mappingKey);
        }
      }
      else {
        TYMMap[tymKey]['quarterTotal'] = formattedValue(quarterValue, mappingKey);
      }
    
    })
    TYMMap["offerFinalized"]['quarterTotal'] = offerTYMquartTot;
    TYMMap["promotedFinalized"]['quarterTotal'] = promotedTYMquartTot;
  }
  let rowLY = [], rowTYP = [], rowTYM = [];
  let rowData = {};
  for (const k1 in LYHMap) {
    rowLY.push(LYHMap[k1]);
  }
  for (const k2 in TYPMap) {
    rowTYP.push(TYPMap[k2]);
  }
  for (const k3 in TYMMap) {
    rowTYM.push(TYMMap[k3]);
  }
  rowData.LYH = rowLY;
  rowData.TYP = rowTYP;
  rowData.TYM = rowTYM;
  return rowData;
}

export const dmmLevelDownload = (reqObj) => dispatch => {
  console.log("req:", reqObj);
  dispatch({
    type: LOADER,
    payload: true
  });
  axios
    .post(environment.baseUrl + "/planSummary/dmmLevelDownload", reqObj)
    .then(res => {
      dispatch({
        type: 'DMM_Download',
        payload: res.data
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      dispatch({
        type: LOADER,
        payload: false
      });
    });
}