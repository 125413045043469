import { callApi } from './config';
import { toast } from 'react-toastify';
import { MESSAGES } from '../common/app-constants';
import { ToastMessage } from '../common/ToastMessage';


export const fetchConstraintsData = (reqObj) => {
  return callApi
    .post(`/constraints/fetch`, reqObj)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};


export const deleteConstraintsData = (reqObj) => {
  return callApi
    .post(`/constraints/delete`, reqObj)
    .then((res) => {
      if (res.status === 200)
      ToastMessage(res.status,MESSAGES.DELETE_SUCCESS_MESSAGE)
      return res.data.data;
    })
    .catch((err) => {
      return err;
    });
};

export const saveConstraintsData = (reqObj) => {
  return callApi
    .post(`/constraints/insert`, reqObj)
    .then((res) => {
      if (res.status === 200)
      ToastMessage(res.status,MESSAGES.SAVE_CONSTRAINTS)
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const downloadConstraintsData = (reqObj) => {
  return callApi
  .post("/constraints/downloadConstraintsData", reqObj)
  .then(res => {
    ToastMessage(res.status,MESSAGES.DOWNLOAD_SUCCESS_MESSAGE)
    return res.data;
  })
  .catch(err => {
    ToastMessage(err.response.status,err.response.data.message)
    throw err;
  });
}

export const fetchVendorData = (reqObj) => {
  return callApi
  .post(`constraints/filters/vendor`, reqObj)
  .then(res => {return res.data.data })
  .catch(err => err)
}

export const fetchArticleData = (reqObj) => {
  return callApi
  .post(`constraints/filters/articleNo`, reqObj)
  .then(res => {return res.data.data })
  .catch(err => err)
}

export const fetchGroupDiscountDepth = (reqObj) => {
  return callApi
  .post(`/constraints/fetchGroupDiscountDepth`, reqObj)
  .then(res => {return res.data.data })
  .catch(err => err)
}

export const addGroup = (reqObj) => {
  
  return callApi
    .post(`/constraints/insertGroup`, reqObj)
    .then((res) => {
      if (res.status === 200){
        const msg = reqObj.type === 'create' ? MESSAGES.CREATE_GROUP_SUCCESS : MESSAGES.SAVE_GROUP_CHANGE_SUCCESS
      ToastMessage(res.status,msg)}
      return res.data;
    })
    .catch((err) => {
      return err;
    });
}

export const fetchGroupdata = reqObj => {
  return callApi
  .get(`constraints/checkIfGroupExist/${reqObj}`)
  .then(res => {return res.data.data })
  .catch(err => err)
}

export const fetchGroupConstraintsData = (reqObj) => {
  return callApi
    .post(`/constraints/fetchGroupConstraints`, reqObj)
    .then((res) => {
      return res.data;
    })
    .catch((err) => {
      return err;
    });
};

export const deleteGroupData = (reqObj) => {
  return callApi
    .post(`/constraints/deleteGroup`, reqObj)
    .then((res) => {
      if (res.status === 200 && reqObj.type === 'delete')
      ToastMessage(res.status,MESSAGES.GROUP_DELETE_SUCCESS_MESSAGE)
      return res.data.data;
    })
    .catch((err) => {
      return err;
    });
};