import React from "react";
import ReactDOM from "react-dom";
import numeral from "numeral"
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store/store";
import "./index.css";
import App from "./App";
import { ToastContainer } from "react-toastify";

// load a locale
numeral.register('locale', 'fr', {
  delimiters: {
    thousands: ',',
    decimal: '.'
  },
  abbreviations: {
    thousand: 'K',
    million: 'Mn',
    billion: 'Bn',
    trillion: 'Tn'
  },
  currency: {
    symbol: '$'
  }
})

// switch between locales
numeral.locale('fr')

const app = (
  <Provider store={store}>
    <Router>
      <div>
        <App />
        <ToastContainer />
      </div>
    </Router>
  </Provider>
);
ReactDOM.render(app, document.getElementById("root"));
