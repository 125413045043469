import axios from 'axios';
import React, { useEffect, useState } from "react";
import environment from '../../environments/environment';
import { ToastMessage } from "../../common/ToastMessage";
import {GET_CAMPAIGN_LIST, GET_ERRORS, LOADER, UPLOAD_DATA} from './actionTypes';
import moment from 'moment';
import { MESSAGES } from "../../common/app-constants";

export const handleFileDownload=(params)=>{
  console.log("params:",params)
  let url=params.value;
  // window.open(url,'_self');
  downloadFile(url)
}
export const getFormattedDate=(date)=>{
  return moment(new Date(date)).format("YYYY-DD-MM hh:mm A");
}
export const formatData=(data, campaignName)=>{
    let columnDefs=[
      {
          headerName:'BBM',
          field: 'campaignName'
      },
      {
        headerName:'Input File Uploaded on',
        field:'inputTime',
        filter:true,
        sortable:true,
      },
      {
        headerName:'Input File- Download link',
        field:'inputURL',
        filter:true,
        sortable:true,
        cellRenderer: function(params) {
          return '<span><i class="fa fa-download"></i></span>'
        },
        cellStyle: params => ({
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer"
        }),
        onCellClicked: params => {
          handleFileDownload(params);
        }
      },
      {
        headerName:'Output File Generated on',
        field:'outputTime',
        filter:true,
        sortable:true,
      },
      {
        headerName:'Output File - Download link',
        field:'outputURL',
        filter:true,
        sortable:true,
        cellRenderer: function(params) {
          return '<span><i class="fa fa-download"></span>'
        },
        cellStyle: params => ({
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer"
        }),
        onCellClicked: params => {
          handleFileDownload(params);
        }
      }

    ];
    let tableData=[];
    data.forEach(row => {
        let rowObj={};
        rowObj['inputTime'] = moment(row['input_file_upload_at']).format("YYYY-MM-DD hh:mm:ss A");
        rowObj['outputTime'] = moment(row['createdat']).format("YYYY-MM-DD hh:mm:ss A") 
        rowObj['campaignName']=row['campaign_name'];
        rowObj['inputURL']=row['input_url'];
        rowObj['outputURL']=row['output_url'];
        tableData.push(rowObj);
    });
    const rowData=tableData;
    // console.log("columns:",columnDefs);
    return {columnDefs,rowData};
}
export const uploadFile= (data, reqObj)=> dispatch=>{
    dispatch({
        type: "LOADER",
        payload: true
    });
    axios
        .post(environment.baseUrl+'/promoUpload/upload_promo_file',data)
        .then(res=>{
         dispatch({
            type: "LOADER",
            payload: false
            });
        if(res.data.status){
          ToastMessage(200,res.data.message)
          dispatch({
            type:'UPLOAD_DATA',
            payload:res.data.data
          });
        }else {
          ToastMessage(403, res.data.message)
        }
        
        })
        .catch(err => {
          dispatch({
            type: GET_ERRORS,
            payload: err
          });
          dispatch({
            type: "LOADER",
            payload: false
          });
        });     
}
export const filterLogsData=(reqObj)=>dispatch=>{
    dispatch({
      type: "LOADER",
      payload: true
    });
     axios.get(environment.baseUrl+`/promoUpload/logs?startDate=${reqObj.startDate}\&endDate=${reqObj.endDate}`)
     .then(res=>{
      let response=res.data.data;
      let {columnDefs, rowData}=formatData(response);
      dispatch({
        type:'LOGS_ROWS',
        payload:rowData
      })
      dispatch({
        type:'LOGS_COLUMNS',
        payload:columnDefs
      })
      dispatch({
        type: "LOADER",
        payload: false
      });
    })
    .catch(err=>{
      dispatch({
          type: GET_ERRORS,
          payload: err
        });
        dispatch({
          type: "LOADER",
          payload: false
      });
    })
}
export const viewLogs=()=>dispatch=>{
  var today = new Date();
  var dd = String(today.getDate()).padStart(2, '0');
  var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyy = today.getFullYear();
  let endDate= yyyy+'-'+mm+'-'+dd;
  let startDate= today.setMonth(today.getMonth()-6);
  var ddStart = String(today.getDate()).padStart(2, '0');
  var mmStart = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  var yyyyStart = today.getFullYear();
  startDate= yyyyStart+'-'+mmStart+'-'+ddStart;
    dispatch({
        type: "LOADER",
        payload: true
    });
  return axios
        .get(environment.baseUrl+`/promoUpload/logs?startDate=${startDate}\&endDate=${endDate}`)
        .then(res=>{
            //console.log("response:",res);
            let {columnDefs, rowData}=formatData(res.data.data);
            dispatch({
                type:'GET_LOGS',
                payload:res.data.data
            })
            dispatch({
                type:'SET_ROWS',
                payload:rowData
            })
            dispatch({
                type:'SET_COLUMNS',
                payload:columnDefs
            })
            dispatch({
                type:LOADER,
                payload:false
            })
        })
        .catch(err=>{
            dispatch({
                type: GET_ERRORS,
                payload: err
              });
              dispatch({
                type: "LOADER",
                payload: false
            });
        })
}
export const fetchCampaignList = reqObj =>dispatch=> {
    dispatch({
        type: "LOADER",
        payload: true
    });
    axios
      .get(environment.baseUrl +`/campaigns/list?future=${reqObj.future}`)
      .then(res => {
          console.log('Data:',res);
          let response=[];
          const fiscYear = `${new Date().getFullYear() + 1}`;
          const tempYear = `${new Date().getFullYear() + 2}`;
          res.data.data.map(obj=>{
              if(obj.name.includes('BBM') && (obj.fyear === fiscYear || obj.fyear === tempYear)){
                response.push({label:obj.name, value:obj.name});
              }
          });
        dispatch({
            type: GET_CAMPAIGN_LIST,
            payload: response
          });
        dispatch({
            type: "LOADER",
            payload: false
          });
      })
      .catch(err => {
          console.log("error:",err);
        dispatch({
          type: GET_ERRORS,
          payload: err
        });
        dispatch({
          type: "LOADER",
          payload: false
        });
      });      
  };

  export const downloadInputTemplate= ()=> dispatch=>{
    dispatch({
        type: "LOADER",
        payload: true
    });
    axios
        .get(environment.baseUrl+'/promoUpload/download_promoupload_input')
        .then(res=>{
         
        if(res.data.status){
          console.log(res.data)
          window.open(res.data.data,'_self');
          ToastMessage(200,MESSAGES.DOWNLOAD_SUCCESS_MESSAGE);
          dispatch({
            type: "LOADER",
            payload: false
            });
        }else {
          ToastMessage(403, res.data.message)
        }
        
        })
        .catch(err => {
          dispatch({
            type: GET_ERRORS,
            payload: err
          });
          dispatch({
            type: "LOADER",
            payload: false
          });
        });     
}


export const downloadFile= (fileName)=>{

  axios
      .get(environment.baseUrl+'/promoUpload/download_file',{params:{fileName}})
      .then(res=>{      
      if(res.data.status){
        console.log(res.data)
        window.open(res.data.data,'_self');
        ToastMessage(200,MESSAGES.DOWNLOAD_SUCCESS_MESSAGE);
        
      }else {
        ToastMessage(403, res.data.message)
      }
      
      })
      .catch(err => {
        console.log(err);
      });     
}