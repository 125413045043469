import React from 'react';
import moment from 'moment';
import './index.css';

const getRandomColor = (user_id, randColors) => {
  if (!randColors.hasOwnProperty(user_id)) {
    const h = Math.floor(Math.random() * 360),
      s = Math.floor(Math.random() * 100) + '%',
      l = Math.floor(Math.random() * 60) + '%'; // max value of l is 100, but I set to 60 cause I want to generate dark colors
    // (use for background with white/light font color)
    randColors[user_id] = `hsl(${h},${s},${l})`;
  }
  return randColors;
};

const randColors = {};
function Comment(props) {
  let { user_id, from_email, body, created_on } = props.comment;
  body.startsWith('System message:') &&
    (body =
      "We've received your request and a member of our staff will review it and reply as soon as possible.");
  return (
    <div className="comment-container">
      <div
        className="user-icon-container"
        style={{ color: getRandomColor(user_id, randColors)[user_id] }}>
        <i className="fa fa-user-circle"></i>
      </div>
      <div className="comment-text-container">
        <div
          className="comment-name"
          style={{ color: getRandomColor(user_id, randColors)[user_id] }}>
          <span>{from_email}</span>
        </div>
        <div dangerouslySetInnerHTML={{ __html: body }}></div>
        <div className="t-a-r">
          <span className="comment-date">{moment(created_on).format('llll')}</span>
        </div>
      </div>
    </div>
  );
}

export default class index extends React.Component {
  scrollToBottom = () => {
    this.messagesEnd &&
      this.messagesEnd.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
  };

  componentDidMount() {
    this.scrollToBottom();
  }

  componentDidUpdate(prevProps, prevState) {
    if(prevProps.comments !== this.props.comments){
      this.scrollToBottom();
    }
  }
  render() {
    return (
      <div className="comments-container">
        {this.props.comments.map((comment) => (
          <>
            <Comment comment={comment} />
            <div
              style={{ float: 'left', clear: 'both' }}
              ref={(el) => {
                this.messagesEnd = el;
              }}></div>
          </>
        ))}
      </div>
    );
  }
}
