// const initialState = {
//     isAuthenticated: false,
//     user: {},
//   };

export default function(state, action) {
  switch (action.type) {
    case 'GET_PROMO_FILTERS':
      return action.payload;
    case 'GET_HIERARCHY_FILTERS':
      return action.payload;

    default:
      return state || [];
  }
}
