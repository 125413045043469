import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalFooter from "react-bootstrap/ModalFooter";
import { ModalBodyStyled } from "../UserInputModal/styled";
const CopyPlanModal = props =>{
    const [planName, setPlanName] = useState('')
    const handleInputChange =(event)=>{
        setPlanName(event.target.value);
    }
    const handleSave =()=>{
        console.log("Plan Name:",planName);
        props.copyPlanSave(planName);
        setPlanName('')
        props.closeModal();
    }
    const handleCancel = ()=>{
        setPlanName('');
        props.closeModal();
    }
    return(
        <Modal show={props.isShow} onHide={() => { }} centered>
            <div style={{paddingRight:'10px',paddingTop:'5px'}}>
                <span
                        onClick={handleCancel}
                        className="pull-right"
                        style={{fontSize:'large'}}
                    >
                        <i class="fa fa-times" aria-hidden="true" />
                 </span>
            </div>
            <ModalBodyStyled>
                <ModalBody style={{paddingTop:'0',paddingBottom:'10px'}}>
                <div>
                    <label>Plan Name</label>
                    <input placeholder="Enter Plan Name"
                            value={planName}
                            onChange={handleInputChange}
                    />
                </div>
                </ModalBody>
            </ModalBodyStyled>
            <ModalFooter style={{border:'none'}}>
                <button className="btn btn-primary saveButton mr-4"
                        onClick={handleCancel}
                >
                    Cancel
                </button>
                <button className="btn btn-primary saveButton"
                        onClick={handleSave}
                        disabled={planName.trim().length == 0}
                >
                    Save
                </button>
            </ModalFooter>
        </Modal>
    )
}

export default CopyPlanModal;