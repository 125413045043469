import React, { Fragment, useState, useEffect } from 'react';
import Chart from './dashboardCharts';
import DashboardTable from './dashboardTable';
import isUndefined from 'lodash/isUndefined';
const ChartTableView = (props) => {
  return (
    <React.Fragment>
    {
        props.consolidatedData.length >0 ? (
          <>
          {props.viewType === 'Charts' ? (
            <>
              <Chart
                chartData={props.consolidatedData}
               type={props.type}
              />
            </>
          ) : !isUndefined(props.consolidatedData) ? (
            props.consolidatedData.length > 0 ? (
              <DashboardTable view={props.view} tableData={props.consolidatedData} type={props.type} />
            ) : (
              ''
            )
          ) : (
            <div className="text-center alert alert-primary">No Data to show</div>
          )}
          </>
        ) : (<div className="text-center">No Data to show</div>)
      }
    </React.Fragment>
  );
};

export default ChartTableView;
