import React, { Component } from "react";
import ActualEditor from './actualEditor';
import {getDisStateFrmStatus} from "../../../../utils/helperFunctions"
import { 
    Popover,
    Overlay,
    OverlayTrigger
} from 'react-bootstrap';

export default class ActualRenderer extends Component {
    ref = React.createRef();
    constructor(props) {
        super(props);

        this.state = {
            saved: '',
            target: null,
            show: false,
        }
    }
    refresh(params) {
        this.setButtonType(params.value);
    }

    onIconClick = (event) => {
        this.setState({
            show: !this.state.show,
            target: event.target,
        })
    }
    hidePopOver = () => {
        this.setState({
            show: false,
            target: null
        })
    }

    handleDiscountChange = (params) => {
        this.props.handleDiscountChange({
            ...params,
            campaignId: this.props.colDef.field.split('_')[0]
        });
        this.hidePopOver();
    }

    setButtonType(type) {
        this.setState({
            saved: type
        })
    };


    render() {
        const book = this.props.colDef.field.split('_')[0];
        const [bookType, cycle] = book.split('-');
        const { data } = this.props;
        const bjsFunding = data[`${book}_bjsFundingFinalized`];
        const discount = data[this.props.colDef.field];
        const mediaType = data[`${book}_mediaTypeFinalized`];
        const promoDays = data[`${book}_promoDaysFinalized`];
        const promoId = data[`${book}_promoIdFinalized`];
        const categoryId = data['categoryId'];
        const promoKey = data['promoKey'];
        const promoKeyToPromoId = data['promoKeyToPromoId'];
        const {
            year,
            planId
        } = this.props;
        return (
            <div className="actual-renderer" ref={this.ref}>
                <span> $ {parseFloat(this.props.value).toFixed(2)}</span>
                <span className="icon-style" >
                    <i className="fa fa-pencil-square-o" onClick={this.onIconClick} disabled={getDisStateFrmStatus(this.props.planStatus)}></i>
                </span>
                <Overlay
                    show={this.state.show}
                    target={this.state.target}
                    placement="bottom"
                    // container={this.ref.current}
                    // containerPadding={20}
                    rootClose={true}
                    onHide={this.hidePopOver}
                >
                    <Popover id={`popover-contained_${this.props.data.articleNo}`}>
                        <Popover.Content>
                            <ActualEditor
                                onSaveClick= {this.handleDiscountChange}
                                book={book}
                                bjsFunding={bjsFunding}
                                discount={discount}
                                mediaType={mediaType}
                                promoDays={promoDays}
                                promoId={promoId}
                                promoKey={promoKey}
                                promoKeyToPromoId={promoKeyToPromoId}
                                articleNo={this.props.data.articleNo}
                                cycle={Number(cycle)}
                                year={year}
                                planId={planId}
                                categoryId={categoryId}
                                bookType={bookType}
                                {...this.props}/>
                        </Popover.Content>
                    </Popover>
                </Overlay>
            </div>
        );
    }
}
