import {
  GET_EDA_CHART_DATA,
  DATA_LOADING,
  CLEAR_EDA_CHART_DATA,
  GET_EDA_CHART_DATA_FILTERS,
  GET_EDA_DEPART_DATA_FILTERS,
  GET_EDA_ERRORS
} from '../actions/types';

const initialState = {
  edaChartData: {},
  edaChartDataFilter: {},
  edaChartDepartmentFilter: {},
  edaError: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case DATA_LOADING:
      return {
        ...state,
        loading: true
      };

    case GET_EDA_CHART_DATA:
      return {
        ...state,
        edaChartData: action.payload,
        loading: false
      };

    case GET_EDA_CHART_DATA_FILTERS:
      return {
        ...state,
        loading: false,
        edaChartDataFilter: action.payload
      };

    case GET_EDA_DEPART_DATA_FILTERS:
      return {
        ...state,
        edaChartDepartmentFilter: action.payload,
        loading: false
      };

    case GET_EDA_ERRORS:
      return {
        ...state,
        edaError: action.payload,
        loading: false
      };

    case CLEAR_EDA_CHART_DATA:
      return {
        ...state,
        edaChartData: {}
      };

    default:
      return state;
  }
}
