import { callApi } from "./config";
import { MESSAGES } from "../common/app-constants";
import { ToastMessage } from "../common/ToastMessage";

export const fetchFinalizePromoData = reqData => {
  return callApi
    .post(`/promo/finalizePromo`, reqData)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return err;
    });
};

export const deleteFinalizePromoData = reqData => {
  return callApi
    .delete("/promo/deletePromoPlan", { params: { ...reqData } })
    .then(res => {
      ToastMessage(res.status,MESSAGES.DELETE_SUCCESS_MESSAGE)
      return res.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      throw err;
    });
};

export const finalizePromoData = reqData => {
  let msg = '';
  if(reqData.status==="APPROVED"){
    msg = MESSAGES.APPROVE_SUCCESS
  } else if(reqData.status==="UNAPPROVED"){
    msg = MESSAGES.UNAPPROVE_SUCCESS
  } else if(reqData.status ==="REJECTED"){
    msg = MESSAGES.REJECT_SUCCESS
  }
  return callApi
    .post("/promo/updateFinalizeData", reqData)
    .then(res => {
      ToastMessage(res.status,msg)
      return res.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      throw err;
    });
};

export const downloadFinalizePromoData = reqData => {
  return callApi
    .post("/promo/downloadFinalizeData", reqData)
    .then(res => {
      ToastMessage(res.status,MESSAGES.DOWNLOAD_SUCCESS_MESSAGE)
      return res.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      throw err;
    });
};
