import React from 'react';
import PropTypes from 'prop-types';
import moment from "moment";
import _ from "lodash";

function calendarWrapperWeeks(props) {
    return (
        <div>
            <div className="RowWrapperHeader">
                {/* -----------------number of weeks in header------------------- */}
                {props.calendarWrapperArr.length
                    ? (_.times(props.calendarWrapperArr.length, index1 => {
                        return (
                            <div
                                key={index1}
                                style={{
                                width: "25%"
                            }}
                                className="CellWrapper HeaderWrapper">
                                {" "}
                                <div>
                                    <p className="calendarWeek1 text-center">
                                        Week {props.calendarWrapperArr[index1].fiscal_week}
                                    </p>
                                    <p className="calendarWeekDate">
                                        {moment(props.calendarWrapperArr[index1].start_date.substr(0, 10)).format("MM/DD ")}
                                        - {moment(props.calendarWrapperArr[index1].end_date.substr(0, 10)).format(" MM/DD")}
                                    </p>
                                </div>
                            </div>
                        );
                    }))
                    : (
                        <div>No Data To Show</div>
                    )}
            </div>
        </div>
    )
}

calendarWrapperWeeks.propTypes = {};

export default calendarWrapperWeeks;
