// const initialState = {     isAuthenticated: false,     user: {},   };

export default function(state, action) {
  switch (action.type) {
    case 'GET_RECOMMENDED_PROMO':
      return action.payload;
    case 'GET_PROMOTION_PIPELINE':
      return {
        ...state,
        promoMertricList: action.payload
      };
    case 'GET_PROMOTION_ARTICLE_LEVEL':
      return {
        ...state,
        promoMertricList: action.payload
      };
    case 'GET_CAMPAIGNS_LIST':
      let resData = action.payload.data.filter(object => {
        object.value = `${object.name} - ${object.startDate} to ${object.endDate}`;
        object.label = `${object.name} - ${object.startDate} to ${object.endDate}`;
        return object;
      });

      return {
        ...state,
        campaignsList: resData
      };

    default:
      return state || [];
  }
}
