import React, { Component } from 'react';
import ReactDatePicker from "react-datepicker";
import { DateRangePicker } from "react-dates";
import { AgGridReact } from 'ag-grid-react';
import { filterLogsData } from './promoUploadAction';
import { connect } from 'react-redux';
import Modal from "react-awesome-modal";
import PageLoader from "../../utils/Loader";
import { openLoader, closeLoader } from '../../actions/promo';
import './promoUpload.css';
import moment from "moment";
class LogsTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            focusedInput: '',
            startDate: '',
            endDate: '',
            resultStartDate:'',
            resultEndDate:'',
            filterDisabled: true,
            displayTable: false,
            defaultColDef: {
                //width: 200,
                resizable: true
            }
        }
    }
    onReset = () => {
        this.setState({
            displayTable: false,
            filterDisabled: true,
            startDate: '',
            endDate: ''
        })
    }
    filterAction = () => {
        let reqObj = {
            data: this.props.logsData,
            startDate: this.state.resultStartDate,
            endDate: this.state.resultEndDate,
        }
        this.setState({
            displayTable: true
        })
        this.props.filterLogsData(reqObj);
    }
    getDateBeforeMonths=(months)=>{
        let today= new Date();
        let startDate= today.setMonth(today.getMonth()-6);
        startDate=moment(startDate).format();
        return startDate;
    }
    isOutsideRangeHandler = day => {
        let formattedDate = moment(day).format(),
            endDate = moment().format();
        let months=6;
        let startDate = this.getDateBeforeMonths(months);
        let isSameOrBefore= moment(formattedDate).isSameOrAfter(startDate) && moment(formattedDate).isSameOrBefore(endDate);
        return !isSameOrBefore;
    };
    onDatesChange(startDate) {
        startDate.startDate != null ? this.setState({ filterDisabled: false }) : this.setState({ filterDisabled: true });
        this.setState({
            startDate: startDate.startDate,
            endDate: startDate.endDate,
        });
        let startDateFormat = JSON.stringify(startDate.startDate);
        let resultStartDate = startDateFormat.slice(1, 11);
        let endDateFormat = JSON.stringify(startDate.endDate);
        let resultEndDate = endDateFormat.slice(1, 11);
        this.setState({
            resultStartDate,
            resultEndDate
        })
    }
    onGridReady(params) {
        this.gridApi = params.api;
        this.columnApi = params.columnApi;
        this.gridApi.sizeColumnsToFit();
        window.onresize = () => {
            this.gridApi.sizeColumnsToFit();
        }
}
    render() {
        return (
            <div>
                <div>
                    <Modal
                        visible={true}
                        effect="fadeInDown"
                        width="75%"
                        height="80%"
                        onClickAway={() => this.props.closeLogsModal()}
                    >
                    <PageLoader loader={this.props.loader}>
                    <span className="pull-right select-store-span cross-btn">
                        <i style={{ cursor: 'pointer', fontSize: '1.35em' }}
                            className="fa fa-times"
                            onClick={() => this.props.closeLogsModal()}
                            aria-hidden="true"
                        />
                    </span>
                    <div className='modalRow' style={{borderBottom:'1px solid #E7EEF3'}}>
                        <label className="form-label"><b>Logs</b></label>
                    </div>
                    <div className='row' id='datePickerDiv'>
                            <label className='timePeriodLabel'>Time Period:</label>
                            <DateRangePicker
                                focusedInput={this.state.focusedInput}
                                onFocusChange={(focusedInput) =>
                                    this.setState({ focusedInput })
                                }
                                startDate={this.state.startDate}
                                endDate={this.state.endDate}
                                onDatesChange={({ startDate, endDate }) =>
                                    this.onDatesChange({ startDate, endDate })
                                }
                                isOutsideRange={day => this.isOutsideRangeHandler(day)}
                            />
                        <div className='row' id='buttonRow'>
                            <button
                                className='btn btn-primary filterBtn'
                                onClick={this.filterAction}
                                disabled={this.state.filterDisabled}
                                style={{marginRight:'20px'}}
                            >
                            <span>
                                <i className='fa fa-filter' />&nbsp; Filter
                            </span>
                            </button>
                            <button
                                className='btn btn-primary resetButton'
                                onClick={this.onReset}
                            >
                            <span>
                                <i className='fa fa-repeat' />&nbsp; Reset
                            </span>
                            </button>
                        </div>
                    </div>
                        {
                            <div className='pipelineGridLogs'>
                                <div id='defaultGrid'
                                    className='ag-theme-balham'
                                    style={{ height: "100%"}}>
                                    <AgGridReact
                                        columnDefs={this.state.displayTable? this.props.columns: this.props.initialColDef}
                                        rowData={this.state.displayTable? this.props.rows: this.props.initialRowData}
                                        defaultColDef={this.state.defaultColDef}
                                        onGridReady = {this.onGridReady}
                                    />
                                </div>
                            </div>
                        }
                    </PageLoader>
                    <div className="mapAdGroup-footer pt-1 pb-2">
                        <div className="text-center mt-2">
                            <button className="btn btn-primary mr-2" 
                                    onClick={() => this.props.closeLogsModal()}>
                                    Close
                            </button>
                        </div>
                    </div>
                    </Modal>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        columns: state.promoUploadData.columns,
        rows: state.promoUploadData.rows,
        loader:state.promoUploadData.loader
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
        filterLogsData: (val) => dispatch(filterLogsData(val)),
        openLoader: (val) => dispatch(openLoader()),
        closeLoader: (val) => dispatch(closeLoader())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(LogsTable);