import { charMapping } from '../constants';
export function generateUniqueIdFromEmaiId(emailId) {
  let encodeValue = '';
  for (const char of emailId) {
    encodeValue += charMapping[char] || '';
  }
  return encodeValue;
}

export function getDaysCount(val1, val2) {
	let oneDay = 24 * 60 * 60 * 1000;
	let firstDate = new Date(val1);
	let secondDate = new Date(val2);

	let diffDays = Math.round(
		(secondDate.getTime() - firstDate.getTime()) / oneDay
	);

	return diffDays;
}

export function getDisStateFrmStatus (status) {
	const inActiveStatus =  ["APPROVED", "EXPIRED", "REJECTED"]
	return inActiveStatus.includes(status)
}

export const formatGroupArticleData = (result) => {
	let tempData = {};
  
	result.forEach((element) => {
	  if (!tempData[element.articleNo]) {
		tempData[element.articleNo] = {
		  articleNo: element.articleNo,
		  discountDepth: [element.discountDepth],
		  brand: element.brand,
		};
	  } else {
		if (!tempData[element.articleNo].discountDepth.includes(element.discountDepth)) {
		  tempData[element.articleNo].discountDepth.push(element.discountDepth);
		}
	  }
	});
  
	return Object.values(tempData);
  };