import { callApi as instance } from '../../apis/config';
import axios from 'axios';

const mojoBaseURL = 'https://app.mojohelpdesk.com/api/v2/';

export default {
  createTicket: (params, body) => {
    return instance.post(`tickets/mojoHelper`, {
      url: `${mojoBaseURL}tickets`,
      type: 'POST',
      params,
      body,
    });
  },
  attachFile: (payload) => {
    return axios.post(
      `${mojoBaseURL}tickets/${payload.ticketId}/attachments?access_key=${payload.access_key}`,
      payload.file,
      {
        headers: {
          'content-type': 'multipart/form-data',
        },
      }
    );
  },
  downloadAttachment: ({ attachmentId, access_key }) => {
    window.open(`${mojoBaseURL}attachments/${attachmentId}?access_key=${access_key}`);
  },
  createComment: (ticketId, params, body) => {
    return instance.post(`tickets/mojoHelper`, {
      url: `${mojoBaseURL}tickets/${ticketId}/comments`,
      type: 'POST',
      params,
      body,
    });
  },
  getTickets: (params, email) => {
    return instance.post(`tickets/mojoHelper`, {
      url: `${mojoBaseURL}tickets/search`,
      type: 'GET',
      params: {
        ...params,
        sf: 'created_on',
        r: 1,
        email
      },
    });
  },
  getTicket: (params) => {
    return instance.post(`tickets/mojoHelper`, {
      url: `${mojoBaseURL}tickets/${params.id}`,
      type: 'GET',
      params,
    });
  },
};

