import React, { useEffect, useState,useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import ConstraintsPopup from './index';
import _ from 'lodash';
const EditConstraintModal = (props) => {
  const myRef=useRef();
  const [show, setShow] = useState(props.isOpen);
  const [constraintData, setConstraintData] = useState({});
  useEffect(() => {
    setShow(props.isOpen);
  }, [props.isOpen]);
  useEffect(() => {
    let data = _.groupBy(_.flatten(props.constraintData.map((obj) => obj.cycles)), 'min');
    let cycles =  (props.actionType === 'Bulk Edit' && props.constraintData.length > 1) ? [] : _.flatten(props.constraintData.map((obj) => obj.cycles)).map((obj) => {
      obj.cycle = obj.name;
      return obj;
    });
    let isBulkEdit = (props.actionType === 'Bulk Edit' && props.constraintData.length > 1);
    const fixedDiscountDepth = _.compact(props.constraintData.map(obj=> {if(_.compact(obj.cycles.map(cycleObj=> cycleObj.fixed)).length > 0){ return obj.articleNo}}))
    let constraintObj = {
      vendor: props.constraintData.map((obj) => obj.vendor),
      ah6: props.constraintData.map((obj) => obj.ah6),
      brand: props.constraintData.map((obj) => obj.brand),
      article: props.constraintData.map((obj) => obj.articleNo),
      promoFatigue: (isBulkEdit ? [] :_.compact(_.uniq(_.flatten(props.constraintData.map((obj) => obj.promoFatigueRelax))))),
      mandatory: (isBulkEdit ? [] :_.compact(_.uniq(_.flatten(props.constraintData.map((obj) => obj.mandatoryCycles))))),
      excluded: (isBulkEdit ? [] : _.compact(_.uniq(_.flatten(props.constraintData.map((obj) => obj.excludedCycles))))),
      cycles: cycles,
      overrideBulkConstraints:fixedDiscountDepth,
      groupid: props.constraintData.filter(item => item.groupid ? true : false).map((obj) => obj.groupid),
      selectedArticles: _.compact(_.uniq(_.flatten(props.constraintData.map((obj => obj.selectedArticles))))),
      maxCycles: (isBulkEdit ? [] :props.constraintData.map((obj) => obj.selectedConstraintType == "QUARTERLY" ?"":obj.maxCycles)),
      minCycles: (isBulkEdit ? [] :props.constraintData.map((obj) => obj.selectedConstraintType == "QUARTERLY" ?"":obj.minCycles)),
      maxquarterly: (isBulkEdit ? [] :props.constraintData.map((obj) => obj.maxquarterly.maxCycleQuarterly)),
      minquarterly: (isBulkEdit ? [] :props.constraintData.map((obj) => obj.minquarterly.minCycleQaurterly)),
      selectedConstraintType: props.constraintData.map(obj => obj.selectedConstraintType)
    };
    setConstraintData(constraintObj);
  }, [props.constraintData , props.actionType]);
  const handleClose = () => {
    setShow(false);
    props.closeModal();
  };
  const handleSave =()=>{
    myRef.current.saveConstraints();
    setShow(false);
    props.closeModal();
  }
  return (
    <React.Fragment>
      <Modal show={show} dialogClassName="constraints-popup modal-90w" onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit Constraints</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ConstraintsPopup
            ref={myRef}
            closeModal={handleClose}
            constraintData={constraintData}
            actionType={props.actionType}
            saveConstraints={props.saveConstraints}
          />
        </Modal.Body>
         {/* <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer> */} 
      </Modal>
    </React.Fragment>
  );
};

export default EditConstraintModal;
