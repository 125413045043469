import React, { Component } from "react";
import DynamicFilters from "../../DynamicFilters";
import { BudgetMasterGA   } from '../../../common/GAUtils';
class BudgetMasterFilter extends Component {
//   const [loader, setLoder] = useState(false);
constructor(props) {
    super(props);
    this.state = {
      loader: false,
      merchcat: [],
      vendor: [],
      year: [],
      quarter: [],
     
    };
  }
    updateState = params => {
      let keysLength = Object.keys(params).length;
        if(params.hasOwnProperty("merchcat") && keysLength ===1){
            this.submitGA('Filter','Changed merchcat selection');
        }
        if(params.hasOwnProperty("vendor") && keysLength ===1){
            this.submitGA('Filter','Changed vendor selection');
        }
        if(params.hasOwnProperty("year") && keysLength ===1 ){
            this.submitGA('Filter','changed Fiscal year selection');
        }
    this.setState(params);
  };


  filterData = () => {
    const filterObj = {
      merchcat: this.state.merchcat.map(key => {return key.value}),
      vendor: this.state.vendor.map(key => {return key.value}),
      year: this.state.year.map(key => {return key.value}),
      quarter: this.state.quarter.map(key => {return key.value.slice(1,2)})
    };
    this.props.filterData(filterObj);
}
onReset =()=>{
  this.setState({
    merchcat: [],
      vendor: [],
      year: [],
      quarter: []
  },()=>{

    this.props.onReset();
  })
}

  submitGA(action,label){
    BudgetMasterGA({ action, label});
  }
  render(){

  const hierarchyLevel = ["merchcat", "vendor"];
  const hierarchyApiCalls = [
    {
      optionsMappingKey: "merchcat",
      UrlName: "merchcat"
    },
    {
      optionsMappingKey: "year",
      UrlName: "selectedFiscalYear"

    },
    // {
    //   optionsMappingKey: "quarter",
    //   UrlName: "selectedFiscalYear"
    // }
  ];
  const hierarchyFilterApi = "merchcatFilter";

  let DynamicFiltersProps = [
    {
      type: "Select",
      placeholder: "Select Category",
      state: this.state.merchcat,
      mappingKey: "merchcat",
      onMenuCloseApi: true,
      grid: "col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4",
      label : "Category"
    },
    {
      type: "MultiSelectCheckboxes",
      placeholder: "Select Vendor",
      state: this.state.vendor,
      mappingKey: "vendor",
      onMenuCloseApi: true,
      grid: "col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4",
      label : "Vendor"
    },
    {
      type: "Select",
      placeholder: "Select Fiscal Year",
      state: this.state.year,
      mappingKey: "year",
      onMenuCloseApi: false,
      grid: "col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4",
      label : "Fiscal Year"
    },
    // {
    //   type: "MultiSelectCheckboxes",
    //   placeholder: "Select Quarter",
    //   state: this.state.quarter,
    //   mappingKey: "quarter",
    //   onMenuCloseApi: false,
    //   grid: "col-md-3",
    //   label : "Quarter"
    // }
  ];

  let DynamicFiltersOptions = {
    merchcatOptions: [],
    vendorOptions: [],
    yearOptions: [],
    quarterOptions: []
  };
  const { merchcat, vendor, year } = this.state;

  return (
    <>
      <DynamicFilters
        filtersList={DynamicFiltersProps}
        options={DynamicFiltersOptions}
        hierarchyLevel={hierarchyLevel}
        hierarchyApiCalls={hierarchyApiCalls}
        hierarchyFilterApi={hierarchyFilterApi}
        openLoader={this.props.openLoader}
        closeLoader={this.props.closeLoader}
        updateParentState={this.updateState}
        filterData={this.filterData}
        onReset={this.onReset}
        viewType="verticle"
        isFilterButtonDisabled={merchcat.length === 0 || vendor.length === 0 || year.length === 0}
      />
    </>
  );
  }
};
export default BudgetMasterFilter;
