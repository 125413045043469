import React, { Component, useState, useProps } from 'react';
import { AgGridReact } from 'ag-grid-react';
// import { Dropdown, FormControl, Form } from 'react-bootstrap';
import { Form } from 'react-bootstrap';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import _ from 'lodash';
import './customSelect.css';

const SELECT_ALL = 'Select All';

const CustomMenu = props => {
  const [value, setValue] = useState('');
  const filterDropdown = event => {
    setValue(event.target.value);
    props.onQuickFilterChanged(event.target.value);
  };

  return (
    <>
      <Form.Control
        style={{ marginTop: '-8px' }}
        className="select-scroll"
        size='sm'
        autoFocus
        placeholder='Search...'
        onChange={filterDropdown}
        value={value}
        autoComplete="off"
      />
    </>
  );
};

class MultiSelectCheckboxes extends Component {
  ref = React.createRef();
  constructor(props) {
    super(props);
    this.state = {
      show: false,
      dropdownOpen: false,
      selected: 0,
      isInputChanged: false,
      target: null,
      columnDefs: [
        {
          headerName: SELECT_ALL,
          field: 'label',
          headerCheckboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          checkboxSelection: true
          // filter: 'agTextColumnFilter'
        }
      ],
      defaultColDef: {
        // filter: true,
        // resizable: true,
        width: 150
      },
      rowSelection: 'multiple',
      rowData: [],
      selectedRows:[]
    };
  }

  componentDidMount() {
    if (this.props.options.length === 0) {
      this.setState({
        selected: 0
      });
    }

    if(this.props.disabledApiCall){
      if (this.props.onMenuCloseApi) {
        this.props.onMenuCloseApiFun();
      }
    }


  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.onFirstDataRendered();
    params.api.sizeColumnsToFit();
    window.onresize = () => {
      this.gridApi.sizeColumnsToFit();
    };
  };
  onSelectionChanged(event) {
    const selectedOptions = this.gridApi.getSelectedRows();
    if(this.props.selectionLimit && selectedOptions.length > this.props.selectionLimit ){
      alert(`You can select upto ${this.props.selectionLimit} options only `)
      const result = selectedOptions.filter(obj1 => this.state.selectedRows.filter(obj2 => obj2.value === obj1.value).length === 0);
      this.props.options.forEach((obj,index)=>{
        if(result[0].value === obj.value){
          this.gridApi.getRowNode(index).setSelected(false, true, false);
        }
      })
      
    }else{
      this.setState({
        selectedRows:selectedOptions
      })
      if (
        this.props.value.length > 0 &&
        selectedOptions.length < this.props.value.length
      ) {
        this.setState({
          selected: selectedOptions.length,
          isInputChanged: true
        });
      } else if (this.props.value.length === 0 && selectedOptions.length > 0) {
        this.setState({
          selected: selectedOptions.length,
          isInputChanged: true
        });
      } else {
        if (
          _.differenceBy(selectedOptions, this.props.value, 'label').length > 0
        ) {
          this.setState({
            selected: selectedOptions.length,
            isInputChanged: true
          });
        } else {
          this.setState({
            selected: selectedOptions.length
          });
        }
      }
    }
    
  }
  onQuickFilterChanged = value => {
    if (this.gridApi) {
      this.gridApi.setQuickFilter(value);
    }
  };

  toggle = () => {
    let len = this.props.value.length;
    if (this.state.dropdownOpen && this.gridApi ) {
      const selectedOptions = this.gridApi.getSelectedRows();
      
      let updatedObj = {};
      updatedObj[`${this.props.mappingKey}`] =
        this.props.options.length > 0 ? selectedOptions : [];
        if(this.state.isInputChanged){
          this.props.updateState(updatedObj);
        }
      if (this.props.onMenuCloseApi && this.state.isInputChanged) {
        this.props.onMenuCloseApiFun();
      }
    } 
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
      isInputChanged: false
    });
  };

  onFirstDataRendered = event => {
    if (this.props.isMulti) {
      if (this.props.value.length === this.props.options.length) {
        this.gridApi.selectAll();
      } else {
        this.props.options.forEach((obj, index) => {
          if (this.props.value.includes(obj)) {
            this.gridApi.getRowNode(index).setSelected(true, false, true);
          } else if (this.props.value.map(obj=> obj.value).indexOf(obj.value)!==-1){
            this.gridApi.getRowNode(index).setSelected(true, false, true);
            return true;  
          } 
          else {
            this.gridApi.getRowNode(index).setSelected(false, true, false);
          }
        });
      }
      if (this.props.defaultSelectedOptions) {
        this.props.options.forEach((obj, index) => {
          if (
            this.props.value
              .map(valueObj => valueObj.label)
              .indexOf(obj.label) !== -1
          ) {
            if(this.gridApi.getRowNode(index)){

              this.gridApi.getRowNode(index).setSelected(true, false, true);
            }
          } else {
            if(this.gridApi.getRowNode(index)){

              this.gridApi.getRowNode(index).setSelected(false, true, false);
            }
          }
        });
        this.setState({
          selected: this.props.value.length
        });
      }
    } else {
      this.props.options.forEach((obj, index) => {
        if (this.props.value.indexOf(obj) !== -1) {
          this.gridApi.getRowNode(index).setSelected(true, false, true);
          return true;
        }else if (this.props.value.map(obj=> obj.value).indexOf(obj.value)!==-1){
          this.gridApi.getRowNode(index).setSelected(true, false, true);
          return true;  
        } else {
          this.gridApi.getRowNode(index).setSelected(false, true, false);
        }
      });
      if (this.props.defaultSelectedOptions) {
        this.props.options.forEach((obj, index) => {
          if (
            this.props.value
              .map(valueObj => valueObj.label)
              .indexOf(obj.label) !== -1
          ) {
            this.gridApi.getRowNode(index).setSelected(true, false, true);
          } else {
            this.gridApi.getRowNode(index).setSelected(false, true, false);
          }
        });
        this.setState({
          selected: this.props.value.length
        });
      }
    }
  };

  componentDidUpdate = (oldProps, oldState) => {
    if (
      oldProps.value !== this.props.value &&
      this.props.defaultSelectedOptions
    ) {
      if (this.gridApi) {
        this.onFirstDataRendered();
      }
    }
    if( oldProps.value !== this.props.value ){
      if(this.props.disabledApiCall){
        if (this.props.onMenuCloseApi) {
          this.props.onMenuCloseApiFun();
        }
      }
      
      this.setState({
        selected: this.props.value ? this.props.value.length : 0 ,
      });
    }
  };

  getSelectedRows() {
    if (this.gridApi) {
      return this.gridApi.getSelectedRows();
    } else {
      return [];
    }
  }
  updateParentState = selectedOptions => {
    let updatedObj = {};
    updatedObj[`${this.props.mappingKey}`] =
      this.props.options.length > 0 ? selectedOptions : [];
    this.props.updateState(updatedObj);
    if (this.props.onMenuCloseApi) {
      this.props.onMenuCloseApiFun();
    }
  };

  onSingleSelectionChanged(event) {
    const selectedOptions = this.getSelectedRows();
    if (
      this.props.value.length > 0 &&
      selectedOptions.length < this.props.value.length
    ) {
      this.setState({
        selected: selectedOptions.length,
        isInputChanged: true,
        dropdownOpen: !this.state.dropdownOpen
      });
    } else if (this.props.value.length === 0 && selectedOptions.length > 0) {
      this.setState({
        selected: selectedOptions.length,
        isInputChanged: true,
        dropdownOpen: !this.state.dropdownOpen
      });
    } else {
      this.setState({
        selected: selectedOptions.length,
        dropdownOpen: !this.state.dropdownOpen
      });
    }
    if(this.state.dropdownOpen){
      this.setState({
        dropdownOpen: false
      })
    }
    this.updateParentState(this.getSelectedRows());
  }

  render() {
    return (
      <React.Fragment>
        {!!(this.state.selected && this.props.clearable) && (
          <i class="fa fa-times-circle clear-button" title="Close" onClick={() => {this.props.onClear(this.props.mappingKey)}}></i>
        )}
        <Dropdown
          isOpen={this.state.dropdownOpen}
          toggle={this.toggle}
          style={{ backgroundColor: '#fff' }}
          positionFixed={true}
          direction='down'>
          <DropdownToggle caret className='custom-dropdown-button' disabled={this.props.disabled}>
            < >
              {this.props.isMulti ? (
                <>
                  {this.props.value &&
                  this.props.value.length &&
                  this.props.value[0].label &&
                  this.state.selected ? (
                    <> 
                    {/* <span > <span class="label label-default">&nbsp;{this.props.value[0].label.length > 8 ? `${this.props.value[0].label.substr(0,8)}...` : this.props.value[0].label }&nbsp;</span> {this.state.selected > 1 ? (<span class="label label-default">&nbsp;+ {this.state.selected -1}&nbsp;</span>) : null} </span>  */}
                    {/* <span style={{marginLeft:"-8px"}} > */} 
                    <span className="selected-text"><span className="label label-default">&nbsp;{this.state.selectedRows.length > 0 ? this.state.selectedRows[0].label : this.props.value[0].label }&nbsp;</span></span>
                    <span style={{marginLeft:"3px"}}>{this.state.selected > 1 ? (<span class="label label-default">&nbsp;+ {this.state.selected -1}&nbsp;</span>) : null} </span>
                    {/* </span>  */}
                    </>
                    // <>{this.state.selected + ' Selected'} </>
                  ) : (
                    <span className="selected-text">{this.props.placeholderButtonLabel}</span>
                  )}
                  &nbsp;&nbsp;&nbsp;&nbsp;
                </>
              ) : (
                <>
                  {this.props.value && this.props.value.length > 0
                    ? (<><span className="selected-text"> {this.props.value[0].label }</span></>)
                    : (<><span className="selected-text">{this.props.placeholderButtonLabel}</span></>)}
                </>
              )}
            </>
          </DropdownToggle>
          <DropdownMenu className='remove-dropdown-transform'>
            <CustomMenu onQuickFilterChanged={this.onQuickFilterChanged} />
            {this.props.options && this.props.options.length && this.props.options.length > 0 ? (
              <div   className={`select-grid ${this.props.options.length === 1 ? 'remove-bottom-space' : null} ${this.props.isMulti && !this.props.selectionLimit ? '' : 'single-select'}`}>
                <div
                  id={this.props.options && this.props.options.length < 10 ? '' : 'myGridForPipeline'}
                  className='ag-theme-balham'
                  style={{ width: '100%' }}>
                  {this.props.isMulti ? (
                    <AgGridReact
                      // columnDefs={this.state.columnDefs}
                      columnDefs={this.props.selectionLimit ? [
                        {
                          headerName: "",
                          field: "label",
                          headerCheckboxSelection: false,
                          headerCheckboxSelectionFilteredOnly: true,
                          checkboxSelection: true
                        }] : this.state.columnDefs}
                      defaultColDef={this.state.defaultColDef}
                      suppressRowClickSelection={this.props.allowRowClickSelection?false:true}
                      rowSelection={this.state.rowSelection}
                      onGridReady={this.onGridReady}
                      rowData={this.props.options}
                      onSelectionChanged={this.onSelectionChanged.bind(this)}
                      domLayout={this.props.options && this.props.options.length < 10 ? 'autoHeight' : 'normal'}
                      enableCellTextSelection={true}
                      rowMultiSelectWithClick={this.props.allowRowClickSelection?true: false}
                    />
                  ) : (
                    <AgGridReact
                      columnDefs={[
                        {
                          headerName: '',
                          field: 'label'
                        }
                      ]}
                      defaultColDef={this.state.defaultColDef}
                      rowSelection='single'
                      onGridReady={this.onGridReady}
                      rowData={this.props.options}
                      onSelectionChanged={this.onSingleSelectionChanged.bind(
                        this
                      )}
                      domLayout={this.props.options && this.props.options.length < 10 ? 'autoHeight' : 'normal'}
                      enableCellTextSelection={true}
                    />
                  )}
                </div>
              </div>
            ) : (
              <div className="no-options">No Options</div>
            )}
          </DropdownMenu>
        </Dropdown>
      </React.Fragment>
    );
  }
}

MultiSelectCheckboxes.defaultProps = { isMulti:true };

export default MultiSelectCheckboxes;
