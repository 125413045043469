import React, { useState, useEffect } from 'react'

import ReactPlayer from 'react-player'
import VideoThumbnail from 'react-video-thumbnail'
import { Card } from 'react-bootstrap'
import { VideoTutorialsGA   } from '../../common/GAUtils';
const VideosList = props => {
  const [currentVideo, setCurrentVideo] = useState(null)
  const playVideo = index => {
    submitGA("Click","play selected video");
    setCurrentVideo(index)
  }
  const AllVideos = () => {
    submitGA("Click","display all videos list");
    setCurrentVideo(null)
  }
 const submitGA =(action,label)=>{
    VideoTutorialsGA({ action, label});
}
  return (
    <div>
      <div className='row'>
        <div className='col-1'>
          <button
            type='button'
            className={`${currentVideo ? '' : 'active'} btn   btn-block btn-outline-info`}
            onClick={AllVideos}>
            <i class='fa fa-list'>&nbsp;</i>All
          </button>
        </div>
      </div>
      <div className='spacer'></div>
      {currentVideo ? (
        <>
          <div className='row'>
            <div className='col-8'>
              <Card className='text-center'>
                <Card.Body>
                  <ReactPlayer
                    url={currentVideo.url}
                    width='300'
                    height='200'
                    playing
                    controls
                    playIcon
                  />
                </Card.Body>
                <Card.Footer className='text-muted'>{currentVideo.title}</Card.Footer>
              </Card>
            </div>
            <div className='col-4'>
            <div style={{height:"540px",overflow:"scroll"}}>
              <div className='row'>
                {props.list.map((videoObj, index) => (
                  <>
                    {videoObj.title !== currentVideo.title ? (
                      <div class='col-12'>
                        <Card className='text-center'>
                          <Card.Body>
                            <ReactPlayer url={videoObj.url} width='200' height='200' />
                          </Card.Body>
                          <Card.Footer className='text-muted'>
                            <div className='row'>
                              <div className='col-10'>{videoObj.title}</div>
                              <div className='col-1'>
                                <i
                                  class='fa fa-play'
                                  aria-hidden='true'
                                  onClick={() => playVideo(videoObj)}></i>
                              </div>
                            </div>
                          </Card.Footer>
                        </Card>
                        <div className='spacer'></div>
                      </div>
                    ) : null}
                  </>
                ))}
              </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <div className='row'>
            {props.list.map((videoObj, index) => (
              <div class='col-4'>
                <Card className='text-center'>
                  <Card.Body>
                    <ReactPlayer url={videoObj.url} width='300' height='200' />
                  </Card.Body>
                  <Card.Footer className='text-muted'>
                    <div className='row'>
                      <div className='col-10'>{videoObj.title}</div>
                      <div className='col-1'>
                        <i
                          class='fa fa-play'
                          aria-hidden='true'
                          onClick={() => playVideo(videoObj)}></i>
                      </div>
                    </div>
                  </Card.Footer>
                </Card>
                <div className='spacer'></div>
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  )
}

export default VideosList
