import {CALENDAR_EMPTY_DATA, CALENDAR_DATA, CALENDAR_STRUCURE, LOADER ,DELETE_KEY} from "./actionTypes";

const initialState = {
    calendarStrucure: null,
    calendarDataPerYear: [],
    loader: false,
    promoMertricList:[]
}

export default function (state = initialState, action) {
    switch (action.type) {

        case CALENDAR_STRUCURE:
            return {
                ...state,
                calendarStrucure: action.payload
            };

        case LOADER:
            return {
                ...state,
                loader: action.payload
            };
        case CALENDAR_DATA:
            let historyCampId = localStorage.getItem('campaignId');
            if (historyCampId && historyCampId !== "null") {
                return {
                    ...state,
                    calendarDataPerYear: action.payload,
                    nodata: false
                };
            }
            return {
                ...state,
                pipelineData: [],
                calendarDataPerYear: action.payload,
                nodata: false
            };

        case CALENDAR_EMPTY_DATA:
            return {
                ...state,
                calendarDataPerYear: action.payload,
                // markdownCalendarData: action.payload, pipelineData: action.payload,
                // pipelineDataSku: action.payload, nodata: true, nodataPipeline: true
            };
        case 'GET_PROMOTION_PIPELINE':
            return {
                ...state,
                promoMertricList: action.payload
            }
        case 'GET_PROMOTION_ARTICLE_LEVEL':
            return {
                ...state,
                promoMertricList: action.payload
            }
            case DELETE_KEY:
                return {
                    ...state,
                    promoMertricList: []
                };
        default:
            return {
                ...state
            };
    }
}
