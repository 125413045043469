import React, { useState, useEffect } from "react";
import {DragSwitch} from 'react-dragswitch';
import 'react-dragswitch/dist/index.css';
import  { cloneDeep } from 'lodash';

const ToggleSelector = (props) => {
  const field = props.colDef.field;
  const [checked, setChecked] = useState(
    props.data ? props.data.lyfunding : false
  );
  const [toggle, disableToggle] = useState(props.data ? props.data.tag : true);
  const tempSkuTagData = props.tempSkuData && props.tempSkuData.tag;

  useEffect(() => {
  });

  useEffect(() => {
    if (props.data) {
      const { lyfunding, tag } = props.data;
      setChecked(lyfunding);
      disableToggle(tag);
    }
  }, [props.data])

  useEffect(() => {
    if (tempSkuTagData && props.tempSkuData.articleNo === props.data.skuid) {
      setChecked(true);
      disableToggle(true);
    }
  }, [props.tempSkuData]);

   const handleChange = selection => {
       let lyFundingData = cloneDeep(props.data);
       lyFundingData[field] = selection;
       props.changeToggleStatus(lyFundingData);
       setChecked(selection);
  }

  return (
    <DragSwitch
    checked={checked}
    onColor={'#44677b'}
    disabled={toggle}
    onChange={handleChange}
    />
  )
}
export default ToggleSelector;
