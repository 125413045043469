import { result } from "underscore";
import {SET_DMMTABLE_COLDATA, SET_DMM_ROWDATA, LOADER, 
    SET_OFFERTABLE_COLDATA, SET_OFFER_ROWDATA, SET_QUARTERDATA, SET_BUYERDATA,
    RESET_FILTERS, SET_FYEARDATA, SET_CATEGORYDATA, SET_VENDORDATA, SET_PLANIDDATA,SET_OFFER_PINNEDDATA} from "./actionTypes";

const initialState = {
    DMMrowData: {},
    DMMcolData:[],
    offerRowData:[],
    offerColData:[],
    offerPinnedData:[],
    quarterData:[],
    buyerData:[],
    categoryData:[],
    vendorData:[],
    planIDData:[],
    fyearData:[],
    offerPinnedData:[],
    loader: false,
    downloadData:{}
    // DMM_downloadData:{},
    // Offer_downloadData:{}
}

export default function (state = initialState, action) {
    switch (action.type) {

        case LOADER:
            return {
                ...state,
                loader: action.payload
            };
        case SET_DMMTABLE_COLDATA:
            return {
                ...state,
                DMMcolData: action.payload
            }
        case SET_DMM_ROWDATA:
            return {
                ...state,
                DMMrowData: action.payload
            }
        case SET_OFFERTABLE_COLDATA:
            return {
                ...state,
                offerColData: action.payload
            };
        case SET_OFFER_ROWDATA:
            return {
                ...state,
                offerRowData: action.payload
            };
        case SET_OFFER_PINNEDDATA:
            return{
                ...state,
                offerPinnedData:action.payload
            };
        case SET_QUARTERDATA:
            return{
                ...state,
                quarterData:action.payload,
                categoryData:[],
                vendorData:[],
                planIDData:[],
                DMMrowData: {},
                DMMcolData:[],
                offerRowData:[],
                offerColData:[],
            }
        case SET_BUYERDATA:
            return{
                ...state,
                buyerData:action.payload,
                categoryData:[],
                vendorData:[],
                planIDData:[],
                DMMrowData: {},
                DMMcolData:[],
                offerRowData:[],
                offerColData:[],
            }
        case SET_CATEGORYDATA:
            return{
                ...state,
                categoryData:action.payload,
                vendorData:[],
                planIDData:[],
                DMMrowData: {},
                DMMcolData:[],
                offerRowData:[],
                offerColData:[],
            }
        case SET_VENDORDATA:
            return{
                ...state,
                vendorData:action.payload,
                planIDData:[],
                DMMrowData: {},
                DMMcolData:[],
                offerRowData:[],
                offerColData:[],
            }
        case SET_PLANIDDATA:
            return{
                ...state,
                planIDData:action.payload,
                DMMrowData: {},
                DMMcolData:[],
                offerRowData:[],
                offerColData:[],
            }
        case SET_FYEARDATA:
            return {
                ...state,
                fyearData: action.payload,
                categoryData:[],
                vendorData:[],
                planIDData:[],
                DMMrowData: {},
                DMMcolData:[],
                offerRowData:[],
                offerColData:[],
                }
        case RESET_FILTERS:
            return{
                ...state,
                categoryData:[],
                vendorData:[],
                planIDData:[],
                loader: false,
                DMMrowData: {},
                DMMcolData:[],
                offerRowData:[],
                offerColData:[],
            }
        case 'DMM_Download':
            return{
                ...state,
                downloadData:action.payload
            }
        case 'Offer_Download':
            return{
                ...state,
                downloadData:action.payload
            }
        default:
            return {
                ...state
            };
    }
}
