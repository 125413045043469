import React, { Fragment, useState, useEffect } from 'react';
import { LEVEL_TYPES, SORT_BY_OPTIONS, QUARTER, CYCLE, YTD,VENDOR } from './constants';
import MultiSelectCheckboxes from '../MultiSelectCheckboxes';
import Toggle from 'react-toggle';
import classNames from 'classnames';
const FiletrOptions = (props) => {
  const [level, setLevel] = useState(props.level);
  const [quarterOptions, setQuarterOptions] = useState(props.quarterOptions);
  const [cycleOptions, setCycleOptions] = useState(props.cycleOptions);
  const [value, setValue] = useState(props.value);
  const [metchcatOptions, setMetchcatOptions] = useState(props.merchcatValues);
  const [sortBy, setSortBy] = useState(props.sortBy);
  const [sortByOptions, setSortByOptions] = useState(props.sortByOptions);
  
  

  useEffect(() => {
    setQuarterOptions(props.quarterOptions);
  }, [props.quarterOptions]);
  useEffect(() => {
    setCycleOptions(props.cycleOptions);
  }, [props.cycleOptions]);
  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  useEffect(() => {
    setSortBy(props.sortBy);
  }, [props.sortBy]);
  useEffect(() => {
    setSortByOptions(props.sortByOptions);
  }, [props.sortByOptions]);
  const onLevelChange = (e) => {
    const { name, value } = e.target;
    props.changeLevel({
      level: value,
    });
    setLevel(value);
  };
  useEffect(() => {
    setMetchcatOptions(props.merchcatValues)
  }, [props.merchcatValues]);
  const updateState = (params) => {
    props.selectedOption(params);
    setValue(params.value);
  };
  const onSortBychange = (params) => {
    props.sortByChange(params.sortBy);
    const val = params.sortBy.map(key => {return {value:key.value, label:key.value}})
    setSortBy(val);
  };
  const updateCateoryChange = params => {
    props.handleSelectedMerchcat(params)
  }

  return (
    <React.Fragment>
      <div className="row">
        <div
          className={
            [QUARTER, CYCLE].indexOf(level) !== -1
              ? 'col-sm-6 col-md-6 col-lg-6 col-xl-6'
              : 'col-sm-6 col-md-6 col-lg-6 col-xl-6'
          }>
          <div className="row radio_filter">
            {LEVEL_TYPES.map((obj, index) => (
              <div
                className={
                  obj.key === level && [QUARTER, CYCLE].indexOf(level) !== -1
                    ? level === QUARTER?'col-sm-7 col-md-7 col-lg-7 col-xl-7':'col-sm-6 col-md-6 col-lg-6 col-xl-6'
                    : level === YTD
                    ? 'col-sm-3 col-md-3 col-lg-3 col-xl-3'
                    : level === QUARTER && obj.label=== YTD?'col-sm-2 col-md-2 col-lg-2 col-xl-2':'col-sm-3 col-md-3 col-lg-3 col-xl-3'
                }>
                <div className="row">
                  <div
                    className={
                      obj.key === level && [QUARTER, CYCLE].indexOf(level) !== -1
                        ? 'col-sm-5 col-md-5 col-lg-5 col-xl-5 increase_padding'
                        : 'col-sm-12 col-md-12 col-lg-12 col-xl-12'
                    } title={obj.key === YTD ? props.ty: null}>
                    <input
                      type="radio"
                      value={obj.key}
                      checked={level === obj.key}
                      onChange={onLevelChange}
                      name="value"
                    />
                    &nbsp;&nbsp;<b>{obj.label}</b>
                  </div>
                  {obj.key === CYCLE && level === CYCLE ? (
                    <>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 filterDropdown">
                        <MultiSelectCheckboxes
                          placeholderButtonLabel="Select"
                          options={props.cycleOptions}
                          value={value}
                          mappingKey="value"
                          updateState={updateState}
                          defaultSelectedOptions={true}
                        />
                      </div>
                    </>
                  ) : null}
                  {obj.key === QUARTER && level === QUARTER ? (
                    <>
                      <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6 filterDropdown">
                        <MultiSelectCheckboxes
                          placeholderButtonLabel="Select"
                          options={props.quarterOptions}
                          value={value}
                          mappingKey="value"
                          updateState={updateState}
                          defaultSelectedOptions={true}
                        />
                      </div>
                    </>
                  ) : null}
                </div>
              </div>
            ))}
            {/*             <div className="col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <div className="row">
                {level === 'cycle' ? (
                  <>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3"><b>Cycle</b></div>
                    <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9">
                      <MultiSelectCheckboxes
                        placeholderButtonLabel="Select"
                        options={props.cycleOptions}
                        value={value}
                        mappingKey="value"
                        updateState={updateState}
                      />
                    </div>
                  </>
                ) : null}
                {level === 'quarter' ? (
                  <>
                    <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3"><b>Quarter</b></div>
                    <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9">
                      <MultiSelectCheckboxes
                        placeholderButtonLabel="Select"
                        options={props.quarterOptions}
                        value={value}
                        mappingKey="value"
                        updateState={updateState}
                      />
                    </div>
                  </>
                ) : null}
              </div>
            </div> */}
          </div>
        </div>
        <div
          className={
            [QUARTER, CYCLE].indexOf(level) !== -1
              ? 'col-sm-2 col-md-2 col-lg-2 col-xl-2'
              : 'col-sm-2 col-md-2 col-lg-2 col-xl-2'
          }>
          <div className="row">
            <div className="text-right BtnGroupRow">
              <div
                id="promoCal"
                className="divInn btn-group "
                style={{
                  textAlign: 'center',
                }}>
                <button
                  type="button"
                  className={classNames({
                    active: props.viewType == 'Charts',
                    ' btn': true,
                    'btn-outline-primary cal-btn': true,
                    btnCalendr: true,
                    calPromo: true,
                  })}
                  onClick={() => props.handleToggleChange('Charts')}
                  style={{
                    width: '90px',
                  }}>
                  Charts
                </button>
                <button
                  type="button"
                  className={classNames({
                    active: props.viewType == 'Table',
                    ' btn': true,
                    'btn-outline-primary cal-btn': true,
                    btnCalendr: true,
                    calPromo: true,
                  })}
                  onClick={() => props.handleToggleChange('Table')}
                  style={{
                    width: '90px',
                  }}>
                  Table
                </button>
              </div>
            </div>
            {/* <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <span className="chart-toggle">
                <b>Charts</b>
              </span>
            </div>
            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <Toggle
                defaultChecked={props.showCharts}
                icons={false}
                onChange={props.handleToggleChange}
              />
            </div>
            <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 col-xl-4">
              <span className="table-toggle">
                <b>Table</b>
              </span>
            </div> */}
          </div>
        </div>
        <div
          className={
            [QUARTER, CYCLE].indexOf(level) !== -1
              ? 'col-sm-4 col-md-4 col-lg-4 col-xl-4'
              : 'col-sm-4 col-md-4 col-lg-4 col-xl-4'
          }>
          <div className="row">
            <div className="col-sm-3 col-md-3 col-lg-3 col-xl-3 sortByLabel">
              <b>Sort By</b>
            </div>
            <div className="col-sm-9 col-md-9 col-lg-9 col-xl-9 sortByDropdown">
              <MultiSelectCheckboxes
                placeholderButtonLabel="Select"
                options={sortByOptions}
                value={sortBy}
                mappingKey="sortBy"
                updateState={onSortBychange}
                isMulti={false}
              />
            </div>
          </div>
        </div>
       
      </div>
      <hr className="dotted-line" style={{ marginBottom: '20px' }} />
      {props.view === VENDOR && <div className="row category_dropdown">
          <div className="">
              <b>Select Category</b>
            </div>
          <div className="col-md-3">
            <MultiSelectCheckboxes
              placeholderButtonLabel="Select Category"
              options={metchcatOptions}
              value={props.selectedMerchcat}
              mappingKey="selectedMerchcat"
              updateState={updateCateoryChange}
              isMulti={true}
            />
          </div>
        </div>}
    </React.Fragment>
  );
};

export default FiletrOptions;
