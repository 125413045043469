import React, { Fragment, useState, useEffect } from 'react';
import ChartTableView from './chartTableView';
const ConsolidateView = (props) => {
  return (
    <React.Fragment>
      <ChartTableView {...props} />
    </React.Fragment>
  );
};

export default ConsolidateView;
