import {
  SET_SKU_DATA,
  SET_VENDORHISTORY_TABLE,
  SET_PLAN_TABLE,
  SET_METRICS_TABLE,
  SET_LOADER,
  SET_BUDGET_PLAN_TABLE,
  SET_BUDGET_PLAN_ROW_DATA,
  SET_YEAR_QUATERS_DATA,
  SET_BUDGET_PLAN_METRICS_TABLE,
  SET_PLAN_DATA,
  SET_PLAN_STATUS,
  SET_DESELECTED_SKUS,
  RESET_BUDGET_PLAN_ID_DATA,
  RESET_VENDORHISTORY_TABLE,
  SET_OPTIMIZATION_LINK,
  RESET_OPTIMIZATION_LINK,
  SET_BUDGET_PLAN_HEADERS,
  RESET_BUDGET_PLAN_HEADERS,
  SET_BUDGET_PLAN_ERROR,
  SET_AH6_DATA,
  SET_INACTIVE_METRICS_DATA,
  SET_VENDORHISTORY_BRAND_TABLE,
  BUDGET_PLAN_CAMPAIGN_LIST,
  SET_UPDATED_DISCOUNT_DEPTH,
} from './actionTypes';

const initialState = {
  skuData: {
    data: [], // data for each pagegroupno and pagegroupsize
    totalCount: 0, // total no of records
    pageGroupNo: 1, //page group number
    pageGroupSize: 500, // no of records to show per page
    deselectedSku: [],
  },
  plannedArticleData: {
    data: [],
    books: [],
  },
  planData: [],
  budgetMetricsData: {
    data: [],
    books: [],
  },
  actualPopUpData: {
    // discountDepth: [],
    // mediaType: [],
    // promoDays:[]
  },
  vendorHistoryTable: [],
  planTable: [],
  yearQuaters: [],
  metricsTable: [],
  loading: false,
  planStatus: '',
  ah6: [],
  updatedPlanDiscounts: [],
  optimizationLink: null,
  headers: [],
  planError: '',
  inactiveMetricsData: [],
  brandHistoryData: [],
  budgetPlanCampaignList: [],
  simulatedDiscounts:[],
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADER:
      return {
        ...state,
        loading: payload,
      };
    case SET_SKU_DATA:
      return {
        ...state,
        skuData: {
          ...state.skuData,
          ...payload,
        },
      };
    case SET_DESELECTED_SKUS:
      return {
        ...state,
        skuData: {
          ...state.skuData,
          ...payload,
        },
      };
    case SET_VENDORHISTORY_TABLE:
      return {
        ...state,
        vendorHistoryTable: payload,
      };
    case SET_VENDORHISTORY_BRAND_TABLE:
      return {
        ...state,
        brandHistoryData: [...payload]
      }
    case SET_PLAN_TABLE:
      return {
        ...state,
        planTable: payload,
      };
    case SET_METRICS_TABLE:
      return {
        ...state,
        metricsTable: payload,
      };
    case SET_BUDGET_PLAN_METRICS_TABLE:
      return {
        ...state,
        budgetMetricsData: payload,
      };
    case SET_BUDGET_PLAN_TABLE:
      return {
        ...state,
        plannedArticleData: payload,
      };
    case SET_UPDATED_DISCOUNT_DEPTH:
      return {
        ...state,
        simulatedDiscounts: payload,
      };
    case SET_PLAN_DATA: {
      return {
        ...state,
        planData: payload,
      };
    }
    case SET_BUDGET_PLAN_ROW_DATA:
      const articleNo = payload.data.articleNo;
      const plannedArticleData = { ...state.plannedArticleData };
      plannedArticleData['data'][articleNo] = payload.data;
      return {
        ...state,
        plannedArticleData,
      };
    case SET_YEAR_QUATERS_DATA:
      return {
        ...state,
        yearQuaters: payload,
      };
    case SET_PLAN_STATUS:
      return {
        ...state,
        planStatus: payload,
      };
    case SET_BUDGET_PLAN_ERROR:
      return {
        ...state,
        planError: payload,
      };
    case RESET_BUDGET_PLAN_ID_DATA:
      return initialState;
    case RESET_VENDORHISTORY_TABLE:
      return {
        ...state,
        vendorHistoryTable: payload,
      };
    case SET_OPTIMIZATION_LINK:
      return {
        ...state,
        optimizationLink: payload,
      };
    case RESET_OPTIMIZATION_LINK:
      return {
        ...state,
        optimizationLink: null,
      };
    case SET_BUDGET_PLAN_HEADERS:
      return {
        ...state,
        headers: payload,
      };
    case SET_AH6_DATA:
      return {
        ...state,
        ah6: payload,
      };
    case RESET_BUDGET_PLAN_HEADERS:
      return {
        ...state,
        headers: [],
      };
    case SET_INACTIVE_METRICS_DATA :
      return {
        ...state,
        inactiveMetricsData: payload
      }
    case BUDGET_PLAN_CAMPAIGN_LIST:
        return {
          ...state,
          budgetPlanCampaignList: action.payload
        }
    default:
      return {
        ...state,
      };
  }
}
