import React from "react";
import MultiSelectCheckboxes from '../MultiSelectCheckboxes';
import Square from "./../common/SquareBox";

const ConstraintCycleSelection = props => {
    return(
        <React.Fragment>
        {props.cyclesList.map((cycleObj) => (
            <>
              <div className="row">
                <div className="col-4">
                  <div className="row">
                    <div className="col-6">{cycleObj.label}</div>
                    <div className="col-6">
                      <MultiSelectCheckboxes
                        placeholderButtonLabel={cycleObj.label}
                        options={cycleObj.options}
                        value={cycleObj.state}
                        mappingKey={cycleObj.mappingKey}
                        updateState={props.updateState}
                        onMenuCloseApi={false}
                        defaultSelectedOptions={true}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-8">
                  <Square
                    selectedCycles={cycleObj.state.map((obj) => obj.value)}
                  />
                </div>
              </div>
              <div className="spacer"></div>
            </>
          ))}
        </React.Fragment>
    )
};

export default ConstraintCycleSelection;