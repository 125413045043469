import { callApi } from "./config";
import { MESSAGES } from "../common/app-constants";
import { ToastMessage } from "../common/ToastMessage";

export const fetchMerchCatValues = (reqObj) => {
  return fetchHierarchyData('merchcat', reqObj);
};

export const fetchCategorySpecificVendors = reqObj => {
  return fetchHierarchyData('vendor', reqObj);
};

export const fetchDateMappingFilterData = (params) => {
  return callApi
    .get(`datemapping/quaters`,{params:params})
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return err;
    });
};

export const fetchCampaignList = reqObj => {
  return callApi
    .get(`/campaigns/list`, { params: reqObj })
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return err;
    });
};

export const fetchPromoCampaigns = reqObj => {
  return callApi
    .post(`promo/campaigns`, { ...reqObj })
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return err;
    });
};

export const fetchFromUrl = (url) => {
  return callApi
    .get(url)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return err;
    });
}

export const updateSelectedMetricsApi = reqObj => {
  return callApi
    .post(`userinfo/save/selectedMetrics`, { ...reqObj })
    .then(res => {
      return res.data.data.selectedMetrics;
    })
    .catch(err => {
      return err;
    });
};

export const fetchSelectedMetricsApi = () => {
  return callApi
    .post(`userinfo/save/selectedMetrics`, {})
    .then(res => {
      if(res.status){
        return res.data.data.selectedMetrics;

      }else{
        return null;
      }
    })
    .catch(err => {
      return err;
    });
};

export const fetchHierarchyData = (filterProperty, reqObj) => {
  return callApi
    .post(`/hierarchy/filters/${filterProperty}`, reqObj)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return err;
    });
};

export const fetchSpecificPlan = (planId) => {
  return callApi
    .get(`/budgetplan/fetch/plans/${planId}`)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return err;
    });
};



export const fetchSimulateData = (reqObj) =>{
  return callApi
    .post(`/simulation/promo`,reqObj)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      throw err;
    });
}

export const saveSimulate = (reqObj) =>{
  return callApi
    .post(`/simulation/savePromo`,reqObj)
    .then(res => {
      ToastMessage(res.status,MESSAGES.SUCCESS_MESSAGE)
      return res.data.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      throw err;
    });
}

export const fetchPromo = (reqObj) =>{
  return callApi
    .get(`/promo/getPromo`,{params: {...reqObj}})
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      throw err;
    });
}

export const downloadPromoData = reqObj => {
  return callApi
    .post("/simulation/downloadPromos", reqObj)
    .then(res => {

      ToastMessage(res.status,MESSAGES.DOWNLOAD_SUCCESS_MESSAGE)
      return res.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      throw err;
    });
}


export const logout = () => {
  window.open('/loggeduser/logout', "_self");
  // return callApi
  //   .get(`/loggeduser/logout`)
  //   .then(res => {
  //     return res.data.data;
  //   })
  //   .catch(err => {
  //     return err;
  //   });
};

export const downloadPromoTracker = reqObj => {
  return callApi
    .post("/promo/downloadPromoTracker", reqObj)
    .then(res => {
      ToastMessage(res.status,MESSAGES.DOWNLOAD_SUCCESS_MESSAGE)
      return res.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      throw err;
    });

}