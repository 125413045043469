import React, { Component } from 'react';
import ConstraintsTable from './constraintsTableComponent';
import CascadingFilters from '../CascadingFilters';
import PageLoader from '../../utils/Loader';
import PageWrapper from '../common/PageWrapper';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  actionGetConstraintsList,
  actionResetConstraintsList,
  actionDeleteConstraint,
  actionSaveConstraint,
  actionDownloadConstraints,
  actionGetVendorList,
  actionGetArticleList,
  actionfetchGroupDiscountDepth,
  actionCreateGroup,
  actionResetGroupData,
  isValidGroup,
  actionFechGroupData,
  actionDeleteGroup,
  actionSetSelecteArticle
} from '../../actions/constraintsAction';
import EditConstraintModal from '../ConstraintsPopup/editConstraintModal';
import './index.css';
import ConfirmationModal from '../common/displayModal';
import GroupProduct from '../GroupProductPopup';
import { ConstraintsGA } from '../../common/GAUtils';

class Constraints extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      filterSelectedOptions: {
        merchcat: [],
        vendor: [],
        ah6: [],
      },
      selectedConstraints: [],
      actionType: null,
      isShow: false,
      selectedArticleNo: [],
      selctedGroupId:[],
      message: '',
      prevStateSelectedFilters: {},
      isGroupOpen: false,
      groupName: '',
      groupMode: 'create',
      constraintDD: []
    };
  }
  openLoader = () => {
    this.setState({
      loader: true,
    });
  };
  closeLoader = () => {
    this.setState({
      loader: false,
    });
  };
  componentDidMount() {
    if (this.props.redirectToConstraints) {
      let filterSelectedOptions = {
        merchcat: this.props.selectedMerchcats.map((data) => data.value),
        vendor: this.props.selectedVendors.map((data) => data.value),
        ah6: this.props.selectedAh6.map((data) => data.value),
      };
      // const reqObj = {
      //   category: this.props.selectedMerchcats.map((data) => data.value),
      //   vendor: this.props.selectedVendors.map((data) => data.value),
      //   ah6: this.props.selectedAh6.map((data) => data.value),
      //   screenName: 'budget-planning'
      // };
      const reqObj = this.props.fetchReqObj;
      this.setState({
        prevStateSelectedFilters: filterSelectedOptions,
        filterSelectedOptions,
      });
      if(this.props.planNum!==''){
        reqObj.planId=this.props.planNum;
      } else if(this.props.planId) {
        reqObj.planId=this.props.planId;
      }
      this.props.actionGetConstraintsList(reqObj);
    }
  }
  filterData = (filterSelectedOptions) => {
    this.submitGA("Click","Constraints Filter Button clicked");
    this.setState({
      filterSelectedOptions,
    });
    const { merchcat, vendor, ah6 } = filterSelectedOptions;
    const reqObj = {
      category: merchcat,
      vendor,
      ah6,
    };
    this.props.actionGetConstraintsList(reqObj);
  };
  onReset = () => {
    this.submitGA("Click","Constraints Reset Button clicked");
    this.setState({
      filterSelectedOptions: {
        vendor: [],
        ah6: [],
        merchcat: [],
      },
      selectedConstraints:[]
    });
    this.props.actionResetConstraintsList();
  };
  filterUpdate = () => {
    this.props.actionResetConstraintsList();
  };
  bulkEdit = () => {
    this.submitGA("Click","Bulk Edit clicked");
    this.setState({
      isOpen: true,
      actionType: 'Bulk Edit',
    });
  };
  bulkRemove = async () => {
    this.submitGA("Click","Bulk Remove clicked");
    const { selectedConstraints } = this.state;

    const articleNo = selectedConstraints.map((constraint) => {
      return constraint.articleNo;
    });
    this.setState({
      isShow: true,
      message: `Do you want to delete Constraints on these Article ${articleNo.join(', ')} ?`,
      selectedArticleNo: articleNo,
    });
  };
 
  handleDeleteConfirm = async (isdelete) => {
    const { filterSelectedOptions, selectedArticleNo, selctedGroupId } = this.state;

    this.submitGA("Click",`Removed constraints for article ${selectedArticleNo.join(', ')} `);
    const { merchcat, vendor, ah6 } = filterSelectedOptions;
    const reqObj = {
      category: merchcat,
      vendor,
      ah6,
    };
    const delReq = {
      articleNo: selectedArticleNo,
      groupid: selctedGroupId
    };
    if(this.props.redirectToConstraints ){
      delReq.screenName= 'budget-planning';
      reqObj.screenName= 'budget-planning';
      if( this.props.planNumber!==''){
      delReq.planId=this.props.planNumber;
      reqObj.planId=this.props.planNumber;}
    }
    this.setState({ isShow: false});
    if (isdelete) {
      this.setState({ selectedConstraints: [] })
      await this.props.actionDeleteConstraint(delReq);
      this.props.actionGetConstraintsList(reqObj);
    }
  };
  closeModal = () => {
    this.submitGA("Click","Edit constraints modal closed");
    if(this.state.actionType == 'Edit') {
      this.setState({
        selectedConstraints: [],
      });
    }
    this.setState({
      isOpen: false,
      // selectedConstraints: [],
    });
  };

  handleConstraintSelection = (data) => {
    const selectedRows = data.api.getSelectedRows();

    // const reqData = selectedRows.map(row => {
    //   return { type: row.type, id: row.id };
    // });

    this.setState({ selectedConstraints: [...selectedRows] });
  };
  handleEditConstraint = (data) => {

    this.setState({ selectedConstraints: [data] });
    this.setState({
      isOpen: true,
      actionType: 'Edit',
    });
  };
  saveConstraints = (params) => {
    const { merchcat, vendor, ah6 } = this.state.filterSelectedOptions;
    let filterData = {};
    let tempParams = params;
    if (this.props.redirectToConstraints) {
      let planData = {
        fyear: this.props.selectedYear,
        quarters: this.props.selectedQuarters.map((data) => parseInt(data.value.slice(1))),
        offerType: this.props.offerType,
        vendor: this.props.selectedVendors.map(data => data.value)
      };
      tempParams.planData = planData;
      tempParams.planData.categories = this.props.selectedMerchcats.map((data) => data.value);
      filterData = planData;
      filterData.category = this.props.selectedMerchcats.map((data) => data.value);
      tempParams.screenName= 'budget-planning'
      filterData.screenName= 'budget-planning';
      if (this.props.planNumber) {
        tempParams.planData.planId = this.props.planNumber;
      } else if(this.props.planId) {
        tempParams.planData.planId = this.props.planId;
      }
    } else {
      filterData = {
        category: merchcat,
        vendor,
        ah6,
      };
    }
    
    const reqobj = {
      params: tempParams,
      filterData,
    };
    this.props.actionSaveConstraint(reqobj);
    this.setState({
      isOpen: false,
      selectedConstraints: [],
    });
  };

  removeConstraint = (data) => {
    let msg = '';

    if(data.articleNo){
      msg = `Article ${data.articleNo}`
      this.submitGA("Click",`Remove constraints clicked for  ${data.articleNo}`);
    } else {
      msg = `Group ${data.groupid}`
      this.submitGA("Click",`Remove constraints clicked for  ${data.groupid}`);
    }
    this.setState({
      isShow: true,
      message: `Do you want to delete Constraints on ${msg} ?`,
      selectedArticleNo: [data.articleNo],
      selctedGroupId: [data.groupid]
    });
  };
  handleBackClick = () => {
    this.submitGA("Click","Back button clicked to navigate to Budget Planning screen");
    this.props.handleBack({planId:this.props.planNumber, data: this.props.vendorHistoryData, prevPlanId: this.props.planId, category: this.props.selectedMerchcats});
   
  };

  handleDownload = () => {
    this.submitGA("Click","Download constraints.");
    const { merchcat, vendor, ah6 } = this.state.filterSelectedOptions;
    const reqObj = {
      category: merchcat,
      vendor: vendor,
      ah6: ah6,
    };
    this.props.actionDownloadConstraints(reqObj);
  };

  getPlanId = () => {
    if(this.props.redirectToConstraints){
    if (this.props.planNumber) {
      return this.props.planNumber
    } else if (this.props.planId) {
      return this.props.planId
    }
  }
    return null;
  }

  getScreenName = () => {
    if (this.props.planNumber || this.props.planId) {
      return 'budget-planning';
    }
    return ''
  }

  handleDeleteGroup = async (args) => {
    const { groupid } = args;
    const { merchcat, vendor, ah6 } = this.state.filterSelectedOptions;

    this.submitGA("Click",`Delete group  ${groupid}`);
    const reqObj = {
      category: merchcat,
      vendor,
      ah6,
      planId: this.getPlanId(),
      screenName: this.getScreenName()
    };

    const deleteReq = {
      groupid,
      type: 'delete',
      planId: this.getPlanId(),
      screenName: this.getScreenName()
    }

    this.setState({
      isGroupOpen: false,
      constraintDD: []
    });

    await this.props.actionDeleteGroup(deleteReq);
    await this.props.actionGetConstraintsList(reqObj);
    
    const restPopUp =[];
    this.props.actionResetGroupData(restPopUp);
  }

  handleEditGroup = () => {
    this.submitGA("Click","Edit Group");
    this.setState({groupMode: 'edit'})
  }

  handleViewEditGroup = (args) => {
    const { merchcat } = this.state.filterSelectedOptions;
    const { data, mode } = args;

    this.submitGA("Click",`Group ${mode}`);
    const { groupid } = data;
    const reqObj = {
      groupid,
      sendInactive: false,
      sendSimulated: true,
      merchcat
    }
    let tempDD = [];
    if(args.data.cycles.length>0) {
      Object.keys(args.data).forEach(key => {
        if (key.startsWith("BBM") && parseFloat(args.data[key])) {
         if(!tempDD.includes(parseFloat(args.data[key]))){
         tempDD.push(parseFloat(args.data[key]))}
        }
    })
  }
    this.props.actionFechGroupData(reqObj);
    this.setState({groupMode: mode, isGroupOpen: true, constraintDD: [...tempDD]});
  }
  createGroup = () => {
    const { merchcat } = this.state.filterSelectedOptions;

    this.submitGA("Click",`Create Group`);
    const reqObj = {
      merchcat,
      sendInactive: false,
      sendSimulated: true,
    };
    this.props.actionGetVendorList(reqObj)
    this.setState({
      isGroupOpen: true,
      groupMode: 'create'
    })
  }
  handleGroupPopupClose = () => {
    this.submitGA("Click",`Group popup close`);
    this.setState({
      isGroupOpen: false,
      constraintDD: []
    });
    const restPopUp =[];
    this.props.actionResetGroupData(restPopUp);
    this.props.actionSetSelecteArticle([]);
  }

  handleSaveGroup = async (args) => {
    this.submitGA("Click",`Save group ${args.groupName}`);
    this.setState({
      isGroupOpen: false,
      constraintDD: []
    });
    const { merchcat, vendor, ah6 } = this.state.filterSelectedOptions;
    const filterData = {
      category: merchcat,
      vendor,
      ah6,
    };
    const groupData = {
      groupId: args.groupName,
      planId: null,
      articleNo: args.articleNo?args.articleNo:null,
      vendor: args.vendor
    }
    // let tempParams = params;
    if (this.props.redirectToConstraints) {
      groupData.planData = {
        categories: this.props.selectedMerchcats.map((data) => data.value),
        fyear: this.props.selectedYear,
        quarters: this.props.selectedQuarters.map((data) => parseInt(data.value.slice(1))),
        offerType: this.props.offerType,
        vendor: this.props.selectedVendors.map(data => data.value)
      };
      groupData.planId = this.props.planNumber?this.props.planNumber:null;
      filterData.screenName= 'budget-planning';
      if (this.props.planNumber) {
        groupData.planData.planId = this.props.planNumber;
        filterData.planId = this.props.planNumber;
      } else if(this.props.planId) {
        groupData.planData.planId = this.props.planId;
        filterData.planId = this.props.planId;
      }
    }

    groupData.category = merchcat[0];
    
    const reqObj = {
      params: groupData,
      filterData,
      type: 'save'
    }
    
    const deleteReq = {
      groupid: args.groupName,
      type: 'save'
    }

    // await this.props.actionDeleteGroup(deleteReq);
    await this.props.actionCreateGroup(reqObj);
    const restPopUp =[];
    this.props.actionResetGroupData(restPopUp);
  }

  handleGroupCreate = async (args) => {
    this.submitGA("Click",`Create group ${args.groupName}`);
    this.setState({
      isGroupOpen: false,
      constraintDD: []
    });
    const { merchcat, vendor, ah6 } = this.state.filterSelectedOptions;
    let filterData = {};

    const groupData = {
      groupId: args.groupName,
      planId: null,
      articleNo: args.articleNo?args.articleNo:null,
      vendor: args.vendor,
      type: 'create'
    }
    // let tempParams = params;
    if (this.props.redirectToConstraints) {
      let planData = {
        fyear: this.props.selectedYear,
        quarters: this.props.selectedQuarters.map((data) => parseInt(data.value.slice(1))),
        offerType: this.props.offerType,
        vendor: this.props.selectedVendors.map(data => data.value)
      };
      groupData.planData = planData;
      groupData.planId = this.props.planNumber?this.props.planNumber:null;
      groupData.planData.categories = this.props.selectedMerchcats.map((data) => data.value);
      filterData = planData;
      filterData.category = this.props.selectedMerchcats.map((data) => data.value);
      groupData.screenName= 'budget-planning'
      filterData.screenName= 'budget-planning';

    if (this.props.planNumber) {
      groupData.planData.planId = this.props.planNumber;
      filterData.planId = this.props.planNumber;
    } else if(this.props.planId) {
      groupData.planData.planId = this.props.planId;
      filterData.planId = this.props.planId;
    }
    } else {
      filterData = {
        category: merchcat,
        vendor,
        ah6,
      };
    }

    groupData.category = merchcat[0];
    const reqObj = {
      params: groupData,
      filterData,
    }
    await this.props.actionCreateGroup(reqObj);
    const restPopUp =[];
    this.props.actionResetGroupData(restPopUp);
    // this.props.actionGetConstraintsList(filterData);
  }
 
  componentWillUnmount() {
    this.props.actionResetConstraintsList();
  }

  submitGA(action,label){
    ConstraintsGA({ action, label});
  }
  
  render() {
    const { merchcat, vendor, ah6 } = this.state.filterSelectedOptions;
    // const {state } = this.props.location;
    
    return (
      <React.Fragment>
        <PageLoader loader={this.state.loader || this.props.loader}>
          <EditConstraintModal
            isOpen={this.state.isOpen}
            closeModal={this.closeModal}
            constraintData={this.state.selectedConstraints}
            actionType={this.state.actionType}
            saveConstraints={this.saveConstraints}
          />
          <PageWrapper>
            <h4 className="promoCal-brdcrm mb-10">
              <span className="font-bold">Constraints</span>
              {this.props.redirectToConstraints  && <hr/> }
            </h4>
            {this.props.redirectToConstraints ? (
              <div>
              <button
                type="button"
                className="btn btn-primary saveButton"
                // disabled={props.isDisableOptimise}
                onClick={() => this.handleBackClick()}>
                Back
              </button>
              <hr/>
              </div>
            ) : (
              <div>
              <div className="row filter-block">
                <CascadingFilters
                  openLoader={this.openLoader}
                  closeLoader={this.closeLoader}
                  filterData={this.filterData}
                  onReset={this.onReset}
                  isFilterButtonRequired={true}
                  // selectedMerchcat={this.props.selectedMerchcat?this.props.selectedMerchcat:[]}
                  disabledApiCall={this.props.redirectToConstraints ? true : false}
                  isFilterButtonDisabled={
                    merchcat.length === 0 || vendor.length === 0 || ah6.length === 0
                  }
                  filterUpdate={this.filterUpdate}
                  screenName="Constraints"
                />
              </div>
              </div>
            )}
            <div className={classNames('bulkEditDelete ', {
                      'constraintDownload': !this.props.userInfo.action.includes('edit-constraints') &&
                      !this.props.userInfo.action.includes('delete-constraints')
            })}>
              {this.props.userInfo.action.includes('edit-constraints') ? (
                <>
                  <button
                    type="button"
                    className={classNames('actionButton', {
                      ' btn': true,
                      'btn-outline-primary cal-btn1': true,
                      btnCalendr: true,
                      calPromo: true,
                    })}
                    disabled={this.state.selectedConstraints.length === 0}
                    onClick={this.bulkEdit}>
                    <i class="fa fa-pencil-square-o" aria-hidden="true"></i> Bulk Edit Constraints
                  </button>
                </>
              ) : null}
              {this.props.userInfo.action.includes('delete-constraints') ? (
                <>
                  <button
                    type="button"
                    className={classNames('actionButton', {
                      ' btn': true,
                      'btn-outline-primary cal-btn1': true,
                      btnCalendr: true,
                      calPromo: true,
                    })}
                    onClick={this.bulkRemove}
                    disabled={this.state.selectedConstraints.length === 0}>
                    <i class="fa fa-trash-o" aria-hidden="true"></i> Bulk Remove Constraints
                  </button>
                </>
              ) : null}
              {this.props.userInfo.action.includes('edit-constraints') ? (
                <>
                  <button
                    type="button"
                    className={classNames('actionButton', {
                      ' btn': true,
                      'btn-outline-primary cal-btn1': true,
                      btnCalendr: true,
                      calPromo: true,
                    })}
                    disabled={this.props.constraintsList && !(this.props.constraintsList.length > 0)}
                    onClick={this.createGroup}
                    >
                    + Create Group
                  </button>
                </>
              ) : null}
              {!this.props.redirectToConstraints &&
              <>
               <div className="row" style={{float: 'right'}}>
                <div className="promolist-header ">
                <label className="pipelineLabel downloadLbel">Constraints List</label>
                </div>
                <button
                  className="btn btn-primary iconbutton download-btn"
                  onClick={this.handleDownload}
                  disabled={this.props.constraintsList && !(this.props.constraintsList.length > 0)}
                >
                  <span>
                    <i class="fa fa-download" aria-hidden="true" />
                  </span>
                </button>
              </div>
              </>}
              {this.props.userInfo.action.includes('edit-constraints') &&
              this.props.userInfo.action.includes('delete-constraints') ? (
                <>
                  <div>
                    <span className="info-message">
                      * Min - Max and Fixed Discount Depth can't be applied for multiple articles
                    </span>
                  </div>
                </>
              ) : null}
            </div>
            {this.props.constraintsList && this.props.constraintsList.length > 0 && (
              <ConstraintsTable
                constraintData={this.props.constraintsList}
                handleConstraintSelection={this.handleConstraintSelection}
                handleEditConstraint={this.handleEditConstraint}
                removeConstraint={this.removeConstraint}
                selectedConstraints={this.state.selectedConstraints}
                userInfo={this.props.userInfo}
                handleViewEditGroup={this.handleViewEditGroup}
                handleEditGroup={this.handleEditGroup}
              />
            )}
            <div>
              <ConfirmationModal
                isShow={this.state.isShow}
                message={this.state.message}
                handleConfirmModel={this.handleDeleteConfirm}
              />
            </div>
            {this.state.isGroupOpen && 
            <GroupProduct
              handleCancel={this.handleGroupPopupClose}
              handleCreate={this.handleGroupCreate}
              handleSaveGroup={this.handleSaveGroup}
              handleEditGroup={this.handleEditGroup}
              handleDeleteGroup={this.handleDeleteGroup}
              groupName={this.state.groupName}
              isShow={this.state.isGroupOpen}
              vendorList={this.props.vendorList}
              articleList={this.props.articleList}
              groupArticleData={this.props.groupArticleData}
              actionGetArticleList={this.props.actionGetArticleList}
              actionfetchGroupDiscountDepth={this.props.actionfetchGroupDiscountDepth}
              merchcat={merchcat}
              isValidGroup={this.props.isValidGroup}
              mode={this.state.groupMode}
              loader={this.props.loader}
              groupDetails={this.props.groupDetails}
              constraintDD={this.state.constraintDD}
              getPlanId={this.getPlanId}
              actionSetSelecteArticle={this.props.actionSetSelecteArticle}
              selectedArticle={this.props.selectedArticle}
            />}
          </PageWrapper>
        </PageLoader>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    constraintsList: state.constraintsData.constraintsList,
    loader: state.constraintsData.loader,
    userInfo: state.userManagementData.userInfo,
    planNumber: state.constraintsData.planNumber,
    vendorList: state.constraintsData.vendorList,
    articleList: state.constraintsData.articleList,
    groupArticleData: state.constraintsData.groupArticleData,
    groupDetails: state.constraintsData.groupDetails,
    selectedArticle: state.constraintsData.selectedArticle
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actionGetConstraintsList: (val) => dispatch(actionGetConstraintsList(val)),
    actionResetConstraintsList: (value) => dispatch(actionResetConstraintsList(value)),
    actionDeleteConstraint: (val) => dispatch(actionDeleteConstraint(val)),
    actionSaveConstraint: (val) => dispatch(actionSaveConstraint(val)),
    actionDownloadConstraints: value => dispatch(actionDownloadConstraints(value)),
    actionGetVendorList: (val) => dispatch(actionGetVendorList(val)),
    actionGetArticleList: (val) => dispatch(actionGetArticleList(val)),
    actionfetchGroupDiscountDepth: (val) => dispatch(actionfetchGroupDiscountDepth(val)),
    actionCreateGroup:  (val) => dispatch(actionCreateGroup(val)),
    actionResetGroupData: (val) => dispatch(actionResetGroupData(val)),
    isValidGroup: (val) => dispatch(isValidGroup(val)),
    actionFechGroupData: (val) => dispatch(actionFechGroupData(val)),
    actionDeleteGroup: (val) => dispatch(actionDeleteGroup(val)),
    actionSetSelecteArticle: (val) => dispatch(actionSetSelecteArticle(val))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Constraints);
