import React, { useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import EditOrDelete from "./editOrDeleteRender";
import { convertMillionsFormatter, precisionFormatter } from "../../../utils/AgGridFormatters";
import isUndefined from "lodash/isUndefined";
import RejectModal from './finalizePromoRejectModal';
import { ValueFormatterService } from "ag-grid-community";

const FinalizePromoTable = props => {
  const {
    promoData,
    handleFinalizePromoEdit,
    handleFinalizePromoDelete,
    onSelectionChanged,
    userInfo,
    finalizeRows
  } = props;
  const [rowData, setRowData] = useState([]);
  const [header, setHeader] = useState([]);
  const [gridApi, setGridApi] = useState({});
  const [modalVisible, setModalVisible]= useState(false);
  const [rejectedPlanData, setRejectedPlanData]= useState([]);

  useEffect(() => {
    const temp = [...promoData];
    temp.forEach(data => {
      const salesData = convertMillionsFormatter(data.sales);
      const marginData = convertMillionsFormatter(data.margin);
      data.margin = (marginData==="NaN")?'':marginData;
      data.sales = salesData;
      data.units = convertMillionsFormatter(data.units);
      data.incrementalMargin = convertMillionsFormatter(data.incrementalMargin);
      data.incrementalSales = convertMillionsFormatter(data.incrementalSales);
      data.incrementalUnits = convertMillionsFormatter(data.incrementalUnits);
      data.spend = convertMillionsFormatter(data.spend);
      data.roi = (typeof(data.roi)==="number")?precisionFormatter({
        value: data.roi
      }): isUndefined(data.roi)?0:data.roi;
    });
    setRowData(temp);
  }, promoData);

  const handleFinalizePromoReject = (data) =>{
    setRejectedPlanData(data);
    openModal();
  }

  const openModal = () =>{
      setModalVisible(true)
  }

  const closeModal = () =>{
    setModalVisible(false)
  }

  useEffect(() => {
    const header = [
      // {
      //   headerName: "",
      //   field: "Select",
      //   headerCheckboxSelection: true,
      //   headerCheckboxSelectionFilteredOnly: true,
      //   checkboxSelection: true,
      //   filter: false,
      //   resizable: false,
      //   pinned: "left",
      //   width: 30
      // },
      {
        headerName: "IA - Promo ID",
        field: "iaPromoId",
        pinned: "left",
        width: 150,
        filter: true
      },
      {
        headerName: "Name",
        field: "name",
        pinned: "left",
        width: 150,
        filter: true
      },
      {
        headerName: "Offer Type",
        field: "offerType",
        filter: true
      },
      {
        headerName: "Cycle",
        field: "cycle",
        filter: true
      },
      {
        headerName: "Source",
        field: "source",
        filter: true
      },
      {
        headerName: "AH4",
        field: "ah4"
      },
      {
        headerName: "Incremental Units",
        field: "incrementalUnits",
        sortable: true
      },
      {
        headerName: "Units",
        field: "units",
        sortable: true
      },
      {
        headerName: "Spend $",
        field: "spend",
        sortable: true
      },
      {
        headerName: "ROI (Sales)",
        field: "roi",
        sortable: true
      },
      {
        headerName: "Incremental Sales $",
        field: "incrementalSales",
        sortable: true
      },
      {
        headerName: "Sales $",
        field: "sales",
        sortable: true
      },
      {
        headerName: "Margin $",
        field: "margin",
        sortable: true
      },
      {
        headerName: "Status",
        field: "status",
        filter: true
      },
      {
        headerName: "Edit / Delete ",
        field: "edit/delete",
        cellRenderer: "editOrDelete",
        cellRendererParams: {
          handleEdit: handleFinalizePromoEdit,
          handleDelete: handleFinalizePromoDelete,
          userInfo: userInfo,
          handleStatus: finalizeRows,
          handleReject: handleFinalizePromoReject
        }
      },
      {
        headerName: "Comment",
        field: "comment",
        valueFormatter : (params)=>{
            return params.value== null? "-":params.value
        },
        tooltipField:'comment'
      }
    ];
    setHeader(header);
  }, [userInfo, handleFinalizePromoEdit, handleFinalizePromoDelete],finalizeRows)

  useEffect(() => {
    if(Object.keys(gridApi).length > 0){
      if(rowData.length > 20){
        gridApi.setDomLayout("normal");
        document.querySelector("#defaultGrid").style.height = "700px";
      }else{
        gridApi.setDomLayout("autoHeight");
        document.querySelector("#defaultGrid").style.height = '';
      }
    }
  }, [rowData])
  function onGridReady(params) {
    this.gridApi = params.api;
    setGridApi(params.api)
    this.gridColumnApi = params.columnApi;
    // this.gridApi.sizeColumnsToFit();
    this.gridColumnApi.autoSizeAllColumns()
  }

  const defaultColDef = {
    sortable: true,
    resizable: true,
  };

  function isRowSelectable(rowNode) {
    return rowNode.data ? rowNode.data.status !== "APPROVED" && rowNode.data.status !== "DRAFT" && rowNode.data.status !== "EXPIRED" : false;
  }

  return (
    <>
      <RejectModal
          showModal={modalVisible}
          openModal={openModal}
          closeModal={closeModal}
          rejectedPlanData={rejectedPlanData}
          finalizeRows={finalizeRows}
      />
      <div className="pipelineGrid finalizePromoTable">
        <div
          id="defaultGrid"
          className="ag-theme-balham"
          style={{ width: "100%" }}
        >
          <AgGridReact
            columnDefs={header}
            rowData={rowData}
            onGridReady={onGridReady}
            onSelectionChanged={onSelectionChanged}
            rowSelection="multiple"
            floatingFilter={true}
            suppressMenuHide={true}
            defaultColDef={defaultColDef}
            suppressRowClickSelection={true}
            frameworkComponents={{
              editOrDelete: EditOrDelete
            }}
            isRowSelectable={isRowSelectable}
            enableCellTextSelection={true}
            domLayout="autoHeight"
          />
        </div>
      </div>
    </>
  );
};

export default FinalizePromoTable;
