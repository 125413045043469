import React, { useState, useEffect } from "react";
import {Modal} from "react-bootstrap";
// import ModalBody from "react-bootstrap/ModalBody";
// import ModalHeader from "react-bootstrap/ModalHeader";
// import ModalFooter from "react-bootstrap/ModalFooter";
import EditableDropDown from "../common/editableDropDownComponent/editableDropDownCompoent";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import { VALIDATION_REGEX } from "../../common/app-constants";
import { AdminGA   } from '../../common/GAUtils';
const ROLE_MERCHANT = 'PromoSmart_Merchant',
  ROLE_ANALYST = 'PromoSmart_Analyst',
  ROLE_ADMIN = 'PromoSmart_Admin',
  ROLE_GMM =  'PromoSmart_GMM',
  ROLE_DMM = 'PromoSmart_DMM',
  ROLE_PROMO_LEAD = 'PromoSmart_Promo_Lead';

const dropDownSelectedObj = {
        'categoryManager': [],
        'dmm': [],
        'gmm': [],
        'buyer': [],
        'assistant1': [],
        'assistant2': [],
        'assistant3': [],
        'cpi': []
}

const assistantArray = ['assistant1','assistant2','assistant3']

const EditUser = props => {
  const [error, setError] = useState("");
  const [selectedData, setSelectedData] = useState({});
  const [isOpen, setIsOpen] = useState(true);
  const [errorFields, setErrorFields] = useState({categoryManager:"",dmm:"",gmm:"",buyer:"",assistant1:"",assistant2:"",assistant3:"",cpi:""});
  const [defaultSelectedData,setDefaultData] = useState(dropDownSelectedObj);
  const { EMAIL_REGEX } = VALIDATION_REGEX;

  useEffect(() => {
    const catAdmData = props.data;
    const defaultData = {};
    const mappingkeys = Object.keys(dropDownSelectedObj);
    mappingkeys.forEach((key) => {
      catAdmData[key] ?
      defaultData[key]= [{label:catAdmData[`${key}Name`],value:catAdmData[key] } ]:
      defaultData[key]= []
    });
    setDefaultData(defaultData);
    setIsOpen(true);
  },[props.data])
  
  const handleSave = async data => {
    submitGA('Click','Save user details');
    if (errorFields.length > 0) {
      const fields = errorFields.join(",");
      var result = fields.replace( /([A-Z])/g, " $1" );
      var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
      setError(`Entered Invalid Email ID for ${finalResult}`);
    } else {
      setError("");
    }
    const reqObj = {};
    if(!isEmpty(data)){
      Object.keys(data).forEach(key => {
        reqObj[key] = data[key];
      });
      reqObj.category = props.data.category;
      setIsOpen(false);
      await props.actionSaveCategoryAdmin(reqObj);
      await setSelectedData({});
      await props.UserRoles();
    }
    setIsOpen(false);
  };


  const handleSelectionChange = args => {
    submitGA('Click','Confirm custom user email id');
    const { key, value } = args;
    const temp = selectedData;
    const error = errorFields;
    var result = key.replace(/([A-Z]+)/g, " $1").replace(/([A-Z][a-z])/g, " $1").replace(/([0-9])/g, " $1")
    var finalResult = result.charAt(0).toUpperCase() + result.slice(1);
    let isValueSame = false;
    if (key.includes('assistant')) {
      const remAssistants = assistantArray.filter((assistant) => {
        return assistant !== key;
      });
     
      remAssistants.forEach((assistant) => {
        if (defaultSelectedData[assistant][0] === value || selectedData[assistant] === value) {
          isValueSame = true;
        }
      });
    }
    if(typeof value === "string"){ 
      if (!EMAIL_REGEX.test(value)) {
        if (key === 'gmm' || key === 'dmm' || key === 'cpi') {
          const gmmError = key.toUpperCase();
          const errorText = `Entered Invalid Email ID for ${gmmError}`;
          error[key] = errorText;
          setError(errorText);
        } else {
          const errorText = `Entered Invalid Email ID for ${finalResult}`;
          error[key] = errorText;
          setError(errorText);
        }
      } else {
       
          
          if(isValueSame){
            const errorText = 'Two Assistants can not have same value';
            error[key] = errorText;
            setError(errorText)
          }else {
            error[key] = '';
            setError('');
          }
        }
      temp[key] = value; 
    } else {
      
        if(isValueSame){
          const errorText = 'Two Assistants can not have same value';
          error[key] = errorText;
          setError(errorText)
        }else {
          error[key] = '';
          setError('');
        }
      temp[key] = value.length ? value[0].value : '';
      // setError('');
    }
    setSelectedData(temp);
    setErrorFields(error);
  };

  const handleInputClose = (key) => {
    submitGA('Click','cancel custom user email id');
    const temp = selectedData;
    delete temp[key];
    setSelectedData(temp);
    setError('');
  }

  const handleModalClose = () => {
    submitGA('Click','Close edit user details popup');
    setError('');
    setIsOpen(false);
    setSelectedData({});
  }

  const clearSelected = (type) => {
    setSelectedData({ ...selectedData, [type]: "" });
    setDefaultData({ ...defaultSelectedData, [type]: [] });
  };
  const submitGA =(action,label)=>{
    AdminGA({ action, label});
  }
  const openAddNewOption = () => {
    submitGA('Click','Add custom user email ');
  }
  return (
    <div>
      <Modal show={!isEmpty(props.data) && isOpen} onHide={handleModalClose} size="lg" >
      <Modal.Header closeButton>
          <Modal.Title>Category: {props.data.category}</Modal.Title>
        </Modal.Header>
        {/* <ModalHeader>
          <p className="col-md-9">Category: {props.data.category}</p>
          <span className="col-md-3 text-right">
          <i class="fa fa-times" aria-hidden="true" onClick={handleModalClose}></i>
          </span>
          <hr />
        </ModalHeader> */}
        <Modal.Body>
          <EditableDropDown
            placeholder="Select"
            options={props.options.filter(i => (i.role == ROLE_GMM))}
            type="select"
            mappingKey="gmm"
            dropDownLabel="GMM"
            inputLabel="GMM"
            handleSelectionChange={handleSelectionChange}
            inputPlaceholder="Enter Email"
            inputDisabled={!isUndefined(errorFields.gmm) && errorFields.gmm !== '' && error === ''}
            selectedData={defaultSelectedData.gmm}
            handleInputClose={handleInputClose}
            onClear={clearSelected}
            openAddNewOption={openAddNewOption}
            editUser={true}
            // isInputDisabled={!isUndefined(selectedData.gmm) && errorFields.gmm.length===0}
          />
          <EditableDropDown
            placeholder="Select"
            options={props.options.filter(i => (i.role == ROLE_DMM))}
            type="select"
            mappingKey="dmm"
            dropDownLabel="DMM"
            inputLabel="DMM"
            handleSelectionChange={handleSelectionChange}
            inputPlaceholder="Enter Email"
            inputType={EMAIL_REGEX}
            selectedData={defaultSelectedData.dmm}
            onClear={clearSelected}
            handleInputClose={handleInputClose}
            openAddNewOption={openAddNewOption}
            editUser={true}
            // isInputDisabled={!isUndefined(selectedData.dmm) && errorFields.dmm.length===0}
          />
          <EditableDropDown
            placeholder="Select"
            options={props.options.filter(i => (i.role == ROLE_DMM))}
            type="select"
            mappingKey="categoryManager"
            dropDownLabel="Category Manager"
            inputLabel="Category Manager"
            handleSelectionChange={handleSelectionChange}
            inputPlaceholder="Enter Email"
            inputType={EMAIL_REGEX}
            selectedData={defaultSelectedData.categoryManager}
            onClear={clearSelected}
            handleInputClose={handleInputClose}
            openAddNewOption={openAddNewOption}
            editUser={true}
            // isInputDisabled={!isUndefined(selectedData.categoryManager) && errorFields.categoryManager.length===0}
          />
          <EditableDropDown
            placeholder="Select"
            options={props.options.filter(i => (i.role == ROLE_MERCHANT))}
            type="select"
            mappingKey="buyer"
            dropDownLabel="Buyer"
            inputLabel="Buyer"
            handleSelectionChange={handleSelectionChange}
            inputPlaceholder="Enter Email"
            inputType={EMAIL_REGEX}
            selectedData={defaultSelectedData.buyer}
            onClear={clearSelected}
            handleInputClose={handleInputClose}
            openAddNewOption={openAddNewOption}
            editUser={true}
            // isInputDisabled={!isUndefined(selectedData.buyer) && errorFields.buyer.length>0}
          />
          <EditableDropDown
            placeholder="Select"
            options={props.options.filter(i => (i.role == ROLE_MERCHANT))}
            type="select"
            mappingKey="cpi"
            dropDownLabel="CPI"
            inputLabel="CPI"
            handleSelectionChange={handleSelectionChange}
            inputPlaceholder="Enter Email"
            inputType={EMAIL_REGEX}
            selectedData={defaultSelectedData.cpi}
            onClear={clearSelected}
            handleInputClose={handleInputClose}
            openAddNewOption={openAddNewOption}
            editUser={true}
            // isInputDisabled={!isUndefined(selectedData.buyer) && errorFields.buyer.length>0}
          />
          <EditableDropDown
            placeholder="Select"
            options={props.options.filter(i => (i.role == ROLE_MERCHANT))}
            type="select"
            mappingKey="assistant1"
            dropDownLabel="Assistant 01"
            inputLabel="Assistant 01"
            handleSelectionChange={handleSelectionChange}
            inputPlaceholder="Enter Email"
            inputType={EMAIL_REGEX}
            selectedData={defaultSelectedData.assistant1}
            onClear={clearSelected}
            handleInputClose={handleInputClose}
            openAddNewOption={openAddNewOption}
            editUser={true}
            // isInputDisabled={!isUndefined(selectedData.assistant1) && errorFields.assistant1.length===0}
          />
          <EditableDropDown
            placeholder="Select"
            options={props.options.filter(i => (i.role == ROLE_MERCHANT))}
            type="select"
            mappingKey="assistant2"
            dropDownLabel="Assistant 02"
            inputLabel="Assistant 02"
            handleSelectionChange={handleSelectionChange}
            inputPlaceholder="Enter Email"
            inputType={EMAIL_REGEX}
            selectedData={defaultSelectedData.assistant2}
            onClear={clearSelected}
            handleInputClose={handleInputClose}
            openAddNewOption={openAddNewOption}
            editUser={true}
            // isInputDisabled={!isUndefined(selectedData.assistant2) && errorFields.assistant2.length===0}
          />
          <EditableDropDown
            placeholder="Select"
            options={props.options.filter(i => (i.role == ROLE_MERCHANT))}
            type="select"
            mappingKey="assistant3"
            dropDownLabel="Assistant 03"
            inputLabel="Assistant 03"
            handleSelectionChange={handleSelectionChange}
            inputPlaceholder="Enter Email"
            inputType={EMAIL_REGEX}
            selectedData={defaultSelectedData.assistant3}
            onClear={clearSelected}
            handleInputClose={handleInputClose}
            openAddNewOption={openAddNewOption}
            editUser={true}
            // isInputDisabled={errorFields.assistant3.length===0}
          />
          {error.length > 0 && <p className="error-message" style={{textTransform: "capitalize"}}>{error}</p>}
        </Modal.Body>
        <Modal.Footer>
          <button
            onClick={() => handleSave(selectedData)}
            className="btn btn-primary saveButton"
            disabled={error.length>0}
          >
            Save
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};


export default EditUser;
