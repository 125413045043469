import React, { Fragment, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import EditUser from "./editUsers";
import isEmpty from "lodash/isEmpty";
import { AdminGA   } from '../../common/GAUtils';
const ActionRender = props => {
  return (
    <span
      style={{
        fontSize: "1.3rem",
        cursor: "pointer"
      }}
      onClick={() => props.agGridReact.props.handleTableOpClick(props.data)}
    >
      <i class="fa fa-pencil" aria-hidden="true" />
    </span>
  );
};
const columnDefs = [
  {
    headerName: "Category",
    field: "category",
    sortable: true,
    filter: true,
    pinned: "left"
  },
  {
    headerName: "GMM",
    field: "gmm",
    sortable: true,
    filter: true
  },
  {
    headerName: "DMM",
    field: "dmm",
    sortable: true,
    filter: true
  },
  {
    headerName: "Category Manager",
    field: "categoryManager",
    sortable: true,
    filter: true
  },
  {
    headerName: "Buyer",
    field: "buyerName",
    sortable: true,
    filter: true
  },
  {
    headerName: "CPI",
    field: "cpi",
    sortable: true,
    filter: true
  },
  {
    headerName: "Assistant 1",
    field: "assistant1",
    sortable: true,
    filter: true
  },
  {
    headerName: "Assistant 2",
    field: "assistant2",
    sortable: true,
    filter: true
  },

  {
    headerName: "Assistant 3",
    field: "assistant3",
    sortable: true,
    filter: true,
  },

  {
    headerName: "Action",
    field: "id",
    width: 100,
    cellRendererFramework: ActionRender
  }
];

const UsersList = props => {
  const [colDefs, setColDefs] = useState(columnDefs);
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState({});

  useEffect(() => {
    setRowData(props.rowData);
    if(Object.keys(gridApi).length > 0){
      if(props.rowData.length > 25){
        gridApi.setDomLayout("normal");
        document.querySelector("#defaultGrid").style.height = "750px";
      }else{
        gridApi.setDomLayout("autoHeight");
        document.querySelector("#defaultGrid").style.height = '';
      }
    }
  }, [props.rowData]);
  
  const onGridReady = params => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };
  const handleTableOpClick = params => {
    submitGA("Click","Edit the user details")
    props.setUserDetails({...params});
  };
  const submitGA =(action,label)=>{
    AdminGA({ action, label});
  }
  return (
    <>
      <div className="spacer"></div>
      <div
        id="defaultGrid"
        className="ag-theme-balham"
        style={{ width: "100%" }}
      >
        <AgGridReact
        floatingFilter={true}
          defaultColDef={{
            resizable: true
          }}
          columnDefs={colDefs}
          rowData={rowData}
          onGridReady={onGridReady}
          handleTableOpClick={handleTableOpClick}
          domLayout='autoHeight'
          enableCellTextSelection={true}
        />
      </div>
      <div>
        {!isEmpty(props.userData) && !isEmpty(props.allUsers) && (
          <EditUser
            data={props.userData}
            options={props.allUsers}
            actionSaveCategoryAdmin={props.actionSaveCategoryAdmin}
            UserRoles={props.UserRoles}
          />
        )}
      </div>
    </>
  );
};

export default UsersList;
