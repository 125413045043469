const numberFormatter = (value) => {
  if(value.value =="-")
    return "-";
  if(value.value =="Loading...")
    return value.value;
  const data = new Intl.NumberFormat('en-us', { currency: 'USD' }).format(value.value || 0);
  return parseFloat(data).toFixed(2);
};
const convertedBooleanValue = (value) => {
  if(value.value == "Loading...")
    return value.value;
  if (value.value && value.value != '' && value.value !="-") {
    return value.value === true || value.value === 'true' ? 'Yes' : 'No';
  } else {
    if (value.value == undefined || value.value == '' || value.value == "-") {
      return '-'
    } else {
      return 'No'
    }
  }
};
const removePrecisionFormatterPlanSummary = (value) => {
  return convertMillionsFormatterPlanSummary(value);
  // return new Intl
  //     .NumberFormat('en-us', {currency: 'USD'})
  //     .format((parseInt((value.value || 0))) || 0)
};
const removePrecisionFormatter = (value) => {
  return convertMillionsFormatter(value);
  // return new Intl
  //     .NumberFormat('en-us', {currency: 'USD'})
  //     .format((parseInt((value.value || 0))) || 0)
};
const precisionFormatter = (value) => {
  if(value.value == "Loading...")
      return value.value;
  if (value != undefined && value.value != undefined && value.value!="-" && value.value!="Loading...") {
    value= value.value;
    return parseFloat(value).toFixed(2);
  } else {
    return '-';
  }
};

const formatToTwoDecimals = (value) => {
  if (value && isFinite(value)) {
    return parseFloat(value).toFixed(2);
  } else {
    return 0;
  }
};

const percentageFormatter = (value) => {
  if (value != undefined && value.value != undefined) {
    let data = (value.value * 100).toFixed(2);
    return data;
  } else {
    return '-';
  }
};

const emptyValueFormatter = (value) => {
  if ((this.props.page && this.props.page === 2) || this.props.page === 3) {
    if (value.value) {
      var tempVal = value.value;
      tempVal = tempVal.toString().replace('%', '');
      tempVal = tempVal.toString().replace(',', '');
      tempVal = tempVal.toString().replace('$', '');

      var toSting = Math.round(tempVal);
      if (parseInt(tempVal) > 0) {
        return toSting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
      } else {
        var splitAvalue = Math.abs(toSting);
        return (
          '-' + splitAvalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ''
          // "(" + splitAvalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ")"
        );
      }
    } else {
      if (value.value === 0) {
        return 0;
      } else {
        return '-';
      }
    }
  }
  if (value.value) {
    var tempVal = value.value;
    tempVal = tempVal.toString().replace('%', '');
    tempVal = tempVal.toString().replace(',', '');
    tempVal = tempVal.toString().replace('$', '');
    var toSting = Math.round(tempVal);

    if (parseInt(tempVal) > 0) {
      return toSting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      var splitAvalue = Math.abs(toSting);
      return (
        '-' + splitAvalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ''
        // "(" + splitAvalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ")"
      );
    }
  } else {
    if (value.value === 0) {
      return 0;
    } else {
      return '-';
    }
  }
};

const percentageAndPrecisionFormatter = (value) => {
  if(value.value == "Loading...")
      return value.value;
  if (value != undefined && value.value != undefined && value.value!='-') {
    let data = parseInt(value.value * 100);
    return data;
  } else if(value.value == '-'){
    return "-";
  }else {
    return 0;
  }
};

const getValue = (value) => {
  if (value != undefined && value.value != undefined) {
    return value.value;
  }
  return value;
};

const convertMillionsFormatterPlanSummary = (value) => {
  if(value.value=="Loading...")
      return value.value;
  let data = getValue(value);
  if (isFinite(data)) {
    console.log("values:",Math.sign(data)*(Math.abs(Number(data)) / 1.0e3));
     let no  =
      Math.abs(Number(data)) >= 1000
        ? new Intl.NumberFormat('en-us', { currency: 'USD' }).format(
          Math.round(Math.sign(data)*(Math.abs(Number(data)) / 1.0e3))//.toFixed(2) || 0.00
          ) + ' K'
        : new Intl.NumberFormat('en-us', { currency: 'USD' }).format(
          Math.round(Math.sign(data)*(Math.abs(Number(data))))//.toFixed(2) || 0.00
          );
    
    //console.log("numbervalue:",no);
    if (data < 0 && no != 0) {
      no = no;
    }
    else if (no == 0) {
     return 0;
    }
    return no;
  } else {
    
    // return 0.00;
    return '-'; 
  }
};


const convertMillionsFormatter = (value) => {
  if(value.value=="Loading...")
      return value.value;
  const data = getValue(value);
  if (isFinite(data)) {
    // let no = Math.abs(Number(data)) >= 1.0e9
    // // ? (Math.abs(Number(data)) / 1.0e9).toFixed(2) + 'Bn'
    // ? (Math.abs(Number(data)) / 1.0e3).toFixed(2) + ' K'
    // : // Six Zeroes for Millions
    // Math.abs(Number(data)) >= 1.0e6
    //     // ? (Math.abs(Number(data)) / 1.0e6).toFixed(2) + 'Mn'
    //     ? (Math.abs(Number(data)) / 1.0e3).toFixed(2) + ' K'
    //     : // Three Zeroes for Thousands
    //     Math.abs(Number(data)) >= 1.0e3
    //         ? (Math.abs(Number(data)) / 1.0e3).toFixed(2) + ' K'
    //         : Math
    //             .abs(Number(data))
    //             .toFixed(2);

    
     let no  =
      Math.abs(Number(data)) >= 1000
        ? new Intl.NumberFormat('en-us', { currency: 'USD' }).format(
          Math.sign(data)*(Math.abs(Number(data)) / 1.0e3).toFixed(2) || 0.00
          ) + ' K'
        : new Intl.NumberFormat('en-us', { currency: 'USD' }).format(
          Math.sign(data)*Math.abs(Number(data)).toFixed(2) || 0.00
          );
    
    if (data < 0 && no != 0) {
      no = no;
    } else if (no == 0) {
      return 0.00;
    }
    return no;
  } else {
    
    // return 0.00;
    return '-'; 
  }
};

const convertMillionsFormatterWithoutPrecisionPlanSummary = (value) => {
  return convertMillionsFormatterPlanSummary(value);
}

const convertMillionsFormatterWithoutPrecision = (value) => {
  return convertMillionsFormatter(value);
  // if (value != undefined && value.value != undefined) {
  //     let data = value.value;
  //     if (isFinite(data)) {
  //         let no = Math.abs(Number(data)) >= 1.0e9
  //         // ? parseInt((Math.abs(Number(data)) / 1.0e9)) + 'Bn'
  //         ? parseInt((Math.abs(Number(data)) / 1.0e3)) + ' K'
  //         : // Six Zeroes for Millions
  //         Math.abs(Number(data)) >= 1.0e6
  //             // ? parseInt((Math.abs(Number(data)) / 1.0e6)) + 'Mn'
  //             ? parseInt((Math.abs(Number(data)) / 1.0e3)) + ' K'
  //             : // Three Zeroes for Thousands
  //             Math.abs(Number(data)) >= 1.0e3
  //                 ? parseInt((Math.abs(Number(data)) / 1.0e3)) + ' K'
  //                 : parseInt(Math.abs(Number(data)));
  //         if(data < 0){
  //             no = '-'+no;
  //         }
  //         return no
  //     } else {
  //         return 0
  //     }
  // } else {
  //     return 0
  // }
};

const convertToCurrency = (value) => {
  if(value.value =="Loading...")
    return value.value;
  if (getValue(value) >= 0) {
    let convertedValue = convertMillionsFormatter(value);
    return validateCellValue(convertedValue) ? convertedValue : ('$' + convertedValue)
    // return '$' + convertMillionsFormatter(value);
  } else {
    let convertedValue = convertMillionsFormatter(value);
    return validateCellValue(convertedValue) ? convertedValue : ('$' + convertedValue)
    // return '$' + conVal;
  }
};

const converToPercentageFloat = (value) => {
  return formatToTwoDecimals(value) + ' %';
};

const CustomSearch = (_, value, filterText) => {
  if (convertMillionsFormatterWithoutPrecision(value).includes(filterText.toUpperCase())) {
    return value;
  }
};
const validateCellValue = (value)=>{
  return (value === '-' || value == 0 || value=="Loading..."); //? '-':value;
}
export {
  numberFormatter,
  removePrecisionFormatter,
  removePrecisionFormatterPlanSummary,
  precisionFormatter,
  percentageFormatter,
  formatToTwoDecimals,
  emptyValueFormatter,
  convertedBooleanValue,
  percentageAndPrecisionFormatter,
  convertMillionsFormatter,
  convertMillionsFormatterPlanSummary,
  convertMillionsFormatterWithoutPrecision,
  convertMillionsFormatterWithoutPrecisionPlanSummary,
  convertToCurrency,
  converToPercentageFloat,
  CustomSearch,
  validateCellValue
};
