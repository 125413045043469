import styled from "styled-components";
export const BudgetPlanTableTitleContent = styled.div`
  display: flex;
  justify-content: left;
  font-size: 14px !important;
  color: gray;
  padding: 0px 30px;
  align-items: center;
  input {
    width: 200px;
    margin-left: 10px;
  }
`;
