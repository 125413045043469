import React from 'react';
import { Component } from 'react';
import { Modal } from 'react-bootstrap';
import '../index.css';

export default class finalizePromoRejectModal extends Component{
    constructor(props){
        super(props);
        this.state={
            commentValue:''
        }
    }
    submitComment =()=>{
        let requestObj = {id: this.props.rejectedPlanData.id, 
                          type:this.props.rejectedPlanData.type,
                          name:this.props.rejectedPlanData.name , 
                          status:"REJECTED",
                          comment: this.state.commentValue}
        this.props.finalizeRows(requestObj);
        this.setState({
            commentValue:''
        })
        this.props.closeModal();
    }
    handleCancel = ()=>{
        this.setState({
            commentValue:''
        })
        this.props.closeModal();
    }
    handleCommentChange = (event)=>{
        this.setState({
            commentValue: event.target.value
        })
    }
    render() {
        return (
            <div>
                <Modal size="md" show={this.props.showModal} centered>
                    <div className="cross-icon">
                        <span
                            onClick={this.props.closeModal}
                            className="pull-right"
                            style={{ cursor: 'pointer' }}
                        >
                            <i class="fa fa-times" aria-hidden="true" />
                        </span>
                    </div> 
                    <Modal.Body className="modalBody">                        
                        <div className='modalRowTitle'>
                            <label>Reason for Rejection</label>
                        </div>
                        <div class="comment-area"> 
                            <textarea class="comment-box" 
                                onChange={this.handleCommentChange}
                                value={this.state.commentValue}
                                placeholder="Enter here" rows="4" cols="30">
                            </textarea> 
                        </div>
                    </Modal.Body>
                    <Modal.Footer className="modalFooter">
                        <div className="cancelButton mr-3">
                            <button className="btn btn-primary resetButton" 
                                onClick={this.handleCancel}
                            >Cancel</button>
                        </div>
                        <div className="submitButton ml-3">
                            <button className="btn btn-primary"
                                onClick={this.submitComment}
                                disabled={this.state.commentValue.length>1?false:true}
                            >Submit</button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </div>
        )
    }
}