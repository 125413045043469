import React, { Component } from 'react';
import { connect } from 'react-redux';
import LoadingOverlay from 'react-loading-overlay';
import 'react-datepicker/dist/react-datepicker.css';
import * as action from '../../actions/promo';
import {
  updateSelectedMetrics,
  fetchSelectedMetrics
} from '../../actions/user';
import { fetchPromoHierarchyData } from '../../apis/promo.api';
import { AgGridReact } from 'ag-grid-react';
import { PROMO_LEVEL, PROMO_ARTICLE_LEVEL } from '../../constants';
import { Link } from 'react-router-dom';
import CustomModal from '../../components/Modal/CustomModal';
import { Form, Row, Col } from 'react-bootstrap';
import MultiSelectCheckboxes from '../MultiSelectCheckboxes';
import _, { result, sum } from 'lodash';
import {
  METRICS_LIST,
  METRICS_COLUMN_NAMES,
  DEFAULT_COLUMNS,
  COLUMNS_LIST,
  PROMO_ARTICLE_LEVEL_COLUMNS,PROMO_LEVEL_COLUMNS,PINNED_COLUMNS,PROMO_ARTICLE_LEVEL_PINNED_COLUMNS
} from './constants.js';
import { ExportToExcel } from '../DownloadExcel';
import './PlannedPromoPipeline.css';
import axios from 'axios';
import environment from '../../environments/environment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { HistoricalPerformanceGA   } from '../../common/GAUtils';
// create your cellRenderer as a React component
class NameCellRenderer extends React.Component {
  render() {
    // put in render logic
    var className = 'perfm-good';
    if (this.props.value === 'TOXIC' || this.props.value === 'INSIGNIFICANT') {
      className = 'perfm-bad';
    }
    if (this.props.value === 'AVERAGE') {
      className = 'perfm-avg';
    }
    if (this.props.value === 'Underperforming') {
      className = 'perfm-underperforming';
    }
    return <div className={'perfm ' + className}>{this.props.value}</div>;
  }
}
class ModalCellRenderer extends React.Component {
  render() {
    // put in render logic
    return (
      <div>{this.props.data!=undefined && (this.props.data.title=="Loading..." || this.props.data.articleDesc=="Loading") ? "Loading..." 
      : this.props.data!=undefined && this.props.data.title != "Total" && this.props.data.articleDesc !="Total" ? 
      <span
        style={{
          fontSize: '1.3rem',
          cursor: 'pointer',
          padding: '0 50%'
        }}
        onClick={() =>
          this.props.agGridReact.props.handleTableOpClick(this.props.data.id)
        }>
        <i class='fa fa-angle-right' aria-hidden='true' />
      </span>
      : "-"
    }
      </div>
    );
  }
}
class ModalCellRenderer2 extends React.Component {
  render() {
    // put in render logic
    return (
      <div
        style={{
          display: 'flex'
        }}>
        <button
          className='iconBtnPipeline'
          onClick={() =>
            this.props.agGridReact.props.handleTableOpClick(this.props.value)
          }>
          <i class='fa fa-angle-right iconPipeline' aria-hidden='true' />
        </button>
        <Link
          to={{
            pathname: '/vis/create-promo/',
            state: {
              id: this.props.value
            }
          }}>
          {/* <div style={{    fontSize: "1.3rem",cursor:"pointer",color:'#44667a'}} onClick={() => this.props.agGridReact.props.handleTableCreateClick(this.props.value)}> */}
          <div
            style={{
              fontSize: '1.3rem',
              cursor: 'pointer',
              color: '#44667a'
            }}>
            <i class='fa fa-plus-circle' aria-hidden='true' />
          </div>
        </Link>
      </div>
    );
  }
}
export class PromotionPipeline extends Component {
  isSelectAllSelected = false;
  constructor(props) {
    super(props);
    let metricOptions = [...COLUMNS_LIST].filter(object => {
      object.label = object.headerName;
      object.value = object.field;
      return object;
    });
    metricOptions.splice(0, 3);
    metricOptions.splice(metricOptions.map(obj=>obj.label).indexOf('New Members Article %'), 1);
    let columnDefs = [...COLUMNS_LIST];
    columnDefs.push({
      headerName: 'Performance',
      field: 'actualPerformance',
      cellStyle: function (params) {     
        const perfomance = {
          "Good":{
          background: "#e8f8f5",
          color: "#34bfa3"
        },
        "TOXIC": {
          background: "#fdeff0",
          color: "#FF727B"
        },
        "INSIGNIFICANT": {
          background: "#fdeff0",
          color: "#FF727B"
        },
        "AVERAGE": {
          background: "#fdf8e6",
          color: "#F0C419"
        },
        "Underperforming": {
          background: "#fdd5d6",
          color: "#f3101c",
          "padding-left":"4px"
        }
      }
      return perfomance[`${params.value}`]
      },
      headerTooltip: 'Relative performance when compared to LY promo performance of the vendor in the category',
      width: 280,
      pinned: 'right',
      filter: true,
      sortable: true
    });

    columnDefs.push({
      headerName: 'Detail',
      width: 120,
      field: 'id',
      cellRendererFramework: ModalCellRenderer,
      pinned: 'right',
      filter: false,
      sortable: false
    });

    this.selrow = [];
    this.state = {
      promosList: [],
      modalVisible: false,
      rowSelection: 'multiple',
      selectedOption: {
        baselineMargin: 0,
        incrementalMargin: 0,
        affinityMargin: 0,
        hierarchies: [],
        cannibalisationMargin: 0,
        totalMargin: 0
      },
      pinnedRowData:[],
      selectedDepartment: null,
      selectedCategory: null,
      selectedSKU: null,
      selectedSubCategory: null,
      loader: false,
      merchcat: null,
      vendor: null,
      month: null,
      ah6: null,
      quarter: null,
      selectedTab: 'Promo Level',
      metrics: [
        {
          name: 'Summary',
          isChecked: true
        },
        {
          name: 'Information Metrics',
          isChecked: false
        },
        {
          name: 'Performance Metrics',
          isChecked: false
        },
        {
          name: 'Member Metrics',
          isChecked: false
        },
        {
          name: 'Basket Metrics',
          isChecked: false
        }
      ],
      columnDefs: columnDefs,
      metricsOptions: metricOptions,
      articleData:[],
      getRowStyle: function (params) {
        if (params.node.rowPinned) {
          return { 'font-weight': 'bold' };
        }
      }
      //pinnedTopRowData: this.createData()
    };
  }

  setColumnsVisible = (columns, hide) => {
    const promoArticleColumn = ['articleNo', 'articleDesc'];
    if (hide) {
      if (this.props.selectedTab == PROMO_LEVEL) {
        this.gridColumnApi.setColumnsVisible(
          columns.filter(o => !promoArticleColumn.includes(o)),
          hide
        );
      } else {
        this.gridColumnApi.setColumnsVisible(
          [...columns, ...promoArticleColumn],
          hide
        );
      }
    } else {
      if (this.props.selectedTab == PROMO_LEVEL) {
        this.gridColumnApi.setColumnsVisible(
          [...columns, ...promoArticleColumn],
          hide
        );
      } else {
        this.gridColumnApi.setColumnsVisible(
          columns.filter(o => !promoArticleColumn.includes(o)),
          hide
        );
      }
    }
  };

  handleTableOpClick = val => {
    this.submitGA("Click","Get waterfall chart details")
    var selectedOptionArr = this.props.tableData.filter(
    item=>item.id==val
    );
    if (selectedOptionArr.length > 0) {
      selectedOptionArr[0].hierarchies = [];
      this.setState(
        {
          selectedOption: selectedOptionArr[0]
        },
        () => {
          if(this.props.selectedTab === PROMO_LEVEL){
            this.getArticleData();
          }
        }
      );
    }
    this.openModal();
  };

  openModal = () => {
    this.setState({ modalVisible: true, articleData: [] });
  };

  closeModal = () => {
    this.setState({ modalVisible: false });
  };

  componentDidMount() {
    this.props.fetchSelectedMetrics({selectedTab: this.props.selectedTab});
  }

  findSum(field,data){
    var sum=0;
    for(let i=0;i<data.length;i++){
      sum+=data[i][field];
    }
    return sum;
  }

  findAvg(field,data){
    var avg = this.findSum(field,data)/data.length;
    return avg;
  }

  findTotal(spend,insertionFee,data){
    var sum=0;
    var total=0;
    for(let i=0;i<data.length;i++){
      if(data[i][insertionFee]==undefined && data[i][spend]==undefined)
          return 0;
      total= (data[i][insertionFee]!=undefined && data[i][spend]!=undefined) ? (data[i][spend]+data[i][insertionFee]):(data[i][insertionFee]==undefined? data[i][spend]
                :data[i][insertionFee]);
      sum+=total;
    }
    return sum;
  }

  getPinnedRowData = (data) => {
    var columnsArray = this.state.columnDefs;
    var pinnedRow={};
    if(data.length==500 && !this.props.promoFlag){
      columnsArray.forEach(column =>{
        pinnedRow[column.field]="Loading...";
      })
      return pinnedRow;
    }
    else{  
    var promoArticleLevel = ['Article Name', 'Article Number'];
    var unitsArray = [];
    var result = {};
    var spendAndInsertionFee = this.findTotal('spend', 'insertionFee',data);
    for (let i = 0; i < columnsArray.length; i++) {
      if (columnsArray[i].aggrField == 'sum') {
        var field = columnsArray[i].field;
        var sum = this.findSum(field,data);
        unitsArray[columnsArray[i].headerName] = sum;
        result[columnsArray[i].field] = sum;
      }
      else if (columnsArray[i].aggrField == 'avg') {
        var field = columnsArray[i].field;
        var avg = this.findAvg(field,data);
        result[columnsArray[i].field] = avg;
      }
      else if (columnsArray[i].headerName == 'Unit Lift') {
        var totalUnits = unitsArray['Total Units'];
        var baselineUnits = this.findSum('baselineUnits',data);
        if(baselineUnits)
          result[columnsArray[i].field] = totalUnits / baselineUnits;
      }
      else if (columnsArray[i].headerName == 'ROI (Sales)') {
        var incrementalSales = unitsArray['Incremental Sales $'];
        if(spendAndInsertionFee)
          result[columnsArray[i].field] = incrementalSales / spendAndInsertionFee;
      }
      else if (columnsArray[i].headerName == 'ROI (Margin)') {
        var incrementalMargin = unitsArray['Incremental Margin $'];
        if(spendAndInsertionFee)
          result[columnsArray[i].field] = incrementalMargin / spendAndInsertionFee;
      }
      else {
        if (this.props.selectedTab == "PROMO_ARTICLE_LEVEL") {
          if(columnsArray[i].headerName=='Article Name')
            result[columnsArray[i].field]="Total";
          else if (columnsArray[i].headerName != 'Offer Name')
            result[columnsArray[i].field] = "-";
        }
        else {
          if(columnsArray[i].headerName=='Offer Name')
            result[columnsArray[i].field]="Total";
          else if (!promoArticleLevel.includes(columnsArray[i].headerName)) {
            result[columnsArray[i].field] = "-";
          }
        }
      }
    }
    return result;
  }
}
  createRowData = (tableData) => {
    if (tableData.length > 0) {
      var result = {};
      result = this.getPinnedRowData(tableData);
      var pinnedData = [];
      pinnedData.push(result);
      this.setState({pinnedRowData: pinnedData});
    }
  };
  promotionPipelineDataHandler = params =>{
      this.gridApi = params.api;
      var tableData=[];
      this.gridApi.forEachNodeAfterFilter((node, index)=>{
        tableData.push(node.data);
      });
      this.createRowData(tableData);
  }
  onGridReady = params => {
    // console.lo
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;

    params.api.sizeColumnsToFit();
    window.onresize = () => {
      this.gridApi.sizeColumnsToFit();
    };
    // this.gridColumnApi.autoSizeAllColumns();
  };
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes();
    this.selrow = rowCount;
    // for(var i=0;i<rowCount.length;i++){   console.log('selrow',rowCount[i]); }
    // window.alert("selection changed, " + rowCount + " rows selected");
  }

  handleMetrics = event => {
    this.submitGA('Click','Changed Metrics');
    let metrics = [...this.state.metrics];
    if (event.target.name === 'Summary') {
      metrics.forEach(metric => {
        if (metric.name === event.target.name) {
          metric.isChecked = event.target.checked;
        } else {
          if (event.target.checked) metric.isChecked = false;
        }
      });
    } else {
      metrics.forEach(metric => {
        if (metric.name === 'Summary') {
          metric.isChecked = false;
        }
        if (metric.name === event.target.name) {
          metric.isChecked = event.target.checked;
        }
      });
    }
    let visibleColumns = [],
      hideColumns = [],
      summaryColumns = [];
    metrics
      .filter(object => object.isChecked === true)
      .forEach(object => {
        if (object.name === 'Summary') {
          let defaultColumns = DEFAULT_COLUMNS.map(object => object.label);
          summaryColumns = this.state.columnDefs.filter(object => {
            return defaultColumns.indexOf(object.headerName) !== -1;
          });
          visibleColumns.push(summaryColumns);
        } else {
          let metrics = [...METRICS_COLUMN_NAMES[`${object.name}`]];
          if(object.name === 'Member Metrics'){
            if(this.props.selectedTab === PROMO_LEVEL){
              metrics.splice(1, 1);
            }else{
              metrics.splice(2, 1);
            }
          }
          visibleColumns.push(metrics);
        }
      });
    if (this.props.selectedTab === PROMO_ARTICLE_LEVEL) {
      const promoArticleLevelColumns = _.flatten(visibleColumns).filter(
        object =>
          ['Article Name', 'Article Number'].indexOf(object.label) !==
          -1
      );
      if (promoArticleLevelColumns.length === 0) {
        visibleColumns.unshift(
          {
            headerName: 'Article Number',
            field: 'articleNo'
          },
          {
            headerName: 'Article Name',
            field: 'articleDesc'
          },
          // {
          //   headerName: 'Brand',
          //   field: 'brand'
          // }
        );
      }
    } else {
      visibleColumns = _.flatten(visibleColumns).filter(
        object =>
          ['Article Name', 'Article Number'].indexOf(
            object.headerName
          ) === -1
      );
    }
    visibleColumns = _.flatten(visibleColumns)
      .map(object => object.field)
      .concat(['title', 'actualPerformance', 'id']);
    this.setColumnsVisible(visibleColumns, true);
    metrics
      .filter(object => object.isChecked === false)
      .forEach(object => {
        if (object.name === 'Summary') {
          hideColumns.push(DEFAULT_COLUMNS);
        } else {
          let columns = [...METRICS_COLUMN_NAMES[`${object.name}`]];
          if (object.name === 'Information Metrics') {
            columns.splice(0, 1);
          }
          if (object.name === 'Member Metrics') {
          }
          hideColumns.push(columns);
        }
      });
    summaryColumns = summaryColumns.map(object => object.field);
    hideColumns = _.flatten(hideColumns).map(object => object.field);
    hideColumns = hideColumns.filter(object => {
      return summaryColumns.indexOf(object) === -1;
    });
    if (this.props.selectedTab === PROMO_ARTICLE_LEVEL) {
      hideColumns = _.flatten(hideColumns).filter(
        object =>
          ['articleNo', 'articleDesc', 'promoId'].indexOf(object) ===
          -1
      );
    } else {
      hideColumns = _.flatten(hideColumns).filter(
        object => ['title', 'promoId'].indexOf(object) === -1
      );
    }
    this.setColumnsVisible(hideColumns, false);


    this.setState({
      metrics: [...metrics]
    });
  };

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.selectedTab !== this.props.selectedTab) {
      this.props.fetchSelectedMetrics({selectedTab: this.props.selectedTab})
      if (this.props.selectedTab === PROMO_ARTICLE_LEVEL) {
        const promoArticleLevelColumns = this.props.selectedColumns.filter(
          object =>
            ['Article Name', 'Article Number'].indexOf(
              object.label
            ) !== -1
        );
        if (promoArticleLevelColumns.length === 0) {
          this.props.selectedColumns.unshift(
            {
              label: 'Article Number',
              value: 'articleNo'
            },
            {
              label: 'Article Name',
              value: 'articleDesc'
            },
            // {
            //   label: 'Brand',
            //   value: 'brand'
            // }
          );
        }
        let metricOptions = [...COLUMNS_LIST].filter(object => {
          object.label = object.headerName;
          object.value = object.field;
          return object;
        });

        metricOptions.splice(0, 1);
        metricOptions.splice(metricOptions.map(obj=>obj.label).indexOf('New Members Offers %'), 1);
        let defaultColumns = DEFAULT_COLUMNS.map(object => object.value);
        let selectedColumns = this.props.selectedColumns
          .filter(object => defaultColumns.indexOf(object.value) !== -1)
          .map(object => object.value);
          if(this.state.metrics.filter(obj=> obj.name === "Member Metrics" && obj.isChecked ).length > 0){
            selectedColumns.push('percOfNewCustArticle')
          }
        this.setColumnsVisible(
          selectedColumns.concat([
            'title',
            'actualPerformance',
            'id',
            'articleDesc',
            'articleNo'
          ]),
          true
        );
        this.setColumnsVisible(
         [
            'percOfNewCust'
          ],
          false
        );
        this.gridColumnApi.setColumnsPinned(['title', 'articleNo'], false);
        let columnDefs = [...this.state.columnDefs].filter(object => {
          if (object.headerName === 'Offer Name') {
            delete object.pinned;
          }
          if (object.headerName === 'Article Name') {
            object.pinned = 'left';
          }
          return object;
        });
        this.setState({
          metricsOptions: metricOptions,
          columnDefs: columnDefs
        });
      } else {
        const promoArticleLevelColumns = this.props.selectedColumns.filter(
          object =>
            ['Article Name', 'Article Number','New Members Article %'].indexOf(object.label) >=
            0
        );
        if(this.state.metrics.filter(obj=> obj.name === "Member Metrics" && obj.isChecked ).length > 0){
          this.setColumnsVisible(
            ['percOfNewCust'],true
          )
          this.setColumnsVisible(
            ['percOfNewCustArticle'],false
          )
        }
        if (promoArticleLevelColumns.length > 0) {
          this.setColumnsVisible(
            promoArticleLevelColumns
              .map(object => object.value)
              .filter(o => ['articleNo', 'articleDesc'].indexOf(o) < 0),
            false
          );

          let metricOptions = [...COLUMNS_LIST].filter(object => {
            object.label = object.headerName;
            object.value = object.field;
            return object;
          });
          metricOptions.splice(0, 3);
          metricOptions.splice(metricOptions.map(obj=>obj.label).indexOf('New Members Article %'), 1);
          let columnDefs = [...this.state.columnDefs].filter(object => {
            if (object.headerName === 'Article Name') {
              delete object.pinned;
            }
            if (object.headerName === 'Offer Name') {
              object.pinned = 'left';
            }
            return object;
          });
          this.gridColumnApi.setColumnsPinned(['title'], true);
          this.setState({
            metricsOptions: metricOptions,
            columnDefs: columnDefs,
            selectedColumns: this.props.selectedColumns.filter(
              (object, index) =>
                ['Article Name', 'Article Number'].indexOf(
                  object.label
                ) === -1
            )
          });
        }
      }
    }
    if(oldProps.tableData !== this.props.tableData){
      var data = this.props.tableData;
      this.createRowData(data);
      if(this.props.tableData.length > 25){
        this.gridApi.setDomLayout("normal");
        document.querySelector("#defaultGrid").style.height = "400px";
      }else{
        this.gridApi.setDomLayout("autoHeight");
        document.querySelector("#defaultGrid").style.height = '';
      }
    }
  }

  updateState = params => {
    this.submitGA('Updating Summary metrics columns','Update Summary Metrics');
    // let visibleColumns = params.selectedColumns.map(object => object.value);
    this.props.postSelectedColumns({
      selectedMetrics: params.selectedColumns
    });
    // let hideColumns =
    //   params.selectedColumns.length > 0
    //     ? [
    //         this.props.selectedColumns
    //           .filter((object, index) => {
    //             return visibleColumns[index] !== object.value;
    //           })
    //           .map(object => object.value)[0]
    //       ]
    //     : this.props.selectedColumns.map(object => object.value);
    
    const displayedColumns = this.gridColumnApi.getAllDisplayedColumns().map(obj=> obj.colDef.field);
  const selectedColumns =params.selectedColumns.map(object => object.value);
  let visibleColumns = [];
  let hideColumns = [];
  displayedColumns.filter(obj=> {
    if(PINNED_COLUMNS.indexOf(obj)!==-1 || selectedColumns.indexOf(obj)!==-1){
      visibleColumns.push(obj)
    }else{
      hideColumns.push(obj)
    }
  } )
    this.setState(params, () => {
      this.setColumnsVisible(
        visibleColumns.concat(['title', 'actualPerformance', 'id']),
        true
      );
      this.setColumnsVisible(hideColumns, false);
        this.gridAutoSize();
    });
    this.gridAutoSize();
  };
  componentWillUnmount() {
    this.setState({
      metrics: [...METRICS_LIST]
    });
  }

  downloadExcel = () => {
    let columnObj = {};
    this.gridColumnApi.columnController.allDisplayedColumns.forEach(obj => {
      if (obj.colDef.headerName !== 'Detail') {
        columnObj[`${obj.colDef.headerName}`] = obj.colDef.field;
      }
      if(obj.colDef.headerName === 'New Members Article %'){
        columnObj[`${obj.colDef.headerName}`] = "percOfNewCust";
      }
      // return columnObj;
    });
    this.props.downloadExcel(JSON.stringify(columnObj));
  };

  gridAutoSize =()=>{
    if (this.gridApi && this.gridColumnApi) {
      const displayedColumns = this.gridColumnApi.getAllDisplayedColumns().map(obj=> obj.colDef.field);
      if (displayedColumns.length <= 10) {
        this.gridApi.sizeColumnsToFit();
      } else {
        this.gridColumnApi.autoSizeAllColumns();
      }
    }
  }
  displaySelectedColumns =()=>{
    if(this.gridApi && this.gridColumnApi){
    if(this.props.selectedTab === PROMO_LEVEL){
        const displayedColumns = this.gridColumnApi.getAllDisplayedColumns().map(obj=> obj.colDef.field);
        const visibleColumnsList =[];
        const hideColumns =[];
        this.props.selectedColumns.filter(obj=>{
          if(PROMO_ARTICLE_LEVEL_COLUMNS.indexOf(obj.headerName)==-1){
            visibleColumnsList.push(obj.field)
          }else{
            hideColumns.push(obj.field);
          }
        })

        displayedColumns.filter(obj=> {
          if(PINNED_COLUMNS.indexOf(obj)!==-1 ){
            visibleColumnsList.push(obj)
          }else{
            if(visibleColumnsList.indexOf(obj) === -1)  {
              hideColumns.push(obj)
            } 
          }
        } )

          this.setColumnsVisible(visibleColumnsList,true);
          this.setColumnsVisible(hideColumns,false);
      }else{
        const displayedColumns = this.gridColumnApi.getAllDisplayedColumns().map(obj=> obj.colDef.field);
        const visibleColumnsList =[];
        const hideColumns =[];
        this.props.selectedColumns.filter(obj=>{
          if(PROMO_LEVEL_COLUMNS.indexOf(obj.headerName)==-1){
            visibleColumnsList.push(obj.field)
          }else{
            hideColumns.push(obj.field);
          }
        })

        displayedColumns.filter(obj=> {
          if(PROMO_ARTICLE_LEVEL_PINNED_COLUMNS.indexOf(obj)!==-1 ){
            visibleColumnsList.push(obj)
          }else{
            if(visibleColumnsList.indexOf(obj) === -1)  {
              hideColumns.push(obj)
            } 
          }
        } )
          this.setColumnsVisible(visibleColumnsList,true);
          this.setColumnsVisible(hideColumns,false);
      }
      this.gridAutoSize();
    }
  }

   getArticleData = async()=> {
    let obj = {promoId : [this.state.selectedOption.promoId]}

    const data = await fetchPromoHierarchyData(obj)      
    this.setState({
      articleData: data
    })
  }
  submitGA =(action,label)=>{
      HistoricalPerformanceGA({ action, label});
  }
  render() {
    const isSummaryMetric = this.state.metrics.filter(obj=> obj.name === "Summary" && obj.isChecked).length > 0 ? true : false;
    if(isSummaryMetric){

      this.displaySelectedColumns();
    }else{
      this.gridAutoSize();
    }
    var modalCellRenderer = ModalCellRenderer;
    if (this.props.page === 2 || this.props.page === 3) {
      modalCellRenderer = ModalCellRenderer2;
    }

    if (Object.keys(this.props.promoPipeLineObj).length > 0) {
      var name = this.props.promoPipeLineObj.name;
      var startDate = this.props.promoPipeLineObj.startDate;
      var endDate = this.props.promoPipeLineObj.endDate;
      var date1 = new Date(endDate);
      var date2 = new Date(startDate);
      var diffTime = Math.abs(date2.getTime() - date1.getTime()) + 1;
      var diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    }
    return (
      <div>
        <CustomModal
          showModal={this.state.modalVisible}
          openModal={this.openModal}
          closeModal={this.closeModal}
          currentPromo={this.state.selectedOption}
          // getArticleData={this.getArticleData}
          articleData={this.state.articleData}
          selectedTabPromoLevel = {this.props.selectedTab}
        />
        <LoadingOverlay
          active={this.props.loader}
          spinner
          text='Loading...'
          styles={{
            overlay: base => ({
              ...base,
              background: 'rgba(255, 255, 255, 0.97)',
              borderRadius: '40px'
            }),
            spinner: base => ({
              ...base,
              width: '50px',
              '& svg circle': {
                stroke: '#44677b'
              }
            }),
            content: base => ({
              ...base,
              color: '#44677b'
            })
          }}>
          {!this.state.showPromotionMain ? (
            <div >
                <div
                  >
                  <Row
                    style={
                      this.props.viewProperties.viewMode
                        ? { marginLeft: '-18px' }
                        : {
                            marginLeft: '-27px'
                          }
                    }>
                    <Col md={4}>
                      <div className='btn-group analyze-promo-btn-group'>
                        <button
                          className={
                            this.props.selectedTab === PROMO_LEVEL
                              ? 'btn btn-outline-primary btnModal1 active'
                              : 'btn btn-outline-primary btnModal1'
                          }
                          onClick={() => this.props.handleToggle(PROMO_LEVEL)} style={{paddingRight:"48px"}}>
                          Promo Level
                        </button>
                        <button
                          className={
                            this.props.selectedTab === PROMO_ARTICLE_LEVEL
                              ? 'btn btn-outline-primary btnModal1 active'
                              : 'btn btn-outline-primary btnModal1'
                          }
                          onClick={() =>
                            this.props.handleToggle(PROMO_ARTICLE_LEVEL)
                          }>
                          Promo Article Level
                        </button>
                      </div>
                    </Col>
                    <Col md={8}>
                      <Row>
                        <Form>
                          <Form.Group controlId='formBasicCheckbox'>
                            {this.state.metrics.map((metric, index) => (
                              <Form.Check
                                key={index}
                                id={`inline-${index}`}
                                custom
                                inline
                                type='checkbox'
                                name={metric.name}
                                onChange={this.handleMetrics.bind(this)}
                                checked={metric.isChecked}
                                label={metric.name}
                              />
                            ))}
                          </Form.Group>
                        </Form>
                      </Row>
                    </Col>
                  </Row>
                  
                    <Row>
                      <Col md='4'></Col>
                      <Col
                        md='3'
                        style={
                          this.props.viewProperties.viewMode
                            ? { marginLeft: '-23px' }
                            : { marginLeft: '-22px' }
                        }>
                        {!this.props.isDropdownOpen && this.state.metrics[0].isChecked ? (
                          <MultiSelectCheckboxes
                            placeholderButtonLabel='Select Metrics'
                            options={this.state.metricsOptions}
                            value={this.props.selectedColumns}
                            mappingKey='selectedColumns'
                            updateState={this.updateState}
                            defaultSelectedOptions={true}
                          />
                        ) : null}
                      </Col>
                      <Col
                        md={{
                          span: 1,
                          offset: 4
                        }}>
                        {!this.props.viewProperties.enableCompaign ? (
                          <>
                            {' '}
                            <OverlayTrigger
                              placement='bottom'
                              overlay={<Tooltip> Download as excel </Tooltip>}>
                              <button
                                className='btn btn-primary iconbutton download-btn'
                                data-tip='Download as excel'
                                data-place='bottom'
                                onClick={this.downloadExcel} disabled={this.props.selectedMerchCat.length===0 || this.props.tableData.length ===0}>
                                <span>
                                  <i
                                    class='fa fa-file-excel-o'
                                    aria-hidden='true'></i>
                                  &nbsp;&nbsp;
                                  <i
                                    class='fa fa-download'
                                    aria-hidden='true'
                                  />
                                </span>
                              </button>
                            </OverlayTrigger>{' '}
                          </>
                        ) : null}
                      </Col>
                    </Row>
                  
                  <hr style={{margin:"30px 0px"}}/>{' '}
                  {this.props.viewProperties.enableCompaign ? (
                    <div className='row pipelineCopydiv'>
                      <div className='col-md-12'>
                        <div
                          className='row'
                          style={{
                            marginLeft: '12px'
                          }}>
                          <div className='col-md-4'>
                            <span className='promoNameText row'>
                              Campaign Name:{' '}
                              {name ? (
                                <p className='promonameResult'>{name}</p>
                              ) : null}
                            </span>
                          </div>
                          <div className='col-md-3'>
                            <span
                              className='promoNameText row'
                              style={{
                                paddingLeft: '2%'
                              }}>
                              Start Date:{' '}
                              {startDate ? (
                                <p className='promonameResult'>{startDate}</p>
                              ) : null}
                            </span>
                          </div>
                          <div className='col-md-3'>
                            <span
                              className='promoNameText row'
                              style={{
                                paddingLeft: '2%'
                              }}>
                              End Date:{' '}
                              {endDate ? (
                                <p className='promonameResult'>{endDate}</p>
                              ) : null}
                            </span>
                          </div>
                          <div className='col-md-2'>
                            <span
                              className='promoNameText row'
                              style={{
                                paddingLeft: '2%'
                              }}>
                              # of days:{' '}
                              {diffDays ? (
                                <p className='promonameResult'>
                                  {diffDays ? diffDays : ''}
                                </p>
                              ) : null}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className='pipelineGrid' style={{marginBottom:"30px"}}>
                    <div
                      id='defaultGrid'
                      className='ag-theme-balham'
                      style={{
                        width: "100%"
                      }}>
                      <AgGridReact
                        floatingFilter={true}
                        defaultColDef={{
                          resizable: true
                        }}
                        multiSortKey='ctrl'
                        columnDefs={this.state.columnDefs}
                        rowData={
                          this.props.viewProperties.viewMode
                            ? this.props.tableData
                            : []
                        }
                        onFilterChanged ={this.promotionPipelineDataHandler}
                        onGridReady={this.onGridReady}
                        getRowStyle ={ function (params) {
                          if (params.node.rowPinned) {
                            return { 'background-color': '#D3D3D3' };
                          }
                        }
                        }
                        pinnedTopRowData= {this.props.tableData.length==0 ? '':this.state.pinnedRowData}
                        handleTableOpClick={this.handleTableOpClick}
                        domLayout="normal"
                        enableCellTextSelection={true}
                        // rowSelection={this.state.rowSelection}
                        // onSelectionChanged={this
                        // .onSelectionChanged
                        // .bind(this)}
                      />
                    </div>
                  </div>
                </div>
              <div></div>
            </div>
          ) : (
            {}
          )}
        </LoadingOverlay>
      </div>
    );
  }
}

const mapActionToProps = dispatch => ({
  postSelectedColumns: val => dispatch(updateSelectedMetrics(val)),
  fetchSelectedMetrics: (val) => dispatch(fetchSelectedMetrics(val)),
  // articleData: (val) => dispatch(fetchPromoHierarchyData(val))
});

const mapStateToProps = state => ({
  selectedColumns: state.userReducer.selectedMetrics
});

export default connect(mapStateToProps, mapActionToProps)(PromotionPipeline);
