import React from 'react';

const EditOrDelete = (props) => {
  const onEditClick = () => {
    props.handleEdit(props.data);
  };
  const onDeleteClick = () => {
    props.handleDelete(props.data);
  };
  return (
    <>
     {props.data.articleNo !== null && props.data.groupid !== null ? null :
      <div className="editOrDelete">
        <span>
          <i className="fa fa-pencil-square-o"  onClick={(e) => onEditClick(e)} ></i>
        </span>
        <span>
          <i class="fa fa-trash-o" onClick={(e) => onDeleteClick(e)} ></i>
        </span>
      </div>
}
    </>
  );
};

export default EditOrDelete;
