import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
const emptyValueFormatter = (value) => {
  if (value.value) {
    return value.value;
  } else {
    if(value.value === 0) 
      return '0.00'
    return '-';
  }
};
const cycleSorting = (val1,val2) => {
  let temp1 = val1.substring(3);
  let temp2 = val2.substring(3)
  if (temp1 === null && temp2 === null) {
    return 0;
  }
  if (temp1 === null) {
    return -1;
  }
  if (temp2 === null) {
    return 1;
  }

  return temp1 - temp2;
}
const DISCOUNT_COLUMNS = [
  {
    headerName: 'Cycle',
    field: 'cycle',
    comparator: cycleSorting
  },
  {
    headerName: 'Min',
    field: 'min',
    valueFormatter: emptyValueFormatter,
  },
  {
    headerName: 'Max',
    field: 'max',
    valueFormatter: emptyValueFormatter,
  },
  {
    headerName: 'Fixed',
    field: 'fixed',
    valueFormatter: emptyValueFormatter,
  },
];
const defaultColDef= {
  sortable: true
}
const ConstraintDiscountDepthTable = (props) => {
  const [rowData, setRowData] = useState(props.rowData);
  const [gridApi, setGridApi] = useState({});
  useEffect(() => {
    setRowData(props.rowData);
    if (gridApi.setRowData) {
      gridApi.setRowData(props.rowData);
    }
  }, [props.rowData]);
  const onGridReady = (params) => {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  };
  return (
    <React.Fragment>
      <div
        id="defaultGrid"
        className="ag-theme-balham"
        style={{
          height: rowData.length>2?200:100,
          width: '100%',
        }}>
        <AgGridReact
          columnDefs={DISCOUNT_COLUMNS}
          rowData={rowData}
          onGridReady={onGridReady}
          defaultColDef={defaultColDef}
          // domLayout="autoHeight"
        />
      </div>
    </React.Fragment>
  );
};

export default ConstraintDiscountDepthTable;
