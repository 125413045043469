import { toast } from "react-toastify";
import { SET_LOADER, SET_CYCLES, SET_QUARTERS, SET_CONSOLIDATED_DATA,
  SET_BUYER_DATA,
  SET_CATEGORY_DATA,
  SET_VENDOR_DATA, SET_MERCHCAT,RESET_CONSOLIDATED_DATA ,UPDATE_CONSOLIDATED_DATA} from './types';
import {
  fetchCycles,
  fetchQuarters,
  fetchConsolidated,
  fetchBuyerView,
  fetchCategoryView,
  fetchVendorView,
} from '../apis/executiveSummary.api';
import {fetchMerchCatValues} from '../apis/common.api.';

export const actionGetCycles = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await fetchCycles(data);
    let cycles = result.map((obj) => {
      return {
        value: obj,
        label: obj,
      };
    });
    dispatch({ type: SET_CYCLES, payload: cycles });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionGetQuarters = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await fetchQuarters(data);
    let quarters = result.map((object) => {
      object.value = `${object.year}-Q${object.quater}`;
      object.label = `${object.year}-Q${object.quater}`;
      return object;
    });
    dispatch({ type: SET_QUARTERS, payload: quarters });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionGetConsolidated = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await fetchConsolidated(data);
    if(Array.isArray(result.data)){
      dispatch({ type: SET_CONSOLIDATED_DATA, payload: result });
    }
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};


export const actionGetBuyerView = (data) => async (dispatch) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const result = await fetchBuyerView(data);
      // dispatch({ type: SET_BUYER_DATA, payload: result });
      if(Array.isArray(result.data)){
        dispatch({ type: SET_CONSOLIDATED_DATA, payload: result });
      }
      dispatch({ type: SET_LOADER, payload: false });
    } catch (err) {
      dispatch({ type: SET_LOADER, payload: false });
      
    }
  };

  

  export const actionGetVendorView = (data) => async (dispatch) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const result = await fetchVendorView(data);
      // dispatch({ type: SET_VENDOR_DATA, payload: result });
      if(Array.isArray(result.data)){
        dispatch({ type: SET_CONSOLIDATED_DATA, payload: result });
      }
      dispatch({ type: SET_LOADER, payload: false });
    } catch (err) {
      dispatch({ type: SET_LOADER, payload: false });
      
    }
  };

  

  export const actionGetCategoryView = (data) => async (dispatch) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const result = await fetchCategoryView(data);
      // dispatch({ type: SET_CATEGORY_DATA, payload: result });
      if(Array.isArray(result.data)){
        dispatch({ type: SET_CONSOLIDATED_DATA, payload: result });
      }
      dispatch({ type: SET_LOADER, payload: false });
    } catch (err) {
      dispatch({ type: SET_LOADER, payload: false });
      
    }
  };
  
  export const actionUpdateSorting = (data) => async (dispatch) => {
        dispatch({ type: UPDATE_CONSOLIDATED_DATA, payload: data });
    
  };

  export const getCategoryList = payload => async dispatch => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      let data = await fetchMerchCatValues(payload);
      if (data) {
        data = data.map(object => {
          return { value: object, label: object };
        })
        dispatch({ type: SET_MERCHCAT, payload: data });
      } else {
        dispatch({ type: SET_MERCHCAT, payload: [] });
      }
      dispatch({ type: SET_LOADER, payload: false });
    } catch (error) {
      dispatch({ type: SET_LOADER, payload: false });
    }
  };
  
  export const resetConsolidatedData = (data) => async (dispatch) => {

    dispatch({ type: RESET_CONSOLIDATED_DATA, payload: [] });

};
