import React, {Component} from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import moment from "moment";
import {isEqual} from "lodash";
import {PROMO_LEVEL, PROMO_ARTICLE_LEVEL} from "../../constants";
import PageLoader from "../../utils/Loader";
import AlertDanger from "../../utils/AlertMessage/alertDanger";
import _ from "underscore";
import {Element, animateScroll as scroll, scroller} from "react-scroll";
import {openLoader, closeLoader} from "../../actions/promo";
import CalendarWrapper from "../Calendar";
import DynamicFilters from '../DynamicFilters';
import PromotionPipeline from '../PromotionPipeline'
import {calendarDatePlot, calendarData, getshowPromotionPipelineData, getPromoArticleLevel, deleteKey, actionDownloadPromoCalendar ,resetCalendarData } from "./promoCalendarAction";
import {MONTH_NAMES} from './constants';
import { toast } from 'react-toastify';
import PromoHierarchyTable from './promoHierarchyTable';
import { PromotionCalendarGA } from '../../common/GAUtils';
import { ToastMessage } from "../../common/ToastMessage";
const monthNames = MONTH_NAMES;

export class PromoCalendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loader: false,
            navigated: 'init',
            adGrpArr: [],
            showSelectedValues: false,
            selectedPromo: "",
            showPromotionMain: false,
            showPromotionMainWithValues: false,
            startDate: new Date(),
            selectedYear: (new Date()).getFullYear(),
            selectedYears: [], //2020
            selectedMonth: (new Date()).toLocaleDateString('en-US', {month: 'short'}), //"Sep",
            selectedView: "Month",
            fiscal_monthnum: Number(new Date().getMonth()),
            quarterData: {
                fiscal_quarter: '1',
                selectedDate: moment(new Date()),
                fiscal_monthnum: 2,
                selectedYear: 2021,
                selectedMonth: MONTH_NAMES[2]
            },
            next: {
                startDate: '',
                fiscal_monthnum: 0,
                fiscal_quarter: 0
            },
            previous: {
                endDate: '',
                fiscal_monthnum: 0,
                fiscal_quarter: 0
            },
            calendarStructure: {},
            needsRefresh: false,
            fiscal_quarter: 3,
            calendarWrapperArr: [],
            calendarWrapperArr1: [],
            historyStartDate: null,
            historyEndDate: null,
            historyQuarter: null,
            histortFiscalMonth: null,
            calendarDataArr: [],
            selectedStatus: [],
            initialLoadFlag: true,
            selectedDate: moment(),
            minDateState: null,
            maxDateState: null,
            quartersOptions: [],
            promosList: [],
            generateCadenceError: false,
            merchcat: [],
            vendor: [],
            ah6: [],
            articleNo:[],
            promoPipeLineObj: {},
            selectedTab: PROMO_LEVEL,
            quarters:[]
        };
        // we have noticed calendarStrucure props getting changed somewhere.
        this.calendarData = JSON.parse(localStorage.getItem("calendarData"));
    }
    componentWillMount() {
        // this.setState({loader: true});
        // this
        //     .props
        //     .calendarDatePlot(this.state.selectedYear);
        this.props.deleteKey();

    }

    componentDidUpdate(oldProps, oldState) {
        if (!isEqual(oldProps.calendarStrucure, this.props.calendarStrucure)) {
            this.getCalendarWrapper(this.state.selectedView);
        }

        if (oldProps.calendarDataPerYear != this.props.calendarDataPerYear) {
            /*------------------yearly navigation enable/disable function------------------ */
            /*-------------------------setting Calendar Data--------------------------*/
            // this.setState({calendarDataArr: this.props.calendarDataPerYear, loader: false});
            this.setState({calendarDataArr: this.props.calendarDataPerYear});
        }

        // if ((this.props.pipelineData && this.props.pipelineDataSku && oldProps.pipelineData != this.props.pipelineData) || oldProps.pipelineDataSku != this.props.pipelineDataSku) {
        //     this.setState({loader: false});
        // }

        if (!this.state.minDateState && !this.state.maxDateState && this.props.calendarStrucure) {
            this.setState({minDateState: this.props.calendarStrucure.overall_dates[0].start_date, maxDateState: this.props.calendarStrucure.overall_dates[0].end_date});
        }
    }

    onRefresh = () => {
        this.submitGA("Click","Reset Button");
        this.reloadData();

    };
    reloadData = () => {
        let currentQuarter =   this.state.quartersOptions.filter(obj=>{
            return moment(new Date()).isAfter(moment(obj.startDate)) && moment(new Date()).isBefore(moment(obj.endDate))
         })
         if(currentQuarter.length === 0){
            currentQuarter=   this.state.quartersOptions.filter(obj=>{
                return moment(moment().format("YYYY-MM-DD")).isSame(moment(obj.startDate)) || moment(moment().format("YYYY-MM-DD")).isSame(moment(obj.endDate))
             })
       }
         let propertiesToUpdate = {}
         let selectedQuarter =currentQuarter[0];
            let date = new Date(selectedQuarter['startDate']);
            propertiesToUpdate.selectedDate =   moment()
            propertiesToUpdate.fiscal_monthnum = selectedQuarter.monthNumbers[0];
            propertiesToUpdate.selectedYear = selectedQuarter.year;
            propertiesToUpdate.fiscal_quarter = selectedQuarter.quater;
            propertiesToUpdate.selectedMonth = MONTH_NAMES[propertiesToUpdate.fiscal_monthnum];
        this.setState({loader: false, vendor: [], merchcat: [], ah6: [],articleNo:[], selectedView: 'Month',promoPipeLineObj:{} , quarters:currentQuarter , selectedYear: currentQuarter[0].year ,quarterData: propertiesToUpdate,selectedPromo: "" },()=>{
            this.updateStateForCalendarWrapper(() => {
                    this.getCalendarWrapper(this.state.selectedView);
            });
            this.props.calendarDatePlot(this.state.quarterData.selectedYear);
            this.props.resetCalendarData();
            
        });
        this
            .props
            .deleteKey();
    };

    updateStateForCalendarWrapper = (callback) => {
        const {
            fiscal_quarter,
            selectedDate,
            fiscal_monthnum,
            selectedMonth,
            selectedYear,
        } = this.state.quarterData;
        this.setState({
            promoPipeLineObj:{},
            fiscal_quarter,
            selectedDate,
            selectedYear,
            fiscal_monthnum,
            selectedMonth
        }, callback)
    }

    filterData = async() => {
        this.submitGA("Click","Filter Button");
        // debugger;
        var merchcat = this.state.merchcat
            ? this
                .state
                .merchcat
                .map(object => object.value)
            : null;
        var vendor = this.state.vendor
            ? this
                .state
                .vendor
                .map(object => object.value)
            : null;
        var month = this.state.month
            ? this
                .state
                .month
                .map(object => object.value)
            : null;

        var sku = this.state.selectedSKU
            ? this.state.selectedSKU.value
            : null;
        var ah6 = this.state.ah6
            ? this
                .state
                .ah6
                .map(object => object.value)
            : null;
        var articleNo = this.state.articleNo.length > 0
            ? this
                .state
                .articleNo
                .map(object => object.value)
            : [];
            let selectedQuarter =this.state.quarters[0];
            let yearQuarters = [{fyear: this.state.quarterData.selectedYear, quarter: this.state.quarterData.fiscal_quarter}]
             
        let calendarObj = {
            // start_date: this.state.historyStartDate,
            // end_date: this.state.historyEndDate,
            // start_date: selectedQuarter.startDate,
            // end_date: selectedQuarter.endDate,
            merchcat,
            vendor,
            ah6,
            month,
            articleNo,
            yearQuarters,
            status: this.state.selectedStatus
        }
        let passedObj = _.pick(calendarObj, _.identity);
        let date = new Date(selectedQuarter['startDate']);
        date.setFullYear(selectedQuarter.year);
        // this
        //     .props
        //     .calendarData({
        //         ...passedObj,
        //         screenName: 'calendar'
        //     });
        await this.props.calendarDatePlot(this.state.quarterData.selectedYear);
        this
            .props
            .deleteKey();
        this.updateStateForCalendarWrapper(() => {
            // if (this.state.needsRefresh) {
                this.getCalendarWrapper(this.state.selectedView);
            //     this.setState({
            //         needsRefresh: false
            //     })
            // }
        });
        
        // this
        //     .props
        //     .calendarData({
        //         ...passedObj,
        //         screenName: 'calendar'
        //     });
    }

    updateNextAndPrevious = ({
        calendarWrapperArr,
        val,
        calendarStrucure
    }) => {
        const fiscal_monthnum = Number(this.state.fiscal_monthnum);
        const fiscal_quarter = Number(this.state.fiscal_quarter);
        const nextStartDate = moment(calendarWrapperArr[calendarWrapperArr.length - 1].end_date).add(1, 'days');
        let nextFiscalMonth = fiscal_monthnum + 1;
        let nextFiscalQuarter = fiscal_quarter + 1;
        if (nextFiscalMonth > 12) {
            nextFiscalMonth = nextFiscalMonth - 12;
        }
        if (nextFiscalQuarter > 4) {
            nextFiscalQuarter = nextFiscalQuarter - 4;
        }
        this.setState({
            next: {
                startDate: nextStartDate,
                fiscal_monthnum: nextFiscalMonth,
                fiscal_quarter: nextFiscalQuarter
            }
        })
        
        let prevFiscalMonth = fiscal_monthnum - 1;
        let prevFiscalQuarter = fiscal_quarter - 1;
        if (prevFiscalMonth <= 0) {
            prevFiscalMonth += 12;
        }
        if (prevFiscalQuarter <= 0) {
            prevFiscalQuarter += 4;
        }
        let prevEndDate = moment(calendarWrapperArr[0].start_date).subtract(1, 'days');
        
        this.setState({
            previous: {
                endDate: prevEndDate,
                fiscal_monthnum: prevFiscalMonth,
                fiscal_quarter: prevFiscalQuarter
            }
        })
    }

    getCalendarWrapper = async(val) => {
        let context = this;
        let calendarWrapperArr = [],
            start_date = null,
            end_date,
            startDate,
            endDate;
        // debugger;
        // we have noticed calendarStrucure props getting changed somewhere.
        const calendarStrucure = JSON.parse(JSON.stringify(this.props.calendarStrucure));
        if (
            calendarStrucure &&
            calendarStrucure.plot_data &&
            calendarStrucure.plot_data.length &&
            calendarStrucure.fiscalYear == this.state.quarterData.selectedYear
        ) {
            const next = Object.assign({}, this.state.next);
            const previous = Object.assign({}, this.state.previous);
            // debugger;
            var fiscal_week_52_patch = false;
            let curr_date = moment(new Date()).format("YYYY-MM-DD");
            if (context.state.selectedDate) {
                curr_date = moment(context.state.selectedDate);
                curr_date.set({hour:0,minute:0,second:0,millisecond:0})
            }
            var temp_fiscal_week = 0;
            var temp_fiscal_monthnum = 0;
            const plotData = calendarStrucure.plot_data
            for (const ele of plotData) {
                // debugger;
                const weekStartDate = moment(ele.start_date);
                const weekEndDate = moment(ele.end_date);
                if (curr_date.isSameOrAfter(weekStartDate) && curr_date.isSameOrBefore(weekEndDate)) {
                    // debugger;
                    temp_fiscal_week = Number(ele.fiscal_week);
                    temp_fiscal_monthnum = Number(ele.fiscal_monthnum);
                    break;
                }
            }
            if (val == "Month") {
                // debugger;
                calendarStrucure
                    .plot_data
                    .forEach(ele => {
                        // debugger
                        if (Number(ele.fiscal_monthnum) == temp_fiscal_monthnum) {
                            calendarWrapperArr.push(ele);
                        }
                    });
            } else {
                //debugger;
                let tempArr = calendarStrucure.plot_data;
                for (let index = 0; index < tempArr.length; index++) {
                    if (tempArr[index].fiscal_quarter == 1 && tempArr[index].fiscal_week == 52) {
                        fiscal_week_52_patch = true;
                    }
                    if (Number(tempArr[index].fiscal_quarter) == context.state.fiscal_quarter && (new Date(tempArr[index].start_date).getFullYear() + 1 == context.state.selectedYear || new Date(tempArr[index].start_date).getFullYear() == context.state.selectedYear)) {
                        if (index < tempArr.length - 1 && tempArr[index].fiscal_week == tempArr[index + 1].fiscal_week) {
                            tempArr[index].end_date = tempArr[index + 1].end_date;
                            calendarWrapperArr.push(tempArr[index]);
                            index++;
                        } else {
                            calendarWrapperArr.push(tempArr[index]);
                        }
                    }
                }
            }
            // debugger;
            if(calendarWrapperArr.length > 0){
                start_date = calendarWrapperArr && calendarWrapperArr[0]
                ? calendarWrapperArr[0].start_date
                : null;

            if (fiscal_week_52_patch == true) {
                end_date = calendarWrapperArr && calendarWrapperArr.length
                    ? calendarWrapperArr[calendarWrapperArr.length - 2].end_date
                    : null;
            } else {
                end_date = calendarWrapperArr && calendarWrapperArr.length
                    ? calendarWrapperArr[calendarWrapperArr.length - 1].end_date
                    : null;
            }
            if (calendarWrapperArr && calendarWrapperArr.length > 0) {
                this.updateNextAndPrevious({
                    calendarWrapperArr,
                    val,
                    calendarStrucure
                });
            }

            let obj = this.getFilterParams();
            let selectedQuarter =this.state.quarters[0];
            if(this.state.merchcat.length > 0){
                await this.props.calendarData({
                    // start_date: selectedQuarter.startDate,
                    // end_date: selectedQuarter.endDate,
                    ...obj,
                    status: context.state.selectedStatus,
                    screenName: 'calendar'
                });
            }
            }
            

        }
        this.setState(state => {
            return {
                ...state,
                calendarWrapperArr,
                historyStartDate: start_date,
                historyEndDate: end_date,
                histortFiscalMonth: state.fiscal_monthnum,
                historyQuarter: state.fiscal_quarter
            };
        });

    };

    toggleMonthQuarter = val => {
        this.submitGA("Click",`Toggle ${val} view`);
        let temp = val;
        if (this.state.selectedView != val) {
            this.setState({selectedView: val , promoPipeLineObj:{}});
            //debugger;

            this.getCalendarWrapper(val);
            this
            .props
            .deleteKey();
        }
    };
    showPromotionMain = e => {
        if (e == "showSelectedValues") {
            this.setState({showSelectedValues: true});
        }
        this.setState({showPromotionMain: true});
    };

    showPromotionMainWithValues = e => {
        this.setState({showPromotionMainWithValues: true});
    };

    showPromotionPipeline = val => {
        this.submitGA("Click","Get Campaign Details");
        this.setState({
            selectedPromo: val.id,
            selectedPromoName: val.name,
            selectedTab: PROMO_LEVEL,
            viewMode: true,
            historyCampaignId: val.id,
            historyCampaignName: val.name,
            promoPipeLineObj: {
                name: val.name,
                startDate: val.start_date,
                endDate: val.end_date,
                id: val.id,
                promodIds: val.promo_ids
            }
        });
        var merchcat = this.state.merchcat
            ? this
                .state
                .merchcat
                .map(object => object.value)
            : null;
        var vendor = this.state.vendor
            ? this
                .state
                .vendor
                .map(object => object.value)
            : null;

        var ah6 = this.state.ah6
            ? this
                .state
                .ah6
                .map(object => object.value)
            : null;
        var articleNo = this.state.articleNo.length > 0
            ? this
                .state
                .articleNo
                .map(object => object.value)
            : [];

            let yearQuarters = [{fyear: this.state.quarterData.selectedYear, quarter: this.state.quarterData.fiscal_quarter}]
        this
            .props
        .getPromoArticleLevel({
                campaignId: [val.id],
                // start_date: this.state.quarters[0].startDate,
                // end_date: this.state.quarters[0].endDate,
                merchcat,
                vendor,
                ah6,
                articleNo,
                // dates: this.state.quarters.map(object => {
                //     return {startDate: object.startDate, endDate: object.endDate};
                // }),
                yearQuarters,
                screenName: 'calendar'
            });

        scroller.scrollTo("showPromotionTag", {
            duration: 800,
            delay: 0,
            smooth: "easeInOutQuart"
        });
    };

    call_edit_adgroup = data => {
        this
            .props
            .history
            .push(`/createpromotion?copyCampaign=true&campaignId=${data.campaignId}&adgroupIds=${data.adgroupId}&promoIds=${data.promoId}`);
    };

    navCallBack = payload => {
        const {
            navigated,
        } = payload;
        this.submitGA("Click",`Toggle ${navigated} view`)
        // debugger;
        this.props.deleteKey();
        const promoPipeLineObj = {}
        const {
            startDate,
            endDate
        } = this.state.quarters[0]
        const {
            quartersOptions,
            quarters
        } = this.state;

        const currentQuarterIndex = _.findIndex(quartersOptions, quarters[0]);

        if (navigated == 'next-month') {
            const { next } = this.state
            const nextDate = moment(next.startDate)
            if (nextDate.isSameOrAfter(moment(startDate)) && nextDate.isBefore(endDate)) {
                this.setState({
                    selectedDate: moment(next.startDate),
                    navigated,
                    fiscal_monthnum: String(next.fiscal_monthnum),
                    promoPipeLineObj
                }, () => {
                    this.getCalendarWrapper(this.state.selectedView);
                })
            } else {
                if (currentQuarterIndex >= 0 && currentQuarterIndex < (quartersOptions.length - 1)) {
                    const nextQuarter = quartersOptions[currentQuarterIndex + 1];
                    this.changeSelectedQuarter({
                        quarters: [nextQuarter]
                    }, () => {
                        this.updateStateForCalendarWrapper(() => {
                            if ((this.selectedView == "Month" && next.fiscal_monthnum == 1) || (this.selectedView != "Month" && next.fiscal_quarter == 1)) {
                                this.props.calendarDatePlot(this.state.selectedYear);
                            } else {
                                this.getCalendarWrapper(this.state.selectedView);
                            }
                        })
                    })
                } else {
                    ToastMessage(403,'No Data found');
                }
            }
        } else if (navigated == 'prev-month') {
            const { previous } = this.state
            const previousDate = moment(previous.endDate)
            if (previousDate.isSameOrAfter(moment(startDate)) && previousDate.isSameOrBefore(endDate)) {
                this.setState({
                    selectedDate: moment(previous.endDate),
                    navigated,
                    fiscal_monthnum: String(previous.fiscal_monthnum),
                    promoPipeLineObj
                }, () => {
                    this.getCalendarWrapper(this.state.selectedView);
                });
            } else {
                if (currentQuarterIndex > 0 && currentQuarterIndex <= (quartersOptions.length - 1)) {
                    const prevQuarter = quartersOptions[currentQuarterIndex - 1];
                    this.changeSelectedQuarter({
                        quarters: [prevQuarter],
                        fiscal_monthnum: previous.fiscal_monthnum,
                        selectedDate: moment(previous.endDate)
                    }, () => {
                        this.updateStateForCalendarWrapper(() => {
                            if ((this.selectedView == "Month" && previous.fiscal_monthnum == 12) || (this.selectedView != "Month" && previous.fiscal_quarter == 4)) {
                                this.props.calendarDatePlot(this.state.selectedYear);
                            } else {
                                this.getCalendarWrapper(this.state.selectedView);
                            }
                        })
                    })
                } else {
                    ToastMessage(403,'No Data found');
                }
            }

        }
    };

    getFilterParams() {
        var merchcat = this.state.merchcat.length > 0
            ? this
                .state
                .merchcat
                .map(object => object.value)
            : null;
        var vendor = this.state.vendor.length > 0
            ? this
                .state
                .vendor
                .map(object => object.value)
            : null;
        var ah6 = this.state.ah6.length > 0
            ? this
                .state
                .ah6
                .map(object => object.value)
            : null;
        var articleNo = this.state.articleNo.length > 0
            ? this
                .state
                .articleNo
                .map(object => object.value)
            : [];
        let yearQuarters = [{fyear: this.state.quarterData.selectedYear, quarter: this.state.quarterData.fiscal_quarter}]
        let calendarObj = {
            merchcat,
            vendor,
            ah6,
            articleNo,
            yearQuarters
            // dates: this.state.quarters.map(object => {
            //     return {startDate: object.startDate, endDate: object.endDate};
            // })
        }
        let passedObj = _.pick(calendarObj, _.identity);
        return passedObj;
    }

    changeSelectedQuarter = (params, callback) => {
        // debugger;
        const propertiesToUpdate = {}
        if(params.quarters.length === 0){
            propertiesToUpdate.selectedDate =  moment().add(0, 'years')
            propertiesToUpdate.fiscal_monthnum = "2";
            propertiesToUpdate.selectedYear = "2021";
            propertiesToUpdate.fiscal_quarter = "1";
            propertiesToUpdate.selectedMonth = MONTH_NAMES[propertiesToUpdate.fiscal_monthnum];
        }else{
            let selectedQuarter =params.quarters[0];
            let date = new Date(selectedQuarter['startDate']);
            propertiesToUpdate.selectedDate =  params.selectedDate ? params.selectedDate : moment(date)
            propertiesToUpdate.fiscal_monthnum = params.fiscal_monthnum ? params.fiscal_monthnum : selectedQuarter.monthNumbers[0];
            propertiesToUpdate.selectedYear = selectedQuarter.year;
            propertiesToUpdate.fiscal_quarter = selectedQuarter.quater;
            propertiesToUpdate.selectedMonth = MONTH_NAMES[propertiesToUpdate.fiscal_monthnum];
        }
        // debugger;
        this.setState({
            quarters: params.quarters,
            quarterData: propertiesToUpdate
        }, callback);
    }

    handleSelectedQuarter = params => {
        this.setState({
            needsRefresh: true
        })
        this.changeSelectedQuarter(params);
    }

    updateState = params => {
        let keysLength = Object.keys(params).length;
        if(params.hasOwnProperty("merchcat") && keysLength ===1){
            this.submitGA('Filter','Changed merchcat selection');
        }
        if(params.hasOwnProperty("ah6") && keysLength ===1){
            this.submitGA('Filter','Changed ah6 selection');
        }
        if(params.hasOwnProperty("vendor") && keysLength ===1 ){
            this.submitGA('Filter','changed vendor selection');
        }
        if(params.hasOwnProperty("articleNo") && keysLength ===1){
            this.submitGA('Filter','changed article selection');
        }
        
        if(params.hasOwnProperty("quarters") && keysLength ===1){
            this.submitGA('Filter','changed quarters  selection');
        }
        if(params.hasOwnProperty('quarters')){
            this.handleSelectedQuarter(params)
        } else {
            this.setState(params);
        }
    };
    getHierarchyData = criteria => {
        this.setState({selectedTab: criteria});
        if(Object.keys(this.state.promoPipeLineObj).length>0){
            this.getData(criteria);
        }
    }
    getData(criteria) {
        var merchcat = this.state.merchcat
            ? this
                .state
                .merchcat
                .map(object => object.value)
            : null;
        var vendor = this.state.vendor
            ? this
                .state
                .vendor
                .map(object => object.value)
            : null;
        var month = this.state.month
            ? this
                .state
                .month
                .map(object => object.value)
            : null;
        var sku = this.state.selectedSKU
            ? this.state.selectedSKU.value
            : null;
        var ah6 = this.state.ah6
            ? this
                .state
                .ah6
                .map(object => object.value)
            : null;
        var articleNo = this.state.articleNo.length > 0
        ? this
            .state
            .articleNo
            .map(object => object.value)
        : [];
        let yearQuarters = [{fyear: this.state.quarterData.selectedYear, quarter: this.state.quarterData.fiscal_quarter}]

        let calendarObj = {
            // start_date: this.state.quarters[0].startDate,
            // end_date: this.state.quarters[0].endDate,
            merchcat,
            vendor,
            ah6,
            month,
            articleNo,
            status: this.state.selectedStatus,
            campaignId: [this.state.selectedPromo],
            yearQuarters
            // dates: this.state.quarters.map(object => {
            //     return {startDate: object.startDate, endDate: object.endDate};
            // })
        }
        let passedObj = _.pick(calendarObj, _.identity);

        if (this.state.viewMode) {
            if (criteria === PROMO_ARTICLE_LEVEL) {
                this
                    .props
                    .getPromoArticleLevel(passedObj);
            } else {
                this
                    .props
                    .getshowPromotionPipelineData(passedObj)
            }
        }

    }

    renderPromotionPipeline = (viewProperties) => {
        const {
            viewMode = false,
            promosList
        } = this.props
        const {
            merchcat,
            selectedPromo,
            selectedPromoName,
            vendor,
            historyStartDate,
            historyEndDate,
            promoPipeLineObj,
            selectedTab
        } = this.state
        return (
            <Element name="showPromotionTag" className="element">
                <PromotionPipeline
                    viewMode={viewMode}
                    call_edit_adgroup_in_createPromo={adgroupData => this.call_edit_adgroup(adgroupData)}
                    selectedPromo={selectedPromo}
                    selectedPromoName={selectedPromoName}
                    selectedMerchCat={merchcat}
                    selectedVendor={vendor}
                    startDate={historyStartDate}
                    endDate={historyEndDate}
                    tableData={promosList}
                    promoPipeLineObj={promoPipeLineObj}
                    selectedTab={selectedTab}
                    handleToggle={this.getHierarchyData}
                    viewProperties={viewProperties}/>
            </Element>
        )

    }
    accessChildState = async(childState)=>{
      let currentQuarter=   childState.quartersOptions.filter(obj=>{
           return moment(new Date()).isAfter(moment(obj.startDate)) && moment(new Date()).isBefore(moment(obj.endDate))
        })
        if(currentQuarter.length === 0){
            currentQuarter=   childState.quartersOptions.filter(obj=>{
                return moment(moment().format("YYYY-MM-DD")).isSame(moment(obj.startDate)) || moment(moment().format("YYYY-MM-DD")).isSame(moment(obj.endDate))
             })
       }

         let propertiesToUpdate = {}
         let selectedQuarter =currentQuarter[0];
            let date = new Date(selectedQuarter['startDate']);
            propertiesToUpdate.selectedDate =   moment()
            propertiesToUpdate.fiscal_monthnum = selectedQuarter.monthNumbers[0];
            propertiesToUpdate.selectedYear = selectedQuarter.year;
            propertiesToUpdate.fiscal_quarter = selectedQuarter.quater;
            propertiesToUpdate.selectedMonth = MONTH_NAMES[propertiesToUpdate.fiscal_monthnum];
        this.setState({
            quarters: currentQuarter,
            quartersOptions: childState.quartersOptions,
            quarterData: propertiesToUpdate
        },async()=>{
        let selectedQuarter = this.state.quarters[0];
        
        this.updateStateForCalendarWrapper(() => {
            if (this.state.needsRefresh) {
                this.getCalendarWrapper(this.state.selectedView);
                this.setState({
                    needsRefresh: false
                })
            }
        });
        await this.props.calendarDatePlot(this.state.quarterData.selectedYear);
            // this.props.calendarData({
            //     start_date: selectedQuarter.startDate,
            //     end_date: selectedQuarter.endDate,
            // });
        })
    }

    handleDownloadPromotion = () => {
        this.submitGA("Click",`Download promotion details`);
        const merchcat = this.state.merchcat
            ? this
                .state
                .merchcat
                .map(object => object.value)
            : null;
        const vendor = this.state.vendor
            ? this
                .state
                .vendor
                .map(object => object.value)
            : null;
        const ah6 = this.state.ah6
            ? this
                .state
                .ah6
                .map(object => object.value)
            : null;
        const articleNo = this.state.articleNo.length > 0
        ? this
            .state
            .articleNo
            .map(object => object.value)
        : [];
        let yearQuarters = [{fyear: this.state.quarterData.selectedYear, quarter: this.state.quarterData.fiscal_quarter}]
        let calendarObj = {
            merchcat,
            vendor,
            ah6,
            articleNo,
            campaignId: [this.state.selectedPromo],
            yearQuarters,
            // dates: this.state.quarters.map(object => {
            //     return {startDate: object.startDate, endDate: object.endDate};
            // }),
            screenName: "calendar",
        }

       this.props.actionDownloadPromoCalendar(calendarObj);
    }
    submitGA(action,label){
        PromotionCalendarGA({ action, label});
      }
    render() {
        const {viewMode, showPromotionMain} = this.state
        const {loader} = this.props
        const viewProperties = {
            enableCompaign: true,
            viewMode: viewMode

        }
        // we have noticed calendarStrucure props getting changed somewhere.
        const calendarStrucure = JSON.parse(JSON.stringify(this.props.calendarStrucure));
        if (this.state.selectedDate) {
            //debugger
            var newDate = (new Date(this.state.selectedDate)).toLocaleDateString('en-US', {"year": 'numeric'})
            var newDate2 = Number(newDate); // + 1

            if (monthNames.includes(this.state.selectedMonth) && monthNames.indexOf(this.state.selectedMonth) === 11 && this.state.navigated === 'next-month') {
                newDate2 += 1
            } else if (monthNames.includes(this.state.selectedMonth) && monthNames.indexOf(this.state.selectedMonth) === 0 && this.state.navigated === 'prev-month') {
                newDate2 -= 1
            }
            var newDate1 = new Date((newDate2).toString())

            //this.state.selectedMonth
        }

        let DynamicFiltersProps = [
            {
                type: 'MultiSelectCheckboxes',
                placeholder: 'Select Merchcat',
                state: this.state.merchcat,
                mappingKey: 'merchcat',
                onMenuCloseApi: true,
                grid:'col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3',
                label:'Merchcat',
            }, {
                type: 'MultiSelectCheckboxes',
                placeholder: 'Select AH6',
                state: this.state.ah6,
                mappingKey: 'ah6',
                onMenuCloseApi: true,
                grid:'col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3',
                label:'AH6',
            }, {
                type: 'MultiSelectCheckboxes',
                placeholder: 'Select Vendor',
                state: this.state.vendor,
                mappingKey: 'vendor',
                onMenuCloseApi: true,
                grid:'col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2',
                label:'Vendor'
            },
            {
                type: 'MultiSelectCheckboxes',
                placeholder: 'Select Article',
                state: this.state.articleNo,
                mappingKey: 'articleNo',
                onMenuCloseApi: false,
                grid:'col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2',
                label:'Article'
            }, 
            {
                type: 'Select',
                placeholder: 'Select Fiscal Year',
                state: this.state.quarters,
                mappingKey: 'quarters',
                onMenuCloseApi: false,
                grid:'col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2' ,
                label : 'Fiscal Year'
            }
            // {
            //     type: 'DatePicker',
            //     placeholder: 'Select Fiscal Year & Month',
            //     state: this.state.selectedDate,
            //     grid:'col-md-3',
            //     label:'Year'
            // }
        ];

        let DynamicFiltersOptions = {
            merchcatOptions: [],
            ah6Options: [],
            vendorOptions: [],
            articleNoOptions: [],
            quartersOptions:[]
        }

        const { merchcat, ah6, vendor, articleNo, quarters} = this.state;
        let hierarchyLevel = ['merchcat', 'ah6', 'vendor','articleNo'];
        let hierarchyApiCalls = [{
            optionsMappingKey: 'merchcat',
            UrlName:'merchcat'
        },{
            optionsMappingKey: 'quarters',
            UrlName:'selectedYear'
        }]
        let hierarchyFilterApi = 'merchcatFilter';
        return (
          <PageLoader loader={this.props.loader || this.state.loader}>
            {!this.state.showPromotionMain ? (
              <div >
                  <div
                    className="card CalendarMain promoCalendarDiv1"
                    id="promocalendar-maincontainer"
                  >
                   
                      <h4 className="promoCal-brdcrm mb-10">
                        <span className="font-bold color-blue">
                          Promotion Calendar
                        </span>
                      </h4>
                    <div className="row filter-block">
                      <DynamicFilters
                        filtersList={DynamicFiltersProps}
                        options={DynamicFiltersOptions}
                        hierarchyLevel={hierarchyLevel}
                        hierarchyApiCalls={hierarchyApiCalls}
                        hierarchyFilterApi={hierarchyFilterApi}
                        openLoader={this.props.openLoader}
                        closeLoader={this.props.closeLoader}
                        updateParentState={this.updateState}
                        filterData={this.filterData}
                        onReset={this.onRefresh}
                        viewType="verticle"
                        accessChildState={this.accessChildState}
                        isFilterButtonDisabled={
                            merchcat.length === 0 ||
                            ah6.length === 0 ||
                            vendor.length === 0 ||
                            articleNo.length === 0 ||
                            quarters.length === 0
                          }
                      />
                    </div>
                    <hr
                      className="dotted-line"
                      style={{ marginTop: "-14px" }}
                    />{" "}
                    {this.state.calendarWrapperArr.length ? (
                      <div className="cal-gap">
                        <CalendarWrapper
                          showPromotionMain={this.showPromotionMain}
                          onTabClick={this.showPromotionPipeline}
                          selectedYear={Number(this.state.selectedYear)}
                          calendarDataArr={this.props.calendarDataPerYear}
                          calendarWrapperArr={this.state.calendarWrapperArr}
                          selectedDepartment={this.state.selectedDepartment}
                          selectedCategory={this.state.selectedCategory}
                          selectedSubCategory={this.state.selectedSubCategory}
                          selectedSKU={this.state.selectedSKU}
                          fiscal_monthnum={this.state.fiscal_monthnum}
                          fiscal_quarter={this.state.fiscal_quarter}
                          quaterFunction={this.toggleMonthQuarter}
                          markdownlinkShow={this.state.markdownlinkShow}
                          navCallBack={this.navCallBack}
                          view={this.state.selectedView}
                          calendarStrucure={calendarStrucure}
                        />
                      </div>
                    ) : this.props.loader === true ? (
                      <div
                        className="Row"
                        height="200"
                        align="middle"
                        style={{
                          justifyContent: "center",
                          padding: "2rem 0",
                          borderTop: "1px solid #ddd",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        Please wait while data is loading...
                      </div>
                    ) : (
                      <div
                        className="Row"
                        height="200"
                        align="middle"
                        style={{
                          justifyContent: "center",
                          padding: "2rem 0",
                          borderTop: "1px solid #ddd",
                          borderBottom: "1px solid #ddd",
                        }}
                      >
                        No data to show!
                      </div>
                    )}
                    
                  </div>
                  
                  {/* {this.renderPromotionPipeline(viewProperties)} */}
                  
                    <div >
                        <PromoHierarchyTable
                        rowData={this.props.promosList}
                        promoPipeLineObj={this.state.promoPipeLineObj}
                        handleDownload={this.handleDownloadPromotion}
                        campaignId={this.state.selectedPromo}
                    />
                    </div> 

                <div className="mtop">
                  <div className="alertDivs">
                    {this.state.generateCadenceError &&
                      !this.props.loader &&
                      this.state.promosList.length === 0 && (
                        <AlertDanger
                          message="No Data Found"
                          show={(() => {
                            setTimeout(() => {
                              this.setState({ generateCadenceError: false });
                            }, 5000);
                            return this.state.generateCadenceError;
                          })()}
                        />
                      )}
                  </div>
                </div>
              </div>
            ) : null}
          </PageLoader>
        );
    }
}

const mapStateToProps = state => {

    return {view: state.calendar.view, calendarStrucure: state.calendar.calendarStrucure, calendarDataPerYear: state.calendar.calendarDataPerYear, loader: state.calendar.loader, promosList: state.calendar.promoMertricList};
};

const mapDispatchToProps = dispatch => {
    return {
        calendarDatePlot: val => dispatch(calendarDatePlot(val)),
        calendarData: val => dispatch(calendarData(val)),
        getshowPromotionPipelineData: val => dispatch(getshowPromotionPipelineData(val)),
        getPromoArticleLevel: val => dispatch(getPromoArticleLevel(val)),
        openLoader: val => dispatch(openLoader()),
        closeLoader: val => dispatch(closeLoader()),
        deleteKey: val => dispatch(deleteKey()),
        actionDownloadPromoCalendar: val => dispatch(actionDownloadPromoCalendar(val)),
        resetCalendarData:val => dispatch(resetCalendarData())
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(PromoCalendar));
