import styled from 'styled-components';
import { Dropdown } from 'react-bootstrap';

export const BudgetPlanTableTitleContent = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 2rem;
  color: gray;
  margin: 1.29rem 0;
`;

export const DropdownToggle = styled(Dropdown.Toggle)`
:after {
  top: 15px !important;
  right: 12px !important;
}
`;