import React, {Component} from "react";
import _ from "lodash";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import "./promoCalendarStyle.css";
import classNames from "classnames";
import moment from 'moment';
import {getDaysCount} from "../../utils/helperFunctions";
import {MONTH_NAMES} from './constants';

import CalendarWrapperWeeks from "./calendarWrapperWeeks";
import CalendarWrapperBlocks from "./calendarWrapperBlocks";
import CalendarWrapperToggleComponent from "./calendarWrapperToggleComponent";
// const colors = ["#64C2DE", "#E32A36", "#81E28B", "#FF8D0F", "#B564DE",
// "#FC4586", "#3D4CD6", "#E9D339", "#FC5A2F"]

class CalendarWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {
            styleMargin: [],
            showRecommendedPromoToolTip: false,
            showRecommendedPromoMarkToolTip: false,
            tooltipId: -1,
            tooltipMarkId: -1,
            isUpdateRequired: true
        };
        this.Month = MONTH_NAMES;
    }
    componentDidUpdate(old, prevState) {
        let {calendarDataArr, calendarWrapperArr} = this.props;

        if (old.calendarDataArr !== calendarDataArr || old.calendarWrapperArr !== calendarWrapperArr) {
            if (calendarDataArr && calendarDataArr.length) {
                this.getPromotionWRange();
            }
        }
    }

    getPromotionWRange = () => {
        let {calendarWrapperArr, calendarDataArr} = this.props;

        let styleMargin = [];
        let startDate = calendarWrapperArr[0].start_date;
        let endDate;
        if (calendarWrapperArr[calendarWrapperArr.length - 1].fiscal_week == 52 && calendarWrapperArr[calendarWrapperArr.length - 1].fiscal_quarter == 1) {
            endDate = calendarWrapperArr[calendarWrapperArr.length - 2].end_date;
        } else {
            endDate = calendarWrapperArr[calendarWrapperArr.length - 1].end_date;
        }

        let diffDays = getDaysCount(startDate, endDate);
        if (calendarDataArr && calendarDataArr.length > 0) {
            calendarDataArr.forEach(ele => {
                var dt = new Date(ele.start_date);
                // let marginEndDate = new Date(dt.setDate(dt.getDate() + 1)).toString();
                let marginEndDate;
                let width = 0;

                if (new Date(ele.start_date).getMonth() === new Date(ele.end_date).getMonth() && new Date(ele.start_date).getDate() == new Date(ele.end_date).getDate()) {
                    width = 1;
                    marginEndDate = new Date(dt.setDate(dt.getDate())).toString();
                } else {
                    marginEndDate = new Date(dt.setDate(dt.getDate() + 1)).toString();
                    if (calendarWrapperArr[0].start_date > ele.start_date) {
                        width = getDaysCount(calendarWrapperArr[0].start_date, ele.end_date) + 1;
                    } else {
                        width = getDaysCount(ele.start_date, ele.end_date) + 1;
                    }
                }

                // let width = this.getDaysCount(ele.start_date, ele.end_date);
                let margin = getDaysCount(calendarWrapperArr[0].start_date, marginEndDate);

                if (margin < 0) {
                    margin = 0;
                } else {
                    margin = margin - 1;
                }
                let flexBasis = (width / diffDays) * 100;
                let marginLeft = (margin / diffDays) * 100;
                if (100 - marginLeft < flexBasis) {
                    flexBasis = 100 - marginLeft;
                }

                styleMargin.push({flexBasisInt: flexBasis, flexBasis: `${flexBasis}%`, marginLeft: `${marginLeft}%`});
            });
        }
            
        this.setState(state => {
            return {styleMargin: styleMargin,isUpdateRequired:false};
        }); 
    };

    getRecommendedData = (e, info) => {
        e.stopPropagation();
        e.preventDefault();
        let campaignID = info.id;
        let startDate = info.start_date;
        let endDate = info.end_date;

        let depData = this.props.selectedDepartment;
        let catData = this.props.selectedCategory;
        let subCatData = this.props.selectedSubCategory;
        let url = "/vis/recommend/promo?campaignID=" + campaignID + "&startDate=" + startDate + "&endDate=" + endDate;
        let queryArray = [];
        if (depData) {
            queryArray.push("department=" + depData.value);
        }
        if (catData) {
            queryArray.push("category=" + catData.value);
        }
        if (subCatData) {
            queryArray.push("subCategory=" + subCatData.value);
        }
        if (queryArray.length > 0) {
            url = url + "&" + queryArray.join("&");
        }
        if (this.props.markdownlinkShow == 1) {
            let newURl = "/vis/recommended";
            window
                .location
                .assign(newURl);
        } else {
            window
                .location
                .assign(url);
        }
    };

    getRecommendedMarkData = (e, info) => {
        e.stopPropagation();
        e.preventDefault();
        let markdownId = info.mrk_id;

        let url = "/vis/Markdown/global-promo?markdownID=" + markdownId;

        this
            .props
            .history
            .push(url);
    };
    toogleRecommendedPromoToolTip = id => {
        if (this.state.showRecommendedPromoToolTip) {
            this.setState({showRecommendedPromoToolTip: false, tooltipId: -1});
        } else {
            this.setState({showRecommendedPromoToolTip: true, tooltipId: id});
        }
    };

    toogleRecommendedPromoMarkdownToolTip = id => {
        if (this.state.showRecommendedPromoMarkToolTip) {
            this.setState({showRecommendedPromoMarkToolTip: false, tooltipMarkId: -1});
        } else {
            this.setState({showRecommendedPromoMarkToolTip: true, tooltipMarkId: id});
        }
    };

    render() {
        if(this.state.isUpdateRequired && this.props.calendarDataArr.length > 0){
            this.getPromotionWRange();
        }
        return (
            <div>
                <div className="calendarWeek row">
                    <div align="left" className="col-5">
                        <div
                            align="left"
                            className=""
                            style={{
                            marginTop: "4px"
                        }}>
                            <span className="calendar-color-description">
                                {this.props.markdownlinkShow == 1
                                    ? "Completed Markdown"
                                    : "Completed campaign"}
                            </span>
                            <span className="calendar-color-description">
                                {this.props.markdownlinkShow == 1
                                    ? "Ongoing Markdown"
                                    : "Ongoing campaign"}
                            </span>
                            <span className="calendar-color-description">
                                {this.props.markdownlinkShow == 1
                                    ? "Future Markdown"
                                    : "Future campaign"}
                            </span>
                        </div>
                    </div>
                    <div
                        align="left"
                        className="col-md-3 centreCalendarText"
                        style={{
                        color: "black"
                    }}>
                        <CalendarWrapperToggleComponent
                            calendarWrapperArr={this.props.calendarWrapperArr}
                            selectedYear={this.props.selectedYear}
                            view={this.props.view}
                            quaterFunction={this.props.quaterFunction}
                            calendarDataPerYear={this.props.calendarDataArr}
                            calendarStrucure={this.props.calendarStrucure}
                            selectedView={this.props.view}
                            fiscal_monthnum={this.props.fiscal_monthnum}
                            fiscal_quarter={this.props.fiscal_quarter}
                            navCallBack={this.props.navCallBack}
                            markdownlinkShow={this.props.markdownlinkShow}/>
                    </div>

                    <div className="col-md-4 text-right BtnGroupRow">
                        <div
                            id="promoCal"
                            className="divInn btn-group "
                            style={{
                            textAlign: "center"
                        }}>
                            <button
                                type="button"
                                className={classNames({
                                active: this.props.view == "Month",
                                " btn": true,
                                "btn-outline-primary cal-btn": true,
                                btnCalendr: true,
                                calPromo: true
                            })}
                                onClick={() => this.props.quaterFunction("Month")}
                                style={{
                                width: "90px"
                            }}>
                                Month
                            </button>
                            <button
                                type="button"
                                className={classNames({
                                active: this.props.view == "Quarter",
                                " btn": true,
                                "btn-outline-primary cal-btn": true,
                                btnCalendr: true,
                                calPromo: true
                            })}
                                onClick={() => this.props.quaterFunction("Quarter")}
                                style={{
                                width: "90px"
                            }}>
                                Quarter
                            </button>
                        </div>
                    </div>
                </div>

                <div className="CalendarWrapper">
                    <CalendarWrapperWeeks calendarWrapperArr={this.props.calendarWrapperArr}/> 
                    {this.props.calendarDataArr && this.props.calendarDataArr.length 
                        ? (<CalendarWrapperBlocks
                            calendarDataArr={this.props.calendarDataArr}
                            styleMargin={this.state.styleMargin
                            ? this.state.styleMargin
                            : []}
                            showRecommendedPromoToolTip={this.state.showRecommendedPromoToolTip}
                            tooltipId={this.state.tooltipId}
                            toogleRecommendedPromoToolTip={this.toogleRecommendedPromoToolTip}
                            markdownlinkShow={this.props.markdownlinkShow}
                            showPromotionMain={this.showPromotionMain}
                            showPromotionMainWithValues={this.showPromotionMainWithValues}
                            onTabClick={this.props.onTabClick}
                            showLoading={this.props.showLoading}
                            calendarWrapperArr={this.props.calendarWrapperArr}
                            getRecommendedData={this.getRecommendedData}
                            toogleRecommendedPromoMarkdownToolTip={this.toogleRecommendedPromoMarkdownToolTip}
                            getRecommendedMarkData={this.getRecommendedMarkData}
                            promoButtonClick={this.props.promoButtonClick}
                            promoDetailsHandler={this.props.promoDetailsHandler}
                            showRecommendedPromoMarkToolTip={this.state.showRecommendedPromoMarkToolTip}
                            tooltipMarkId={this.state.tooltipMarkId}/>)
                        : this.props.showLoading === true
                            ? (
                                <div
                                    className="Row"
                                    height="200"
                                    align="middle"
                                    style={{
                                    justifyContent: "center",
                                    padding: "2rem 0",
                                    borderTop: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd"
                                }}>
                                    Please wait while data is loading...
                                </div>
                            )
                            : (
                                <div
                                    className="Row"
                                    height="200"
                                    align="middle"
                                    style={{
                                    justifyContent: "center",
                                    padding: "2rem 0",
                                    borderTop: "1px solid #ddd",
                                    borderBottom: "1px solid #ddd"
                                }}>
                                    No data to show!
                                </div>
                            )}
                </div>
            </div>
        );
    }
}

const mapStateToProps = state => ({showLoading: state.calendar.loader});

const mapDispatchToProps = dispatch => ({
    // getGlobalPromoPerMarkdown: params =>
    // dispatch(getGlobalPromoPerMarkdown(params))
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(CalendarWrapper));
