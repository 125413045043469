import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logoutUser } from '../../actions/auth';
import { clearCurrentChartdata } from '../../actions/chart';
import { someAction } from '../../actions/auth';
import './sideBarStyle.css';
import { pageLinks, sideBarNames } from './constants';

class SideBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      class: '',
      display: 'none'
    };
  }
  toggleSideBarExpansionHandler = expand => {
    if (expand === true || this.state.class !== 'active') {
      this.setState({ class: 'active', display: 'inline-block' });
      return;
    }
    if (this.state.class === 'active') {
      this.setState({ class: '', display: 'none' });
    }
  };
  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  handleClickOutside =(event) =>{
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.class === 'active') {
        this.setState({ class: '', display: 'none' });
      }
    }
  }

  //ref to sidebar parent node
  setWrapperRef = node => {
    this.wrapperRef = node;
  };

  render() {
    let sidebarList = sideBarNames.filter(obj=> this.props.userInfo.indexOf(obj.accessKey)!==-1)
    return (
      <nav
        id="sidebar"
        className={this.state.class}
        ref={this.setWrapperRef}
        onClick={this.toggleSideBarExpansionHandler}
      >
        <div className="sidebar-toggle-arrow">
          {this.state.class === 'active' ? (
            <i
              className="fa fa-angle-double-left sidebar-toggle-arrow-icon sidebar-toggle-arrow-left-icon"
              aria-hidden="true"
            ></i>
          ) : (
            <i
              className="fa fa-angle-double-right sidebar-toggle-arrow-icon sidebar-toggle-arrow-right-icon"
              aria-hidden="true"
            ></i>
          )}
        </div>
        <div className="sidebar-header" />
        <ul className="list-unstyled components ">
          {sidebarList.map((navObj, index) => (
            <li key={index}>
              <NavLink
                activeClassName="active"
                to={navObj.labelUrl}
                onClick={this.toggleSideBarExpansionHandler}
                title={navObj.labelName}
              >
                <img className="imgIcons" src={require(`${navObj.logoUrl}`)} />
                <span
                  className="hidetext fontstyling"
                  style={{
                    display: this.state.display
                  }}
                >
                  {navObj.labelName}
                </span>
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    );
  }
}

SideBar.propTypes = {
  logoutUser: PropTypes.func.isRequired,
  authReducer: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  authReducer: state.authReducer,
  userInfo: state.userManagementData.userInfo.pages
});

export default withRouter(
  connect(mapStateToProps, { logoutUser, clearCurrentChartdata, someAction })(
    SideBar
  )
);
