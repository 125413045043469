import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import SideBarNew from '../../components/SideBar/SideBar';
import Header from '../../components/Header/Header';
import { Route, Switch, Redirect } from 'react-router-dom';
import BudgetPlanning from '../BudgetPlanning';
import FinalizePromotion from '../FinalizePromotion';
import CreatePromo from '../CreatePromo';
import PlanSummary from '../PlanSummary';
import SkuMaster from '../SkuMaster';
import AddSku from '../SkuMaster/AddSku';
import BudgetMaster from '../BudgetMaster';
import PromoCalendar from '../PromoCalendar';
import UserManagement from '../UserManagement';
import '../../assets/PlannedPromoPipeline.css';
import '../../assets/promocalendarstyling.css';
import '../../assets/daterange-picker.css';
import { connect } from 'react-redux';
import AnalyzeCampaign from '../AnalyzeEvent';
import historicalPerformance from '../HistoricalPerformance';
import PromoUpload from '../PromoUpload';
import { sideBarNames, headerRoutes } from '../SideBar/constants';
import Support from '../../lib/Support';
import VideoTutorials from '../VideoTutorials';
import Dashboard from '../Dashboard';
import Constraints from '../Constraints';

class Layout extends Component {
  render() {
    const { path } = this.props.match;
    const { pathname } = this.props.location;
    return (
      <div className="wrapper" style={{ background: '#F0F4F7' }}>
        <SideBarNew />
        <div id="content">
          <Header userInfo={this.props.userInfo} />

          <Switch>
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              access_key="df62f5d8658402d797cc0e2f86efbd85d340919a"
              path={`/vis/support`}
              component={Support}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              path={`${path}/Promo`}
              component={PromoCalendar}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              path={`${path}/analyze-campaign`}
              component={AnalyzeCampaign}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              path={`${path}/historical-performance`}
              component={historicalPerformance}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              path={`${path}/promo-upload`}
              component={PromoUpload}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              path={`${path}/budget-planning`}
              component={BudgetPlanning}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              path={`${path}/plan-summary`}
              component={PlanSummary}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              path={`${path}/budget-master`}
              component={BudgetMaster}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              path={`${path}/finalize-promotion`}
              component={FinalizePromotion}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              parentPath
              path={`${path}/create-new-promotion`}
              component={CreatePromo}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              path={`${path}/sku-master`}
              component={SkuMaster}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              path={`${path}/add-sku`}
              component={AddSku}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              exact
              path={`${path}/edit-sku/:id`}
              component={AddSku}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              exact
              path={`${path}/admin`}
              component={UserManagement}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              exact
              path={`${path}/video-tutorials`}
              component={VideoTutorials}
            />
            <ProtectedRoute
              pathname={pathname}
              userInfo={this.props.userInfo}
              exact
              path={`${path}/executive-summary`}
              component={Dashboard}
            />
             <ProtectedRoute
            pathname={pathname}
            userInfo={this.props.userInfo}
            exact
            path={`${path}/constraints`}
            component={Constraints}
          /> 
            <Redirect from="/vis" to="/vis/historical-performance" />
          </Switch>
        </div>
      </div>
    );
  }
}

const ProtectedRoute = ({ component: Component, ...rest }) => {
  if (rest.pathname.indexOf('/add-sku') !== -1 || rest.pathname.indexOf('/edit-sku') !== -1) {
    rest.pathname = '/vis/sku-master';
  }
  if (rest.pathname.indexOf('/create-new-promotion') !== -1) {
    const userInfo = rest.userInfo.pages.filter(
      (obj) => ['budget-planning', 'finalize-promotion'].indexOf(obj) !== -1
    );
    rest.pathname = sideBarNames.filter((obj) => obj.accessKey === userInfo[0])[0].labelUrl;
  }
  let toUrl;
  if (rest.userInfo.pages.length > 0) {
    toUrl = sideBarNames.filter((obj) => rest.userInfo.pages[0] === obj.accessKey)[0].labelUrl;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const authorizedRoutes = [...sideBarNames, ...headerRoutes].filter(
          (obj) => obj.labelUrl === rest.pathname
        );
        if (
          authorizedRoutes.length > 0 &&
          rest.userInfo.pages.indexOf(authorizedRoutes[0].accessKey) !== -1
        ) {
          const allProps = { ...props, ...rest };
          return <Component {...allProps} />;
        } else {
          if (toUrl) {
            return <Redirect from="/" to={toUrl} />;
          } else {
            return <div>You are not authorized</div>;
          }
        }
      }}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    userInfo: state.userManagementData.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Layout));

// export default withRouter(Layout);
