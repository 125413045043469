
import React, { useState, useEffect } from 'react';
import { getDisStateFrmStatus } from "../../utils/helperFunctions"

const RadioButtons = props => {
    const [isChecked, setIsChecked] = useState();

  // useEffect(() => {
  //   console.log("========setChecked=========", props.data, props.data.isChecked);
  //   setIsChecked(props.data.isChecked);
  // }, [props.data])

  const selectedPromo = () => {
    // setIsChecked(!isChecked);
    const obj = {
        isCheck: !isChecked,
        data: props.data,
        tableData: props.agGridReact.props.rowData
    }
    props.selectPromo(obj);
  };
  return (
    <div className='promo-radio-buttons'>
      <input
        type='radio'
        checked={props.data.isChecked}
        onChange={selectedPromo}
        name='discount'
        disabled={getDisStateFrmStatus(props.promoStatus)}
      />
    </div>
  );
};

export default RadioButtons;