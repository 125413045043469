import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PageLoader from '../../utils/Loader'
import VideosList from './VideosList'
import { getVidosList} from '../../actions/userManagementAction';

class VideoTutorials extends Component {
  constructor (props) {
    super(props)

  }

  componentWillMount () {
    this.props.getVideos();
  }

  render () {
    return (
      <div>
        <PageLoader loader={this.props.loader}>
          <div className='card CalendarMain promoCalendarDiv1' id='promocalendar-maincontainer'>
            <h4 className='promoCal-brdcrm mb-10'>
              <span className='font-bold'>Video Tutorials</span>
            </h4>
            <hr />
            <VideosList list={this.props.videosList} />
          </div>
        </PageLoader>
      </div>
    )
  }
}



const mapStateToProps = state => ({
  videosList: state.userManagementData.videosList,
  loader: state.userManagementData.loading
});

const mapDispatchToProps = dispatch => {
  return {
    getVideos: value => dispatch(getVidosList(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(VideoTutorials));


