export const PROMO_LEVEL = 'PROMO_LEVEL';
export const PROMO_ARTICLE_LEVEL = 'PROMO_ARTICLE_LEVEL';
export const DMM_LEVEL = 'DMM_LEVEL';
export const OFFER_LEVEL = 'OFFER_LEVEL';

export const charMapping = {
  A: 'a0',
  B: 'a1',
  C: 'a2',
  D: 'a3',
  E: 'a4',
  F: 'a5',
  G: 'a6',
  H: 'a7',
  I: 'a8',
  J: 'a9',
  K: 'b0',
  L: 'b1',
  M: 'b2',
  N: 'b3',
  O: 'b4',
  P: 'b5',
  Q: 'b6',
  R: 'b7',
  S: 'b8',
  T: 'b9',
  U: 'c0',
  V: 'c1',
  W: 'c2',
  X: 'c3',
  Y: 'c4',
  Z: 'c5',
  a: 'c6',
  b: 'c7',
  c: 'c8',
  d: 'c9',
  e: 'd0',
  f: 'd1',
  g: 'd2',
  h: 'd3',
  i: 'd4',
  j: 'd5',
  k: 'd6',
  l: 'd7',
  m: 'd8',
  n: 'd9',
  o: 'e0',
  p: 'e1',
  q: 'e2',
  r: 'e3',
  s: 'e4',
  t: 'e5',
  u: 'e6',
  v: 'e7',
  w: 'e8',
  x: 'e9',
  y: 'f0',
  z: 'f1',
  '0': 'f2',
  '1': 'f3',
  '2': 'f4',
  '3': 'f5',
  '4': 'f6',
  '5': 'f7',
  '6': 'f8',
  '7': 'f9',
  '8': 'g0',
  '9': 'g1',
  '!': 'g2',
  '#': 'g3',
  $: 'g4',
  '%': 'g5',
  '&': 'g6',
  '*': 'g7',
  "'": 'g8',
  '+': 'g9',
  '-': 'h0',
  '/': 'h1',
  '=': 'h2',
  '?': 'h3',
  '^': 'h4',
  _: 'h5',
  '`': 'h6',
  '{': 'h7',
  '|': 'h8',
  '}': 'h9',
  '~': 'i0',
  '@': 'i1',
  '.': 'i2',
};
