import React, { useState } from "react";
import { getDisStateFrmStatus } from "../../utils/helperFunctions";
import NumberFormat from 'react-number-format';

const InputButton = props => {
  const [val, setVal] = useState(props.value);

  const handleValueChange = val => {
    setVal(val.value);
    const obj = {
      value: val.value,
      data: props.data,
      tableData: props.agGridReact.props.rowData
    };

    props.handlePromoValueChange(obj);
  };
  const validatePromoValue = () => {
    // setVal(val.value);
    const tableData = props.agGridReact.props.rowData;
    const data = props.data
    const value = val
    const tempData = tableData.map(row => {
      if (row.id === data.id) {
        row.promoValue = value;
      }
      return row;
    });
    const promoValues = tempData.map(row => {
      return row.promoValue;
    });
    const regularPriceval = props.articleNumbers.map(data => {
      return {
        regularPrice: data.regularPrice  
      };
    });
    var regMin = regularPriceval.filter((v) => {
     return  (v.regularPrice !== null && v.regularPrice !== 0)
    })
      var min = Math.min.apply(null, regMin.map((a) => a.regularPrice)) 
      var target = promoValues.forEach(a => {
          if(a > min) {
            props.handlePromoValueError("Target Promotion value cannot be greater than Regular price");
          }
      })
    const duplicateCount = tableData.reduce((count, el) => {
      if (el.promoValue === val) count++;
      return count;
    }, 0);
    if (duplicateCount > 1) {
      props.handlePromoValueError("Duplicate PromoValue");
    }
  };
  return (
    <div className="input-button">
      <div className="input-group mb-3">
        <NumberFormat 
          className="form-control"
          placeholder="promo Value"
          value={val} 
          thousandSeparator={true} 
          onValueChange={values => {handleValueChange(values)}}
          onBlur={values => {validatePromoValue(values)}}
          disabled={getDisStateFrmStatus(props.promoStatus)}
          allowNegative={false}
                />
        <div class="input-group-prepend">
          <span class="input-group-text" id="basic-addon1">
            $
          </span>
        </div>
      </div>
    </div>
  );
};

export default InputButton;
