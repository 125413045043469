import {GET_CAMPAIGN_LIST,GET_LOGS,LOADER, UPLOAD_DATA, LOGS_COLUMNS, LOGS_ROWS, SET_COLUMNS, SET_ROWS} from "./actionTypes";

const initialState = {
    campaignsList: [],
    loader: false,
    data:[],
    logsData:[],
    columns:[],
    rows:[],
    initialColumns:[],
    initialRows:[],
    uploadData:{}
}

export default function (state = initialState, action) {
    switch (action.type) {
        case LOADER:
            return {
                ...state,
                loader: action.payload
            };
        case GET_CAMPAIGN_LIST:
            let resData = action.payload
            return {
                ...state,
                campaignsList: resData
            }
        case UPLOAD_DATA:
            return{
                ...state,
                uploadData: action.payload
            }
        case GET_LOGS:
            return{
                ...state,
                logsData:action.payload
            }
        case SET_COLUMNS:
            return{
                ...state,
                initialColumns:action.payload
            }
        case SET_ROWS:
            return{
                ...state,
                initialRows:action.payload
            }
        case LOGS_ROWS:
            return{
                ...state,
                rows:action.payload
            }
        case LOGS_COLUMNS:
            return{
                ...state,
                columns:action.payload
            }
        default:
             return {
                ...state
            };
    }
}