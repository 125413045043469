import { AUTO_PROMO_DATA, AUTO_GET_NEW_OPTIMIZED_DATA, SKU_IDS,  GET_HISTORICAL_DATA } from "../actions/types";

const initialState = {
  autoPromoData: {},
  loading: false,
  skuIds: [],
  historical_data: []
};

export default function(state = initialState, action) {
  switch (action.type) {
    case AUTO_PROMO_DATA:
      return {
        ...state,
        autoPromoData: action.payload
      };
    case AUTO_GET_NEW_OPTIMIZED_DATA:
      return {
        ...state,
        optimizedData: action.payload
      };
      case SKU_IDS:
      return {
        ...state,
        skuIds: action.payload
      };
      case  GET_HISTORICAL_DATA:
        return {
          ...state,
          historical_data: action.payload
        };
     

    default:
      return state;
  }
}
