import axios from "axios";
import store from '../store/store';
import environment from "../environments/environment";
import  {SessionExpiredRedirection} from '../actions/userManagementAction'
export const callApi = axios.create({
  baseURL: `${environment.baseUrl}/`,
});

const refreshToken = (num) => new Promise((resolve, reject) => {  
  let token;
  const interval = setInterval(function() {
    // method to be executed;
    token = localStorage.getItem("isSessionExpired");
    if(token === 'false'){
      // localStorage.removeItem("isSessionExpired");
      clearInterval(interval)
      resolve(true)
    }
    }, 5000);
    
  });  

// Add a request interceptor
callApi.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    // console.log("api config...", config);
    return config;
  },
  function(error) {
    // Do something with request error
    console.log("request fails with error", error.message);
    return Promise.reject(error);
  }
);

callApi.interceptors.response.use(
  function(res) {
    return res;
  },
  async function(error) {
    const {
      response: { status },
    } = error;
    console.log(error ,status)
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const {dispatch} = store; // direct access to redux store.
    console.log("response fails with error", error.message,error.config);
    if (status === 401) {
      dispatch(SessionExpiredRedirection());
      const isAuthenticated = localStorage.getItem('isAuthenticated')
         console.log("Authenticate Request ",isAuthenticated)
         if(isAuthenticated === 'true'){
           localStorage.removeItem("isAuthenticated");
         }else{
           console.log("Rmeoved Token")
           localStorage.removeItem("isAuthenticated");
           window.location.href = `${environment.baseUrl}/user/login`;
           return;
         }
    //  setTimeout(() => {
    //   window.location.href = `${environment.baseUrl}/user/login`;
    //  }, 10);
      return;
    }else{
      return Promise.reject(error);
    }
    
  }
);
