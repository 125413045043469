import React, { useState, useEffect } from 'react';
import ConstaintDiscountDepth from './constraintsDiscountDepth';
import NumericInput from 'react-numeric-input';
import { quarterlyDiscountCycleOptions } from './constants';
import { filter } from 'lodash';

const MinMaxCycleDiscountDepth = (props) => {
  const {
    discountDepthList,
    updateDiscountDepth,
    updateDiscountCycles,
    discountCycleOptions,
    discountDepth,
    applyDiscountDepth,
    discountValues,
    error,
    activeDiscountChangedKey,
    promoError,
    minCycleError,
    maxCycleError,
    minCycle,
    selectMinCycle,
    maxCycle,
    selectMaxCycle,
    article,
    quarterlyMinCycle,
    quarterlyMaxCycle,
    viewType,
    minValueWarning,
    maxValueWarning
  } = props.minMaxProps;
  const [ddOptions, setDdOptions] = useState([]);
  const [maxPromoLimit, setMaxPromoLimit] = useState(20);
  const [maxPromo, setMaxPromo] = useState([]);
  const [minPromo, setMinPromo] = useState([]);
  const [discountDepthTableData, setDiscountDepthTableData] = useState(discountDepth);

  const sortDiscountTable = (discountDepths) => {
    return discountDepths.sort((a, b) => (parseInt(a.cycle.substring(3)) - parseInt(b.cycle.substring(3))))
  }

  const getYearlyDiscountDepth = () => {
    return discountDepth.filter(item => item.quarter == 0)
  }

  const getQuarterDiscountDepth = discountDepthOptionsArr => {
    let keys = discountDepthOptionsArr.map(ele => ele.value);
    let filteredData = discountDepth.filter(ele => {
      if (keys.includes(ele.cycle)) {
        if (viewType == 'quarterly' && ele.quarter != 0) {
          return true
        } else {
          return false
        }
      } else {
        return false
      }
    });
    return filteredData;
  }
  useEffect(() => {
    if (viewType == 'quarterly' && props.selectedQuarter.length > 0) {
      let quarterDDOptions = quarterlyDiscountCycleOptions[props.selectedQuarter];
      const quarterDiscountDepth = getQuarterDiscountDepth(quarterDDOptions);
      let tempDDList = discountDepthList.DISCOUNT_DEPTH_LIST;
      tempDDList.forEach(element => {
        if(element.mappingKey === "fixedDiscount"){
          let tempOtions = quarterDDOptions.filter(ele => element.cycleOptions.some(option => option.value === ele.value))
          element.cycleOptions = tempOtions
        }else{
        element.cycleOptions = quarterDDOptions}
      });
      setDiscountDepthTableData(quarterDiscountDepth)
      setDdOptions(tempDDList);
    } else {
      setDdOptions(discountDepthList.DISCOUNT_DEPTH_LIST);
      setDiscountDepthTableData(getYearlyDiscountDepth(discountDepth))
    }
  }, [discountDepthList.DISCOUNT_DEPTH_LIST]);

  useEffect(() => {
    if (viewType == 'quarterly' && props.selectedQuarter.length > 0) {
      let quarterDDOptions = quarterlyDiscountCycleOptions[props.selectedQuarter];
      quarterlyMaxCycle[0] && !(quarterlyMaxCycle[0][props.selectedQuarter] == undefined || quarterlyMaxCycle[0][props.selectedQuarter] == null)
        ? setMaxPromo(quarterlyMaxCycle[0][props.selectedQuarter])
        : setMaxPromo([null]);
      (quarterlyMinCycle[0] && !(quarterlyMinCycle[0][props.selectedQuarter] == undefined || quarterlyMinCycle[0][props.selectedQuarter] == null)) 
        ? setMinPromo(quarterlyMinCycle[0][props.selectedQuarter])
        : setMinPromo([null]);
      setMaxPromoLimit(quarterDDOptions.length);
      let tempDDList = discountDepthList.DISCOUNT_DEPTH_LIST;
     tempDDList.forEach(element => {
        if(element.mappingKey === "fixedDiscount"){
          let tempOtions = quarterDDOptions.filter(ele => element.cycleOptions.some(option => option.value === ele.value))
          element.cycleOptions = tempOtions
        }else{
        element.cycleOptions = quarterDDOptions}
      });
      const quarterDiscountDepth = getQuarterDiscountDepth(quarterDDOptions);
      setDiscountDepthTableData(quarterDiscountDepth)
      setDdOptions(tempDDList);
    } else if (viewType == 'yearly'){
      setMaxPromoLimit(20);
      setMaxPromo(maxCycle);
      setMinPromo(minCycle);
      setDdOptions(discountDepthList.DISCOUNT_DEPTH_LIST);
      setDiscountDepthTableData(getYearlyDiscountDepth(discountDepth))
    }
  }, [props.selectedQuarter]);
  useEffect(() => {
    if (viewType == 'yearly') {
      setMinPromo(minCycle);
    }
  }, [minCycle]);
  useEffect(() => {
    if (viewType == 'yearly') {
      setMaxPromo(maxCycle);
    }
  }, [maxCycle]);
  useEffect(() => {
    if (viewType == 'quarterly' && props.selectedQuarter.length > 0) {
      quarterlyMaxCycle[0] && !(quarterlyMaxCycle[0][props.selectedQuarter] == undefined || quarterlyMaxCycle[0][props.selectedQuarter] == null)
        ? setMaxPromo(quarterlyMaxCycle[0][props.selectedQuarter])
        : setMaxPromo([null]);
    }
  }, [quarterlyMaxCycle]);
  useEffect(() => {
    if (viewType == 'quarterly' && props.selectedQuarter.length > 0) {
      (quarterlyMinCycle[0] && !(quarterlyMinCycle[0][props.selectedQuarter] == undefined || quarterlyMinCycle[0][props.selectedQuarter] == null)) 
        ? setMinPromo(quarterlyMinCycle[0][props.selectedQuarter])
        : setMinPromo([null]);
    }
  }, [quarterlyMinCycle]);
 
  return (
    <>
      <div className="row">
        <div className="col-3">
          <div className="row">
            <div className="col-6">Min Promos</div>
            <div className="col-6">
              <NumericInput
                className="form-control"
                min={0}
                max={maxPromoLimit}
                value={minPromo}
                onChange={selectMinCycle}
              />
            </div>
          </div>
        </div>
        <div className="col-3">
          <div className="row">
            <div className="col-6">Max Promos</div>
            <div className="col-6">
              <NumericInput
                className="form-control"
                min={0}
                max={maxPromoLimit}
                value={maxPromo}
                onChange={selectMaxCycle}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="errorMessage">{promoError}</div>
        <div className="errorMessage">{minCycleError}</div>
        <div className="errorMessage">{maxCycleError}</div>
        <div className="warningMessage">{minValueWarning}</div>
        <div className="warningMessage">{maxValueWarning}</div>
      </div>
      <div className="spacer"></div>
      {article.length === 1 && (
        <ConstaintDiscountDepth
          discountDepthList={ddOptions}
          updateState={updateDiscountDepth}
          updateDiscountCycles={updateDiscountCycles}
          options={discountCycleOptions}
          discountDepth={sortDiscountTable(discountDepthTableData)}
          applyDiscountDepth={applyDiscountDepth}
          discountValues={discountValues}
          isError={error}
          activeDiscountChangedKey={activeDiscountChangedKey}
          viewType={viewType}
          selectedQuarter={props.selectedQuarter}
        />
      )}
    </>
  );
};

export default MinMaxCycleDiscountDepth;
