import React, { Component } from "react";
import { Bar } from "react-chartjs-2";

const options = {
  plugins: {
    datalabels: {
      display: false
    }
  },
  scales: {
    xAxes: [
      {
        gridLines: {
          lineWidth: 0
        },
        scaleLabel: {
          display: true,
          labelString: "Year"
        },
        barPercentage: 0.3
      }
    ],
    yAxes: [
      {
        gridLines: {
          lineWidth: 0
        },
        scaleLabel: {
          display: true,
          labelString: "COGS"
        }
      }
    ]
  },
  legend: {
    position: "bottom",
    fontSize:12,
    labels: {
      boxWidth: 12
    }
  },
  maintainAspectRatio: false
};

class BarChart extends Component {
  render() {
    return (
      <div
        className="bar-chart"
        id="barChartContainer"
        style={this.props.height && { height: this.props.height }}
      >
        <Bar
      // width="400" 
        // height="450"
        id="display_numbers"
          data={this.props.data}
          options={this.props.options || options}
          responsive={true}
          getElementAtEvent={event =>
            this.props.triggerParentUpdate &&
            this.props.triggerParentUpdate(event)
          }
        />
      </div>
    );
  }
}

export default BarChart;
