export const pageLinks = {
  createPromo: ['/vis/auto-create-promo', '/vis/history-promo', '/vis/create-promo'],
  planningPromo: [
    '/vis/budgetplanning',
    '/vis/Promo',
    '/vis/planned-promo-pipeline',
    '/vis/finalize-promo',
    '/vis/promosettings',
    '/vis/analyze-promo',
    '/vis/analyze-campaign',
    '/vis/historical-performance',
    '/vis/budget-master',
    'vis/constraints'
  ],
  analysisPromos: ['/vis/EDA', '/vis/PromoView', '/vis/analyze-promo', '/vis/analyze-campaign'],
  questionsPromo: ['vis/QuIQ']
};

export const headerRoutes = [
  {
    labelName: 'Support',
    labelUrl: '/vis/support',
    accessKey: 'support'
  }
];

export const sideBarNames = [
  {
    labelName: 'Analyze Books',
    labelUrl: '/vis/analyze-campaign',
    logoUrl: './analyze-books-icon.svg',
    accessKey: 'analyze-books'
  },
  {
    labelName: 'Historical Performance',
    labelUrl: '/vis/historical-performance',
    logoUrl: './historical-performance.svg',
    accessKey: 'historical-performance'
  },
  {
    labelName: 'Promo PMR Upload',
    labelUrl: '/vis/promo-upload',
    logoUrl: './promoUpload.svg',
    accessKey: 'promo-upload'
  },
  {
    labelName: 'SKU Master',
    labelUrl: '/vis/sku-master',
    logoUrl: './sku-master-icon.svg',
    accessKey: 'sku-master'
  },
  {
    labelName: 'Budget Master',
    labelUrl: '/vis/budget-master',
    logoUrl: './budgetMaster.svg',
    accessKey: 'budget-master'
  },
  {
    labelName: 'Budget Planning',
    labelUrl: '/vis/budget-planning',
    logoUrl: './budget-planning-icon.svg',
    accessKey: 'budget-planning'
  },
  {
    labelName: 'Plan Summary',
    labelUrl: '/vis/plan-summary',
    logoUrl: './plan-summary-icon.svg',
    accessKey: 'plan-summary'
  },
  {
    labelName: 'Finalize Promotion',
    labelUrl: '/vis/finalize-promotion',
    logoUrl: './finalize.svg',
    accessKey: 'finalize-promotion'
  },
  {
    labelName: 'Promotion Calendar',
    labelUrl: '/vis/Promo',
    logoUrl: './promotion-calendar-icon.svg',
    accessKey: 'promotion-calendar'
  },
  {
    labelName: 'Admin',
    labelUrl: '/vis/admin',
    logoUrl: './admin-icon.svg',
    accessKey: 'admin'
  },
  {
    labelName: 'Video Tutorials',
    labelUrl: '/vis/video-tutorials',
    logoUrl: './video-tutorial.svg',
    accessKey: 'video-tutorials'
  },
  {
    labelName: 'Executive Summary',
    labelUrl: '/vis/executive-summary',
    logoUrl: './executive_summary.svg',
    accessKey: 'executive-summary'
  },
  {
    labelName: 'Constraints',
    labelUrl: '/vis/constraints',
    logoUrl: './constraints.svg',
    accessKey: 'constraints'
  },
];
