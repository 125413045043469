import React, { Component } from "react";
import BreadCrumb from "../BreadCrumb";
import LoadingOverlay from "react-loading-overlay";
import "./index.css";
import FinalizePromoFilter from "./components/finalizePromoFilter";
import FinalizePromoTable from "./components/finalizePromoTable";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import {
  actionGetfinalizePromo,
  actionResetFinalizePromo,
  actionDeleteFinalizePromo,
  actionFinalizePromo,
  actionDownloadFinalizePromo
} from "../../actions/finalizePromoAction";
import ConfirmationModal from "../common/displayModal";
import isEmpty from "lodash/isEmpty";
import PageLoader from "../../utils/Loader";
import PageWrapper from "../common/PageWrapper";
import { FinalizePromotionGA } from '../../common/GAUtils';
class FinalizePromotion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      merchcat: [],
      vendor: [],
      year: [],
      quarter: [],
      offerType: [{value: 'BBM', label:'BBM'}, {value: 'FOC', label:'FOC'}],
      isShow: false,
      message: "",
      deletePromo: {
        type: "",
        id: ""
      },
      selectedPromo: [],
      isBrowserNavigation: false
    };
  }

  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes();
    this.selrow = rowCount;
  }
  updateState = params => {
    let keysLength = Object.keys(params).length;
        if(params.hasOwnProperty("merchcat") && keysLength ===1){
            this.submitGA('Filter','Changed merchcat selection');
        }
        if(params.hasOwnProperty("vendor") && keysLength ===1 ){
          this.submitGA('Filter','changed vendor selection');
        }
        if(params.hasOwnProperty("year") && keysLength ===1){
          this.submitGA('Filter','Changed fiscal year selection');
        }
        if(params.hasOwnProperty("quarter") && keysLength ===1){
            this.submitGA('Filter','changed quarter selection');
        }
        if(params.hasOwnProperty("offerType") && keysLength ===1){
          this.submitGA('Filter','changed offer type selection');
      }
    this.setState(params);
  };

  onReset = () => {
    this.submitGA("Click","Reset Button");
    this.setState({
      merchcat: [],
      vendor: [],
      year: [],
      quarter: [],
      offerType: [],
      message: ''
    });
    this.props.actionResetFinalizePromo();
  };

  filterData = async () => {
    this.submitGA("Click","Filter Button");
    const reqObj = this.getSelectedFilters(this.state);
    await this.props.actionGetfinalizePromo(reqObj);
    (this.props.finalizePromoData>0 ? this.setState({message:''}) : this.setState({message:'No Data Available'}));
  };
  handleFinalizePromoEdit = data => {
    this.submitGA("Click","Edit/Approved finalize promotions");
    const newState = { 
        isBrowserNavigation: true,
        merchcat: this.state.merchcat,
        vendor: this.state.vendor,
        year: this.state.year,
        quarter: this.state.quarter,
        offerType: this.state.offerType
     };
    window.history.pushState(newState,'')
    
    const { type, id } = data;
    if (type === "plan") {
      
      this.props.history.push(`/vis/budget-planning?planId=${id}`)
    }
    if (type === "promo") {
      this.props.history.push(`/vis/create-new-promotion?promoId=${id}`);
    }
  };


  handleFinalizePromoDelete = data => {

    const { type, id, name } = data;
    const promoDetails = { ...this.state.deletePromo };
    promoDetails.type = type;
    promoDetails.id = id;
    this.setState({
      deletePromo: promoDetails,
      isShow: true,
      message: `Do you want to delete Finalized ${type} - ${name}?`
    });
  };

  handleDeleteConfirm = async isConfirm => {
    const reqObj = {
      type: this.state.deletePromo.type,
      id: this.state.deletePromo.id
    };
    if (isConfirm) {
      this.submitGA("Click","Delete finalize promotions");
      this.props.actionDeleteFinalizePromo(reqObj);
      this.filterData();
    }
    this.setState({ isShow: false });
  };

componentWillMount() {
  let isRedirect = true;
  window.onpopstate = (event) => {
    const data = event.state;
    if (event.state.isBrowserNavigation) {
      isRedirect = false;
      const reqObj = this.getSelectedFilters(data)
      this.setState({ 
        isBrowserNavigation: false,
        merchcat: data.merchcat,
        vendor: data.vendor,
        year: data.year,
        quarter: data.quarter,
        offerType: data.offerType
       });
       this.props.actionGetfinalizePromo(reqObj);
       window.history.pushState({isBrowserNavigation: false},'')
    }else{
      this.props.actionResetFinalizePromo();
    }
  };
  setTimeout(() => {
    if(isRedirect){
      this.props.actionResetFinalizePromo();
    }  
  }, 10);
  
}

  componentWillUnmount() {
    // this.props.actionResetFinalizePromo();
  }

  handlePromoSelection = data => {
    const selectedRows = data.api.getSelectedRows();
    const reqData = selectedRows.map(row => {
      return { type: row.type, id: row.id };
    });

    this.setState({ selectedPromo: [...reqData] });
  };

  finalizeRows = async (args) => {
    const { id, type, name, status} = args;
    let reqObj;
    if(status ==="REJECTED"){
      reqObj = {
        data: [{type, id, comment: args.comment}],
        status : "REJECTED",
        }
    }else if(status ==="APPROVED"){
      reqObj = {
        data: [{type, id}],
        status: "UNAPPROVED"
      };
    }else reqObj = {
      data: [{type, id}],
      status: "APPROVED"
    };

    this.submitGA("Click","Approved finalize promotions");
    await this.props.actionFinalizePromo(reqObj);
    await this.filterData();
  };

  getSelectedFilters = (data) => {
    const quarter = data.quarter.map(obj => {
      const val = obj.value.split("");
      return parseInt(val[1]);
    });
    const reqObj = {
      merchcat: [data.merchcat[0].value],
      year: [data.year[0].value],
      vendor: data.vendor.map(obj => obj.value),
      offerType: data.offerType.map(obj => obj.value),
      quarter,
      screenName: 'finalize'
    };
    return reqObj
  }

  handleDownload = () => {
    this.submitGA("Click","Download promotions list");
    const reqObj = this.getSelectedFilters(this.state);
    this.props.actionDownloadFinalizePromo(reqObj);
  };

   openLoader = () => {
    this.setState({
      loading: true
    })
  };
   closeLoader = () => {
    this.setState({
      loading: false
    })
  };

  submitGA(action,label){
    FinalizePromotionGA({ action, label});
  }
  render() {
    let DynamicFiltersProps = [
      {
        type: "Select",
        placeholder: "Select Category",
        state: this.state.merchcat,
        mappingKey: "merchcat",
        onMenuCloseApi: true,
        grid: "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
        label : "Category",
        disabledApiCall:true,
        defaultSelectedOptions:true
      },
      {
        type: "MultiSelectCheckboxes",
        placeholder: "Select Vendor",
        state: this.state.vendor,
        mappingKey: "vendor",
        onMenuCloseApi: false,
        grid: "col-xs-12 col-sm-6 col-md-3 col-lg-3 col-xl-3",
        label : "Vendor",
        defaultSelectedOptions:true
      },
      {
        type: "Select",
        placeholder: "Select Fiscal Year",
        state: this.state.year,
        mappingKey: "year",
        onMenuCloseApi: false,
        grid: "col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2",
        label : "Fiscal Year",
        defaultSelectedOptions:true
      },
      {
        type: "MultiSelectCheckboxes",
        placeholder: "Select Quarter",
        state: this.state.quarter,
        mappingKey: "quarter",
        onMenuCloseApi: false,
        grid: "col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2",
        label : "Quarter",
        defaultSelectedOptions:true
      },
      {
        type: "MultiSelectCheckboxes",
        placeholder: "Select Offer Type",
        state: this.state.offerType,
        mappingKey: "offerType",
        onMenuCloseApi: false,
        grid: "col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2",
        label: "Offer Type",
        defaultSelectedOptions:true
      }
    ];

    let DynamicFiltersOptions = {
      merchcatOptions: [],
      vendorOptions: [],
      yearOptions: [],
      quarterOptions: [],
      offerTypeOptions: []
    };

    const { finalizePromoData } = this.props;
    const { merchcat, vendor, year, quarter, offerType} = this.state;

    return (
      <PageLoader loader={this.state.loading || this.props.loading}>
      <PageWrapper>
      <div>
                <h4 className="promoHeading mb-1 color-blue">
                  <BreadCrumb root="" child="Finalize Promotion" />
                </h4>
                <div className="row filter-block">
                  <FinalizePromoFilter
                    DynamicFiltersOptions={DynamicFiltersOptions}
                    DynamicFiltersProps={DynamicFiltersProps}
                    updateState={this.updateState}
                    filterData={this.filterData}
                    onReset={this.onReset}
                    openLoader={this.openLoader}
                    closeLoader={this.closeLoader}
                    isFilterButtonDisabled={merchcat.length ===0 || vendor.length === 0 || year.length===0 || quarter.length===0 || offerType.length===0}
                    screenName='finalize'
                  />
                </div>
                {/* <hr className="dotted-line" /> */}
                {finalizePromoData.length > 0 ? (
                  <div className="row" style={{ padding: "5px 0px" }}>
                  <div className="col-4">
                  <div className="promolist-header">
                  <label className="pipelineLabel">Promotion List</label>
                </div>
                <button
                  className="btn btn-primary iconbutton download-btn"
                  onClick={this.handleDownload}
                >
                  <span>
                    <i class="fa fa-download" aria-hidden="true" />
                  </span>
                </button>
                  </div>
                  {/* <div className="col-8">
                  {this.props.userInfo.indexOf('finalize')!== -1? (
                    <><button
                    onClick={() => this.finalizeRows()}
                    className="pull-right btn btn-primary "
                    style={{ marginLeft: "10px" }}
                    disabled={isEmpty(this.state.selectedPromo)}
                  >
                    <span>Finalize</span>
                  </button></>
                  ): null}
                  </div> */}
                    
                  </div>
                ): 
                  <div className="row">
                  <div className="col-md-5"></div>
                    <div className="col-md-3">
                    <p>{this.state.message}</p>
                    </div>
                  </div>

                }
                {finalizePromoData.length > 0 && (
                  <FinalizePromoTable
                    promoData={finalizePromoData}
                    userInfo={this.props.userInfo}
                    onSelectionChanged={this.handlePromoSelection}
                    handleFinalizePromoEdit={this.handleFinalizePromoEdit}
                    handleFinalizePromoDelete={this.handleFinalizePromoDelete}
                    finalizeRows={this.finalizeRows}
                  />
                )}

          <div>
            <ConfirmationModal
              isShow={this.state.isShow}
              message={this.state.message}
              handleConfirmModel={this.handleDeleteConfirm}
            />
          </div>
        {/* </LoadingOverlay> */}
      </div>
      </PageWrapper>
      </PageLoader>

    );
  }
}

const mapStateToProps = state => ({
  finalizePromoData: state.finalizePromoData.finalizePromoData,
  loading: state.finalizePromoData.loading,
  userInfo: state.userManagementData.userInfo.action
});

const mapDispatchToProps = dispatch => {
  return {
    actionGetfinalizePromo: val => dispatch(actionGetfinalizePromo(val)),
    actionResetFinalizePromo: () => dispatch(actionResetFinalizePromo()),
    actionDeleteFinalizePromo: val => dispatch(actionDeleteFinalizePromo(val)),
    actionFinalizePromo: val => dispatch(actionFinalizePromo(val)),
    actionDownloadFinalizePromo: val =>
      dispatch(actionDownloadFinalizePromo(val))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(FinalizePromotion));
 