import React, { Component } from "react";
import ReactDOM from "react-dom";
import { fetchDiscountDepthDropDown } from "../../../../apis/budgetPlan.api";
import _ from 'lodash';
import { getDisStateFrmStatus } from "../../../../utils/helperFunctions"
import { BudgetPlanningGA } from '../../../../common/GAUtils';
const NEW_PROMO = 'New Promo';
export default class ActualEditor extends Component {
    constructor(props) {
        super(props);

        this.state = {
            promoDays: this.props.promoDays,
            bjsFunding: this.props.bjsFunding,
            mediaType: this.props.mediaType,
            discountDepth: this.props.discount,
            promoId: this.props.promoId,
            promoKey: this.props.promoKey,
            promoKeyToPromoId: this.props.promoKeyToPromoId,
            discountDepthlist: [],
            mediaTypeList: [],
            promoDaysList: [],
            actualPopUpData: [],
        }
    }

    getCurrentPromoKey = () => {
        const {
            year,
            cycle,
            categoryId,
            bookType,
            planId
        } = this.props
        const vendor = this.props.data.vendor;
        const {
            mediaType,
            promoDays,
            discountDepth,
            promoKeyToPromoId
        } = this.state
        let discount = String(discountDepth);
        const promoKeyItems = [planId, year, cycle, categoryId, mediaType, vendor, bookType, promoDays, discount.includes('.') ? discount : `${discount}.0`];
        const promoKey = promoKeyItems.join('|');
        return promoKey
    }

    getExistingPromoIDList = () => {
        const {
            promoKeyToPromoId
        } = this.state;
        const promoKey = this.getCurrentPromoKey();
        let existingPromoIds = promoKeyToPromoId[promoKey];
        return _.uniq(existingPromoIds);
    }

    getPromoIdList = () => {
        return [...this.getExistingPromoIDList(), NEW_PROMO]
    }

    componentDidMount = async () => {
        const {
            articleNo,
            cycle
        } = this.props;
        const actualPopUpData = await fetchDiscountDepthDropDown({
            articleNo,
            cycle
        });
        if (actualPopUpData && actualPopUpData.length) {
            let discountDepth = _.uniq(actualPopUpData.map(item => item.discountDepth));
            let promoDays = _.uniq(actualPopUpData.map(item => item.promodays));
            let promoType= _.uniq(actualPopUpData.map(item => item.promoType));
            this.setState({
                actualPopUpData,
                discountDepthlist: discountDepth,
            })
            this.setState({
                promoDays: this.props.promoDays,
                promoDaysList: promoDays,
                bjsFunding: this.props.bjsFunding,
                mediaType: this.props.mediaType,
                mediaTypeList: promoType,
                discountDepth: this.props.discount
            })
        }
    }


    onSaveClick = () => {
        const {
            promoDays,
            bjsFunding,
            mediaType,
            discountDepth,
            promoId,
            promoKey,
        } = this.state
        const {
            cycle,
            articleNo
        } = this.props
        let newPromoId = promoId;
        let newPromoKey = promoKey;
        let isNewPromo = false;
        if (newPromoId == NEW_PROMO) {
            isNewPromo = true;
            const {
                promoKey,
                promoId
            } = this.getNewPromoKeyAndPromoId()
            newPromoId = promoId;
            newPromoKey = promoKey;
        }
        this.props.onSaveClick({
            promoDays,
            bjsFunding,
            mediaType,
            discountDepth,
            cycle,
            articleNo,
            isNewPromo,
            promoKey: discountDepth != 0 ? newPromoKey : '',
            promoId: discountDepth != 0 ? newPromoId : ''
        });
    }
    getRandomTwoDigit = () => {
        const min = 1, max = 99;
        const randomInt = Math.floor(Math.random() * (max - min + 1)) + min;
        if (randomInt < 10) {
            return `0${randomInt}`
        }
        return String(randomInt);
    }
    getNewPromoKeyAndPromoId = () => {
        const promoKey = this.getCurrentPromoKey();
        const existingPromoIds = this.getExistingPromoIDList();
        const {
            year,
            cycle,
            categoryId,
            planId
        } = this.props
        let newPromoId;
        do {
            newPromoId = [year, cycle, categoryId, `${planId}${this.getRandomTwoDigit()}`]
        } while (existingPromoIds.includes())
        return {
            promoKey,
            promoId: newPromoId.join('|')
        }
    }

    handleChange = (event, isDiscountDepth = false) => {
        event.preventDefault();
        let { name, value } = event.target
        this.submitGA("Click",`Changed ${name} selection`);
        // As we get requirement saying no change required after discount change
        // Need to remove if block in future and keep only else block.
        if (false && isDiscountDepth) {
           this.setState({ ...this.state, [name]: value },()=>{
            let mediaTypeList = [];
            let promoDaysList = [];
            this.state.actualPopUpData.forEach(element => {
                if(`${element.discountDepth}` === this.state.discountDepth)
                mediaTypeList.indexOf(element.promoType) === -1 &&  mediaTypeList.push(element.promoType);
                promoDaysList.indexOf(element.promodays) === -1 &&  promoDaysList.push(element.promodays)
            });
            this.setState({mediaTypeList, promoDaysList, promoDays:promoDaysList[0],mediaType:mediaTypeList[0]}, () => {
                const promoIdList = this.getPromoIdList();
                this.setState({
                    promoId: promoIdList[0]
                })
            })
           })
        } else {
            this.setState({ ...this.state, [name]: value }, () => {
                if (name != 'promoId') {
                    const promoIdList = this.getPromoIdList();
                    this.setState({
                        promoId: promoIdList[0]
                    })
                }
            });
        }
    };
    submitGA(action,label){
        BudgetPlanningGA({ action, label});
    }
    render() {
        const promoIdList = this.getPromoIdList();
        const { active } = this.props.data;
        return (
            <div ref="container" className="popup-container">
                <div className="inputwraper-style"><label>Discount Depth</label>
                    <select id="discountDepth" name="discountDepth" value={this.state.discountDepth} className="input-style" onChange={e => { this.handleChange(e, true) }} disabled={getDisStateFrmStatus(this.props.planStatus) || !active}>
                        {this.state.discountDepthlist.length
                            && this.state.discountDepthlist.sort().map((item, index) => {
                                return (<option key={index} value={item}>{item.toFixed(2)}</option>)
                            })}
                    </select>
                </div>
                <div className="inputwraper-style">
                    <label>No Of Promo Days</label>
                    <select id="promoDays" name="promoDays" value={this.state.promoDays} className="input-style" onChange={e => { this.handleChange(e) }} disabled={getDisStateFrmStatus(this.props.planStatus) || !active}>
                        {this.state.promoDaysList.length
                            && this.state.promoDaysList.map((item, index) => (
                                <option key={index} value={item}>{item}</option>)
                            )}
                    </select>
                </div>
                <div className="inputwraper-style">
                    <label>BJS Funding</label>
                    <input type="text" name="bjsFunding" className="input-style" value={this.state.bjsFunding} onChange={e => { this.handleChange(e) }} disabled={getDisStateFrmStatus(this.props.planStatus) || !active} /><i>%</i>

                </div>
                <div className="inputwraper-style"><label>Media Type</label>
                    <select id="mediaType" name="mediaType" value={this.state.mediaType} className="input-style" onChange={e => { this.handleChange(e) }} disabled={getDisStateFrmStatus(this.props.planStatus) || !active}>
                        {this.state.mediaTypeList.length
                            && this.state.mediaTypeList.map((item, index) => (
                                <option key={index} value={item}>{item}</option>)
                            )}
                    </select>
                </div>
                <div className="inputwraper-style"><label>Promo ID</label>
                    <select id="promoId" name="promoId" value={this.state.promoId} className="input-style" onChange={e => { this.handleChange(e) }} disabled={getDisStateFrmStatus(this.props.planStatus) || !active}>
                        {promoIdList.length
                            && promoIdList.map((item, index) => (
                                <option key={index} value={item}>{item}</option>)
                            )}
                    </select>
                </div>
                <div className="text-center mt-3">
                    <button onClick={this.onSaveClick} className="btn btn-primary save-btn" disabled={getDisStateFrmStatus(this.props.planStatus)  || !active}>Ok</button>
                </div>
            </div>
        );
    }
}
