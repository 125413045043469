
import { UPDATE_SELECTED_METRICS  , SET_LOADER} from '../actions/types';

const initialState = {
  selectedMetrics: [
    {
      label: "Offer Name",
      value: "title"
    },
    {
        label: "Offer Type",
        value: 'actualCouponType'
    }, {
        label: "Book #",
        value: "mediaCycle"
    }, {
        label: "FY",
        value: "fYear"
    }, {
        label: "Quarter",
        value: "fQuater"
    },
    {
        label: "Coupon Type",
        value: 'actualCouponType'
    }, {
        label: "Vendor Funded",
        value: 'vendorFunded'
    }
  ],
  loading: false
};

export default function (state = initialState, action) {
    switch (action.type) {
      case UPDATE_SELECTED_METRICS:
        return {
            ...state,
            selectedMetrics: action.payload || initialState.selectedMetrics,
        };
        case SET_LOADER:
        return {
            ...state,
            loading: action.payload
        };
      default:
          return state || [];
    }
}

