import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import {
  actionBudgetPlanArticleCampaigns,
  actionUpdateBudgetMetricsTable,
  actionBudgetPlanActualColSave,
  setBudgetPlanHeaders,
  actionSimulationPlan,
  resetBudgetPlanHeaders
} from "../../budgetPlanAction";
import _ from "lodash";
import TableWrapper from "../../../common/TableWrapper";
import { BudgetPlanTableTitleContent, DropdownToggle } from "./styled";
import { convertMillionsFormatter ,precisionFormatter} from "../../../../utils/AgGridFormatters";

import ActualRenderer from "./actualRenderer";
import { BudgetPlanningGA } from '../../../../common/GAUtils';
import {DragSwitch} from 'react-dragswitch';
import 'react-dragswitch/dist/index.css';
import { Dropdown } from 'react-bootstrap';

const MODEL_TYPES = [
  {
    model: 'Inactive Article',
    color: '#D3D3D3'
  },
  
  {
  model: 'Model Prediction',
  color: '#aaffc3'
},

// {
//   model: 'Quantitative Estimation',
//   color: '#ffd8b1'
// },
{
  model: 'Prediction Using Hierarchy Model',
  color: '#FFFF99'
},
{
  model: 'Prediction Using Similar Article',
  color: '#87CEFA'
},
// {
//   model: 'Quantitative Estimation+ Promo Fatigue Relaxation',
//   color: '#469990'
// }
]
const AidRenderComponent = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        color: " #44677b"
      }}
    >
      <span>{props.value}</span>
    </div>
  );
};
const BudgetPlanTable = ({
  budgetPlanningData,
  selectedYearVal,
  getPlanId,
  actionBudgetPlanArticleCampaigns,
  plannedArticleData,
  planName,
  handleDiscountsChange,
  actionUpdateBudgetMetricsTable,
  actionBudgetPlanActualColSave,
  handleDownload,
  planStatus,
  promoTrackerDownload,
  setBudgetPlanHeaders,
  planTableHeaders,
  redirectFrom,
  updateRedirectFrom,
  actoinUpdateInactiveMetrics,
  budgetPlanCampaignList
}) => {
  const [colDef, setColDefs] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [optimizeBudget, setOptimizeBudget] = useState(0);
  const [gridApi, setGridApi] = useState({});
  const [updatedDiscounts, setUpdatedDiscounts] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState(undefined);
  const [checked, setChecked] = useState(true);
  
  useEffect(() => {
    const planId = getPlanId();
    actionBudgetPlanArticleCampaigns(planId);
  }, []);

  const setRowLevelData = (checked) => {
    console.log("Checked:", checked);
    let articleLevelData = Object.values(plannedArticleData.data);
    const {
      promoKeyToPromoId
    } = plannedArticleData;
    if (checked) {
      articleLevelData = articleLevelData.filter(article => article.active == true)
    }
    articleLevelData.forEach(item => {
      item['promoKeyToPromoId'] = promoKeyToPromoId;
    });
    console.log("data:", articleLevelData);
    setRowData(articleLevelData);
  }
  useEffect(() => {
    //table column headers
    if (!plannedArticleData || _.isEmpty(plannedArticleData)) {
      return;
    }

    const handleArticleSelection = data => {
      submitGA("Click","Article level discount table in budget planning");
      setSelectedArticle(data.articleNo);
      data.active ? actionUpdateBudgetMetricsTable({
        articleData: data,
        budgetPlanCampaignList
      }): actoinUpdateInactiveMetrics({
        articles: [data],
        budgetPlanCampaignList
      }) ;
      const scrollIntoViewOptions = { block: "start", inline: "start" };
      const viewElement = data.active ? "metricsSection" : "inactiveMetrics";
      setTimeout(() => {
        document
        .getElementById(viewElement)
        .scrollIntoView({});
      },0);
    };
    const {
      promoKeyToPromoId
    } = plannedArticleData;
    let year;
    if (!_.isEmpty(selectedYearVal)) {
      year = selectedYearVal.value;
    }
    const columnDefs = [
      {
        headerName: "AH6",
        field: "hierarchyLevel6",
        pinned: "left",
        sortable: true,
        filter: true
      },
      {
        headerName: "Article",
        suppressSizeToFit: true,
        field: "articleNo",
        width: 80,
        pinned: "left",
        sortable: true,
        filter: true,
        cellRendererFramework: AidRenderComponent,
        cellStyle: params => ({
          color: "blue",
          textDecoration: "underline",
          cursor: "pointer"
        }),
        onCellValueChanged: params => {},
        onCellClicked: params => {
          handleArticleSelection(params.data);
        }
      },
      {
        headerName: "Description",
        field: "description",
        sortable: true,
        filter: true,
        cellStyle: function (params) {
          if(!params.data.active) {
            return {
              backgroundColor: "#D3D3D3"
            };
           
          } else if (params.data.modelType) {
            const activeModel =  MODEL_TYPES.filter(obj=> obj.model === params.data.modelType)
            if(activeModel.length > 0){
              return {
                backgroundColor: activeModel[0].color
              };
            }else{
              return {}
            }

          } else {
            return {}
          }
        },
        pinned: "left"
      },
      {
        headerName: "Vendor",
        field: "vendor",
        sortable: true,
        filter: true,
        pinned: "left"
      },
      {
        headerName: "Brand",
        field: "brand",
        sortable: true,
        filter: true,
        pinned: "left",
        width: 100
      }
    ];
    plannedArticleData.books.forEach(book => {
      const bookNumber = book.split("-")[1];
      const bookTemplate = {
        headerName: `Book ${bookNumber}`,
        children: [
          {
            headerName: "LY",
            field: `${book}_discountLY`,
            width: 100,
            sortable:true,
            valueFormatter: (params) => {
              const pFormatter = precisionFormatter(params)
              if (pFormatter == '-') {
                return pFormatter
              }
              return '$' + pFormatter
            },
            tooltipField:`${book}_mediaTypeLyTooltip`
          },
          {
            headerName: "MediaTyepLY",
            field: `${book}_mediaTypeLyTooltip`,
            width: 100,
            sortable:true,
            hide:true
          },
          {
            headerName: "Optimized",
            field: `${book}_discountOptimized`,
            sortable:true,
            width: 100,
            valueFormatter: (params) => {
              return '$' + precisionFormatter(params) },
            tooltipField:`${book}_mediaTypeOptimizedTooltip`
          },
          {
            headerName: "MediaTyepOptimized",
            field: `${book}_mediaTypeOptimizedTooltip`,
            width: 100,
            sortable:true,
            hide:true
          },
          {
            headerName: "Finalized",
            field: `${book}_discountFinalized`,
            sortable:true,
            cellRendererFramework: ActualRenderer,
            cellRendererParams: {
              handleDiscountChange,
              year,
              planId: getPlanId(),
              planStatus
            },
            tooltipField:`${book}_mediaTypeFinalizedTooltip`
          },
          {
            headerName: "MediaTyepFinalized",
            field: `${book}_mediaTypeFinalizedTooltip`,
            width: 100,
            sortable:true,
            hide:true
          },
          {
            headerName: "Promo Id",
            field: `${book}_promoIdFinalized`,
            cellStyle: function (params) {
              if (params.value == '') return;
              const stringToColour = function(str) {
                let hash = 0;
                for (let i = 0; i < str.length; i++) {
                  hash = str.charCodeAt(i) + ((hash << 5) - hash);
                }
                let colour = '#';
                for (let i = 0; i < 3; i++) {
                  let value = (hash >> (i * 8)) & 0xFF;
                  colour += ('00' + value.toString(16)).substr(-2);
                }
                return colour;
              }
              if (params.value) {
                return {
                  backgroundColor: stringToColour(params.value.split('').reverse().join(''))
                };
              } else {
                return {}
              }
            },
            sortable:true
          }
        ]
      };
      columnDefs.push(bookTemplate);
    });
    
    const differenceColumns = _.differenceBy(columnDefs, planTableHeaders, 'headerName');
    
    // _.mapValues(_.groupBy(articleLevelData, 'ARCH_DATETIME'))
    
    if(differenceColumns.length >0 || redirectFrom === 'constraints'){
      setColDefs(columnDefs);
      setBudgetPlanHeaders(columnDefs)
      if(redirectFrom === 'constraints'){
        updateRedirectFrom();
      }
    }else{

      console.log("NO Difference",planTableHeaders, columnDefs ,differenceColumns);
    }
    setRowLevelData();
    if (selectedArticle) {
      actionUpdateBudgetMetricsTable({
        articleData: plannedArticleData.data[selectedArticle],
        selectedYearVal
      });
    }
    if (plannedArticleData.budgetOptimized) {
      setOptimizeBudget(plannedArticleData.budgetOptimized);
    }
    if(Object.keys(gridApi).length > 0){
      // gridApi.sizeColumnsToFit();
    }
  }, [plannedArticleData, selectedYearVal]);

  const handleDiscountChange = ({
    promoDays,
    bjsFunding,
    mediaType,
    discountDepth,
    cycle,
    promoId,
    isNewPromo,
    promoKey,
    campaignId,
    articleNo
  }) => {
    submitGA("Click","Discount depth changed");
    let updatedDiscounts = [];
    updatedDiscounts.push({
      promoDays,
      bjsFunding,
      mediaType,
      discountDepth,
      cycle,
      promoId,
      isNewPromo,
      promoKey,
      campaignId,
      articleNo,
      planId: getPlanId()
    });
    actionSimulationPlan(updatedDiscounts);
    handleDiscountsChange(updatedDiscounts);
  };

  // specifying column headers and row data to table gridOptions
  const gridOptions = {
    // columnDefs: colDef,
    // rowData: rowData,
    domLayout: "autoHeight",
    defaultColDef: {
      width: 150,
      resizable: true
    }
  };

  // onPencilIconClick = (articleNo) => {
  //   console.log(articleNo)
  // }

  function onGridReady(params) {
    setGridApi(params.api);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.gridApi.sizeColumnsToFit();
  }
  const submitGA =(action,label)=>{
    BudgetPlanningGA({ action, label});
  }
  const handleChange = () => {
    setChecked(!checked);
    setRowLevelData(checked);
  }
  return (
    <>
      <TableWrapper>
      <div className="row" style={{marginLeft:"1px"}}>
        {MODEL_TYPES.map((obj, index) => (
          <div className={ (MODEL_TYPES.length-1) === index ? "col-3" :"col-3"}>
            <div className="row">
            <div className={(MODEL_TYPES.length-1) === index ? "col-3" :"col-3"} style={{
              boxSizing: "border-box",
              width: "100px",
              height: "15px",
              border:"1px solid", backgroundColor: obj.color}}></div>
            <div className="col-9">{obj.model}</div>
            </div>
          </div>
        ))}
        </div>
        <div className="spacer"></div>
        <BudgetPlanTableTitleContent>
        
          <p className="textstyle budget-align">Plan: {`${getPlanId()}-${planName}`}</p>
          <p className="textstyle budget-align">
            Optimized budget: $ {convertMillionsFormatter(optimizeBudget)}
          </p>
          <p className="textstyle budget-align"> Active Article &nbsp;
          <DragSwitch 
            checked={checked}
            onColor={'#44677b'}
            onChange={handleChange}
          /> 
          &nbsp; All Article
          </p>
        <div className="dropdown" style={{justifySelf: "end"}}>
          <Dropdown>
            <DropdownToggle 
              id="dropdown-basic"
              className=" btn-primary btn-sm mt-2" style={{display:"flex", width:'190px'}}>
              <i class="fa fa-download mr-2 mt-1" aria-hidden="true" />
              <div>Download Files</div>
            </DropdownToggle>

            <Dropdown.Menu>
              <Dropdown.Item 
                onClick={()=>handleDownload()}>Optimized Plan</Dropdown.Item>
              <Dropdown.Item 
                onClick={()=>promoTrackerDownload()}>Promo Tracker</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
  
        </BudgetPlanTableTitleContent>
        <AgGridReact
          id='budgetPlanGrid'
          floatingFilter={true}
          multiSortKey='ctrl'
          singleClickEdit={true}
          suppressDragLeaveHidesColumns={true}
          suppressHorizontalScroll={false}
          suppressRowTransform={true}
          suppressColumnMoveAnimation={true}
          onGridReady={onGridReady}
          gridOptions={gridOptions}
          columnDefs={colDef}
          rowData={rowData}
          tooltipShowDelay={0}
          frameworkComponents={{
            actualRenderer: ActualRenderer,
          }}
          enableCellTextSelection={true}
          enableBrowserTooltips={true}
        />
      </TableWrapper>
      <hr />
    </>
  );
};

const mapStateToProps = state => ({
  plannedArticleData: state.budgetPlan.plannedArticleData,
  planTableHeaders: state.budgetPlan.headers,
  budgetPlanCampaignList: state.budgetPlan.budgetPlanCampaignList
});

const mapDispatchToProps = dispatch => {
  return {
    actionBudgetPlanArticleCampaigns: val =>
      dispatch(actionBudgetPlanArticleCampaigns(val)),
    actionUpdateBudgetMetricsTable: val =>
      dispatch(actionUpdateBudgetMetricsTable(val)),
    actionBudgetPlanActualColSave: val =>
      dispatch(actionBudgetPlanActualColSave(val)),
    actionSimulationPlan: val =>
      dispatch(actionSimulationPlan(val)),
    setBudgetPlanHeaders: val =>
      dispatch(setBudgetPlanHeaders(val)),
    resetBudgetPlanHeaders: val =>
      dispatch(resetBudgetPlanHeaders(val)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BudgetPlanTable));
