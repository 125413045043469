import React, { Component } from 'react';
import moment from 'moment';
import { isEmpty } from 'lodash';
import PageLoader from '../../utils/Loader';
import BreadCrumb from '../BreadCrumb';
import { Card } from 'react-bootstrap';
import BasicInfo from './BasicInfo';
import AddSku from './AddSku';
import Parameters from './Parameters';
import Actions from './Actions';
import SimulationResult from './SimulationResult';

import {
  actionGetSku,
  dataSimulate,
  getBookNumbersList ,
  savePromo,
  submitPromo,
  resetPromoId ,
  getCategoryList,
  actionFetchPromo,
  actionDownloadPromo,
  actionDownloadPromoTracker,
  actionResetPromoStatus
} from '../../actions/createPromoaction';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import './index.css';
import _ from 'lodash';
import queryString from "query-string";
import { CreatePromoGA } from '../../common/GAUtils';
class CreatePromo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      offerName: "",
      offerType: "BBM",
      mediaType: "Paper Coupon",
      isVendorFunded: true,
      bookNumber: [],
      promoStartDate: null,
      promoEndDate: null,
      articleArr: [],
      selectedMerchcat: [],
      targetPromoValueArr: [],
      promoValueError: "",
      enableSave: false,
      enableSimulation: false,
      enableSubmit: false,
      oldParamsState: {},
      tempStatus: null,
      isOfferTypeChanged: false,
      savedOffer:[]
    };
  }

  getBookNumbers = async () => {
    this.props.getBookNumbersList({
      name: this.state.offerType,
    });
  };
  componentDidMount() {
    this.props.resetPromoId();

    this.props.getCategoryList({
      screenName: "standalone",
    });
    
    const promoId = this.getPromoId();
    if (promoId) {
      this.props.actionFetchPromo({promoId})
    }else{
      this.getBookNumbers();
    }
  }
  updateExistingPromoData = (promoData) => {
    const {
      title,
      campaignId,
      couponType,
      vendorFunded,
      startDate,
      endDate,
      category,
      hierarchies,
      promoValue
    } = this.props.promoData
    let offerType = this.state.isOfferTypeChanged ? this.state.offerType : campaignId.split('-')[0] ;
    let bookNumber = [];
    bookNumber = ((this.props.bookNumberOptions.length > 0 ? this.props.bookNumberOptions[_.findIndex(this.props.bookNumberOptions, {name: campaignId})] : []) || []);
    this.setState({
      offerName: title,
      offerType,
      couponType,
      isVendorFunded: vendorFunded,
      bookNumber: Array.isArray(bookNumber) ? [] : [bookNumber],
      promoStartDate: moment(startDate),
      promoEndDate: moment(endDate),
      selectedMerchcat: [{
        label: category[0],
        value: category[0]
      }],
      targetPromoValueArr: [{
        id: Math.floor(Math.random() * Math.floor(100000)),
        isChecked: true,
        promoValue
      }],
      articleArr: hierarchies,
      isOfferTypeChanged: false
    }, () => {
      if(this.state.bookNumber.length > 0){

        this.simulateData(false);
      }
    })
  }

  getIsAllRequiredFields = () => {
    const promoValueCondion =
      this.state.targetPromoValueArr.length === 0
        ? true
        : !this.state.targetPromoValueArr.some((row) => row.promoValue > 0);
    return (
      this.state.bookNumber.length === 0 ||
      this.state.promoStartDate === null ||
      this.state.promoEndDate === null ||
      this.state.articleArr.length === 0 ||
      promoValueCondion ||
      this.state.offerName.trim().length===0||
      this.state.promoValueError !== ""
    );
  };

  componentDidUpdate = (oldProps, prevState) => {
    if (oldProps.promoData != this.props.promoData) {
      // if (this.props.bookNumberOptions && this.props.bookNumberOptions.length > 0) {
        this.props.getBookNumbersList({
          name: this.props.promoData.offerType
        });
        this.updateExistingPromoData(this.props.promoData)
      // }
    }
    if (oldProps.bookNumberOptions != this.props.bookNumberOptions) {
      if (Object.keys(this.props.promoData).length > 0) {
        this.updateExistingPromoData(this.props.promoData);
      }
    }
    if (
      Object.keys(this.state.oldParamsState).length === 0 &&
      !this.getIsAllRequiredFields()
    ) {
      const tempPromoId = this.getPromoId();

      if (tempPromoId) {
        const currParamState = this.getRequestedParams(this.state);
        if (this.props.promoData.status === "DRAFT") {
          this.setState({
            oldParamsState: currParamState,
            tempStatus: this.props.promoData.status,
            enableSubmit: true
          });
        } else if (this.props.promoData.status === "UNAPPROVED") {
          this.setState({
            oldParamsState: currParamState,
            tempStatus: this.props.promoData.status,
            enableSubmit: true,
            enableSave: true
          });
        }else {
          this.setState({
            oldParamsState: currParamState,
            tempStatus: this.props.promoData.status,
          });
        }
      } else {
        const currParamState = this.getRequestedParams(this.state);
        this.setState({
          oldParamsState: currParamState,
          enableSimulation: true,
          enableSave: false,
          enableSubmit: false,
        });
      }
    }
    if (Object.keys(this.state.oldParamsState).length > 0 ) {
      const currState = this.getRequestedParams(this.state);

     if(!this.getIsAllRequiredFields()){
      if (
        !_.isEqual(this.state.oldParamsState, currState) &&
        !this.state.enableSimulation
      ) {
        this.setState({
          enableSimulation: true,
          enableSave: false,
          enableSubmit: false,
          tempStatus: null,
        });
      } 
      if (_.isEqual(this.state.oldParamsState, currState)) {
        if (
          Object.keys(this.props.simulationData).length > 0 &&
          !this.state.enableSimulation &&
          this.state.tempStatus === null
        ) {
          this.setState({
            enableSave: true,
            enableSubmit: true,
            tempStatus: "SIMULATED",
          });
        }
        if (!this.state.enableSubmit && this.state.enableSave) {
          this.setState({ enableSave: false });
        }
        // if(this.state.tempStatus === "DRAFT" && !this.state.enableSubmit) {
        //   this.setState({ enableSubmit: true});
        // }
        // if((this.props.promoStatus === "PENDING" || this.state.tempStatus === "PENDING") && this.state.enableSubmit){
        //   this.setState({ enableSubmit: false});
        // }
      }
    } else {
        if(this.state.enableSimulation) {
          this.setState({
            enableSimulation: false
          })
        }
        if(this.state.enableSave || this.state.enableSubmit) {
          this.setState({
            enableSubmit: false,
            enableSave: false
          })
        }
    }
  }
  };

  getPromoId = () => {
    const params = queryString.parse(this.props.location.search);
    return params.promoId;
  };

  handleDateChange = (promoStartDate, promoEndDate) => {
    this.submitGA("Filter","Changed promotion period selection");
    this.setState({ promoStartDate, promoEndDate });
  };

  updateState = (params) => {
    const { bookNumber } = params;
    this.submitGA("Filter","Changed Book number selection");
    this.setState({
      bookNumber,
    });
  };
  onOfferChange = (e) => {

    const { name, value } = e.target;
    switch (name) {
      case 'offerType':
        this.submitGA("Filter","Changed offer type selection");
        break;
      case 'mediaType':
          this.submitGA("Filter","Changed promo type selection");
          break;
          case 'isVendorFunded':
            this.submitGA("Filter","Changed vendor funded selection");
            break;
               
      default:
        break;
    }
    this.setState(
      {
        [name]: value,
      },
      () => {
        // debugger
        // if(this.getIsAllRequiredFields()){
        //   this.setState({
        //     enableSimulation:false
        //   })
        // }
        
        if (name === "offerType") {
          this.setState({
            bookNumber: [],
            promoStartDate: null,
            promoEndDate: null,
            isOfferTypeChanged: true
          })
          this.getBookNumbers();
        }
      }
    );
  };

  handleSelectedMerchcat = ({ selectedMerchcat }) => {
    this.submitGA("Filter","Changed merchcat selection");
    this.setState({
      selectedMerchcat,
    });
  };

  updateSkuArticlNumbers = (data) => {
    this.setState(
      {
        articleArr: data,
      },
      () => {
        // if(this.getIsAllRequiredFields()){
        //   this.setState({
        //     enableSimulation:false
        //   })
        // }
      }
    );
  };

  addPromoValues = (data) => {
  this.setState({
    targetPromoValueArr: data,
  });
    // if(!this.getIsAllRequiredFields() && diffPromoValue.length>0){
    //   this.setState({
    //     enableSimulation: true
    //   })
    // }
  };

  handlePromoValueError = (error) => {
    this.setState({
      promoValueError: error,
    });
  };

  getRequestedParams = (requestType) => {
    const isVendorFunded =
      requestType.isVendorFunded === true ||
      requestType.isVendorFunded === "true"
        ? true
        : false;
    const articleArr = requestType.articleArr.map((obj) => {
      obj.articleNo = obj.hierarchyLevel7;
      return obj;
    });
    const bookNo =
      requestType.bookNumber.length > 0
        ? requestType.bookNumber[0].value.split("-")[1]
        : [];
    const startDate = moment(requestType.promoStartDate).format("YYYY-MM-DD");
    const endDate = moment(requestType.promoEndDate).format("YYYY-MM-DD");

    const targetPromoValueArr = requestType.targetPromoValueArr.map(
      (obj) => obj.promoValue
    );
    const campaignId =
      requestType.bookNumber.length > 0 ? requestType.bookNumber[0].value : [];
    const title = requestType.offerName;
    return {
      articleArr,
      targetPromoValueArr,
      offerType: requestType.offerType,
      bookNo,
      startDate,
      endDate,
      mediaType: requestType.mediaType,
      isVendorFunded,
      campaignId,
      title,
    };
  };

  simulateData = (isSimulateClick=true) => {
    this.submitGA("Click","Simulate the data");
    const params = this.getRequestedParams(this.state);
    if (!_.isEqual(this.state.oldParamsState, params)) {
      this.setState({ oldParamsState: params });
    }
    this.props.getSimulationResult(params);
    this.setState({ enableSimulation: false });
    if(isSimulateClick) {
      this.setState({ enableSave: true, enableSubmit: true})
    }
  };

  getSaveSubmitParams = (type) => {
    let params = this.getRequestedParams(this.state);
    params.status = type === "save" ? "DRAFT" : "PENDING";
    params.targetPromoValueArr = this.state.targetPromoValueArr
      .filter((obj) => obj.isChecked === true)
      .map((obj) => obj.promoValue);
    this.setState({
      savedOffer:params.targetPromoValueArr
    })
    return params;
  };

  savePromo = () => {
    this.submitGA("Click","Save promo");
    let params = this.getSaveSubmitParams("save");
    // params.status = 'DRAFT';
    // params.targetPromoValueArr = this.state.targetPromoValueArr.filter(obj=> obj.isChecked === true ).map(obj=> obj.promoValue)
    if (this.props.promoId) {
      params.promoId = this.props.promoId;
    }
    this.props.savePromo(params);
    this.setState({ enableSave: false });
  };

  componentWillUnmount() {
    const reqObj = {status:'',promoData:{}, simulationData:{}}
    this.props.actionResetPromoStatus(reqObj);
  }
  submitPromo = () => {
    this.submitGA("Click","Submit  promo ");
    let params = this.getSaveSubmitParams("submit");
    // params.status = 'PENDING';
    // params.targetPromoValueArr = this.state.targetPromoValueArr.filter(obj=> obj.isChecked === true ).map(obj=> obj.promoValue)
    if (this.props.promoId) {
      params.promoId = this.props.promoId;
    }
    this.setState({ enableSubmit: false });
    this.props.submitPromo(params);
  };

  handlePromoDownload = () => {
    this.submitGA("Click","download promo values");
    let params = this.getRequestedParams(this.state);
    params.targetPromoValueArr = this.state.targetPromoValueArr
      .filter((obj) => obj.isChecked === true)
      .map((obj) => obj.promoValue);
    // params.targetPromoValueArr = this.state.savedOffer
    this.props.actionDownloadPromo(params);
  };

  handlePromoTrackerDownload = () => {
    this.submitGA("Click","download promo tracker");
    const params = {
      promoId: this.props.promoId
    };
    this.props.actionDownloadPromoTracker(params);
  };

  submitGA(action,label){
    CreatePromoGA({ action, label});
  }
  render() {
    const { skuData, actionGetSku, promoStatus } = this.props;
    const addSkuProps = {
      skuData,
      actionGetSku,
    };
    return (
      <Card style={{padding:"15px"}}>
        <PageLoader loader={this.props.loader}>
          <div style={{paddingLeft:"10px"}}><BreadCrumb root="" child="Create New Promo" /></div>
          <div style={{ marginLeft: "15px" }}>
            {/* Basic Info start */}
            <BasicInfo
              {...this.state}
              bookNumberOptions={this.props.bookNumberOptions}
              handleDateChange={this.handleDateChange}
              updateState={this.updateState}
              onOfferChange={this.onOfferChange}
              promoStatus={promoStatus}
            />
            {/* Basic Info end */}
            <div className="spacer"></div>
            {/* Add SKU start */}
            <AddSku
              loader={this.props.loader}
              addSkuProps={addSkuProps}
              screenName="standalone"
              updateSkuArticlNumbers={this.updateSkuArticlNumbers}
              articleNumbers={this.state.articleArr}
              merchcatValues={this.props.merchcatValues}
              selectedMerchcat={this.state.selectedMerchcat}
              handleSelectedMerchcat={this.handleSelectedMerchcat}
              disabledApiCall={true}
              promoStatus={promoStatus}
            />
            <hr style={{ marginTop: "20px" }}/>
            {/* Add SKU end */}
            <div className="spacer"></div>
            <Parameters
              updateSkuArticlNumbers={this.updateSkuArticlNumbers}
              articleNumbers={this.state.articleArr}
              addPromoValues={this.addPromoValues}
              handlePromoValueError={this.handlePromoValueError}
              targetPromoValueArr={this.state.targetPromoValueArr}
              error={this.state.promoValueError}
              promoStatus={promoStatus}
              selectedMerchcat={this.state.selectedMerchcat}
            />
            <div className="spacer"></div>
            <Actions
              {...this.state}
              getSimulationResult={this.simulateData}
              savePromo={this.savePromo}
              submitPromo={this.submitPromo}
              simulationData={this.props.simulationData}
              promoStatus={promoStatus}
              userInfo={this.props.userInfo}
              promoId={this.props.promoId}
              enableSave={this.state.enableSave}
              enableSubmit={this.state.enableSubmit}
              enableSimulation={this.state.enableSimulation}
              targetPromoValueArr={this.state.targetPromoValueArr}
            />
            {isEmpty(this.props.simulationData) ? null : (
              <>
                <SimulationResult
                  simulationData={this.props.simulationData}
                  handleDownload={this.handlePromoDownload}
                  promoTrackerDownload={this.handlePromoTrackerDownload}
                  promoStatus={promoStatus}
                />
              </>
            )}
          </div>
        </PageLoader>
      </Card>
    );
  }
}

const mapStateToProps = state => ({
  skuData: state.createPromoData.skuData,
  loader: state.createPromoData.loading,
  simulationData: state.createPromoData.simulationData,
  bookNumberOptions: state.createPromoData.bookNumbers,
  promoId: state.createPromoData.promoId,
  merchcatValues: state.createPromoData.merchcatValues,
  promoData: state.createPromoData.promoData,
  promoStatus: state.createPromoData.promoStatus,
  userInfo: state.userManagementData.userInfo.action
});

const mapDispatchToProps = dispatch => {
  return {
    actionGetSku: value => dispatch(actionGetSku(value)),
    getSimulationResult: val => dispatch(dataSimulate(val)),
    getBookNumbersList: val => dispatch(getBookNumbersList(val)),
    savePromo: val => dispatch(savePromo(val)),
    submitPromo : val => dispatch(submitPromo(val)),
    resetPromoId: val => dispatch(resetPromoId(val)),
    getCategoryList: val => dispatch(getCategoryList(val)),
    actionFetchPromo: val => dispatch(actionFetchPromo(val)),
    actionDownloadPromo: val => dispatch(actionDownloadPromo(val)),
    actionDownloadPromoTracker: val=> dispatch(actionDownloadPromoTracker(val)),
    actionResetPromoStatus: val => dispatch(actionResetPromoStatus(val))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(CreatePromo));
