import React, { Fragment, useState, useEffect } from 'react';
import { VIEW_TYPES, PERFORMANCE_TYPES } from './constants';
import { Nav } from 'react-bootstrap';
const DashboardHeader = (props) => {
  const [view, setView] = useState(props.view);
  const [performance, setPerformance] = useState(props.type);
  const changeTab = (eventKey) => {
    props.changeView({
      view: eventKey
    })
    setView(eventKey);
  };
  const onPerformanceChange = (e) => {
    const { name, value } = e.target;
    props.changeView({
      type: value
    })
    setPerformance(value);
  };
  return (
    <React.Fragment>
      <div className="row dashboard-header">
        <div className="col-12">
        <Nav
          fill
          variant="tabs"
          onSelect={changeTab}
          defaultActiveKey="consolidated"
          activeKey={view}>
          {VIEW_TYPES.map((obj, index) => (
            <Nav.Item key={obj.key}>
              <Nav.Link eventKey={obj.key}>
                <b className="tab-header">{obj.label}</b>
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
        </div>
      </div>
      <div className="row dashboard-header" style={{ marginTop: '20px' }}>
        <div className="col-2"></div>
        <div className="col-8">
          <div className="row">
            {PERFORMANCE_TYPES.map((obj, index) => (
              <div className="col-4">
                <input
                  type="radio"
                  value={obj.key}
                  checked={performance === obj.key}
                  onChange={onPerformanceChange}
                  name="performance"
                />{' '}
                &nbsp;&nbsp;<b className="labelName">{obj.label}</b>
              </div>
            ))}
          </div>
        </div>
        <div className="col-2"></div>
      </div>
    </React.Fragment>
  );
};

export default DashboardHeader;
