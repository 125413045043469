import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import ModalBody from 'react-bootstrap/ModalBody';
import ModalHeader from 'react-bootstrap/ModalHeader';
import ModalFooter from 'react-bootstrap/ModalFooter';
import isEmpty from 'lodash/isEmpty';
import { Form, Row, Col, Card } from 'react-bootstrap';
import GroupTableCompnent from './groupTableComponent';
import DynamicFilters from '../DynamicFilters';
import MultiSelectCheckboxes from '../MultiSelectCheckboxes';
import PageLoader from '../../utils/Loader';
import PageWrapper from '../common/PageWrapper';
import Footer from './popupFooterComponent';
import _ from 'lodash'
import './index.css';
import { ToastMessage } from '../../common/ToastMessage';
// import { ModalBodyStyled } from "./styled";

function GroupProduct(props) {
  const [vendor, setVendor] = useState([]);
  const [article, setArticle] = useState([]);
  const [groupData, setGroupData] = useState([]);
  const [loader, setLoder] = useState(false);
  const [groupName, setGroupName] = useState('');
  const [vendorOptions, setVendorOption] = useState([]);
  const [articleOptions, setArticleOptions] = useState([]);
  const [error, setError] = useState('');
  const [isGroupExist, setIsGroupExist] = useState(false);
  const [isValidGroupName, setIsValidGroupName] = useState();
  const [updatedData, setUpdatedData] = useState([]);
  useEffect(() => {
    setGroupName(props.groupName);
  }, [props.groupName]);

  useEffect(() => {
    if(!isEmpty(props.groupDetails)){
      const {groupName, vendor, articleNo} =props.groupDetails;
      setGroupName(groupName);
      setVendor([{value: vendor, label: vendor }]);
      const articleData = articleNo.map(ele => {
        return {value: ele, label: ele }
      });
      setArticle(articleData);
    }

  },[props.groupDetails])

  const formatDropDownOtion = (valueArr) => {
    let data = valueArr.map((val) => {
      return {
        label: val,
        value: val,
      };
    });
    return data;
  };
  useEffect(() => {
    let vendorOptions = [];
    if (props.vendorList.length > 0) {
      vendorOptions = formatDropDownOtion(props.vendorList);
    }
    setVendorOption(vendorOptions);
  }, [props.vendorList]);

  useEffect(() => {
    setArticle(props.selectedArticle)
    let params = {
      article: props.selectedArticle
    }
    updateArticleState(params);
  }, [props.selectedArticle]);

  const checkCommanDD = data => {
    let cmndd = data.reduce((p, c) => p.filter((e) => {
      if(e)
      return c.includes(e)}));
    cmndd.length === 0 
      ? setError('* Group cannot be created, no common discount point')
      : props.constraintDD.every(r => cmndd.includes(r)) ? setError('') 
      : setError('* Group cannot be created, no common discount point');
  }
  useEffect(() => {
    setGroupData(props.groupArticleData);
    if (props.groupArticleData.length > 0) {
      let ddArr = props.groupArticleData.map((ele) => {
        return ele.discountDepth;
      });
      if(ddArr.length>0){
      checkCommanDD(ddArr);}
    }
  }, [props.groupArticleData]);

  useEffect(() => {
    let articleOptions = [];
    if (props.articleList && props.articleList.length > 0) {
      articleOptions = formatDropDownOtion(props.articleList);
    }
    if(article.length>0){
      const tempData = article;
      const onlyArticlesFromOptions = articleOptions.map(item => item.value);
      const onlyArticlesFromSelections = article.map(item => item.value);
      onlyArticlesFromSelections.forEach((item, index) => {
        if (!onlyArticlesFromOptions.includes(item)) {
          articleOptions.push(article[index])
        }
      })
    }
    console.log("========setarticleoptions=======");
    console.log(articleOptions);
    setArticleOptions(articleOptions);
  }, [props.articleList]);

  useEffect(() => {
    let ddArr = groupData.map((ele) => {
      return ele.discountDepth;
    });
    if(ddArr.length>0){
    checkCommanDD(ddArr);}
  },[groupData])

  const updateVendorState = (params) => {
    const { vendor } = params;

    const selectedVendor = vendor.map((ele) => ele.value);
    setVendor(vendor);

    const reqObj = {
      sendInactive: false,
      merchcat: props.merchcat,
      vendor: selectedVendor,
      sendSimulated: true,
      planId: props.getPlanId()
    };
    setGroupData([]);
    props.actionGetArticleList(reqObj);
    props.actionSetSelecteArticle([]);
  };
  const updateArticleState = (params) => {
    let tempData = [];
    if(params.article.length>0){
      tempData.push(...params.article);
    }
    
    const selectedarticle = tempData.map((ele) => ele.value);
    setArticle(params.article);
    const reqObj = {
      articleNo: selectedarticle,
    };
    props.actionfetchGroupDiscountDepth(reqObj);
  };

  const handleGroupNameChange = (event) => {
    let name = event.target.value;
    
    if(!isAlphaNumeric(name)){
      ToastMessage(403,'Please enter a valid Group Name');
      setGroupName('');
      // return
    } else {
      setGroupName(name);
    }
  };
  const isAlphaNumeric =(groupName)=>{
    let regEx = /^[A-Z0-9a-z_]+$/;
    return regEx.test(groupName);
  }
  const focusLost = async (event) => {
    if (props.mode === 'create' && groupName.length > 0) {
      // if(!isAlphaNumeric(groupName)){
      //   ToastMessage(403,'Please enter a valid Group Name');
      //   setGroupName('');
      //   return
      // }
      const result = await props.isValidGroup(groupName);
      setIsGroupExist(result);
    }
  };

  const createGroup = () => {
    let args = {
      articleNo: article.map((ele) => `${ele.value}`),
      groupName,
      vendor: vendor.map((ele) => ele.value)
    };
    props.handleCreate(args);
  };

  const updatedTableData = (data) => {
    const selectedArticles = data.updatedData.map(item => item.articleNo)
    // setArticle(data.articleOptions.filter(optionItem => selectedArticles.includes(optionItem.value)));
    // setArticle(formatDropDownOtion(selectedArticles));
    let formattedData = formatDropDownOtion(selectedArticles)
    props.actionSetSelecteArticle(formattedData)
    setGroupData(data.updatedData);
  };

  const handleCancel = () => {
    setGroupData(props.groupArticleData);
    props.handleCancel();
  };

  const handleDeleteGroup = () => {
    const args = {
      groupid: groupName
    };
    props.handleDeleteGroup(args);
  }

  const handleSaveGroup = () => {
    let args = {
      articleNo: article.map((ele) => `${ele.value}`),
      groupName,
      vendor: vendor.map((ele) => ele.value)
    };

    props.handleSaveGroup(args);
  }

  return (
    <>
      
        {/* <PageWrapper> */}
          <Modal dialogClassName="constraints-popup modal-90w" show={props.isShow} onHide={props.handleCancel}>
          <PageLoader loader={props.loader}>
            <ModalHeader closeButton>
              <Modal.Title>
                {props.mode === 'create'
                  ? 'Create Group'
                  : props.mode === 'edit'
                  ? 'Edit Group'
                  : groupName}
              </Modal.Title>
            </ModalHeader>
            {/* <ModalBodyStyled> */}
            <ModalBody>
              <div>
                {props.mode === 'view' && vendor.length>0 ? (
                  <div>
                    <span>
                      <b> Vendor : </b> {vendor[0].value}
                    </span>
                  </div>
                ) : (
                  <div>
                    <div className="row">
                      <div className="col-md-4">
                        <Form.Label>
                          <b> Group Name </b>
                        </Form.Label>
                        <Form.Control
                          size="md"
                          type="text"
                          name="groupName"
                          value={groupName}
                          onChange={handleGroupNameChange}
                          onMouseLeave={focusLost}
                          placeholder="Enter Group Name"
                          disabled={props.mode === 'edit'}
                        />
                      </div>
                      <div className="col-md-4">
                        <Form.Label>
                          <b> Vendor </b>
                        </Form.Label>
                        <MultiSelectCheckboxes
                          placeholderButtonLabel="Select Vendor"
                          mappingKey="vendor"
                          options={vendorOptions}
                          value={vendor}
                          updateState={updateVendorState}
                          isMulti={false}
                        />
                      </div>
                      <div className="col-md-4">
                        <Form.Label>
                          <b> Article </b>
                        </Form.Label>
                        <MultiSelectCheckboxes
                          placeholderButtonLabel="Select Article"
                          mappingKey="article"
                          options={articleOptions}
                          value={article}
                          updateState={updateArticleState}
                          allowRowClickSelection={true}
                          defaultSelectedOptions={true}
                          // isMulti={false}
                        />
                      </div>
                    </div>
                    <div>
                      {isGroupExist && <span className="invalid">Group already Exists. </span>}
                    </div>
                  </div>
                )}
              </div>
              <div className="marginTop">
                <GroupTableCompnent 
                  rowData={groupData} 
                  updatedTableData={updatedTableData} 
                  mode={props.mode}
                  articleOptions={articleOptions}
                />
              </div>
            </ModalBody>
            <ModalFooter>
              <Footer
                mode={props.mode}
                handleEditGroup={handleSaveGroup}
                handleDelete={handleDeleteGroup}
                createGroup={createGroup}
                handleCancel={handleCancel}
                error={error}
                isGroupExist={isGroupExist}
                groupName={groupName}
                groupData={groupData}
              />
             
            </ModalFooter>
            <div className="display-center">
              <span className="invalid autoMargin"> {error.length > 0 ? error : null} </span>
            </div>
            </PageLoader>
          </Modal>
        {/* </PageWrapper> */}
     
    </>
  );
}
export default GroupProduct;
