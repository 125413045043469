import React, { Fragment, useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import environment from "../../environments/environment";
const SessionExpire = props => {
    const [show, setShow] = useState(props.isSessionExpired);
  useEffect(() => {
    setShow(props.isSessionExpired);
  }, [props.isSessionExpired])
  const handleClose = () => {
    // props.onModalClose();
    setShow(false);
    // localStorage.setItem("isSessionExpired", true);
    window.location.href = `${environment.baseUrl}/user/login`;
    // window.open(`${environment.baseUrl}/user/login`,'_blank')    
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} backdrop="static">
        <Modal.Header closeButton>
          <Modal.Title>Session Timeout</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <p>You're being timed out due to inactivity.</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SessionExpire