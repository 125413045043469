import {
  SET_FINALIZE_PROMO_DATA,
  SET_LOADER,
  RESET_FINALIZE_PROMO_DATA,
  DELETE_FINALIZE_PROMO
} from "./types";
import {
  fetchFinalizePromoData,
  deleteFinalizePromoData,
  finalizePromoData,
  downloadFinalizePromoData
} from "../apis/finalizePromo.api";
import environmentModule from "../environments/environment";
import { ToastMessage } from "../common/ToastMessage";


export const actionGetfinalizePromo = reqObj => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await fetchFinalizePromoData(reqObj);
    const formattedData = result.map(row => {{
      if (row.type != 'promo') {
        let temp = `${row.id}-${row.name}`;
        row.name = temp;
      }
      return row;
    }});
    dispatch({ type: SET_FINALIZE_PROMO_DATA, payload: formattedData });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionResetFinalizePromo = () => async dispatch => {
  try {
    dispatch({ type: RESET_FINALIZE_PROMO_DATA });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionDeleteFinalizePromo = reqObj => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    await deleteFinalizePromoData(reqObj);
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionFinalizePromo = reqObj => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    await finalizePromoData(reqObj);
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionDownloadFinalizePromo = reqObj => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await downloadFinalizePromoData(reqObj);
    window.open(result.data, "_self");
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};
