import React, {Component} from "react";
import {Tooltip} from "reactstrap";
import Popover from "../common/promoPopup";

class calendarWrapperDataDiv extends Component {
    ref = React.createRef();
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {

        let caldendarDataIndex = this.props.calendarDataArr
            ? this.props.calendarDataArr[this.props.indexRow]
            : null;
        return (
            <div className="calendarRow" >
                <div
                    className="RowSegment RowSegmentStyle"
                    style={{
                    borderLeft: "5px solid " + caldendarDataIndex.color_code_border,
                    backgroundColor: caldendarDataIndex.color_code,
                    flexBasis: this.props.styleMargin.flexBasis,
                    marginLeft: this.props.styleMargin.marginLeft
                }}
                    onClick={() => this.setState({showPopUp: true})}
                    ref={this.ref}>
                    <div class="calendar-text">
                    
                        {this.props.styleMargin.flexBasisInt < 20
                            ? `${caldendarDataIndex
                                .name
                                .substring(0, 2)}...`
                            : caldendarDataIndex.name}
                        {(this.props.calendarDataArr[this.props.indexRow].color_case !== "Completed ") && (
                            <React.Fragment>
                                <i
                                    id={`recommendedPromo-button-${this.props.indexRow}`}
                                    // className="fa fa-chevron-circle-right calendar-text--button"
                                    aria-hidden="true"
                                    // onClick={e => this
                                    // .props
                                    // .getRecommendedData(e, caldendarDataIndex)}
                                    ></i>
                                <Tooltip
                                    placement="bottom"
                                    isOpen={this.props.showRecommendedPromoToolTip && this.props.tooltipId === this.props.indexRow}
                                    target={`recommendedPromo-button-${this.props.indexRow}`}
                                    toggle={() => this.props.toogleRecommendedPromoToolTip(this.props.indexRow)}>
                                    {this.props.markdownlinkShow == 1
                                        ? "Recommended Markdown"
                                        : "Recommended Promo"}
                                </Tooltip>
                                {this.props.markdownlinkShow == 1 && (
                                    <React.Fragment>
                                        <i
                                            id={`recommendedmarkPromo-button-${this.props.indexRow}`}
                                            // className="fa fa-wpexplorer calendar--markdown--button"
                                            // aria-hidden="true"
                                            // onClick={e => this
                                            // .props
                                            // .getRecommendedMarkData(e, this.props.calendarDataArr[this.props.indexRow])}
                                            >
                                            </i>
                                        <Tooltip
                                            placement="bottom"
                                            isOpen={this.props.showRecommendedPromoMarkToolTip && this.props.tooltipMarkId === this.props.indexRow}
                                            target={`recommendedmarkPromo-button-${this.props.indexRow}`}
                                            toggle={() => this.props.toogleRecommendedPromoMarkdownToolTip(this.props.indexRow)}>
                                            Global Promo Details
                                        </Tooltip>
                                    </React.Fragment>
                                )}
                            </React.Fragment>
                        )}
                    </div>
                    <div
                        style={{
                        marginTop: "5px",
                        cursor: "pointer",
                        maxHeight: "1.8rem"
                    }}>
                        <Popover
                            item={{
                            placement: "bottom",
                            text: "Left"
                        }}
                        container={this.ref.current}
                            id={this.props.indexRow}
                            showPromotionMain={this.props.showPromotionMain}
                            showPromotionMainWithValues={this.props.showPromotionMainWithValues}
                            onpromoButtonClick={this.props.promoButtonClick}
                            markdownlinkShow={this.props.markdownlinkShow}
                            promoDetailsHandler={this.props.promoDetailsHandler}
                            info={caldendarDataIndex}
                            onTabClick={this.props.onTabClick}
                            name="rohan"/>
                    </div>
                </div>
            </div>
        );
    }
}

export default calendarWrapperDataDiv;
