import React, { useEffect, useState } from 'react';
import MultiSelectCheckboxes from '../MultiSelectCheckboxes';
import ConstraintDiscountDepthTable from './constraintsDiscountDepthTable';
import _ from "lodash";
const ConstraintDiscountDepth = (props) => {
  const [discountTableData, setDiscountTableData] = useState(props.discountDepth);
  const [error, setError] = useState(props.isError);
  const [activeDiscount, setActiveDiscount] = useState(props.activeDiscountChangedKey);
  const [discountDepth, setDiscountDepth] = useState([]);
  const applyDiscountDepth = (obj) => {

    let cycleObj = obj.cycleState.map(obj=> obj.value)[0]
    // let tableIndex = discountTableData.map(obj=> obj.cycle).indexOf(cycleObj)
    // console.log(cycleObj ,tableIndex)
    let  appliedDiscountData = [...discountTableData]
    switch (obj.mappingKey) {

      case 'minDiscount':
        obj.cycleState.forEach(discountCycleObj=>{
          // let tableIndex = discountTableData.map(obj=> obj.cycle).indexOf(discountCycleObj.value)
          let tableIndex = _.findIndex(discountTableData, ['cycle', discountCycleObj.value]);
          if(tableIndex !== -1){
            let discountObj = appliedDiscountData[tableIndex];
            discountObj.min = obj.state.map(obj=> obj.value)[0]
            appliedDiscountData.splice(tableIndex,1,_.cloneDeep(discountObj))
          }else{
            let discountObj = {
              cycle : discountCycleObj.value,
              min : obj.state.map(obj=> obj.value)[0],
              max: null,
              fixed: null,
              quarter: props.selectedQuarter
            };
            appliedDiscountData.push(discountObj)
          }
        })
          
          setDiscountTableData([...appliedDiscountData] );
        break;
      case 'maxDiscount':
        obj.cycleState.forEach(discountCycleObj=>{
          // let tableIndex = discountTableData.map(obj=> obj.cycle).indexOf(discountCycleObj.value)
          let tableIndex = _.findIndex(discountTableData, ['cycle', discountCycleObj.value]);
          if(tableIndex !== -1){
            let discountObj = appliedDiscountData[tableIndex];
            discountObj.max = obj.state.map(obj=> obj.value)[0]
            discountTableData.splice(tableIndex,1,_.cloneDeep(discountObj))
          }else{
            let discountObj = {
              cycle : discountCycleObj.value,
              max : obj.state.map(obj=> obj.value)[0],
              min: null,
              fixed: null,
              quarter: props.selectedQuarter
            };
            appliedDiscountData.push(discountObj)
          }
        })
          setDiscountTableData([...appliedDiscountData]);
        break;
      case 'fixedDiscount':
        obj.cycleState.forEach(discountCycleObj=>{
          // let tableIndex = discountTableData.map(obj=> obj.cycle).indexOf(discountCycleObj.value)
          let tableIndex = _.findIndex(discountTableData, ['cycle', discountCycleObj.value]);
          if(tableIndex !== -1){
            let discountObj = appliedDiscountData[tableIndex];
            discountObj.fixed = obj.state.map(obj=> obj.value)[0]
            appliedDiscountData.splice(tableIndex,1,_.cloneDeep(discountObj))
          }else{
            let discountObj = {
              cycle : discountCycleObj.value,
              fixed : obj.state.map(obj=> obj.value)[0],
              min: null,
              max: null,
              quarter: props.selectedQuarter
            };
            appliedDiscountData.push(discountObj)
          }
        })
          setDiscountTableData([...appliedDiscountData]);
        break;

      default:
        break;


    }
    props.applyDiscountDepth(obj,appliedDiscountData);
  };

useEffect(() => {
    setDiscountTableData([...props.discountDepth])
}, [props.discountDepth])
useEffect(() => {
  setError(props.isError)
}, [props.isError])
useEffect(() => {
  setActiveDiscount(props.activeDiscountChangedKey)
}, [props.activeDiscountChangedKey]);
useEffect(()=>{
  setDiscountDepth(props.discountDepthList)
},[props.discountDepthList]);

  return (
    <React.Fragment>
      <div className="row">
        <div className="col-8">
          {discountDepth.map((cycleObj,index) => (
            <>
              <div className="row">
                <div className="col-3">{cycleObj.label}</div>
                <div className={error.length > 0 && activeDiscount === cycleObj.mappingKey ? "col-3 error" :"col-3" }>
                  <MultiSelectCheckboxes
                    placeholderButtonLabel="Select Cycles"
                    options={cycleObj.cycleOptions}
                    value={cycleObj.cycleState}
                    mappingKey={cycleObj.cycleMappingKey}
                    updateState={props.updateDiscountCycles}
                    onMenuCloseApiFun={()=>{
                      props.discountValues(cycleObj)
                    }}
                    onMenuCloseApi={true}
                  />
                </div>
                <div className={error.length > 0 && activeDiscount === cycleObj.mappingKey ? "col-3 error" :"col-3" } >
                  <MultiSelectCheckboxes
                    placeholderButtonLabel={cycleObj.placeholder}
                    options={cycleObj.options}
                    value={cycleObj.state}
                    mappingKey={cycleObj.mappingKey}
                    updateState={props.updateState}
                    onMenuCloseApi={false}
                    isMulti={false}
                  />
                </div>
                <div className="col-3">
                  <button
                    className="btn btn-primary iconbutton download-btn"
                    disabled={(cycleObj.state && (cycleObj.state.length ===0 || cycleObj.cycleState.length === 0)) || error.length > 0}
                    onClick={() => {
                      applyDiscountDepth(cycleObj);
                    }}>
                    Apply
                  </button>
                </div>
              </div>
              <div className="spacer"></div>
            </>
          ))}
        </div>
        <div className="col-4">
          {' '}
          <ConstraintDiscountDepthTable  rowData={discountTableData}/>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ConstraintDiscountDepth;
