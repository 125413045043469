import React from "react";
import PropTypes from "prop-types";

const BreadCrumb = props => {
  const { root, child } = props;

  return (
    <>
      <div >
        <h4 className="BreadCumHeading mb-0">
          {root} <span className="heading-top">{child}</span>
        </h4>
      </div>

      {/* <hr style={{ margin: "0px" }} /> */}
    </>
  );
};

export default BreadCrumb;
