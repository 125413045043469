import React, { Component } from 'react';
import CanvasJSReact from '../assets/canvasjs.react';
import { cloneDeep, isEqual } from 'lodash';
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
function emptyValueFormatter(value) {
  if (value) {
    var toSting = Math.round(value);
    if (value > 0) {
      return toSting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    } else {
      var splitAvalue = Math.abs(toSting);
      return (
        '(' + splitAvalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') + ')'
      );
    }
  } else {
    if (value == 0) {
      return 0;
    } else {
      return '-';
    }
  }
}
class WaterfallChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: null,
      optionsWaterfall: {}
    };
  }

  setWaterFallOptions = () => {
    this.setState({
      optionsWaterfall: {
        animationEnabled: true,
        exportEnabled: true,
        title: {
          text: this.props.selectedTab,
          fontSize: 14,
          fontFamily: 'Poppins'
        },
        axisY: {
          labelFontSize: 12,
          labelMaxWidth: 70,
          labelFontColor: 'black',
          gridThickness: 0,
          stripLines: [
            {
              value: 0,
              showOnTop: true,
              color: 'gray',
              thickness: 2
            }
          ]
        },
        axisX: {
          labelFontColor: 'black',
          labelFontSize: 12
        },
        toolTip: {
          shared: true,
          contentFormatter: function(e) {
            return (
              e.entries[0].dataPoint.label +
              ':' +
              emptyValueFormatter(e.entries[0].dataPoint.y)
            );
          }
        },
        height: 250,
        data: [
          {
            risingColor: '#5B9BD5',
            fallingColor: '#ED7D31',
            type: 'waterfall',
            indexLabelOrientation: 'vertical',
            lineColor: 'gray',
            dataPoints: [
              {
                label: 'Baseline',
                y: Math.round(
                  this.props.analysisData.find(f => f.metric === 'Baseline')
                    .value
                )
              },
              {
                label: 'Incremental',
                y: Math.round(
                  this.props.analysisData.find(f => f.metric === 'Incremental')
                    .value
                )
              },

              {
                label: 'Affinity',
                y: Math.round(
                  this.props.analysisData.find(f => f.metric === 'Affinity')
                    .value
                )
              },
              {
                label: 'Cannibalization',
                y: Math.round(
                  this.props.analysisData.find(
                    f => f.metric === 'Cannibalization'
                  ).value
                )
              },
              {
                label: 'Pull Forward',
                y: Math.round(
                  this.props.analysisData.find(f => f.metric === 'Pull Forward')
                    .value
                )
              },
              {
                label: 'Total',
                // y: Math.round(
                //   this.props.analysisData.find(f => f.metric === 'Total').value
                // ),
                isCumulativeSum: true
              }
            ]
          }
        ]
      }
    });
  };

  componentDidMount() {
    this.setWaterFallOptions();
  }

  componentDidUpdate(prevProps) {
    if(prevProps.currentPromo){
      if (
        prevProps.selectedTab !== this.props.selectedTab ||
        prevProps.currentPromo.id !== this.props.currentPromo.id
      ) {
        this.setWaterFallOptions();
      }
    }

    let analysisDataCopy = cloneDeep(prevProps.analysisData);
    if (!isEqual(analysisDataCopy, this.props.analysisData)) {
      this.setWaterFallOptions();
    }
  }
  render() {
    return (
      <div>
        <div className="card">
          <CanvasJSChart options={this.state.optionsWaterfall} containerProps={this.props.containerProps}/>
          <div className="watermarkdiv" />
        </div>
      </div>
    );
  }
}
export default WaterfallChart;
