import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import MultiSelectCheckboxes from "../../../MultiSelectCheckboxes";
import ModifySkuModal from "../ModifySku";
import { getVendorHistoryTable, getSkuDataTable } from "../../budgetPlanAction";
import { isEmpty} from "lodash";
import {getDisStateFrmStatus} from "../../../../utils/helperFunctions"
import { BudgetPlanningGA } from '../../../../common/GAUtils';
const Filter = ({
  merchcats = [],
  selectedMerchcats = [],
  vendors = [],
  selectedVendors = [],
  getMerchcatSpecificVendors,
  updateState,
  years,
  selectedYearVal,
  setYearSelected,
  setSelectedCategory,
  quarters,
  selectedQuarters,
  promoType,
  setPromoType,
  getBookData,
  bookNumbers,
  selectedBookNumbers,
  getVendorHistory,
  finalizeBudget,
  isExistingPlan,
  handleModifySaveClick,
  dselectedSKU,
  planId,
  planStatus
}) => {
  const [isVisible, setIsVisible] = useState(false);
  const [year, setYear] = useState([]);
  // const [isDisplayBookNumber, setIsDisplayBook] = useState(false);
  /*
    as per discussion with analytics team they are not performing predictions at book level, only quarters level
  */
  const disablingBookNumber = false;
  const disablingOfferType = false;

  const onShowPlan = () => {
    const reqObj = {
      filters: {vendor: selectedVendors.map(ele => ele.value),
      category: [selectedMerchcats[0].value],
      fYear: [selectedYearVal.value],
      quarter: selectedQuarters.map(ele => ele.value.substring(1, 2)),
    },
    planId: planId=== 0 ? '' : planId
  };
    getVendorHistory(reqObj);
    finalizeBudget();
  };
  useEffect(() => {
    if(Object.keys(selectedYearVal).length > 0){
      setYear([selectedYearVal])
    }
  }, [selectedYearVal])
  const submitGA =(action,label)=>{
    BudgetPlanningGA({ action, label});
  }
  return (
    <div>
      {isVisible && (
        <ModifySkuModal
          isVisible={isVisible}
          setIsVisible={setIsVisible}
          merchcat={[selectedMerchcats[0].label]}
          handleModifySaveClick={handleModifySaveClick}
          vendor={selectedVendors.map(_o => _o.label)}
          dselectedSKU={dselectedSKU}
        />
      )}

      <div className="row filter-block" >
        <div className="col-md-12" style={{marginLeft:"10px"}}>
          <div className="row">
            <div className="col-md-3">
              <label className="budget-labels">Category</label>
              <MultiSelectCheckboxes
                placeholderButtonLabel="Select category"
                options={merchcats}
                value={selectedMerchcats}
                mappingKey="selectedMerchcats" // mappingkey will be same as value property but in string format
                // onMenuCloseApi={obj.onMenuCloseApi}
                onMenuCloseApiFun={() => {
                  return;
                }}
                updateState={value => setSelectedCategory(value.selectedMerchcats)}
                isMulti={false}
                defaultSelectedOptions={true}
                disabled={getDisStateFrmStatus(planStatus) || planId || planStatus=="PREDRAFT"}
              />
            </div>
            <div className="col-md-3">
              <label className="budget-labels">Vendor</label>
              <MultiSelectCheckboxes
                placeholderButtonLabel="Select vendor"
                options={vendors}
                value={selectedVendors}
                mappingKey="selectedVendors" // mappingkey will be same as value property but in string format
                // onMenuCloseApi={obj.onMenuCloseApi}
                onMenuCloseApiFun={() => {
                  return;
                }}
                updateState={updateState}
                defaultSelectedOptions={true}
                disabled={getDisStateFrmStatus(planStatus) || planStatus=="PREDRAFT"}
              />
            </div>
            <div className="col-md-3">
              <label className="budget-labels">Fiscal Year</label>
               <MultiSelectCheckboxes
              placeholderButtonLabel="Select Fiscal Year"
              options={years}
              value={isEmpty(selectedYearVal) ? [] : [selectedYearVal]}
              mappingKey="selectedYearVal" // mappingkey will be same as value property but in string format
              updateState={value => setYearSelected(value.selectedYearVal[0])}
              defaultSelectedOptions={true}
              isMulti={false}
              disabled={getDisStateFrmStatus(planStatus) || planStatus=="PREDRAFT"}
            /> 
            </div>
            <div className="col-md-3 ">
              <button
                type="button"
                onClick={() => {
                  submitGA("Click","click on modify sku");
                  setIsVisible(true); }}
                className="btn btn-primary top-margin p-2"
                disabled={selectedMerchcats.length === 0|| selectedQuarters.length === 0 || getDisStateFrmStatus(planStatus) || planStatus=="PREDRAFT"}
              >
                Modify SKU
              </button>
            </div>
          </div>
        </div>
        <div className="col-md-12" style={{paddingBottom:"15px",marginLeft:"10px"}}>
          <div className="row">
            <div className="col-md-3">
              <label className="budget-labels">Quarter</label>
              <MultiSelectCheckboxes
                placeholderButtonLabel="Select quarter"
                options={quarters}
                value={selectedQuarters}
                mappingKey="selectedQuarters" // mappingkey will be same as value property but in string format
                onMenuCloseApiFun={() => getBookData()}
                updateState={updateState}
                defaultSelectedOptions={true}
                disabled={getDisStateFrmStatus(planStatus) || planStatus=="PREDRAFT"}
              />
            </div>
            <div className="col-md-3">
            {disablingOfferType && <label className="budget-labels">Offer Type</label>}
            {disablingOfferType && <div className="budget-offer-type">
             <span> 
              <input
                type="radio"
                onChange={event => setPromoType(event.target.value)}
                name="selectedPromo"
                value="BBM"
                checked={promoType === "BBM"}
              ></input>
              <label className="budget-labels">BBM</label>
              </span>
              <span>
              <input
                type="radio"
                onChange={event => setPromoType(event.target.value)}
                name="selectedPromo"
                value="FOC"
                checked={promoType === "FOC"}
              ></input>
              <label className="budget-labels">FOC</label>
              </span>
              </div>}
            </div>
            <div className="col-md-3">
              {/*  set isDisplayBookNumber to true when need to display book number*/}
              {disablingBookNumber && <label className="budget-labels">Book number</label> }

             {disablingBookNumber && <MultiSelectCheckboxes
                placeholderButtonLabel="Select book"
                options={bookNumbers}
                value={selectedBookNumbers}
                mappingKey="selectedBookNumbers" // mappingkey will be same as value property but in string format
                updateState={updateState}
              />}
            </div>
            <div className="col-md-3 ">
              <button
                type="button"
                onClick={e => {
                  onShowPlan();
                }}
                className="btn btn-primary top-margin p-2"
                disabled={
                  !selectedVendors.length || selectedQuarters.length === 0 || getDisStateFrmStatus(planStatus) || planStatus=="PREDRAFT"
                }
              >
                {/*this condition needs to be added to the disabled when book numbers are displayed
                 || !selectedBookNumbers.length */}
                Finalize Budget
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    getVendorHistory: val => dispatch(getVendorHistoryTable(val)),
  };
};

export default connect(null, mapDispatchToProps)(Filter);
