import React from "react";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalFooter from "react-bootstrap/ModalFooter";
import isEmpty from "lodash/isEmpty";
import EditableDropDown from "./editableDropDownComponent/editableDropDownCompoent"
import './index.css';

const ConfirmationModal = props => {
  return (
    <Modal show={props.isShow} onHide={() => {}} centered>
      <ModalHeader />
      <ModalBody>
        <div>
          <h6 className="displayText">{props.message}</h6>
        </div>
      </ModalBody>
      <ModalFooter>
        <button
          onClick={() => props.handleConfirmModel(false)}
          className="btn btn-primary confirmationButton"
        >
          No
        </button>
        <button
          onClick={() => props.handleConfirmModel(true)}
          className="btn btn-primary saveButton"
        >
          Yes
        </button>
      </ModalFooter>
    </Modal>
  );
};
export default ConfirmationModal;
