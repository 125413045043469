import { callApi } from "./config";
import { MESSAGES } from "../common/app-constants";
import { ToastMessage } from "../common/ToastMessage";

export const getUserCategoryRoles = reqObj => {
    return callApi
      .get(`/loggeduser/categoryRoles`)
      .then(res => {
        if(res.status){
          return res.data.data
        }
        return [];
      })
      .catch(err => {
        ToastMessage(err.response.status,err.response.data.message)
        return err;
      });
  };

  export const getAllUsers = () => {
    return callApi
      .get(`/loggeduser/allUsers`)
      .then(res => {
        if(res.status){
          return res.data.data
        }
        return [];
      })
      .catch(err => {
        return err;
      });
  };

  export const saveCategoryAdmin = reqObj => {
    return callApi
    .post(`/loggeduser/categoryAdmin`, reqObj)
    .then(res => {
      if(res.status){
        ToastMessage(res.status,"Saved successfully")
        return res.data.data
      }
      return [];
    })
    .catch(err => {
      return err;
    });
  }

  
  export const loggeduserInfo = () => {
    return callApi
      .get(`/loggeduser/roleActions`)
      .then(res => {
        if(res.status){
          return res.data.data
        }
        return {};
      })
      .catch(err => {
        console.log(err)
        return {};
      });
  };

  export const downloadUserDetails = reqObj => {
    return callApi
      .post("/loggeduser/downloadUsers", reqObj)
      .then(res => {
        ToastMessage(res.status,MESSAGES.DOWNLOAD_SUCCESS_MESSAGE)
        return res.data;
      })
      .catch(err => {
        ToastMessage(err.response.status,err.response.data.message)
        throw err;
      });
  }

  export const getVideos = reqObj => {
    return callApi
      .get(`/loggeduser/videoLinks`)
      .then(res => {
        if(res.status){
          return res.data.data
        }
        return [];
      })
      .catch(err => {
        ToastMessage(err.response.status,err.response.data.message)
        return err;
      });
  };

  export const updateSession = () => {
    return callApi
      .get(`/loggeduser/updateSession`)
      .then(res => {
        if(res.status){
          return res.data.data
        }
        return {};
      })
      .catch(err => {
        console.log(err)
        return {};
      });
  };