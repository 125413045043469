import {
  SET_FINALIZE_PROMO_DATA,
  SET_LOADER,
  RESET_FINALIZE_PROMO_DATA
} from "../actions/types";

const initialState = {
  finalizePromoData: [],
  loading: false
};

export default function(state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADER:
      return {
        ...state,
        loading: payload
      };
    case SET_FINALIZE_PROMO_DATA:
      return {
        ...state,
        finalizePromoData: payload
      };
    case RESET_FINALIZE_PROMO_DATA:
      return {
        ...state,
        finalizePromoData: []
      };
    default:
      return {
        ...state
      };
  }
}
