export const SET_SKU_DATA = "SET_SKU_DATA";
export const SET_VENDORHISTORY_TABLE = "SET_VENDORHISTORY_TABLE";
export const SET_PLAN_TABLE = "SET_PLAN_TABLE";
export const SET_METRICS_TABLE = "SET_METRICS_TABLE";
export const SET_LOADER = "SET_LOADER";
export const SET_BUDGET_PLAN_TABLE = "SET_BUDGET_PLAN_TABLE"
export const SET_BUDGET_PLAN_METRICS_TABLE = "SET_BUDGET_PLAN_METRICS_TABLE";
export const SET_DISCOUNT_DEPTH_LIST = "SET_DISCOUNT_DEPTH_LIST";
export const SET_BUDGET_PLAN_ROW_DATA = "SET_BUDGET_PLAN_ROW_DATA";
export const SET_YEAR_QUATERS_DATA = "SET_YEAR_QUATERS_DATA";
export const SET_PLAN_DATA = 'SET_PLAN_DATA';
export const SET_DESELECTED_SKUS = 'SET_DESELECTED_SKUS';
export const SET_PLAN_STATUS ="SET_PLAN_STATUS";
export const RESET_BUDGET_PLAN_ID_DATA = "RESET_BUDGET_PLAN_ID_DATA"
export const RESET_VENDORHISTORY_TABLE = "RESET_VENDORHISTORY_TABLE";
export const SET_OPTIMIZATION_LINK = "SET_OPTIMIZATION_LINK";
export const RESET_OPTIMIZATION_LINK = "RESET_OPTIMIZATION_LINK";
export const SET_BUDGET_PLAN_HEADERS = "SET_BUDGET_PLAN_HEADERS";
export const RESET_BUDGET_PLAN_HEADERS = "RESET_BUDGET_PLAN_HEADERS";
export const SET_BUDGET_PLAN_ERROR = "SET_BUDGET_PLAN_ERROR";
export const SET_AH6_DATA = "SET_AH6_DATA";
export const SET_INACTIVE_METRICS_DATA = "SET_INACTIVE_METRICS_DATA";
export const SET_VENDORHISTORY_BRAND_TABLE = "SET_VENDORHISTORY_BRAND_TABLE"
export const BUDGET_PLAN_CAMPAIGN_LIST = "BUDGET_PLAN_CAMPAIGN_LIST"
export const SET_UPDATED_DISCOUNT_DEPTH = "SET_UPDATED_DISCOUNT_DEPTH";
