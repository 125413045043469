import React,{useState, useEffect} from 'react';
import styled from 'styled-components';
import {getDisStateFrmStatus} from "../../../../utils/helperFunctions"

const FooterControls = props => {
  const { handleSaveOptimisedPlan, handleSubmitForApproval, planStatus, isDisableOptimise, updatedDiscounts, handleCopyPlan} = props;
  const [saveEnabled, setSaveEnabled]=useState(false);
  
  const saveDisabled = () => {
    if(!isDisableOptimise) {
      return true;
    } else {
        if(planStatus==="PENDING" || planStatus==="DRAFT" ||
           planStatus==="REJECTED" || getDisStateFrmStatus(planStatus)){
          return true;
        }
    }
    return false;
  }
  const submitDisabled = () => {
    if(!isDisableOptimise) {
      return true;
    } else {
        if(planStatus==="PENDING" || planStatus==="REJECTED" || getDisStateFrmStatus(planStatus)) {
          return true;
        }
    }
    return false;
  }
  const handleSave = () => {
    setSaveEnabled(true);
    handleSaveOptimisedPlan();
  }
  const handleSubmit = () => {
  
    handleSubmitForApproval();
  }
  const copyPlan = ()=>{
    props.handleCopyPlan();
  }
  const copyPlanDisabled = () =>{
      return planStatus=="DRAFT"
  }
  return (
    <FooterButtonsContainer>
      <div style={{ paddingRight: '25px' }}>
        <button
          type="button"
          className="btn btn-primary saveButton"
          onClick={()=>copyPlan()}
          disabled={!copyPlanDisabled()}
        >
          Copy Plan
        </button>
      </div>
      <div style={{ paddingRight: '25px' }}>
        {props.userInfo.indexOf('save')!==-1 ? (
          <>
          <button
          type="button"
          className="btn btn-primary saveButton"
          onClick={() => handleSave()}
          disabled={saveDisabled()}
        >
          Save
        </button>
          </>
        ): null}
      </div>
      <div>
        {props.userInfo.indexOf('submit')!==-1 ?
        (<button
          type="button"
          className="btn btn-primary saveButton"
          onClick={() => handleSubmit()}
          disabled={submitDisabled()}
        >
          Send for approval
        </button>): null}
      </div>
    </FooterButtonsContainer>
  );
};

export default FooterControls;

const FooterButtonsContainer = styled.div`
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
