import React, { useState, useEffect } from "react";
import DynamicFilters from "../../DynamicFilters";

const FinalizePromoFilter = props => {
  const [loader, setLoder] = useState(false);
  const {
    DynamicFiltersOptions,
    DynamicFiltersProps,
    updateState,
    filterData,
    onReset,
    isFilterButtonDisabled,
    screenName
  } = props;
  const hierarchyLevel = ["merchcat", "vendor"];
  const hierarchyApiCalls = [
    {
      optionsMappingKey: "merchcat",
      UrlName: "merchcat"
    },
    {
      optionsMappingKey: "year",
      UrlName: "selectedFiscalYear"
    },
    {
      optionsMappingKey: "quarter",
      UrlName: "selectedFiscalYear"
    },
    {
      optionsMappingKey: "offerType",
      UrlName: ""
    }
  ];
  const hierarchyFilterApi = "merchcatFilter";

  const openLoader = () => {
    setLoder(true);
  };
  const closeLoader = () => {
    setLoder(false);
  };
  return (
    <>
      <DynamicFilters
        filtersList={DynamicFiltersProps}
        options={DynamicFiltersOptions}
        hierarchyLevel={hierarchyLevel}
        hierarchyApiCalls={hierarchyApiCalls}
        hierarchyFilterApi={hierarchyFilterApi}
        openLoader={props.openLoader}
        closeLoader={props.closeLoader}
        updateParentState={updateState}
        filterData={filterData}
        onReset={onReset}
        viewType="verticle"
        isFilterButtonDisabled={isFilterButtonDisabled}
        disabledApiCallOnCascade ={true}
        screenName={screenName}
      />
    </>
  );
};
export default FinalizePromoFilter;
