export const GET_ERRORS  = "GET_ERRORS";
export const SET_DMMTABLE_COLDATA  = "SET_DMMTABLE_COLDATA";
export const SET_DMM_ROWDATA = "SET_DMM_ROWDATA";
export const SET_OFFERTABLE_COLDATA = "SET_OFFERTABLE_COLDATA";
export const SET_OFFER_ROWDATA = "SET_OFFER_ROWDATA";
export const LOADER  = "LOADER";
export const SET_QUARTERDATA = "SET_QUARTERDATA";
export const SET_BUYERDATA = "SET_BUYERDATA";
export const SET_CATEGORYDATA = "SET_CATEGORYDATA";
export const SET_VENDORDATA= "SET_VENDORDATA";
export const SET_PLANIDDATA="SET_PLANIDDATA";
export const SET_FYEARDATA = "SET_FYEARDATA";
export const RESET_FILTERS = "RESET_FILTERS";
export const SET_OFFER_PINNEDDATA= "SET_OFFER_PINNEDDATA";