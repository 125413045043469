import axios from 'axios';
import { GET_ERRORS, DATA_LOADING, CLEAR_ERRORS, SET_CURRENT_USER, GET_BUDGET_DATA, GET_BUDGET_CALENDAR_DATA, GET_BUDGET_GRAPH_DATA, GET_BUDGET_EDIT } from './types';
import environment from './../environments/environment';
import { toast } from "react-toastify";
import environmentModule from "../environments/environment";
import { downloadPlanData, downloadPromoTracker, downloadPlanSummaryData } from "../apis/budgetPlan.api";
import {
  fetchMerchCatValues,
  fetchCategorySpecificVendors
} from "../apis/common.api.";

export const setLoading = () => {
    return {
      type: DATA_LOADING
    };
  };


 export const getBudgetData = reqObject => async dispatch => { 
    dispatch({ type: SET_CURRENT_USER, payload:  localStorage.getItem('token') });
    dispatch({ type: DATA_LOADING, payload: true });

    try {
        const budgetDataResponse = await axios.post(`${environment.hemantUrl}/eda/budgetallocation`,reqObject);
        dispatch({ type: CLEAR_ERRORS });
        dispatch({ type: GET_BUDGET_DATA, payload: budgetDataResponse.data });
        dispatch({ type: DATA_LOADING, payload: false });
    } catch (err) {
        dispatch({ type: GET_ERRORS, payload: err });
    }
 };


 export const getBudgetCalendarData = reqObject => async dispatch => { 
    dispatch({ type: SET_CURRENT_USER, payload:  localStorage.getItem('token') });
    dispatch({ type: DATA_LOADING, payload: true });
    
    try {
        const budgetAllocationResponse = await axios.post(`${environment.hemantUrl}/eda/budgetallocationcard`,reqObject);
        dispatch({ type: CLEAR_ERRORS });
        dispatch({ type: GET_BUDGET_CALENDAR_DATA, payload: budgetAllocationResponse.data });
        dispatch({ type: DATA_LOADING, payload: false });
    } catch(err) {
        dispatch({ type: GET_ERRORS, payload: err });
    }
 };

 export const getBudgetGraphData = reqObject => async dispatch => { 
    dispatch({ type: SET_CURRENT_USER, payload:  localStorage.getItem('token') });
    dispatch({ type: DATA_LOADING, payload: true });
    
    try {
        const budgetGraphResponse = await axios.post(`${environment.hemantUrl}/eda/budgetgraph`,reqObject);
        dispatch({ type: CLEAR_ERRORS });
        dispatch({ type: GET_BUDGET_GRAPH_DATA, payload: budgetGraphResponse.data });
        dispatch({ type: DATA_LOADING, payload: false });
    } catch(err) {
        dispatch({
            type: GET_ERRORS,
            payload: err
        });
    }
 };


 export const postBudgetEdit = ({ budgetEditPayload, budgetDataPayload }) => async dispatch => { 
     dispatch({
         type: SET_CURRENT_USER,
         payload:  localStorage.getItem('token')
      })
      dispatch({
        type: DATA_LOADING,
        payload: true
      });
      try {
        const budgetEditResponse = await axios.post(`${environment.hemantUrl}/eda/budgetedit`,budgetEditPayload);
        dispatch({ type: GET_BUDGET_EDIT, payload: budgetEditResponse.data });
        const budgetAllocationResponse = await axios.post(`${environment.hemantUrl}/eda/budgetallocation`,budgetDataPayload)
        dispatch({ type: GET_BUDGET_DATA, payload: budgetAllocationResponse.data });
        const budgetCalendarResponse = await axios.post(`${environment.hemantUrl}/eda/budgetallocationcard`,budgetDataPayload);
        dispatch({ type: GET_BUDGET_CALENDAR_DATA, payload: budgetCalendarResponse.data });
        const budgetGraphResponse = await axios.post(`${environment.hemantUrl}/eda/budgetgraph`,budgetDataPayload);
        dispatch({ type: CLEAR_ERRORS });
        dispatch({ type: GET_BUDGET_GRAPH_DATA, payload: budgetGraphResponse.data });
        dispatch({ type: DATA_LOADING, payload: false });
      } catch (err) {
        dispatch({
            type: GET_ERRORS,
            payload: err
        });
      }
 };

 export const actionDownloadBudgetPlanning = reqObj => async dispatch => {
    try {
      dispatch({ type: DATA_LOADING, payload: true });
      const result = await downloadPlanData(reqObj);
      window.open(result.data, "_self");
      dispatch({ type: DATA_LOADING, payload: false });
    } catch (err) {
      dispatch({ type: DATA_LOADING, payload: false });
    }
  };

  export const fetchMerchCat = reqObj => async dispatch => {
      dispatch({ type: DATA_LOADING, payload: true });
      const result = await fetchMerchCatValues();
      dispatch({ type: DATA_LOADING, payload: false });
      return result;
  };

  export const fetchVendorList = reqObj => async dispatch => {
    dispatch({ type: DATA_LOADING, payload: true });
    const result = await fetchCategorySpecificVendors(reqObj);
    dispatch({ type: DATA_LOADING, payload: false });
    return result;
};

export const actionDownloadPromoTracker = reqObj => async dispatch => {
  try {
    dispatch({ type: DATA_LOADING, payload: true });
    const result = await downloadPromoTracker(reqObj);
    window.open(result.url, "_self");
    dispatch({ type: DATA_LOADING, payload: false });
  } catch (err) {
    dispatch({ type: DATA_LOADING, payload: false });
  }
};

export const actionDownloadPlanSummary = reqObj => async dispatch => {
  try {
    dispatch({ type: DATA_LOADING, payload: true });
    const result = await downloadPlanSummaryData(reqObj);
    window.open(result.url, "_self");
    dispatch({ type: DATA_LOADING, payload: false });
  } catch (err) {
    dispatch({ type: DATA_LOADING, payload: false });
  }
};






