import React, { Component } from 'react';
import { Card, Button } from 'react-bootstrap';
import Modal from 'react-awesome-modal';
import LoadingOverlay from 'react-loading-overlay';
import TicketsTable from './views/TicketsTable';
import TicketForm from './views/TicketForm';
import api from './apiHelper';
import './index.css';
import { SupportGA   } from '../../common/GAUtils';
// const queueId = 148040; // This is ticket queue id for BJ's PS
export default class MojoSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tickets: [],
      cardLoader: false,
      modalLoader: false,
      isModalVisible: false,
      ticketDetails: {},
    };
  }
  componentDidMount() {
    this.getTickets();
  }

  getTickets = () => {
    this.setState({ cardLoader: true });
    const { email } = this.props.userInfo;
    api
      .getTickets({ access_key: this.props.access_key, per_page: 1000 }, email)
      .then((resp) => {
        this.setState({
          tickets: (resp.data && resp.data.data) || [],
          cardLoader: false,
        });
      });
  };

  createTicket = (payload, resetForm) => {
    this.submitGA("Click","create ticket");
    this.setState({ modalLoader: true });
    api.createTicket({ access_key: this.props.access_key }, payload).then((resp) => {
      if (payload.files.length > 0) {
        let counter = { value: 0 };
        this.attachFiles({
          ticketId: resp.data.data.id,
          counter,
          files: payload.files,
          resetForm,
          isCreateFlow: true,
        });
      } else {
        this.setState({ tickets: [], isModalVisible: false, modalLoader: false }, () => {
          this.getTickets();
          resetForm();
        })
      }
    }).catch(error => {
      console.log(error);
      this.setState({
        modalLoader: false
      })
    });
  };

  attachFiles = ({ ticketId, counter, files, resetForm, isCreateFlow }) => {
    this.submitGA("Click","attaching a file");
    this.setState({ modalLoader: true });
    const noOfFiles = files.length;
    const formData = new FormData();
    formData.append('file', files[counter.value]);
    api.attachFile({ ticketId, access_key: this.props.access_key, file: formData }).then((resp) => {
      if (counter.value === noOfFiles - 1) {
        counter.value = 0;
        isCreateFlow
          ? this.setState({ tickets: [], isModalVisible: false, modalLoader: false }, () => {
              this.getTickets();
              resetForm();
            })
          : this.onTicketClick(ticketId);
      } else {
        ++counter.value;
        this.attachFiles({ ticketId, counter, files, resetForm, isCreateFlow });
      }
    }).catch(error => {
      console.log(error)
      this.setState({
        modalLoader: false
      })
    });
  };

  createComment = (ticketId, payload) => {
    this.setState({ modalLoader: true });
    api.createComment(ticketId, { access_key: this.props.access_key }, payload).then((resp) => {
      this.onTicketClick(ticketId);
    });
  };
  downloadAttachment = (attachmentId) => {
    this.submitGA("Click","download attachement");
    api.downloadAttachment({ attachmentId, access_key: this.props.access_key });
  };

  onTicketClick = (id) => {
    this.submitGA("Click","display selected ticket details");
    !this.state.modalLoader && this.setState({ cardLoader: true });
    api.getTicket({ access_key: this.props.access_key, id }).then((resp) => {
      this.setState({
        ticketDetails: resp.data.data,
        isModalVisible: true,
        modalLoader: false,
        cardLoader: false,
      });
    });
  };

  submitGA(action,label){
    SupportGA({ action, label});
}
  render() {
    return (
      <>
        <LoadingOverlay active={this.state.cardLoader} spinner text="Loading...">
          <Card>
            <Card.Body>
              <Card.Title>Tickets</Card.Title>
              <div style={{ textAlign: 'right', margin: '1rem 0' }}>
                <Button
                  variant="primary"
                  onClick={() => {
                    this.submitGA("Click","click on create ticket button");
                    this.setState({ isModalVisible: true, ticketDetails: {} });
                  }}>
                  Create Ticket
                </Button>
              </div>
              <TicketsTable
                access_key={this.props.access_key}
                rowData={this.state.tickets}
                onTicketClick={this.onTicketClick}
              />
            </Card.Body>
          </Card>
        </LoadingOverlay>
        <Modal
          visible={this.state.isModalVisible}
          width="55%"
          effect="fadeInDown"
          onClickAway={() => this.setState({ isModalVisible: false })}>
          <LoadingOverlay active={this.state.modalLoader} spinner text="Loading...">
            <div className="modal-body">
              <h4>{this.state.ticketDetails.id ? 'Ticket View' : 'Ticket Creation'}</h4>
              <TicketForm
                attachFiles={this.attachFiles}
                downloadAttachment={this.downloadAttachment}
                createTicket={this.createTicket}
                createComment={this.createComment}
                userInfo={this.props.userInfo}
                ticketDetails={this.state.ticketDetails}
              />
            </div>
          </LoadingOverlay>
        </Modal>
      </>
    );
  }
}
