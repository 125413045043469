import {
  GET_ERRORS,
  CLEAR_ERRORS,
  GET_EDA_ERRORS,
  ERROR_MSG
} from '../actions/types';

const initialState = {
  err_msg: null
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_ERRORS:
      return {
        ...state,
        chartData: action.payload,
        loading: false
      };
    case ERROR_MSG:
      return {
        ...state,
        err_msg: action.payload
      };
    case CLEAR_ERRORS:
      return {};
    default:
      return state;
  }
}
