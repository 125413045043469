import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import MultiSelectCheckboxes from '../../../MultiSelectCheckboxes';
import { Form } from 'react-bootstrap';
import { BudgetPlanningGA } from '../../../../common/GAUtils';



const BudgetMetricsDropDown = ({
  articleList,
  handleFilterChange,
  articleSelectedFromPlanTable,
}) => {
  const [ah6Options, setAh6Options] = useState([]);
  const [selectedAh6, setSelectedAh6] = useState([]);
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [vendorOptions, setVendorOptions] = useState([]);
  const [articleNo, setArticleNo] = useState(undefined);
  const [articleOptions, setArticleOptions] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState([]);

  useEffect(() => {
    if (articleSelectedFromPlanTable) {
      setArticleNo(articleSelectedFromPlanTable);
    }
  }, [articleSelectedFromPlanTable]);

  useEffect(() => {
    const ah6Options = [],
      vendorOptions = [],
      articleOptions = [];
    articleList.forEach((article) => {
      const { vendor, hierarchyLevel6, articleNo, active } = article;
      ah6Options.push(hierarchyLevel6);
      vendorOptions.push(vendor);
      articleOptions.push(articleNo);
    });
    const _ah60Options = convertValuesForDropDown(_.uniq(ah6Options));
    const _vendorOptions = convertValuesForDropDown(_.uniq(vendorOptions));
    const _articleOptions = convertValuesForDropDown(_.uniq(articleOptions));
    setAh6Options(_ah60Options);
    setVendorOptions(_vendorOptions);
    setArticleOptions(_articleOptions);

    if (!articleNo) {
      setSelectedAh6(_ah60Options);
      setSelectedVendor(_vendorOptions);
      setSelectedArticle(_articleOptions);
      handleArticleSelection(getDropdownValues(_articleOptions));
    }
  }, [articleList]);

  useEffect(() => {
    if (articleNo) {
      const filteredArticleList = articleList.filter((item) => articleNo.includes(item.articleNo));
      setSelectedAh6(
        convertValuesForDropDown(_.uniq(filteredArticleList.map((item) => item.hierarchyLevel6)))
      );
      setSelectedVendor(
        convertValuesForDropDown(_.uniq(filteredArticleList.map((item) => item.vendor)))
      );
      setSelectedArticle(
        convertValuesForDropDown(_.uniq(filteredArticleList.map((item) => item.articleNo)))
      );
      handleArticleSelection(articleNo);
    }
  }, [articleNo]);

  const convertValuesForDropDown = (items) => {
    return items.map((item) => ({
      label: item,
      value: item,
    }));
  };

  const getDropdownValues = (items) => items.map((i) => i.value);

  const handleDropdownChange = (params) => {
    if (params.hasOwnProperty('selectedAh6')) {
      submitGA("Click","select AH6 in budget planning metrics");
      const selectAh6Values = getDropdownValues(params.selectedAh6);
      setArticleNo(
        articleList
          .filter((item) => selectAh6Values.includes(item.hierarchyLevel6))
          .map((item) => item.articleNo)
      );
    }
    if (params.hasOwnProperty('selectedVendor')) {
      submitGA("Click","select vendor in budget planning metrics");
      const selectedVendorValues = getDropdownValues(params.selectedVendor);
      setArticleNo(
        articleList
          .filter((item) => selectedVendorValues.includes(item.vendor))
          .map((item) => item.articleNo)
      );
    }
    if (params.hasOwnProperty('selectedArticle')) {
      submitGA("Click","select article in budget planning metrics");
      const selectedArticleNoValues = getDropdownValues(params.selectedArticle);
      setArticleNo(
        articleList
          .filter((item) => selectedArticleNoValues.includes(item.articleNo))
          .map((item) => item.articleNo)
      );
    }
  };

  const submitGA =(action,label)=>{
    BudgetPlanningGA({ action, label});
  }

  const handleArticleSelection = (articleNos = selectedArticle) => {
    handleFilterChange(
      articleList.filter((item) => {
        if (articleNos.includes(item.articleNo)) return true;
        return false;
      })
    );
  };

  return (
    <>
      <div
        className="row"
        style={{
          ...metricsTableTitleStyle,
          ...articleDropDownStyle,
        }}>
        <div className="col-md-3">
          <Form>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>
                <b> AH6 </b>{' '}
              </Form.Label>{' '}
              <MultiSelectCheckboxes
                options={ah6Options}
                placeholderButtonLabel="Select AH6"
                closeMenuOnSelect={true}
                value={selectedAh6}
                mappingKey="selectedAh6"
                updateState={handleDropdownChange}
                defaultSelectedOptions={true}
              />{' '}
            </Form.Group>{' '}
          </Form>{' '}
        </div>{' '}
        <div className="col-md-3">
          <Form>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>
                <b> Vendor </b>{' '}
              </Form.Label>{' '}
              <MultiSelectCheckboxes
                options={vendorOptions}
                placeholderButtonLabel="Select Vendor"
                closeMenuOnSelect={true}
                value={selectedVendor}
                mappingKey="selectedVendor"
                updateState={handleDropdownChange}
                defaultSelectedOptions={true}
              />{' '}
            </Form.Group>{' '}
          </Form>{' '}
        </div>
        <div className="col-md-3">
          <Form>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>
                <b> Article </b>{' '}
              </Form.Label>{' '}
              <MultiSelectCheckboxes
                options={articleOptions}
                placeholderButtonLabel="Select Article"
                closeMenuOnSelect={true}
                value={selectedArticle}
                mappingKey="selectedArticle"
                updateState={handleDropdownChange}
                defaultSelectedOptions={true}
              />{' '}
            </Form.Group>{' '}
          </Form>{' '}
        </div>{' '}
      </div>{' '}
    </>
  );
};

export default BudgetMetricsDropDown;

const metricsTableTitleStyle = {
  marginLeft: '20px',
};

const articleDropDownStyle = {
  zIndex: 99,
  position: 'relative',
};
