import React from 'react';

const EditOrDelete = (props) => {
  const onEditClick = () => {
    const id = props.data.id;
    const type = props.data.type;
    props.handleEdit({ id, type });
  };
  const onDeleteClick = () => {
    const { id, type, name } = props.data;
    props.handleDelete({ id, type, name });
  };
  const changeStatus = () => {
    const { id, type, name, status } = props.data;
    props.handleStatus({ id, type, name, status });
  }
  const handleRejection = () =>{
    const { id, type, name, status } = props.data;
    props.handleReject({ id, type, name, status });
  }
  return (
    <>
      <div className="editOrDelete">
        <span>
          <i className="fa fa-pencil-square-o" onClick={(e) => onEditClick(e)} title="Edit"></i>
        </span>
        {props.userInfo.indexOf('delete') !== -1 && props.data.status !== 'APPROVED' ? (
          <span>
            <i class="fa fa-trash-o" onClick={(e) => onDeleteClick(e)} title="Delete"></i>
          </span>
        ) : null}
        {/* {props.data.status === 'APPROVED' && (
          <>
          <button onClick={(e) => changeStatus(e)} className="action-button unapprove btn btn-primary ">
            Unapprove
          </button>
          </>
        )} */}
        {props.userInfo.indexOf('finalize') !== -1 && (props.data.status === 'PENDING' || props.data.status === 'UNAPPROVED') && (
          <>
          {/* <span >
          <i class="fa fa-check-circle-o" onClick={(e) => changeStatus(e)} title="Approve" style={{fontSize:"15px"}}></i>
        </span> */}
          <button onClick={(e) => changeStatus(e)} className="action-button btn btn-primary ">
            Approve
          </button>
          </>
        )}
        {props.userInfo.indexOf('finalize') !== -1 && (props.data.status === 'PENDING') && (
          <>
          {/* <span >
          <i class="fa fa-check-circle-o" onClick={(e) => changeStatus(e)} title="Approve" style={{fontSize:"15px"}}></i>
        </span> */}
          <span>
          <button onClick={() => handleRejection()} className="action-button btn btn-primary">
            Reject
          </button>
          </span>
          </>
        )}
      </div>
    </>
  );
};

export default EditOrDelete;
