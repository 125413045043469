import React from 'react';

const Categoires = (props) => {
  const articleClass = props.constraintData.groupid.length>0 ? 'col-3':'col-2';
  return (
    <div className="row" style={{ marginTop: '-18px', marginBottom: '-13px' }}>
      {props.constraintData.groupid.length>0 && 
      <div className="col-4">
      {/* <div className="row"> */}
        <div className="popup-header">
          <b>Group Name </b>:
        </div>
          {props.constraintData.groupid.length > 2
            ? `${props.constraintData.groupid.slice(0, 2).join(', ')} + ${
                props.constraintData.groupid.length - 2
              }`
            : props.constraintData.groupid.join(', ')}
    </div>
      }
      <div className="col-4">
        {/* <div className="row"> */}
          <div className="popup-header">
            <b>Vendor </b>:
          </div>
            {props.constraintData.vendor.length > 2
              ? `${props.constraintData.vendor.slice(0, 2).join(', ')} + ${
                  props.constraintData.vendor.length - 2
                }`
              : props.constraintData.vendor.join(', ')}
      </div>
      {props.constraintData.groupid.length === 0 &&
      <>
      <div className="col-3">
          <div className="popup-header">
            <b>Brand </b>:
          </div>
            {props.constraintData.brand.length > 2
              ? `${props.constraintData.brand.slice(0, 2).join(', ')} + ${
                  props.constraintData.brand.length - 2
                }`
              : props.constraintData.brand.join(', ')}
      </div>
      <div className="col-3">
          <div className="popup-header">
            <b>AH6 </b>:
          </div>
            {props.constraintData.ah6.length > 2
              ? `${props.constraintData.ah6.slice(0, 2).join(', ')} + ${
                  props.constraintData.ah6.length - 2
                }`
              : props.constraintData.ah6.join(', ')}
      </div>
      </>
      }
      <div className={articleClass}>
          <div className="popup-header">
            <b>Article </b>:
          </div>
          {props.constraintData.groupid.length>0 ? 
          props.constraintData.selectedArticles.length > 2
          ? `${props.constraintData.selectedArticles.slice(0, 2).join(', ')} + ${
              props.constraintData.selectedArticles.length - 2
            }`
          : props.constraintData.selectedArticles.join(', ')
          :
            props.constraintData.article.length > 2
              ? `${props.constraintData.article.slice(0, 2).join(', ')} + ${
                  props.constraintData.article.length - 2
                }`
              : props.constraintData.article.join(', ')}
      </div>
    </div>
  );
};

export default Categoires;
