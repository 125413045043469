import { GET_VENDOR_BUDGET_MASTER_DATA, DATA_LOADING, UPDATE_BUDGET_MASTER_DATA, RESET_BUDGET_MASTER_DATA, VENDOR_BRAND_BUDGET_DATA, UPDATE_VENDOR_BRAND_BUDGET_DATA } from '../actions/types';

const initialState = {
    loading:false,
    budgetMasterTableData: [],
    modifiedData: {},
    vendorBrandBudgetData: []
}

export default function (state = initialState, action) {
    switch(action.type) {
        case DATA_LOADING:
					return {
							...state,
							loading: action.payload
                    };
        case GET_VENDOR_BUDGET_MASTER_DATA:
             return{
                 ...state,
                 budgetMasterTableData: action.payload
             };
        case UPDATE_BUDGET_MASTER_DATA:{
            const {vendor,field,value,brand} = action.payload;
            const temp = Object.assign({}, state.modifiedData);
            temp[`${brand}-${vendor}`] = temp[`${brand}-${vendor}`] || { brand };
            temp[`${brand}-${vendor}`][field] = value;
            temp[`${brand}-${vendor}`]['vendor'] = vendor;

            return{
                ...state,
                modifiedData: temp
            }};
        case 'SAVE_BUDGETMASTER':{
            return{
                ...state,
                modifiedData:{}
            }
        }
        case RESET_BUDGET_MASTER_DATA: {
            return{
                ...state,
                budgetMasterTableData: [],
                modifiedData:{}
            }
        };
        case VENDOR_BRAND_BUDGET_DATA: {
            return{
                ...state,
                vendorBrandBudgetData: action.payload
            };
        }

        default:
            return state;
    }
}