import { GET_BUDGET_DATA, DATA_LOADING,GET_BUDGET_CALENDAR_DATA, GET_BUDGET_GRAPH_DATA, GET_BUDGET_EDIT } from '../actions/types'

const initialState = {
	budgetPlanning: {},
	loading:false,
	budgetPlanningCalenderData:{},
	budgetGraphData:{}
}


export default function (state = initialState, action) {
	switch (action.type) {

			case DATA_LOADING:
					return {
							...state,
							loading: action.payload
					};

			case GET_BUDGET_DATA:
					return {
							...state, 
							budgetPlanning: action.payload,
							loading:false
					};
			case GET_BUDGET_CALENDAR_DATA:
					return {
							...state, 
							budgetPlanningCalenderData: action.payload,
							loading:false
					};
			case GET_BUDGET_GRAPH_DATA:
					return {
							...state, 
							budgetGraphData: action.payload,
							loading:false
					};
			case GET_BUDGET_EDIT:
					return {
						...state,
						loading: false,
					}
			default:
					return state;
	}
}