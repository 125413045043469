import React, { useState } from 'react';

const ExpandCollapse = (props) => {
    const handleExpand = (data, rowData, type) => {
      props.handleExpandCollapse(data, rowData, type);
    };
  
    const count = props.agGridReact.props.rowData.filter((obj) => obj.vendor === props.value).length;
    return (
      <div>
        { props.data.brand === 'ALL BRANDS' && count === 1 ? (
          <span
            className="expand_collapse"
            onClick={() => handleExpand(props.data, props.agGridReact.props.rowData, 'expand')}>
            <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
          </span>
        ) : props.data.brand == 'ALL BRANDS' && count > 1 ? (
          <span
            className="expand_collapse"
            onClick={() => handleExpand(props.data, props.agGridReact.props.rowData, 'collapse')}>
            <i class="fa fa-chevron-circle-up" aria-hidden="true"></i>
          </span>
        ) : null}
        <span>
        {props.value}
        </span>
      </div>
    );
  };

  export default ExpandCollapse;