import {
  numberFormatter,
  removePrecisionFormatter,
  convertToCurrency,
  precisionFormatter,
  percentageFormatter,
  emptyValueFormatter,
  convertedBooleanValue,
  convertMillionsFormatterWithoutPrecision,
  percentageAndPrecisionFormatter,
  CustomSearch,
  validateCellValue
} from "../../utils/AgGridFormatters";
import _ from "underscore";
const METRICS_LIST = [
  {
    name: "Summary",
    isChecked: true,
  },
  {
    name: "Information Metrics",
    isChecked: false,
  },
  {
    name: "Performance Metrics",
    isChecked: false,
  },
  {
    name: "Member Metrics",
    isChecked: false,
  },
  {
    name: "Basket Metrics",
    isChecked: false,
  },
];
const METRICS_COLUMN_NAMES = {
  Summary: [],
  "Information Metrics": [
    {
      headerName: "Article Name",
      field: "articleDesc",
      aggrField: "-",
      headerTooltip:
        "Unique BJ's description for each article (SKU) mapped to a Promotion",
      pinned: "left",
      sortable: true,
      hide: true,
      filter: true,
    },
    {
      headerName: "Article Number",
      field: "articleNo",
      aggrField: "-",
      headerTooltip: "Unique BJ's identifier for each article (SKU)",
      hide: true,
      pinned: "left",
      filter: true,
      sortable: true
    },
    {
      headerName: "Offer Name",
      field: "title",
      aggrField: "-",
      headerTooltip: "Description of the promo",
      pinned: "left",
      sortable: true,
      hide: false,
      filter: true,
      width:300
    },
    
    {
      headerName: "Offer ID",
      field: "promoId",
      aggrField: "-",
      headerTooltip: "Unique identifier for the Promotion",
      // pinned: "left",
      filter: true,
      sortable: true,
    },
    {
      headerName: "Offer Type",
      field: "actualCouponType",
      aggrField: "-",
      headerTooltip:
        "Nature of the coupon offered to members(Paper, clipless)",
      hide: false,
      filter: true,
      sortable: true
    },
    {
      headerName: "Book #",
      field: "mediaCycle",
      aggrField: "-",
      headerTooltip: "Media cycle in which article was promoted",
      hide: false,
      filter: true,
      sortable: true
    },
    {
      headerName: "FY",
      field: "fYear",
      aggrField: "-",
      headerTooltip: "Fiscal year during which article was promoted",
      hide: false,
      filter: true,
      sortable: true
    },
    {
      headerName: "Quarter",
      field: "fQuater",
      aggrField: "-",
      headerTooltip: "Fiscal quarter during which article was promoted",
      hide: false,
      // valueFormatter: numberFormatter,
      filter: true,
      sortable: true
    },
    {
      headerName: "Brand",
      field: "brand",
      aggrField: "-",
      headerTooltip: "Brand of the article",
      hide: true,
      filter: true,
      sortable: true
    },
    {
      headerName: "Vendor",
      field: "vendor",
      aggrField: "-",
      headerTooltip: "Name of the Vendor the article belongs to",
      hide: true,
      filter: true,
      sortable: true
    },
    {
      headerName: "Vendor Funded",
      field: "actualVendorFunded",
      aggrField: "-",
      headerTooltip: "Whether a promotion is funded by the Vendor or not(Y/N)",
      valueFormatter: convertedBooleanValue,
      // valueGetter: function(params) {
      //   return convertedBooleanValue({value:params.data.vendorFunded})
      //   },
      hide: false,
      filter: 'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
        textCustomComparator: function(_, value, filterText) {
          var filterTextLowerCase = filterText.toLowerCase();
          var valueLowerCase = value.toString().toLowerCase();
          var aliases = {
            yes: 'true',
            no: 'false'
          };
          function contains(target, lookingFor) {
            return target && target.indexOf(lookingFor) >= 0;
          }
          var literalMatch = contains(valueLowerCase, filterTextLowerCase);
          return (
            literalMatch ||
            contains(valueLowerCase, aliases[filterTextLowerCase])
          );
        },
      },
      sortable: true
    },
    {
      headerName: "Start Date",
      field: "startDate",
      aggrField: "-",
      headerTooltip: "Start date of the promotion",
      hide: true,
      filter: true,
      sortable: true
    },
    {
      headerName: "End Date",
      field: "endDate",
      aggrField: "-",
      headerTooltip: "End date of the promotion",
      hide: true,
      filter: true,
      sortable: true
    },
    // {
    //   headerName: "Offer Description",
    //   field: "description",
    //   headerTooltip: "Detailed description of the promo",
    //   hide: true,
    //   filter: true,
    // },
    {
      headerName: "Coupon Value $",
      field: "actualPromoValue",
      aggrField: "avg",
      headerTooltip: "Effective discount offered for the article in the promo",
      hide: true,
      // valueGetter: (params) => {
      //   return '$' + precisionFormatter({value: params.data.promoValue})
      // },
      valueFormatter: (params) => {
        const value = precisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return '$' + precisionFormatter(params)
      },
      // filter: true,
      filter:'agTextColumnFilter',
      filterParams: {
        caseSensitive: true,
        filterOptions: ['contains'],
      textCustomComparator: function(_, value, filterText) {
        var convertedValue = '$' + precisionFormatter({value:parseFloat(value)});
        if(convertedValue.includes(filterText)){
              return value;
            }
          }
        },
      sortable: true
    },
    {
      headerName: "Validity Days",
      field: "actualValidityDays",
      aggrField: "-",
      headerTooltip: "Number of days for which promo was valid",
      hide: true,
      // valueFormatter: numberFormatter,
      filter: true,
      sortable: true
    },
    {
      headerName: "Discount Rate %",
      field: "couponValue",
      aggrField: "avg",
      headerTooltip: "Discount depth %(discount value/list price * 100) offered by the promotion",
      hide: true,
      // valueFormatter: removePrecisionFormatter ,
      valueFormatter: (params) => {
        const value = precisionFormatter(params);
        return validateCellValue(value) ? value : ( value + ' %');
        // return  precisionFormatter(params) + ' %'
      },
      // filter: true,
      filter:'agTextColumnFilter',
      filterParams: {
        caseSensitive: true,
        filterOptions: ['contains'],
      textCustomComparator: function(_, value, filterText) {
        var convertedValue = precisionFormatter({value:parseFloat(value)}) + ' %';
        if(convertedValue.includes(filterText)){
              return value;
            }
          }
        },
      sortable: true
    },
  ],

  "Performance Metrics": [
    {
      headerName: "Total Units",
      field: "actualTotalUnits",
      aggrField: "sum",
      headerTooltip:
        "Total Quantity sold during the promotion(includes non-promo transactions)",
      hide: true,
      valueFormatter: convertMillionsFormatterWithoutPrecision,
      // valueGetter: function(params) {
      //   return convertMillionsFormatterWithoutPrecision({value:params.data.actualTotalUnits})
      //   },

      sortable: true
    },
    {
      headerName: "Promo Units",
      field: "promoQty",
      aggrField: "sum",
      headerTooltip:
        "Quantity sold exclusively on promotion ( at discounted price)",
      hide: true,
      valueFormatter: convertMillionsFormatterWithoutPrecision,
      // valueGetter: function(params) {
      //   return convertMillionsFormatterWithoutPrecision({value:params.data.promoQty})
      //   },
      sortable: true
    },
    {
      headerName: "Unit Lift",
      field: "actualUnitsLift",
      aggrField: "division",
      headerTooltip:
        "Lift in the units sold due to the promo",
      hide: true,
      valueFormatter: precisionFormatter,
      // valueGetter: function(params) {
      //   return precisionFormatter({value:params.data.actualUnitsLift})
      //   },
      sortable: true
    },
    {
      headerName: "Sales $",
      field: "sales&incremental",
      aggrField: "sum",
      // field:'baselineAndIncrementalSales',
      hide:true,
      headerTooltip:
        "Total sales generated during the promotion(includes non-promo transactions)",
      // colId: "sales&incremental",
      valueFormatter: function(params) {
        // return params.data.baselineSales + params.data.directIncrementalSales;
        // return '$' + removePrecisionFormatter({value:(params.data.baselineSales + params.data.directIncrementalSales)})
        const value = removePrecisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return '$' + removePrecisionFormatter(params)

      },
      // valueFormatter: removePrecisionFormatter,
      // valueFormatter: (params) => {
      //   return '$' + removePrecisionFormatter(params)
      // },
      sortable: true
    },
    {
      headerName: "Net Sales $",
      field: "actualTotalSales",
      aggrField: "sum",
      headerTooltip: "Sales inclusive of secondary effects ( affinity,cannibalization,pull forward)",
      hide: true,
      // valueFormatter: removePrecisionFormatter,
      valueFormatter: (params) => {
        const value = removePrecisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return '$' + removePrecisionFormatter(params)
      },
      // valueGetter: function(params) {
      //   return '$' + removePrecisionFormatter({value:params.data.actualTotalSales})
      //   },
      sortable: true
    },
    {
      headerName: "Baseline Sales $",
      field: "baselineSales",
      aggrField: "sum",
      headerTooltip:
        "Sales that would have been generated had there been no promotion",
      hide: true,
      // valueFormatter: removePrecisionFormatter,
      valueFormatter: (params) => {
        const value = removePrecisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
      },
      sortable: true
    },
    {
      headerName: "Incremental Sales $",
      field: "directIncrementalSales",
      aggrField: "sum",
      headerTooltip:
        "Sales increment/decrement of the promotion over baseline (Sales - Baseline)",
      hide: true,
      // valueFormatter: removePrecisionFormatter,
      valueFormatter: (params) => {
        const value = removePrecisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return '$' + removePrecisionFormatter(params)
      },
      // valueGetter: function(params) {
      //   return '$' + removePrecisionFormatter({value:params.data.directIncrementalSales})
      //   },
      sortable: true
    },
    {
      headerName: "Margin $",
      field: "baseline&incremental",
      aggrField: "sum",
      // field:'baselineAndIncrementalMargin',
      hide:true,
      headerTooltip: "Total margin generated during the promotion",
      // colId: "baseline&incremental",
      valueFormatter: function(params) {
        // return '$' + removePrecisionFormatter({value: (params.data.baselineMargin + params.data.directIncrementalMargin)});
        const value = removePrecisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return '$' + removePrecisionFormatter(params);
      },
      // valueFormatter: removePrecisionFormatter,
      // valueFormatter: (params) => {
      //   return '$' + removePrecisionFormatter(params)
      // },
      sortable: true
    },
    {
      headerName: "Net Margin $",
      field: "actualTotalMargin",
      aggrField: "sum",
      headerTooltip:
        "Margin inclusive of secondary effects ( affinity, cannibalization, pull forward)",
      hide: true,
      // valueFormatter: removePrecisionFormatter,
      valueFormatter: (params) => {
        const value = removePrecisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return '$' + removePrecisionFormatter(params)
      },
      // valueGetter: function(params) {
      //   return '$' + removePrecisionFormatter({value: params.data.actualTotalMargin});
      // },
      sortable: true
    },
    {
      headerName: "Baseline Margin $",
      field: "baselineMargin",
      aggrField: "sum",
      headerTooltip:
        "Margin that would have been generated had there been no promotion",
      hide: true,
      // valueFormatter: removePrecisionFormatter,
      valueFormatter: (params) => {
        const value = removePrecisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return '$' + removePrecisionFormatter(params)
      },
      // valueGetter: function(params) {
      //   return '$' + removePrecisionFormatter({value: params.data.baselineMargin});
      // },
      sortable: true
    },
    {
      headerName: "Incremental Margin $",
      field: "directIncrementalMargin",
      aggrField: "sum",
      headerTooltip:
        "Margin increment/decrement of the promotion over baseline (Margin - Baseline)",
      hide: true,
      // valueFormatter: removePrecisionFormatter,
      valueFormatter: (params) => {
        const value = removePrecisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return '$' + removePrecisionFormatter(params)
      },
      // valueGetter: function(params) {
      //   return '$' + removePrecisionFormatter({value: params.data.directIncrementalMargin});
      // },
      sortable: true
    },
    {
      headerName: "ROI (Sales)",
      field: "roiSales",
      aggrField: "division",
      headerTooltip:
        "Incremental sales over total vendor spend(inclusive of insertion fees)",
      hide: true,
      valueFormatter: precisionFormatter,
      // valueGetter: function(params) {
      //   return  precisionFormatter({value: params.data.roiSales});
      // },
      sortable: true
    },
    {
      headerName: "ROI (Margin)",
      field: "roiMargin",
      aggrField: "division",
      headerTooltip:
        "Incremental Margin over Total vendor spend(inclusive of insertion fees)",
      hide: true,
      valueFormatter: precisionFormatter,
      // valueGetter: function(params) {
      //   return  precisionFormatter({value: params.data.roiMargin});
      // },
      sortable: true
    },
    {
      headerName: "Coupons Redeemed",
      field: "couponsRedeemed",
      aggrField: "sum",
      headerTooltip:
        "Number of coupons redeemed while the promotion was valid for the article",
      hide: true,
      valueFormatter: removePrecisionFormatter,
      // valueGetter: function(params) {
      //   return  removePrecisionFormatter({value: params.data.couponsRedeemed});
      // },
      sortable: true,
    },
    {
      headerName: "Redeemed Coupon Value $",
      field: "spend",
      aggrField: "sum",
      headerTooltip:
        "Value of coupons redeemed during the promotion for the article",
      hide: true,
      // valueFormatter: removePrecisionFormatter,
      valueFormatter: (params) => {
        const value = removePrecisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return '$' + removePrecisionFormatter(params)
      },
      sortable: true
    },
    {
      headerName: "Redeemed ATC Coupon",
      field: "redeemedATCCoupon",
      aggrField: "sum",
      headerTooltip:
        "Total number of add-to-cart coupons redeemed for the article on promotion",
      hide: true,
      valueFormatter: removePrecisionFormatter,
      // valueGetter: (params) => {
      //   return removePrecisionFormatter({value: params.data.redeemedATCCoupon})
      // },
      sortable: true
    },
    {
      headerName: "Week 1 %",
      field: "week1",
      aggrField: "-",
      headerTooltip: "% of coupons redeemed in week 1 of promotion",
      hide: true,
      // valueFormatter: percentageAndPrecisionFormatter,
      valueFormatter: (params) => {
        const value = percentageAndPrecisionFormatter(params);
        return validateCellValue(value) ? value : ( value + ' %');
        // return  percentageAndPrecisionFormatter(params) + ' %'
      },
      sortable: true,
      width:100
    },
    {
      headerName: "Week 2 %",
      field: "week2",
      aggrField: "-",
      headerTooltip: "% of coupons redeemed in week 2 of promotion",
      hide: true,
      // valueFormatter: percentageAndPrecisionFormatter,
      valueFormatter: (params) => {
        const value = percentageAndPrecisionFormatter(params);
        return validateCellValue(value) ? value : ( value + ' %');
        // return  percentageAndPrecisionFormatter(params) + ' %'
      },
      // valueGetter: (params) => {
      //   return  percentageAndPrecisionFormatter({value:params.data.week2}) + ' %'
      // },
      sortable: true,
      width:100
    },
    {
      headerName: "Week 3 %",
      field: "week3",
      aggrField: "-",
      headerTooltip: "% of coupons redeemed in week 3 of promotion",
      hide: true,
      // valueFormatter: percentageAndPrecisionFormatter,
      valueFormatter: (params) => {
        const value = percentageAndPrecisionFormatter(params);
        return validateCellValue(value) ? value : ( value + ' %');
        // return  percentageAndPrecisionFormatter(params) + ' %'
      },
      // valueGetter: (params) => {
      //   return  percentageAndPrecisionFormatter({value:params.data.week3}) + ' %'
      // },
      sortable: true,
      width:100
    },
    {
      headerName: "Week 4 %",
      field: "week4",
      aggrField: "-",
      headerTooltip: "% of coupons redeemed in week 4 of promotion",
      hide: true,
      // valueFormatter: percentageAndPrecisionFormatter,
      valueFormatter: (params) => {
        const value = percentageAndPrecisionFormatter(params);
        return validateCellValue(value) ? value : ( value + ' %');
        // return  percentageAndPrecisionFormatter(params) + ' %'
      },
      // valueGetter: (params) => {
      //   return  percentageAndPrecisionFormatter({value:params.data.week4}) + ' %'
      // },
      sortable: true,
      width:100
    },
    {
      headerName: "Week 5 %",
      field: "week5",
      aggrField: "-",
      headerTooltip: "% of coupons redeemed in week 5 of promotion",
      hide: true,
      // valueFormatter: percentageAndPrecisionFormatter,
      valueFormatter: (params) => {
        const value = percentageAndPrecisionFormatter(params);
        return validateCellValue(value) ? value : ( value + ' %');
        // return  percentageAndPrecisionFormatter(params) + ' %'
      },
      // valueGetter: (params) => {
      //   return  percentageAndPrecisionFormatter({value:params.data.week5}) + ' %'
      // },
      sortable: true,
      width:100
    },
  ],
  "Member Metrics": [
    {
      headerName: "Promo Only Members %",
      field: "promoOnlyUsers",
      aggrField: "-",
      headerTooltip: "% of members who only purchased the article on promo",
      hide: true,
      // valueFormatter: numberFormatter,
      valueFormatter: (params) => {
        const value = numberFormatter(params);
        return validateCellValue(value) ? value : ( value + ' %');
        // return  numberFormatter(params) + ' %'
      },
      // valueGetter: (params) => {
      //   return  numberFormatter({value:params.data.promoOnlyUsers}) + ' %'
      // },
      sortable: true
    },
    {
      headerName: "New Members Article %",
      // field: "percOfNewCust",
      field: "percOfNewCustArticle",
      aggrField: "-",
      headerTooltip:
        "Members who have not purchased the article in the past 6 months and are purchasing for the first time on promo over Total members who have purchased the article. (Influx rate)",
      hide: true,
      // valueFormatter: precisionFormatter,
      valueFormatter: (params) => {
        const value = precisionFormatter(params);
        return validateCellValue(value) ? value : ( value + ' %');
        // return  precisionFormatter(params) + ' %'
      },
      // valueGetter: (params) => {
      //   return  precisionFormatter({value:params.data.percOfNewCustArticle}) + ' %'
      // },
      sortable: true
    },
    {
      headerName: "New Members Offers %",
      field: "percOfNewCust",
      aggrField: "-",
      headerTooltip:
        "Members who have not purchased the articles in the past 6 months and are purchasing all the articles on the promotion for the first time over Total members who have purchased the article. (Influx rate)",
      hide: true,
      // valueFormatter: precisionFormatter,
      valueFormatter: (params) => {
        // return  precisionFormatter(params) + ' %'
        const value = precisionFormatter(params);
        return validateCellValue(value) ? value : ( value + ' %');
      },
      // valueGetter: (params) => {
      //   return  precisionFormatter({value:params.data.percOfNewCust}) + ' %'
      // },
      sortable: true
    },
    {
      headerName: "New Members AH4 %",
      field: "percOfNewCustAh4",
      aggrField: "-",
      headerTooltip:
        "Members who have not purchased any article from respective AH4 in the past 6 months and are purchasing for the first time over Total members who have purchased articles from AH4 on promo . (Influx rate)",
      hide: true,
      // valueFormatter: precisionFormatter,
      valueFormatter: (params) => {
        const value = precisionFormatter(params);
        return validateCellValue(value) ? value : ( value + ' %');
        // return  precisionFormatter(params) + ' %'
      },
      // valueGetter: (params) => {
      //   return  precisionFormatter({value:params.data.percOfNewCustAh4}) + ' %'
      // },
      sortable: true
    },
    {
      headerName: "New Members AH6 %",
      field: "percOfNewCustAh6",
      aggrField: "-",
      headerTooltip:
        "Members who have not purchased any article from respective AH6 in the past six months and are purchasing for the first time over total members who have purchased articles from AH6 on promo(Influx rate)",
      hide: true,
      // valueFormatter: precisionFormatter,
      valueFormatter: (params) => {
        const value = precisionFormatter(params);
        return validateCellValue(value) ? value : ( value + ' %');
        // return  precisionFormatter(params) + ' %'
      },
      // valueGetter: (params) => {
      //   return  precisionFormatter({value:params.data.percOfNewCustAh6}) + ' %'
      // },
      sortable: true
    },
  ],

  "Basket Metrics": [
    {
      headerName: "Pull Forward $",
      field: "pullForwardSales",
      aggrField: "sum",
      headerTooltip: "Future Non-promo sales lost due to promotion",
      hide: true,
      // valueFormatter: removePrecisionFormatter,
      valueFormatter: (params) => {
        const value = removePrecisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return '$' + removePrecisionFormatter(params)
      },
      // valueGetter: (params) => {
      //   return '$' + removePrecisionFormatter({value:params.data.pullForwardSales})
      // },
      sortable: true
    },
    {
      headerName: "Cannibalization $",
      field: "cannibalizationSales",
      aggrField: "sum",
      headerTooltip:
        "Cannibalized revenue of competing/substitute articles due to promo",
      hide: true,
      // valueFormatter: removePrecisionFormatter,
      valueFormatter: convertToCurrency,
      // valueGetter: (params) => {
      //   return  convertToCurrency({value:params.data.cannibalizationSales})
      // },
      sortable: true
    },
    {
      headerName: "Affinity $",
      field: "haloSales",
      aggrField: "sum",
      headerTooltip:
        "Revenue generated from sales of affined items due to promo",
      hide: true,
      // valueFormatter: removePrecisionFormatter,
      valueFormatter: (params) => {
        const value = removePrecisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return '$' + removePrecisionFormatter(params)
      },
      // valueGetter: (params) => {
      //   return '$' + removePrecisionFormatter({value:params.data.haloSales})
      // },
      sortable: true
    },
    {
      headerName: "Avg. Promo Basket $",
      field: "promoBasket",
      aggrField: "-",
      headerTooltip:
        "Average basket value when the promoted article was purchased by the member (during promo period)",
      hide: true,
      // valueFormatter: removePrecisionFormatter,
      valueFormatter: (params) => {
        const value = removePrecisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return '$' + removePrecisionFormatter(params)
      },
      sortable: true
    },
    {
      headerName: "Avg. Non Promo Basket $",
      field: "nonPromoBasket",
      aggrField: "-",
      headerTooltip:
        "Average basket value when the promoted article was purchased without promo(during promo period)",
      hide: true,
      // valueFormatter: removePrecisionFormatter,
      valueFormatter: (params) => {
        const value = removePrecisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return '$' + removePrecisionFormatter(params)
      },
      sortable: true
    },
    {
      headerName: "PMR-Id",
      field: "pmrId",
      headerTooltip: "PMR-Id",
      hide: true,
      sortable: true
    }
  ],
};

const DEFAULT_COLUMNS = [
  {
    label: "Offer ID",
    value: "promoId",
  },
  {
    label: "Offer Name",
    value: "title",
  },
  {
    label: "Offer Type",
    value: "actualCouponType",
  },
  {
    label: "Book #",
    value: "mediaCycle",
  },
  {
    label: "FY",
    value: "fYear",
  },
  {
    label: "Quarter",
    value: "fQuater",
  },
  {
    label: "Vendor Funded",
    value: "actualVendorFunded",
  },
];

const COLUMNS_LIST = _.flatten(Object.values(METRICS_COLUMN_NAMES));
const PROMO_ARTICLE_LEVEL_COLUMNS = ['Article Name', 'Article Number','New Members Article %'];
const PROMO_LEVEL_COLUMNS = [];
const PINNED_COLUMNS = ['title', 'actualPerformance', 'id'];
const PROMO_ARTICLE_LEVEL_PINNED_COLUMNS = ['articleDesc', 'actualPerformance', 'id'];

export { METRICS_LIST, METRICS_COLUMN_NAMES, DEFAULT_COLUMNS, COLUMNS_LIST ,PROMO_ARTICLE_LEVEL_COLUMNS,PROMO_LEVEL_COLUMNS,PINNED_COLUMNS ,PROMO_ARTICLE_LEVEL_PINNED_COLUMNS};
