import React, { Fragment, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import MultiSelectCheckboxes from '../MultiSelectCheckboxes';
import SimulationChart from './SimulationChart';
import { Nav } from 'react-bootstrap';
import { convertToCurrency, convertMillionsFormatter } from '../../utils/AgGridFormatters';
import BasketAffect from './BasketAffect';
import { CreatePromoGA } from '../../common/GAUtils';
const convertToTableData = consolidated => {
  let simulationAnalysisData = {};

  ['Sales', 'Units'].forEach((criteria ,index) => {
    if(index ==0){
      simulationAnalysisData[`basketAffect`] = {
        'Affinity' : consolidated.affinityArr,
        'Cannibalization' : consolidated.canniArr
      }
    }
    simulationAnalysisData[`${criteria}`] = [
      {
        metric: 'Baseline',
        value: consolidated[`baseline${criteria}`]
      },
      {
        metric: 'Incremental',
        value: consolidated[`incremental${criteria}`]
      },
      {
        metric: 'Affinity',
        value: consolidated[`affinity${criteria}`]
      },
      {
        metric: 'Cannibalization',
        value: consolidated[`cannibalization${criteria}`]
      },
      {
        metric: 'Total',
        value: consolidated[`total${criteria}`]
      }
    ];
  });
  return simulationAnalysisData;
};
const SimulationResult = props => {
  const options = [{ label: 'Consolidated', value: 'Consolidated' }];
  const [activeTab, setActiveTab] = useState();
  const [discountList, setDiscountList] = useState([]);
  const [articleNoOptions, setArticleNoOptions] = useState([]);
  const [articleNo, setArticleNo] = useState(options);
  const [analysisData, setAnalysisData] = useState({});

  useEffect(() => {
    let keys = Object.keys(props.simulationData);
    if (keys.length > 0) {
      let articleData = keys.map(obj => {
        return { value: obj, label: obj };
      });
      let discounts = Object.keys(props.simulationData[`${keys[0]}`]);
      setActiveTab(discounts[0]);
      setDiscountList(discounts);
      setArticleNoOptions(articleData.reverse());
      setAnalysisData(
        convertToTableData({
          ...props.simulationData.Consolidated[`${discounts[0]}`]
        })
      );
    }
  }, [props.simulationData]);

  const updateAnalysisData = (selectedTab, selectedArticle) => {
    setAnalysisData(
      convertToTableData({
        ...props.simulationData[`${selectedArticle[0].value}`][`${selectedTab}`]
      })
    );
    setActiveTab(selectedTab);
  };
  const updateState = params => {
    submitGA("Click","Changed sku ids simulated results selection");
    setArticleNo(params.articleNo);
    updateAnalysisData(discountList[0], params.articleNo);
  };

  const changeTab = eventKey => {
    submitGA("Click","Changed discount tabs");
    updateAnalysisData(eventKey, articleNo);
  };
  const submitGA =(action,label) =>{
    CreatePromoGA({ action, label});
  }
  const changedView=(value)=>{
    submitGA("Click",`Switch to ${value} view`);
  }
  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <p>
            <h4 className="font-bold font-size-14" style={{ marginTop: '26px' }}>
              Simulation Results
            </h4>
          </p>
        </div>
      </div>
      <hr />
      <div className="row">
        <div className="col-md-3">
          <MultiSelectCheckboxes
            placeholderButtonLabel="Select Simulation Result"
            options={articleNoOptions}
            value={articleNo}
            mappingKey="articleNo"
            updateState={updateState}
            isMulti={false}
            defaultSelectedOptions={true}
          />
        </div>
        <div className="col-md-9">
          <div className="promo-Download">
            <div className="promolist-header">
              <label className="pipelineLabel downloadLbel">Simulated Promo</label>
            </div>
            <button
              className="btn btn-primary iconbutton download-btn"
              onClick={props.handleDownload}
              disabled={false}
            >
              <span>
                <i class="fa fa-download" aria-hidden="true" />
              </span>
            </button>
          </div>
          {/* <div className="promo-Download">
            <div className="promolist-header">
              <label className="pipelineLabel downloadLbel">Download Promo Tracker</label>
            </div>
            <button
              className="btn btn-primary iconbutton download-btn"
              onClick={props.promoTrackerDownload}
              disabled={props.promoStatus===""}
              >
              <span>
                <i class="fa fa-download" aria-hidden="true" />
              </span>
            </button>
          </div> */}
        </div>
      </div>
      <div className="spacer"></div>
      <div className="row" style={{ paddingLeft: '15px' }}>
        <Nav
          fill
          variant="tabs"
          onSelect={changeTab}
          defaultActiveKey="Summary"
          activeKey={activeTab}>
          {discountList.map((obj, index) => (
            <Nav.Item key={obj}>
              <Nav.Link eventKey={obj}>
                <b>$ {obj}</b> &nbsp;Discount &nbsp;&nbsp;
              </Nav.Link>
            </Nav.Item>
          ))}
        </Nav>
      </div>
      <div className="spacer"></div>
      <hr />

      <div className="row promo-simulation-chart">
        <div className="col-md-6">
          <p className="text-center">
            {' '}
            <b>Sales</b>
          </p>
          {analysisData && analysisData.Sales ? (
            <SimulationChart analysisData={analysisData.Sales} />
          ) : null}
        </div>
        <div className="col-md-6">
          <p className="text-center">
            {' '}
            <b>Units</b>
          </p>

          {analysisData && analysisData.Units ? (
            <SimulationChart analysisData={analysisData.Units} />
          ) : null}
        </div>
      </div>
      <div className="spacer"></div>
      <div className="row">
        <div className="col-md-6">
        {analysisData && analysisData.Sales ? (
          <div className="ag-theme-balham" style={{ width: '100%' }}>
            <AgGridReact
              defaultColDef={{
                resizable: true,
              }}
              columnDefs={[
                {
                  headerName: 'Metrics',
                  field: 'metric',
                },
                {
                  headerName: 'Values',
                  field: 'value',
                  valueFormatter: convertToCurrency,
                },
              ]}
              rowData={analysisData.Sales}
              onGridReady={(params) => {
                params.api.sizeColumnsToFit();
              }}
              domLayout="autoHeight"
              enableCellTextSelection={true}
            />
          </div>
           ) : null}
        </div>
        <div className="col-md-6">
        {analysisData && analysisData.Units ? (
          <div className="ag-theme-balham" style={{ width: '100%' }}>
            <AgGridReact
              defaultColDef={{
                resizable: true,
              }}
              columnDefs={[
                {
                  headerName: 'Metrics',
                  field: 'metric',
                },
                {
                  headerName: 'Values',
                  field: 'value',
                  valueFormatter: convertMillionsFormatter,
                },
              ]}
              rowData={analysisData.Units}
              onGridReady={(params) => {
                params.api.sizeColumnsToFit();
              }}
              domLayout="autoHeight"
              enableCellTextSelection={true}
            />
          </div>
          ) : null}
        </div>
      </div>
      <div className="spacer"></div>
      {analysisData && analysisData.basketAffect ? (
        <>
        <BasketAffect basketAffect= {analysisData.basketAffect} changedView={changedView}/>
        <br/>
        </>
      ) : null}
    </>
  );
};

export default SimulationResult;
