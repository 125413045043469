import React from 'react';
import { Form, Button, Col } from 'react-bootstrap';
import Dropzone from 'react-dropzone';
import bsCustomFileInput from 'bs-custom-file-input';

import Select from 'react-select';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Comments from '../../Components/Comments';
import { ticketPriorityCodes, ticketTypes, ticketStatusCodes } from '../../constants';
import { DEFAULT_DESC } from "../../../../common/app-constants"
import { SupportGA   } from '../../../../common/GAUtils';
const SUPPORTED_FORMATS = '.xls, .xlsx, .csv, .jpg, .jpeg, .png';

const schema = Yup.object({
  title: Yup.string().required('Required'),
  description: Yup.string().required(),
  ticket_type_id: Yup.number().required(),
});

function TicketForm(props) {
  const AttachmentList = (innerProps) => {
    return (
      <div className="files-list mt-2">
        {innerProps.attachments.map((attachment) => {
          return (
            <div
              className="file-name file-download"
              key={attachment.id}
              onClick={() => {
                props.downloadAttachment(
                  attachment.id,
                  attachment.filename,
                  attachment.content_type
                );
              }}>
              {attachment.filename}
            </div>
          );
        })}
      </div>
    );
  };
  const Attachments = (props) => {
    return (
      <div className="files-list mt-2">
        {props.files.map((file) => (
          <div className="file-name" key={file.name}>
            <i
              class="fa fa-times-circle file-close"
              onClick={() => {
                const files = props.files.filter((e) => e.name !== file.name);
                props.setFieldValue('files', files);
              }}></i>
            {file.name}
          </div>
        ))}
      </div>
    );
  };
  bsCustomFileInput.init();
  const {
    id,
    user_id,
    ticket_type_id,
    priority_id,
    title,
    tags,
    status_id,
    description,
    all_comments,
    attachments,
    related_data
  } = props.ticketDetails;
  const submitGA =(action,label)=>{
    SupportGA({ action, label});
  } 
  return (
    <Formik
      validationSchema={schema}
      onSubmit={(values, { resetForm }) => {
        props.createTicket(values, resetForm);
      }}
      enableReinitialize
      initialValues={{
        id: id || '',
        user_id: user_id || '',
        ticket_type_id: ticket_type_id || 132242,
        priority_id: priority_id || 30,
        title: title || '',
        description: description || DEFAULT_DESC,
        comment: '',
        user: { email: props.userInfo.email },
        files: [],
      }}>
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        setFieldValue,
        values,
        touched,
        isValid,
        errors,
        handleReset,
      }) => {
        return (
          <Form noValidate onSubmit={handleSubmit}>
            {id && (
              <Form.Row>
                <Col md={2}>
                  <div
                    style={{
                      padding: '0.5rem',
                      color: '#fff',
                      backgroundColor: '#777',
                      borderRadius: '0.5rem',
                    }}>
                    ID: {id}
                  </div>
                </Col>
                <Col md={3}>
                  <div
                    style={{
                      padding: '0.5rem',
                      color: '#fff',
                      backgroundColor: status_id && ticketStatusCodes[status_id].color,
                      borderRadius: '0.5rem',
                    }}>
                    Status: {status_id && ticketStatusCodes[status_id].value}
                  </div>
                </Col>
                 <Col md={4}>
                  <div
                    style={{
                      padding: '0.5rem',
                      color: '#fff',
                      backgroundColor: status_id && ticketStatusCodes[status_id].color,
                      borderRadius: '0.5rem',
                    }}>
                    Created By: {related_data && related_data.user && related_data.user.full_name}
                  </div>
                </Col>
                {tags && !!tags.length && (
                  <Col md={{ span: 7 }}>
                    <div className="tags-container">
                      {tags.map((tag) => (
                        <div className="tag" style={{ backgroundColor: tag.color }}>
                          {tag.label}
                        </div>
                      ))}
                    </div>
                    <span className="tag-icon-container">
                      <i class="fa fa-tags"></i>
                    </span>
                  </Col>
                )}
              </Form.Row>
            )}
            <Form.Row>
              <Form.Group as={Col} md="4" controlId="validationFormik01">
                <Form.Label>Ticket Type</Form.Label>
                <Select
                  isDisabled={id}
                  value={ticketTypes[values.ticket_type_id]}
                  options={Object.values(ticketTypes)}
                  onChange={(selectedOption) => {
                    submitGA("Click","select ticket type");
                    handleChange('ticket_type_id')(selectedOption.value);
                  }}></Select>
              </Form.Group>
              <Form.Group as={Col} md="4" controlId="validationFormik02">
                <Form.Label>Priority</Form.Label>
                <Select
                  isDisabled={id}
                  value={ticketPriorityCodes[values.priority_id]}
                  options={Object.values(ticketPriorityCodes)}
                  onChange={(selectedOption) => {
                    submitGA("Click","select priority");
                    handleChange('priority_id')(selectedOption.value);
                  }}></Select>
              </Form.Group>
            </Form.Row>
            <Form.Group controlId="validationFormik03">
              <Form.Label>Title</Form.Label>
              <Form.Control
                disabled={id}
                size="sm"
                type="text"
                name="title"
                value={values.title}
                onChange={handleChange}
                isValid={touched.title && !errors.title}
              />
            </Form.Group>
            <Form.Group controlId="validationFormik04">
              <Form.Label>Description</Form.Label>
              <Form.Control
                disabled={id}
                size="sm"
                as="textarea"
                rows="5"
                name="description"
                value={values.description}
                onChange={handleChange}
                isValid={touched.description && !errors.description}
              />
            </Form.Group>

            <Form.Group controlId="validationFormik04">
              <Form.Label>Attachments</Form.Label>
              <Dropzone
                accept={SUPPORTED_FORMATS}
                onDrop={(files) => {
                  submitGA("Click","file upload");
                  let counter = { value: 0 };
                  id
                    ? files &&
                      files.length &&
                      props.attachFiles({
                        ticketId: id,
                        counter,
                        files,
                        isCreateFlow: false,
                      })
                    : setFieldValue('files', values.files.concat(files));
                }}>
                {({ getRootProps, getInputProps }) => (
                  <section>
                    <div {...getRootProps({ className: 'dropzone' })}>
                      <input {...getInputProps()} />
                      <p>
                        Drag 'n' drop some files here, or click to select files. Supported formats{' '}
                        {SUPPORTED_FORMATS}
                      </p>
                    </div>
                    {id ? (
                      <AttachmentList attachments={attachments} />
                    ) : (
                      <Attachments files={values.files} setFieldValue={setFieldValue} />
                    )}
                  </section>
                )}
              </Dropzone>
            </Form.Group>

            {id && (
              <Form.Group controlId="validationFormik05">
                <Form.Label>Comments</Form.Label>
                <Comments comments={all_comments}></Comments>
                <Form.Row style={{ marginTop: '0.5rem' }}>
                  <Col md="11">
                    <Form.Control
                      size="sm"
                      as="textarea"
                      placeholder="Add New Comment"
                      rows="1"
                      name="comment"
                      value={values.comment}
                      onChange={handleChange}
                      isValid={touched.comment && !errors.comment}
                    />
                  </Col>
                  <Col md="1" className="t-a-r">
                    <Button
                      disabled={!values.comment}
                      onClick={() => {
                        props.createComment(id, { body: values.comment, user_id });
                        handleChange('comment')('');
                      }}>
                      Add
                    </Button>
                  </Col>
                </Form.Row>
              </Form.Group>
            )}
            {!id && <Button type="submit">Create</Button>}
          </Form>
        );
      }}
    </Formik>
  );
}

export default TicketForm;
