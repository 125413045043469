import React, { Component } from 'react'
import PropTypes from 'prop-types'
import DynamicFilters from '../DynamicFilters';
import { SKUMasterGA, CreatePromoGA   } from '../../common/GAUtils';
import _ from 'lodash';
class CascadingFilters extends Component {
    constructor(props) {
        super(props)
        let merchcat =[];
        let ah6 = [];
        let vendor = [];
        let prevStateSelectedFilters={};
        if(this.props.selectedMerchcat){
            merchcat = this.props.selectedMerchcat
        }
        if(this.props.prevStateSelectedFilters){
            prevStateSelectedFilters = this.props.prevStateSelectedFilters
        }
        this.state ={
            merchcat: merchcat,
            ah6:ah6,
            vendor: vendor,
            ...prevStateSelectedFilters
        }
    }

    updateSelectedOptions = params => {
        let keysLength = Object.keys(params).length;
        if(params.hasOwnProperty("merchcat") && keysLength ===1){
            this.submitGA('Filter','Changed merchcat selection');
            if(!_.isEqual(params.merchcat&&this.state.merchcat)&&!_.isEmpty(this.state.merchcat)&& this.props.filterUpdate){
                this.props.filterUpdate();
            }
        }
        if(params.hasOwnProperty("ah6") && keysLength ===1){
            this.submitGA('Filter','Changed ah6 selection');
            if(this.props.screenName === 'SKU Master' ){
                let data = this.getPrevStateSelectedFilters('ah6');
                if(data.length > 0){
                    
                    params[`ah6`] = data;
                }
            }
            if(!_.isEqual(params.ah6&&this.state.ah6)&&!_.isEmpty(this.state.ah6)&& this.props.filterUpdate){
                this.props.filterUpdate();
            }
        }
        if(params.hasOwnProperty("vendor") && keysLength ===1 ){
            this.submitGA('Filter','changed vendor selection');
            console.log("Prev Selected Filtsrs",params,this.props.prevStateSelectedFilters)
            
            if(this.props.screenName === 'SKU Master'){
                let data = this.getPrevStateSelectedFilters('vendor');
                if(data.length > 0 ){
                    params[`vendor`] = data;
                    this.props.resetPrevSelectedFilters();
                }

            }
            if(!_.isEqual(params.vendor&&this.state.vendor)&&!_.isEmpty(this.state.vendor)&& this.props.filterUpdate){
                this.props.filterUpdate();
            }
        }
        this.setState(params,()=>{
            if(!this.props.isFilterButtonRequired){
                if(this.state.merchcat.length > 0 && this.state.ah6.length > 0 && this.state.vendor.length > 0 ){
                        this.filterData();                    
                }
            }
        });
        
    };
    onReset = () => {
        this.setState({
            vendor: [],
            ah6: [],
            merchcat: [],
        });
        this.props.onReset();
    };

    getLabelName =(value)=>{
        return _.map(value,'label')
    }

    filterData =()=>{
        this.props.filterData({
            merchcat: this.getLabelName(this.state.merchcat),
            vendor: this.getLabelName(this.state.vendor),
            ah6: this.getLabelName(this.state.ah6)
        })
    }

    submitGA(action,label){
        if(this.props.screenName === 'SKU Master'){

            SKUMasterGA({ action, label});
        }
        if(this.props.screenName === 'standalone'){
            CreatePromoGA({ action, label});
        }
    }

    getPrevStateSelectedFilters=(key)=>{
        if(Object.keys(this.props.prevStateSelectedFilters).length > 0 ){
            let data = this.props.prevStateSelectedFilters[`${key}`] || []
            return data;
        }
        return []
    }

    render() {
        let disableMerchcat = this.props.selectedMerchcat||this.props.disableFilter? true : false;
        let disableFilter = this.props.disableFilter?this.props.disableFilter: false;
        let disabledApiCall = this.props.disabledApiCall;
        let DynamicFiltersProps = [
            {
                type: 'Select',
                placeholder: 'Select Category',
                state: this.state.merchcat,
                mappingKey: 'merchcat',
                onMenuCloseApi: true,
                label:'Category',
                grid:'col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4',
                disabled: disableMerchcat,
                disabledApiCall
            }, {
                type: 'MultiSelectCheckboxes',
                placeholder: 'Select AH6',
                state: this.state.ah6,
                mappingKey: 'ah6',
                onMenuCloseApi: true,
                grid:'col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4',
                label:"AH6",
                disabled: disableFilter
            }, {
                type: 'MultiSelectCheckboxes',
                placeholder: 'Select Vendor',
                state: this.state.vendor,
                mappingKey: 'vendor',
                onMenuCloseApi: this.props.screenName != "SKU Master" ? true : false ,
                grid:'col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4',
                label:"Vendor",
                disabled: disableFilter
            }
        ];
        if(this.props.hierarchyLevel){
            DynamicFiltersProps = DynamicFiltersProps.filter(obj=> this.props.hierarchyLevel.indexOf(obj.mappingKey)!==-1)
        }

        if(this.props.hierarchyView){
            const filterList = [];
            this.props.hierarchyLevel.forEach(obj=> {
                let index = DynamicFiltersProps.map(filterObj=> filterObj.mappingKey).indexOf(obj);
                if(index !== -1){
                      
                    filterList.push(DynamicFiltersProps[index])
                }
            })
            DynamicFiltersProps = filterList;
        }
        let DynamicFiltersOptions = {
            merchcatOptions: [],
            ah6Options: [],
            vendorOptions: []
        }


        let hierarchyLevel = this.props.hierarchyLevel || ['merchcat', 'ah6', 'vendor'];
        let hierarchyApiCalls = this.props.hierarchyApiCalls || [{optionsMappingKey: 'merchcat',UrlName:'merchcat'}]
        let hierarchyFilterApi = this.props.hierarchyFilterApi || 'merchcatFilter' ;
        return (
            <>
            <DynamicFilters
                                                filtersList={DynamicFiltersProps}
                                                options={DynamicFiltersOptions}
                                                hierarchyLevel={hierarchyLevel}
                                                hierarchyApiCalls={hierarchyApiCalls}
                                                hierarchyFilterApi ={hierarchyFilterApi}
                                                openLoader={this.props.openLoader}
                                                closeLoader={this.props.closeLoader}
                                                updateParentState={this.updateSelectedOptions}
                                                filterData={this.filterData}
                                                onReset={this.onReset}
                                                viewType="verticle"
                                                screenName={this.props.screenName}
                                                isFilterButtonRequired={this.props.isFilterButtonRequired}
                                                isFilterButtonDisabled={this.state.merchcat.length === 0 || disableFilter}
                                                isResetButtonDisabled={disableFilter}
                                            />
            </>
        )
    }
}

CascadingFilters.propTypes = {

}

export default CascadingFilters