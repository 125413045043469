import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';


const DeleteActionCompo = props => {
    const onDeleteClick = () => {

      const tableData = props.agGridReact.props.rowData;
      const deleteData = props.data;
      const args = {
        tableData,
        deleteData
      }
        props.handleDelete(args);
      };
    return (
        <>
            <span>
              <i class="fa fa-trash-o" onClick={(e) => onDeleteClick(e)}></i>
            </span>
        </>
      );
}

const GroupTableCompnent = (props) => {
  const [gridApi, setGridApi] = useState({});
  const [colDef, setColDef] = useState([]);
  const [rowData, setRowData] = useState([]);

  const handleRemoveArticle  = args => {
    const { deleteData, tableData} = args;
     
      const updatedData = tableData.filter(ele => { return ele.articleNo !== deleteData.articleNo})
      setRowData(updatedData);
      props.updatedTableData({updatedData, deleteData});
  }
  const cloumndef = [
    {
      headerName: 'Article',
      field: 'articleNo',
    //   width: 130,
      sortable: true,
      filter: true,
    },
    {
      headerName: 'Brand',
      field: 'brand',
    //   width: 130,
      sortable: true,
      filter: true,
    },
   
  ];

  useEffect(()=> {
    let tempData = cloumndef;
    if(props.mode === 'create' || props.mode === 'edit'){
      tempData.push( {
        headerName: 'Actions',
        field: 'delete',
        cellRenderer: 'delete',
        cellRendererParams: {
          handleDelete: handleRemoveArticle,
        }
      });
    }
    setColDef(tempData);
  },[]);
  
  useEffect(() => {
    setRowData(props.rowData)
  }, [props.rowData]);


  function onGridReady(params) {
    this.gridApi = params.api;
    setGridApi(params.api);
    this.gridColumnApi = params.columnApi;
    this.gridApi.sizeColumnsToFit();
    // this.gridColumnApi.autoSizeAllColumns();
  }

  return (
    <React.Fragment>
      <div id="defaultGrid" className="ag-theme-balham" style={{ width: '100%'}}>
        <AgGridReact
          columnDefs={colDef}
          rowData={rowData}
          onGridReady={onGridReady}
          // suppressDragLeaveHidesColumns={true}
          // suppressHorizontalScroll={true}
          // suppressRowTransform={true}
          // suppressColumnMoveAnimation={false}
          defaultColDef={{
            resizable: true
          }}
          floatingFilter={true}
          suppressMenu={true}
          suppressRowClickSelection={true}
          frameworkComponents={{
            delete: DeleteActionCompo,
          }}
          enableCellTextSelection={true}
          domLayout="autoHeight"
        //   getRowStyle={getRowStyle}
        />
      </div>
    </React.Fragment>
  );
};

export default GroupTableCompnent;
