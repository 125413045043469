import React, { Fragment, useState, useEffect } from 'react';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import MultiSelectCheckboxes from '../MultiSelectCheckboxes';
import { Form } from 'react-bootstrap';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';
import { getDisStateFrmStatus } from "../../utils/helperFunctions"
const BasicInfo = props => {
  const [focusedInput, setFocusedInput] = useState(null);
  const [bookStartDate, setBookStartDate] = useState(null);
  const [bookEndDate, setBookEndDate] = useState(null);


  useEffect(() => {
    const { bookNumber } = props;
    if (bookNumber && bookNumber.length > 0) {
      setBookStartDate(moment(bookNumber[0].startDate));
      setBookEndDate(moment(bookNumber[0].endDate));
    }
  }, [props.bookNumber])

  const isOutsideRangeHandler = day => {
    let formattedDate = moment(day).format(),
      startDate = moment(bookStartDate).format(),
      endDate = moment(bookEndDate)
        .add(1, "d")
        .format(),
      isSameOrAfter = moment(formattedDate).isSameOrAfter(startDate),
      isSameOrBefore = moment(formattedDate).isSameOrBefore(endDate);
    return !(isSameOrAfter && isSameOrBefore);
  };

  const handleBookChange = (selectedBook) => {
    const { bookNumber } = selectedBook;
    const minDate = moment(bookNumber[0].startDate);
    const maxDate = moment(bookNumber[0].endDate)
    props.updateState(selectedBook);
    props.handleDateChange(minDate, maxDate);
  }

  return (
    <>
      <div className='filter-block'>
      <h4 className='font-bold font-size-14'>Basic Information</h4>
      <div className='spacer'></div>
      <div className='row'>
        <div className='col-md-4'>
          <label>
            <span className='text-danger'>*</span> Offer Type
          </label>
          <div className='row'>
            <div className='col-md-4'>
              <label>
                <input
                  type='radio'
                  value='BBM'
                  checked={props.offerType === 'BBM'}
                  onChange={props.onOfferChange}
                  name='offerType'
                  disabled={getDisStateFrmStatus(props.promoStatus)}
                />
                &nbsp;&nbsp; BBM
              </label>
            </div>
            <div className='col-md-4'>
              <label>
                <input
                  type='radio'
                  value='FOC'
                  checked={props.offerType === 'FOC'}
                  onChange={props.onOfferChange}
                  name='offerType'
                  disabled={getDisStateFrmStatus(props.promoStatus)}
                />
                &nbsp;&nbsp; FOC
              </label>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <label>
            <span className='text-danger'>*</span> Promo Type
          </label>
          <div className='row'>
            <div className='col-md-4'>
              <label>
                <input
                  type='radio'
                  value='Paper Coupon'
                  checked={props.mediaType === 'Paper Coupon'}
                  onChange={props.onOfferChange}
                  name='mediaType'
                  disabled={getDisStateFrmStatus(props.promoStatus)}
                />
                &nbsp;&nbsp; Paper
              </label>
            </div>
            <div className='col-md-4'>
              <label>
                <input
                  type='radio'
                  value='Clipless Coupon'
                  checked={props.mediaType === 'Clipless Coupon'}
                  onChange={props.onOfferChange}
                  name='mediaType'
                  disabled={getDisStateFrmStatus(props.promoStatus)}
                />
                &nbsp;&nbsp; Clipless
              </label>
            </div>
          </div>
        </div>
        <div className='col-md-4'>
          <label>
            <span className='text-danger'>*</span> Vendor Funded
          </label>
          <div className='row'>
            <div className='col-md-3'>
              <label>
                <input
                  type='radio'
                  value={true}
                  checked={
                    props.isVendorFunded === true ||
                    props.isVendorFunded === 'true'
                  }
                  onChange={props.onOfferChange}
                  name='isVendorFunded'
                  disabled={getDisStateFrmStatus(props.promoStatus)}
                />
                &nbsp;&nbsp; Yes
              </label>
            </div>
            <div className='col-md-3'>
              <label>
                <input
                  type='radio'
                  value={false}
                  checked={
                    props.isVendorFunded === false ||
                    props.isVendorFunded === 'false'
                  }
                  onChange={props.onOfferChange}
                  name='isVendorFunded'
                  disabled={getDisStateFrmStatus(props.promoStatus)}
                />
                &nbsp;&nbsp; No
              </label>
            </div>
          </div>
        </div>
      </div>
      <div className='spacer'></div>
      <div className='row' style={{paddingBottom:"20px"}}>
        <div className='col-md-4'>
          <label>
            <span className='text-danger'>*</span> Offer Name
          </label>
          <div>
            <input
              className='form-control'
              name='offerName'
              value={props.offerName}
              onChange={event => {
                props.onOfferChange(event);
              }}
              disabled={getDisStateFrmStatus(props.promoStatus)}
            />
          </div>
        </div>
        <div className='col-md-4'>
          <label>
            <span className='text-danger'>*</span> Book Number
          </label>
          <MultiSelectCheckboxes
            placeholderButtonLabel='Book Number'
            options={props.bookNumberOptions}
            value={props.bookNumber}
            mappingKey='bookNumber'
            updateState={handleBookChange}
            isMulti={false}
            disabled={getDisStateFrmStatus(props.promoStatus)}
          />
        </div>
        <div className='col-md-4'>
          <label>
            <span className='text-danger'>*</span> Promotion Period
          </label>
          <DateRangePicker
            startDateId='promo_period_start_date'
            startDate={props.promoStartDate}
            endDate={props.promoEndDate}
            minDate={bookStartDate}
            maxDate={bookEndDate}
            endDateId='promo_period_end_date'
            onDatesChange={({ startDate, endDate }) =>
              props.handleDateChange(startDate, endDate)
            }
            focusedInput={focusedInput}
            onFocusChange={focusedInput2 => setFocusedInput(focusedInput2)}
            isOutsideRange={day => isOutsideRangeHandler(day)}
            disabled={getDisStateFrmStatus(props.promoStatus)}
          />
        </div>
      </div>
      </div>
      </>
  );
};

export default BasicInfo;
