import React, { Fragment, useState, useEffect, useRef } from "react";
import { fetchPromoCampaigns } from "../../apis/common.api.";
import isEmpty from "lodash/isEmpty";
import _ from "lodash";
import { getDisStateFrmStatus } from "../../utils/helperFunctions"


const AddSku = props => {
 
  return (
    <>
      <div className="row promo-action-buttons-wrapper">
        {/* <div className="col-md-4"></div> */}
        <div className="promo-action-button">
          <button
            className="btn btn-lg btn-primary filterBtn my-3"
            onClick={props.getSimulationResult}
            disabled={ !props.enableSimulation || getDisStateFrmStatus(props.promoStatus) || props.targetPromoValueArr.length ===0 }
          >
            Simulate
          </button>
        </div>
        {props.userInfo.indexOf('save')!==-1 ? 
        (<>
          <div className="promo-action-button">
          <button
            className="btn btn-lg btn-primary filterBtn my-3"
            onClick={props.savePromo}
            disabled={ !props.enableSave || getDisStateFrmStatus(props.promoStatus)}
          >
            Save
          </button>
        </div>
          </>) : null}
        {props.userInfo.indexOf('submit')!==-1 ? (
          <>
          <div className="promo-action-button">
          <button
            className="btn btn-lg btn-primary filterBtn my-3"
            onClick={props.submitPromo}
            disabled={ !props.enableSubmit || getDisStateFrmStatus(props.promoStatus)}
          >
            Submit
          </button>
        </div>
          </>
        ) : null}
      </div>
    </>
  );
};

export default AddSku;
