import isEmpty from '../validation/is-empty';

import { SET_CURRENT_USER, LOGOUT_CURRENT_USER } from '../actions/types';
import setAuthToken from '../utils/setAuthToken';

const initialState = {
  isAuthenticated: false,
  user: {}
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated:
          action.payload || localStorage.getItem('token') ? true : false,
        user: action.payload
      };
    case LOGOUT_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: false,
        user: {}
      };
    default:
      return state;
  }
}
