import axios from 'axios';
import environment from './../environments/environment';
import { GET_ERRORS, SET_CURRENT_USER } from './types';
import setAuthToken from '../utils/setAuthToken';
export const getRecommendedPromo = params => dispatch => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: localStorage.getItem('token')
  });
  var url = environment.baseUrl + '/eda/getRecommendedPromoList';
  if (params) {
    url += params;
  }
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: 'GET_RECOMMENDED_PROMO',
        payload: res.data
      });
    })
    .catch(err => {
      //
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const getCumulativeFilters = reqObject => dispatch => {
  setAuthToken(localStorage.getItem('token'), true);
  dispatch({
    type: SET_CURRENT_USER,
    payload: localStorage.getItem('token')
  });
  axios
    .post(`${environment.baseUrl}/promo/individualFiltersApi`, reqObject)
    .then(res => {
      dispatch({
        type: 'FILTER_DATA',
        payload: res.data.data
      });
    })
    .catch(err => {
      dispatch({
        type: 'GET_ERRORS',
        payload: err
      });
    });
};

export const getPromoFilters = () => dispatch => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: localStorage.getItem('token')
  });
  dispatch({
    type: 'LOADER',
    payload: true
  });
  axios
    .get(environment.baseUrl + '/hierarchy/getAllHierarchy')
    .then(res => {
      dispatch({
        type: 'GET_PROMO_FILTERS',
        payload: res.data
      });
      dispatch({
        type: 'LOADER',
        payload: false
      });
    })
    .catch(err => {
      //
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      dispatch({
        type: 'LOADER',
        payload: false
      });
    });
};

export const getPromoConfigureData = filters => dispatch => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: localStorage.getItem('token')
  });
  var url = environment.baseUrl + '/promo/getPromoConfig';
  if (filters) {
    url = url + '?' + filters;
  }
  axios
    .get(url)
    .then(res => {
      dispatch({
        type: 'GET_PROMO_CONFIG',
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const updateData = (configureData, next) => dispatch => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: localStorage.getItem('token')
  });

  axios
    .post(environment.baseUrl + `/promo/updatePromoConfig`, configureData)
    .then(res => {
      dispatch({
        type: 'UPDATE_CONFIG',
        payload: res.data
      });
      return next();
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message
      });
    });
};

export const insertRecommendedData = (reqObj, next) => dispatch => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: localStorage.getItem('token')
  });

  axios
    .post(environment.baseUrl + `/promo/insertRecommendData`, reqObj)
    .then(res => {
      dispatch({
        type: 'INSERT_RECOMMEND_PROMO',
        payload: res.data
      });
      return next();
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message
      });
    });
};

export const updateRecommendPromo = reqObj => dispatch => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: localStorage.getItem('token')
  });

  axios
    .post(environment.baseUrl + `/promo/updateRecommendData`, reqObj)
    .then(res => {
      dispatch({
        type: 'UPDATE_RECOMMEND_PROMO',
        payload: res.data
      });
    })
    .catch(err => {
      dispatch({
        type: GET_ERRORS,
        payload: err.message
      });
    });
};

export const getshowPromotionPipelineData = val => dispatch => {
  dispatch({
    type: 'LOADER',
    payload: true
  });
  axios
    .post(environment.baseUrl + '/promo/promoData', val)
    .then(res => {
      dispatch({
        type: 'GET_PROMOTION_PIPELINE',
        payload: res.data
      });
      dispatch({
        type: 'LOADER',
        payload: false
      });
    })
    .catch(err => {
      //
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      dispatch({
        type: 'LOADER',
        payload: false
      });
    });
};

export const getPromoArticleLevel = val => dispatch => {
  dispatch({
    type: 'LOADER',
    payload: true
  });
  axios
    .post(environment.baseUrl + '/promo/promoHierarchyData', val)
    .then(res => {
      dispatch({
        type: 'GET_PROMOTION_ARTICLE_LEVEL',
        payload: res.data
      });
      dispatch({
        type: 'LOADER',
        payload: false
      });
    })
    .catch(err => {
      //
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      dispatch({
        type: 'LOADER',
        payload: false
      });
    });
};
export const getAnalyzeEventDetails = val => dispatch => {
  if (val.screenName != 'historical') {
    dispatch({
      type: 'LOADER',
      payload: true
    });
  }
  axios
    .post(environment.baseUrl + '/promo/campaigns', val)
    .then(res => {
      dispatch({
        type: 'GET_CAMPAIGNS_LIST',
        payload: res.data
      });
      if (val.screenName != 'historical') {
        dispatch({
          type: 'LOADER',
          payload: false
        });
      }
    })
    .catch(err => {
      //
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      dispatch({
        type: 'LOADER',
        payload: false
      });
    });
};

export const getCompaignDetails = val => dispatch => {
  dispatch({
    type: 'LOADER',
    payload: true
  });
  axios
    .get(
      environment.baseUrl + `/promo/campaignAnalytics?campaignId=${val}`
    )
    .then(res => {
      dispatch({
        type: 'GET_CAMPAIGNS_LIST',
        payload: res.data
      });
      dispatch({
        type: 'LOADER',
        payload: false
      });
    })
    .catch(err => {
      //
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
      dispatch({
        type: 'LOADER',
        payload: false
      });
    });
};

export const openLoader = val => dispatch => {
  dispatch({
    type: 'LOADER',
    payload: true
  });
};
export const closeLoader = val => dispatch => {
  dispatch({
    type: 'LOADER',
    payload: false
  });
};
