import { GET_SKUS, DATA_LOADING, SET_BOOK_NUMBERS  ,SET_SIMULATE_DATA ,SET_PROMO_ID , RESET_PROMO_ID , SET_MERCHCAT, SET_PROMO_DATA, SET_PROMO_SATUS} from '../actions/types';

const initialState = {
  skuData: [],
  loading: false,
  bookNumbers:[],
  simulationData:{},
  promoId: null,
  promoData: {},
  merchcatValues:[],
  promoStatus:''
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SKUS:
      return {
        ...state,
        skuData: action.payload
      };
    case DATA_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SET_BOOK_NUMBERS:
      return {
        ...state,
        bookNumbers: action.payload
      };
    case SET_SIMULATE_DATA:
        return {
          ...state,
          simulationData: action.payload
        };
    case SET_PROMO_ID:
          return {
            ...state,
            promoId: action.payload
          };
    case SET_PROMO_DATA:
      return {
        ...state,
        promoData: action.payload
      };
    case SET_PROMO_SATUS: 
      return{
        ...state,
        promoStatus: action.payload
      }
    case RESET_PROMO_ID:
          return {
            ...state,
            promoId: action.payload
          };
    case SET_MERCHCAT:
          return {
            ...state,
            merchcatValues: action.payload
          };
            
    default:
      return state;
  }
}
