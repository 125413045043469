import {
  SET_LOADER,
  GET_CONSTRAINTS_LIST,
  RESET_CONSTRAINTS_LIST,
  SET_PLANID,
  SET_VENDOR_LIST,
  SET_ARTICLE_LIST,
  SET_ARTICLE_DETAILS,
  SET_GROUP_DETAILS,
  SET_SELECTED_ARTICLE
} from './types';
import {
  fetchConstraintsData,
  deleteConstraintsData,
  saveConstraintsData,
  downloadConstraintsData,
  fetchVendorData,
  fetchArticleData,
  fetchGroupDiscountDepth,
  addGroup,
  fetchGroupdata,
  fetchGroupConstraintsData,
  deleteGroupData
} from '../apis/constraints.api';
import { toast } from 'react-toastify';
import _ from 'lodash'
import { formatGroupArticleData } from '../utils/helperFunctions';


const arrangeConstraintsData = (groupData, result) => {
  const articleToResultObj = _.keyBy(result.data, 'articleNo');
  let res = [];
  groupData.data.forEach(item => {
    res.push(item);
    if (item.selectedArticles) {
      item.selectedArticles.forEach(selectedArticle => {
        if(articleToResultObj[selectedArticle]){
        articleToResultObj[selectedArticle].groupid = item.groupid;
        res.push(articleToResultObj[selectedArticle]);
        delete articleToResultObj[selectedArticle];
        }
      })
    }
  })
  res = [...res, ...Object.values(articleToResultObj)];
  return res; 
}

const modifyData = data => {
    let tempData = data;
    tempData.forEach((ele) => {
      if (ele.articleNo) {
        ele.articleOrGroup = ele.articleNo;
      } else {
        ele.articleOrGroup = ele.groupid;
      }
    });
  return tempData;
}

export const actionGetConstraintsList = (data) => async (dispatch) => {
  try {
    let tempData = data;
    dispatch({ type: SET_LOADER, payload: true });
    const result = await fetchConstraintsData(data);
    if (result.planId) {
      tempData.planId = result.planId;
      dispatch({ type: SET_PLANID, payload: result.planId });
    }
    const groupData = await fetchGroupConstraintsData(tempData);
    if (groupData && groupData.data) {
     let res  = arrangeConstraintsData(groupData, result);
      result['data'] = res;
    }

    const finalData = modifyData(result.data);

    dispatch({ type: GET_CONSTRAINTS_LIST, payload: finalData });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionResetConstraintsList = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    dispatch({ type: GET_CONSTRAINTS_LIST, payload: [] });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionDeleteConstraint = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    await deleteConstraintsData(data);
    // dispatch({ type: GET_CONSTRAINTS_LIST, payload: result });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionSaveConstraint = (data) => async (dispatch) => {
  try {
    let tempFilterData = data.filterData;
    dispatch({ type: SET_LOADER, payload: true });
    const planResult = await saveConstraintsData(data.params);
    if (planResult.planId) {
      tempFilterData.planId = planResult.planId;
      dispatch({ type: SET_PLANID, payload: planResult.planId });
    }
    const result = await fetchConstraintsData(tempFilterData);
    const groupData = await fetchGroupConstraintsData(tempFilterData);

    if (groupData && groupData.data) {
      let res  = arrangeConstraintsData(groupData, result);
      result['data'] = res;
    }

    const finalData = modifyData(result.data);
    // result.data.push(...groupData.data);
    if (result.planId) {
      dispatch({ type: SET_PLANID, payload: result.planId });
    }
    

    dispatch({ type: GET_CONSTRAINTS_LIST, payload: finalData });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionDownloadConstraints = (reqObj) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await downloadConstraintsData(reqObj);
    window.open(result.url, '_self');
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionGetVendorList = (reqObj) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await fetchVendorData(reqObj);
    dispatch({ type: SET_VENDOR_LIST, payload: result });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionGetArticleList = (reqObj) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await fetchArticleData(reqObj);
    dispatch({ type: SET_ARTICLE_LIST, payload: result.map(obj=> { return parseInt(obj)} ) });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionfetchGroupDiscountDepth = (reqObj) => async (dispatch) => {
  let data = [];
  try {
    dispatch({ type: SET_LOADER, payload: true });
    if (reqObj.articleNo.length > 0) {
      const result = await fetchGroupDiscountDepth(reqObj);
      data = formatGroupArticleData(result);
    }
    dispatch({ type: SET_ARTICLE_DETAILS, payload: data });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionCreateGroup = (reqObj) => async (dispatch) => {
  try {
    let tempFilterData = reqObj.filterData;
    dispatch({ type: SET_LOADER, payload: true });
    const planResult = await addGroup(reqObj.params);
    if (planResult.planId) {
      tempFilterData.planId = planResult.planId;
      dispatch({ type: SET_PLANID, payload: planResult.planId });
    }
    const result = await fetchConstraintsData(tempFilterData);
    const groupData = await fetchGroupConstraintsData(tempFilterData);

    if (groupData && groupData.data) {
      let res  = arrangeConstraintsData(groupData, result);
      result['data'] = res;
    }
    // result.data.push(...groupData.data);
    const finalData = modifyData(result.data);
    
    if (result.planId) {
      dispatch({ type: SET_PLANID, payload: result.planId });
    }
    

    dispatch({ type: GET_CONSTRAINTS_LIST, payload: finalData });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const actionResetGroupData = (reqObj) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    dispatch({ type: SET_ARTICLE_DETAILS, payload: reqObj });
    dispatch({type: SET_GROUP_DETAILS, payload: {}});
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const isValidGroup = (data) => async (dispatch) => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await fetchGroupdata(data);

    console.log("++++++++++++++++++++++validGroupId", result);
    dispatch({ type: SET_LOADER, payload: false });
    return result.length > 0;
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

  export const actionFechGroupData = (reqObj) => async (dispatch) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const { groupid, merchcat, sendInactive, sendSimulated } = reqObj;
      const result = await fetchGroupdata(groupid);
      if(result.length>0) {
        let groupData = [];
        
        const articleNo = result.map(element => element.articleno);
       
        let groupDetails = {
          groupName : result[0].groupid,
          vendor: result[0].vendor,
          articleNo
        }

        const reqData ={
          articleNo
        }

        const articleListReq = {
          merchcat,
          vendor: [result[0].vendor],
          sendInactive,
          sendSimulated
        }
        const data = await fetchGroupDiscountDepth(reqData);
        groupData = formatGroupArticleData(data);
        dispatch({type: SET_GROUP_DETAILS, payload: groupDetails});
        dispatch({ type: SET_ARTICLE_DETAILS, payload: groupData });

        const vendorList = await fetchVendorData({merchcat, sendSimulated});
        dispatch({ type: SET_VENDOR_LIST, payload: vendorList });

        const articleList = await fetchArticleData(articleListReq);
        dispatch({ type: SET_ARTICLE_LIST, payload: articleList });
      }
      dispatch({ type: SET_LOADER, payload: false });
    } catch (err) {
      dispatch({ type: SET_LOADER, payload: false });
    }
  };

  export const actionDeleteGroup = (data) => async (dispatch) => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      await deleteGroupData(data);
      // dispatch({ type: GET_CONSTRAINTS_LIST, payload: result });
      dispatch({ type: SET_LOADER, payload: false });
    } catch (err) {
      dispatch({ type: SET_LOADER, payload: false });
    }
  };

  export const actionSetSelecteArticle = data => async (dispatch) => {
    try{
      dispatch({ type: SET_LOADER, payload: true });
      dispatch({type: SET_SELECTED_ARTICLE, payload: data});
      dispatch({ type: SET_LOADER, payload: false });
    } catch (err) {
      dispatch({ type: SET_LOADER, payload: false });
    }
  }
