import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import TableWrapperMainDiv from '../../../common/TableWrapper';
import _ from 'lodash';
import BudgetMetricsDropDown from '../BudgetMetricsDropDown'

const BudgetInactiveArticleTable = ({plannedArticleData, inactiveMetricsData, actoinUpdateInactiveMetrics, budgetPlanCampaignList}) => {
  const [colDefs, setColDefs] = useState([]);
  const [gridApi, setGridApi] = useState({});
  const [gridColumnApi, setGridColumnApi] = useState({});
  const [rowData, setRowData] = useState([]);
  const [articleList, setArticleList] = useState([]);

  useEffect(() => {
    const data = Object.values(plannedArticleData.data);
    setArticleList(data.filter(article => !article.active ));
  }, [plannedArticleData]);

  useEffect(() => {
    const columnDefs = [
      {
        headerName: "LY",
        field: "campaignType",
        cellStyle: campaignTypeCellStyle,
        pinned: 'left'
      },
      {
        headerName: "Total",
        field: "total",
        pinned: 'left'
      }
    ];
    plannedArticleData.books.forEach(bookNumber => {
      const bookNum = bookNumber.split('-')[1];
      columnDefs.push({
        headerName: `Book ${bookNum}`,
        field: `book${bookNum}`
      });
    });

    setColDefs(columnDefs);
    if(inactiveMetricsData.length >0){
      setRowData(inactiveMetricsData);
    }
  }, [inactiveMetricsData]);

  useEffect(() => {
    if (Object.keys(gridApi).length > 0) {
      if(colDefs.length <= 10){
        gridApi.sizeColumnsToFit();
      }else{
        gridColumnApi.autoSizeAllColumns();
      }
    }
  }, [rowData]);
  var gridOptions = {
    defaultColDef: {
      resizable: true,
      width: 100,
    },
    suppressRowTransform: true,
  };

  function onGridReady(params) {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.gridApi.sizeColumnsToFit();
  }

  const handleFilterChange = (articleList) => {
    actoinUpdateInactiveMetrics({
      articles: articleList,
      budgetPlanCampaignList
    });
  }

  const overlayNoRowsTemplate= "No Inactive Articles to Display";
  return (
    <>
    <div id="inactiveMetrics">
        <p>Inactive Articles Table</p>
    </div>
      <BudgetMetricsDropDown
        articleList={articleList}
        handleFilterChange={handleFilterChange}
        articleSelectedFromPlanTable={inactiveMetricsData[0] ? inactiveMetricsData[0].selectedArticleNum : undefined}
      ></BudgetMetricsDropDown>
      <div id="budgetInactiveArticleTable">
        <TableWrapperMainDiv>
          <AgGridReact
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            domLayout="autoHeight"
            rowData={rowData}
            columnDefs={colDefs}
            enableCellTextSelection={true}
            overlayNoRowsTemplate={overlayNoRowsTemplate}
          />
        </TableWrapperMainDiv>
      </div>
    <hr />
    </>
  );
};

export default BudgetInactiveArticleTable;

//custom style for table cells
 
  const campaignTypeCellStyle = {
    color: '#44677B'
  };
