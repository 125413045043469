import React, { Component, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import TableWrapper from '../../../common/TableWrapper';
import EditBudget from '../../../../common/EditBudgetComponent/index';
import endsWith from 'lodash/endsWith';
import { convertMillionsFormatter } from '../../../../utils/AgGridFormatters';
import { AllCommunityModules } from '@ag-grid-community/all-modules';
import '@ag-grid-community/all-modules/dist/styles/ag-grid.css';

const ExpandCollapse = (props) => {
  const handleExpand = (data, rowData, type) => {
    props.handleExpandCollapse(data, rowData, type);
  };

  const count = props.agGridReact.props.rowData.filter((obj) => obj.vendor === props.value).length;
  return (
    <div>
      { props.data.brand === 'ALL BRANDS' && count === 1 ? (
        <span
          className="expand_collapse"
          onClick={() => handleExpand(props.data, props.agGridReact.props.rowData, 'expand')}>
          <i class="fa fa-chevron-circle-down" aria-hidden="true"></i>
        </span>
      ) : props.data.brand == 'ALL BRANDS' && count > 1 ? (
        <span
          className="expand_collapse"
          onClick={() => handleExpand(props.data, props.agGridReact.props.rowData, 'collapse')}>
          <i class="fa fa-chevron-circle-up" aria-hidden="true"></i>
        </span>
      ) : null}
      <span>
      {props.value}
      </span>
    </div>
  );
};
const BudgetMasterTable = ({
  tableHeaders,
  budgetMasterData,
  updateModifiedData,
  modifiedData,
  saveBudgetMaster,
  userInfo,
  year,
  handleExpandCollapse,
  vendorBrandBudgetData,
  actionUpdateVendorBrandBudget,
  handleBrandBudgetModification,
  handleVendorBudgetModification,
  budgetMasterTableData
}) => {
  const [totalGridData, setTotalGridData] = useState([]);
  const [gridData, setGridData] = useState([]);
  const [updatedBudgetMaster, setUpdatedBudgetMaster] = useState([]);
  const [colDef, setColDef] = useState([]);
  const [totalColDef, setTotalColDef] = useState([]);
  const [gridApi, setGridApi] = useState({});
  const [totalgridApi, setTotalGridApi] = useState({});
  useEffect(() => {
    if (Object.keys(gridApi).length > 0) {
      gridApi.sizeColumnsToFit();
    }
  }, [gridData, totalGridData]);
  useEffect(() => {
    setUpdatedBudgetMaster(budgetMasterData)
    let formattedData = formatNumber(budgetMasterData);
    setGridData(formattedData);
   
    
  }, [budgetMasterData]);

  useEffect(() => {
    updateTotalCount(true);
    let itemsToUpdate = [];
    if (Object.keys(gridApi).length > 0) {
      gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index){
        let data = rowNode.data;
        itemsToUpdate.push(data);
      });
      gridApi.updateRowData({ update: itemsToUpdate });
    }
  }, [updatedBudgetMaster, gridApi, budgetMasterData]);

  useEffect(() => {
    const cd = tableHeaders.map((key, index) => {
      if (index > 1) {
        return {
          headerName: `Quarter ${key[key.length - 1]} - ${year[0]}`,
          children: [
            {
              headerName: 'LY',
              field: `${key}_budgetAvailbleLY`,
              suppressMovable: true,
              width: 110,
            },
            {
              headerName: 'TY',
              field: `${key}_budgetAvailbleTY`,
              cellRenderer: 'editBudget',
              width: 110,
              cellRendererParams: {
                handleBudgetChange,
                isDisabled: !(
                  budgetMasterData[0][`${key}_budgetAvailbleTY_editable`] &&
                  userInfo.action.includes('save-budget')
                ),
              },
              suppressMovable: true,
            },
          ],
          suppressMovable: true,
        };
      }
      if (index === 0) {
        return {
          headerName: 'Vendor',
          field: key,
          suppressMovable: true,
          width: 130,
          cellRenderer: 'expandCollapse',
          cellRendererParams: {
            handleExpandCollapse,
          },
          cellClass: 'cellPadding',
          tooltipField: key
        };
      }
      return {
        headerName: 'Brand',
        field: key,
        suppressMovable: true,
        width: 80,
        tooltipField: key
      };
    });

    const tcd = [
      {
        headerName: `${tableHeaders[0]}`,
        field: 'vendor',
        suppressMovable: true,
        width: 130,
      },
      {
        headerName: `${tableHeaders[1]}`,
        field: 'brand',
        suppressMovable: true,
        width: 80,
      },
    ];
    tableHeaders.forEach((tableHeader, index) => {
      if (index > 1) {
        tcd.push({
          headerName: 'LY',
          field: `${tableHeader}_budgetAvailbleLY`,
          suppressMovable: true,
          width: 110,
        });
        tcd.push({
          headerName: 'TY',
          field: `${tableHeader}_budgetAvailbleTY`,
          suppressMovable: true,
          width: 110,
        });
      }
    });

    setColDef(cd);
    setTotalColDef(tcd);
    // if(Object.keys(gridApi).length > 0){
    //   gridApi.sizeColumnsToFit();
    // }
  }, [tableHeaders]);

  const handleBudgetChange = (data) => {
    const { currentRow, field, value, rowData } = data;
    const { vendor } = currentRow;
    const reqObj = {
      vendor,
      field,
      value,
    };
    let updatedRow = [];
    // updateModifiedData(reqObj);
    if (currentRow.brand !== 'ALL BRANDS') {

      updatedRow = handleBrandBudgetModification(data);
  } else {
    updatedRow = handleVendorBudgetModification(data);
  }
    setUpdatedBudgetMaster(updatedRow);
  };

  function onGridReady(params) {
    setGridApi(params.api);
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  function onGridReadyTotal(params) {
    setTotalGridApi(params.api);
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  const formatNumber = (arg) => {
    let formattedData = [];
    arg.map((data) => {
      let formatedRowData = {};
      Object.keys(data).forEach((value) => {
        if (
          typeof data[value] !== 'string' &&
          typeof data[value] !== 'function' &&
          !endsWith(value, 'TY')
        ) {
          formatedRowData[value] = convertMillionsFormatter({ value: data[value] });
        } else {
          endsWith(value, 'TY')
            ? (formatedRowData[value] = data[value])//Math.round(data[value]))
            : (formatedRowData[value] = data[value]);
        }
      });
      formattedData.push(formatedRowData);
    });
    return formattedData;
  };

  function updateTotalCount(isEdit) {
    let total = {};
    let formattedTotal = {};
    // let totalGridData = isEdit ? budgetMasterData: data ;
    let tepmGridData = vendorBrandBudgetData;
    if (tepmGridData.length > 0) {
      tepmGridData.map((rowData, index) => {
        Object.keys(rowData).forEach((key) => {
          if (total[key]) {
            if (typeof rowData[key] === 'string' ) {
              key==='brand'?total[key] = '-' : total[key] = 'TOTAL';
            } else {
              // isEdit && endsWith(key, 'TY')
              //   ? (total[key] += updatedBudgetMaster[index][key] || 0)
                // : 
                total[key] += rowData[key];
            }
          } else {
            if (typeof rowData[key] === 'string') {
              key==='brand'?total[key] = '-' : total[key] = 'TOTAL';
            } else {
              // isEdit && endsWith(key, 'TY')
              //   ? (total[key] = updatedBudgetMaster[index][key] || 0)
              //   : (
                total[key] = rowData[key];
            }
          }
        });
      });
    }

    Object.keys(total).forEach((value) => {
      if (typeof total[value] !== 'string' && typeof total[value] !== 'function') {
        formattedTotal[value] = convertMillionsFormatter({ value: total[value] });
      } else {
        formattedTotal[value] = total[value];
      }
    });

    // let tableData = gridData;
    // tableData.push(formattedTotal);

    // setGridData(tableData);

    setTotalGridData([formattedTotal]);
  }

  return (
    <div className="budget-master-table">
      {/* {console.log("gridData", gridData)} */}
      {/* As per disussion with sumeet not showing total available budget */}
      {/* <BudgetMasterTableTitleContent>
                <label className="budget-labels">Available Budget</label>
                <input
                className="form-control catagorySelect"
                placeholder="Enter available budget"
                value={!isEmpty(totalGridData)?totalGridData[0].budgetAvailable : 0 }
                disabled
                />
            </BudgetMasterTableTitleContent> */}
      <TableWrapper>
        <AgGridReact
          // modules={[ClientSideRowModelModule]}
          singleClickEdit={true}
          suppressDragLeaveHidesColumns={true}
          suppressHorizontalScroll={true}
          suppressRowTransform={true}
          suppressColumnMoveAnimation={false}
          onGridReady={onGridReady}
          columnDefs={colDef}
          rowData={gridData}
          domLayout="autoHeight"
          frameworkComponents={{
            editBudget: EditBudget,
            expandCollapse: ExpandCollapse,
          }}
          enableCellTextSelection={true}
          modules={AllCommunityModules}
          // onCellValueChanged={function(params) {
          //   return  params.api.redrawRows()}}
        ></AgGridReact>
        <div className="dispay-total">
          <AgGridReact
            singleClickEdit={true}
            suppressDragLeaveHidesColumns={true}
            suppressHorizontalScroll={true}
            suppressRowTransform={true}
            suppressColumnMoveAnimation={false}
            onGridReady={onGridReadyTotal}
            columnDefs={totalColDef}
            rowData={totalGridData}
            headerHeight="0"
            domLayout="autoHeight"
            rowHeight="35"
            enableCellTextSelection={true}
          />
        </div>
      </TableWrapper>
      {userInfo.action.indexOf('save-budget') !== -1 ? (
        <>
        {console.log("data:",modifiedData)}
          <div className="text-center">
            <button
              type="button"
              onClick={saveBudgetMaster}
              className="btn btn-primary saveButton"
              disabled={modifiedData.length === 0}>
              Save
            </button>
          </div>
        </>
      ) : null}
    </div>
  );
};

export default BudgetMasterTable;
