import {
  numberFormatter,
  removePrecisionFormatter,
  precisionFormatter,
  percentageFormatter,
  emptyValueFormatter,
  convertedBooleanValue,
  convertMillionsFormatterWithoutPrecision,
  percentageAndPrecisionFormatter,
  validateCellValue
} from "../../utils/AgGridFormatters";

export const MONTH_NAMES = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]

export const METRIC_COLUMN_NAMES = [
  {
      headerName: "Article Name",
      field: "description",
      headerTooltip:
        "Unique BJ's description for each article (SKU) mapped to a Promotion",
      pinned: "left",
      sortable: true,
      filter: true,
    },
    {
      headerName: "Article Number",
      field: "articleNo",
      headerTooltip: "Unique BJ's identifier for each article (SKU)",
      pinned: "left",
      filter: true,
      sortable: true
    },
    {
      headerName: "Offer Name",
      field: "title",
      headerTooltip: "Description of the promo",
      pinned: "left",
      sortable: true,
      filter: true,
      width:300
    },
    {
      headerName: "IA Promo ID",
      field: "iaPromoId",
      headerTooltip: "IA Unique identifier for the Promotion",
      // pinned: "left",
      filter: true,
      sortable: true,
    },
    {
      headerName: "Offer ID",
      field: "promoId",
      headerTooltip: "Unique identifier for the Promotion",
      // pinned: "left",
      filter: true,
      sortable: true,
    },
    {
      headerName: "Recommended Media Type",
      field: "offerType",
      headerTooltip:
        "Media Type in which article was promoted ",
      filter: true,
      sortable: true
    },
    {
      headerName: "Actual Media Type",
      field: "actualOfferType",
      headerTooltip:
        "Media Type in which article was recommended",
      filter: true,
      sortable: true
    },
    {
      headerName: "Recommended Promo Type",
      field: "couponType",
      headerTooltip:
        "Recommended Nature of the coupon offered to members(Paper, clipless)",
      filter: true,
      sortable: true
    },
    {
      headerName: "Actual Promo Type",
      field: "actualCouponType",
      headerTooltip:
        "Actual Nature of the coupon offered to members(Paper, clipless)",
      filter: true,
      sortable: true
    },
    {
      headerName: "Book #",
      field: "bookNumber",
      headerTooltip: "Media cycle in which article was promoted",
      filter: true,
      sortable: true
    },
    {
      headerName: "FY",
      field: "fyear",
      headerTooltip: "Fiscal year during which article was promoted",
      filter: true,
      sortable: true
    },
    {
      headerName: "Quarter",
      field: "quarter",
      headerTooltip: "Fiscal quarter during which article was promoted",
      // valueFormatter: numberFormatter,
      filter: true,
      sortable: true
    },
    {
      headerName: "Brand",
      field: "brand",
      headerTooltip: "Brand of the article",
      filter: true,
      sortable: true
    },
    {
      headerName: "Recommended Vendor Funded",
      field: "vendorFunded",
      headerTooltip: "Whether a Promotion is funded by the Vendor or not(Y/N)",
      valueFormatter: convertedBooleanValue,
      // valueGetter: function(params) {
      //   return convertedBooleanValue({value:params.data.vendorFunded})
      //   },
      filter: true,
      sortable: true
    },
    {
      headerName: "Actual Vendor Funded",
      field: "actualVendorFunded",
      headerTooltip: "Whether a Promotion is funded by the Vendor or not(Y/N)",
      valueFormatter: convertedBooleanValue,
      // valueGetter: function(params) {
      //   return convertedBooleanValue({value:params.data.vendorFunded})
      //   },
      filter: true,
      sortable: true
    },
    {
      headerName: "Start Date",
      field: "startDate",
      headerTooltip: "Start Date of the Promotion",
      filter: true,
      sortable: true
    },
    {
      headerName: "End Date",
      field: "endDate",
      headerTooltip: "End Date of the Promotion",
      filter: true,
      sortable: true
    },
    {
      headerName: "Recommended Coupon Value $",
      field: "promoValue",
      headerTooltip: "Recommended Effective discount offered for the article in the promo",
      valueFormatter: (params) => {
        const value = precisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        return '$' + precisionFormatter(params)
      },
      // filter: true,
      filter:'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
      textCustomComparator: function(_, value, filterText) {
        var convertedValue = '$' + precisionFormatter({value:parseFloat(value)});
        if(convertedValue.includes(filterText)){
              return value;
            }
          }
        },
      sortable: true
    },
    {
      headerName: "Actual Coupon Value $",
      field: "actualPromoValue",
      headerTooltip: "Actual Effective discount offered for the article in the promo",
      valueFormatter: (params) => {
        const value = removePrecisionFormatter(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return '$' + precisionFormatter(params)
      },
      // filter: true,
      filter:'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
      textCustomComparator: function(_, value, filterText) {
        var convertedValue = '$' + precisionFormatter({value:parseFloat(value)});
        if(convertedValue.includes(filterText)){
              return value;
            }
          }
        },
      sortable: true
    },
    {
      headerName: "Recommended Validity Days",
      field: "validityDays",
      headerTooltip: "Number of days for which promo was valid",
      // valueFormatter: numberFormatter,
      filter: true,
      sortable: true
    },
    {
      headerName: "Actual Validity Days",
      field: "actualValidityDays",
      headerTooltip: "Number of days for which promo was valid",
      // valueFormatter: numberFormatter,
      filter: true,
      sortable: true
    },
    {
      headerName: "Projected Units",
      field: "simulatedUnits",
      // headerTooltip: "Discount depth(%) offered by promotion (by list price)",
      valueFormatter: (params) => {
        return  convertMillionsFormatterWithoutPrecision(params)
      },
      filter: true,
      sortable: true
    },
    {
      headerName: "Actual Units",
      field: "actualUnits",
      // headerTooltip: "Discount depth(%) offered by promotion (by list price)",
      valueFormatter: (params) => {
        return  convertMillionsFormatterWithoutPrecision(params)
      },
      filter: true,
      sortable: true
    },
    {
      headerName: "Projected Spend",
      field: "simulatedSpend",
      valueFormatter: (params) => {
        const value = convertMillionsFormatterWithoutPrecision(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return  '$' + convertMillionsFormatterWithoutPrecision(params)
      },
      // filter: true,
      filter:'agTextColumnFilter',
      filterParams: {
        filterOptions: ['contains'],
      textCustomComparator: function(_, value, filterText) {
        var convertedValue = '$' + convertMillionsFormatterWithoutPrecision({value:parseFloat(value)});
        if(convertedValue.includes(filterText)){
              return value;
            }
          }
        },
      sortable: true
    },
    {
      headerName: "Actual Spend",
      field: "actualSpend",
      valueFormatter: (params) => {
        return  '$' + convertMillionsFormatterWithoutPrecision(params)
      },
      // filter: true,
      filter:'agTextColumnFilter',
      filterParams: {
        caseSensitive: true,
        filterOptions: ['contains'],
      textCustomComparator: function(_, value, filterText) {
        var convertedValue = '$' + convertMillionsFormatterWithoutPrecision({value:parseFloat(value)});
        if(convertedValue.includes(filterText)){
              return value;
            }
          }
        },
      sortable: true
    },
    {
      headerName: "Projected ROI (Sales)",
      field: "simulatedRoi",
      valueFormatter: (params) => {
        return  precisionFormatter(params)
      },
      filter: true,
      sortable: true
    },
    {
      headerName: "Actual ROI (Sales)",
      field: "actualRoi",
      valueFormatter: (params) => {
        return  precisionFormatter(params)
      },
      filter: true,
      sortable: true
    },
    {
      headerName: "Projected Revenue",
      field: "simulatedSales",
      // headerTooltip: "Discount depth(%) offered by promotion (by list price)",
      valueFormatter: (params) => {
        const value = convertMillionsFormatterWithoutPrecision(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return  '$' + convertMillionsFormatterWithoutPrecision(params)
      },
      // filter: true,
      filter:'agTextColumnFilter',
      filterParams: {
        caseSensitive: true,
        filterOptions: ['contains'],
      textCustomComparator: function(_, value, filterText) {
        var convertedValue = '$' + convertMillionsFormatterWithoutPrecision({value:parseFloat(value)});
        if(convertedValue.includes(filterText)){
              return value;
            }
          }
        },
      sortable: true
    },
    {
      headerName: "Actual Revenue",
      field: "actualSales",
      // headerTooltip: "Discount depth(%) offered by promotion (by list price)",
      valueFormatter: (params) => {
        const value = convertMillionsFormatterWithoutPrecision(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return  '$' + convertMillionsFormatterWithoutPrecision(params)
      },
      // filter: true,
      filter:'agTextColumnFilter',
      filterParams: {
        caseSensitive: true,
        filterOptions: ['contains'],
      textCustomComparator: function(_, value, filterText) {
        var convertedValue = '$' + convertMillionsFormatterWithoutPrecision({value:parseFloat(value)});
        if(convertedValue.includes(filterText)){
              return value;
            }
          }
        },
      sortable: true
    },{
      headerName: "Projected Margin",
      field: "simulatedMargin",
      // headerTooltip: "Discount depth(%) offered by promotion (by list price)",
      valueFormatter: (params) => {
        const value = convertMillionsFormatterWithoutPrecision(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return  '$' + convertMillionsFormatterWithoutPrecision(params)
      },
      // filter: true,
      filter:'agTextColumnFilter',
      filterParams: {
        caseSensitive: true,
        filterOptions: ['contains'],
      textCustomComparator: function(_, value, filterText) {
        var convertedValue = '$' + convertMillionsFormatterWithoutPrecision({value:parseFloat(value)});
        if(convertedValue.includes(filterText)){
              return value;
            }
          }
        },
      sortable: true
    },
    {
      headerName: "Actual Margin",
      field: "actualMargin",
      // headerTooltip: "Discount depth(%) offered by promotion (by list price)",
      valueFormatter: (params) => {
        const value = convertMillionsFormatterWithoutPrecision(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return  '$' + convertMillionsFormatterWithoutPrecision(params)
      },
      // filter: true,
      filter:'agTextColumnFilter',
      filterParams: {
        caseSensitive: true,
        filterOptions: ['contains'],
      textCustomComparator: function(_, value, filterText) {
        var convertedValue = '$' + convertMillionsFormatterWithoutPrecision({value:parseFloat(value)});
        if(convertedValue.includes(filterText)){
              return value;
            }
          }
        },
      sortable: true
    },{
      headerName: "Projected Incremental Units (IU)",
      field: "simulatedIncrementalUnits",
      // headerTooltip: "Discount depth(%) offered by promotion (by list price)",
      valueFormatter: (params) => {
        return  convertMillionsFormatterWithoutPrecision(params)
      },
      filter: true,
      sortable: true
    },
    {
      headerName: "Actual Incremental Units (IU)",
      field: "actualIncrementalUnits",
      // headerTooltip: "Discount depth(%) offered by promotion (by list price)",
      valueFormatter: (params) => {
        return  convertMillionsFormatterWithoutPrecision(params)
      },
      filter: true,
      sortable: true
    },
    {
      headerName: "Projected Incremental Revenue",
      field: "simulatedIncrementalSales",
      // headerTooltip: "Discount depth(%) offered by promotion (by list price)",
      valueFormatter: (params) => {
        const value = convertMillionsFormatterWithoutPrecision(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return  '$' + convertMillionsFormatterWithoutPrecision(params)
      },
      // filter: true,
      filter:'agTextColumnFilter',
      filterParams: {
        caseSensitive: true,
        filterOptions: ['contains'],
      textCustomComparator: function(_, value, filterText) {
        var convertedValue = '$' + convertMillionsFormatterWithoutPrecision({value:parseFloat(value)});
        if(convertedValue.includes(filterText)){
              return value;
            }
          }
        },
      sortable: true
    },
    {
      headerName: "Actual Incremental Revenue",
      field: "actualIncrementalSales",
      // headerTooltip: "Discount depth(%) offered by promotion (by list price)",
      valueFormatter: (params) => {
        const value = convertMillionsFormatterWithoutPrecision(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return  '$' + convertMillionsFormatterWithoutPrecision(params)
      },
      // filter: true,
      filter:'agTextColumnFilter',
      filterParams: {
        caseSensitive: true,
        filterOptions: ['contains'],
      textCustomComparator: function(_, value, filterText) {
        var convertedValue = '$' + convertMillionsFormatterWithoutPrecision({value:parseFloat(value)});
        if(convertedValue.includes(filterText)){
              return value;
            }
          }
        },
      sortable: true
    },
    {
      headerName: "Projected Incremental Margin",
      field: "simulatedIncrementalMargin",
      // headerTooltip: "Discount depth(%) offered by promotion (by list price)",
      valueFormatter: (params) => {
        const value = convertMillionsFormatterWithoutPrecision(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return  '$' + convertMillionsFormatterWithoutPrecision(params)
      },
      // filter: true,
      filter:'agTextColumnFilter',
      filterParams: {
        caseSensitive: true,
        filterOptions: ['contains'],
      textCustomComparator: function(_, value, filterText) {
        var convertedValue = '$' + convertMillionsFormatterWithoutPrecision({value:parseFloat(value)});
        if(convertedValue.includes(filterText)){
              return value;
            }
          }
        },
      sortable: true
    },
    {
      headerName: "Actual Incremental Margin",
      field: "actualIncrementalMargin",
      // headerTooltip: "Discount depth(%) offered by promotion (by list price)",
      valueFormatter: (params) => {
        const value = convertMillionsFormatterWithoutPrecision(params);
        return validateCellValue(value) ? value : ('$' + value);
        // return  '$' + convertMillionsFormatterWithoutPrecision(params)
      },
      // filter: true,
      filter:'agTextColumnFilter',
      filterParams: {
        caseSensitive: true,
        filterOptions: ['contains'],
      textCustomComparator: function(_, value, filterText) {
        var convertedValue = '$' + convertMillionsFormatterWithoutPrecision({value:parseFloat(value)});
        if(convertedValue.includes(filterText)){
              return value;
            }
          }
        },
      sortable: true
    },
]