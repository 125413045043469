import { ToastMessage } from '../common/ToastMessage';
import { callApi } from './config';


export const fetchCycles = (reqObj) => {
  return callApi
    .get(`/constraints/fetchCycles`)
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      ToastMessage(err.response.status,err.response.data.message)
      return err;
    });
};

export const fetchQuarters = (reqObj) => {
  return callApi
    .get(`/datemapping/quaters`, { params: reqObj })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      ToastMessage(err.response.status,err.response.data.message)
      return err;
    });
};

export const fetchConsolidated = (reqObj) => {
  return callApi
    .post(`/dashboard/fetchConsolidated`, reqObj)
    .then((res) => {
      if(res.status){
        return res.data
      }
      return [];
    })
    .catch((err) => {
      ToastMessage(err.response.status,err.response.data.message)
      return err;
    });
};

export const fetchCategoryView = (reqObj) => {
  return callApi
    .post(`/dashboard/fetchCategoryView`, reqObj)
    .then((res) => {
      if(res.status){
        return res.data.data
      }
      return [];
    })
    .catch((err) => {
      ToastMessage(err.response.status,err.response.data.message)
      return err;
    });
};

export const fetchBuyerView = (reqObj) => {
  return callApi
    .post(`/dashboard/fetchBuyerView`, reqObj)
    .then((res) => {
      if(res.status){
        return res.data.data
      }
      return [];
    })
    .catch((err) => {
      ToastMessage(err.response.status,err.response.data.message)
      return err;
    });
};

export const fetchVendorView = (reqObj) => {
  return callApi
    .post(`/dashboard/fetchVendorView`, reqObj)
    .then((res) => {
      if(res.status){
        return res.data.data
      }
      return [];
    })
    .catch((err) => {
      ToastMessage(err.response.status,err.response.data.message)
      return err;
    });
};
