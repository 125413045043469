import React, { Component } from 'react';
import PropTypes from 'prop-types';
import BreadCrumb from '../../BreadCrumb';
import CascadingFilters from '../../CascadingFilters';
import PageLoader from '../../../utils/Loader';
import { Form, Row, Col, Card } from 'react-bootstrap';
import { default as ReactSelect } from 'react-select';
import DynamicFilters from '../../DynamicFilters';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { getRefSkuInfo, createSkuMaster ,isValidSku ,getArticleInfo ,updateSkuMaster, } from '../skuMasterAction';
import _ from 'lodash';
import MultiSelectCheckboxes from '../../MultiSelectCheckboxes';
import EditableDropDown from '../../common/editableDropDownComponent/editableDropDownCompoent';
import { actionGetBrands, actionGetVendors } from '../../../actions/skuMasterAction';
import {NUMBER_REGEX} from "../../../common/app-constants";
import { SKUMasterGA   } from '../../../common/GAUtils';

class AddSku extends Component {
  constructor(props) {
    super(props);

    let stateObj = this.props.location.state;
    let selectedMerchcat = [];
    if (stateObj && stateObj.merchcat) {
      selectedMerchcat = this.getDropdownOptions(stateObj.merchcat[0]);
    }
    this.state = {
      loader: false,
      merchcat: [...selectedMerchcat],
      ah5: [],
      ah6: [],
      vendor: [],
      refSkuOptions: [],
      skuId: '',
      description: null,
      refSku: [],
      isValidSku: false,
      brand: [],
      vendorInputDisabled: false,
      brandInputDisabled: false,
      oldVendor: '',
      oldBrand: '',
      brandError: '',
      skuIdError: '',
      vendorError:''
    };
  }

  getDropdownOptions(value) {
    if (value) {
      return [
        {
          label: value,
          value: value,
        },
      ];
    } else {
      return [];
    }
  }
  componentDidMount() {
    const { id } = this.props.match.params;

    if (id) {
      this.props.getArticleInfo({ sendInactive: true, articleNo: [`${id}`] }).then((res) => {
        let data = res[0];
        const selectedMerchcat = this.getDropdownOptions(data.hierarchyLevel4);
        this.refreshRefSkus({ merchcat: [this.getLabelName(selectedMerchcat)] });
        const reqObj = {
          merchcat: data.hierarchyLevel4
        }
        this.props.actionGetVendors(reqObj);
        this.setState({
          merchcat: selectedMerchcat,
          ah5: this.getDropdownOptions(data.hierarchyLevel5),
          ah6: this.getDropdownOptions(data.hierarchyLevel6),
          vendor: this.getDropdownOptions(data.vendor),
          refSkuOptions: [],
          skuId: id,
          description: data.description,
          refSku: this.getDropdownOptions(data.refSKU),
          brand: this.getDropdownOptions(data.brand),
          oldVendor: data.vendor,
          oldBrand: data.brand
        });
      });
    } else {
      const selectedAH4 = this.props.location.state.merchcat;
      const reqObj = {
        merchcat: [selectedAH4[0]]
      }
      this.props.actionGetVendors(reqObj);
      this.setState({ merchcat: this.getDropdownOptions(selectedAH4[0]) });
      this.refreshRefSkus({ merchcat: selectedAH4 });
    }
  }

  componentDidUpdate(oldProps, prevState) {
    if (oldProps.addUpdateSku != this.props.addUpdateSku) {
      this.redirectBack();
    }
    if (this.state.vendor !== prevState.vendor && !_.isEmpty(this.state.vendor)) {
      const { merchcat, ah5, vendor, ah6 } = this.state;
      const reqObj = {
        merchcat: merchcat.map((val) => {
          return val.value;
        }),
        // ah5: ah5.map(val=> {return val.value}),
        vendor: vendor.map((val) => {
          return val.value;
        }),
        // ah6: ah6.map(val=> {return val.value})
      };
      this.props.actionGetBrands(reqObj);
    }
  }

  openLoader = () => {
    this.setState({ loader: true });
  };
  closeLoader = () => {
    this.setState({ loader: false });
  };
  refreshRefSkus = (params) => {
    const { id } = this.props.match.params;
    const reqObj = {
      filters: {
        sendInactive: true,
        sendSimulated: true,
        merchcat: this.state.merchcat.map((item) => item.value),
      },
      skuId: id,
    };

    // if (merchcat && merchcat.length > 0 && this.state.merchcat !== merchcat) {
    this.props.getRefSkuData(reqObj);
    // }
  };

  updateState = (params) => {
    // if (params.hasOwnProperty('merchcat')) {
    //   this.refreshRefSkus([this.getLabelName(params.merchcat)]);
    // }
    let keysLength = Object.keys(params).length;
    const { id } = this.props.match.params;
    let screenName = id ? "Edit Sku" : 'Add Sku'
        if(params.hasOwnProperty("merchcat") && keysLength ===1){
            this.submitGA('Click',`Changed merchcat selection in ${screenName}`);
        }
        if(params.hasOwnProperty("ah5") && keysLength ===1){
          this.submitGA('Click',`Changed ah5 selection in ${screenName}`);
      }
        if(params.hasOwnProperty("ah6") && keysLength ===1){
            this.submitGA('Click',`Changed ah6 selection in ${screenName}`);
        }
        if(params.hasOwnProperty("vendor") && keysLength ===1 ){
            this.submitGA('Click',`changed vendor selection in ${screenName}`);
            this.setState({brand: []})
        }
        if(params.hasOwnProperty("refSku") && keysLength ===1 ){
          this.submitGA('Click',`changed refSku selection in ${screenName}`);
      }
        
    this.setState(params, () => {
      let key = Object.keys(params)[0];
      if (params[`${key}`].length > 0) {
        this.refreshRefSkus({
          merchcat: this.state.merchcat.map((obj) => obj.value),
          ah5: this.state.ah5.map((obj) => obj.value),
          ah6: this.state.ah6.map((obj) => obj.value),
          vendor: this.state.vendor.map((obj) => obj.value),
        });
      }
    });
  };

  handleVendorChange = (args) => {
    const { key, value } = args;
    const { id } = this.props.match.params;
    let screenName = id ? "Edit Sku" : 'Add Sku'
    this.submitGA('Click',`changed vendor selection in ${screenName}`);
    this.setState({
      brand:[]
    })
    if (typeof value === 'string') {
      value.length > 0
        ? this.setState({
            [key]: [{ label: value, value: value }],
            vendorInputDisabled: true,
            vendorError: '',
          })
        : this.setState({ vendorError: 'Vendor can not be empty' });
    } else {
      if (this.state.vendor !== value) {
        this.setState({ [key]: value });
      }
    }
  };
  handleBrandChange = (args) => {
    const { key, value } = args;
    const { id } = this.props.match.params;
    let screenName = id ? "Edit Sku" : 'Add Sku'
    this.submitGA('Click',`changed brand selection in ${screenName}`);
    if (typeof value === 'string') {
      value.length > 0
        ? this.setState({
            [key]: [{ label: value, value: value }],
            brandInputDisabled: true,
            brandError: '',
          })
        : this.setState({ brandError: 'Brand can not be empty' });
    } else {
      if (this.state.brand !== value) {
        this.setState({ [key]: value });
      }
    }
  };
  handleChange = (event) => {
    let { name, value } = event.target;
    const { POSITIVE_NUMBER } = NUMBER_REGEX;
    // if(name === 'skuId'){
    //   let  isValid = this.props.refSkuData.map(obj=> obj.label ).indexOf(value) ===-1
    //   console.log(isValid)
    //   this.setState({
    //     isValidSku : isValid
    //   })
    // }
    if (name === 'skuId') {
      POSITIVE_NUMBER.test(value)
        ? this.setState({ [name]: value, skuIdError: '' },()=>{
          this.focusLost();
        })
        : this.state.skuId.length > 0
        ? this.setState({ [name]: this.state.skuId })
        : this.setState({ [name]: this.state.skuId, skuIdError: 'Enter a numeric value.' });
        
    } else {
      this.setState({ [name]: value });
    }
  };
  getLabelName = (value) => {
    return _.map(value, 'label')[0];
  };

  createOrUpdateSku = async () => {
    const { merchcat, ah5, ah6, vendor, skuId, description, refSku, brand, oldVendor, oldBrand } = this.state;
    let params = {
      merchcat: this.getLabelName(merchcat),
      ah5: this.getLabelName(ah5),
      ah6: this.getLabelName(ah6),
      vendor: this.getLabelName(vendor),
      skuId,
      description,
      refSKU: this.getLabelName(refSku),
      brand: this.getLabelName(brand),
    };
    const { id } = this.props.match.params;

    if (id) {
      this.submitGA("Click", "Update Sku")
      params.oldVendor = oldVendor;
      params.oldBrand = oldBrand;
      this.props.updateSku(params);
    } else {
      this.submitGA("Click", "Create Sku")
      await this.props.createSku(params);
      // this.props.history.push('/vis/sku-master')
    }
  };

  reset = () => {
    this.setState({
      merchcat: [],
      ah5: [],
      ah6: [],
      vendor: [],
      skuId: '',
      description: '',
      refSku: [],
      brand: [],
    });
  };

  redirectBack = () => {
    this.reset();
    this.props.history.push({
      pathname: '/vis/sku-master',
      state: {
        isRedirected: true,
      },
    });
  };

  isInvalid() {
    const {
      merchcat,
      ah5,
      ah6,
      vendor,
      skuId,
      description,
      brand,
      refSku,
      isValidSku,
      skuIdError
    } = this.state;
    const { id } = this.props.match.params;
    if (id) {
      return merchcat.length > 0 &&
        ah5.length > 0 &&
        ah6.length > 0 &&
        skuId &&
        description &&
        brand.length > 0 && skuIdError === ''
        ? false
        : true;
    }
    return merchcat.length > 0 &&
      ah5.length > 0 &&
      ah6.length > 0 &&
      vendor.length > 0 &&
      refSku.length > 0 &&
      skuId && skuId.length<=10 &&
      description &&
      brand.length > 0 &&
      !isValidSku && skuIdError === ''
      ? false
      : true;
  }
  focusLost = async () => {
    const { id } = this.props.match.params;
    if(this.state.skuId.length > 9){
        this.setState({
          skuIdError:'Sku Id should not be more than 9 digits',
          isValidSku: false
          // skuId:''
        })
        // return
    }
    if (this.state.skuId && id === undefined && this.state.skuId.length <= 9 ) {
      let result = await this.props.isValidSku(this.state.skuId);
      this.setState({
        isValidSku: result,
      });
    }
  };
  handleVendorInputClose = ()=>{
    this.setState({
      vendor:[], inputDisabled:false, vendorError:'', brand:[]
    })
  }
  handleInputClose = () => {
    let temp = this.state.brand;
    temp = [];
    this.setState({ brand: [], inputDisabled: false, brandError: '' });
  };
  submitGA(action,label){
    SKUMasterGA({ action, label});
}
  openAddNewOption =()=>{
    // this.setState({
    //   brand:[]
    // })
    this.submitGA('Click','Add custom brand');
  }
  render() {
    const { id } = this.props.match.params;
    let defaultSelectedOptions = id ? true : false;
    let disabled = id ? true : false;
    let DynamicFiltersProps = [
      {
        type: 'Select',
        placeholder: 'Select AH4',
        state: this.state.merchcat,
        mappingKey: 'merchcat',
        onMenuCloseApi: true,
        grid: 'col-md-4',
        label: 'AH4',
        required: true,
        defaultSelectedOptions,
        disabled: true,
        disabledApiCall: true,
      },
      {
        type: 'Select',
        placeholder: 'Select AH5',
        state: this.state.ah5,
        mappingKey: 'ah5',
        onMenuCloseApi: true,
        grid: 'col-md-4',
        label: 'AH5',
        required: true,
        defaultSelectedOptions,
        disabled,
      },
      {
        type: 'Select',
        placeholder: 'Select AH6',
        state: this.state.ah6,
        mappingKey: 'ah6',
        onMenuCloseApi: false,
        grid: 'col-md-4',
        label: 'AH6',
        required: true,
        defaultSelectedOptions,
        disabled,
      },
    ];

    let DynamicFiltersOptions = {
      merchcatOptions: [],
      ah5Options: [],
      ah6Options: []
    };

    let hierarchyLevel = ['merchcat', 'ah5', 'ah6']
    let hierarchyApiCalls = [
      {
        optionsMappingKey: 'merchcat',
        UrlName: 'merchcat',
      },
    ];
    let hierarchyFilterApi = 'merchcatFilter';
    return (
      <PageLoader loader={this.state.loader || this.props.loading}>
        <div>
          <div className="card CalendarMain promoCalendarDiv1" id="promocalendar-maincontainer">
            <h4 className="promoHeading mb-1 color-blue">
              <BreadCrumb root="SKU Master /" child={id ? 'Edit SKU' : 'Add New SKU'} />
            </h4>
            <hr />
            <div className="spacer"></div>
            <span className=" heading-top description-title">SKU Details</span>
            <div
              className="row "
              style={{
                paddingLeft: '35px',
              }}>
              <div className="col-md-5 ">
                <div className="row">
                  <div className="col-md-3">
                    <Form.Label column="md">
                      <b>
                        <span className="text-danger">*</span> SKU ID
                      </b>
                    </Form.Label>
                  </div>
                  <div className="col-md-6">
                    <Form.Control
                      size="md"
                      type="text"
                      placeholder="Enter SKU ID"
                      name="skuId"
                      value={this.state.skuId}
                      onChange={this.handleChange}
                      // onMouseLeave={this.focusLost}
                      disabled={id ? true : false}
                    />
                    <span className="invalid">
                      {this.state.isValidSku
                        ? 'SKU ID already exists'
                        : this.state.skuIdError.length > 0
                        ? this.state.skuIdError
                        : null}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5">
                <div className="row">
                  <div className="col-md-4">
                    <Form.Label>
                      &nbsp;&nbsp;
                      <b>
                        <span className="text-danger">*</span> SKU Description
                      </b>
                    </Form.Label>
                  </div>
                  <div className="col-md-7">
                    <Form.Control
                      size="md"
                      type="text"
                      name="description"
                      value={this.state.description}
                      onChange={this.handleChange}
                      placeholder="Enter description about SKU ID"
                      disabled={id ? true : false}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="spacer"></div>
            <div className="row row-description-title">
              <DynamicFilters
                filtersList={DynamicFiltersProps}
                options={DynamicFiltersOptions}
                hierarchyLevel={hierarchyLevel}
                hierarchyApiCalls={hierarchyApiCalls}
                hierarchyFilterApi={hierarchyFilterApi}
                openLoader={this.openLoader}
                closeLoader={this.closeLoader}
                updateParentState={this.updateState}
                filterData={this.handleFilterAction}
                onReset={this.onReset}
                viewType="verticle"
                isFilterButtonRequired={false}
                isDefaultSelectedAll={false}
                disabledApiCallOnCascade={id ? true : false}
                screenName="Add/Edit Sku"
              />
            </div>
            <div className="col-md-10 row  row-description-title">
              <div className="col-md-4 vendor-dropdown">
                <EditableDropDown
                  mappingKey="vendor"
                  selectedData={this.state.vendor}
                  handleSelectionChange={this.handleVendorChange}
                  handleInputClose={this.handleVendorInputClose}
                  type="select"
                  placeholder="Select Vendor"
                  options={this.props.vendorOption}
                  dropDownLabel="Vendor"
                  inputLabel="Vendor"
                  inputPlaceholder="Enter Vendor"
                  isInputDisabled={this.state.vendorInputDisabled}
                  viewType="verticle"
                  required={true}
                  openAddNewOption={this.openAddNewOption}
                  title="Add new Vendor"
                  editUser={false}
                />
                <span className="invalid">
                  {this.state.vendorError.length > 0 ? this.state.vendorError : null}
                </span>
              </div>
              <div className="col-md-4 brand-dropdown" style={{marginLeft:'19px'}}>
                <EditableDropDown
                  mappingKey="brand"
                  selectedData={this.state.brand}
                  handleSelectionChange={this.handleBrandChange}
                  handleInputClose={this.handleInputClose}
                  type="select"
                  placeholder="Select Brand"
                  options={this.props.brandOption}
                  dropDownLabel="Brand"
                  inputLabel="Brand"
                  inputPlaceholder="Enter Brand"
                  isInputDisabled={this.state.brandInputDisabled}
                  viewType="verticle"
                  required={true}
                  openAddNewOption={this.openAddNewOption}
                  title="Add new Brand"
                  editUser={false}
                />
                <span className="invalid">
                  {this.state.brandError.length > 0 ? this.state.brandError : null}
                </span>
              </div>
            </div>
            <div className="spacer"></div>
            <span className=" heading-top description-title">SKU Mapping</span>
            <div className="spacer"></div>
            <div
              className="row "
              style={{
                paddingLeft: '40px',
              }}>
              <div className="col-md-2">
                <Form.Label column="md">
                  <b>
                    {!id && <span className="text-danger">*</span>}
                    Reference SKU &nbsp;
                    {/* <Form.Check
                    inline
                    label='Reference SKU '
                    type='radio'
                    id={`inline-radio-2`}
                  /> */}
                  </b>
                </Form.Label>
              </div>
              <div className="col-md-4">
                <MultiSelectCheckboxes
                  placeholderButtonLabel="Select SKU"
                  mappingKey="refSku"
                  options={this.props.refSkuData}
                  value={this.state.refSku}
                  updateState={this.updateState}
                  isMulti={false}
                />
              </div>
            </div>
            <hr
              style={{
                marginTop: '30px',
              }}
            />
            <div
              className="row sku-action-buttons-wrapper"
              style={{
                marginTop: '30px',
              }}>
              {/* <div className='col-md-4'></div> */}
              <div className="sku-action-button">
                <button
                  disabled={this.isInvalid()}
                  className="btn btn-primary filterBtn"
                  onClick={() => this.createOrUpdateSku()}>
                  {id ? 'Update' : 'Create'}
                </button>
              </div>
              <div className="sku-action-button">
                <Link
                  to={{
                    pathname: '/vis/sku-master',
                    state: {
                      isRedirected: true,
                    },
                  }}>
                  <button className="btn btn-primary resetButton" onClick={()=>{ this.submitGA("Click", "Cancel add sku")}}>
                    <span>&nbsp; Cancel</span>
                  </button>
                </Link>
              </div>
            </div>
            <div className="spacer" style={{ marginBottom: '70px' }}></div>
          </div>
        </div>
      </PageLoader>
    );
  }
}

const mapStateToProps = state => {
  return {
    refSkuData: state.skuMasterData.refSkuInfo,
    brandOption: state.skuMasterData.brandData,
    vendorOption : state.skuMasterData.vendorData,
    loading: state.skuMasterData.loading,
    addUpdateSku: state.skuMasterData.addUpdateSku
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getRefSkuData: val => dispatch(getRefSkuInfo(val)),
    createSku: val => dispatch(createSkuMaster(val)),
    updateSku: val => dispatch(updateSkuMaster(val)),
    isValidSku: val => dispatch(isValidSku(val)),
    getArticleInfo : val => dispatch(getArticleInfo(val)),
    actionGetBrands: val => dispatch(actionGetBrands(val)),
    actionGetVendors : val => dispatch(actionGetVendors(val))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AddSku);
