import React, { useEffect, useState } from 'react';

const Footer = (props) => {

  return (
    <>
      {props.mode === 'edit' ? 
     
        <>
          <button
            onClick={() => props.handleEditGroup(true)}
            className="btn btn-primary saveButton"
            disabled={props.error.length > 0}>
            Save Changes
          </button>
          <button
            onClick={() => props.handleDelete(true)}
            className="actionButton btn btn-outline-primary cal-btn1"
            // disabled={props.planName.trim().length === 0}
          >
            Delete Group
          </button>
        </>
     : props.mode === 'create' ?
      
        <button
          onClick={() => props.createGroup()}
          className="btn btn-primary saveButton"
          disabled={props.error.length > 0 || props.groupName.length === 0 || props.groupData.length === 0 || props.isGroupExist}>
          Create
        </button>
      : null
    }
    { props.mode !== 'view' ? 
      <button onClick={() => props.handleCancel()} className="actionButton btn btn-primary resetButton">
        Cancel
      </button>
      : null
}
    </>
  );
};

export default Footer;
