import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";

const DataTable = props => {
  const onGridReady = params => {
    props.isSizetoFit && params.api.sizeColumnsToFit();
  };
  return (
    <div className="ag-theme-balham" style={props.style}>
      <AgGridReact onGridReady={onGridReady} {...props} enableCellTextSelection={true}/>
    </div>
  );
};

export default DataTable;
