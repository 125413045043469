import React, { Fragment, useState, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import TableWrapper from "../common/TableWrapper";
import { FooterBtnDiv } from "../../components/BudgetPlanning/Components/ModifySku/styled";
import { Modal, Button } from "react-bootstrap";
// import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import PageLoader from "../../utils/Loader";
import CascadingFilters from "../CascadingFilters";
import EditBudget from "../../common/EditBudgetComponent/index";
import  MultiSelectCheckboxes  from "../MultiSelectCheckboxes";
import { getDisStateFrmStatus } from "../../utils/helperFunctions"
import { CreatePromoGA } from '../../common/GAUtils';
const modalHeaderStyle = {
  display: "flex",
  padding: "18px 21px 7px 16px",
  justifyContent: "space-between"
};
const SkuHeadStyle = {
  fontSize: "16px",
  fontWeight: "600"
};
const deleteRow = props => {
  return (
    <i
      class="fa fa-times-circle-o"
      aria-hidden="true"
      onClick={() =>
        props.handleRowDeletion(props.data, props.agGridReact.props.rowData)
      }
    ></i>
  );
};

const AddSku = props => {
  const [modalShow, setModalShow] = useState(false);
  const [loader, setLoader] = useState(false);
  const [selectedRows, setSelectedRows] = useState();
  const [category, setCategory] = useState([]);
  const [vendor, setVendor] = useState([]);
  const [AH6, setAH6] = useState([]);
  const [modalRowData, setModalRowData] = useState([]);
  const [metchcatOptions, setMetchcatOptions] = useState(props.merchcatValues);
  
  const [modal, setModal] = useState(false);

  useEffect(() => {
    setSelectedRows(props.articleNumbers);
  }, [props.articleNumbers])

  const modalColDefs = [
    {
      headerName: "",
      field: "checkbox",
      headerCheckboxSelection: false,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true
    },
    {
      headerName: "SKU ID",
      field: "hierarchyLevel7",
      filter: true,
      sortable: true
    },
    {
      headerName: "Description",
      field: "description",
      filter: true,
      sortable: true
    }
  ];

  const handleRowDeletion = (data, rowData) => {
    submitGA("Click","delete sku from selected skus list");
    let tempRowData = rowData.filter(sku => {
      return sku.hierarchyLevel7 !== data.hierarchyLevel7;
    });

    // setSkuRowData(tempRowData);
    props.updateSkuArticlNumbers(tempRowData);
  };
  const colDefs = [
    {
      headerName: "SKU",
      field: "hierarchyLevel7"
    },
    {
      headerName: "AH4",
      field: "hierarchyLevel4"
    },
    {
      headerName: "AH6",
      field: "hierarchyLevel6"
    },
    {
      headerName: "Vendor",
      field: "vendor"
    },
    {
      headerName: "Regular Price",
      field: "regularPrice",
      cellRenderer: "editBudget",
      cellRendererParams: {
        isDisabled: true
      }
    },
    {
      headerName: "Cost",
      field: "cost",
      cellRenderer: "editBudget",
      cellRendererParams: {
        isDisabled: true
      }
    },
    {
      headerName: "",
      field: "delete",
      cellRenderer: "deleterow",
      cellRendererParams: {
        handleRowDeletion
      }
    }
  ];

  useEffect(() => {
    formatRowData(props.addSkuProps.skuData);
  }, [props.addSkuProps.skuData]);

  
  useEffect(() => {

    setMetchcatOptions(props.merchcatValues)
  }, [props.merchcatValues]);
  

  function formatRowData(skuData) {
    if (skuData.length > 0) {
      setLoader(false);
      const tempRowData = skuData.map(data => {
        return {
          hierarchyLevel7: data.hierarchyLevel7,
          description: data.description
        };
      });
      setModalRowData(tempRowData);
    }
  }

  const onSelectionChanged = evt => {
    submitGA("Click","selecting sku");
    var selectedRows = evt.api.getSelectedRows();
    setSelectedRows(selectedRows);
  };
  function onGridReady(params) {
    let allColumnIds = [];
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridColumnApi.getAllColumns().forEach(column => {
      allColumnIds.push(column.colId);
    });
    if (selectedRows.length > 0) {
      const selecteId = selectedRows.map(row => {
        return row.hierarchyLevel7;
      });
      this.gridApi.forEachNode(function(node) {
        if (selecteId.includes(`${node.data.hierarchyLevel7}`)) {
          node.setSelected(true);
        }
      });
    }
    this.gridColumnApi.autoSizeColumns(allColumnIds, true);
    this.gridApi.sizeColumnsToFit();
  }
  const filterData = filterSelectedOptions => {
    // setCategory(filterSelectedOptions.merchcat);
    // setVendor(filterSelectedOptions.vendor);
    // setAH6(filterSelectedOptions.ah6);
    setLoader(true);
    const payload = {
      merchcat: filterSelectedOptions.merchcat,
      vendor: filterSelectedOptions.vendor,
      ah6: filterSelectedOptions.ah6,
      screenName: props.screenName,
    };
    props.addSkuProps.actionGetSku(payload);
  };

  const openLoader = () => {
    setLoader(true);
  };
  const closeLoader = () => {
    setLoader(false);
  };
  const onReset = () => {
    setModalRowData([]);
  };

  const handleAddSku = () => {
    const selectedSkuId = selectedRows.map(data => data.hierarchyLevel7);
    const data = props.addSkuProps.skuData.filter(sku => {
      return selectedSkuId.includes(sku.hierarchyLevel7);
    });
    props.updateSkuArticlNumbers(data);
    // setSkuRowData(data);
    setModalShow(false);
    setModalRowData([]);
    toggle();
  };
  const defaultColDef = {
    resizable: true
  };

  const toggle = () => {
    if(modal){
      submitGA("Click","Close add sku modal")
    }
    setSelectedRows(props.articleNumbers);
    setModal(!modal);
  }

  const updateState = params => {
    props.handleSelectedMerchcat(params)
    setModalRowData([]);
    props.updateSkuArticlNumbers([]);
  }

  const submitGA =(action,label) =>{
    CreatePromoGA({ action, label});
  }

  return (
    <>
      <div>
      <Modal show={modal} size="lg" onHide={toggle}>
        <Modal.Header closeButton>
          <Modal.Title>Select SKU </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <PageLoader loader={loader || props.loader}>
              <div className="row" >
                <CascadingFilters
                  openLoader={openLoader}
                  closeLoader={closeLoader}
                  filterData={filterData}
                  onReset={onReset}
                  screenName={props.screenName}
                  hierarchyLevel={['merchcat', 'vendor', 'ah6']}
                  isFilterButtonRequired={false}
                  hierarchyView={true}
                  disabledApiCall={true}
                  selectedMerchcat={props.selectedMerchcat}
                />
              </div>

              {modalRowData.length > 0 && (
                <div className="row-selection">
                  <div
                    id="myGridForPipeline"
                    className="ag-theme-balham"
                    style={{ width: "100%" }}
                  >
                    <AgGridReact
                      onGridReady={onGridReady}
                      columnDefs={modalColDefs}
                      rowData={modalRowData}
                      onSelectionChanged={onSelectionChanged}
                      rowSelection="multiple"
                      defaultColDef={defaultColDef}
                      suppressRowClickSelection={true}
                      skipHeaderOnAutoSize={true}
                      floatingFilter={true}
                      enableCellTextSelection={true}
                    />
                  </div>
                </div>
              )}
            </PageLoader>
        </Modal.Body>
        <Modal.Footer>
        <button
        className="btn btn-primary saveButton my-2 text-center"
        onClick={() => handleAddSku()}
        disabled={modalRowData.length === 0 || selectedRows.length == 0}>
        ADD SKUs
      </button>
        </Modal.Footer>
      </Modal>
        {/* <Modal style={{ top: '20%' }} isOpen={modal} toggle={toggle} size="lg">
          <div style={modalHeaderStyle}>
            <p style={SkuHeadStyle}>Select SKU</p>
            <i className="fa fa-times fa-1x" onClick={() => toggle()}></i>
          </div>
          <ModalBody>
            <PageLoader loader={loader || props.loader}>
              <div className="row" style={{ marginTop: '-30px', fontWeight: '300' }}>
                <CascadingFilters
                  openLoader={openLoader}
                  closeLoader={closeLoader}
                  filterData={filterData}
                  onReset={onReset}
                  screenName={props.screenName}
                  hierarchyLevel={['merchcat', 'vendor', 'ah6']}
                  isFilterButtonRequired={false}
                  hierarchyView={true}
                  disabledApiCall={true}
                  selectedMerchcat={props.selectedMerchcat}
                />
              </div>

              {modalRowData.length > 0 && (
                <div className="row-selection">
                  <div
                    id="myGridForPipeline"
                    className="ag-theme-balham"
                    style={{ width: "100%" }}
                  >
                    <AgGridReact
                      onGridReady={onGridReady}
                      columnDefs={modalColDefs}
                      rowData={modalRowData}
                      onSelectionChanged={onSelectionChanged}
                      rowSelection="multiple"
                      defaultColDef={defaultColDef}
                      suppressRowClickSelection={true}
                      skipHeaderOnAutoSize={true}
                      floatingFilter={true}
                      enableCellTextSelection={true}
                    />
                  </div>
                </div>
              )}
            </PageLoader>
          </ModalBody>
          <ModalFooter>
            <button
              className="btn btn-primary saveButton my-2 text-center"
              onClick={() => handleAddSku()}
              disabled={modalRowData.length === 0 || selectedRows.length == 0}>
              ADD SKUs
            </button>{' '}
          </ModalFooter>
        </Modal> */}
        <div className="row">
          <div className="col-md-3">
            <label style={{ fontWeight: 'bold', fontSize: '14px' }}>Category</label>
          </div>
        </div>
        <div className="row">
          <div className="col-md-3">
            <MultiSelectCheckboxes
              placeholderButtonLabel="Select Category"
              options={metchcatOptions}
              value={props.selectedMerchcat}
              mappingKey="selectedMerchcat"
              updateState={updateState}
              isMulti={false}
              disabled={getDisStateFrmStatus(props.promoStatus)}
            />
          </div>
          <div className="col-md-8"></div>
          <div className="col-md-1 " style={{marginLeft:"-20px"}}>
            <button
              className="btn btn-primary filterBtn "
              disabled={props.selectedMerchcat.length === 0 || getDisStateFrmStatus(props.promoStatus)}
              onClick={() => {
                submitGA("Click","Click on Add Sku");
                setModalRowData([]);
                // setModalShow(true);
                toggle();
              }}>
              <span className="fa fa-plus"></span> Add SKU
            </button>
          </div>
        </div>
        <div className="spacer"></div>
        <div className={props.articleNumbers.length === 1 ? 'remove-ag-grid-bottom-space' : ''}>
        <div className="ag-theme-balham" style={{ width: "100%" }}>
          <AgGridReact
            defaultColDef={{
              resizable: true,
            }}
            columnDefs={colDefs}
            // rowData={skuRowData}
            rowData={props.articleNumbers}
            onGridReady={(params) => {
              params.api.sizeColumnsToFit();
            }}
            domLayout="autoHeight"
            frameworkComponents={{
              editBudget: EditBudget,
              deleterow: deleteRow,
            }}
            enableCellTextSelection={true}
          />
        </div>
      </div>
        </div>
    </>
  );
};

export default AddSku;
