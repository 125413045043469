import { callApi } from "./config";
import { MESSAGES } from "../common/app-constants";
import { ToastMessage } from "../common/ToastMessage";

export const fetchBudgetMasterTableData = reqObj => {
  return callApi
    .post(`vendorbudget/quarterly/list`, reqObj)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return err;
    });
};

export const saveBudgetMasterTableData = reqObj => {
  const { SUCCESS_MESSAGE, FAILURE_MESSAGE } = MESSAGES;

  return callApi
    .post(`vendorbudget/quarterly/update`, reqObj)
    .then(res => {
      if (res.status === 200)
      ToastMessage(res.status,SUCCESS_MESSAGE)
      else {
        ToastMessage(res.status, FAILURE_MESSAGE)
      }
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const downloadBudgetMasterData = reqObj => {
  return callApi
    .post("/vendorbudget/downloadBudgetData", reqObj)
    .then(res => {
      ToastMessage(res.status, MESSAGES.DOWNLOAD_SUCCESS_MESSAGE)
      return res.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      throw err;
    });
}
