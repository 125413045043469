import React from "react";
import styled from "styled-components";

const TableWrapper = props => {
  return (
    <TableWrapperMainDiv
      className="ag-theme-balham"
      style={{ height: props.height }}
    >
      {props.children}
    </TableWrapperMainDiv>
  );
};

const TableWrapperMainDiv = styled.div`
  padding: 15px 30px;
  background: white;
  border-radius: 4px;
`;
export default TableWrapper;
