import React, { Component } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import "./index.css";
import "../../assets/promotionstyling.css";
import "../../assets/promocalendarstyling.css";
import "react-toggle/style.css";
import {
  getBudgetData,
  getBudgetCalendarData,
  getBudgetGraphData,
  postBudgetEdit,
  actionDownloadBudgetPlanning,
  fetchMerchCat,
  fetchVendorList,
  actionDownloadPromoTracker,
  actionDownloadPlanSummary,
} from "../../actions/budgetPlanningAction";
import {actionSimulationPlan, actionCopyPlan} from "./budgetPlanAction";

import {
  postBudgetPlanUpsert,
  actionYearQuaters,
  getVendorHistoryTable,
  setSkuData,
  actionResetBudgetPlanIdData,
  resetVendorHistoryTable,
  resetOptimizationLink,
  resetBudgetPlanHeaders,
  retryPlanArticleCampaigns,
  actionResetPlanError,
  actionGetAh6Data,
  actionUpdateVendorHistory,
  actoinUpdateInactiveMetrics,
  actionUpdateBrandBudget,
  actionBudgetPlanCampaignList
} from "./budgetPlanAction";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import moment from "moment";
import PageLoader from "../../utils/Loader";
import _, { cloneDeep, remove } from "lodash";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  setTotalCount,
  actionFetchSpecificPlan,
  actionSaveOrSubmitPlan,
  actionResetPlanStatus
} from "./budgetPlanAction";

//main page wrapper
import PageWrapper from "../common/PageWrapper";

//child components
import BreadCrumb from "../BreadCrumb";
import Filter from "./Components/Filter/index.js";
import BudgetPlanGraph from "./Components/BudgetPlanGraph/index.js";
import BudgetPlanTable from "./Components/BudgetPlanTable/index.js";
import BudgetMetricsTable from "./Components/BudgetMetricsTable";
import FooterControls from "./Components/FooterControls";

//constants from common contants file
import { budgetDataPayload, vendorOptions } from "./constants";
import CalenderBox from "./Components/CalenderBox/index.js"; // to be
import VendorPlanHistoryTable from "./Components/VendorPlanHistoryTable";

import axios from "axios";
import UserInputNodal from "./Components/UserInputModal";
import {
  fetchMerchCatValues,
  fetchCategorySpecificVendors,
  fetchCampaignList,
  fetchSpecificPlan
} from "../../apis/common.api.";

import queryString from "query-string";
import { BudgetPlanningGA } from '../../common/GAUtils';
import RedirectOptimizedPlan from "./RedirectOptimizedPlan";
import Constraints from "../Constraints/index";
import BudgetInactiveArticleTable from "./Components/BudgetInactiveArticleTable";
import { ToastMessage } from "../../common/ToastMessage";
import CopyPlan from "./Components/CopyPlanModal";


let planArticleInterval = undefined;
class BudgePlanning extends Component {
  budgetData = [];

  constructor(props) {
    super(props);
    this.state = {
      merchcats: [],
      selectedMerchcats: [],
      vendors: [],
      selectedVendors: [],
      isBudgetOptimize: false,
      selectedYearVal: {},
      promoType: "BBM",
      selectedQuarters: [],
      bookNumbers: [],
      selectedBookNumbers: [],
      previousBudget: this.props.budgetGraphData.totlAllo,
      defaultColDef: {
        // editable: true,
        resizable: true
      },
      quarters: [],
      years: [],
      modifySkuPopupData: [],
      isFinalizeBudget: false,
      showMetricsTable: true,
      isShow: false,
      isDisableOptimise: false,
      planName: "",
      isExistingPlan: false,
      dselectedSKU: [],
      planId: 0,
      updatedAvailableBudget: [],
      isShowAlert: false,
      updatedDiscounts: [],
      redirectToBudgetOptimationPlan:false,
      redirectToConstraints: false,
      planNum: '',
      isBrowserNavigation: false,
      redirectFrom:'',
      BudgetPlanPayload: {},
      vendorBrandHistoryTable: [],
      optimizationLevel: {},
      showModal:false
    };
  }

  // update state handler
  updateState = obj => {
      let keysLength = Object.keys(obj).length;
        if(obj.hasOwnProperty("selectedVendors") && keysLength ===1){
            this.submitGA('Filter','Changed vendor selection');
        }
        if(obj.hasOwnProperty("selectedQuarters") && keysLength ===1){
            this.submitGA('Filter','Changed quarter selection');
        }
    this.setState({
      ...this.state,
      ...obj
    });
  };
  getPlanId = () => {
    const params = queryString.parse(this.props.location.search);
    return params.planId;
  };

  getQuaters = fYear => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth()+1;
    const quaters = [];
    this.props.yearQuaters.forEach(({ endDate, year, quater, monthNumbers, startDate }) => {
      const dStartDate = new Date(startDate);
      if (year == fYear) {
      // Commented below lines to show current quater in filters . need to uncomment if current quarter to be removed from filters.
      // if (year == fYear && dStartDate > currentDate) {
      //   if(fYear == currentYear+1){
      //     if(monthNumbers.some(month => month >= currentMonth)){
      //       quaters.push({
      //         label: `Q${quater}`,
      //         value: `Q${quater}`
      //       });
      //     }
      //   } else {
        quaters.push({
          label: `Q${quater}`,
          value: `Q${quater}`
        });
      // }
    // }
    }
    });
    return quaters;
  };

  getYears = yearQuaters => {
    const currentDate = new Date();
    const years = [];
    this.props.yearQuaters.forEach(({ endDate, year }) => {
      const dEndDate = new Date(endDate);
      if (dEndDate > currentDate) {
        if (!years.includes(year)) years.push(year);
      }
    });
    return years.map(year => ({ value: year, label: year }));
  };
  // get merchcat specific vendors handler
  getMerchcatSpecificVendors = async isDisplayExistingPlan => {
    // category === merchcat
    // const selectedMerchcats = [this.state.selectedMerchcats.value];
    
    if (this.state.selectedMerchcats.length > 0 ) {
      const data = await this.props.fetchCategorySpecificVendors({
        merchcat: [this.state.selectedMerchcats[0].value]
      });
      if (data) {
        const formattedData = data.map(val => ({
          label: val,
          value: val
        }));
        this.setState({
          vendors: [...formattedData]
        });
        if (!isDisplayExistingPlan) {
          this.setState({
            selectedVendors: [...formattedData]
          },()=>{
          });
        }
      }
    }
  };

  // get promo books data handler
  getBookData = async () => {
    const { selectedQuarters, promoType, selectedYearVal } = this.state;
    const quarters = selectedQuarters.map(obj => obj.value.substring(1, 2));
    const data = await fetchCampaignList({
      quarter: quarters,
      name: promoType,
      fyear: [selectedYearVal.value]
    });
    if (data) {
      let result = data.map(({ name = "" }) => {
        return {
          value: parseInt(name.split("-")[1]),
          label: name.split("-")[1]
        };
      });
      this.setState({
        ...this.state,
        bookNumbers: _.sortBy(result, obj => obj.value),
        selectedBookNumbers: _.sortBy(result, obj => obj.value)
      });
    }
  };

  //set quarter handler
  setQuarter = value => {
    this.setState({
      ...this.state,
      selectedQuarter: value
    });
  };

  // set promo type handler
  setPromoType = value => {
    this.setState({
      ...this.state,
      promoType: value,
      selectedBookNumbers: []
    });
  };

  setIsDisableOptimise = value => {
    this.setState({
      isDisableOptimise: value
    });
  };

  componentWillUnmount() {
    this.props.setSkuData({
      data: [],
      deselectedSku: [],
      totalCount: 0
    });
    this.props.actionResetPlanStatus({status:''});
    this.props.resetOptimizationLink();
    this.props.resetBudgetPlanHeaders();
    clearInterval(planArticleInterval);
  }

  componentDidUpdate = async(oldProps, oldState) => {
    
     let prevPlanId = queryString.parse(oldProps.location.search);
    let currentPlanId = queryString.parse(this.props.location.search);
    if(prevPlanId.planId){
    if(prevPlanId.planId.includes("?constraints") && this.state.redirectFrom !== 'constraints'){
      this.setState({
        redirectFrom : `constraints`
      })
    }}


    if (oldState.selectedYearVal.value != this.state.selectedYearVal.value  ||
        oldProps.plannedArticleData.data != this.props.plannedArticleData.data) {
       this.props.actionBudgetPlanCampaignList({
        selectedYearVal: this.state.selectedYearVal
      })
    }

    if((_.isEmpty(currentPlanId) && !_.isEmpty(prevPlanId) && prevPlanId!== currentPlanId) || this.props.planError.length>0){
      if(Object.keys(prevPlanId).indexOf("constraints") === -1){
        this.props.resetBudgetPlanIdData();
      }
      const data = await this.props.fetchMerchCatValues();
      let formattedData =[];
    if (data && data.length) {
      formattedData = data.map(val => ({
        label: val,
        value: val
      }));
    }
     this.props.actionResetPlanError("");
     if(_.isEmpty(currentPlanId) && !_.isEmpty(prevPlanId) && Object.keys(prevPlanId).indexOf("constraints") == -1){
      this.setState({
        merchcats:formattedData,
        selectedMerchcats: [],
        vendors: [],
        selectedVendors: [],
        // selectedYearVal: {},
        selectedQuarters: [],
        isExistingPlan:false,
        isFinalizeBudget:false,
        isBudgetOptimize:false,
        showMetricsTable:true,
        planId: 0,
        planName:""
      })
    } 
  }

    if (oldProps.planData !== this.props.planData) {
      const data = this.props.planData;
      if (data && Array.isArray(data) && data.length > 0) {
        const [planData] = data;
        this.setState({
          planName: planData.planName
        });
        const reqObj = {
          filters: {
            vendor: planData.vendors,
            category: planData.categories,
            fYear: [planData.fyear],
            quarter: planData.quarters
          },
          planId: this.getPlanId()
        };
         this.props.getVendorHistoryTable(reqObj);
        this.formatPlanSpecificData(planData);
      }
    }

    if (this.props.yearQuaters != oldProps.yearQuaters) {
      const years = this.getYears();
      if (years.length > 0) {
        const quarters = this.getQuaters(years[0].value);
        this.setState({
          quarters,
          years,
          selectedYearVal: years[0]
        });
      }
    }

  if(this.props.optimizationLink !== oldProps.optimizationLink ){
    
    planArticleInterval =  setInterval(async() => {
      let planId = parseInt(this.props.optimizationLink.split("=")[1])
      const planArticleData  = await this.props.retryPlanArticleCampaigns(planId)
      if(planArticleData.length > 0){
        this.setState({
          redirectToBudgetOptimationPlan:true
        })
        clearInterval(planArticleInterval);
      }
    }, 10000);
  }

  if(this.props.vendorHistoryData !== oldProps.vendorHistoryData){
    this.setState({vendorBrandHistoryTable: this.props.vendorHistoryData});
  }
  
  if(this.props.brandHistoryData !== oldProps.brandHistoryData){
    let vendorBrandLevel = [];
    if(Object.keys(currentPlanId).length>0){
    let tempData = this.props.vendorHistoryData.map(ele => {
      if(ele.isvendorlevel === false){
        let brandData = this.props.brandHistoryData;
        vendorBrandLevel.push(ele);
        brandData.forEach(brandEle => {
          if(ele.vendor === brandEle.vendor) {
            vendorBrandLevel.push(brandEle);
          }
        })
            } else {
              vendorBrandLevel.push(ele);
      }
    })
    this.setState({vendorBrandHistoryTable: vendorBrandLevel});}
  } 
  };

  componentWillMount() {
    let isRedirect = true;
    window.onpopstate = (event) => {
      const data = event.state;
      if (event.state.isBrowserNavigation) {
        isRedirect = false;
    this.props.actionYearQuaters();
        this.setState({ 
          isBrowserNavigation: false,
          redirectToConstraints: false,
          selectedMerchcats: data.merchcat,
          selectedVendors: data.vendor,
          selectedYearVal: data.year,
          selectedQuarters: data.quarter,
          promoType: data.offerType,
          isFinalizeBudget: true,
          dselectedSKU: data.dselectedSKU
         });
        
        this.props.actionUpdateVendorHistory(data.vendorHistoryData);
        window.history.pushState({isBrowserNavigation: false},'')
      }else{
            this.props.resetVendorHistoryTable();
          }

        };
    setTimeout(() => {
      if(isRedirect){
        this.props.resetVendorHistoryTable();
      }  
    }, 10);
    
  }

  async componentDidMount() {
    this.props.resetVendorHistoryTable();
    //fetching category dropdown data
    await this.props.actionYearQuaters();
    if (this.getPlanId()) {
      this.setIsDisableOptimise(true);
    }
    // const data = await fetchMerchCatValues();
    const data = await this.props.fetchMerchCatValues();
    if (data && data.length) {
      const formattedData = data.map(val => ({
        label: val,
        value: val
      }));
      this.setState({
        ...this.state,
        merchcats: formattedData
      });
    }

    const specificPlanId = this.getPlanId();
    if (specificPlanId) {
      this.setState({
        isExistingPlan: true,
        planId: specificPlanId,
        isBudgetOptimize: true
      });
      await this.props.actionFetchSpecificPlan(specificPlanId);
    }
  }

  formatPlanSpecificData = async planData => {
    const selectedYearVal = { value: planData.fyear, label: planData.fyear };
    const selectedMerchcats = [{
      value: planData.categories[0],
      label: planData.categories[0]
    }];
    const selectedQuarters = planData.quarters.map(key => {
      return { value: `Q${key}`, label: `Q${key}` };
    });
    const selectedBookNumbers = planData.books.map(key => {
      return { value: key, label: `${key}` };
    });
    const selectedVendors = planData.vendors.map(key => {
      return { value: key, label: `${key}` };
    });
    this.setYearSelected(selectedYearVal);
    this.setState(
      {
        ...this.state,
        selectedYearVal,
        selectedMerchcats,
        selectedQuarters,
        selectedBookNumbers,
        selectedVendors,
        isFinalizeBudget: true,
        planName: planData.planName,
        dselectedSKU: planData.dselectedSKU
      },
      () => {
        this.getMerchcatSpecificVendors(true);
      }
    );
  };

  handleFirstDataRendered = event => {
    this.props.sizeToFit
      ? event.api.sizeColumnsToFit()
      : event.columnApi.autoSizeAllColumns();
  };
  toggleMonthQuarter = val => {
    if (this.state.selectedView != val) {
      this.setState({ selectedView: val });
    }
  };

  TYLYToggleHandler = () => {
    this.setState({ toggleLYSelected: !this.state.toggleLYSelected });
  };

  fetchFreshData = payload => {
    const {
      getBudgetData,
      getBudgetCalendarData,
      getBudgetGraphData
    } = this.props;
    getBudgetData(payload);
    getBudgetCalendarData(payload);
    getBudgetGraphData(payload);
  };

  vendorSelected = selectedVendorVal => {
    this.setState({
      selectedVendorVal
    });
  };
  categorySelected = selectedCategoryVal => {
    const { value } = selectedCategoryVal;
    const payload = {
      category: value
    };
    this.setState({ selectedCategoryVal }, this.fetchFreshData(payload));
  };

  setYearSelected = selectedYearVal => {
    this.submitGA("Filter","Changed Fiscal year selection");
    const {planData} = this.props;
    const quarters = this.getQuaters(selectedYearVal.value);
    if(planData.length>0 && selectedYearVal.value === planData[0].fyear) {
      const selectedQuarters = planData[0].quarters.map(key => {
        return { value: `Q${key}`, label: `Q${key}` };
      });
      this.setState({selectedQuarters})
    }
    else {
      this.setState({selectedQuarters:[]})
    }
    this.setState({
      selectedYearVal,
      quarters,
    });
  };

  setSelectedCategory = value => {
    this.submitGA("Filter","Changed merchcat selection");
    this.setState(
      {
        selectedMerchcats: value,
        // selectedQuarters: [...this.state.quarters]
      },
      () => {
        this.getMerchcatSpecificVendors();
        this.getBookData();
      }
    );
  };

  onAllocateBudget = () => {
    const { selectedCategoryVal, selectedYearVal } = this.state;
    const payload = {
      category: selectedCategoryVal.value,
      Years: selectedYearVal.value
    };
    if (payload.category && payload.Years) {
      this.fetchFreshData(payload);
    }
  };

  redirectToCalendar = data => {
    let timeMonth = moment(
      (!_.isEmpty(this.state.selectedYearVal)
        ? String(this.state.selectedYearVal.value)
        : "2019") + String(" " + data.months + " 01")
    ).format();

    let startDate = moment([data.year, data.months - 1]).format("YYYY-MM-DD");
    let endDate = moment(startDate)
      .endOf("month")
      .format("YYYY-MM-DD");
    localStorage.setItem("startDate", startDate);
    localStorage.setItem("endDate", endDate);
    localStorage.setItem("fiscalMonth", data.months - 1);
    this.props.history.push(
      `/vis/Promo?populateMonth=true&timeMonth=${timeMonth}`
    );
  };

  // changing budget planned data
  changeBudgetData = ({ months, planned }) => {
    let updateBudgetData = _.cloneDeep(this.budgetData);
    updateBudgetData = _.filter(updateBudgetData, budget => {
      return budget.month !== months;
    });
    updateBudgetData.push({
      month: months,
      planned
    });

    this.budgetData = updateBudgetData;
  };

  // Saving update budget planned value
  saveBudgetDataChangedHandler = async () => {
    const budgetData = this.budgetData.map(budget => {
      return {
        month: moment()
          .month(budget.month)
          .format("M"),
        planned: budget.planned
      };
    });
    const budgetEditPayload = {
      Years: this.state.selectedYearVal.value
        ? this.state.selectedYearVal.value
        : "2019",
      category: this.state.selectedCategoryVal.value
        ? this.state.selectedCategoryVal.value
        : "FROZEN ENTREES",
      budgetData: budgetData
    };

    if (_.isEmpty(budgetData)) {
      ToastMessage(403,"No change in allocation amount")
      return;
    }
    try {
      this.props.postBudgetEdit({ budgetEditPayload, budgetDataPayload });
    } catch (err) {
      console.log("Update budget data failed");
    }
  };

  _optimiseBudget = () => {
    // this.setState({ isBudgetOptimize: true });
    const { updatedAvailableBudget, optimizationLevel } = this.state;
    let {
      selectedYearVal,
      selectedMerchcats,
      promoType,
      selectedQuarters,
      selectedBookNumbers,
      planName
    } = this.state;
    let { deselectedSKU, vendorHistoryData, brandHistoryData } = this.props;
    let tempVendorHistoryData = [...brandHistoryData];

    tempVendorHistoryData.forEach((row, index) => {
      row.budgetAvailable = updatedAvailableBudget[index];
      if(optimizationLevel[row.vendor]){
        row.isvendorlevel = optimizationLevel[row.vendor].isvendorlevel;
      } else {
        row.isvendorlevel = true;
      }
    });

    let merchcatValue = selectedMerchcats.length > 0 ? selectedMerchcats[0].value : null;
    const BudgetPlanPayload = {
      planName,
      fyear: selectedYearVal.value,
      categories: [merchcatValue],
      offerType: promoType,
      quarters: selectedQuarters.map(itemObj =>
        Number(itemObj.value.substring(1, 2))
      ),
      budgetOptimized: 0,
      dselectedSKU: deselectedSKU,
      books: selectedBookNumbers.map(itemObj => itemObj.value),
      planVendorArr: tempVendorHistoryData.map(row => {
        return {
          vendor: row.vendor,
          category: row.category,
          budgetUtilised: row.budgetUtilisedTY,
          budgetAvailable: row.budgetAvailable,
          brand: row.brand,
          isvendorlevel: row.isvendorlevel
        };
      })
    };

    const planId = this.getPlanId();
    if (planId) {
      BudgetPlanPayload.planId = planId;
      BudgetPlanPayload.status = this.props.planStatus;
    }
    if(this.state.planNum>0){
      BudgetPlanPayload.planId = this.state.planNum
    }

    try {
      this.props.postBudgetPlanUpsert(BudgetPlanPayload);
      if (BudgetPlanPayload.planId) {
        this.setState({ isBudgetOptimize: false });
      }
    } catch (err) {
      console.log("Create budgetPlane Api failed");
    }
    this.setState({planNum:''})
  };

  _finalizeBudget = () => {
    this.submitGA("Click","get finalize budget");
    this.setIsDisableOptimise(false);
    this.setState({ isFinalizeBudget: true });  
    const reqObj = {
      merchcat: this.state.selectedMerchcats.map(category => category.value)
    }
    this.props.actionGetAh6Data(reqObj)
  };

  handleUserInputModel = isOkButtonClicked => {
    if (isOkButtonClicked && this.state.planName) {
      this.submitGA("Click","Optimize budget");
      this._optimiseBudget();
      this.setState({
        isShow: false,
        planName: "",
        isShowAlert: true,
        isDisableOptimise: true
      });
    } else {
      this.setState({ isShow: isOkButtonClicked, planName: "" });
    }
  };
  handleDiscountsChange = (changedValues) => {
    let simulatedDiscounts = this.props.simulatedDiscounts;
    if(simulatedDiscounts && simulatedDiscounts.length){
      let editedChange = changedValues;
      simulatedDiscounts.push(editedChange[0]);
      this.props.actionSimulationPlan(simulatedDiscounts);
      this.setState({updatedDiscounts: simulatedDiscounts});
    } 
    else {
      this.props.actionSimulationPlan(changedValues);
      this.setState({ updatedDiscounts: changedValues});
           }
  }

  handleOptimiseBudget = data => {
    
    this.setState({ isShow: true, updatedAvailableBudget: data.budget, optimizationLevel: data.optimizeLevel });
  };

  handleModifySaveClick = () => {
    this.setIsDisableOptimise(false);
  };

  _handleChange = e => {
    this.setState({ planName: e.target.value || "" });
  };
  openModal = ()=>{
    this.setState({
      showModal:true
    })
  }
  closeModal = ()=>{
    this.setState({
      showModal:false
    })
  }
  handleCopyPlan = ()=>{
    console.log("Clicked copy plan");
    this.openModal();
  }
  handleCopyPlanSave = (planName)=>{
    console.log("plan name:",planName);
    let reqObj={
      planName:planName,
      planId:this.getPlanId()
    }
    this.props.actionCopyPlan(reqObj);
  }
  handleSaveOptimisedPlan = () => {
    let updatedDiscounts = cloneDeep(this.state.updatedDiscounts);
    let simulatedDiscounts = cloneDeep(this.props.simulatedDiscounts);
    if(simulatedDiscounts && simulatedDiscounts.length){
      updatedDiscounts = simulatedDiscounts;
    }
    const reqObj = {
      data: [
        {
          type: "plan",
          id: this.getPlanId(),
        }
      ],
      updatedDiscounts,
      status: "DRAFT"
    };
    this.submitGA("Click","save budget data");
    this.setState({updatedDiscounts: updatedDiscounts}, () =>{
      this.props.actionSaveOrSubmitPlan(reqObj);
    });
  };


  handleSubmitForApproval = () => {
    this.submitGA("Click","budget send for approval");
    let updatedDiscounts = cloneDeep(this.state.updatedDiscounts);
    let simulatedDiscounts = cloneDeep(this.props.simulatedDiscounts);
    if(simulatedDiscounts && simulatedDiscounts.length){
      updatedDiscounts = simulatedDiscounts;
    }
    const reqObj = {
      data: [
        {
          type: "plan",
          id: this.getPlanId()
        }
      ],
      updatedDiscounts,
      status: "PENDING",
      campaignsList : this.props.budgetPlanCampaignList

    };
    this.setState({updatedDiscounts: updatedDiscounts}, () =>{
    this.props.actionSaveOrSubmitPlan(reqObj);
    });
  };

  handleBudgetDownload = () => {
    const reqObj = {
      planId: this.getPlanId()
    }
    this.submitGA("Click","Download optimize plan");
    this.props.actionDownloadBudgetPlanning(reqObj)
  }

  handlePlanSummaryDownload = () => {
    const reqObj = {
      planId: this.getPlanId(),
      budgetPlanCampaignList: this.props.budgetPlanCampaignList
    }
    this.submitGA("Click","Download optimize plan");
    this.props.actionDownloadPlanSummary(reqObj)
  }

  handleBudgetPromoTrackerDownload = () => {
    const {selectedMerchcats, selectedVendors} = this.state;
    const reqObj = {
      planId: this.getPlanId(),
      vendor: selectedVendors.map((data) => data.value),
      merchcat: selectedMerchcats.map(category => category.value),
      budgetPlanCampaignList: this.props.budgetPlanCampaignList
    }
    this.submitGA("Click","Download promo tracker");
    this.props.actionDownloadPromoTracker(reqObj);
  }

  submitGA(action,label){
    BudgetPlanningGA({ action, label});
  }

  handleUpdateConstraints = data => {

    // const { updatedAvailableBudget } = this.state;
    let {
      selectedYearVal,
      selectedMerchcats,
      promoType,
      selectedQuarters,
      selectedBookNumbers,
      selectedVendors,
      planName,
      vendorBrandHistoryTable
    } = this.state;
    let { deselectedSKU, brandHistoryData, ah6 } = this.props;
    let tempVendorHistoryData = vendorBrandHistoryTable;

    // tempVendorHistoryData.forEach((row, index) => {
    //   row.budgetAvailable = data[index];
    // });
    let merchcatValue = selectedMerchcats.length > 0 ? selectedMerchcats[0].value : null;
    const BudgetPlanPayload = {
      screenName: 'budget-planning',
      fyear: selectedYearVal.value,
      category: [merchcatValue],
      vendor: selectedVendors.map((data) => data.value),
      ah6: ah6.map((data) => data.value),
      offerType: promoType,
      quarters: selectedQuarters.map(itemObj =>
        Number(itemObj.value.substring(1, 2))
      ),
      budgetOptimized: 0,
      dselectedSKU: deselectedSKU,
      books: selectedBookNumbers.map(itemObj => itemObj.value),
      planVendorArr: tempVendorHistoryData.map(row => {
        return {
          vendor: row.vendor,
          category: row.category,
          brand: row.brand,
          budgetUtilised: row.budgetUtilisedTY,
          budgetAvailable: row.budgetAvailable
        };
      })
    };



    // let tempVendorHistoryData = this.props.vendorHistoryData;

    
    // tempVendorHistoryData.forEach((row, index) => {
    //   row.budgetAvailable = data[index];
    // });
    const existingPlan = this.getPlanId();
    const newState = { 
      isBrowserNavigation: true,
      merchcat: this.state.selectedMerchcats,
      vendor: this.state.selectedVendors,
      year: this.state.selectedYearVal,
      quarter: this.state.selectedQuarters,
      offerType: this.state.promoType,
      vendorHistoryData: tempVendorHistoryData,
      dselectedSKU: this.state.dselectedSKU
   };
   const planId = this.getPlanId();
    if (planId) {
      BudgetPlanPayload.planName = planName;
    }

    window.history.pushState(newState,'')
    this.setState({planId: existingPlan, BudgetPlanPayload, redirectToConstraints:true })
    this.props.actionUpdateVendorHistory(tempVendorHistoryData);
    const path = this.props.location.search ? `/vis/budget-planning${this.props.location.search}?constraints`: `/vis/budget-planning?constraints`;
    this.props.history.push(path);
  }

  handleBack =(args) => {
    const reqObj = {
      merchcat: args.category.map(category => category.value)
    }
    this.props.actionGetAh6Data(reqObj)
    // actionGetAh6Data
    let path,redirectFrom;
    if(args.prevPlanId){
      
      path =  `/vis/budget-planning?planId=${args.prevPlanId}`;
      redirectFrom = `constraints` 
    } else{
      path = `/vis/budget-planning`
      redirectFrom =''
    }
    this.setState({redirectToConstraints:false, planNum: args.planId,redirectFrom });
    // this.props.actionUpdateVendorHistory(args.data);
    this.props.history.push(path);
  }

  updateRedirectFrom=()=>{
    this.setState({
      redirectFrom:''
    })
  }

  handleExpandCollapse = (data, tableData, type, optimizationLevel) => {
    let temp = [];
    tableData.forEach(ele => {
      if(optimizationLevel[ele.vendor]){
        ele.isvendorlevel = optimizationLevel[ele.vendor].isvendorlevel;
      }
      if(data.vendor !== ele.vendor) {
        temp.push(ele);
      } else {
        if(type === 'expand'){
        temp.push(ele);
        let filetrdata = this.props.brandHistoryData.filter(ele => ele.vendor === data.vendor);
        temp.push(...filetrdata);
      } else if(type === 'collapse' && ele.brand === 'ALL BRANDS' ){
        temp.push(ele);
      }
      }
    });
    this.setState({vendorBrandHistoryTable: temp})
  }

  handleBrandBudgetModification = data => {
    const { currentRow, field, value, rowData } = data;
    const { vendor, brand } = currentRow;
    const reqObj = {
      vendor,
      field,
      value,
      brand
    };
    let updatedRow = [];
    let brandPrevBudget = this.props.brandHistoryData.filter(data => data.vendor === currentRow.vendor && data.brand === currentRow.brand);

    updatedRow = rowData.map((row) => {
      if (row.vendor === currentRow.vendor && row.brand === 'ALL BRANDS') {
        row[field] = row[field]-brandPrevBudget[0][field]+value;
      } else if(row.vendor === currentRow.vendor && row.brand === currentRow.brand) {
        row[field] = value;
      }
      return row;
    });
  
    let tempBrandBudget = this.props.brandHistoryData.map(data => {
      if(data.vendor === currentRow.vendor && data.brand === currentRow.brand){
        data[field] = value;
      }
      return data;
    })
    this.props.actionUpdateBrandBudget(tempBrandBudget);
    this.setState({vendorBrandHistoryTable: updatedRow});

  return tempBrandBudget
  }

  handleVendorBudgetModification = data => {
    const { currentRow, field, value, rowData } = data;
  let updatedRow = [];
  let tempVendorBrandBudgetData = this.props.brandHistoryData;
  let vendorBrandPrevBudget = tempVendorBrandBudgetData.filter(data => data.vendor === currentRow.vendor);
  // const { vendor } = currentRow;
  let temp = [];
  let vedorBudget = rowData.filter(row => row.vendor === currentRow.vendor && row.brand === 'ALL BRANDS');
  let updatedBrandBudget = tempVendorBrandBudgetData.map((row) => {
    if (row.vendor === currentRow.vendor) {
      let tempBrandBudget = 0;
        if(vedorBudget[0][field] !== 0) {
          temp = vendorBrandPrevBudget.map(brandRow => {
            if(brandRow.brand === row.brand) {
              let brandPortion = brandRow[field]/vedorBudget[0][field];
              tempBrandBudget = value * brandPortion;
              row[field] = tempBrandBudget;
              brandRow[field] = tempBrandBudget
            }
            return brandRow;
          })
        } else {
          let brandCount = vendorBrandPrevBudget.length;
          tempBrandBudget =parseFloat(value / brandCount);
          temp = vendorBrandPrevBudget.map(brandRow => {
            if(brandRow.brand === row.brand) {
              row[field] = tempBrandBudget;
              brandRow[field] = tempBrandBudget
            }
            return brandRow;
          })
        }
     
    }
    return row;
  });

  updatedRow = rowData.map(row => {
    if (row.vendor === currentRow.vendor) {
      if(row.brand !== 'ALL BRANDS'){
        temp.forEach(brandRow => {
          if(brandRow.brand === row.brand) {
            row[field] = brandRow[field];
            }
        })
      } else {
        row[field] = value;
      }
    }
    return row;
  });

  this.props.actionUpdateBrandBudget(tempVendorBrandBudgetData);
  this.setState({vendorBrandHistoryTable: updatedRow})
  return tempVendorBrandBudgetData;
  }
  render() {
    const {
      budgetGraphData,
      budgetPlanningData,
      budgetCalendarData,
      vendorHistoryData
    } = this.props;
    const {
      merchcats,
      selectedMerchcats,
      vendors,
      selectedVendors,
      years,
      quarters,
      selectedQuarters,
      promoType,
      bookNumbers,
      selectedBookNumbers,
      modifySkuPopupData,
      isBudgetOptimize,
      isFinalizeBudget,
      showMetricsTable,
      planName,
      isShow,
      isExistingPlan,
      dselectedSKU,
      planId,
      isShowAlert,
      redirectToBudgetOptimationPlan,
      vendorBrandHistoryTable
    } = this.state;

    return (
      <PageLoader loader={this.props.loader || this.props.loading}>
        {this.state.redirectToConstraints ? (
          <Constraints
            redirectToConstraints={this.state.redirectToConstraints}
            selectedMerchcats={this.state.selectedMerchcats}
            selectedVendors={this.state.selectedVendors}
            selectedQuarters={this.state.selectedQuarters}
            selectedYear={this.state.selectedYearVal.value}
            handleBack={this.handleBack}
            selectedAh6={this.props.ah6}
            offerType={this.state.promoType}
            vendorHistoryData={vendorHistoryData}
            planNum={this.state.planNum}
            planId={this.state.planId}
            fetchReqObj={this.state.BudgetPlanPayload}
          />
        ) : (
          <PageWrapper>
            {/* <ToastContainer /> */}
            <div className="row">
              <div className="col-md-6" style={{ paddingTop: '20px' }}>
                <BreadCrumb root="" child="Budget Planning" />
              </div>
              <div className="col-md-6 text-right heading-top">
                <Link
                  to={{
                    pathname: '/vis/create-new-promotion',
                  }}>
                  <button
                    className="btn btn-primary mr-4 mt-3"
                    style={{ textTransform: 'capitalize' }}
                    onClick={() => {
                      this.submitGA('Click', 'Create Standalone Offer');
                    }}>
                    Create standalone offer
                  </button>
                </Link>
              </div>
            </div>
            <Filter
              merchcats={merchcats}
              selectedMerchcats={selectedMerchcats}
              vendors={vendors}
              selectedVendors={selectedVendors}
              getMerchcatSpecificVendors={this.getMerchcatSpecificVendors}
              updateState={this.updateState}
              setYearSelected={this.setYearSelected}
              selectedYearVal={this.state.selectedYearVal}
              quarters={this.state.quarters}
              years={this.state.years}
              selectedQuarters={selectedQuarters}
              promoType={promoType}
              setPromoType={this.setPromoType}
              bookNumbers={bookNumbers}
              selectedBookNumbers={selectedBookNumbers}
              getBookData={this.getBookData}
              onAllocateBudget={this.onAllocateBudget}
              modifySkuPopupData={modifySkuPopupData}
              finalizeBudget={this._finalizeBudget}
              setSelectedCategory={this.setSelectedCategory}
              isExistingPlan={isExistingPlan}
              dselectedSKU={dselectedSKU}
              handleModifySaveClick={this.handleModifySaveClick}
              planId={planId}
              planStatus={this.props.planStatus}
            />
            {(isExistingPlan || isFinalizeBudget) && (
              // ?<VendorPlanHistoryTable vendorHistoryData={vendorHistoryData} optimiseBudget={this.handleOptimiseBudget}/>
              // : (isFinalizeBudget) &&
              <VendorPlanHistoryTable
                vendorHistoryData={vendorBrandHistoryTable}
                optimiseBudget={this.handleOptimiseBudget}
                isDisableOptimise={this.state.isDisableOptimise}                
                setIsDisableOptimise={this.setIsDisableOptimise}
                planId={planId}
                planStatus={this.props.planStatus}
                optimizationLink={this.props.optimizationLink}
                handleUpdateConstraints={this.handleUpdateConstraints}
                handleExpandCollapse={this.handleExpandCollapse}
                handleBrandBudgetModification={this.handleBrandBudgetModification}
                handleVendorBudgetModification={this.handleVendorBudgetModification}
                brandLevelData={this.props.brandHistoryData}
              />
            )}
            <div>
              {isBudgetOptimize && this.props.planData.length > 0 ? (
                <BudgetPlanTable
                  getPlanId={this.getPlanId}
                  defaultColDef={this.state.defaultColDef}
                  planName={this.state.planName}
                  budgetPlanningData={budgetPlanningData}
                  handleDiscountsChange={this.handleDiscountsChange}
                  planStatus={this.props.planStatus}
                  selectedYearVal={this.state.selectedYearVal}
                  handleDownload={this.handleBudgetDownload}
                  promoTrackerDownload={this.handleBudgetPromoTrackerDownload}
                  redirectFrom={this.state.redirectFrom}
                  updateRedirectFrom={this.updateRedirectFrom}
                  actoinUpdateInactiveMetrics={this.props.actoinUpdateInactiveMetrics}
                />
              ) : null}
              {isBudgetOptimize && this.props.planData.length > 0 && showMetricsTable ? (
                <BudgetMetricsTable
                  getPlanId={this.getPlanId}
                  defaultColDef={this.state.defaultColDef}
                  budgetPlanningData={budgetPlanningData}
                  selectedYearVal={this.state.selectedYearVal}
                  handleSaveOptimisedPlan={this.handleSaveOptimisedPlan}
                  handleSubmitForApproval={this.handleSubmitForApproval}
                  planStatus={this.props.planStatus}
                  selectedYearVal={this.state.selectedYearVal}
                  updatedDiscounts={this.state.updatedDiscounts}
                  isDisableOptimise={this.state.isDisableOptimise}
                  handleDownload = {this.handlePlanSummaryDownload}
                />
              ) : null}
              {isBudgetOptimize && this.props.planData.length > 0 ? (
                <BudgetInactiveArticleTable
                  plannedArticleData={this.props.plannedArticleData}
                  inactiveMetricsData={this.props.inactiveMetricsData}
                  actoinUpdateInactiveMetrics={this.props.actoinUpdateInactiveMetrics}
                  budgetPlanCampaignList={this.props.budgetPlanCampaignList}
                />
              ) : null}
              {isBudgetOptimize && this.props.planData.length > 0 && showMetricsTable ? (
                <FooterControls
                  handleSaveOptimisedPlan={this.handleSaveOptimisedPlan}
                  handleSubmitForApproval={this.handleSubmitForApproval}
                  handleCopyPlan={this.handleCopyPlan}
                  // isDisabled={planStatus !== 'DRAFT' && planStatus !== 'PREDRAFT'}
                  userInfo={this.props.userInfo}
                  isDisableOptimise={this.state.isDisableOptimise}
                  updatedDiscounts={this.state.updatedDiscounts}
                  planStatus={this.props.planStatus}
                />
              ) : null}
            </div>
            {this.state.showModal &&(
                <CopyPlan
                    isShow={this.state.showModal}
                    closeModal={this.closeModal}
                    openModal={this.openModal}
                    copyPlanSave={this.handleCopyPlanSave}
                />
            )}
            {isShow && (
              <UserInputNodal
                isShow={isShow}
                handleUserInputModel={this.handleUserInputModel}
                inputLabel="Plan Name"
                inputPlaceholder="Enter Plan Name"
                handleChange={this._handleChange}
                planName={planName}
              />
            )}

            {isShowAlert && (
              <div className="show-alert">
                <h5>A mail will be sent to your inbox after the plan is ready</h5>
              </div>
            )}
            {this.props.optimizationLink && redirectToBudgetOptimationPlan && (
              <RedirectOptimizedPlan optimizationLink={this.props.optimizationLink} isOpen={true} />
            )}
            {/* <FooterControls /> */}
          </PageWrapper>
        )}
      </PageLoader>
    );
  }
}

BudgePlanning.propTypes = {
  getBudgetData: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  simulatedDiscounts:state.budgetPlan.simulatedDiscounts,
  budgetPlanningData: state.budgetData.budgetPlanning,
  budgetCalendarData: state.budgetCalendar.budgetPlanningCalenderData,
  budgetGraphData: state.budgetData.budgetGraphData,
  loader: state.budgetData.loading,
  vendorHistoryData: state.budgetPlan.vendorHistoryTable,
  ah6: state.budgetPlan.ah6,
  deselectedSKU: state.budgetPlan.skuData.deselectedSku,
  planData: state.budgetPlan.planData,
  yearQuaters: state.budgetPlan.yearQuaters,
  planStatus: state.budgetPlan.planStatus,
  loading: state.budgetPlan.loading,
  optimizationLink: state.budgetPlan.optimizationLink,
  planError: state.budgetPlan.planError,
  plannedArticleData:state.budgetPlan.plannedArticleData,
  userInfo: state.userManagementData.userInfo.action, 
  inactiveMetricsData: state.budgetPlan.inactiveMetricsData,
  brandHistoryData: state.budgetPlan.brandHistoryData,
  budgetPlanCampaignList: state.budgetPlan.budgetPlanCampaignList
});

const mapDispatchToProps = dispatch => {
  return {
    actionSimulationPlan : val => dispatch(actionSimulationPlan(val)),
    getBudgetData: val => dispatch(getBudgetData(val)),
    getBudgetCalendarData: val => dispatch(getBudgetCalendarData(val)),
    getBudgetGraphData: val => dispatch(getBudgetGraphData(val)),
    postBudgetEdit: val => dispatch(postBudgetEdit(val)),
    postBudgetPlanUpsert: val => dispatch(postBudgetPlanUpsert(val)),
    getVendorHistoryTable: val => dispatch(getVendorHistoryTable(val)),
    actionFetchSpecificPlan: val => dispatch(actionFetchSpecificPlan(val)),
    actionYearQuaters: val => dispatch(actionYearQuaters(val)),
    actionResetPlanError: val => dispatch(actionResetPlanError(val)),
    actionGetAh6Data: val => dispatch(actionGetAh6Data(val)),
    setSkuData: val => dispatch(setSkuData(val)),
    actionSaveOrSubmitPlan: val => dispatch(actionSaveOrSubmitPlan(val)),
    actionDownloadBudgetPlanning: val => dispatch(actionDownloadBudgetPlanning(val)),
    fetchMerchCatValues: val => dispatch(fetchMerchCat(val)),
    fetchCategorySpecificVendors: val=> dispatch(fetchVendorList(val)),
    actionDownloadPromoTracker: val => dispatch(actionDownloadPromoTracker(val)),
    actionResetPlanStatus: val => dispatch(actionResetPlanStatus(val)),
    resetBudgetPlanIdData:val=>dispatch(actionResetBudgetPlanIdData(val)),
    resetVendorHistoryTable:val=>dispatch(resetVendorHistoryTable(val)),
    resetOptimizationLink:val=>dispatch(resetOptimizationLink(val)),
    resetBudgetPlanHeaders: val =>dispatch(resetBudgetPlanHeaders(val)),
    retryPlanArticleCampaigns: val =>dispatch(retryPlanArticleCampaigns(val)),
    actionUpdateVendorHistory: val =>dispatch(actionUpdateVendorHistory(val)),
    actionDownloadPlanSummary: val =>dispatch(actionDownloadPlanSummary(val)),
    actoinUpdateInactiveMetrics: val => dispatch(actoinUpdateInactiveMetrics(val)),
    actionUpdateBrandBudget: val => dispatch(actionUpdateBrandBudget(val)),
    actionBudgetPlanCampaignList: val => dispatch(actionBudgetPlanCampaignList(val)),
    actionCopyPlan : val=> dispatch(actionCopyPlan(val))
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BudgePlanning));
