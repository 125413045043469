import ReactGA from 'react-ga';
import environment from '../environments/environment';


class GaUtils {
  constructor() {
    console.log('User Id',process.env.REACT_APP_TRACKING_ID);
    ReactGA.initialize({
      trackingId: environment.trackingID,
    });
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  setUserId = (userId) => {
    ReactGA.set({ userId })
  }
  trackEvent = (category) => (action, label) => {
    console.log('Google Analytics', category, action, label);

    // using ES6 object shortcut notation
    ReactGA.event({
      category,
      action:action.action,
      label:action.label
    });
  };
}

export default GaUtils;
