import { toast } from "react-toastify";
import { MESSAGES } from "./app-constants";

export const ToastMessage = (status,message)=>{
    switch (status) {
        case 200:
            toast.success(message, {
                position: toast.POSITION.TOP_CENTER
              });
            break;
        case 403:
            toast.error(message, {
                position: toast.POSITION.TOP_CENTER
              });
        default:
            break;
    }

}