import React, { Component } from 'react';
import PageLoader from '../../utils/Loader';
import { PROMO_LEVEL, PROMO_ARTICLE_LEVEL } from '../../constants';
import environment from '../../environments/environment';
import moment from 'moment';
import { Card, Form, Row, Col } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { openLoader, closeLoader, getAnalyzeEventDetails } from '../../actions/promo';
// import PromotionPipeline from './../../components/Content/Promotions/PromoCalendar/promoPipelineCalendar';
import PromotionPipeline from '../PromotionPipeline';
import {
  fetchPromoHierarchyData,
  fetchPromoData,
  downloadHistoricalPromos,
} from '../../apis/promo.api';
import { Element, scroller } from 'react-scroll';
import DynamicFilters from '../DynamicFilters';
import _ from 'underscore';
import './historicalPerformance.css';
import { TouchListener } from 'ag-grid-community';
import MultiSelectCheckboxes from '../MultiSelectCheckboxes';
import { resetCampaingsList } from '../AnalyzeEvent/analyzeEventAction';
import { HistoricalPerformanceGA } from '../../common/GAUtils';
class HistoricalPerformance extends Component {
  constructor(props) {
    super(props);
    this.state = {
      promoPipeLineObj: {},
      promo: '',
      promosList: [],
      selectedDate: new Date(),
      selectedTab: PROMO_LEVEL,
      merchcat: [],
      vendor: [],
      brand:[],
      month: [],
      ah6: [],
      quarter: [],
      articleNo: [],
      loader: false,
      selectedYear: [],
      campaign: [],
      promoArticleLevelData: [],
      promoFlag:false
    };
  }

  componentDidMount() {
    const emailLink = localStorage.getItem('emailLink');
    if(emailLink){
      this.props.history.push(emailLink)
      localStorage.removeItem('emailLink')
    }
    this.props.resetCampaingsList();
    // this.props.booksList({});
  }

  componentDidUpdate(oldProps, oldState) {
    if (oldProps.campaignsList !== this.props.campaignsList) {
      if (this.state.merchcat.length > 0) {
        this.setState({
          campaign: this.props.campaignsList,
        });
      }
    }
    if(oldState.campaign !== this.state.campaign){
        this.setState({
          promosList:[],
          promoArticleLevelData:[]
        });
        this.refreshPipeLineTableData();
    
    }
  }
  handleFilterAction = () => {
    this.submitGA('Click', 'Filter Button');
    this.setState(
      {
        promosList: [],
        promoArticleLevelData: [],
      }
    );
    let params = this.getSelectedFilters();
    if (this.state.merchcat.length > 0) {
      this.props.openLoader();
      this.props.booksList(params);
    }
  };
  onReset = () => {
    this.submitGA('Click', 'Reset Button');
    this.setState({
      loader: false,
      vendor: [],
      brand:[],
      ah6: [],
      merchcat: [],
      articleNo: [],
      selectedDate: new Date(),
      promosList: [],
      selectedYear: [],
      campaign: [],
      promoArticleLevelData: [],
    });
    this.props.resetCampaingsList();
    // this.props.booksList({});
  };
  handleToggle = (type) => {
    this.submitGA('Toggle', 'Toggle  Promo and Promo Article Level');
    this.setState(
      {
        selectedTab: type,
      },
      () => {
        if (this.state.selectedTab === PROMO_LEVEL) {
          if (this.state.promosList.length == 0) {
            this.refreshPipeLineTableData();
          }
        } else {
          if (this.state.promoArticleLevelData.length == 0) {
            this.refreshPipeLineTableData();
          }
        }
      }
    );
  };
  updatePromoArticleLevel = (data) => {
    let promoALData = data.map((obj, index) => {
      obj.id = obj.articleNo + '-' + obj.promoId;
      obj.percOfNewCustArticle = obj.percOfNewCust;
      obj['sales&incremental'] = obj.baselineSales + obj.directIncrementalSales
      obj['baseline&incremental'] = obj.baselineMargin + obj.directIncrementalMargin
      return obj;
    });
    return promoALData;
  }
  async refreshPipeLineTableData() {
    let params = this.getSelectedFilters();
    params.offset = 0;
    //if no filters are selected, dont call api
    if (params.merchcat.length == 0) return;
    try {
      this.props.openLoader();
      let data = [];
      if (this.state.selectedTab == PROMO_LEVEL) {
        if(this.state.campaign.length>0){
          data = await fetchPromoData(params);
          data = data.map((obj, index) => {
            obj.id = obj.promoId;
            obj.percOfNewCustArticle = obj.percOfNewCust;
            obj['sales&incremental'] = obj.baselineSales + obj.directIncrementalSales
            obj['baseline&incremental'] = obj.baselineMargin + obj.directIncrementalMargin
            // obj.baselineAndIncrementalSales = obj.baselineSales + obj.directIncrementalSales
            // obj.baselineAndIncrementalMargin = obj.baselineMargin + obj.directIncrementalMargin
            return obj;
          });
        }
        this.setState(
          {
            promosList: data,
          },
          async () => {
            this.props.closeLoader();
            if (data.length >= 500) {
              params.offset = 500;
              let remainingData = await fetchPromoData(params);
              remainingData = remainingData.map((obj, index) => {
                obj.id = obj.promoId;
                obj.percOfNewCustArticle = obj.percOfNewCust;
                obj['sales&incremental'] = obj.baselineSales + obj.directIncrementalSales
                obj['baseline&incremental'] = obj.baselineMargin + obj.directIncrementalMargin
                // obj.baselineAndIncrementalSales = obj.baselineSales + obj.directIncrementalSales
                // obj.baselineAndIncrementalMargin = obj.baselineMargin + obj.directIncrementalMargin
                return obj;
              });
              // this.props.closeLoader();
              this.setState({
                promosList: data.concat(remainingData),
              });
            } else {
              // this.props.closeLoader();
            }
          }
        );
      } else {
        if(this.state.campaign.length>0){
          data = await fetchPromoHierarchyData(params);
          data = this.updatePromoArticleLevel(data);
        }
        this.setState(
          {
            promoArticleLevelData: data,
          },
          async () => {
            var dataFlag=false;
            this.props.closeLoader();
            if (data.length >= 500) {
              params.offset = 500;
              let remainingData = await fetchPromoHierarchyData(params);
              remainingData = this.updatePromoArticleLevel(remainingData);
              // this.props.closeLoader();
              var newData=data.concat(remainingData);
              if(newData.length==500){
                dataFlag=true;
              }
              this.setState({
                promoArticleLevelData: data.concat(remainingData),
                promoFlag:dataFlag
              });
            } else {
              // this.props.closeLoader();
            }
          }
        );
      }

      // this.props.closeLoader();
    } catch (error) {
      this.props.closeLoader();
    }
  }
  onGridReady = (params) => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  toggleDatePicker() {
    this.component.setOpen(this.focus);
    this.focus = !this.focus;
  }
  updateState = (params) => {
    let keysLength = Object.keys(params).length;
    if (params.hasOwnProperty('merchcat') && keysLength === 1) {
      this.submitGA('Filter', 'Changed merchcat selection');
    }
    if (params.hasOwnProperty('ah6') && keysLength === 1) {
      this.submitGA('Filter', 'Changed ah6 selection');
    }
    if (params.hasOwnProperty('vendor') && keysLength === 1) {
      this.submitGA('Filter', 'changed vendor selection');
    }
    if (params.hasOwnProperty('brand') && keysLength === 1) {
      this.submitGA('Filter', 'changed brand selection');
    }
    if (params.hasOwnProperty('articleNo') && keysLength === 1) {
      this.submitGA('Filter', 'changed article selection');
    }

    if (params.hasOwnProperty('selectedYear') && keysLength === 1) {
      this.submitGA('Filter', 'changed selected year selection');
    }

    if (params.hasOwnProperty('campaign') && keysLength === 1) {
      this.submitGA('click', 'changed books selection');
    }

    this.setState(params);
  };
  getSelectedFilters = () => {
    let merchcat = this.state.merchcat ? this.state.merchcat.map((object) => object.value) : null;
    let vendor = this.state.vendor ? this.state.vendor.map((object) => object.value) : null;
    let brand = this.state.brand ? this.state.brand.map((object) => object.value) : null;
    let selectedDate = moment(this.state.selectedDate).format('YYYY-MM-DD');
    let ah6 = this.state.ah6 ? this.state.ah6.map((object) => object.value) : null;
    let articleNo = this.state.articleNo.map((obj) => obj.value);
    // let dates =this.state.selectedYear.map(object => {
    //         return {startDate: object.startDate, endDate: object.endDate};
    //     });
    let campaignId = this.state.campaign.map((obj) => obj.id);
    let yearQuarters = this.state.selectedYear.map((yr) => {
      return { fyear: yr.year, quarter: yr.quater };
    });

    let calendarObj = {
      merchcat,
      vendor,
      brand,
      ah6,
      articleNo,
      selectedDate,
      type: this.state.selectedTab,
      // dates,
      campaignId,
      yearQuarters,
      screenName: 'historical',
    };
    return _.pick(calendarObj, _.identity);
  };
  downloadExcel = async (selectedColumns) => {
    this.submitGA('Click', 'Download File');
    const passedObj = this.getSelectedFilters();
    this.props.openLoader();
    try {
      const data = await downloadHistoricalPromos({ ...passedObj, selectedColumns });
      window.open(data.url, '_self');
      this.props.closeLoader();
    } catch (error) {
      this.props.closeLoader();
    }
  };
  getPromoData = () => {
    this.refreshPipeLineTableData();
  };

  submitGA(action, label) {
    HistoricalPerformanceGA({ action, label });
  }
  render() {
    const viewProperties = {
      enableCompaign: false,
      viewMode: true,
    };
    let DynamicFiltersProps = [
      {
        type: 'MultiSelectCheckboxes',
        placeholder: 'Select Merchcat',
        state: this.state.merchcat,
        mappingKey: 'merchcat',
        onMenuCloseApi: true,
        grid: 'col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2',
        label: 'Merchcat',
      },
      {
        type: 'MultiSelectCheckboxes',
        placeholder: 'Select AH6',
        state: this.state.ah6,
        mappingKey: 'ah6',
        onMenuCloseApi: true,
        grid: 'col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2',
        label: 'AH6',
      },
      {
        type: 'MultiSelectCheckboxes',
        placeholder: 'Select Vendor',
        state: this.state.vendor,
        mappingKey: 'vendor',
        onMenuCloseApi: true,
        grid: 'col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2',
        label: 'Vendor',
      },
      {
        type: 'MultiSelectCheckboxes',
        placeholder: 'Select Brand',
        state: this.state.brand,
        mappingKey: 'brand',
        onMenuCloseApi: true,
        grid: 'col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2',
        label: 'Brand',
      },
      {
        type: 'MultiSelectCheckboxes',
        placeholder: 'Select Article',
        state: this.state.articleNo,
        mappingKey: 'articleNo',
        onMenuCloseApi: false,
        grid: 'col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2',
        label: 'Article',
      },
      {
        type: 'MultiSelectCheckboxes',
        placeholder: 'Select Fiscal Year',
        state: this.state.selectedYear,
        mappingKey: 'selectedYear',
        onMenuCloseApi: false,
        grid: 'col-xs-12 col-sm-6 col-md-2 col-lg-2 col-xl-2',
        label: 'Fiscal Year',
        selectionLimit: 5,
      },
      // {
      //     type: 'DatePicker',
      //     placeholder: 'Select Fiscal Year & Month',
      //     state: this.state.selectedDate,
      //     grid:'col-md-3'
      // }
    ];

    let DynamicFiltersOptions = {
      merchcatOptions: [],
      ah6Options: [],
      brandOptions:[],
      vendorOptions: [],
      articleNoOptions: [],
      selectedYearOptions: [],
    };

    let hierarchyLevel = ['merchcat','ah6','vendor','brand', 'articleNo'];
    let hierarchyApiCalls = [
      {
        optionsMappingKey: 'merchcat',
        UrlName: 'merchcat',
      },
      {
        optionsMappingKey: 'selectedYear',
        UrlName: 'selectedYear',
      },
    ];
    let hierarchyFilterApi = 'merchcatFilter';
    const { merchcat, ah6, vendor, brand, articleNo, selectedYear } = this.state;
    return (
      <div>
        <PageLoader loader={this.props.loader || this.state.loader || this.props.loading}>
          {!this.state.showPromotionMain ? (
            <div>
              <div className="card CalendarMain promoCalendarDiv1" id="promocalendar-maincontainer">
                <div className="row">
                  <div className="col-4">
                    <h4 className="promoCal-brdcrm mb-10">
                      <span className="font-bold">Historical Performance</span>
                    </h4>
                  </div>
                  <div className="col-4"></div>
                </div>
                <div className="row filter-block">
                  <DynamicFilters
                    filtersList={DynamicFiltersProps}
                    options={DynamicFiltersOptions}
                    hierarchyLevel={hierarchyLevel}
                    hierarchyApiCalls={hierarchyApiCalls}
                    hierarchyFilterApi={hierarchyFilterApi}
                    openLoader={this.props.openLoader}
                    closeLoader={this.props.closeLoader}
                    updateParentState={this.updateState}
                    filterData={this.handleFilterAction}
                    onReset={this.onReset}
                    promoFlag={this.state.promoFlag}
                    viewType="verticle"
                    isFilterButtonDisabled={
                      merchcat.length === 0 ||
                      ah6.length === 0 ||
                      vendor.length === 0 ||
                      brand.length === 0||
                      articleNo.length === 0 ||
                      selectedYear.length === 0
                    }
                    screenName="historical"
                  />
                </div>
                <div className="row" style={{ marginTop: '-18px' }}>
                  <div className="col-md-3">
                    <label style={{ fontWeight: 'bold', fontSize: '14px' }}>Book (s)</label>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 ">
                    <MultiSelectCheckboxes
                      placeholderButtonLabel="Select Book (s)"
                      options={this.props.campaignsList}
                      value={this.state.campaign}
                      mappingKey="campaign"
                      updateState={this.updateState}
                      onMenuCloseApiFun={this.getPromoData}
                      onMenuCloseApi={true}
                      defaultSelectedOptions={true}
                    />
                  </div>
                </div>
                <div className="spacer"></div>
                <Element
                  name="showPromotionTag"
                  style={{
                    position: 'relative',
                    zIndex: 0,
                  }}
                  className="element">
                  <PromotionPipeline
                    viewMode={this.props.viewMode ? this.props.viewMode : false}
                    call_edit_adgroup_in_createPromo={(adgroupData) =>
                      this.call_edit_adgroup(adgroupData)
                    }
                    selectedPromo={this.state.selectedPromo}
                    selectedPromoName={this.state.selectedPromoName}
                    selectedMerchCat={this.state.merchcat}
                    selectedVendor={this.state.vendor}
                    startDate={this.state.historyStartDate}
                    endDate={this.state.historyEndDate}
                    tableData={
                      this.state.selectedTab === PROMO_LEVEL
                        ? this.state.promosList
                        : this.state.promoArticleLevelData
                    }
                    openLoader={this.props.openLoader}
                    closeLoader={this.props.closeLoader}
                    promoPipeLineObj={this.state.promoPipeLineObj}
                    selectedTab={this.state.selectedTab}
                    handleToggle={this.handleToggle}
                    viewProperties={viewProperties}
                    downloadExcel={this.downloadExcel}
                  />
                </Element>
              </div>
            </div>
          ) : (
            {}
          )}
        </PageLoader>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    view: state.calendar.view,
    loader: state.calendar.loader,
    campaignsList: state.analyzeEvent.campaignsList,
    loading: state.userReducer.loading,
    userInfo: state.userManagementData.userInfo,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    openLoader: (val) => dispatch(openLoader()),
    closeLoader: (val) => dispatch(closeLoader()),
    booksList: (val) => dispatch(getAnalyzeEventDetails(val)),
    resetCampaingsList: (val) => dispatch(resetCampaingsList(val)),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HistoricalPerformance));
