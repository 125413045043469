import React, { Component } from 'react';
import { Line } from 'react-chartjs-2';
import 'chartjs-plugin-dragdata';

class LineChart extends Component {
  render() {
    const { data, options, width, height } = this.props;
    return (
      <div>
        <Line
          data={data}
          options={options}
          width={width}
          height={height}
          responsive={true}
        />
      </div>
    );
  }
}

export default LineChart;
