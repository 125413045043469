import React, { useEffect, useState } from 'react';
import { AgGridReact } from 'ag-grid-react';
import { convertMillionsFormatter } from '../../utils/AgGridFormatters';
import {OVERALL_PERFORMANCE_TABLE,PLANNED_VS_OPTIMIZED_TABLE,PROMO_PERFORMANCE_TABLE, OVERALL, PROMO, OPTIMIZED} from './constants';
import isUndefined from 'lodash/isUndefined';

const DashboardTable = (props) => {
  const [gridApi, setGridApi] = useState({});
  const [colDef, setColDef] = useState([]);
  const [rowData, setRowData] = useState([]);
  const flattenObject = (data) => {
    let obj = {};
    
    for (let index in data) {
      if (!data.hasOwnProperty(index)) continue;

      if (data[index] && typeof data[index] == 'object') {
        let flatObject = {};
        if(Object.keys(data[index]).length===3) {
          flatObject = flattenObject(data[index]);
        } else {
          const obj = data[index];
          const keys = props.type==="optimized"?['optimized','planned','change']:['ly','ty','change'];
          keys.forEach(key => {
            if(!obj.hasOwnProperty(key)){
              obj[key]='-';
            }
          })
          
          flatObject = flattenObject(obj);
        }
        for (let inObj in flatObject) {
          if (!flatObject.hasOwnProperty(inObj)) continue;

          
            if(inObj === 'change') {
              obj[index + '_' + inObj] = `${convertMillionsFormatter(flatObject[inObj]*100)} %`;
            }else {
              obj[index + '_' + inObj] = convertMillionsFormatter(flatObject[inObj]);
            }
        }
      } else {
        obj[index] = data[index];
      }
    }
    return obj;
  };

  function onGridReady(params) {
    let allColumnIds = [];
    setGridApi(params.api);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.gridColumnApi.getAllColumns().forEach(column => {
      allColumnIds.push(column.colId);
    });

    this.gridColumnApi.autoSizeColumns(allColumnIds, true);
    // this.gridApi.sizeColumnsToFit();
  }
  useEffect(() => {
    const data = props.tableData.map((element) => {
      return flattenObject(element);
    });
    let columDefinition = [
      {
        headerName: props.view.charAt(0).toUpperCase() + props.view.slice(1),
        field: 'name',
        width: 130,
        sortable:true,
        filter: true
      },
    ];

    
    let metricsList = [];
    switch (props.type) {
      case OVERALL:
        metricsList = OVERALL_PERFORMANCE_TABLE
        break;
        case PROMO:
          metricsList = PROMO_PERFORMANCE_TABLE
          break;
          case OPTIMIZED:
            metricsList = PLANNED_VS_OPTIMIZED_TABLE
            break;
      default:
        break;
    }

    columDefinition.push(...metricsList);
    setColDef(columDefinition);
    setRowData(data);
  }, props.tableData);
  useEffect(() => {
    if(Object.keys(gridApi).length > 0){
    if(rowData.length > 50){
      gridApi.setDomLayout("normal");
      document.querySelector("#defaultGrid").style.height = "350px";
    }else{
      gridApi.setDomLayout("autoHeight");
      document.querySelector("#defaultGrid").style.height = '';
    }
  }

  }, [rowData])
  return (
    <React.Fragment>
    <div className={rowData.length === 1 ? 'remove-ag-grid-bottom-space' : ''}>
    <div
      // id="modifySkuGrid"
      id='defaultGrid'
      className="ag-theme-balham"
      style={{
        // height: "100%",
        width: "100%"
      }}
    >
          <AgGridReact
            floatingFilter={true}
            defaultColDef={{
              resizable: true,
            }}
            suppressCellSelection={false}
            columnDefs={colDef}
            rowData={rowData}
            onGridReady={onGridReady}
            domLayout='autoHeight'
            enableCellTextSelection={true}
          />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DashboardTable;
