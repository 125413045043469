import React, { Component } from 'react';
import ConstaintDiscountDepth from './constraintsDiscountDepth';
import ConstarintCycleSelection from './cycleSelectionComponent';
import Categories from './categories';
import { fetchDiscountDepthDropDown } from '../../apis/budgetPlan.api';
import './index.css';
import _, { cloneDeep } from 'lodash';
import PageLoader from '../../utils/Loader';
import { cycleOptionsList, isConsecutive, discountCycleOptions,quarterlyDiscountCycleOptions, findConsecutive,quarters } from './constants';
import { Alert, InputGroup } from 'react-bootstrap';
import NumericInput from 'react-numeric-input';
import { fetchGroupDiscountDepth } from '../../apis/constraints.api';
import { formatGroupArticleData } from '../../utils/helperFunctions';
import classNames from 'classnames';
import MinMaxCycleDiscountDepth from './minMaxCycleDiscountDepthWrapper';
import ExpandCollapse from './qarterlyExpandCollapseComponent';

const initialQuarterObj = {1:[],2:[],3:[],4:[]}
class ConstraintsPopup extends Component {
  constructor(props) {
    super(props);
    let minDiscCycle=[];
    let maxDiscCycle=[];
    let fixedDiscCycle=[];
    const mandatoryValues = this.props.constraintData.mandatory;
    const excludedValues = this.props.constraintData.excluded;
    const discountDepth = this.props.constraintData.cycles
      .filter((obj) => obj.fixed !== null)
      .map((obj) => parseInt(obj.cycle.split('BBM')[1]));
    this.state = {
      loader: false,
      promoFatigue: this.props.constraintData.promoFatigue.map((obj) => {
        return { value: obj, label: obj };
      }),
      excluded: this.props.constraintData.excluded.map((obj) => {
        return { value: obj, label: obj };
      }),
      mandatory: this.props.constraintData.mandatory.map((obj) => {
        return { value: obj, label: obj };
      }),
      promoFatigueFixedOrMandatory:[],
      promoFatigueRelaxation:[],
      minDiscount: [],
      maxDiscount: [],
      fixedDiscount: [],
      minDiscountQuarterly: initialQuarterObj,
      maxDiscountQuarterly: initialQuarterObj,
      fixedDiscountQuarterly: initialQuarterObj,
      minDiscountCycle: [],
      maxDiscountCycle: [],
      fixedDiscountCycle: [],
      minDiscountCycleQuarterly: initialQuarterObj,
      maxDiscountCycleQuarterly: initialQuarterObj,
      fixedDiscountCycleQuarterly: initialQuarterObj,
      cycleOptions: [...cycleOptionsList],
      promoFatigueOptions: cycleOptionsList.map(item => Object.assign(item, {userMade: false})),
      excludedOptions: [...cycleOptionsList].filter(
        (obj) => mandatoryValues.concat(discountDepth).indexOf(obj.value) === -1
      ),
      mandatoryOptions: [...cycleOptionsList].filter(
        (obj) => excludedValues.indexOf(obj.value) === -1
      ),
      discountOptions: _.range(1, 21).map((obj) => {
        return { value: obj, label: obj };
      }),
      discountCycleOptions: [...discountCycleOptions],
      minCycleOptions: [...discountCycleOptions],
      maxCycleOptions: [...discountCycleOptions],
      fixedCycleOptions: [...discountCycleOptions].filter(
        (obj) => excludedValues.indexOf(parseInt(obj.value.split('BBM')[1])) === -1
      ),
      discountDepth: this.props.constraintData.cycles.filter((ele) => ele.quarter == 0),
      discountDepthQuarterly: this.props.constraintData.cycles.filter((ele) => ele.quarter != 0),
      minDiscountOptions: [],
      maxDiscountOptions: [],
      fixedDiscountOptions: [],
      error: '',
      changedDiscountDepth: [],
      activeDiscountChangedKey: '',
      minCycle: this.props.constraintData.minCycles,
      maxCycle: this.props.constraintData.maxCycles,
      minCycleError: '',
      maxCycleError: '',
      promoError: '',
      excludedCycleError: '',
      mandatoryCycleError: '',
      viewType:
        this.props.constraintData.selectedConstraintType.length > 1
          ? 'yearly'
          : this.props.constraintData.selectedConstraintType[0].toLowerCase(),
      selectedQuarter: '',
      quarterlyMinCycle: this.props.constraintData.minquarterly,
      quarterlyMaxCycle: this.props.constraintData.maxquarterly,
      isViewChanged: false,
      maxValueWarning: '',
      minValueWarning: '',
    };
  }

  updateState = (params) => {
    console.log("promo:",this.state.promoFatigue);
    if (params.hasOwnProperty('promoFatigue')) {
      const pfSelectedValues = params.promoFatigue;
      const updatedParams = {
        promoFatigue: pfSelectedValues.map(item => {
          item.userMade = true
          return item;
        })
      };
      this.setState(updatedParams)
      this.autoSelectCycle(updatedParams);
    } else {
      this.setState(params, () => {});
      this.autoSelectCycle(params);
    }
    if (params.hasOwnProperty('excluded')) {
      if (this.state.viewType == 'quarterly') {
        let minValue = this.state.quarterlyMinCycle[0] ? this.state.quarterlyMinCycle[0] : {};
        let isError = this.excludedValidateMinCycleQuarterly(minValue, params.excluded);
        isError.length > 0
          ? this.setState({ excludedCycleError: isError })
          : this.setState({ excludedCycleError: '' });
      } else {
        let isError = this.excludedValidateMinCycleYearly(this.state.minCycle, params.excluded);

        this.setState({ excludedCycleError: isError });
      }
    }
    if (params.hasOwnProperty('mandatory')) {
      if (this.state.viewType == 'quarterly') {
        let maxValue = this.state.quarterlyMaxCycle[0] ? this.state.quarterlyMaxCycle[0] : {};
        let isError = this.validateMandatoryCycleQuarterly(maxValue, params.mandatory);
        isError.length > 0
          ? this.setState({ mandatoryCycleError: isError })
          : this.setState({ mandatoryCycleError: '' });
      } else {
        let isError = this.validateMandatoryCycleYearly(this.state.maxCycle, params.mandatory);

        this.setState({ mandatoryCycleError: isError });
      }
    }
  };

  applyDiscountDepth = (resetObj, discountData) => {
    this.autoSelectDiscountDepth(resetObj);
    let params = {};
    const { selectedQuarter } = this.state;
    if (this.state.viewType === 'quarterly') {
      let tempData = { ...this.state[`${resetObj.mappingKey}Quarterly`] };
      let tempCycle = { ...this.state[`${resetObj.cycleMappingKey}Quarterly`] };
      let tempDiscountDepth = this.state.discountDepthQuarterly.filter(
        (ele) => ele.quarter != this.state.selectedQuarter || ele.quarter == 0
      );
      tempData[selectedQuarter] = [];
      tempCycle[selectedQuarter] = [];
      tempDiscountDepth.push(...discountData);
      params[`${resetObj.mappingKey}Quarterly`] = tempData;
      params[`${resetObj.cycleMappingKey}Quarterly`] = tempCycle;
      params.discountDepthQuarterly = tempDiscountDepth;
    } else {
      params[`${resetObj.mappingKey}`] = [];
      params[`${resetObj.cycleMappingKey}`] = [];
      params.discountDepth = discountData;
    }
    // discountData.forEach((data) => {
    //   if (parseFloat(data.max) < parseFloat(data.min) && error.length === 0) {
    //     error = 'Maximum Discount depth should be greater than Minimum Discount depth';
    //   }
    // });
    // this.setState({ error });
    if (this.state.error.length === 0) {
      this.setState(params);
    }
  };

  groupActualPopUpData = async (cycle) => {
    const reqData = {
      articleNo: this.props.constraintData.selectedArticles,
      cycle,
    };
    let groupArticleData = [],
      cmndd = [];
    const data = await fetchGroupDiscountDepth(reqData);
    groupArticleData = formatGroupArticleData(data);
    if (groupArticleData.length > 0) {
      let ddArr = groupArticleData.map((ele) => {
        return ele.discountDepth;
      });
      cmndd = ddArr.reduce((p, c) => p.filter((e) => c.includes(e)));
    }
    return cmndd;
  };

  discountValues = async (params) => {
    const { viewType, selectedQuarter } = this.state;
    let tempCycle =
      viewType === 'quarterly'
        ? [...this.state[`${params.cycleMappingKey}Quarterly`][selectedQuarter]]
        : [...this.state[params.cycleMappingKey]];
    let cycleState = _.isEqual(tempCycle, params.cycleState) ? params.cycleState : tempCycle;
    if (cycleState.length > 0) {
      this.setState({
        loader: true,
      });
      const cycle = cycleState.map((obj) => {
        return parseInt(obj.value.split('BBM')[1]);
      });
      const articleNo = this.props.constraintData.article;
      let actualPopUpData = [];
      if (articleNo[0] !== null) {
        actualPopUpData = await fetchDiscountDepthDropDown({
          articleNo,
          cycle,
        });
      } else {
        actualPopUpData = await this.groupActualPopUpData(cycle);
      }
      if (actualPopUpData.length > 0) {
        let dropdownData = {
          loader: false,
        };
        let discountDepth =
          articleNo[0] !== null
            ? _.uniq(actualPopUpData.map((item) => item.discountDepth))
            : actualPopUpData;
        discountDepth = discountDepth.sort((a, b) => {
          return parseFloat(a) - parseFloat(b);
        });
        dropdownData[`${params.mappingKey}Options`] = discountDepth.map((obj) => {
          return { value: parseFloat(obj).toFixed(2), label: parseFloat(obj).toFixed(2) };
        });
        dropdownData[`${params.mappingKey}Options`].unshift({ value: '-', label: '-' });
        this.setState(dropdownData);
      } else {
        this.setState({
          loader: false,
        });
      }
    }
  };
  closeModal = () => {
    this.setState(
      {
        loader: false,
        promoFatigue: [],
        excluded: [],
        mandatory: [],
        minDiscount: [],
        maxDiscount: [],
        fixedDiscount: [],
        minDiscountQuarterly: {},
        maxDiscountQuarterly: {},
        fixedDiscountQuarterly: {},
        minDiscountCycle: [],
        maxDiscountCycle: [],
        fixedDiscountCycle: [],
        minDiscountCycleQuarterly: {},
        maxDiscountCycleQuarterly: {},
        fixedDiscountCycleQuarterly: {},
        cycleOptions: [],
        discountOptions: [],
        discountCycleOptions: [],
        discountDepth: [],
        discountDepthQuarterly: [],
        minDiscountOptions: [],
        maxDiscountOptions: [],
        fixedDiscountOptions: [],
        isViewChanged: false,
        maxValueWarning: '',
        minValueWarning: '',
      },
      () => {
        this.props.closeModal();
      }
    );
  };
  getQuaterValue = (value) => {
    if (value < 6) return 1;
    if (value >= 6 && value <= 10) return 2;
    if (value >= 11 && value <= 15) return 3;
    return 4;
  };
  saveConstraints = () => {
    const tempdiscountDepth =
      this.state.viewType === 'yearly'
        ? this.state.discountDepth
        : this.state.discountDepthQuarterly;
    let cycles = tempdiscountDepth.map((obj) => {
      let matchedString = obj.cycle.match(/\d+/);
      let offerType = obj.cycle.slice(0, matchedString.index);
      let quarter = this.getQuaterValue(parseInt(matchedString[0]));
      let discount = {
        offerType: offerType,
        cycle: matchedString[0],
        min: obj.min === '-' ? null : obj.min,
        max: obj.max === '-' ? null : obj.max,
        fixed: obj.fixed === '-' ? null : obj.fixed,
        quarter: this.state.viewType === 'yearly' ? 0 : quarter,
      };
      return discount;
    });
    let minCycle = null;
    let maxCycle = null;

    if (this.state.viewType === 'yearly') {
      minCycle = !Array.isArray(this.state.minCycle)
        ? this.state.minCycle
        : this.state.minCycle.length > 0
        ? this.state.minCycle[0]
        : null;

      maxCycle = !Array.isArray(this.state.maxCycle)
        ? this.state.maxCycle
        : this.state.maxCycle.length > 0
        ? this.state.maxCycle[0]
        : null;
    }
    let params = {
      articleNo: this.props.constraintData.article,
      promoFatigueRelax: this.state.promoFatigue.map((obj) => obj.value),
      mandatoryCycles: this.state.mandatory.map((obj) => obj.value),
      excludedCycles: this.state.excluded.map((obj) => obj.value),
      cycle: cycles,
      minCycle,
      maxCycle,
      groupid: this.props.constraintData.groupid,
      maxpromosquarterly: this.state.quarterlyMaxCycle,
      minpromosquarterly: this.state.quarterlyMinCycle,
      selectedConstraintType: this.state.viewType.toUpperCase(),
    };
    this.props.saveConstraints(params);
  };
  
  getCombinedList = (mandatoryValueList, fixedDiscountList) => {
    let combinedList =[];
    if(mandatoryValueList ==undefined && fixedDiscountList == undefined)
      return combinedList;
    combinedList= mandatoryValueList.concat(fixedDiscountList);
    combinedList = combinedList.sort(function(a,b){
         return a-b;
     });
    return combinedList;
  };
  getPromoFatigueRelaxation =(mandatoryList, fixedDiscountList) =>{
      const combinedList = this.getCombinedList(mandatoryList, fixedDiscountList);
      console.log(combinedList);
      let promoFatigue=[];
      const consecutiveList = findConsecutive(combinedList);
      return consecutiveList;
  };
  updatePromoFatigueState(promoFatigueValues) {
    let consecutiveCycles = this.state.promoFatigueOptions.filter(item => promoFatigueValues.includes(item.value));
    console.log("consecutive:", consecutiveCycles);
    let promoFatigue = this.state.promoFatigue.concat(consecutiveCycles);
    promoFatigue = this.state.cycleOptions.filter(
      (obj) => promoFatigue.indexOf(obj.value) === -1);
    if(this.state.viewType != "quarterly"){
    promoFatigue = promoFatigue.filter(item => {
      if (item.userMade) {
        return true;
      } else {
        if (promoFatigueValues.includes(item.value)) {
          return true;
        }
      }
      return false;
    });
  }
    console.log("pf cycles:", promoFatigue);
    this.setState({promoFatigue:promoFatigue});
  }
  autoSelectCycle = (params) => {
    let key = Object.keys(params)[0];

    switch (key) {
      case 'promoFatigue':
        break;
      case 'excluded':
        const excluded = params[`excluded`].map((obj) => obj.value);

        const mandatoryCycles = this.state.cycleOptions.filter(
          (obj) => excluded.indexOf(obj.value) === -1
        );

        const fixedDiscountCycles = this.state.discountCycleOptions.filter(
          (obj) => excluded.indexOf(parseInt(obj.value.split('BBM')[1])) === -1
        );
        this.setState({
          mandatoryOptions: mandatoryCycles,
          fixedCycleOptions: fixedDiscountCycles,
        });
        break;
      case 'mandatory':
        const selectedMandatory = this.state.mandatory.map((obj) => obj.value);
        const mandatory = params[`mandatory`].map((obj) => obj.value);
        let differenceValues = _.difference(mandatory, selectedMandatory);
        console.log(differenceValues);
        const promoFatigueValues = this.getPromoFatigueRelaxation(mandatory, this.state.changedDiscountDepth);
        this.updatePromoFatigueState(promoFatigueValues);
        const excludedCycles = this.state.cycleOptions.filter(
          (obj) => mandatory.indexOf(obj.value) === -1);
        this.setState({
          excludedOptions: excludedCycles,
        })
        break;

      default:
        break;
    }
  };
  
  autoSelectDiscountDepth = (params) => {
    let key = params.mappingKey;

    switch (key) {
      case 'minDiscount':
        break;
      case 'maxDiscount':
        break;
      case 'fixedDiscount':
        const mandatory = this.state.mandatory.map((obj)=>obj.value);
        const fixedDiscountCycleList = this.state.fixedDiscountCycle.map((obj) =>
          parseInt(obj.value.split('BBM')[1])
        );
        const promoFatigueValues = this.getPromoFatigueRelaxation(mandatory, this.state.changedDiscountDepth);
        this.updatePromoFatigueState(promoFatigueValues);
        const excludedCycles = this.state.cycleOptions.filter(
            (obj) => mandatory.indexOf(obj.value) === -1);
          this.setState({
            excludedOptions: excludedCycles,
          })
          break;

      default:
        break;
    }
  };
  updateDiscountDepth = (params) => {
    let isError = false;
    let key = Object.keys(params)[0];
    params.activeDiscountChangedKey = Object.keys(params)[0];
    const { viewType, selectedQuarter } = this.state;
    let tempminDiscountCycle =
      viewType === 'quarterly'
        ? [...this.state.minDiscountCycleQuarterly[selectedQuarter]]
        : this.state.minDiscountCycle;
    let tempmaxDiscountCycle =
      viewType === 'quarterly'
        ? [...this.state.maxDiscountCycleQuarterly[selectedQuarter]]
        : this.state.maxDiscountCycle;
    let tempfixedDiscountCycle =
      viewType === 'quarterly'
        ? [...this.state.fixedDiscountCycleQuarterly[selectedQuarter]]
        : this.state.fixedDiscountCycle;
    let tempminDiscount =
      viewType === 'quarterly'
        ? [...this.state.minDiscountQuarterly[selectedQuarter]]
        : this.state.minDiscount;
    let tempmaxDiscount =
      viewType === 'quarterly'
        ? [...this.state.maxDiscountQuarterly[selectedQuarter]]
        : this.state.maxDiscount;
    let tempfixedDiscount =
      viewType === 'quarterly'
        ? [...this.state.fixedDiscountQuarterly[selectedQuarter]]
        : this.state.fixedDiscount;
    let tempDiscountDepth =
      viewType === 'quarterly' ? this.state.discountDepthQuarterly : this.state.discountDepth;
    if (params.hasOwnProperty('minDiscount')) {
      const minDiscountCycle = tempminDiscountCycle.map((obj) => obj.value);
      const maxDiscountCycle = tempmaxDiscountCycle.map((obj) => obj.value);
      const fixedDiscountCycle = tempfixedDiscountCycle.map((obj) => obj.value);
      const minDiscount = params.minDiscount.map((obj) => obj.value)[0];
      const maxDiscount =
        tempmaxDiscount.length > 0 ? tempmaxDiscount.map((obj) => obj.value)[0] : null;
      const fixedDiscount =
        tempfixedDiscount.length > 0 ? tempfixedDiscount.map((obj) => obj.value)[0] : null;
      minDiscountCycle.forEach((obj) => {
        if (maxDiscountCycle.indexOf(obj) !== -1) {
          if (maxDiscount && parseFloat(maxDiscount) < parseFloat(minDiscount)) {
            isError = true;
            params.error = 'Minimum Discount depth should be lesser than Maximum Discount depth';
          }
        }
        if (fixedDiscountCycle.indexOf(obj) !== -1) {
          if (
            (fixedDiscount && parseFloat(fixedDiscount) < parseFloat(minDiscount)) ||
           (maxDiscount && parseFloat(fixedDiscount) > parseFloat(maxDiscount))
          ) {
            isError = true;
            params.error =
              'Fixed discount depth should be between Min discount depth and Max discount';
          }
        }
      });
      tempDiscountDepth.forEach((data) => {
        if (
          minDiscountCycle.indexOf(data.cycle) !== -1 &&
          parseFloat(data.max) < parseFloat(minDiscount)
        ) {
          isError = true;
          params.error = 'Minimum Discount depth should be lesser than Maximum Discount depth';
        }
        if (
          minDiscountCycle.indexOf(data.cycle) !== -1 &&
          parseFloat(data.fixed) < parseFloat(minDiscount)
        ) {
          isError = true;
          params.error = 'Minimum Discount depth should be lesser than Fixed Discount depth';
        }
        const fixedDiscountCycle = tempfixedDiscountCycle.map((obj) => obj.value);
        const fixedDiscount = data.fixed;
        if (
          fixedDiscountCycle.indexOf(data.cycle) !== -1 &&
          (parseFloat(fixedDiscount) < parseFloat(data.min || 0) ||
            parseFloat(fixedDiscount) > parseFloat(data.max || 0))
        ) {
          isError = true;
          params.error =
            'Fixed discount depth should be between Min discount depth and Max discount';
        }
      });
    }
    if (params.hasOwnProperty('maxDiscount')) {
      const minDiscountCycle = tempminDiscountCycle.map((obj) => obj.value);
      const maxDiscountCycle = tempmaxDiscountCycle.map((obj) => obj.value);
      const fixedDiscountCycle = tempfixedDiscountCycle.map((obj) => obj.value);
      const minDiscount =
        tempminDiscount.length > 0 ? tempminDiscount.map((obj) => obj.value)[0] : 0;
      const maxDiscount = params.maxDiscount.map((obj) => obj.value)[0];
      const fixedDiscount =
        tempfixedDiscount.length > 0 ? tempfixedDiscount.map((obj) => obj.value)[0] : 0;
      maxDiscountCycle.forEach((obj) => {
        if (minDiscountCycle.indexOf(obj) !== -1) {
          if (parseFloat(maxDiscount) < parseFloat(minDiscount)) {
            isError = true;
            params.error = 'Minimum Discount depth should be lesser than Maximum Discount depth';
          }
        }
        if (fixedDiscountCycle.indexOf(obj) !== -1) {
          if (
            parseFloat(fixedDiscount) < parseFloat(minDiscount) ||
            parseFloat(fixedDiscount) > parseFloat(maxDiscount)
          ) {
            isError = true;
            params.error =
              'Fixed discount depth should be between Min discount depth and Max discount';
          }
        }
      });
      tempDiscountDepth.forEach((data) => {
        if (
          maxDiscountCycle.indexOf(data.cycle) !== -1 &&
          parseFloat(maxDiscount) < parseFloat(data.min)
        ) {
          isError = true;
          params.error = 'Minimum Discount depth should be lesser than Maximum Discount depth';
        }
        if (
          maxDiscountCycle.indexOf(data.cycle) !== -1 &&
          parseFloat(maxDiscount) < parseFloat(data.fixed)
        ) {
          isError = true;
          params.error = 'Maximum Discount depth should be greater than Fixed Discount depth';
        }
        // Fixed Discount

        const fixedDiscountCycle = tempfixedDiscountCycle.map((obj) => obj.value);
        const fixedDiscount = data.fixed;
        if (
          fixedDiscountCycle.indexOf(data.cycle) !== -1 &&
          (parseFloat(fixedDiscount) < parseFloat(data.min || 0) ||
            parseFloat(fixedDiscount) > parseFloat(maxDiscount || 0))
        ) {
          isError = true;
          params.error =
            'Fixed discount depth should be between Min discount depth and Max discount';
        }
      });
    }

    if (params.hasOwnProperty('fixedDiscount')) {
      const fixedDiscountCycle = tempfixedDiscountCycle.map((obj) => obj.value);
      const minDiscountCycle = tempminDiscountCycle.map((obj) => obj.value);
      const maxDiscountCycle = tempmaxDiscountCycle.map((obj) => obj.value);
      const fixedDiscount = params.fixedDiscount.map((obj) => obj.value)[0];
      const minDiscount =
        tempminDiscount.length > 0 ? tempminDiscount.map((obj) => obj.value)[0] : 0;
      const maxDiscount =
        tempmaxDiscount.length > 0 ? tempmaxDiscount.map((obj) => obj.value)[0] : 0;
      fixedDiscountCycle.forEach((obj) => {
        if (minDiscountCycle.concat(maxDiscountCycle).indexOf(obj) !== -1) {
          if (
            parseFloat(fixedDiscount) < parseFloat(minDiscount) ||
            (tempmaxDiscount.length > 0 && parseFloat(fixedDiscount) > parseFloat(maxDiscount))
          ) {
            isError = true;
            params.error =
              'Fixed discount depth should be between Min discount depth and Max discount';
          }
        }
      });

      tempDiscountDepth.forEach((data) => {
        // Fixed Discount

        if (
          fixedDiscountCycle.indexOf(data.cycle) !== -1 &&
          (parseFloat(fixedDiscount) < parseFloat(data.min || 0) ||
            (data.max && parseFloat(fixedDiscount) > parseFloat(data.max || 0)))
        ) {
          isError = true;
          params.error =
            'Fixed discount depth should be between Min discount depth and Max discount';
        }
      });
    }

    if (!isError) {
      params.error = '';
    }
    if (viewType === 'quarterly') {
      let tempKeyObj = { ...this.state[`${key}Quarterly`] };
      tempKeyObj[selectedQuarter] = params[key];
      let data = {
        [`${key}Quarterly`]: tempKeyObj,
        activeDiscountChangedKey: key,
        error: params.error,
      };
      this.setState(data);
    } else {
      this.setState(params, () => {
        console.log(params);
      });
    }
  };
  updateDiscountCycles = (params) => {
    let key = Object.keys(params)[0];
    let quarterKey = { ...this.state[`${key}Quarterly`] };
    let discoutQuarterly = { ...this.state[`${key.split('Cycle')[0]}Quarterly`] };
    const { viewType, selectedQuarter } = this.state;
    let tempData = viewType === 'quarterly' ? quarterKey[selectedQuarter] : this.state[`${key}`];
    let prevDiscounts = tempData.map((obj) => parseInt(obj.value.split('BBM')[1]));
    let updatedDiscount = params[`${key}`].map((obj) => parseInt(obj.value.split('BBM')[1]));
    const changedDiscountDepth = _.difference(updatedDiscount, prevDiscounts);
    params.changedDiscountDepth = changedDiscountDepth;
    params[`${key.split('Cycle')[0]}`] = [];
    if (viewType === 'yearly') {
      this.setState(params, () => {
        console.log(params);
      });
    } else {
      quarterKey[selectedQuarter] = params[key];
      discoutQuarterly[selectedQuarter] = [];
      let data = {
        [`${key}Quarterly`]: quarterKey,
        [`${key.split('Cycle')[0]}Quarterly`]: discoutQuarterly,
        changedDiscountDepth: changedDiscountDepth,
      };
      this.setState(data);
    }
  };

  validateMandatoryCycleYearly = (maxValue, mandatoryValues) => {
    //debugger;
    let max = maxValue;
    if(Array.isArray(maxValue)){
     max = maxValue.length>0 ? maxValue[0] : null
    }
    if (max && max < mandatoryValues.length) {
      return 'Mandatory Promos should not be greater than Max Promo';
    }
    return '';
  };

  validateMandatoryCycleQuarterly = (maxValue, mandatoryValues) => {
    let tempQuarterData = {};
    let errorQuarters = [];
    let tempData = mandatoryValues.map((ele) => ele.value);

    tempData.forEach((data) => {
      let quarter = this.getQuaterValue(data);
      tempQuarterData[quarter]
        ? (tempQuarterData[quarter] = tempQuarterData[quarter] + 1)
        : (tempQuarterData[quarter] = 1);
    });

    Object.keys(tempQuarterData).forEach((key) => {
      if (maxValue[key] && maxValue[key] < tempQuarterData[key]) {
        errorQuarters.push({ quarter: key });
      }
    });

    if (errorQuarters.length > 0) {
      let msg = 'Mandatory Promos should not be greater than Max Promo for';
      errorQuarters.forEach((ele) => {
        msg = `${msg} Quarter ${ele.quarter} `;
      });
      return msg;
    }
    return '';
  };

  selectMaxCycle = (value) => {
    let quarter = this.state.selectedQuarter;
    let min =
      this.state.viewType === 'yearly'
        ? this.state.minCycle
        : this.state.quarterlyMinCycle[0]
        ? this.state.quarterlyMinCycle[0][quarter]
        : null;

    if (min && value !== null && value < min) {
      this.setState({ promoError: 'Max Promo Value should be greater than Min promo' });
    } else if (value < 0) {
      this.setState({ maxCycleError: 'Max Promo Value cannot be less than zero' });
    } else {
      this.setState({ promoError: '' });
      this.setState({ maxCycleError: '' });
    }
    if (this.state.viewType === 'yearly') {
      
      if (value <= 20) {

        if (this.state.mandatory.length > 0) {
          let error = this.validateMandatoryCycleYearly(value, this.state.mandatory);
          this.setState({ mandatoryCycleError: error });
        }

        this.setState({
          maxCycle: value,
          maxValueWarning: '',
        });
      } else {
        this.setState({ maxValueWarning: `Max Promo value should be less than 21` });
      }
    } else {
      let data = {};
      if (this.state.quarterlyMaxCycle[0]) {
        data = this.state.quarterlyMaxCycle[0];
      }
      let quarterLength = quarterlyDiscountCycleOptions[quarter].length;
      if (value <= quarterLength) {
        data[quarter] = value;

        if (this.state.mandatory.length > 0) {
          let error = this.validateMandatoryCycleQuarterly(data, this.state.mandatory);
          this.setState({ mandatoryCycleError: error });
        }

        this.setState({ maxValueWarning: '' });
      } else {
        this.setState({
          maxValueWarning: `Max Promo value should be less than ${quarterLength + 1}`,
        });
      }

     
      this.setState({
        quarterlyMaxCycle: [data],
      });
    }
  };
  excludedValidateMinCycleYearly = (minValue, excludedValues) => {
    let tempLimit = 20 - excludedValues.length;
    if (minValue > tempLimit) return `Min Promo Value should be lesser than ${tempLimit + 1}`;

    return '';
  };

  excludedValidateMinCycleQuarterly = (minValue, excludedValues) => {
    let tempQuarterData = {};
    let errorQuarters = [];
    let tempData = excludedValues.map((ele) => ele.value);

    tempData.forEach((data) => {
      let quarter = this.getQuaterValue(data);
      tempQuarterData[quarter]
        ? (tempQuarterData[quarter] = tempQuarterData[quarter] + 1)
        : (tempQuarterData[quarter] = 1);
    });

    Object.keys(tempQuarterData).forEach((key) => {
      let tempLimit = quarterlyDiscountCycleOptions[key].length - tempQuarterData[key];
      if (minValue[key] && minValue[key] > tempLimit) {
        errorQuarters.push({ quarter: key, limit: tempLimit });
      }
    });

    if (errorQuarters.length > 0) {
      let msg = 'Min Promo Value for';
      errorQuarters.forEach((ele) => {
        msg = `${msg} Quarter ${ele.quarter} should be less than ${ele.limit + 1 } ,`;
      });
      return msg;
    }
    return '';
  };
  selectMinCycle = (value) => {
    let quarter = this.state.selectedQuarter;
    let max =
      this.state.viewType === 'yearly'
        ? Array.isArray(this.state.maxCycle)?this.state.maxCycle[0]:this.state.maxCycle
        : this.state.quarterlyMaxCycle[0]
        ? this.state.quarterlyMaxCycle[0][quarter]
        : null;
    if (max && value !== null && value > max) {
      this.setState({ promoError: 'Min Promo Value should be lesser than Max promo' });
    } else if (value < 0) {
      this.setState({ minCycleError: 'Min Promo Value cannot be less than zero' });
    } else {
      this.setState({ promoError: '' });
      this.setState({ minCycleError: '' });
    }
    if (this.state.viewType === 'yearly') {
     
      if (value < 21) {
        if (this.state.excluded.length > 0) {
          let isExcludedError = this.excludedValidateMinCycleYearly(value, this.state.excluded);
          this.setState({ excludedCycleError: isExcludedError });
        }
        this.setState({
          minCycle: value,
          minValueWarning: '',
        });
      } else {
        this.setState({ minValueWarning: `Min Promo value should be less than 21` });
      }
    } else {
      let data = {};
      if (this.state.quarterlyMinCycle[0]) {
        data = this.state.quarterlyMinCycle[0];
      }
      let quarterLength = quarterlyDiscountCycleOptions[quarter].length;
      if (value <= quarterLength) {
        data[quarter] = value;

        if (this.state.excluded.length > 0) {
          let isExcludedError = this.excludedValidateMinCycleQuarterly(data, this.state.excluded);
          this.setState({ excludedCycleError: isExcludedError });
        }

        this.setState({ minValueWarning: '' });
      } else {
        this.setState({
          minValueWarning: `Min Promo value should be less than ${quarterLength + 1}`,
        });
      }
     
      this.setState({
        quarterlyMinCycle: [data],
      });
    }
  };

  handleToggleChange = (view) => {
    this.setState({
      viewType: view,
      isViewChanged: true,
      promoError: '',
      minCycleError: '',
      minValueWarning: '',
      maxValueWarning: '',
    });
  };

  handleQuarterSelection = (value) => {
    let quarter = value;
    const excludedValues = this.props.constraintData.excluded;
    let min =
      this.state.viewType === 'yearly'
        ? this.state.minCycle
        : this.state.quarterlyMinCycle[0]
        ? this.state.quarterlyMinCycle[0][quarter]
        : null;
    let max =
      this.state.viewType === 'yearly'
        ? this.state.maxCycle
        : this.state.quarterlyMaxCycle[0]
        ? this.state.quarterlyMaxCycle[0][quarter]
        : null;

    if (min && max && min > max) {
      this.setState({ promoError: 'Min Promo Value should be lesser than Max promo' });
    } else {
      this.setState({ promoError: '' });
    }
    this.setState({
      selectedQuarter: quarter,
      minValueWarning: '',
      maxValueWarning: '',
    });
  };
  render(){
    const CYCLES_LIST = [
      {
        label: 'Promo Fatigue Relaxation',
        mappingKey: 'promoFatigue',
        //state: (this.state.mandatory.length<=0 && this.state.changedDiscountDepth.length<=0) ? this.state.promoFatigue : this.state.promoFatigueFixedOrMandatory,
        state: this.state.promoFatigue,
        options: this.state.promoFatigueOptions,
      },
      {
        label: 'Excluded Cycles',
        mappingKey: 'excluded',
        state: this.state.excluded,
        options: this.state.excludedOptions,
      },
      {
        label: 'Mandatory Cycles',
        mappingKey: 'mandatory',
        state: this.state.mandatory,
        options: this.state.mandatoryOptions,
      },
    ];

    const {
      viewType,
      selectedQuarter,
      minDiscountCycleQuarterly,
      minDiscountQuarterly,
      maxDiscountQuarterly,
      fixedDiscountQuarterly,
      maxDiscountCycleQuarterly,
      fixedDiscountCycleQuarterly,
    } = this.state;
    const DISCOUNT_DEPTH_LIST = [
      {
        label: 'Min Discount Depth',
        mappingKey: 'minDiscount',
        cycleMappingKey: 'minDiscountCycle',
        placeholder: 'Min Value',
        state:
          viewType === 'quarterly' && selectedQuarter.length > 0
            ? minDiscountQuarterly[selectedQuarter]
            : this.state.minDiscount,
        cycleState:
          viewType === 'quarterly' && selectedQuarter.length > 0
            ? minDiscountCycleQuarterly[selectedQuarter]
            : this.state.minDiscountCycle,
        options: this.state.minDiscountOptions,
        cycleOptions: this.state.minCycleOptions,
      },
      {
        label: 'Max Discount Depth',
        mappingKey: 'maxDiscount',
        cycleMappingKey: 'maxDiscountCycle',
        placeholder: 'Max Value',
        state:
          viewType === 'quarterly' && selectedQuarter.length > 0
            ? maxDiscountQuarterly[selectedQuarter]
            : this.state.maxDiscount,
        cycleState:
          viewType === 'quarterly' && selectedQuarter.length > 0
            ? maxDiscountCycleQuarterly[selectedQuarter]
            : this.state.maxDiscountCycle,
        options: this.state.maxDiscountOptions,
        cycleOptions: this.state.maxCycleOptions,
      },
      {
        label: 'Fixed Discount Depth',
        mappingKey: 'fixedDiscount',
        cycleMappingKey: 'fixedDiscountCycle',
        placeholder: 'Fixed Value',
        state:
          viewType === 'quarterly' && selectedQuarter.length > 0
            ? fixedDiscountQuarterly[selectedQuarter]
            : this.state.fixedDiscount,
        cycleState:
          viewType === 'quarterly' && selectedQuarter.length > 0
            ? fixedDiscountCycleQuarterly[selectedQuarter]
            : this.state.fixedDiscountCycle,
        options: this.state.fixedDiscountOptions,
        cycleOptions: this.state.fixedCycleOptions,
      },
    ];
    const { article } = this.props.constraintData;
    const minMaxProps = {
      discountDepthList: { DISCOUNT_DEPTH_LIST },
      updateDiscountDepth: this.updateDiscountDepth,
      updateDiscountCycles: this.updateDiscountCycles,
      discountCycleOptions: this.state.discountCycleOptions,
      discountDepth:
        viewType === 'quarterly' ? this.state.discountDepthQuarterly : this.state.discountDepth,
      applyDiscountDepth: this.applyDiscountDepth,
      discountValues: this.discountValues,
      error: this.state.error,
      activeDiscountChangedKey: this.state.activeDiscountChangedKey,
      promoError: this.state.promoError,
      minCycleError: this.state.minCycleError,
      maxCycleError: this.state.maxCycleError,
      minCycle: this.state.minCycle,
      selectMinCycle: this.selectMinCycle,
      maxCycle: this.state.maxCycle,
      selectMaxCycle: this.selectMaxCycle,
      article: article,
      viewType: this.state.viewType,
      quarterlyMinCycle: this.state.quarterlyMinCycle,
      quarterlyMaxCycle: this.state.quarterlyMaxCycle,
      minValueWarning: this.state.minValueWarning,
      maxValueWarning: this.state.maxValueWarning,
    };
    return (
      <>
        <PageLoader loader={this.state.loader}>
          <Categories constraintData={this.props.constraintData} />
          <hr />
          <ConstarintCycleSelection
            cyclesList={CYCLES_LIST}
            updateState={this.updateState}
            options={this.state.cycleOptions}
          />
          <div className="row">
            <div className="col-2">Level of Constraints</div>
            <div className="text-right constraintsToggle">
              <div
                id="promoCal"
                className="divInn btn-group "
                style={{
                  textAlign: 'center',
                }}>
                <button
                  type="button"
                  className={classNames({
                    active: this.state.viewType == 'yearly',
                    ' btn': true,
                    'btn-outline-primary cal-btn': true,
                    btnCalendr: true,
                    calPromo: true,
                  })}
                  onClick={() => this.handleToggleChange('yearly')}
                  style={{
                    width: '90px',
                  }}>
                  Yearly
                </button>
                <button
                  type="button"
                  className={classNames({
                    active: this.state.viewType == 'quarterly',
                    ' btn': true,
                    'btn-outline-primary cal-btn': true,
                    btnCalendr: true,
                    calPromo: true,
                  })}
                  onClick={() => this.handleToggleChange('quarterly')}
                  style={{
                    width: '90px',
                  }}>
                  Quarterly
                </button>
              </div>
            </div>
          </div>
          <div className="spacer"></div>

          {this.state.isViewChanged && (
            <div className="warningMessage">
              Warning! - {this.state.viewType === 'yearly' ? 'Quarter' : 'Year'} Level constraints
              will be Ignored.
            </div>
          )}

          <div className="spacer"></div>

          {this.state.viewType === 'quarterly' && (
            <div className="warningMessage">
              Warning! - Promo fatigue constraints will be relaxed when min cycles are greater than
              3
            </div>
          )}

          <div className="spacer"></div>

          {this.state.excludedCycleError.length > 0 && (
            <div className="errorMessage">{this.state.excludedCycleError}</div>
          )}

          <div className="spacer"></div>

          {this.state.mandatoryCycleError.length > 0 && (
            <div className="errorMessage">{this.state.mandatoryCycleError}</div>
          )}
          {this.state.viewType === 'yearly' ? (
            <>
              <MinMaxCycleDiscountDepth minMaxProps={minMaxProps} selectedQuarter="0" />
            </>
          ) : (
            <ExpandCollapse
              minMaxProps={minMaxProps}
              handleQuarterSelection={this.handleQuarterSelection}
            />
          )}
          <div className="spacer"></div>
          <div>
            <span className="errorMessage">{this.state.error}</span>
            {article.length > 1 &&
            this.props.constraintData.overrideBulkConstraints.length > 0 &&
            this.state.excluded.length === 0 ? (
              <>
                <Alert key={2} variant="warning">
                  Warning! - Articles {this.props.constraintData.article.join(' ,')} will override
                  fixed discount depth
                </Alert>
              </>
            ) : null}

            {this.state.excluded.length > 0 &&
            this.props.constraintData.overrideBulkConstraints.length > 0 ? (
              <>
                <Alert key={2} variant="warning">
                  Warning! - Articles {this.props.constraintData.overrideBulkConstraints.join(' ,')}{' '}
                  will override the fixed discount depth
                </Alert>
              </>
            ) : null}
          </div>
          <hr />
          <div className="row">
            <div className="col-5"></div>
            <div className="col-2">
              <div className="row">
                <div className="col-6">
                  <button className="btn btn-primary resetButton" onClick={this.closeModal}>
                    {' '}
                    Cancel
                  </button>
                </div>
                <div className="col-6">
                  <button
                    className="btn btn-primary"
                    disabled={
                      this.state.error.length !== 0 ||
                      this.state.minCycleError.length !== 0 ||
                      this.state.maxCycleError.length !== 0 ||
                      this.state.promoError.length !== 0 ||
                      this.state.excludedCycleError.length !== 0 ||
                      this.state.mandatoryCycleError.length !== 0
                    }
                    onClick={this.saveConstraints}>
                    {' '}
                    Save
                  </button>
                </div>
              </div>
            </div>
            <div className="col-4"></div>
          </div>
        </PageLoader>
      </>
    );
  }
}

export default ConstraintsPopup;
