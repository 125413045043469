import { callApi } from "./config";
import { MESSAGES } from "../common/app-constants";
import { ToastMessage } from "../common/ToastMessage";
  
export const fetchModifySkuData = reqObj => {
  return callApi
    .post(`hierarchy/list`, reqObj)
    .then(res => {
      return res.data;
    })
    .catch(err => {
      return err;
    });
};

export const setBudgetPlanUpsert = reqObj => {
  const { CREATE_PLAN_SUCCESS } = MESSAGES;
  return callApi
    .post(`budgetplan/upsert`, reqObj)
    .then(res => {
      // showToast(res.status, CREATE_PLAN_SUCCESS)
      ToastMessage(res.status,CREATE_PLAN_SUCCESS)
       return res.data.data;
    })
    .catch(err => {
      return err;
    });
};

export const postSkuMasterSave = reqObj => {
  const { SUCCESS_MESSAGE } = MESSAGES;
  return callApi
  .post(`hierarchy/update/tag`,reqObj)
  .then(res => {
    // showToast(res.status, SUCCESS_MESSAGE)
    ToastMessage(res.status,SUCCESS_MESSAGE)
    return res.data.data })
  .catch(err => {
    ToastMessage(err.response.status,err.response.data.message)
      throw err;
    })
}

export const refSkuInfo = (reqObj) => {
  return callApi
  .post(`hierarchy/filters/articleNo`, reqObj)
  .then(res => {return res.data.data })
  .catch(err => err)
}

export const skuDetails = (reqObj) => {
  return callApi
  .post(`hierarchy/list`, reqObj)
  .then(res => {return res.data.data })
  .catch(err => err)
}


export const createSku = reqObj => {
  const { CREATE_SKU_SUCCESS } = MESSAGES;
  return callApi
  .post(`hierarchy/create/sku`,reqObj)
  .then(res => {
    ToastMessage(res.status,CREATE_SKU_SUCCESS)
    return res.data.data })
  .catch(err => err)
}
export const validateSkuId = reqObj => {
  return callApi
  .get(`hierarchy/checkIfSKUExist/${reqObj}`)
  .then(res => {return res.data.data })
  .catch(err => err)
}

export const updateSku = reqObj => {
  const { SUCCESS_MESSAGE } = MESSAGES;
  return callApi
  .post(`hierarchy/update/sku`,reqObj)
  .then(res => {
    ToastMessage(res.status,SUCCESS_MESSAGE)
    return res.data.data })
  .catch(err => err)
}

export const downloadSkuMasterData = reqObj => {
  return callApi
    .post("/hierarchy/downloadSku", reqObj)
    .then(res => {
      ToastMessage(res.status,MESSAGES.DOWNLOAD_SUCCESS_MESSAGE)
      return res.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      throw err;
    });
}