import React, { useState, useEffect } from "react";
import MultiSelectCheckboxes from "../../MultiSelectCheckboxes/index";
import isUndefined from "lodash/isUndefined";
import "../index.css";
import { InputGroup, FormControl, Form, Col, Row } from 'react-bootstrap';
import { AdminGA  , SKUMasterGA } from '../../../common/GAUtils';
const EditableDropDown = props => {
  const [isAdd, setIsAdd] = useState(false);
  const [value, setValue] = useState("");

  const [selectedOption, setSelectedOption] = useState([]);
  const [isInputDisabled, setInputDisabled] = useState(false)

  useEffect(() => {
    setInputDisabled(props.isInputDisabled);
  },[props.isInputDisabled]);

  useEffect(() => {
    setSelectedOption(props.selectedData);
  },[props.selectedData]);

  const updateSelectedOption = params => {
    const key = props.mappingKey;
    setSelectedOption(params[key]);
    props.handleSelectionChange({ key, value: params[key]})
  };
  

  const handleInputAction = add => {
    if (add) {
      if (!isUndefined(props.inputType) && props.inputType.test(value)) {
        setInputDisabled(true);
      } else {
        setInputDisabled(props.isInputDisabled)
      }
      props.handleSelectionChange({ key: props.mappingKey, value});
    } else {
      setValue("");
      setIsAdd(false);
      setInputDisabled(false);
      props.handleInputClose(props.mappingKey);
    }
  };
  const renderCheckbox = () => {
    switch (props.type) {
      case "select":
        return (
          <MultiSelectCheckboxes
            placeholderButtonLabel={props.placeholder}
            menuPortalTarget={document.querySelector("body")}
            options={props.options}
            value={selectedOption}
            mappingKey={props.mappingKey}
            defaultSelectedOptions={true}
            updateState={updateSelectedOption}
            isMulti={false}
          //clearable={true}
          //onClear={props.onClear}
          />
        );
      case "multiSelect":
        return (
          <MultiSelectCheckboxes
            placeholderButtonLabel={props.placeholder}
            menuPortalTarget={document.querySelector("body")}
            options={props.options}
            value={selectedOption}
            mappingKey={props.mappingKey}
            defaultSelectedOptions={props.selectedData}
            updateState={updateSelectedOption}
            clearable={true}
            onClear={props.onClear}
          />
        );
    }
  };

  const renderInput = () => {
    return(
      <div>
        <input
          type="text"
          className={
            props.editUser ?
            'col-md-6 editable-field':
            'col-md-8 editable-field'
          }
          value={value}
          placeholder={props.inputPlaceholder}
          disabled={isInputDisabled}
          onChange={val => {
            handleOnChange(val);
          }}
        />
        <span>
          <i
            class="fa fa-check-circle"
            title="Confirm"
            onClick={() => {
              handleInputAction(true);
            }}
          ></i>
        </span>
        <span>
          <i
            class="fa fa-times-circle"
            title="Close"
            onClick={() => {
              handleInputAction(false);
            }}
          ></i>
        </span>
      </div>
    );
  }

  const renderView = (obj) => {
    const msg = props.title ? props.title : "Add new Email Id";
    switch (props.viewType) {
      case 'verticle':
        return (
          <Form>
            <Form.Group controlId="exampleForm.ControlInput1">
              <Form.Label>
                <b>
                  {props.required ? (
                    <>
                      <span className="text-danger">*</span>{' '}
                      {!isAdd ? props.dropDownLabel : props.inputLabel}
                    </>
                  ) : !isAdd ? (props.dropDownLabel) : (props.inputLabel)}
                </b>
              </Form.Label>
              {!isAdd ? (
                <div className="row editable-dropdown">
                  {<div className={
                        props.editUser ? 
                        'col-md-6 dropdown' :
                        'col-md-10 dropdown'}
                    >{renderCheckbox()}
                   </div>
                  }
                  <span className="col-md-2" onClick={openAddNewOption}>
                    <i class="fa fa-plus-circle" title={msg}></i>
                  </span>
                </div>
              ) : (
                renderInput()
              )}
            </Form.Group>
          </Form>
        );
        break;
      case 'inline':
        return (
          <Form>
            <Form.Group as={Row} controlId='formPlaintextEmail'>
              <Form.Label column md='4'>
              <b>
                  {props.required ? (
                    <>
                      <span className="text-danger">*</span>{' '}
                      {!isAdd ? props.dropDownLabel : props.inputLabel}
                    </>
                  ) : !isAdd ? (props.dropDownLabel) : (props.inputLabel)}
                </b>
              </Form.Label>
              <Col md='8' className="">
                {!isAdd ? <div className="row editable-dropdown">{
                  <div className={
                    props.editUser ? 
                    'col-md-6 dropdown':
                    'col-md-10 dropdown'}
                  >{renderCheckbox()}
                  </div>}
                  <span className="col-md-3" onClick={openAddNewOption}>
                    <i class="fa fa-plus-circle" title={msg}></i>
                  </span></div>
                  : renderInput()}
              </Col>
            </Form.Group>
          </Form>
        );
        break;

      default:
        return (

          <Form>
            <Form.Group as={Row} controlId='formPlaintextEmail'>
              <Form.Label column md='4'>
                <b>
                  {props.required ? (
                    <>
                      <span className="text-danger">*</span>{' '}
                      {!isAdd ? props.dropDownLabel : props.inputLabel}
                    </>
                  ) : !isAdd ? (props.dropDownLabel) : (props.inputLabel)}
                </b>
              </Form.Label>
              <Col md='8'>
                {!isAdd ? <div className="row editable-dropdown">{
                  <div className={
                    props.editUser ? 
                    'col-md-6 dropdown' :
                    'col-md-10 dropdown'
                  }>{renderCheckbox()}
                  </div>}
                  <span className="col-md-3">
                    <i class="fa fa-plus-circle" onClick={openAddNewOption} title={msg}></i>
                  </span></div>
                : renderInput()}
              </Col>
            </Form.Group>
          </Form>
        );

        break;
    }
  }


  

  const openAddNewOption = () => {
    props.openAddNewOption();
    setIsAdd(true);
  };

  const handleOnChange = event => {
    setValue(event.target.value);
  };
  return (
    <div className="editable-drop-down">
      {renderView()}
    </div>
  );
};

export default EditableDropDown;
