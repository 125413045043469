import React, { useState } from 'react';

import { DragSwitch } from 'react-dragswitch';
import 'react-dragswitch/dist/index.css';

const OptimizationLevelSelector = (props) => {
  const [checked, setChecked] = useState(props.data.isvendorlevel === false?true:false)

  const handleChange = selection => {
    setChecked(selection);
    const dataObj = {
        rowData: props.data,
        selection
    }
    props.handleOptimizeLevelChange(dataObj);
  }

  return (
    props.data.brand === 'ALL BRANDS' ? 
    <DragSwitch checked={checked} onColor={'#44677b'} onChange={(e) => {
      handleChange(e)
    }
    }/>
    : null
  )
}

export default OptimizationLevelSelector;