import React, { Component, Fragment } from "react";
import BreadCrumb from "../BreadCrumb";
import SkuTable from "./Components/SkuTable";
import CascadingFilters from "../CascadingFilters";
import "./index.css";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import PageLoader from "../../utils/Loader";
import TableWrapper from "../common/TableWrapper";
import isEmpty from "lodash/isEmpty";
import { convertMillionsFormatter } from "../../utils/AgGridFormatters";
import {find, cloneDeep, remove, keyBy} from 'lodash';
import {
  getSkuDataTable,
  setSkuData,
  actionSaveUpdatedSkuMaster,
  actionResetSkuMaster,
  actionDownloadSkuMaster,
  setFiltersData,
  resetFiltersData
} from "../../actions/skuMasterAction";
import { fetchHierarchyData } from "../../apis/common.api.";
import PageWrapper from "../common/PageWrapper";
import { SKUMasterGA   } from '../../common/GAUtils';
const AidRenderComponent = props => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-around",
        alignItems: "center",
        color: " #44677b"
      }}
    >
    {props.agGridReact.props.isEditable ? (
      <>
      <Link to={"/vis/edit-sku/" + props.value}>
        <span onClick={()=>{ SKUMasterGA({ action: "Click", label:"Edit Sku"})}}>{props.value}</span>
      </Link>
      </>
    ) : (<>
        <span>{props.value}</span>
      </>)}
      
    </div>
  );
};

class SkuMaster extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loader: false,
      year: [],
      quarter: [],
      bbm: [],
      filterSelectedOptions: {
        merchcat: [],
        vendor: [],
        ah6: []
      },
      skuRowData: [],
      columnDefs: [
        {
          headerName: "Article ID",
          field: "skuid",
          sortable: true,
          cellRendererFramework: AidRenderComponent,
          cellStyle: params => ({
            color: "blue",
            textDecoration: "underline",
            cursor: "pointer"
          })
        },
        {
          headerName: "Article description",
          field: "description"
        },
        {
          headerName: "Vendor",
          field: "vendor"
        },
        {
          headerName: "Brand",
          field: "brand"
        },
        {
          headerName: "LY Revenue",
          field: "lyRevenue"
        },
        {
          headerName: "TY Revenue",
          field: "tyRevenue"
        },
        {
          headerName: "Tag",
          field: "tag",
          filter: false,
          headerComponentParams: { menuIcon: "" },
          cellRenderer: "tagRenderComponent",
          cellRendererParams: {
            isTagDisable: !this.props.userInfo.action.includes('save-sku'),
            changeTagStatus: this.changeTagStatus
          }
        },
        {
          headerName: "LY Funding",
          field: "lyfunding",
          filter: false,
          headerComponentParams: { menuIcon: "" },
          cellRenderer:"toggleSelector",
          cellRendererParams: {
            changeToggleStatus: this.changeToggleStatus
          }
        },   
         
      ],
      defaultColDef: {
        headerComponentParams: { menuIcon: "ag-icon ag-icon-filter" },
        filter: true,
        sortable: true,
        width: 135,
        resizable: true
      },
      isPaginationActive: false,
      tempSkuData: []
    };
  }

  openLoader = () => {
    this.setState({
      loader: true
    });
  };
  closeLoader = () => {
    this.setState({
      loader: false
    });
  };
  onSelectionChanged(event) {
    var rowCount = event.api.getSelectedNodes();
    this.selrow = rowCount;
  }
  componentDidMount() {
    if(this.props.location.state === undefined){
      this.props.setFiltersData({
        vendor: [],
        ah6: [],
        merchcat: []
      });
      this.props.actionResetSkuMaster();
      
    }else{
      if(this.props.prevStateSelectedFilters.merchcat.length > 0 ){
        this.setState(
          {
            filterSelectedOptions: {
              merchcat: this.props.prevStateSelectedFilters.merchcat.map(obj=> obj.label),
              ah6: this.props.prevStateSelectedFilters.ah6.map(obj=> obj.label),
              vendor: this.props.prevStateSelectedFilters.vendor.map(obj=> obj.label)
            }
          }
       ,()=>{
          this.refershSkuData(this.state.filterSelectedOptions);
       })
      }
      
    }
}
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.skuData && nextProps.skuData.data !== prevState.skuRowData) {
      const rowData = nextProps.skuData.data.map((tempItem) => {
        Object.keys(tempItem).forEach((key) => {
          typeof tempItem[key] === "number"
            ? (tempItem[key] = convertMillionsFormatter(tempItem[key]))
            : (tempItem[key] = tempItem[key]);
        });
        return tempItem;
      });
      return { skuRowData: rowData };
    }
  }
  componentWillUnmount() {
    // this.props.actionResetSkuMaster();
  }
  

  getSelectedDropdownValues =()=>{
      return {
        merchcat: this.state.filterSelectedOptions.merchcat.map(obj=>{ return { value: obj, label: obj}}),
        ah6: this.state.filterSelectedOptions.ah6.map(obj=>{ return { value: obj, label: obj}}),
        vendor: this.state.filterSelectedOptions.vendor.map(obj=>{ return { value: obj, label: obj}})
      }
  }

  changeTagStatus = (data) => {
    const newData = this.state.tempSkuData.filter(
      (row) => row.articleNo !== data.skuid
    );
    const { merchcat } = this.state.filterSelectedOptions;
    const changedData = {
      articleNo: data.skuid,
      tag: data.tag.value,
      lyfunding: data.tag.value ? data.tag.value : data.lyfunding,
      vendor: data.vendor,
      brand: data.brand,
      oldLyFunding: data.oldLyFunding,
      category: merchcat[0]
    };
    newData.push(changedData);
    const updatedSkurowdata = this.state.skuRowData.map((row) => {
      if (row.skuid == data.skuid) {
        row.tag = data.tag.value;
        row.lyfunding = data.tag.value ? data.tag.value : data.lyfunding;
      }
      return cloneDeep(row);
    });
    this.setState({
      tempSkuData: newData,
      skuRowData: updatedSkurowdata,
    });
  };
  changeToggleStatus = (data) => {
    const newData = this.state.tempSkuData.filter(
      (row) => row.articleNo !== data.skuid
    );
    const { merchcat } = this.state.filterSelectedOptions;
    const changedData = {
      articleNo: data.skuid,
      tag: data.tag,
      lyfunding: data.lyfunding,
      vendor: data.vendor,
      brand: data.brand,
      oldLyFunding: data.oldLyFunding,
      category: merchcat[0]
    };
    newData.push(changedData);
    const updatedSkurowdata = this.state.skuRowData.map((row) => {
      if (row.skuid == data.skuid) {
        row.tag = data.tag.value;
        row.lyfunding = data.tag.value ? data.tag.value : data.lyfunding;
      }
      return cloneDeep(row);
    });
    this.setState({
      tempSkuData: newData,
      skuRowData: updatedSkurowdata,
    });
  };

  saveSkuMaster = async () => {
    this.submitGA("Click","Saved sku master list")
    const { tempSkuData } = this.state;
    const reqObj = {
      skuArray: tempSkuData
    };
    await this.props.actionSaveUpdatedSkuMaster(reqObj);
    this.setState({ tempSkuData: [] });
    this.refershSkuData(this.state.filterSelectedOptions);
  };

  refershSkuData = async(filterSelectedOptions) => {
    // try {
    //   const reqObj = {
    //     merchcat: filterSelectedOptions['merchcat']
    //   }
      // const data = await fetchHierarchyData('vendor', reqObj);
      // if(data){
      //   console.log("vendor data:",data)
      //   filterSelectedOptions['vendor']=data//this.props.vendorData.map(obj=>obj.label);
      // }
      console.log("Filter data:",filterSelectedOptions);
      this.props.getSkuData({
        sendInactive: true,
        ...filterSelectedOptions
      });
    // } catch (err) {
    //   console.log(err)
    //   //dispatch({ type: SET_LOADER, payload: false });
    // }
    
  };

  filterData = filterSelectedOptions => {
    this.submitGA("Click","Filter Button");
    this.setState({
      filterSelectedOptions
    },()=>{  
      this.props.setFiltersData(this.getSelectedDropdownValues());
    });
    this.refershSkuData(filterSelectedOptions);
   
  };
  onReset = () => {
    this.submitGA("Click","Reset Button");
    this.setState({
      filterSelectedOptions: {
        vendor: [],
        ah6: [],
        merchcat: []
      }
    },()=>{
      
      this.props.setFiltersData(this.getSelectedDropdownValues());
    });
    this.props.actionResetSkuMaster();
    
  };

  handleDownload = () => {
    this.submitGA("Click","Download Sku Master list");
    const reqObj = {
      sendInactive: true,
      ...this.state.filterSelectedOptions
    };

    this.props.actionDownloadSkuMaster(reqObj);
  };
  
  submitGA(action,label){
      SKUMasterGA({ action, label});
  }
  resetPrevSelectedFilters = ()=>{
    
    this.props.resetFiltersData({
      merchcat:[],
      ah6:[],
      vendor:[]
    });
  }

  render() {
    const { merchcat, vendor, ah6 } = this.state.filterSelectedOptions;
    const {state } = this.props.location;
    return (
      <PageLoader loader={this.state.loader || this.props.loader}>
      <PageWrapper>
        <div className="sku-master-container">
          <h4 className="promoHeading mb-1 color-blue">
            <BreadCrumb root="" child="SKU Master" />
          </h4>
          <div className="row filter-block">
            <CascadingFilters
              openLoader={this.openLoader}
              closeLoader={this.closeLoader}
              filterData={this.filterData}
              onReset={this.onReset}
              isFilterButtonRequired={true}
              disabledApiCall={state=== undefined ? false : true}
              prevStateSelectedFilters={state=== undefined ? {} : this.props.prevStateSelectedFilters }
              isFilterButtonDisabled={merchcat.length ===0 || vendor.length === 0 || ah6.length === 0}
              screenName="SKU Master"
              resetPrevSelectedFilters={this.resetPrevSelectedFilters}
            />
          </div>

          <div className="row" style={{ padding: "5px 7px" }}>
            <div className="col-md-6 skuMasterDownload">
              <div className="promolist-header">
                <label className="pipelineLabel downloadLbel">
                  SKU Master List
                </label>
              </div>
              <button
                className="btn btn-primary iconbutton download-btn"
                onClick={this.handleDownload}
                disabled={isEmpty(merchcat)}
              >
                <span>
                  <i class="fa fa-download" aria-hidden="true" />
                </span>
              </button>
            </div>
            <div className="col-md-6">
            {this.props.userInfo.action.indexOf('create-sku')!==-1 ? (
              <>
              <Link
                to={{
                  pathname: "/vis/add-sku",
                  state: {
                    merchcat: this.state.filterSelectedOptions.merchcat
                  }
                }}
              >
                <button
                  className="pull-right btn btn-primary filterBtn my-3"
                  disabled={isEmpty(merchcat)} onClick={()=>{
                    this.submitGA("Click","Create New Sku");
                  }}
                >
                  Add new SKU
                </button>
              </Link>
              </>
            ): null}
              
            </div>
          </div>
          {this.props.skuData.data.length > 0 && (
            <div>
              <div>
                <SkuTable
                  tempSkuData={this.state.tempSkuData}
                  columnDefs={this.state.columnDefs}
                  defaultColDef={this.state.defaultColDef}
                  rowData={this.state.skuRowData}
                  isPaginationActive={this.state.isPaginationActive}
                  setSkuData={this.props.setSkuData}
                  totalCount={this.props.skuData.totalCount}
                  pageGroupNo={this.props.skuData.pageGroupNo}
                  pageGroupSize={this.props.skuData.pageGroupSize}
                  isEditable ={ this.props.userInfo.action.indexOf('edit-sku')!==-1}
                />
                <div className="row">
                {this.props.userInfo.action.indexOf('save-sku')!==-1 ? (
                  <>
                  <div className="col-md-11 mx-auto text-right">
                  <button
                    className="btn btn-primary filterBtn my-3"
                    onClick={this.saveSkuMaster}
                  >
                    Save
                  </button>
                </div>
                  </>
                ) : null }

                </div>
              </div>
            </div>
          )}
        </div>
        </PageWrapper>
      </PageLoader>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.budgetPlan.loading,
    skuData: state.skuMasterData.skuData,
    prevStateSelectedFilters: state.skuMasterData.prevStateSelectedFilters,
    userInfo: state.userManagementData.userInfo,
    loader: state.skuMasterData.loading,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getSkuData: val => dispatch(getSkuDataTable(val)),
    setSkuData: value => dispatch(setSkuData(value)),
    actionSaveUpdatedSkuMaster: value => dispatch(actionSaveUpdatedSkuMaster(value)),
    actionResetSkuMaster: () => dispatch(actionResetSkuMaster()),
    actionDownloadSkuMaster: val => dispatch(actionDownloadSkuMaster(val)),
    setFiltersData: val => dispatch(setFiltersData(val)),
    resetFiltersData: val => dispatch(resetFiltersData(val))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SkuMaster);
