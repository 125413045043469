import React, { Fragment, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "./createPromo.css";
import InputButton from "./ParametersInputCol";
import RadioButtons from "./parametersRadioCol";
import { CreatePromoGA } from '../../common/GAUtils';
const removeButton = props => {
  return (
    <div className="remove-buttons" disabled={props.promoStatus === "APPROVED"}>
      <i
        class="fa fa-times-circle-o"
        aria-hidden="true"
        onClick={() =>
          props.handleRowDeletion(props.data, props.agGridReact.props.rowData)
        }
      ></i>
    </div>
  );
};
const Parameters = props => {
  const [rowData, setRowData] = useState([]);
  const [gridApi, setGridApi] = useState({});
  // const [error, setError] = useState("");
  // const handleFocus = args => {
  //   const { value, data, tableData } = args;
  //     const tempData = tableData.map(row => {
  //       if (row.id === data.id) {
  //         row.promoValue = value;
  //       }
  //       return row;
  //     });
  //     const promoValues = tempData.map(row => {
  //       return row.promoValue;
  //     });
  //     const regularPriceval = props.articleNumbers.map(data => {
  //       return {
  //         regularPrice: data.regularPrice 

  //       };
  //     });
  //     console.log(promoValues,"promoooooooooooo")

  //     var regMin = regularPriceval.filter((v) => {
  //      return  (v.regularPrice !== null && v.regularPrice !== 0)
  //     })
  //       console.log(regMin,"reglmin")
  //       var min = Math.min.apply(null, regMin.map((a) => a.regularPrice)) 
  //       console.log(min,"minval")
  //       var target = (promoValues.forEach(a => {
  //         if(a > min) {
  //           props.handlePromoValueError("Target Promotion value cannot be greater than Regular price");
  //         }
  //       }))
  // }
  const { handlePromoValueError, error, articleNumbers, promoStatus } = props;

  const handleRowDeletion = (data, rowData) => {
    submitGA("Click","delete discount promo");
    let tempRowData = rowData.filter(row => {
      return row.id !== data.id;
    });
    // setRowData(tempRowData);
    const duplicateCount = tempRowData.reduce((count, el) => {
      if (el.promoValue === data.promoValue) count++;
      return count;
    }, 0);
    if (duplicateCount === 1 || duplicateCount === 0) {
      props.handlePromoValueError("");
    }
    props.addPromoValues(tempRowData);
  };

  const handlePromoValueChange = args => {
    submitGA("Click","Target promo value changed");
    const { value, data, tableData } = args;
    const tempData = tableData.map(row => {
      if (row.id === data.id) {
        row.promoValue = value;
      }
      return row;
    });
    const promoValues = tempData.map(row => {
      return row.promoValue;
    });
    const result = promoValues.some((element, index) => { return promoValues.indexOf(element) !== index });
    // setRowData(tempData);
    if (!result) {
      props.handlePromoValueError("");
    }
    props.addPromoValues(tempData);
  };

  const selectPromo = args => {
    submitGA("Click","changed default discount");
    const { isCheck, data, tableData } = args;
    const updateRowData = tableData.map(row => {
      if (row.id === data.id) {
        row.isChecked = isCheck;
      } else {
        row.isChecked = false;
      }
      return row;
    });
    // setRowData(updateRowData);
    props.addPromoValues(updateRowData);
    if (gridApi) {
      gridApi.redrawRows();
    }
  };

  const columnDefs = [
    {
      headerName: "Set as default discount",
      field: "id",
      width: 200,
      cellRenderer: "radioButton",
      cellRendererParams: {
        selectPromo,
        promoStatus
      }
    },
    {
      headerName: "Target Promotion Value",
      field: "promoValue",
      width: 200,
      cellRenderer: "inputButton",
      cellRendererParams: {
        handlePromoValueChange,
        handlePromoValueError,
        articleNumbers,
        error,
        promoStatus
      }
    },
    {
      headerName: "",
      field: "id",
      width: 200,
      cellRenderer: "deleterow",
      cellRendererParams: {
        handleRowDeletion
      }
    }
  ];
  const onGridReady = (params) => {
    setGridApi(params.api);
  }


  const addPromoValues = () => {
    submitGA("Click","Add Promo value");
    let tempData = [...props.targetPromoValueArr];
    const id = Math.floor(Math.random() * Math.floor(100000));
    const newRow =
      tempData.length > 0
        ? {
          isChecked: false,
          promoValue: 0,
          id
        }
        : {
          isChecked: true,
          promoValue: 0,
          id
        };
    tempData.push(newRow);
    // setRowData(tempData);
    props.addPromoValues(tempData);
  };
  const submitGA =(action,label) =>{
    CreatePromoGA({ action, label});
  }
  return (
    <>
      <div className="row">
        <div className="col-md-10">
          <p>
            <b>Parameters</b> (To simulate at different scenarios)
          </p>
        </div>
      </div>
      <div className="row">
        <div className="col-md-3 col-sm-3"></div>
        <div className="col-md-6 col-sm-6">
          <div style={{ margin: 'auto', width: '602px' }}>

            <div className="ag-theme-balham" style={{ width: "100%" }}>
              <AgGridReact

                columnDefs={columnDefs}
                // rowData={rowData}
                rowData={props.targetPromoValueArr}
                domLayout="autoHeight"
                suppressCellSelection={true}
                onGridReady={onGridReady}
                frameworkComponents={{
                  radioButton: RadioButtons,
                  inputButton: InputButton,
                  deleterow: removeButton
                }}
                rowHeight="50"
                enableCellTextSelection={true}
              />
            </div>
            <div>
              <span className="promoError">{error}</span>
            </div>
            <div style={{ textAlign: "right" }}>
              <button
                className="btn btn-primary filterBtn my-3"
                onClick={addPromoValues}
                disabled={props.promoStatus === "APPROVED" || (props.selectedMerchcat.length === 0 || props.articleNumbers.length === 0)}
              >
                <span className="fa fa-plus"></span> Add Promo Value
              </button>
            </div>
          </div>
        </div>
      </div>
      <hr className="dotted-line" style={{ marginTop: "24px" }} />
    </>
  );
};

export default Parameters;
