import React, { Component, useState, useEffect, useRef, createRef } from "react";
import { withRouter } from "react-router-dom";
import PageLoader from "../../utils/Loader";
import { connect } from "react-redux";
import MultiSelectCheckboxes from '../MultiSelectCheckboxes';
import { ToastMessage } from "../../common/ToastMessage";
import { Card, Form, Row, Col } from 'react-bootstrap';
import { openLoader, closeLoader } from '../../actions/promo';
import { fetchCampaignList, uploadFile, viewLogs,downloadInputTemplate } from './promoUploadAction';
import LogsTable from './LogsTable';
class PromoUpload extends Component {
    constructor(props) {
        super(props);
        this.hiddenFileInput = createRef(null);
        this.state = {
            campaignsList: [],
            selectedFile: '',
            logs: false,
            showModal:false
        }
    }
    componentDidMount() {
        this.props.getCampaignsList({ future: 'current', name: 'BBM' });
    }
    componentDidUpdate(oldProps,oldState){
        if(oldProps.uploadData!=this.props.uploadData){
            console.log("Uploaded data:",this.props.uploadData);
            const data=this.props.uploadData;
            window.open(data.outputUrl, '_self');
        }
    }
    getSelectedBooks = (books) => {
        this.setState({
            campaignsList: books
        })
    }
    updateState = (params) => {
        console.log(params);
        this.getSelectedBooks(params.campaign);
    }
    handleClick = event => {
        this.hiddenFileInput.current.click();
    };
    handleChange = (event) => {
        const fileUploaded = event.target.files[0];
        var extension=fileUploaded.name.split('.').pop();
        if(extension!=='xlsx' && extension!='xls'){
            ToastMessage(403,"File type not supported. Please upload a xlsx file.");
            event.target.value = '';
            return;
        }
        const bookSelected = this.state.campaignsList[0].value;
        const data = new FormData();
        data.append('file', fileUploaded, fileUploaded.name);
        data.append('campaignName', bookSelected);
        const reqObj = {
            fileData: data,
            campaignName: bookSelected
        }
        this.props.uploadFile(data);
        event.target.value = '';
    };
    viewLogs = () => {
        this.setState({
            logs: true,
            showModal:true
        })
        this.props.openLoader();
        this.props.viewLogs();
        this.props.closeLoader();
    }
    closeLogsModal=()=>{
        this.setState({
            showModal:false
        });
    }
    
    downloadInputTemplate = () => {
        this.props.openLoader();
        this.props.downloadInputTemplate();
        this.props.closeLoader();
    }
    render() {
        return (
            <PageLoader loader={this.props.loader}>
                <div>
                    <div className="card CalendarMain promoCalendarDiv1" id="promocalendar-maincontainer">
                        <div className="row">
                            <div className="col-4">
                                <h4 className="promoCal-brdcrm mb-10">
                                    <span className="font-bold">Promo PMR Upload</span>
                                </h4>
                            </div>
                        </div>
                        <div className="row filter-block">
                            <div className="col-md-3">
                                <div className="form-group">
                                    <label className="form-label"><b>Book(s)</b></label>
                                    <MultiSelectCheckboxes
                                        placeholderButtonLabel="Select Book"
                                        options={this.props.campaignsList}
                                        value={this.state.campaignsList}
                                        mappingKey="campaign"
                                        updateState={this.updateState}
                                        isMulti={false}
                                        defaultSelectedOptions={true}
                                    />
                                </div>
                            </div>
                            <div className='row' style={{ paddingTop: "34px" }}>
                                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                                    <button
                                        type="file" name="file"
                                        className='btn btn-primary iconbutton download-btn'
                                        onClick={this.handleClick}
                                        disabled={this.state.campaignsList && this.state.campaignsList.length>0? false: true}
                                    >
                                    <span>
                                            <i className='fa fa-upload' />&nbsp; Upload Input
                                    </span>
                                    </button>
                                    <input type="file" name="file" ref={this.hiddenFileInput}
                                        onChange={this.handleChange}
                                        style={{ display: 'none' }} />
                                </div>
                                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                                    <button
                                        className='btn btn-primary iconbutton download-btn'
                                        onClick={() => this.downloadInputTemplate()}
                                        // disabled={this.state.campaignsList && this.state.campaignsList.length>0? false: true}
                                    >
                                    <span>
                                        <i className='fa fa-download' />&nbsp; Download Input Template
                                    </span>
                                    </button>
                                </div>
                                <div style={{ marginLeft: "20px", marginRight: "20px" }}>
                                    <button
                                        className='btn btn-primary resetButton'
                                        onClick={this.viewLogs}
                                    >
                                    <span>
                                        <i className='fa fa-file' />&nbsp; View Logs
                                    </span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div>
                            <p>* Output file will be downloaded automatically after processing the input file</p>
                            {this.state.logs && this.state.showModal ? 
                            <LogsTable 
                                logsData={this.props.logsData}
                                books={this.state.campaignsList}
                                closeLogsModal={this.closeLogsModal}
                                initialColDef={this.props.initialColumns}
                                initialRowData={this.props.initialRows}
                            /> : null}
                        </div>
                    </div>
                </div>
            </PageLoader>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loader: state.promoUploadData.loader,
        campaignsList: state.promoUploadData.campaignsList,
        logsData: state.promoUploadData.logsData,
        initialColumns: state.promoUploadData.initialColumns,
        initialRows:state.promoUploadData.initialRows,
        uploadData: state.promoUploadData.uploadData
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        openLoader: (val) => dispatch(openLoader()),
        closeLoader: (val) => dispatch(closeLoader()),
        getCampaignsList: (val) => dispatch(fetchCampaignList(val)),
        uploadFile: (val) => dispatch(uploadFile(val)),
        viewLogs: (val) => dispatch(viewLogs(val)),
        downloadInputTemplate: (val) => dispatch(downloadInputTemplate(val))
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PromoUpload));