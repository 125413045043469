import axios from 'axios';
import {
  GET_ERRORS,
  SET_CURRENT_USER,
  GET_CHART_DATA,
  GET_CHART_DATA_FILTERS,
  DATA_LOADING,
  CLEAR_CHART_DATA,
  CLEAR_ERRORS
} from './types';
import environment from './../environments/environment';
export const setLoading = () => {
  return {
    type: DATA_LOADING
  };
};

// Get chartData from the Api
export const getChartData = reqObject => dispatch => {
  dispatch({
    type: SET_CURRENT_USER,
    payload: localStorage.getItem('token')
  });
  dispatch({
    type: CLEAR_CHART_DATA
  });
  dispatch({
    type: CLEAR_ERRORS
  });
  dispatch(setLoading());

  axios
    .post(`${environment.baseUrl}`, reqObject)
    .then(res => {
      if (res.data.Error !== undefined && res.data.Error !== '') {
        //
        dispatch({
          type: CLEAR_CHART_DATA
        });
        dispatch({
          type: GET_ERRORS,
          payload: res.data
        });
      } else {
        //
        dispatch({
          type: CLEAR_ERRORS
        });
        dispatch({
          type: GET_CHART_DATA,
          payload: res.data
        });
      }
    })
    .catch(err => {
      //
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

// Get chart's filters from the Api
export const getChartDataFilters = reqObject => dispatch => {
  // dispatch(setLoading());

  dispatch({
    type: SET_CURRENT_USER,
    payload: localStorage.getItem('token')
  });

  axios
    .post(`${environment.baseUrl}/filters`, reqObject)
    .then(res => {
      // dispatch({
      //     type: CLEAR_FILTERS_DATA,
      // });
      //
      dispatch({
        type: CLEAR_ERRORS
      });
      dispatch({
        type: GET_CHART_DATA_FILTERS,
        payload: res.data
      });
    })
    .catch(err => {
      //
      dispatch({
        type: GET_ERRORS,
        payload: err
      });
    });
};

export const clearCurrentChartdata = () => {
  return {
    type: CLEAR_CHART_DATA
  };
};

// export const clearCurrentFilterdata = () => {
//     return {
//         type: CLEAR_FILTERS_DATA
//     }
// }
