import React from 'react';
import LineChart from '../../../Charts/LineCharts';
import { Doughnut } from 'react-chartjs-2';
import styled from 'styled-components';
import moment from 'moment';
import { numberWithCommas } from '../../utils';

export default function BudgetPlanGraph({
  budgetGraphData = {},
  selectedView,
  toggleMonthQuarter
}) {
  const { months: labelsData = [], data: chartData } = budgetGraphData;
  const labels = [];
  if (budgetGraphData) {
    labelsData.map(label => {
      labels.push(
        moment()
          .month(label - 1)
          .format('MMMM')
      );
    });
  }

  const colors = [];
  colors.push({
    backgroundColor: '#44677b'
  });
  colors.push({
    backgroundColor: '#bcbcbc'
  });
  colors.push({
    backgroundColor: '#a6cee3'
  });
  const datasets = [];
  for (let key in chartData) {
    let index = Object.keys(chartData).indexOf(key);
    datasets.push({
      label: key,
      data: chartData[key].map(val => (val > 0 ? val : null)),
      backgroundColor: colors[index % 3].backgroundColor,
      borderColor: colors[index % 3].backgroundColor,
      borderWidth: 5
    });
  }

  const lineOption = {
    tooltips: {
      intersect: true,
      callbacks: {
        label: function(tooltipItem, data) {
          let label = data.datasets[tooltipItem.datasetIndex].label || '';
          if (label) {
            label += ': ';
          }
          let no = tooltipItem.yLabel;
          if (parseFloat(tooltipItem.yLabel) / 1000000 >= 1) {
            //add m
            no = parseFloat(tooltipItem.yLabel / 1000000).toFixed(2) + 'M';
          } else if (parseFloat(tooltipItem.yLabel) / 1000 >= 1) {
            //add k
            no = (parseFloat(tooltipItem.yLabel) / 1000).toFixed(2) + 'K';
          }
          label += '$' + no;
          return label;
        }
      }
    },
    options: {
      spanGaps: false
    },
    scaleShowVerticalLines: false,
    hover: {
      intersect: false
    },
    elements: {
      line: {
        tension: 0,
        fill: false
      },
      point: {
        radius: 0
      }
    },
    plugins: {
      datalabels: {
        display: false
      }
    },
    scales: {
      xAxes: [
        {
          barPercentage: 1.0,
          scaleLabel: {
            display: true,
            // labelString: lineDatajson.x_label,
            fontStyle: 'bold'
          },
          gridLines: {
            display: false
          },
          ticks: {
            autoSkip: true,
            maxTicksLimit: 15,
            fontSize: 10,
            opacity: 0.7,
            callback: function(value, index, values) {
              if (value === 0) {
                return null;
              }
              if (value.length >= 12) {
                return (
                  value.substr(0, 5) + '...' + value.substr(value.length - 5, 0)
                );
              } else {
                return value;
              }
            }
          }
        }
      ],
      yAxes: [
        {
          offset: true,
          gridLines: {
            display: true
          },
          scaleLabel: {
            display: true,
            fontStyle: 'bold',
            fontSize: 10
          },
          ticks: {
            callback: function(value) {
              var ranges = [
                { divider: 1e9, suffix: 'B' },
                { divider: 1e6, suffix: 'M' },
                { divider: 1e3, suffix: 'k' }
              ];
              function formatNumber(n) {
                for (let i = 0; i < ranges.length; i++) {
                  if (n >= ranges[i].divider) {
                    return (
                      (n / ranges[i].divider).toString() + ranges[i].suffix
                    );
                  }
                }
                return n;
              }
              return '$' + formatNumber(value);
            }
          }
        }
      ]
    },
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        boxWidth: 12
      },
      fontFamily: 'poppins',
      fontSize: '12px'
    },
    responsive: true,
    maintainAspectRatio: true
  };

  var lineData = {
    labels: labels,
    datasets: datasets
  };

  //code for pie chart
  let pieLabels = [];
  pieLabels.push(Math.round(budgetGraphData.avallable));
  pieLabels.push(Math.round(budgetGraphData.alloted));

  var pieOptions = {
    tooltips: {
      callbacks: {
        label: function(tooltipItem, data) {
          //get the concerned dataset
          let dataset = data.datasets[tooltipItem.datasetIndex];
          //calculate the total of this data set
          let total = dataset.data.reduce(function(
            previousValue,
            currentValue,
            currentIndex,
            array
          ) {
            return previousValue + currentValue;
          });
          //get the current items value
          let currentValue = dataset.data[tooltipItem.index];
          //calculate the precentage based on the total and current item, also this does a rough rounding to give a whole number
          let percentage = Math.floor((currentValue / total) * 100 + 0.5);
          return percentage + '%';
        }
      }
    },
    cutoutPercentage: 80,
    plugins: {
      datalabels: {
        display: false,
        color: '#303030',
        font: {
          size: 12,
          weight: 600
        }
      }
    },
    stacked: true,
    maintainAspectRatio: true,
    responsive: true,
    legend: {
      position: 'right',
      labels: {
        boxWidth: 10,
        usePointStyle: true,
        fontSize: 15
      }
    }
  };

  var pieData = {
    labels: [
      pieLabels[0] + '% ' + 'Available',
      pieLabels[1] + '% ' + 'Allotted'
    ],
    datasets: [
      {
        data: pieLabels,
        backgroundColor: ['#7b8eca', '#C19AB7'],
        hoverBackgroundColor: ['#7b8eca', '#C19AB7']
      }
    ]
  };

  return (
    <div className="row budgetSubContainer">
      <div className="col-md-12">
        <div className="card pieBox">
          <div className="row">
            <div className="col-md-2">
              <p className="textstyle">Budget Plan</p>
            </div>
            <div className="col-md-5 BtnGroupRow1">
              <div
                id="promoCal"
                className="divInn1 btn-group1 "
                style={{ textAlign: 'center' }}
              >
                <p className="textstyle">
                  Previous year Budget :
                  <span className="allocate-text">
                    {budgetGraphData.totlAllo != 0
                      ? '$' + numberWithCommas(budgetGraphData.totlAllo * 0.79)
                      : '$' + localStorage.getItem('prevData')}
                  </span>
                </p>

                <StyledGraphButton
                  selected={selectedView === 'Year'}
                  onClick={() => toggleMonthQuarter('Year')}
                >
                  Year
                </StyledGraphButton>
                <StyledGraphButton
                  selected={selectedView === 'Quarter'}
                  onClick={() => toggleMonthQuarter('Quarter')}
                >
                  Quarter
                </StyledGraphButton>
              </div>
            </div>
            <div className="col-md-5 text-right">
              <p className="textstyle">
                Allocated Budget :
                <span className="allocate-text">
                  {' '}
                  {budgetGraphData.totlAllo
                    ? '$' + numberWithCommas(budgetGraphData.totlAllo)
                    : ''}
                </span>
              </p>
            </div>
          </div>
          <LineChart
            data={lineData}
            options={lineOption}
            width={500}
            height={100}
          />
        </div>
      </div>
      {/* new wireframe doesn't show pie chart.commenting the code for now. 
        todo : remove/uncomment commented code as per as requirement in future.
      */}
      {/* <div className="col-md-4">
        <div className="card pieBox">
          <div className="row">
            <div className="col-md-6">
              <p className="textstyle">Budget</p>
            </div>
            <div className="col-md-6 text-right">
              <p className="textstyle">
                Total :{' '}
                <span className="allocate-text">
                  {budgetGraphData.totlBug
                    ? '$' + numberWithCommas(budgetGraphData.totlBug)
                    : ''}
                </span>
              </p>
            </div>
          </div>
          <div className="pieChartBox">
            <Doughnut data={pieData} options={pieOptions} />
          </div>
        </div>
      </div> */}
    </div>
  );
}

export const StyledGraphButton = styled.button`
  background: ${props => (props.selected ? '#507A90' : 'lightgray')};
  border: none;
  width: 90px;
  color: white;
  cursor: pointer;
`;
