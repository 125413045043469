import gaUtils from './Analytics';

const gaUtilsObj = new gaUtils();
const HistoricalPerformanceGA = gaUtilsObj.trackEvent('Historical Performance');
const AnalyzeBooksGA = gaUtilsObj.trackEvent('Analyze Books');
const SKUMasterGA = gaUtilsObj.trackEvent('SKU Master');
const BudgetMasterGA = gaUtilsObj.trackEvent('Budget Master');
const BudgetPlanningGA = gaUtilsObj.trackEvent('Budget Planning');
const PlanSummaryGA = gaUtilsObj.trackEvent('PLan Summary');
const FinalizePromotionGA = gaUtilsObj.trackEvent('Finalize Promotion');
const PromotionCalendarGA = gaUtilsObj.trackEvent('Promotion Calendar');
const AdminGA = gaUtilsObj.trackEvent('Admin');
const VideoTutorialsGA = gaUtilsObj.trackEvent('Video Tutorials');
const CreatePromoGA = gaUtilsObj.trackEvent('Create Promo');
const SupportGA = gaUtilsObj.trackEvent('Support');
const ConstraintsGA = gaUtilsObj.trackEvent('Constraints');
export {
  gaUtilsObj,
  HistoricalPerformanceGA,
  AnalyzeBooksGA,
  SKUMasterGA,
  BudgetMasterGA,
  BudgetPlanningGA,
  PlanSummaryGA,
  FinalizePromotionGA,
  PromotionCalendarGA,
  AdminGA,
  VideoTutorialsGA,
  CreatePromoGA,
  SupportGA,
  ConstraintsGA
};
