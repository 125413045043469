import React, { useState, useEffect } from 'react';
import MinMaxCycleDiscountDepth from './minMaxCycleDiscountDepthWrapper';
import { Accordion, Card } from 'react-bootstrap';
import { quarters } from './constants';

const ExpandCollapse = (props) => {
  const [selectedQuarter, setSelectedQuarter] = useState('');

  const { minMaxProp } = props;
  const handleOnClick = (event) => {
    const quarter = event.target.textContent.split(' ')[1].split('')[1];
    selectedQuarter == quarter ? setSelectedQuarter('') : setSelectedQuarter(quarter);
    props.handleQuarterSelection(quarter);
  };
  return (
    <>
      <Accordion>
        {quarters.map((quarter, index) => (
          <Card>
            <Accordion.Toggle onClick={handleOnClick} as={Card.Header} eventKey={`${index}`}>
              {index + 1 == selectedQuarter ? (
                <i class="fa fa-angle-down"></i>
              ) : (
                <i class="fa fa-angle-right"></i>
              )}
              {quarter}
            </Accordion.Toggle>
            <Accordion.Collapse eventKey={`${index}`}>
              <Card.Body>
                <MinMaxCycleDiscountDepth
                  minMaxProps={props.minMaxProps}
                  selectedQuarter={selectedQuarter}
                />
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))}
      </Accordion>
    </>
  );
};

export default ExpandCollapse;
