import { callApi } from "./config";
import { toast } from "react-toastify";
import { MESSAGES } from "../common/app-constants";
import { ToastMessage } from "../common/ToastMessage";


export const fetchVendorList = reqObj => {
  return callApi
    .post(`vendorBudget/list`, reqObj)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      return err;
    });
};

export const fetchPlanArticleCampaigns = planId => {
  return callApi
    .get(`budgetplan/fetch/planArticleCampaigns/${planId}`)
    .then(res => {
      return res.data.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      return err.response;
    });
};
export const fetchDiscountDepthDropDown = reqObj => {
  return (
    callApi
      // budgetplan/fetch/optimizePlan/dropdowns
      .post(`budgetplan/fetch/optimizedPlan/dropdowns`, reqObj)
      .then(res => {
        return res.data.data.dropdowns;
      })
      .catch(err => {
        return err;
      })
  );
};

export const fetchSimulatePlan = reqObj => {
  const {FAILURE_MESSAGE } = MESSAGES;
  return callApi
    .post(`budgetplan/article/campaign/simulate`, reqObj)
    .then(res => {
      if (res.status != 200) {
        ToastMessage(res.status,FAILURE_MESSAGE)
      }
      return res.data;
    })
    .catch(err => err);
};

export const postBudgetPlanActualColSave = reqObj => {
  const { SUCCESS_MESSAGE, FAILURE_MESSAGE } = MESSAGES;
  return callApi
    .post(`budgetplan/article/campaign/update`, reqObj)
    .then(res => {
      if (res.status === 200)
      ToastMessage(res.status,MESSAGES.DOWNLOAD_SUCCESS_MESSAGE)
      else {
        ToastMessage(res.status,FAILURE_MESSAGE)
      }
      return res.data.data;
    })
    .catch(err => err);
};

export const postSaveOrSubmit = reqObj => {
  const {
    SUCCESS_MESSAGE,
    SUCCESS_SUBMIT_APPROVAL,
    FAILURE_MESSAGE
  } = MESSAGES;
  const msg =
    reqObj.status === "DRAFT" ? SUCCESS_MESSAGE : SUCCESS_SUBMIT_APPROVAL;
  return callApi
    .post(`/promo/updateFinalizeData`, reqObj)
    .then(res => {
      if (res.status === 200)
      ToastMessage(res.status,msg)
      else {
        ToastMessage(res.status,FAILURE_MESSAGE)
      }
      return res.data.data;
    })
    .catch(err => {
      console.log('error while saving the offer');
      console.log(err);
      if (err.response) {
        ToastMessage(err.response.status,err.response.data.message)
      }
    });
};


export const getAh6Data = reqObj => {
  return callApi
    .post(`hierarchy/filters/ah6`, reqObj)
    .then(res => {
      if(res.data.data){

        return res.data.data;
      }else{
        return []
      }
    })
    .catch(err => err);
};

export const downloadPlanData = reqObj => {
  return callApi
    .post("/budgetplan/budgetPlan/download", reqObj)
    .then(res => {
      ToastMessage(res.status,MESSAGES.DOWNLOAD_SUCCESS_MESSAGE)
      return res.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      throw err;
    });
}

export const downloadPromoTracker = reqObj => {
  return callApi
    .post("/budgetPlan/budgetPlan/downloadPromoTracker", reqObj)
    .then(res => {
      ToastMessage(res.status,MESSAGES.DOWNLOAD_SUCCESS_MESSAGE)
      return res.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      throw err;
    });
}

export const downloadPlanSummaryData = reqObj => {
  return callApi
    .post("/budgetplan/budgetPlan/downloadPlanSummary", reqObj)
    .then(res => {
      ToastMessage(res.status,MESSAGES.DOWNLOAD_SUCCESS_MESSAGE)
      return res.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      throw err;
    });
}

export const copyPlanData = reqObj => {
  return callApi
    .post("/budgetplan/copyPlan", reqObj)
    .then(res => {
      ToastMessage(res.status,"Plan copied successfully")
      return res.data;
    })
    .catch(err => {
      ToastMessage(err.response.status,err.response.data.message)
      throw err;
    });
}