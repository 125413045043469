import React, { Component } from 'react';
import "./sideBarMiniStyle.css"
export default class SideBarMini extends Component {
    constructor(props) {
        super(props);
        this.state = {
			class: '',
			display: "none"
		}	
    }

	onHover = ()=>{
		this.setState({
			class:'active',
			display: "inline-block"
		})
	}

	onLeaveHover = ()=>{
		this.setState({
			class:'',
			display: ""
		})
	}
    render() {
        return (
            <div id="icon-bar" onMouseEnter={e=> this.onHover(e)}
            onMouseLeave={e=> this.onLeaveHover(e)} className ={this.state.class}>

            <a href="#" className="active">
            <span className="hidetext fontstyling" style={{display: this.state.display,float:"left"}}>Create Promo</span>&nbsp;
                <i className="fa fa-modx ministyle"></i>
                 
            </a>
            <a href="#"><span className="hidetext fontstyling" style={{display: this.state.display,float:"left"}}>Pipeline</span>&nbsp;<i className="fa fa-tasks ministyle" aria-hidden="true"></i>
            
            </a>
            <a href="#"><span className="hidetext fontstyling" style={{display: this.state.display,float:"left"}}>Compare</span>&nbsp;
                <i className="fa fa-bar-chart ministyle"></i> 
                
                </a>
            <a href="#"><span className="hidetext fontstyling" style={{display: this.state.display,float:"left"}}>Historical performance</span>&nbsp;
                <i className="fa fa-cog ministyle"></i>
                
            </a>
            <a href="#">
            <span className="hidetext fontstyling" style={{display: this.state.display,float:"left"}}>Summary</span>&nbsp;
                        <i class="fa fa-check-square-o ministyle" aria-hidden="true"></i> 
                    </a>
            <a href="#">
            <span className="hidetext fontstyling" style={{display: this.state.display,float:"left"}}>Logout</span>&nbsp;
                <i className="fa fa-user-circle-o ministyle" aria-hidden="true"></i> 
            </a>
        </div>
        );

}
}