/********* importing component specific api functions - start **********/
// 0. common api's across different pages
import {
  fetchMerchCatValues,
  fetchCategorySpecificVendors,
  fetchDateMappingFilterData,
  fetchPromoCampaigns,
  updateSelectedMetrics,
} from "./common.api.";
// 1. promo page related apis
import {
  fetchCalanderPlotData,
  fetchCalenderData,
  fetchPromoData,
  fetchPromoHierarchyData,
  fetchPromoAndPlanHierarchyData,
  downloadPromoCalendarData
} from "./promo.api";
// 2 . historical performance page apis
// 3 . analyze event page apis
// 4 . budget planning page apis

//5 . Sku management apis
import { fetchModifySkuData } from "./skuManagement.api";

/********* importing component specific api functions - end **********/

/*export all page api functions from here.until it grows to a really huge object,
export all api functions from here for ease in accesibility and readbility.
*/
export const apiFunctions = {
  fetchCalanderPlotData,
  fetchCalenderData,
  fetchPromoData,
  fetchPromoHierarchyData,
  fetchMerchCatValues,
  fetchCategorySpecificVendors,
  fetchDateMappingFilterData,
  fetchPromoCampaigns,
  fetchPromoAndPlanHierarchyData,
  fetchModifySkuData,
  downloadPromoCalendarData
};
