import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { AgGridReact } from 'ag-grid-react';
import _ from 'lodash';
import TableWrapperMainDiv from '../../../common/TableWrapper';
import { connect } from 'react-redux';
import { actionUpdateBudgetMetricsTable } from '../../budgetPlanAction';
import BudgetMetricsDropDown from '../BudgetMetricsDropDown'

const BudgetMetricsTable = ({
  budgetMetricsData,
  plannedArticleData,
  actionUpdateBudgetMetricsTable,
  handleDownload,
  selectedYearVal,
  budgetPlanCampaignList
}) => {
  const [colDefs, setColDefs] = useState([]);
  const [gridApi, setGridApi] = useState({});
  const [rowData, setRowData] = useState([]);
  const [articleNo, setArticleNo] = useState(undefined);

  const [articleList, setArticleList] = useState([]);


  useEffect(() => {
    //table column headers
    const columnDefs = [
      {
        headerName: "",
        field: "MetricCategory",
        cellRenderer: "showCellRenderer",
        pinned: 'left',
        rowSpan: function(params) {
          if (params.data.MetricCategory) {
            return 4;
          } else {
            return 1;
          }
        },
        cellStyle: MergeCellStyle
      },
      {
        headerName: "",
        field: "yearBy",
        cellStyle: yearByCellStyle,
        pinned: 'left'
      },
      {
        headerName: "Total",
        field: "total",
        pinned: 'left'
      }
    ];
    budgetMetricsData.books.forEach(bookNumber => {
      columnDefs.push({
        headerName: `Book ${bookNumber}`,
        field: `Book${bookNumber}`
      });
    });
    setColDefs(columnDefs);
    console.log("**************",budgetMetricsData.data)
    setRowData(budgetMetricsData.data);
    setArticleNo(budgetMetricsData.articleNo);
  }, [budgetMetricsData]);

  useEffect(() => {
    const articles = Object.values(plannedArticleData.data)
    setArticleList(articles.filter(elem => elem.active));
  }, [plannedArticleData]);


  useEffect(() => {
    if (Object.keys(gridApi).length > 0) {
      if (budgetMetricsData.books.length < 10) {
        gridApi.sizeColumnsToFit();
      }
    }
  }, [colDefs, budgetMetricsData]);

  function createShowCellRenderer() {
    function ShowCellRenderer() {}

    ShowCellRenderer.prototype.init = function(params) {
      var cellBlank = !params.value;
      if (cellBlank) {
        return null;
      }

      this.ui = document.createElement('div');
      this.ui.innerHTML = `<div class="show-name">${params.value.name}
        </div>`;
    };

    ShowCellRenderer.prototype.getGui = function() {
      return this.ui;
    };

    return ShowCellRenderer;
  }

  // specifying column headers and row data to table gridOptions
  var gridOptions = {
    components: {
      showCellRenderer: createShowCellRenderer()
    },
    defaultColDef: {
      resizable: true,
      width: 100
    },
    suppressRowTransform: true
  };
  const handleFilterChange = (articles) => {
    actionUpdateBudgetMetricsTable({
      articleData: articles,
      budgetPlanCampaignList 
    })
  };

  function onGridReady(params) {
    setGridApi(params.api);
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    // this.gridApi.sizeColumnsToFit();
  }


  return (
    <>
      <div id="metricsSection" className='row' style={metricsTableTitleStyle}>
        <p className='col-md-6'>Metrics</p>
        <div className="row budget-plan-summary-download">
                <div className="plan-download-header">
                <label className="pipelineLabel downloadLbel">Plan Summary</label>
                </div>
                <button
                  className="btn btn-primary iconbutton download-btn"
                  onClick={handleDownload}
                >
                  <span>
                    <i class="fa fa-download" aria-hidden="true" />
                  </span>
                </button>
              </div>
        </div>
      <BudgetMetricsDropDown
        articleList={articleList}
        handleFilterChange={handleFilterChange}
        articleSelectedFromPlanTable={budgetMetricsData.articleNo}
      ></BudgetMetricsDropDown>
      <div id='budgetMetricsTable'>
        <TableWrapperMainDiv>
          <AgGridReact
            onGridReady={onGridReady}
            gridOptions={gridOptions}
            domLayout='autoHeight'
            rowData={rowData}
            columnDefs={colDefs}
            enableCellTextSelection={true}
          />
        </TableWrapperMainDiv>
      </div>
      <hr />
    </>
  );
};

const mapStateToProps = state => ({
  budgetMetricsData: state.budgetPlan.budgetMetricsData,
  plannedArticleData: state.budgetPlan.plannedArticleData,
  userInfo: state.userManagementData.userInfo.action,
  budgetPlanCampaignList: state.budgetPlan.budgetPlanCampaignList
});

const mapDispatchToProps = dispatch => {
  return {
    actionUpdateBudgetMetricsTable: val =>
    dispatch(actionUpdateBudgetMetricsTable(val))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BudgetMetricsTable));

//custom style for table cells

const MergeCellStyle = {
  display: 'flex',
  alignItems: 'center',
  background: 'white',
  color: '#44677B',
  borderLeft: '1px solid lightgray !important',
  borderRight: '1px solid lightgray !important',
  borderBottom: '1px solid lightgray !important'
};

const articleDropDownStyle = {
  zIndex: 99,
  position: 'relative'
};

const metricsTableTitleStyle = {
  marginLeft: '20px'
};

const yearByCellStyle = {
  color: '#44677B'
};
