import {  DATA_LOADING, SET_CATEGORY_ROLES, SET_USER_DATA, GET_ALL_USERS ,SET_USER_ROLE_INFO ,USER_LOGOUT , SESSION_EXPIRED ,SET_VIDEOS_LIST} from '../actions/types';

const initialState = {
  roles:[],
  loading: false,
  userData: {},
  allUsers: [],
  isAuthenticated: false,
  userInfo:{
    "action": [],
    "pages": []
  },
  isSessionExpired: false,
  videosList:[]
};

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_CATEGORY_ROLES:
      return {
        ...state,
        roles: action.payload
      };
    case DATA_LOADING:
      return {
        ...state,
        loading: action.payload
      };
    case SET_USER_DATA:
       return{
         ...state,
         userData: action.payload
       }
    case GET_ALL_USERS:
      return {
        ...state,
        allUsers: action.payload
      }
      case SET_USER_ROLE_INFO:
        return {
          ...state,
          isAuthenticated: true,
          userInfo: action.payload
        }  
        case USER_LOGOUT:
          return {
            ...state,
            isAuthenticated: false,
          }  
        case SESSION_EXPIRED:
          return {
            ...state,
            isSessionExpired: action.payload
          }
          case SET_VIDEOS_LIST:
            return {
              ...state,
              videosList: action.payload
            }
             
    default:
      return state;
  }
}
