import React from 'react';

const PageWrapper = props => {
  return <div style={containerstyle}>{props.children}</div>;
};
export default PageWrapper;
const containerstyle = {
  minHeight: '100vh',
  maxHeight: '100%',
  boxShadow: '0px 0px 10px #00000014',
  background: 'white',
  borderRadius: '5px',
  paddingBottom: '50px',
  paddingLeft: "24px",
  paddingRight: "24px"
};
