import {
  SET_LOADER,
  SET_CYCLES,
  SET_QUARTERS,
  SET_CONSOLIDATED_DATA,
  SET_BUYER_DATA,
  SET_CATEGORY_DATA,
  SET_VENDOR_DATA,
  SET_MERCHCAT,
  RESET_CONSOLIDATED_DATA,
  UPDATE_CONSOLIDATED_DATA
} from '../actions/types';

const initialState = {
  loader: false,
  cycles: [],
  quarter: [],
  consolidatedData: [],
  categoryData: [],
  buyerData: [],
  vendorData: [],
  merchcatValues:[],
  ty:null
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADER:
      return {
        ...state,
        loader: payload,
      };
    case SET_CYCLES:
      return {
        ...state,
        cycles: payload,
      };
    case SET_QUARTERS:
      return {
        ...state,
        quarter: payload,
      };
    case SET_CONSOLIDATED_DATA:
      return {
        ...state,
        consolidatedData: payload.data,
        ty: payload.lastDay
      };
    case SET_CATEGORY_DATA:
      return {
        ...state,
        categoryData: payload,
      };
    case SET_BUYER_DATA:
      return {
        ...state,
        buyerData: payload,
      };
    case SET_VENDOR_DATA:
      return {
        ...state,
        vendorData: payload,
      };
      case SET_MERCHCAT:
        return {
          ...state,
          merchcatValues: action.payload
        };
        case RESET_CONSOLIDATED_DATA:
          return {
            ...state,
            consolidatedData: [],
          };
          case UPDATE_CONSOLIDATED_DATA:
      return {
        ...state,
        consolidatedData: payload
      };
    default:
      return {
        ...state,
      };
  }
}
