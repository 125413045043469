export const NUMBER_REGEX = {
  POSITIVE_NUMBER: /^([1-9][0-9]*)?$/,
  DECIMAL_NUMBER: /^([0-9]*|[0-9]*\.[0-9]*)?$/
};

export const MESSAGES = {
  SUCCESS_MESSAGE: "Saved successfully",
  FAILURE_MESSAGE: "Failed to save chnages",
  CREATE_PLAN_SUCCESS: "Your plan is created successfully",
  CREATE_SKU_SUCCESS: "SKU created successfully",
  DELETE_SUCCESS_MESSAGE: "Deleted Successfully",
  DOWNLOAD_SUCCESS_MESSAGE: "Downloaded successfully",
  SUCCESS_SUBMIT_APPROVAL: "Plan successfully submitted for approval",
  SAVE_CONSTRAINTS: "Constraints saved successfully",
  APPROVE_SUCCESS: "Approved Successfully",
  REJECT_SUCCESS: "Rejected Successfully",
  UNAPPROVE_SUCCESS: "Un Approved Successfully",
  CREATE_GROUP_SUCCESS: "Group created successfully",
  SAVE_GROUP_CHANGE_SUCCESS: "Group changes Saved successfully",
  GROUP_DELETE_SUCCESS_MESSAGE: "Group deleted Successfully",
};

export const VALIDATION_REGEX = {
  EMAIL_REGEX: /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
  POSITIVE_DECIMAL_NUMBER: /^([0-9]*|[0-9]*\.[0-9]*)?$/
}

export const DEFAULT_DESC = `I am facing issues with following details. \nCategory: \nVendor: \nAh6: \nScreen: \nDescription: `