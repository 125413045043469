import _ from 'lodash';

const cycleOptionsList = _.range(1, 21).map((obj) => {
  return { value: obj, label: obj };
});

const discountCycleOptions = _.range(1, 21).map((obj) => {
  return { value: `BBM${obj}`, label: `BBM${obj}` };
});

const quarterlyDiscountCycleOptions = {
  "1" : _.range(1, 6).map((obj) => {
    return { value: `BBM${obj}`, label: `BBM${obj}` };
  }),
  "2" : _.range(6, 11).map((obj) => {
    return { value: `BBM${obj}`, label: `BBM${obj}` };
  }),
  "3" : _.range(11, 16).map((obj) => {
    return { value: `BBM${obj}`, label: `BBM${obj}` };
  }),
  "4" : _.range(16, 21).map((obj) => {
    return { value: `BBM${obj}`, label: `BBM${obj}` };
  })
}

const getArray = (arr,i) =>{
      while(i<arr.length-1 && (arr[i]+1 === arr[i+1]))
      {
        i++;
      }
      return i;
}

const findConsecutive = (arr) =>{
  let result=[];
  let i=0, resVal=0;
  while(i<arr.length)
  {
      resVal= getArray(arr, i);
      if(resVal!=i){
        if(result.length==0)
        result=arr.slice(i,resVal+1);
      else
        result=result.concat(arr.slice(i,resVal+1));
    }
   i=resVal+1;
}
return result;
}

const isConsecutive = (arr) => {
  const differenceAry = arr.slice(1).map(function (n, i) {
    return n - arr[i];
  });
  console.log(differenceAry);
  const isDifference = differenceAry.every((value) => value == 1);
  console.log(isDifference);
  return isDifference;
};

const quarters = ['Quarter 01','Quarter 02','Quarter 03','Quarter 04']
export { cycleOptionsList, discountCycleOptions, isConsecutive, quarters, quarterlyDiscountCycleOptions , findConsecutive };
