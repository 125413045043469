import {
  SET_SKU_DATA,
  SET_VENDORHISTORY_TABLE,
  SET_BUDGET_PLAN_TABLE,
  SET_BUDGET_PLAN_METRICS_TABLE,
  SET_PLAN_TABLE,
  SET_METRICS_TABLE,
  SET_LOADER,
  SET_DISCOUNT_DEPTH_LIST,
  SET_BUDGET_PLAN_ROW_DATA,
  SET_YEAR_QUATERS_DATA,
  SET_PLAN_DATA,
  SET_DESELECTED_SKUS,
  SET_PLAN_STATUS,
  RESET_BUDGET_PLAN_ID_DATA,
  RESET_VENDORHISTORY_TABLE,
  SET_OPTIMIZATION_LINK,
  RESET_OPTIMIZATION_LINK,
  SET_BUDGET_PLAN_HEADERS,
  RESET_BUDGET_PLAN_HEADERS,
  SET_BUDGET_PLAN_ERROR,
  SET_AH6_DATA,
  SET_INACTIVE_METRICS_DATA,
  SET_VENDORHISTORY_BRAND_TABLE,
  BUDGET_PLAN_CAMPAIGN_LIST,
  SET_UPDATED_DISCOUNT_DEPTH,
} from "./actionTypes";
import { fetchVendorList, fetchPlanArticleCampaigns, fetchDiscountDepthDropDown, postBudgetPlanActualColSave, postSaveOrSubmit ,getAh6Data, fetchSimulatePlan, copyPlanData}  from "../../apis/budgetPlan.api";
import { fetchModifySkuData,setBudgetPlanUpsert } from "../../apis/skuManagement.api";
import { fetchCampaignList, fetchDateMappingFilterData, fetchSpecificPlan } from "../../apis/common.api.";
import { findKeyFromObject } from './utils';
import _ from 'lodash';
import { convertMillionsFormatter, converToPercentageFloat, formatToTwoDecimals } from "../../utils/AgGridFormatters";
import moment from 'moment'
import { toast } from "react-toastify";

export const getVendorHistoryTable = value => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await fetchVendorList(value);
    const updatedVendorData = result.vendorList.map(item => {
      if(isNaN(item.tradeSpendLLY) && isNaN(item.insertionFeeLLY)) {
        return{
          ...item
        }
      }
      return{
      ...item,
      budgetUtilisedLLY: (item.tradeSpendLLY  || 0) + (item.insertionFeeLLY || 0),
      budgetUtilisedLY: (item.tradeSpendLY || 0) + (item.insertionFeeLY || 0)
    }});
    const updatedBrandData = result.brandList.map(item => {
      if(isNaN(item.tradeSpendLLY) && isNaN(item.insertionFeeLLY)) {
        return{
          ...item
        }
      }
      return{
      ...item,
      budgetUtilisedLLY: (item.tradeSpendLLY || 0) + (item.insertionFeeLLY || 0),
      budgetUtilisedLY: (item.tradeSpendLY || 0) + (item.insertionFeeLY || 0)
    }});
    if (result.vendorList) {
      dispatch({ type: SET_VENDORHISTORY_TABLE, payload: updatedVendorData });
      dispatch({ type: SET_VENDORHISTORY_BRAND_TABLE, payload: updatedBrandData });
      dispatch({ type: SET_LOADER, payload: false });
    }
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const getSkuDataTable = value => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await fetchModifySkuData(value);
    const { data, totalCount } = result;
    if (data) {
      const formattedSkuData = data.map(
        ({
          hierarchyLevel4: ah4 = "-",
          hierarchyLevel6: ah6 = "-",
          hierarchyLevel7: skuid = "-",
          vendor = "-",
          description = "-",
          refSKU = "-",
          replace_sku = "-",
        }) => {
          return {
            skuid,
            description,
            ah6,
            vendor,
            refSKU,
            replace_sku,
          };
        }
      );
      dispatch({
        type: SET_SKU_DATA,
        payload: { data: formattedSkuData, totalCount },
      });
      dispatch({ type: SET_LOADER, payload: false });
    }
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

// this might look confusing at first look. it is only meant to dispatch synchrous actions, ignore thunk dispatch in this
export const setSkuData = value => dispatch => {
  dispatch({
    type: SET_SKU_DATA,
    payload: value,
  });
};
export const postBudgetPlanUpsert = BudgetPlanPayload => async dispatch => {
  dispatch({
      type: SET_LOADER,
      payload: true
  });
  try {
      const budgetEditResponse = await setBudgetPlanUpsert(BudgetPlanPayload);
      dispatch({ type: SET_OPTIMIZATION_LINK, payload: budgetEditResponse.url });
      dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};
const formatAticleCampaignLevelToArticleLevel = (planArticleCampaigns) => {
  const articleNoToArticleLevel = {};
  const campaignIdDiscountsToPromoId = {};
  const books = [];
  let budgetOptimized = 0;
  // const bookIdToMetrics = 'bookIdToMetrics';
  const promoKeyToPromoId = {};
  planArticleCampaigns.forEach(articleCampaign => {
    const {
      articleNo,
      campaignId,
    } = articleCampaign;
    budgetOptimized += (parseInt(articleCampaign.budgetPlannedFinalized) + parseInt(articleCampaign.insertionFeeFinalized));
    const articleDetails = _.pick(articleCampaign, [
      'articleNo',
      'hierarchyLevel6',
      'description',
      'categoryId',
      'vendor',
      'brand',
      'modelType',
      'active'
    ])
    const articleLevel = articleNoToArticleLevel[articleNo] || articleDetails;
    // articleLevelData[articleNo][bookIdToMetrics][campaignId] = bookDetails;
    articleLevel[`${campaignId}_discountLY`] = articleCampaign['discountLY'];
    articleLevel[`${campaignId}_discountOptimized`] = articleCampaign['discountOptimized'];
    articleLevel[`${campaignId}_discountFinalized`] = articleCampaign['discountFinalized'];
    articleLevel[`${campaignId}_promoDaysOptimized`] = articleCampaign['promoDaysOptimized'];
    articleLevel[`${campaignId}_promoDaysFinalized`] = articleCampaign['promoDaysFinalized'];
    articleLevel[`${campaignId}_bjsFundingOptimized`] = articleCampaign['bjsFundingOptimized'];
    articleLevel[`${campaignId}_bjsFundingFinalized`] = articleCampaign['bjsFundingFinalized'];
    articleLevel[`${campaignId}_mediaTypeOptimized`] = articleCampaign['mediaTypeOptimized'];
    articleLevel[`${campaignId}_mediaTypeFinalized`] = articleCampaign['mediaTypeFinalized'];
    articleLevel[`${campaignId}_mediaTypeLy`] = articleCampaign['mediaTypeLy'];
    articleLevel[`${campaignId}_mediaTypeOptimizedTooltip`] = 'MediaType : '+articleCampaign['mediaTypeOptimized'];
    articleLevel[`${campaignId}_mediaTypeFinalizedTooltip`] = 'MediaType : '+articleCampaign['mediaTypeFinalized'];
    articleLevel[`${campaignId}_mediaTypeLyTooltip`] = 'MediaType : '+ articleCampaign['mediaTypeLy'];
    articleLevel[`${campaignId}_unitsLy`] = articleCampaign['unitsLy'];
    articleLevel[`${campaignId}_unitsOptimized`] = articleCampaign['unitsOptimized'];
    articleLevel[`${campaignId}_unitsFinalized`] = articleCampaign['unitsFinalized'];
    articleLevel[`${campaignId}_revenueLy`] = articleCampaign['revenueLy'];
    articleLevel[`${campaignId}_revenueOptimized`] = articleCampaign['revenueOptimized'];
    articleLevel[`${campaignId}_revenueFinalized`] = articleCampaign['revenueFinalized'];
    articleLevel[`${campaignId}_budgetPlannedLy`] = articleCampaign['budgetPlannedLy'];
    articleLevel[`${campaignId}_budgetPlannedOptimized`] = articleCampaign['budgetPlannedOptimized'];
    articleLevel[`${campaignId}_budgetPlannedFinalized`] = articleCampaign['budgetPlannedFinalized'];
    articleLevel[`${campaignId}_insertionFeeLy`] = articleCampaign['insertionFeeLy'];
    articleLevel[`${campaignId}_insertionFeeOptimized`] = articleCampaign['insertionFeeOptimized'];
    articleLevel[`${campaignId}_insertionFeeFinalized`] = articleCampaign['insertionFeeFinalized'];
    articleLevel[`${campaignId}_marginLy`] = articleCampaign['marginLy'];
    articleLevel[`${campaignId}_marginOptimized`] = articleCampaign['marginOptimized'];
    articleLevel[`${campaignId}_marginFinalized`] = articleCampaign['marginFinalized'];
    articleLevel[`${campaignId}_roiLy`] = articleCampaign['roiLy'];
    articleLevel[`${campaignId}_roiOptimized`] = articleCampaign['roiOptimized'];
    articleLevel[`${campaignId}_roiFinalized`] = articleCampaign['roiFinalized'];
    articleLevel[`${campaignId}_promoIdLy`] = articleCampaign['promoIdLy'];
    articleLevel[`${campaignId}_promoIdOptimized`] = articleCampaign['promoIdOptimized'];
    articleLevel[`${campaignId}_promoIdFinalized`] = articleCampaign['promoIdFinalized'];
    articleLevel[`${campaignId}_incrementalRevenueLy`] = articleCampaign['incrementalRevenueLy'];
    articleLevel[`${campaignId}_incrementalRevenueOptimized`] = articleCampaign['incrementalRevenueOptimized'];
    articleLevel[`${campaignId}_incrementalRevenueFinalized`] = articleCampaign['incrementalRevenueFinalized'];
    articleLevel[`${campaignId}_spendLy`] = articleCampaign['spendLy'];
    articleLevel[`${campaignId}_spendOptimized`] = articleCampaign['spendOptimized'];
    articleLevel[`${campaignId}_spendFinalized`] = articleCampaign['spendFinalized'];

    articleNoToArticleLevel[articleNo] = articleLevel;
    const promoKey = articleCampaign['promoKey'];
    if (promoKey && promoKey != '') {
      promoKeyToPromoId[promoKey] = promoKeyToPromoId[promoKey] || [];
      promoKeyToPromoId[promoKey].push(articleCampaign['promoIdFinalized'])
    }
    // const promoIdKey = `${articleCampaign.campaignId}-${articleCampaign.discountFinalized}`;
    // campaignIdDiscountsToPromoId[promoIdKey] = campaignIdDiscountsToPromoId[promoIdKey] || [];
    // campaignIdDiscountsToPromoId[promoIdKey].push(articleCampaign.promoIdFinalized);
    // articleNoToArticleLevel[articleNo]['discountDepth'] = [];
    // articleNoToArticleLevel[articleNo]['updatePlanRow'] = actionBudgetPlanActualColSave;
    if (!books.includes(campaignId)) books.push(campaignId)
  });
  const sortedBooks = books.sort((a, b) => (parseInt(a.split('-')[1]) - parseInt(b.split('-')[1])));
  
  return ({
    sortedBooks: sortedBooks,
    articleNoToArticleLevel: articleNoToArticleLevel,
    budgetOptimized,
    promoKeyToPromoId,
  })
}

export const actionBudgetPlanArticleCampaigns = planId => async dispatch => {
  dispatch({ type: SET_LOADER, payload: true });
  try {
    const planArticleCampaigns = await fetchPlanArticleCampaigns(planId);
    
    if(planArticleCampaigns.status){
        dispatch({type:SET_BUDGET_PLAN_ERROR, payload: planArticleCampaigns.data.message})
    } else{
    const {
      articleNoToArticleLevel,
      sortedBooks,
      budgetOptimized,
      promoKeyToPromoId,
    } = formatAticleCampaignLevelToArticleLevel(planArticleCampaigns);
    dispatch({
      type: SET_BUDGET_PLAN_TABLE,
      payload: {
        books: sortedBooks,
        data: articleNoToArticleLevel,
        budgetOptimized,
        promoKeyToPromoId,
      }
    })
  }
  dispatch({ type: SET_LOADER, payload: false });
  } catch (error) {
    dispatch({ type: SET_LOADER, payload: false });
  }
}

export const actionSimulationPlan = (requestObj) => async dispatch => {
  dispatch({ type: SET_LOADER, payload: true });
  try {
    const budgetPlanRowData = await fetchSimulatePlan(requestObj);
    const {
      articleNoToArticleLevel,
      sortedBooks,
      budgetOptimized,
      promoKeyToPromoId,
    } =  formatAticleCampaignLevelToArticleLevel(budgetPlanRowData.data);
    dispatch({
      type: SET_BUDGET_PLAN_TABLE,
      payload: {
        books: sortedBooks,
        data: articleNoToArticleLevel,
        budgetOptimized,
        promoKeyToPromoId,
      }
    });
    dispatch({type: SET_UPDATED_DISCOUNT_DEPTH,
    payload: budgetPlanRowData.updatedDiscounts
    });
    dispatch({ type: SET_PLAN_STATUS, payload: "" });
    dispatch({ type: SET_LOADER, payload: false });
  }catch (error) {
    dispatch({ type: SET_LOADER, payload: false });
  }
}


export const actionFetchSpecificPlan = specificPlanId => async dispatch => {
  try {
    const data = await fetchSpecificPlan(specificPlanId);
    dispatch({
      type: SET_PLAN_DATA,
      payload: data,
    })
    dispatch({
      type: SET_PLAN_STATUS,
      payload: data[0].status
    })
    if (data && data.length > 0) {
      dispatch({
        type: SET_SKU_DATA,
        payload: {
          deselectedSku: data[0].dselectedSKU,
        },
      });
    }
  } catch (error) {
  }

}

export const actionResetPlanStatus = reqObj => async dispatch => {
  dispatch({
    type: SET_PLAN_STATUS,
    payload: reqObj.status
  })
}

/*
export const actionBudgetPlanActualColSave = requestObj => async dispatch => {
  dispatch({ type: SET_LOADER, payload: true });
  try {
    const budgetPlanRowData = await postBudgetPlanActualColSave(requestObj);
    const {
      articleNoToArticleLevel,
      sortedBooks,
    } =  formatAticleCampaignLevelToArticleLevel(budgetPlanRowData);
    dispatch({
      type: SET_BUDGET_PLAN_ROW_DATA,
      payload: {
        books: sortedBooks,
        data: articleNoToArticleLevel[requestObj.articleNo],
      }
    });
    dispatch({ type: SET_LOADER, payload: false });
  }catch (error) {
    dispatch({ type: SET_LOADER, payload: false });
  }
}
*/



export const actionBudgetPlanActualColSave = requestObj => async dispatch => {
  dispatch({ type: SET_LOADER, payload: true });
  try {
    const budgetPlanRowData = await postBudgetPlanActualColSave(requestObj);
    const {
      articleNoToArticleLevel,
      sortedBooks,
    } =  formatAticleCampaignLevelToArticleLevel(budgetPlanRowData);
    dispatch({
      type: SET_BUDGET_PLAN_ROW_DATA,
      payload: {
        books: sortedBooks,
        data: articleNoToArticleLevel[requestObj.articleNo],
      }
    });
    dispatch({ type: SET_LOADER, payload: false });
  }catch (error) {
    dispatch({ type: SET_LOADER, payload: false });
  }
}



export const actionYearQuaters = () => async dispatch => {
  dispatch({ type: SET_LOADER, payload: true });
  try {
    const datemappingQuaters = await fetchDateMappingFilterData();
    dispatch({ type: SET_YEAR_QUATERS_DATA, payload: datemappingQuaters });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (error) {
    dispatch({ type: SET_LOADER, payload: false });
  }
}

export const actionResetPlanError = error => async dispatch => {
  dispatch({ type: SET_LOADER, payload: true });
  try {
    dispatch({ type: SET_BUDGET_PLAN_ERROR, payload: error });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (error) {
    dispatch({ type: SET_LOADER, payload: false });
  }
}

export const actionBudgetPlanCampaignList = (payload) => async (dispatch) => {
  try {
    const { selectedYearVal } = payload;
    let campaignList = await fetchCampaignList({
      fyear: [selectedYearVal.value],
      name: 'BBM',
    });
    dispatch({ type: BUDGET_PLAN_CAMPAIGN_LIST, payload: campaignList });
  } catch (error) {
    console.log('error while fetching campaign List');
  }
};

const deduplicatedKey = (key) => {
  return `${key}_deduplicated`
}

const aggregateAllArticleData = (articleData, campaignList) => {
  const result = {}
  const attributesToAggregate = [
    'unitsLy',
    'unitsOptimized',
    'unitsFinalized',
    'revenueLy',
    'revenueOptimized',
    'revenueFinalized',
    'budgetPlannedLy',
    'budgetPlannedOptimized',
    'budgetPlannedFinalized',
    'marginLy',
    'marginOptimized',
    'marginFinalized',
    'incrementalRevenueLy',
    'incrementalRevenueOptimized',
    'incrementalRevenueFinalized',
    'spendLy',
    'spendOptimized',
    'spendFinalized',
    'insertionFeeLy',
    'insertionFeeOptimized',
    'insertionFeeFinalized'
  ];
  articleData.forEach(article => {
    Object.keys(article).forEach(key => {
      if (key.includes('_')) {
        let metrics = key.split('_')[1]
        if (attributesToAggregate.includes(metrics)) {
          const value = (isNaN(article[key]) || !article[key]) ? 0 : article[key];
          result[key] = result[key] || 0;
          result[key] += parseInt(value);
          const _deDuplicatedKey = deduplicatedKey(key);
          result[_deDuplicatedKey] = result[_deDuplicatedKey] || 0;
          result[_deDuplicatedKey] += calculateDeduplicatedValue(article, key, campaignList)
        }
      }
    });
  })
  const books = _.uniq(Object.keys(result).map(key => key.split('_')[0]))
  books.forEach(book => {
    const roiLy = result[`${book}_incrementalRevenueLy_deduplicated`] / (result[`${book}_budgetPlannedLy_deduplicated`]+result[`${book}_insertionFeeLy`]);  
    const roiOptimized = result[`${book}_incrementalRevenueOptimized_deduplicated`] / (result[`${book}_budgetPlannedOptimized_deduplicated`]+result[`${book}_insertionFeeOptimized`]);
    const roiFinalized = result[`${book}_incrementalRevenueFinalized_deduplicated`] /(result[`${book}_budgetPlannedFinalized_deduplicated`]+result[`${book}_insertionFeeFinalized`]);
    result[`${book}_roiLy`] = isNaN(roiLy) ? 0 : roiLy;
    result[`${book}_roiOptimized`] = isNaN(roiOptimized) ? 0 : roiOptimized;
    result[`${book}_roiFinalized`] = isNaN(roiFinalized) ? 0 : roiFinalized;
  })
  return result;
}

const REVENUE = 'revenue';
const UNITS = 'units';
const BUDGET_PLANNED = 'budgetPlanned';
const INSERTION_FEE = 'insertionFee';
const INCREMENTAL_REVENUE = 'incrementalRevenue';
const MARGIN = 'margin';
const ROI = 'roi';
const DISCOUNT = 'discount'
const MEDIATYPE = 'mediaType'
const PROMO_DAYS = 'promoDays'

const getMetricType = (key) => {
  let metricType = '';
  if (key.includes(REVENUE)) {
    metricType = REVENUE;
  } else if (key.includes(INCREMENTAL_REVENUE)) {
    metricType = INCREMENTAL_REVENUE;
  } else if (key.includes(UNITS)) {
    metricType = UNITS;
  } else if (key.includes(BUDGET_PLANNED)) {
    metricType = BUDGET_PLANNED;
  } else if (key.includes(INSERTION_FEE)) {
    metricType = INSERTION_FEE;
  } else if (key.includes(MARGIN)) {
    metricType = MARGIN;
  } else if (key.includes(ROI)) {
    metricType = ROI;
  }
  return metricType
}

const calculateDeduplicatedValue = (article, key, campaignList) => {

  const getPrevAndNextCampaignNames = (campaignName) => {
    let [offerType, cycle, fyear] = campaignName.split('-');
    cycle = parseInt(cycle)
    return [`${offerType}-${cycle - 1}-${fyear}`, `${offerType}-${cycle + 1}-${fyear}`]
  }

  const calculateExtraValue = (value, overlapDays, fullDays) => {
    return (value/parseInt(fullDays)) * overlapDays
  }

  const getMetricYear = () => {
    const underScoreIndex = key.indexOf('_') + 1;
    const metricType = getMetricType(key);
    const metricYear = key.substring(underScoreIndex + metricType.length);
    return metricYear;
  }

  const getArticleCyclePromoDays = (campaignName, metricYear) => {
    if (metricYear == 'Ly' || metricYear == 'LY') {
      return 28;
    }
    const promoDaysKey = `${campaignName}_${PROMO_DAYS}${metricYear}`
    return article[promoDaysKey]
  }

  const getPrevAndNextOverlapDays = (prevCampaignName, campaignName, nextCampaignName) => {
    const dateFormatter = 'YYYY-MM-DD';
    const prevCampaign = campaignList[prevCampaignName];
    const nextCampaign = campaignList[nextCampaignName];
    const currentCampaign = campaignList[campaignName];
    const metricYear = getMetricYear();

    let prevCampaignOverlapDays = 0, nextCampaignOverlapDays = 0;
    if (prevCampaign) {
      const prevCampaignPromoDays = getArticleCyclePromoDays(prevCampaignName, metricYear)
      const prevCampaignEndDate = moment(prevCampaign.actualStartDate, dateFormatter).add(prevCampaignPromoDays, 'days')
      prevCampaignOverlapDays = prevCampaignEndDate.diff(moment(currentCampaign.actualStartDate, dateFormatter), 'days');
    }
    if (nextCampaign) {
      const currentPromoDays = getArticleCyclePromoDays(campaignName, metricYear);
      const currentCampaignEndDate = moment(currentCampaign.actualStartDate, dateFormatter).add(currentPromoDays, 'days');
      nextCampaignOverlapDays = currentCampaignEndDate.diff(moment(nextCampaign.actualStartDate , dateFormatter), 'days')
    }
    return [prevCampaignOverlapDays, nextCampaignOverlapDays]
  }

  const getFinalizedExtraValue = (article, metricType, campaignName, prevOrNextCampaignName, prevOrNextOverlappingDays) => {
    let metricYear = getMetricYear();

    const value = article[key];
    const currentPromoDays = getArticleCyclePromoDays(campaignName, metricYear);
    const prevOrNextDiscount = article[`${prevOrNextCampaignName}_${DISCOUNT}${metricYear == 'Ly' ? 'LY' : metricYear}`];
    if (prevOrNextDiscount == undefined) {
      return 0;
    }
    if (prevOrNextOverlappingDays <= 0) {
      return 0;
    }
    let prevOrNextExtraValue = 0;
    if (value != 0) {
      const currentDiscount = article[`${campaignName}_${DISCOUNT}${metricYear == 'Ly' ? 'LY' : metricYear}`];
      if (currentDiscount < prevOrNextDiscount) {
        prevOrNextExtraValue = calculateExtraValue(value, prevOrNextOverlappingDays, currentPromoDays)
      } else if (currentDiscount == prevOrNextDiscount) {
        const prevOrNextMediaType = article[`${prevOrNextCampaignName}_${MEDIATYPE}${metricYear == 'Ly' ? 'Optimized' : metricYear}`];
        const currentMediaType = article[`${campaignName}_${MEDIATYPE}${metricYear == 'Ly' ? 'Optimized' : metricYear}`];
        if (currentMediaType == 'Paper Coupon' && prevOrNextMediaType == 'Clipless Coupon') {
          prevOrNextExtraValue = calculateExtraValue(value, prevOrNextOverlappingDays, currentPromoDays)
        } else if (currentMediaType == prevOrNextMediaType) {
          const prevOrNextValue = article[`${prevOrNextCampaignName}_${metricName}`];
          if (value <= prevOrNextValue) {
            prevOrNextExtraValue = calculateExtraValue(value, prevOrNextOverlappingDays, currentPromoDays)
          }
        }
      }
    }
    return prevOrNextExtraValue
  }

  const [campaignName, metricName] = key.split('_');
  const [prevCampaignName, nextCampaignName] = getPrevAndNextCampaignNames(campaignName);
  const [prevOverlappingDays, nextOverlappingDays] = getPrevAndNextOverlapDays(prevCampaignName, campaignName, nextCampaignName);

  const metricType = getMetricType(key);
  const metricYear = getMetricYear()
  const value = article[key]
  if (metricType == REVENUE
    || metricType == INCREMENTAL_REVENUE
    || metricType == UNITS
    || metricType == BUDGET_PLANNED
    || metricType == MARGIN) {
      const prevExtraValue = getFinalizedExtraValue(article, metricType, campaignName, prevCampaignName, prevOverlappingDays)
      const nextExtraValue = getFinalizedExtraValue(article, metricType, campaignName, nextCampaignName, nextOverlappingDays)
      return value - prevExtraValue - nextExtraValue;
  } else {
    return value
  }
}

export const actionUpdateBudgetMetricsTable = ({ articleData, budgetPlanCampaignList }) => async dispatch => {
  if (budgetPlanCampaignList.length == 0) {
    return;
  }
  const campaignList = _.keyBy(budgetPlanCampaignList, 'name')
  let _articleData = articleData;
  if (!Array.isArray(articleData)) {
    articleData = [articleData];
  }
  _articleData = aggregateAllArticleData(articleData, campaignList);
  const metricsYearByIndexes = {
    Ly: 0,
    Optimized: 1,
    Finalized: 2,
    Change: 3
  }
  const metricsYearColumnName = {
    Ly: "LY",
    Optimized: "Optimized",
    Finalized: "Finalized",
    Change: "% Change"
  }

  // SET_BUDGET_PLAN_METRICS_TABLE
  const metricsRowData = Object.assign({}, {
    revenue: [
      {
        MetricCategory: { name: "Net Sales ($)" },
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 0)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 1)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 2)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 3)]
      }
    ],
    [INCREMENTAL_REVENUE]: [
      {
        MetricCategory: { name: "Inc Sales ($)" },
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 0)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 1)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 2)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 3)]
      }
    ],
    units: [
      {
        MetricCategory: { name: "Units" },
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 0)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 1)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 2)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 3)]
      }
    ],
    margin: [
      {
        MetricCategory: { name: "Margin ($)" },
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 0)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 1)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 2)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 3)]
      }
    ],
    roi: [
      {
        MetricCategory: { name: "ROI (Sales)" },
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 0)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 1)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 2)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 3)]
      }
    ],
    budgetPlanned: [
      {
        MetricCategory: { name: "Coupon Redemptions ($)" },
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 0)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 1)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 2)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 3)]
      }
    ],
    insertionFee: [
      {
        MetricCategory: { name: "Insertion Fee ($)" },
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 0)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 1)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 2)]
      },
      {
        yearBy: metricsYearColumnName[findKeyFromObject(metricsYearByIndexes, 3)]
      }
    ]
  });

  const bookNumbers = [];
  Object.keys(_articleData).forEach(key => {
    const cycle = key.split('-')[1]
    if (key.startsWith("BBM") || key.startsWith("FOC")) {
      if (!bookNumbers.includes(cycle)) {
        bookNumbers.push(cycle)
      }
    }

    const metricType = getMetricType(key);
    if (!(metricType == REVENUE ||
      metricType == INCREMENTAL_REVENUE ||
      metricType == UNITS ||
      metricType == BUDGET_PLANNED ||
      metricType == INSERTION_FEE ||
      metricType == MARGIN ||
      metricType == ROI)) {
        return;
      }
    const underScoreIndex = key.indexOf('_') + 1;
    let metricYear = key.substring(underScoreIndex + metricType.length);
    if (Object.keys(metricsYearByIndexes).includes(metricYear)) {
      let value = _articleData[key] === Infinity ? 0 : _articleData[key]
      metricsRowData[metricType][metricsYearByIndexes[metricYear]]['total'] =
          metricsRowData[metricType][metricsYearByIndexes[metricYear]]['total'] || 0;
      const deDuplicatedValue = _articleData[deduplicatedKey(key)]
      metricsRowData[metricType][metricsYearByIndexes[metricYear]]['total'] += deDuplicatedValue;
      metricsRowData[metricType][metricsYearByIndexes[metricYear]][`Book${cycle}`] = value;
    }
    // metricsRowData[metricType][metricsYearByIndexes[metricYear]]['total'] = 
      // converToPercentageFloat(metricsRowData[metricType][metricsYearByIndexes[metricYear]]['total']);
    // const metrics = Object.keys(metricsRowData);
  })
  let isRoiSales = false;
  for (let key in metricsRowData) {
    if (metricsRowData[key][0].MetricCategory) {
      isRoiSales = metricsRowData[key][0].MetricCategory.name == 'ROI (Sales)' ? true : false;
      if (isRoiSales) {
        const lyRevenue = metricsRowData[INCREMENTAL_REVENUE][metricsYearByIndexes.Ly]['total'];
        const lySpend = metricsRowData[INSERTION_FEE][metricsYearByIndexes.Ly]['total']
          + metricsRowData[BUDGET_PLANNED][metricsYearByIndexes.Ly]['total'];
        const lyROI = lyRevenue / lySpend;
        metricsRowData[key][metricsYearByIndexes.Ly].total = formatToTwoDecimals(lyROI);

        const optimizedRevenue = metricsRowData[INCREMENTAL_REVENUE][metricsYearByIndexes.Optimized]['total'];
        const optimizedSpend = metricsRowData[INSERTION_FEE][metricsYearByIndexes.Optimized]['total']
          + metricsRowData[BUDGET_PLANNED][metricsYearByIndexes.Optimized]['total'];
        const optimizedROI = optimizedRevenue / optimizedSpend;
        metricsRowData[key][metricsYearByIndexes.Optimized].total = formatToTwoDecimals(optimizedROI);

        const finalizedRevenue = metricsRowData[INCREMENTAL_REVENUE][metricsYearByIndexes.Finalized]['total'];
        const finalizedSpend = metricsRowData[INSERTION_FEE][metricsYearByIndexes.Finalized]['total']
          + metricsRowData[BUDGET_PLANNED][metricsYearByIndexes.Finalized]['total'];
        const finalizedROI = finalizedRevenue / finalizedSpend;
        metricsRowData[key][metricsYearByIndexes.Finalized].total = formatToTwoDecimals(finalizedROI);
      }
    }
  }

  for (let key in metricsRowData) {
    for (let bookNumber in metricsRowData[key][metricsYearByIndexes.Finalized]) {
      if (bookNumber == 'yearBy') continue;
      const lyValue = metricsRowData[key][metricsYearByIndexes.Ly][bookNumber];
      const finalizedValue = metricsRowData[key][metricsYearByIndexes.Finalized][bookNumber];
      const changeValue = 100 * (finalizedValue - lyValue) / lyValue;
      metricsRowData[key][metricsYearByIndexes.Change][bookNumber] = converToPercentageFloat(changeValue);

      if (key != ROI) {
        metricsRowData[key][metricsYearByIndexes.Ly][bookNumber] = convertMillionsFormatter(lyValue)
        metricsRowData[key][metricsYearByIndexes.Finalized][bookNumber] = convertMillionsFormatter(finalizedValue)
        metricsRowData[key][metricsYearByIndexes.Optimized][bookNumber] = convertMillionsFormatter(metricsRowData[key][metricsYearByIndexes.Optimized][bookNumber])
      } else {
        metricsRowData[key][metricsYearByIndexes.Ly][bookNumber] = formatToTwoDecimals(lyValue)
        metricsRowData[key][metricsYearByIndexes.Finalized][bookNumber] = formatToTwoDecimals(finalizedValue)
        metricsRowData[key][metricsYearByIndexes.Optimized][bookNumber] = formatToTwoDecimals(metricsRowData[key][metricsYearByIndexes.Optimized][bookNumber])
      }
    }
  }
  const data = [
    ...metricsRowData['units'],
    ...metricsRowData['incrementalRevenue'],
    ...metricsRowData['revenue'],
    ...metricsRowData['margin'],
    ...metricsRowData['roi'],
    ...metricsRowData['budgetPlanned'],
    ...metricsRowData['insertionFee']
  ]
  dispatch({ type: SET_BUDGET_PLAN_METRICS_TABLE, payload: {
    data,
    books: bookNumbers.sort((a, b) => parseInt(a) - parseInt(b)),
    articleNo: articleData.length == 1 ? articleData[0].articleNo : undefined,
    articleDescription: _articleData.description,
  }});
}

export const actionSaveOrSubmitPlan = reqObj => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    await postSaveOrSubmit(reqObj);
    dispatch({ type: SET_PLAN_STATUS, payload: reqObj.status });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
}

export const actionResetBudgetPlanIdData = reqObj => async dispatch => {
  dispatch({
    type: RESET_BUDGET_PLAN_ID_DATA,
  })
}

export const resetVendorHistoryTable = reqObj => async dispatch =>{
  dispatch({ type: RESET_VENDORHISTORY_TABLE, payload: [] });
}

export const resetOptimizationLink= reqObj => async dispatch =>{
  dispatch({ type: RESET_OPTIMIZATION_LINK, payload: null });
}

export const setBudgetPlanHeaders = reqObj => async dispatch =>{
  dispatch({ type: SET_BUDGET_PLAN_HEADERS, payload: reqObj });
}

export const resetBudgetPlanHeaders= reqObj => async dispatch =>{
  dispatch({ type: RESET_BUDGET_PLAN_HEADERS, payload: {} });
}

export const retryPlanArticleCampaigns= planId => async dispatch =>{
  const planArticleCampaigns = await fetchPlanArticleCampaigns(planId);
  if(planArticleCampaigns.length > 0){
    return planArticleCampaigns;
  }else{
    return []
  }
}

export const actionGetAh6Data = reqObj => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await getAh6Data(reqObj);
    const payload = result.map(data => {return {value: data, label: data}})
    dispatch({ type: SET_AH6_DATA, payload });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
}

export const actionUpdateVendorHistory = payload => async dispatch=>{
  dispatch({type: SET_VENDORHISTORY_TABLE, payload})
}

export const actoinUpdateInactiveMetrics = ({articles, budgetPlanCampaignList}) => async dispatch => {
  if (budgetPlanCampaignList.length == 0) {
    return;
  }
  const campaignList = _.keyBy(budgetPlanCampaignList, 'name')

  let selectedArticleNum = undefined;
  let lyRevenue = {
    total: 0
  }, lyMargin = {
    total: 0
  }, lyIncRevenue = {
    total: 0
  }, lyUnits = {
    total: 0
  }, lyInsFee = {
    total: 0
  }, lySpend ={
    total: 0
  };
  
  if(articles.length === 1)  {
    selectedArticleNum = articles[0].articleNo;
  }
  articles.forEach(element => {
      const keys = Object.keys(element);

      keys.forEach(key => {
        const tempKey = `book${key.split('_')[0].split('-')[1]}`;
        if(key.endsWith('revenueLy')){
          lyRevenue[tempKey]? lyRevenue[tempKey] += element[key] : lyRevenue[tempKey] = element[key];
          // debugger;
          lyRevenue.total += calculateDeduplicatedValue(element, key, campaignList);
        }
        if(key.endsWith('unitsLy')){
          lyUnits[tempKey]?  lyUnits[tempKey] += element[key] : lyUnits[tempKey] = element[key];
          lyUnits.total += calculateDeduplicatedValue(element, key, campaignList);
        }
        if(key.endsWith('marginLy')){
          lyMargin[tempKey]? lyMargin[tempKey] += element[key] : lyMargin[tempKey] = element[key];
          lyMargin.total += calculateDeduplicatedValue(element, key, campaignList);
        }
        if(key.endsWith('incrementalRevenueLy')){
          lyIncRevenue[tempKey]? lyIncRevenue[tempKey] += element[key] : lyIncRevenue[tempKey] = element[key];
          lyIncRevenue.total += calculateDeduplicatedValue(element, key, campaignList);
        }
        if(key.endsWith('insertionFeeLy')){
          lyInsFee[tempKey]? lyInsFee[tempKey] += element[key] : lyInsFee[tempKey] = element[key];
          lyInsFee.total += element[key];
        }
        if(key.endsWith('spendLy')){
          lySpend[tempKey]? lySpend[tempKey] += element[key] : lySpend[tempKey] = element[key];
          lySpend.total += calculateDeduplicatedValue(element, key, campaignList);
          // lySpend.total += element[key];
        }
      })
    });

    Object.keys(lyRevenue).forEach(key => {
      if(typeof(lyRevenue[key]) == "number"){
        lyRevenue[key] = convertMillionsFormatter(lyRevenue[key]);
      }
    });

    Object.keys(lyMargin).forEach(key => {
      if(typeof(lyMargin[key]) == "number"){
        lyMargin[key] = convertMillionsFormatter(lyMargin[key]);
      }
    });

    Object.keys(lyIncRevenue).forEach(key => {
      if(typeof(lyIncRevenue[key]) == "number"){
        lyIncRevenue[key] = convertMillionsFormatter(lyIncRevenue[key]);
      }
    });

    Object.keys(lyInsFee).forEach(key => {
      if(typeof(lyInsFee[key]) == "number"){
        lyInsFee[key] = convertMillionsFormatter(lyInsFee[key]);
      }
    });

    Object.keys(lySpend).forEach(key => {
      if(typeof(lySpend[key]) == "number"){
        lySpend[key] = convertMillionsFormatter(lySpend[key]);
      }
    });


    Object.keys(lyUnits).forEach(key => {
      if(typeof(lyUnits[key]) == "number"){
        lyUnits[key] = convertMillionsFormatter(lyUnits[key]);
      }
    });

if(articles.length>0){
    lyRevenue.campaignType = "Net Sales ($)";
    lyMargin.campaignType = "Margin ($)";
    lyIncRevenue.campaignType = "Inc Sales ($)";
    lyUnits.campaignType = "Units";
    lySpend.campaignType = "Coupon Redemptions ($)";
    lyInsFee.campaignType = "Insertion Fee ($)";
    lyRevenue.selectedArticleNum = selectedArticleNum;
}
    const payload = articles.length>0?[lyRevenue,lyMargin,lyIncRevenue,lyUnits,lyInsFee,lySpend]:[];

    dispatch({type: SET_INACTIVE_METRICS_DATA, payload})
}

export const actionUpdateBrandBudget = payload => dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    dispatch({ type: SET_VENDORHISTORY_BRAND_TABLE, payload });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
}

export const actionCopyPlan = reqObj => async dispatch => {
  try {
    console.log("Plan details:",reqObj);
    dispatch({ type: SET_LOADER, payload: true });
    const result = await copyPlanData(reqObj);
    console.log("result:",result);
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
}