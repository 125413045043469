import styled from "styled-components";
import ModalBody from "react-bootstrap/ModalBody";
export const ModalBodyStyled = styled.div`
.modal-body {
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 1rem 2.5rem;
};
label{
    margin: 0;
    font-size:1rem;
    font-weight: 600;
    display: flex;
};
input {
    width: 100%;
    padding: 0px 15px;
}
`;
