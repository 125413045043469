import React, { Fragment, useState, useEffect } from 'react';
import { AgGridReact } from 'ag-grid-react';
import classNames from 'classnames';
import { METRIC_COLUMN_NAMES } from './constants';
const PromoHierarchyTable = props => {
  
  const [diffDays, setDiffDays] = useState(null);
  const [gridApi, setGridApi] = useState({});
  useEffect(() => {
    if(Object.keys(gridApi).length > 0){
      if(props.rowData.length > 25){
        gridApi.setDomLayout("normal");
        document.querySelector("#defaultGrid").style.height = "750px";
      }else{
        gridApi.setDomLayout("autoHeight");
        document.querySelector("#defaultGrid").style.height = '';
      }
    }
  }, [props.rowData])
  useEffect(() => {
    if (Object.keys(props.promoPipeLineObj).length > 0) {
      const startDate = props.promoPipeLineObj.startDate;
      const endDate = props.promoPipeLineObj.endDate;
      const date1 = new Date(endDate);
      const date2 = new Date(startDate);
      const diffTime = Math.abs(date2.getTime() - date1.getTime()) + 1;
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setDiffDays(diffDays)
    }
  }, [props.promoPipeLineObj])
  const onGridReady = params => {
    setGridApi(params.api);
    params.columnApi.autoSizeAllColumns();
  };
  return (
    <>
    <div className=" card calendarContainer" id="main-calendar-container" style={{padding:"15px 15px"}}>

        <div className="spacer"></div>
        <div className="row">
          <div className="promolist-header">
            <label className="pipelineLabel downloadLbel">
              Download Promotion
            </label>
          </div>
          <button
            className="btn btn-primary iconbutton download-btn"
            onClick={props.handleDownload}
            disabled={props.rowData.length ===0}
          >
            <span>
              <i class="fa fa-download" aria-hidden="true" />
            </span>
          </button>
        </div>
        <div className="row ">
          <div className="col-md-12">
            <div
              className="row"
              style={{
                marginLeft: "12px",
              }}
            >
              <div className="col-md-4">
                <span className="promoNameText row">
                  Campaign Name:{" "}
                  {props.promoPipeLineObj.name ? (
                    <p className="promonameResult">
                      {props.promoPipeLineObj.name}
                    </p>
                  ) : null}
                </span>
              </div>
              <div className="col-md-3">
                <span
                  className="promoNameText row"
                  style={{
                    paddingLeft: "2%",
                  }}
                >
                  Start Date:{" "}
                  {props.promoPipeLineObj.startDate ? (
                    <p className="promonameResult">
                      <b>{props.promoPipeLineObj.startDate}</b>
                    </p>
                  ) : null}
                </span>
              </div>
              <div className="col-md-3">
                <span
                  className="promoNameText row"
                  style={{
                    paddingLeft: "2%",
                  }}
                >
                  End Date:{" "}
                  {props.promoPipeLineObj.endDate ? (
                    <p className="promonameResult">
                      <b>{props.promoPipeLineObj.endDate}</b>
                    </p>
                  ) : null}
                </span>
              </div>
              <div className="col-md-2">
                <span
                  className="promoNameText row"
                  style={{
                    paddingLeft: "2%",
                  }}
                >
                  # of days:{" "}
                  {diffDays ? (
                    <p className="promonameResult">
                      {diffDays ? diffDays : ""}
                    </p>
                  ) : null}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="pipelineGrid" style={{marginBottom:"30px"}}>
          <div
            id="defaultGrid"
            className="ag-theme-balham"
            style={{
              // height: "100%",
              width: "100%",
            }}
          >
            <AgGridReact
              floatingFilter={true}
              defaultColDef={{
                resizable: true,
              }}
              multiSortKey="ctrl"
              columnDefs={METRIC_COLUMN_NAMES}
              rowData={props.rowData}
              onGridReady={onGridReady}
              domLayout="autoHeight"
              enableCellTextSelection={true}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default PromoHierarchyTable;
