import autoPromoData from "../reducers/autoPromoCreation";
import promoConfigReducer from "./promoConfigReducer";
import { combineReducers } from "redux";
import chartReducer from "./chart";
import errorReducer from "./error";
import edaReducer from "./EdaReducer";
import authReducer from "./authReducer";
import promoReducer from "./promoReducer";
import filterReducer from "./filterReducer";
import budgetReducer from "./budgetPlanningReducer";
import userReducer from "./userReducer";
import budgetMasterReducer from "./budgetMasterReducer";
import skuMasterReducer from "./skuMasterReducer";
import createPromoReducer from "./createPromoReducer";
import finalizePromoReducer from "./finalizePromoReducer";
import constraintsReducer from "./constraintsReducer";
import promoUploadReducer from "../components/PromoUpload/promoUploadReducer";
// splitted components reducers
import calendarReducer from "../components/PromoCalendar/promoCalendarReducer";
import AnalyEventReducer from "../components/AnalyzeEvent/analyzeEventReducer";
import budgetPlanningReducer from "../components/BudgetPlanning/budgetPlanReducer";
import userManagementReducer from "./userManagementReducer";
import planSummaryReducer from "../components/PlanSummary/planSummaryReducer";
// import {skuMasterReducer as compskuMasterReducer} from "../components/SkuMaster/skuMasterReducer";
import executiveSummaryReducer from "./executiveSummaryReducer";


export default combineReducers({
  errors: errorReducer,
  chartData: chartReducer,
  edaChartData: edaReducer,
  authReducer: authReducer,
  calendar: calendarReducer,
  autoPromoCreation: autoPromoData,
  promoData: promoReducer,
  filterData: filterReducer,
  promoConfigData: promoConfigReducer,
  budgetData: budgetReducer,
  budgetCalendar: budgetReducer,
  analyzeEvent: AnalyEventReducer,
  budgetPlan: budgetPlanningReducer,
  planSummary: planSummaryReducer,
  userReducer: userReducer,
  budgetMasterData: budgetMasterReducer,
  promoUploadData:promoUploadReducer,
  // skumMaster: compskuMasterReducer,
  skuMasterData: skuMasterReducer,
  createPromoData: createPromoReducer,
  finalizePromoData:finalizePromoReducer,
  userManagementData: userManagementReducer,
  constraintsData: constraintsReducer,
  dashboardData:executiveSummaryReducer
});

/*
import { combineReducers } from 'redux';
import errorReducer from './error';
//import calendarReducer from './calendar';
import calendarReducer from '../../components/Content/Promotions/PromoCalendar/calendarReducer.js';
import adGroupReducer from './adgroup';
import simulationReducer from './simulationReducer';
import createPromo from './createPromoReducer';
//import summaryReducer from './summaryReducer';
import summaryReducer from '../../components/Content/Promotions/PromoCalendar/summaryReducer.js';
import validationData from './validationReducer';
import loginReducer from './loginReducer';
import shopDataReducer from "./shopDataReducer";
import compareReducer from "./compareReducer";
import edaReducer from "./edaReducer";
//../../components/Content/Promotions/PromoCalendar/calendarReducer.js

export default combineReducers({
  errors: errorReducer,
  calendar: calendarReducer,
  adGroupData: adGroupReducer,
  simulationData: simulationReducer,
  createPromoData: createPromo,
  summaryData: summaryReducer,
  validationDatas: validationData,
  userLoginDetails: loginReducer,
  shopDataReducer: shopDataReducer,
  compareReducer: compareReducer,
  edaChartData: edaReducer,
});
*/
