import React, { Component } from 'react';
import { AgGridReact } from 'ag-grid-react';
import TableWrapper from "../common/TableWrapper";
import './planSummary.css';
import {connect} from "react-redux";
import {createTotalRowData} from "./planSummaryAction";
class OfferLevelTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            defaultColDef: {
                width: 70,
                resizable: true
            },
            pinnedData:[],
            flag:false
        };
    }   
    handleFilterChange=(params)=>{
     this.gridApi = params.api;
      let tableData=[];
      this.gridApi.forEachNodeAfterFilter((node, index)=>{
        tableData.push(node.data);
      });
      console.log("Filtered Data:",tableData);
      if(tableData.length!= this.props.rowData.length){
        let pinnedRowData= this.props.getPinnedRowData(tableData, this.props.offercoldef);
        this.setState({
            pinnedData:pinnedRowData,
            flag:true
        });
      }
      else{
        let pinnedRow=this.props.pinnedData;
        this.setState({
            pinnedData:pinnedRow,
            flag:false
        });  
      }
    } 
    render() {
        return (
            <div className='pipelineGrid' style={{ marginTop: "30px", height: '450px' }}>
                <div
                    id='defaultGrid'
                    className='ag-theme-balham'
                    style={{
                        width: "100%",
                        height:"100%"
                    }}>
                    <AgGridReact
                        floatingFilter={true}
                        columnDefs={this.props.offercoldef}
                        rowData={this.props.rowData}
                        rowHeight="30"
                        getRowStyle ={ function (params) {
                            if (params.node.rowPinned) {
                              return { 'background-color': '#D3D3D3' };
                            }
                          }
                        }
                        onFilterChanged={this.handleFilterChange}
                        pinnedTopRowData={this.state.flag==false?this.props.pinnedData:this.state.pinnedData}
                        defaultColDef={this.state.defaultColDef}
                    />
                </div>
            </div>
        );
    }
}

const mapStateToProps = (store) => {
    return {
        pinnedData: store.planSummary.offerPinnedData
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        getPinnedRowData: (val1, val2)=>createTotalRowData(val1, val2)
    };
};
export default connect(mapStateToProps,mapDispatchToProps)(OfferLevelTable);