import {
    SET_SKU_DATA,
    SET_LOADER,
    RESET_SKU_MASTER,
    SET_REF_SKU_INFO,
    RESET_FILTERS_DATA,
    SET_FILTERS_DATA,
    SET_BRAND_DATA,
    ADD_UPDATE_SKU,
} from "../actions/types";

const initialState = {
    skuData: {
        data: [], // data for each pagegroupno and pagegroupsize
        totalCount: 0, // total no of records
        pageGroupNo: 1, //page group number
        pageGroupSize: 500, // no of records to show per page
        deselectedSku: [],
    },
    loading: false,
    refSkuInfo:[],
    prevStateSelectedFilters:{
        merchcat: [],
        vendor: [],
        ah6: []
    },
    brandData: [],
    vendorData: [],
    addUpdateSku: ''
};

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case SET_LOADER:
            return {
                ...state,
                loading: payload,
            };
        case SET_SKU_DATA:
            return {
                ...state,
                skuData: {
                    ...state.skuData,
                    ...payload,
                },
            };
        case RESET_SKU_MASTER: 
            return {
                ...state,
                skuData: {
                    ...state.skuData,
                    data:[]
                }
            }
        case SET_REF_SKU_INFO:
            return {
                    ...state,
                    ...payload,
            };
        case SET_FILTERS_DATA:
             return {
                    ...state,
                    prevStateSelectedFilters:payload,
            };
        case RESET_FILTERS_DATA:
             return {
                    ...state,
                    prevStateSelectedFilters:payload,
            };
            case SET_BRAND_DATA:
                return {
                    ...state,
                    brandData: payload
                };
        case 'SET_VENDOR_DATA':
            return {
                ...state,
                vendorData: payload
            }
        case ADD_UPDATE_SKU:
            return {
                ...state,
                addUpdateSku: payload
            };

        default:
            return {
                ...state
            };
    }
}
