import { SET_LOADER, GET_CONSTRAINTS_LIST, RESET_CONSTRAINTS_LIST, SET_PLANID, SET_VENDOR_LIST, SET_ARTICLE_LIST, SET_ARTICLE_DETAILS, SET_GROUP_DETAILS, SET_SELECTED_ARTICLE } from '../actions/types';

const initialState = {
  constraintsList: [],
  planNumber: "",
  loader: false,
  vendorList: [],
  articleList: [],
  groupArticleData: [],
  groupDetails: {},
  selectedArticle: []
};

export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case SET_LOADER:
      return {
        ...state,
        loader: payload,
      };
    case GET_CONSTRAINTS_LIST:
      return {
        ...state,
        constraintsList: payload,
      };
    case SET_PLANID:
      return{
        ...state,
        planNumber: payload
      }
    case SET_VENDOR_LIST: 
      return {
        ...state,
        vendorList: payload
      }
    case SET_ARTICLE_LIST: 
      return {
        ...state,
        articleList: payload
      }
    case SET_ARTICLE_DETAILS:
        return {
          ...state,
          groupArticleData: payload
        }
    case SET_GROUP_DETAILS: 
        return {
          ...state,
          groupDetails: payload
        }
    case SET_SELECTED_ARTICLE: 
        return {
          ...state,
          selectedArticle: payload
        }
    default:
      return {
        ...state,
      };
  }
}
