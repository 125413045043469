import React from 'react';
import { Modal } from 'react-bootstrap';
import PromoAnalysis from '../analyzePromo/promoAnalysis';
import './CustomModal.css';
import { PROMO_LEVEL, PROMO_ARTICLE_LEVEL } from '../../constants';
export default class CustomModal extends React.Component {
  render() {
    //
    // var type =
    //   this.props.currentPromo && this.props.currentPromo.promoType
    //     ? OFFER_TYPE_OPTIONS.filter(
    //         type => type.value === this.props.currentPromo.promoType
    //       )[0].label
    //     : 'SIMPLE PERCENTAGE DISCOUNT';
    // var value =
    //   this.props.currentPromo && this.props.currentPromo.promoValue
    //     ? this.props.currentPromo.promoValue.toFixed(2)
    //     : '51.77';
    // const prefix = '$';
    // const postfix = '%';
    return (
      <div>
        <Modal size="lg" show={this.props.showModal}>
          <Modal.Body>
            <span
              onClick={this.props.closeModal}
              className="pull-right"
              style={{ cursor: 'pointer' }}
            >
              <i class="fa fa-times" aria-hidden="true" />
            </span>
            <div className="modal-htitle" >
            <div className="row">
              <div className="col-md-6">
                Offer Name: &nbsp;
                <span style={{ fontWeight: 'bolder' }}>
                  {this.props.currentPromo.title}
                </span>
              </div>
              <div className="col-md-4">
                Offer Type: &nbsp;
                <span className="font-bold font-size-13">
                  {this.props.currentPromo.actualCouponType}
                </span>
              </div>
              <div className="col-md-2">
                Book: &nbsp;
                <span className="font-bold font-size-13">
                  {this.props.currentPromo.mediaCycle}
                </span>
              </div>

            </div>
             {this.props.selectedTabPromoLevel === PROMO_ARTICLE_LEVEL ? (
              <>
              <div className="row">
              <div className="col-md-8">
                Article Name: &nbsp;
              <span className="font-bold font-size-13">
                {this.props.currentPromo.articleDesc}
              </span>
            </div>
            <div className="col-md-4">
            Article Number: &nbsp;
            <span className="font-bold font-size-13">
              {this.props.currentPromo.articleNo}
            </span>
            </div>
            </div>
              </>
            ):null}
 
            </div>
            <PromoAnalysis {...this.props} analysisType={false} />
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}
