import React, { Component } from 'react';
import MultiSelectCheckboxes from '../MultiSelectCheckboxes';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import {
  fetchHierarchyData,
  fetchDateMappingFilterData
} from '../../apis/common.api.';
import { InputGroup, FormControl, Form, Col, Row } from 'react-bootstrap';
import { default as ReactSelect } from 'react-select';
export default class Filters extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ...this.props.options
    };
  }

  async componentDidMount() {
    const promises = [];
    if (this.props.hierarchyApiCalls.length > 0) {
      this.props.hierarchyApiCalls.forEach(obj => {
        let promise;
        if (obj.UrlName == 'selectedYear' || obj.UrlName == 'selectedFiscalYear' || obj.UrlName ==  'selectedPrevYear') {
          promise = fetchDateMappingFilterData({
            screenName: this.props.screenName
          });
        } else {
          if(obj.UrlName != '') {
          promise = fetchHierarchyData(obj.UrlName, {
            screenName: this.props.screenName
          });
        }}
        promises.push(promise);
      });
    }
    this.props.openLoader();
    try {
      const res = await Promise.all(promises);
      let optionsStateObj = {};
      this.props.hierarchyApiCalls.forEach((obj, index) => {
        this.props.closeLoader();
        const data = res[index];
        // if we need customized dropdown value. like concatnating mulitiple columns in a object.
        if (['selectedYear', 'article' , 'quarters'].indexOf(obj.optionsMappingKey) !== -1) {
          let tempData = [];
          if (obj.UrlName == 'selectedPrevYear') {
            tempData = data.filter((obj) => {
              var currentDate = new Date();
              var dStartDate = new Date(obj.startDate);
              if (currentDate > dStartDate) {
                return obj;
              }
            });
          } else {
            tempData = data;
          }
          optionsStateObj[`${obj.optionsMappingKey}Options`] = tempData.map(
            object => {
              object.value = `${object.year}-Q${object.quater}`;
              object.label = `${object.year}-Q${object.quater}`;
              return object;
            }
          );
          return true;
        }
        if (['year'].indexOf(obj.optionsMappingKey) !== -1) {
          const years = [];
          data.forEach( row => {
            if (!years.includes(row.year)) years.push(row.year);
          });
          let yearOption = [];
          if(obj.UrlName == 'selectedFiscalYear'){
            const currentDate = new Date();
            const futureYrs = this.props.screenName === 'finalize' ? years.filter(year => year >= currentDate.getFullYear()) : years.filter(year => year > currentDate.getFullYear());
            yearOption = futureYrs.map(year => ({ value: year, label: year }));
          }else{
          yearOption = years.map(year => ({ value: year, label: year }));
          }
          optionsStateObj[`${obj.optionsMappingKey}Options`] = yearOption;
          const defaultYearData = this.props.filtersList.filter(obj=> obj.mappingKey === 'year');
          if(defaultYearData.length > 0 && defaultYearData[0].state.length > 0){
            this.updateState({year: defaultYearData[0].state})
          }else{
            this.updateState({year: [yearOption[0]]})
          }
          return true;
        }
        if (['quarter'].indexOf(obj.optionsMappingKey) !== -1) {
          const quaters = [];
          data.forEach( option => {
            if (!quaters.includes(option.quater)) quaters.push(option.quater);
          });
          const QuatersOptions = quaters.map(quater => ({ value: `Q${quater}`, label: `Q${quater}` }));
          optionsStateObj[`${obj.optionsMappingKey}Options`] = QuatersOptions ;
          const defaultQuarterData = this.props.filtersList.filter(obj=> obj.mappingKey === 'quarter');
          if(defaultQuarterData.length > 0 && defaultQuarterData[0].state.length > 0){
            this.updateState({quarter: defaultQuarterData[0].state})
          }else{
            this.updateState({quarter: [...QuatersOptions]})
          }
          
          return true;
        }
        if(['offerType'].indexOf(obj.optionsMappingKey) !== -1) {
          const offerTypeOptions = [{value: 'BBM', label:'BBM'}, {value: 'FOC', label:'FOC'}];
          optionsStateObj[`${obj.optionsMappingKey}Options`] = offerTypeOptions ;
          const defaultofferType = this.props.filtersList.filter(obj=> obj.mappingKey === 'offerType');
          if(defaultofferType.length > 0 && defaultofferType[0].state.length > 0){
            this.updateState({offerType: defaultofferType[0].state})
          }else{
            this.updateState({offerType: [...offerTypeOptions]})
          }
          return true;
        }
        optionsStateObj[`${obj.optionsMappingKey}Options`] = data.map(val => ({
          value: val,
          label: val
        }));
      });
      this.setState({
        ...optionsStateObj
      });
      
      if(this.props.accessChildState){
        this.props.accessChildState(this.state)
      }
    } catch (error) {
      this.props.closeLoader();
    }
  }
  asyncExecution = (obj, key, index) =>
    new Promise(async (resolve, reject) => {
      let filtersListIndex = this.props.hierarchyLevel.indexOf(key) + 1;
      let categoryDetails = {},
        parentObj = {};
      const url = obj;
      const reqObj = {};
      let preFilterKeysIndex = filtersListIndex + index;
      this.props.filtersList.slice(0, preFilterKeysIndex).filter(filterObj => {
        reqObj[filterObj.mappingKey] = filterObj.state.map(
          object => object.label
        );
      });
      this.props.openLoader();
      try {
        const data = await fetchHierarchyData(url, {...reqObj, screenName: this.props.screenName});
        let object = {};
        object[`${obj}Options`] = [
          // {
          //     value: 'Select All',
          //     label: 'Select All'
          // }
        ].concat(
          data.map(object => {
            return { value: object, label: object };
          })
        );
        categoryDetails[`${obj}`] = data.map(object => {
          return { value: object, label: object };
        });
        if(this.props.isDefaultSelectedAll){
          parentObj[`${obj}`] = data.map(object => ({
            value: object,
            label: object
          }));
          
        }else{
          if(!this.props.disabledApiCallOnCascade){
          parentObj[`${obj}`]=[];
          }
        }
        
        if(Object.keys(parentObj).length > 0){

          this.updateState(parentObj);
        }
        this.setState(object);
        this.props.closeLoader();
        resolve(true);
      } catch (error) {
        this.props.closeLoader();
      }
    });

  sequentialExecution = (arr, key, index = 0) => {
    if (index >= arr.length) return Promise.resolve();
    return this.asyncExecution(arr[index], key, index).then(r => {
      return this.sequentialExecution(arr, key, index + 1);
    });
  };

  getFiltersData = async (type, currentStateObj) => {
    let index = this.props.hierarchyLevel.indexOf(type);
    let startIndex = index + 1;
    let endIndex = 1;
    let postHierarchyKeys = this.props.hierarchyLevel.slice(
      startIndex,
      this.props.hierarchyLevel.length
    );
    let preHierarchyKeys = this.props.hierarchyLevel.slice(0, startIndex);
    let stateObj = {},
      optionsObj = {};
    postHierarchyKeys.forEach(obj => {
      optionsObj[`${obj}Options`] = [];
      stateObj[`${obj}`] = [];
      this.props.filtersList.filter(filterObj => {
        if (filterObj.mappingKey === obj) {
          if(this.props.disabledApiCallOnCascade){
            stateObj[`${obj}`] = filterObj.state;
          }else{

            filterObj.state = [];
          }
        }
      });
    });

    let key = this.props.hierarchyLevel[startIndex];
    const url = key;
    const reqObj = {};
    preHierarchyKeys.forEach(obj => {
      this.props.filtersList.filter(filterObj => {
        if (filterObj.mappingKey === obj) {
          reqObj[obj] = filterObj.state.map(object => object.label);
        }
      });
    });
    this.setState({
      ...optionsObj
    });
    this.updateState({
      ...stateObj
    });
    if (currentStateObj.length > 0) {
      try {
        this.props.openLoader();
        const temp = await fetchHierarchyData(url, {
          screenName: this.props.screenName,
          ...reqObj
        });
        let object = {},
          parentObj = {};
          const data = temp.filter(val => {
            return val.length>0;
          })
        object[`${key}Options`] = [
          // {
          //     value: 'Select All',
          //     label: 'Select All'
          // }
        ].concat(
          data.map(object => {
            return { value: object, label: object };
          })
        );
        if(this.props.isDefaultSelectedAll){
          parentObj[`${key}`] = data.map(object => ({
            value: object,
            label: object
          }));
          
        }else{
          if(!this.props.disabledApiCallOnCascade){
            parentObj[`${key}`]=[];
          }
      }
        if(Object.keys(parentObj).length>0){
          const defaultMappingKey = this.props.filtersList.filter(obj=> obj.mappingKey === key);
          if(defaultMappingKey.length > 0 && defaultMappingKey[0].state.length > 0){
            let mappingKeyObj = {}
            mappingKeyObj[`${key}`] = defaultMappingKey[0].state
            this.updateState(mappingKeyObj)
          }else{
            this.updateState(parentObj)
          }
          // this.updateState(parentObj);
        }
        const postFilters = this.props.hierarchyLevel.slice(
          this.props.hierarchyLevel.indexOf(key) + 1,
          this.props.hierarchyLevel.length
        );
        const preFilters = this.props.hierarchyLevel.slice(
          0,
          this.props.hierarchyLevel.indexOf(key)
        );
        if (postFilters.length > 0) {
          this.sequentialExecution(postFilters, key);
        }
        this.setState(object);
        this.props.closeLoader();
      } catch (error) {
        this.props.closeLoader();
      }
    }
  };
  updateState = params => {
    this.props.updateParentState({
      ...params
    });
  };
  toggleDatePicker() {
    this.component.setOpen(this.focus);
    this.focus = !this.focus;
  }

  handleYearData = date => {
    this.props.updateParentState({
      selectedDate: new Date(moment(date).add(0, 'years'))
    });
    if (this.props.onDatepickerChange) {
      this.props.onDatepickerChange(date);
    }
  };

  reset() {
    let resetObj = Object.assign({}, this.props.options);
    // delete resetObj[`${this.props.hierarchyLevel[0]}Options`];
    this.props.hierarchyApiCalls.forEach(obj => {
      delete resetObj[`${obj.optionsMappingKey}Options`];
    });

    this.setState({
      ...resetObj
    });
    this.props.onReset();
  }

  handleRadioButtonChange = e => {
      const { name, value } = e.target;
      this.updateState(
        {
          [name]: value
        }
      )
  }
  renderFilters(obj) {
    switch (obj.type) {
      case 'MultiSelectCheckboxes':
        return (
          <MultiSelectCheckboxes
            placeholderButtonLabel={obj.placeholder}
            options={this.state[`${obj.mappingKey}Options`]}
            value={obj.state}
            mappingKey={obj.mappingKey}
            onMenuCloseApi={obj.onMenuCloseApi}
            onMenuCloseApiFun={val => {
              if (obj.onMenuCloseApi) {
                this.getFiltersData(obj.mappingKey, obj.state);
              }
            }}
            updateState={this.updateState}
            disabledApiCall = {obj.disabledApiCall}
            selectionLimit={obj.selectionLimit}
            defaultSelectedOptions={obj.defaultSelectedOptions}
            allowRowClickSelection={true}
          />
        );
      case 'Select':
        return (
          <MultiSelectCheckboxes
            placeholderButtonLabel={obj.placeholder}
            menuPortalTarget={document.querySelector("body")}
            options={this.state[`${obj.mappingKey}Options`]}
            value={obj.state}
            mappingKey={obj.mappingKey}
            onMenuCloseApi={obj.onMenuCloseApi}
            onMenuCloseApiFun={val => {
              if (obj.onMenuCloseApi) {
                this.getFiltersData(obj.mappingKey, obj.state);
              }
            }}
            defaultSelectedOptions={obj.defaultSelectedOptions}
            disabled={obj.disabled}
            updateState={this.updateState}
            isMulti={false}
            disabledApiCall = {obj.disabledApiCall}
          />
        );
        break;
      case 'DatePicker':
        return (
          <div className="filter-datepicker">
            <InputGroup >
              <DatePicker
                id='dateMonthpicker'
                className='datePickervaluestyling form-control'
                showMonthYearPicker
                selected={obj.state == null ? new Date(moment()) : obj.state}
                onChange={this.handleYearData}
                dateFormat='MM/yyyy'
                ref={r => {
                  this.component = r;
                }}
                autoFocus={this.focus}
                minDate={obj.mindDate}
                maxDate={obj.maxDate}
                placeholderText='Select Fiscal Year & Month'
              />
              <InputGroup.Append>
                <i
                  id='calendarIco'
                  className='fa fa-calendar'
                  aria-hidden='true'
                  onClick={() => this.toggleDatePicker()}
                />
              </InputGroup.Append>
            </InputGroup>
          </div>
        );
      case 'Radio' : 
      return (
        <div className='row'>
        {obj.keys.map(radioObj =>(
          <div className='col-md-6'>
          <label>
            <input
              type='radio'
              value={radioObj.value}
              checked={obj.state === radioObj.value}
              onChange={this.handleRadioButtonChange}
              name={obj.mappingKey}
            />
            &nbsp;&nbsp; {radioObj.label}
          </label>
        </div>
        ))}
        
        {/* <div className='col-md-6'>
          <label>
            <input
              type='radio'
              value={obj.value}
              checked={obj.state === obj.value}
              onChange={this.handleRadioButtonChange}
              name={obj.name}
            />
            &nbsp;&nbsp; FOC
          </label>
        </div> */}
      </div>
    
      );
      default:
        return <></>;
    }
  }
  renderView(obj) {
    switch (this.props.viewType) {
      case 'verticle':
        return (
          <Form className="dropdown-labels">
            <Form.Group controlId='exampleForm.ControlInput1'>
            <Form.Label>
                <b>{obj.required ? (<><span className="text-danger">*</span> {obj.label}</>) : obj.label } </b>
                </Form.Label>
              {this.renderFilters(obj)}
            </Form.Group>
          </Form>
        );
        break;
      case 'inline':
        return (
          <Form >
            <Form.Group as={Row} controlId='formPlaintextEmail'>
              <Form.Label column sm='4'>
                <b>{obj.label}</b>
              </Form.Label>
              <Col sm='8'>{this.renderFilters(obj)}</Col>
            </Form.Group>
          </Form>
        );
        break;
      case 'horizontal':
        return (
          <Form>
            <Form.Group as={Row} controlId='formPlaintextEmail'>
              <Form.Label column sm='4'>
                {obj.label}
              </Form.Label>
              <Col sm='8'>{this.renderFilters(obj)}</Col>
            </Form.Group>
          </Form>
        );
        break;

      default:
        return <>{this.renderFilters(obj)}</>;
        break;
    }
  }

  renderFilterButtons() {
    switch (this.props.viewType) {
      case 'verticle':
        return (
          <>
            {/* {<div className="dropdown-labels">
            <Form.Group controlId='exampleForm.ControlInput1'>
              <Form.Label>
              </Form.Label>
              <Form.Row>
                <div className="row">
                <div className="col-md-4 ">
                <button
                    className='btn btn-primary filterBtn'
                    onClick={() => this.props.filterData()}
                    disabled={this.props.isFilterButtonDisabled}
                    >
                    <span>
                      <i className='fa fa-filter' />
                      &nbsp; Filter
                    </span>
                  </button>
                </div>
                
                <div className="col-md-4">
                
                <button
                className='btn btn-primary resetButton'
                onClick={() => this.reset()}
                disabled={this.props.isResetButtonDisabled}>
                <span>
                  <i className='fa fa-repeat'></i>&nbsp; Reset
                </span>
              </button>
                </div>
                </div>
                  
              </Form.Row>
            </Form.Group>
            </div>} */}
            <Form.Group controlId='exampleForm.ControlInput1'>
              <Form.Label>
                <br />
              </Form.Label>
              <div className="row">
                <div className=" col-xs-12 col-sm-6 col-md-5 col-lg-5 col-xl-5">
                  <button
                    className='btn btn-primary filterBtn'
                    onClick={() => this.props.filterData()} disabled={this.props.isFilterButtonDisabled}>
                    <span>
                      <i className='fa fa-filter' />
                      &nbsp; Filter
                    </span>
                  </button>
                </div>
                <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
                  <button
                    className='btn btn-primary resetButton'
                    onClick={() => this.reset()} disabled={this.props.isResetButtonDisabled}>
                    <span>
                      <i className='fa fa-repeat'></i>&nbsp; Reset
                    </span>
                  </button>
                </div>
              </div>
            </Form.Group>
          </>
        );

        break;
      default:
        return (
          <>
          {/* {<div className='col-md-1'></div>
            <div className='col-md-4'>
              <button
                className='btn btn-primary filterBtn'
                onClick={() => this.props.filterData()}
                disabled={this.props.isFilterButtonDisabled}
                >
                <span>
                  <i className='fa fa-filter' />
                  &nbsp; Filter
                </span>
              </button>
            </div>
            <div className='col-md-4'>
              <button
                className='btn btn-primary resetButton'
                onClick={() => this.reset()}>
                <span>
                  <i className='fa fa-repeat'></i>&nbsp; Reset
                </span>
              </button>
            </div>} */}
            <div className='col-xs-12 col-sm-6 col-md-5 col-lg-5 col-xl-5'>
              <button
                className='btn btn-primary filterBtn'
                onClick={() => this.props.filterData()} disabled={this.props.isFilterButtonDisabled}>
                <span>
                  <i className='fa fa-filter' />
                  &nbsp; Filter
                </span>
              </button>
            </div>
            <div className='col-xs-12 col-sm-6 col-md-5 col-lg-5 col-xl-5'>
              <button
                className='btn btn-primary resetButton'
                onClick={() => this.reset()} disabled={this.props.isResetButtonDisabled}>
                <span>
                  <i className='fa fa-repeat'></i>&nbsp; Reset
                </span>
              </button>
            </div>
          </>
        );
        break;
    }
  }
  render() {
    return (
      <React.Fragment>
        {/* {<div className='col-md-12'>
          <div className='row'>
            {this.props.filtersList.map((obj, index) => (
              <div key={index} className="col-2">
                {this.renderView(obj)}
              </div>
            ))}
            <div className='col-md-3'>
              {this.props.isFilterButtonRequired ? this.renderFilterButtons() : null}
            </div>
          </div>
        </div>} */}
         <div className='col-md-10'>
          <div className='row'>
            {this.props.filtersList.map((obj, index) => (
              <div key={index} className={obj.grid}>
                {this.renderView(obj)}
              </div>
            ))}
          </div>
        </div>
        <div className='col-md-2'>
          <div className='row'>{this.props.isFilterButtonRequired ?  this.renderFilterButtons() : null}</div>
        </div>
      </React.Fragment>
    );
  }
}


Filters.defaultProps = { isDefaultSelectedAll:true , isFilterButtonRequired:true};