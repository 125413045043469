import React, { Component } from "react";
import "./index.css";
import { convertMillionsFormatter } from "../../utils/AgGridFormatters";
import NumberFormat from 'react-number-format';
import isUndefined from "lodash/isUndefined";
import {VALIDATION_REGEX} from '../app-constants'
class EditBudget extends Component {
    constructor(props) {
        super(props);

        this.state = {
            value: isUndefined(props.value)? 0 : props.value,
            isEditing: false,
            isDisabled: props.isDisabled || false,
            data:{}
        };
    }

    toggleEditing = () => {
        this.setState({ isEditing: !this.state.isEditing });
    }

    handleChange = (values) => {
        const changedValue = isNaN(parseInt(values.value)) ? 0 : parseInt(values.value);
        const data = {
            currentRow: this.props.data,
            field: this.props.colDef.field, 
            value:  changedValue,
            rowData: this.props.agGridReact.props.rowData
        }
        // this.props.handleBudgetChange(data);
        this.setState({ value : values.value, data });
    };

    handleDataModification = () => {
        this.setState({ isEditing: !this.state.isEditing });
        if(Object.keys(this.state.data).length>0){
        this.props.handleBudgetChange(this.state.data);}
    }


    render() {

        return (

            <div className="edit-available-budget">
                <label>$</label>

                {this.state.isEditing ? (
                <NumberFormat 
                    className="editBudgetInput"
                    value={this.state.value} 
                    thousandSeparator={true} 
                    onValueChange={values => {this.handleChange(values)}}
                    onBlur={this.handleDataModification}
                    allowNegative={false}
                />
                ) : (
                    <input
                    className="editBudgetInput"
                    type="text"
                    value={convertMillionsFormatter(this.state.value)}
                    onFocus={this.toggleEditing}
                    disabled={this.state.isDisabled}
                    readOnly
                  />
                )}
            </div>
        );
    }
}

export default EditBudget;