import React, { Fragment, useState ,useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import classNames from "classnames";
import { convertToCurrency } from '../../utils/AgGridFormatters';
const columnDefs= [
  {
    headerName: "AH4",
    field: "ah4"
  },
  {
    headerName: "AH6",
    field: "ah6"
  },
  {
    headerName: "Vendor",
    field: "vendor"
  },
  {
    headerName: "Article Number",
    field: "articleNo"
  },
  {
    headerName: "Affinity Margin",
    field: "margin",
    valueFormatter: convertToCurrency
  },
  {
    headerName: "Affinity Sales",
    field: "sales",
    valueFormatter: convertToCurrency
  }
]
const BasketAffect = props => {
  const [selectedView, setSelectedView] = useState("Affinity");
  const [colDefs, setColDefs] = useState(columnDefs)
  const [rowData, setRowData] = useState(props.basketAffect[`${selectedView}`])
  const [gridApi, setGridApi] = useState({});
  useEffect(() => {
      setRowData(props.basketAffect[`${selectedView}`])
  }, [props.basketAffect])
  const changedView = value => {
    props.changedView(value);
    let cols = [];
    setSelectedView(value);
    if(value === 'Affinity'){
      
       cols = [{
        headerName: "AH4",
        field: "ah4"
      },
      {
        headerName: "AH6",
        field: "ah6"
      },
      {
        headerName: "Vendor",
        field: "vendor"
      },
      {
        headerName: "Article Number",
        field: "articleNo"
      },{
        headerName: "Affinity Margin",
        field: "margin",
        valueFormatter: convertToCurrency
      },
      {
        headerName: "Affinity Sales",
        field: "sales",
        valueFormatter: convertToCurrency
      }]
    }else{
       cols = [{
        headerName: "AH4",
        field: "ah4"
      },
      {
        headerName: "AH6",
        field: "ah6"
      },
      {
        headerName: "Vendor",
        field: "vendor"
      },
      {
        headerName: "Article Number",
        field: "articleNo"
      },{
        headerName: "Cannibalization Margin",
        field: "margin",
        valueFormatter: convertToCurrency
      },
      {
        headerName: "Cannibalization Sales",
        field: "sales",
        valueFormatter: convertToCurrency
      }]
    
    }
    setColDefs(cols)
    setRowData(props.basketAffect[`${value}`])
  };

  useEffect(() => {
    if(Object.keys(gridApi).length > 0){
        gridApi.sizeColumnsToFit();
    }
  }, [colDefs])
  const onGridReady =(params)=> {
    setGridApi(params.api);
    params.api.sizeColumnsToFit();
  }
  return (
    <>
      <div className="row">
        <div className="col-md-3">
          <p>
            <h4
              className="font-bold font-size-14"
              style={{ marginTop: "26px" }}
            >
              Basket Affect
            </h4>
          </p>
          <div
            id="promoCal"
            className="divInn btn-group "
            style={{
              textAlign: "center"
            }}
          >
            <button
              type="button"
              className={classNames({
                active: selectedView == "Affinity",
                " btn": true,
                "btn-outline-primary cal-btn": true,
                btnCalendr: true,
                calPromo: true
              })}
              onClick={() => changedView("Affinity")}
              style={{
                width: "120px"
              }}
            >
              Affinity
            </button>
            <button
              type="button"
              className={classNames({
                active: selectedView == "Cannibalization",
                " btn": true,
                "btn-outline-primary cal-btn": true,
                btnCalendr: true,
                calPromo: true
              })}
              onClick={() => changedView("Cannibalization")}
              style={{
                width: "120px"
              }}
            >
              Cannibalization
            </button>
          </div>
        </div>
      </div>
      <div className="spacer"></div>
      <div className={rowData.length === 1 ? 'remove-ag-grid-bottom-space' : ''}>
      <div className="ag-theme-balham" style={{ width: "100%" }}>
        <AgGridReact
          defaultColDef={{
            resizable: true
          }}
          columnDefs={colDefs}
          rowData={rowData}
          onGridReady={onGridReady}
          domLayout="autoHeight"
          enableCellTextSelection={true}
        />
      </div>
      </div>
    </>
  );
};

export default BasketAffect;
