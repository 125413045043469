export const GET_ERRORS = "GET_ERRORS";
export const ERROR_MSG = "ERROR_MSG";
export const CLEAR_ERRORS = "CLEAR_ERRORS";
export const DATA_LOADING = "DATA_LOADING";
export const GET_CHART_DATA = "GET_CHART_DATA";
export const GET_CHART_DATA_FILTERS = "GET_CHART_DATA_FILTERS";
export const CLEAR_CHART_DATA = "CLEAR_CHART_DATA";
export const GET_EDA_CHART_DATA = "GET_EDA_CHART_DATA";
export const CLEAR_EDA_CHART_DATA = "CLEAR_EDA_CHART_DATA";
// export const CLEAR_FILTERS_DATA = 'CLEAR_FILTERS_DATA'
export const AUTO_PROMO_DATA = "AUTO_PROMO_DATA";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const LOGOUT_CURRENT_USER = "LOGOUT_CURRENT_USER";
export const SOME_ACTION = "SOME_ACTION";
export const GET_EDA_CHART_DATA_FILTERS = "GET_EDA_CHART_DATA_FILTERS";
export const GET_EDA_DEPART_DATA_FILTERS = "GET_EDA_DEPART_DATA_FILTERS";
export const GET_EDA_ERRORS = "GET_EDA_ERRORS";
export const GET_BUDGET_DATA = "GET_BUDGET_DATA";
export const GET_BUDGET_CALENDAR_DATA = "GET_BUDGET_CALENDAR_DATA";
export const GET_BUDGET_GRAPH_DATA = "GET_BUDGET_GRAPH_DATA";
export const GET_BUDGET_EDIT = "GET_BUDGET_EDIT";
export const AUTO_GET_NEW_OPTIMIZED_DATA = "AUTO_GET_NEW_OPTIMIZED_DATA";
export const SKU_IDS = "SKU_IDS"
export const GET_HISTORICAL_DATA = "GET_HISTORICAL_DATA"
export const UPDATE_SELECTED_METRICS = "UPDATE_SELECTED_METRICS"

//budget master types
export const GET_VENDOR_BUDGET_MASTER_DATA = "GET_VENDOR_BUDGET_MASTER_DATA";
export const UPDATE_BUDGET_MASTER_DATA = "UPDATE_BUDGET_MASTER_DATA";
export const SAVE_BUDGET_MASTER_DATA = "SAVE_BUDGET_MASTER_DATA";
export const RESET_BUDGET_MASTER_DATA = "RESET_BUDGET_MASTER_DATA";
export const VENDOR_BRAND_BUDGET_DATA = "VENDOR_BRAND_BUDGET_DATA";
export const UPDATE_VENDOR_BRAND_BUDGET_DATA = "UPDATE_VENDOR_BRAND_BUDGET_DATA";
// export const SHOW_ERROR = 'SHOW_ERROR'

//SKU Master Types
export const SET_LOADER = "SET_LOADER";
export const SET_SKU_DATA = "SET_SKU_DATA";
export const SAVE_SKU_MASTER = "SAVE_SKU_MASTER";
export const RESET_SKU_MASTER = "RESET_SKU_MASTER";
export const SET_REF_SKU_INFO = "SET_REF_SKU_INFO";
export const SET_FILTERS_DATA = "SET_FILTERS_DATA";
export const RESET_FILTERS_DATA = "RESET_FILTERS_DATA";
export const SET_BRAND_DATA = "SET_BRAND_DATA";
export const ADD_UPDATE_SKU = "ADD_UPDATE_SKU";


//create Promo types
export const GET_SKUS = "GET_SKUS";
export const SET_BOOK_NUMBERS = "SET_BOOK_NUMBERS";
export const SET_SIMULATE_DATA = "SET_SIMULATE_DATA"
export const SET_PROMO_ID = "SET_PROMO_ID"
export const SET_PROMO_DATA = "SET_PROMO_DATA"
export const RESET_PROMO_ID = "RESET_PROMO_ID"
export const SET_MERCHCAT = "SET_MERCHCAT";
export const SET_PROMO_SATUS = "SET_PROMO_SATUS"

//Finalize Promo types
export const SET_FINALIZE_PROMO_DATA = "SET_FINALIZE_PROMO_DATA"
export const RESET_FINALIZE_PROMO_DATA = "RESET_FINALIZE_PROMO_DATA"
export const DELETE_FINALIZE_PROMO = "DELETE_FINALIZE_PROMO"


// User Management

export const SET_CATEGORY_ROLES = "GET_CATEGORY_ROLES";
export const SET_USER_DATA = "SET_USER_DATA";
export const GET_ALL_USERS = "GET_ALL_USERS";

export const SET_USER_ROLE_INFO = "SET_USER_ROLE_INFO";
export const USER_LOGOUT = "USER_LOGOUT";
export const SESSION_EXPIRED = "SESSION_EXPIRED"

export const SET_VIDEOS_LIST = "SET_VIDEOS_LIST"

//Constraints
export const GET_CONSTRAINTS_LIST = "GET_CONSTRAINTS_LIST";
export const RESET_CONSTRAINTS_LIST = "RESET_CONSTRAINTS_LIST";
export const SET_PLANID = "SET_PLANID";
export const SET_VENDOR_LIST = "SET_VENDOR_LIST";
export const SET_ARTICLE_LIST = "SET_ARTICLE_LIST";
export const SET_ARTICLE_DETAILS = "SET_ARTICLE_DETAILS"; 
export const SET_GROUP_DETAILS = "SET_GROUP_DETAILS";
export const SET_SELECTED_ARTICLE = "SET_SELECTED_ARTICLE";

// Executive Summary

export const SET_CYCLES = "SET_CYCLES";
export const SET_QUARTERS = "SET_QUARTERS";
export const SET_CONSOLIDATED_DATA = "SET_CONSOLIDATED_DATA"
export const SET_BUYER_DATA = "SET_BUYER_DATA"
export const SET_CATEGORY_DATA = "SET_CATEGORY_DATA"
export const SET_VENDOR_DATA = "SET_VENDOR_DATA"
export const RESET_CONSOLIDATED_DATA = "RESET_CONSOLIDATED_DATA";
export const UPDATE_CONSOLIDATED_DATA = "UPDATE_CONSOLIDATED_DATA"