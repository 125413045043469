import React, { Component, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import PageLoader from "../../utils/Loader";
import { ToastContainer, toast } from "react-toastify";
import PageWrapper from "../common/PageWrapper";
import BreadCrumb from "../BreadCrumb";
import Select from "react-select";
import BudgetMasterTable from "./components/Budget-master-table";
import {
  actionGetBudgetMasterTableData,
  actionSaveBudgetMasterData,
  actionUpdateModifiedRow,
  actionResetData,
  actionDownloadBudgetMaster,
  actionUpdateVendorBrandBudget
} from "../../actions/budgetMasterAction";
import { connect } from "react-redux";
import BudgetMasterFilter from "./components/budgetMasterFilterComponent";
import "./index.css";
import { BudgetMasterGA   } from '../../common/GAUtils';
const BudgetMaster = props => {
  const [merchcat, setCategory] = useState([]);
  const [loader, setloader] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const [year, setYear] = useState([]);
  const [quarter, setquarter] = useState([]);
  const [tableData, setTableData] = useState([]);
  useEffect(() => {
    formatHeader();
    setTableData(props.budgetMasterTableData);
  }, [props.budgetMasterTableData]);

  const sortColumnHeaders = headers => {
    console.log("+++++++++=headers", headers);
    const getYearAndQuater = yearAndQuarter => {
      let [year, quarter] = yearAndQuarter.split("-");
      quarter = quarter.substring(1, 2);
      return [parseInt(year), parseInt(quarter)];
    };
    return headers.sort((header1, header2) => {
      const [year1, quarter1] = getYearAndQuater(header1);
      const [year2, quarter2] = getYearAndQuater(header2);
      return year1 - year2 || quarter1 - quarter2;
    });
  };
  useEffect(() => {
    return () => {
      props.actionResetData();
    };
  }, []);

  const formatHeader = () => {
    const { budgetMasterTableData } = props;
    const headers = [];
    const yearQuarter = [];
    if (budgetMasterTableData.length > 0) {
      budgetMasterTableData.forEach(data => {
        Object.keys(data).forEach(key => {
          if (headers.indexOf(key.split("_")[0]) === -1) {
            let temp = key.split("_")[0];
            
            if(temp.includes("-")){
              headers.push(temp)
            }// else {
            //   headers.push(temp);
            // }
          }
        });
      });
    }
    // if(headers.length>0 && headers[0] !== "vendor") {
    //   headers.unshift("vendor")
    // }
    
    setTableHeaders(["vendor","brand", ...sortColumnHeaders(headers)]);
  };

  const saveBudgetMaster = async () => {
    submitGA("Click","Save Budget Master");
    let reqObj = {
      category: merchcat[0],
      vendorArr: Object.values(props.modifiedData)
    };

    await props.actionSaveBudgetMasterData(reqObj);
    const payload = {
      category: merchcat,
      vendor,
      fYear: year,
      quarter: ['1', '2', '3', '4']
    };
    await props.actionGetBudgetMasterTableData(payload);
  };
  const openLoader = () => {
    setloader(true);
  };
  const closeLoader = () => {
    setloader(false);
  };

  const filterData = filterSelectedOptions => {
    submitGA("Click","Filter Button");
    props.actionResetData();
    setCategory(filterSelectedOptions.merchcat);
    setVendor(filterSelectedOptions.vendor);
    setYear(filterSelectedOptions.year);
    setquarter(['1', '2', '3', '4'])
    const payload = {
      category: filterSelectedOptions.merchcat,
      vendor: filterSelectedOptions.vendor,
      fYear: filterSelectedOptions.year,
      quarter: ['1', '2', '3', '4']
    };
    props.actionGetBudgetMasterTableData(payload);

  };
  const onReset = () => {
    submitGA("Click","Reset Button");
    setCategory([]);
    setVendor([]);
    props.actionResetData();
  };

  const handleDownload = () => {
    submitGA("Click","Download Budget master list");
    const reqObj = {
      category: merchcat,
      vendor: vendor,
      fYear: year,
      quarter: ['1', '2', '3', '4']
    };
    props.actionDownloadBudgetMaster(reqObj);
  };
  const submitGA =(action,label)=>{
    BudgetMasterGA({ action, label});
  }

  const handleExpandCollapse = (data, tableData, type) => {
    let temp = [];
    tableData.forEach(ele => {
      if(data.vendor !== ele.vendor) {
        temp.push(ele);
      } else {
        if(type === 'expand'){
        temp.push(ele);
        let filetrdata = props.vendorBrandBudgetData.filter(ele => ele.vendor === data.vendor);
        temp.push(...filetrdata);
      } else if(type === 'collapse' && ele.brand === 'ALL BRANDS' ){
        temp.push(ele);
      }
      }
    });
    setTableData(temp);
  }

  const handleBrandBudgetModification = dataObj => {
    const { currentRow, field, value, rowData } = dataObj;
    const { vendor, brand } = currentRow;
    const reqObj = {
      vendor,
      field,
      value,
      brand
    };
    let updatedRow = [];
      let brandPrevBudget = props.vendorBrandBudgetData.filter(data => data.vendor === currentRow.vendor && data.brand === currentRow.brand);

    updatedRow = rowData.map((row) => {
      if (row.vendor === currentRow.vendor && row.brand === 'ALL BRANDS') {
        row[field] = row[field]-brandPrevBudget[0][field]+value;
      } else if(row.vendor === currentRow.vendor && row.brand === currentRow.brand) {
        row[field] = value;
      }
      return row;
    });
  
    let tempBrandBudget = props.vendorBrandBudgetData.map(data => {
      if(data.vendor === currentRow.vendor && data.brand === currentRow.brand){
        data[field] = value;
      }
      return data;
    })
    props.actionUpdateModifiedRow(reqObj);
    props.actionUpdateVendorBrandBudget(tempBrandBudget);
    setTableData(updatedRow);

  return tempBrandBudget
  }

 const handleVendorBudgetModification = dataObj => {
  const { currentRow, field, value, rowData } = dataObj;
  let updatedRow = [];
  let tempVendorBrandBudgetData = props.vendorBrandBudgetData;
  let vendorBrandPrevBudget = tempVendorBrandBudgetData.filter(data => data.vendor === currentRow.vendor);
  const { vendor } = currentRow;
  let temp = [];
  let vedorBudget = rowData.filter(row => row.vendor === currentRow.vendor && row.brand === 'ALL BRANDS');
  let updatedBrandBudget = tempVendorBrandBudgetData.map((row) => {
    if (row.vendor === currentRow.vendor) {
      let tempBrandBudget = 0;
        if(vedorBudget[0][field] !== 0) {
          temp = vendorBrandPrevBudget.map(brandRow => {
            if(brandRow.brand === row.brand) {
              let brandPortion = brandRow[field]/vedorBudget[0][field];
              tempBrandBudget = value * brandPortion;
              row[field] = tempBrandBudget;
              brandRow[field] = tempBrandBudget
            }
            return brandRow;
          })
        } else {
          let brandCount = vendorBrandPrevBudget.length;
          tempBrandBudget =parseFloat(value / brandCount);
          temp = vendorBrandPrevBudget.map(brandRow => {
            if(brandRow.brand === row.brand) {
              row[field] = tempBrandBudget;
              brandRow[field] = tempBrandBudget
            }
            return brandRow;
          })
        }
     
    }
    return row;
  });

  updatedRow = rowData.map(row => {
    if (row.vendor === currentRow.vendor) {
      if(row.brand !== 'ALL BRANDS'){
        temp.forEach(brandRow => {
          if(brandRow.brand === row.brand) {
            row[field] = brandRow[field];
            }
        })
      } else {
        row[field] = value;
      }
    }
    return row;
  })

  tempVendorBrandBudgetData.map(row => {
    temp.forEach(brandRow => {
      if(brandRow.brand === row.brand) {
        // row[field] = brandRow[field];
        const reqObj = {
          vendor,
          field,
          value: brandRow[field],
          brand: brandRow.brand
        };
        props.actionUpdateModifiedRow(reqObj);
      }
    })
  });

  props.actionUpdateVendorBrandBudget(tempVendorBrandBudgetData);
  setTableData(updatedRow);
  return tempVendorBrandBudgetData;
 }
  return (
    <>
      <PageLoader loader={props.loading||loader}>
        <PageWrapper>
          {/* <ToastContainer /> */}
          <h4 className="promoHeading mb-1 color-blue">
          <BreadCrumb root="" child="Budget Master" />
          </h4>
          <div className="row filter-block">
            
         
          <BudgetMasterFilter
          filterData ={ filterData} 
          onReset = { onReset }
          openLoader = {openLoader}
          closeLoader = {closeLoader}
          />
           </div>
          {props.budgetMasterTableData.length > 0 ? (
            <div>
              <div className="row">
                <div className="promolist-header">
                <label className="pipelineLabel downloadLbel">Budget Master List</label>
                </div>
                <button
                  className="btn btn-primary iconbutton download-btn"
                  onClick={handleDownload}
                >
                  <span>
                    <i class="fa fa-download" aria-hidden="true" />
                  </span>
                </button>
              </div>
              <BudgetMasterTable
                budgetMasterData={tableData}
                tableHeaders={tableHeaders}
                updateModifiedData={props.actionUpdateModifiedRow}
                modifiedData={props.modifiedData}
                saveBudgetMaster={saveBudgetMaster}
                userInfo={props.userInfo}
                year={year}
                handleExpandCollapse={handleExpandCollapse}
                vendorBrandBudgetData={props.vendorBrandBudgetData}
                actionUpdateVendorBrandBudget={props.actionUpdateVendorBrandBudget}
                handleBrandBudgetModification={handleBrandBudgetModification}
                handleVendorBudgetModification={handleVendorBudgetModification}
                budgetMasterTableData={props.budgetMasterTableData}
              />
            </div>
          ) : 
          merchcat.length > 0 && <h6 className="empty-budget-data">No Data to Show</h6>
          }
        </PageWrapper>
      </PageLoader>
    </>
  );
};

const mapStateToProps = state => ({
  budgetMasterTableData: state.budgetMasterData.budgetMasterTableData,
  modifiedData: state.budgetMasterData.modifiedData,
  loading: state.budgetMasterData.loading,
  userInfo: state.userManagementData.userInfo,
  vendorBrandBudgetData: state.budgetMasterData.vendorBrandBudgetData
});

const mapDispatchToProps = dispatch => {
  return {
    actionGetBudgetMasterTableData: value =>
      dispatch(actionGetBudgetMasterTableData(value)),
    actionSaveBudgetMasterData: value =>
      dispatch(actionSaveBudgetMasterData(value)),
    actionUpdateModifiedRow: value => dispatch(actionUpdateModifiedRow(value)),
    actionDownloadBudgetMaster: value =>
      dispatch(actionDownloadBudgetMaster(value)),
    actionResetData: () => dispatch(actionResetData()),
    actionUpdateVendorBrandBudget: value => dispatch(actionUpdateVendorBrandBudget(value))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(BudgetMaster));
