import {
  GET_VENDOR_BUDGET_MASTER_DATA,
  DATA_LOADING,
  UPDATE_BUDGET_MASTER_DATA,
  SAVE_BUDGET_MASTER_DATA,
  RESET_BUDGET_MASTER_DATA,
  VENDOR_BRAND_BUDGET_DATA,
  UPDATE_VENDOR_BRAND_BUDGET_DATA,
} from './types';
import {
  fetchBudgetMasterTableData,
  saveBudgetMasterTableData,
  downloadBudgetMasterData,
} from '../apis/budgetmaster.api';
import _ from 'lodash';
import environmentModule from '../environments/environment';
import { toast } from 'react-toastify';
import { ToastMessage } from '../common/ToastMessage';

const formatTableData = (data) => {
  const keyArray = [];
  let tempData = data;
  tempData.forEach((vh) => {
    keyArray.unshift(...Object.keys(vh));
  });
  let uniqueKeyArray = _.uniq(keyArray);
  const missingKeyArray = [];
  uniqueKeyArray.forEach((key) => {
    const lyOrTY = key.substring(key.length - 2);
    const preKey = key.substring(0, key.length - 2);
    if (lyOrTY == 'TY') {
      if (!uniqueKeyArray.includes(`${preKey}LY`)) {
        missingKeyArray.push(`${preKey}LY`);
      }
    } else {
      if (lyOrTY == 'LY') {
        if (!uniqueKeyArray.includes(`${preKey}TY`)) {
          missingKeyArray.push(`${preKey}TY`);
        }
      }
    }
  });
  uniqueKeyArray = _.uniq([...uniqueKeyArray, ...missingKeyArray]);
  tempData.forEach((vh) => {
    uniqueKeyArray.forEach((key) => {
      if (!vh[key] && key !== 'vendor' && key !== 'brand') {
        vh[key] = 0;
      }
    });
  });

  return tempData;
};
export const actionGetBudgetMasterTableData = (payload) => async (dispatch) => {
  dispatch({ type: DATA_LOADING, payload: true });
  try {
    const result = await fetchBudgetMasterTableData(payload);
    // const keyArray = [];
    // result.forEach(vh => {
    //     keyArray.unshift(...Object.keys(vh));
    // })
    // let uniqueKeyArray = _.uniq(keyArray);
    // const missingKeyArray = []
    // uniqueKeyArray.forEach(key => {
    //     const lyOrTY = key.substring(key.length - 2)
    //     const preKey = key.substring(0, key.length - 2)
    //     if (lyOrTY == 'TY') {
    //         if (!uniqueKeyArray.includes(`${preKey}LY`)) {
    //             missingKeyArray.push(`${preKey}LY`)
    //         }
    //     } else {
    //         if (lyOrTY == 'LY') {
    //             if (!uniqueKeyArray.includes(`${preKey}TY`)) {
    //                 missingKeyArray.push(`${preKey}TY`)
    //             }
    //         }
    //     }
    // })
    // uniqueKeyArray = _.uniq([...uniqueKeyArray, ...missingKeyArray]);
    // result.forEach(vh => {
    //     uniqueKeyArray.forEach(key => {
    //         if (!vh[key]) {
    //             vh[key] = 0
    //         }
    //     })
    // })

    const vendorBudgetData = formatTableData(result.vendorArray);
    const vendorBrandBudgetData = formatTableData(result.vendorBrandArray);

    dispatch({ type: GET_VENDOR_BUDGET_MASTER_DATA, payload: vendorBudgetData });
    dispatch({ type: VENDOR_BRAND_BUDGET_DATA, payload: vendorBrandBudgetData });
    dispatch({ type: DATA_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: DATA_LOADING, payload: false });
  }
};

export const actionSaveBudgetMasterData = (reqObj) => async (dispatch) => {
  dispatch({ type: DATA_LOADING, payload: true });
  try {
    const result = await saveBudgetMasterTableData(reqObj);
    dispatch({type:'SAVE_BUDGETMASTER', payload:[]})
  } catch (error) {
    dispatch({ type: DATA_LOADING, payload: false });
  }
};

export const actionUpdateModifiedRow = (reqObj) => (dispatch) => {
  dispatch({ type: UPDATE_BUDGET_MASTER_DATA, payload: reqObj });
};

export const actionResetData = () => (dispatch) => {
  dispatch({ type: RESET_BUDGET_MASTER_DATA });
};

export const actionDownloadBudgetMaster = (reqObj) => async (dispatch) => {
  try {
    dispatch({ type: DATA_LOADING, payload: true });
    const result = await downloadBudgetMasterData(reqObj);
    window.open(result.url, '_self');
    dispatch({ type: DATA_LOADING, payload: false });
  } catch (err) {
    dispatch({ type: DATA_LOADING, payload: false });
  }
};

export const actionUpdateVendorBrandBudget = payload => async (dispatch) => {
  try {
    dispatch({ type: DATA_LOADING, payload: true });
    dispatch({ type: VENDOR_BRAND_BUDGET_DATA, payload });
    dispatch({ type: DATA_LOADING, payload: false });
  } catch (err) {
    dispatch({ type: DATA_LOADING, payload: false });
  }
};
