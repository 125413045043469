import styled from "styled-components";

export const LoadingDiv = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  justify-content: center;
`;

export const FooterBtnDiv = styled.div`
  display: flex;
  justify-content: center;
  margin: 5px 0px;
`;
