import {
    SET_LOADER,
    SET_SKU_DATA,
    SAVE_SKU_MASTER,
    ADD_UPDATE_SKU,
    SET_REF_SKU_INFO
  } from "./actionTypes";
  import { fetchModifySkuData, postSkuMasterSave  , refSkuInfo, skuDetails, createSku ,validateSkuId,updateSku } from "../../apis/skuManagement.api";
  import _ from 'lodash';

  export const getSkuDataTable = value => async dispatch => {
    try {
      dispatch({ type: SET_LOADER, payload: true });
      const result = await fetchModifySkuData(value);
      const { data, totalCount } = result;
      if (data) {
        const formattedSkuData = data.map(
          ({
            hierarchyLevel7: skuid = "-",
            vendor = "-",
            description = "-",
            brand = "-",
            lyRevenue= "-",
            tyRevenue= "-",
            tag="Active"
          }) => {
            return {
              skuid,
              description,
              vendor,
              brand,
              lyRevenue,
              tyRevenue,
              tag
            };
          }
        );
        dispatch({
          type: SET_SKU_DATA,
          payload: { data: formattedSkuData, totalCount },
        });
        dispatch({ type: SET_LOADER, payload: false });
      }
    } catch (err) {
      dispatch({ type: SET_LOADER, payload: false });
    }
  };

  export const setSkuData = value => dispatch => {
    dispatch({
      type: SET_SKU_DATA,
      payload: value,
    });
  };


export const actionSaveUpdatedSkuMaster = data => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await postSkuMasterSave(data);
    dispatch({ type: SET_LOADER, payload: false });

  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};


export const getRefSkuInfo = (reqObj) => async dispatch => {
  try {
    const { filters, skuId} = reqObj;
    dispatch({ type: SET_LOADER, payload: true });
    const result = await refSkuInfo(filters);
    const temp = result.filter(val => {
           return val != skuId;
    });
    dispatch({
      type: SET_REF_SKU_INFO,
      // payload: { refSkuInfo: result.map(obj=> obj.hierarchyLevel7) },
      payload: { refSkuInfo: temp.map(item=> {return { value: item, label: item}}) },
    });
    dispatch({ type: SET_LOADER, payload: false });

  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};


export const createSkuMaster = data => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await createSku(data);
    dispatch({ type: ADD_UPDATE_SKU, payload: String(Math.random()) });
    dispatch({ type: SET_LOADER, payload: false });
  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};

export const isValidSku = data => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await validateSkuId(data);
    
    dispatch({ type: SET_LOADER, payload: false });
    return result;

  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};


export const getArticleInfo = (filters) => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await skuDetails(filters);
    // dispatch({
      //   type: SET_REF_SKU_INFO,
      //   // payload: { refSkuInfo: result.map(obj=> obj.hierarchyLevel7) },
      //   payload: { refSkuInfo: result.map(obj=> {return { value: obj.hierarchyLevel7, label: obj.hierarchyLevel7}}) },
      // });
      dispatch({ type: SET_LOADER, payload: false });
      return result;

  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};


export const updateSkuMaster = data => async dispatch => {
  try {
    dispatch({ type: SET_LOADER, payload: true });
    const result = await updateSku(data);
    dispatch({ type: ADD_UPDATE_SKU, payload: String(Math.random()) });
    dispatch({ type: SET_LOADER, payload: false });

  } catch (err) {
    dispatch({ type: SET_LOADER, payload: false });
  }
};
