import React, { Component, useState, useEffect} from 'react';
import { AgGridReact } from "ag-grid-react";
import TagRenderComponent from "./tagRenderer";
import { Pagination } from "antd";
import TableWrapper from "../../common/TableWrapper";
import ToggleSelector from "./toggleSelector";

const SkuTable = ({
    isPaginationActive,
    columnDefs,
    defaultColDef,
    tabelLocation,
    rowData,
    pageGroupNo,
    pageGroupSize,
    totalCount,
    setSkuData , isEditable, tempSkuData}) => {

        const [gridApi, setGridApi] = useState({});

  useEffect(() => {
    console.log("there is change in the data", tempSkuData);
    if (Object.keys(gridApi).length > 0) {
      gridApi.forEachNodeAfterFilterAndSort(function (rowNode, index) {
        let data = rowNode.data;
        tempSkuData.filter((row) => {
          if (row.articleNo === data.skuid) {
            data.tag = row.tag;
            data.lyfunding = row.lyfunding;
            gridApi.refreshCells({ rowNodes: [rowNode], force: true });
          }
        });
      });
    }
  }, [tempSkuData, gridApi]);

    const paginationCount = parseInt(parseInt(totalCount) / pageGroupSize);

    function onGridReady(params) {
        setGridApi(params.api)
        params.api.sizeColumnsToFit();
    }
    return (<div>
        <TableWrapper height="300px">
            <AgGridReact
                columnDefs={columnDefs}
                rowData={rowData}
                onGridReady={onGridReady}
                // handleTableOpClick={this.handleTableOpClick}
                frameworkComponents={{
                    tagRenderComponent: TagRenderComponent,
                    toggleSelector:ToggleSelector,
                }}
                defaultColDef={defaultColDef}
                suppressMenuHide={true}
                floatingFilter={true}
                rowHeight="37"
                suppressDragLeaveHidesColumns={true}
                suppressHorizontalScroll={false}
                suppressRowTransform={true}
                suppressColumnMoveAnimation={true}
                isEditable ={isEditable}
                enableCellTextSelection={true}
                tempSkuData={tempSkuData}
                getRowNodeId={(data) => data.skuid}
                />
        </TableWrapper>
        {/* <div className="d-flex justify-content-end">
            {isPaginationActive && <Pagination
                pageSize={1}
                total={paginationCount + 1} // +1, to get the rest of  the records
                onChange={e => {
                    setSkuData({ pageGroupNo: e });
                }}
                style={{ padding: "20px 0px" }}
            />}
        </div> */}
    </div>)
}
 export default SkuTable;