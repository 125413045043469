import React, { Component } from 'react';
import DataTable from '../DataTable';
import WaterfallChart from '../WaterFallChart';
import './analyzePromoStyle.css';
import { PROMO_LEVEL } from '../../constants';
import { precisionFormatter, removePrecisionFormatter} from "../../utils/AgGridFormatters";
import { AgGridReact } from "ag-grid-react";
import { HistoricalPerformanceGA   } from '../../common/GAUtils';
class PromoAnalysis extends Component {
  constructor(props) {
    super(props);

    this.state = {
      analysisData: [],
      analysisGridData: [],
      selectedTab: 'Sales'
    };
    this.prefix = '$';
    this.postfix = '%';
    this.emptyValueFormatter = value => {
      if (value.value) {
        var toSting = Math.round(value.value);
        if (value.value > 0) {
          return toSting.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        } else {
          var splitAvalue = Math.abs(toSting);
          return (
            '-' +
            splitAvalue.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
            ''
          );
        }
      } else {
        if (value.value === 0) {
          return 0;
        } else {
          return '-';
        }
      }
    };
    this.articleColumnDefs = [
        { 
          headerName: "Article ID", 
          field: "articleNo" 
        },
        { 
          headerName: "Description", 
          field: "articleDesc",
          tooltipField: "articleDesc"
        },
        { 
          headerName: "Baseline",
          valueFormatter: (params) => {
          return '$' + removePrecisionFormatter(params)}, 
          field: "baselineSales" 
        },
        { 
          headerName: "Incremental",
          valueFormatter: (params) => {
          return '$' + removePrecisionFormatter(params) }, 
          field: "directIncrementalSales" 
        },
        { 
          headerName: "Affinity", field: "haloSales", 
          valueFormatter: (params) => {
          return  '$' + removePrecisionFormatter(params) } 
        },
        { 
          headerName: "Cannibalization",
          valueFormatter: (params) => {
          return  '$' + removePrecisionFormatter(params) }, 
          field: "cannibalizationSales",
         },
        { 
          headerName: "Total", 
          field: "actualTotalSales" ,
          valueFormatter: (params) => {
            return  '$' + removePrecisionFormatter(params) }, 
        }
    ];
    this.analysisMetrics = [
      {
        headerName: 'Metric',
        field: 'metric'
      },
      {
        headerName: 'Value',
        field: 'value',
        valueFormatter: this.emptyValueFormatter
      }
    ];
    this.colDefs = [
      {
        headerName: 'Department',
        sortable: true,
        unSortIcon: true,
        field: 'hierarchy.hierarchyLevel1'
      },
      {
        headerName: 'Category',
        sortable: true,
        unSortIcon: true,
        field: 'hierarchy.hierarchyLevel2'
      },
      {
        headerName: 'Sub Category',
        sortable: true,
        unSortIcon: true,
        field: 'hierarchy.hierarchyLevel3'
      },
      {
        headerName: 'SKU Group',
        sortable: true,
        unSortIcon: true,
        field: 'hierarchy.hierarchyLevel4'
      }
    ];
  }

  componentDidMount() {
    this.getHierarchyData(this.state.selectedTab);
  }
  componentDidUpdate(prevProps) {
    if (prevProps.currentPromo.id !== this.props.currentPromo.id) {
      this.getHierarchyData(this.state.selectedTab);
    }
    if(prevProps.articleData !== this.props.articleData){
      this.gridResponsive();
    }
  }
   getArticleSummaryTab = () => {
    this.submitGA("Click",`switch to Article Summary tab`);
    this.setState({
      selectedTab: "Article"
    },()=>{
      this.gridResponsive();
    });
    // this.props.getArticleData()
  };
  getHierarchyData = criteria => {
    this.submitGA("Click",`switch to ${criteria} tab`);
    if (this.props.currentPromo) {
      let initialObj = {};
      initialObj[`baseline${criteria}`] =
        this.props.currentPromo[`baseline${criteria}`] || 0;
      initialObj[`directIncremental${criteria}`] =
        this.props.currentPromo[`directIncremental${criteria}`] || 0;
      initialObj[`halo${criteria}`] =
        this.props.currentPromo[`halo${criteria}`] || 0;
      initialObj[`cannibalization${criteria}`] =
        this.props.currentPromo[`cannibalization${criteria}`] || 0;
      initialObj[`total${criteria}`] =
        this.props.currentPromo[`actualTotal${criteria}`] || 0;
      initialObj[`pullForward${criteria}`] =
        this.props.currentPromo[`pullForward${criteria}`] || 0;
        initialObj[`netIncremental${criteria}`] =
        (this.props.currentPromo[`actualTotal${criteria}`] - this.props.currentPromo[`baseline${criteria}`] ) || 0;
        

      let consolidated = this.props.currentPromo.hierarchies.reduce(
        (sum, hierarchy) => {
          let sumObj = {};
          sumObj[`baseline${criteria}`] =
            // sum[`baseline${criteria}`] +
            hierarchy[`baseline${criteria}`];
          sumObj[`directIncremental${criteria}`] =
            // sum[`directIncremental${criteria}`] +
            hierarchy[`directIncremental${criteria}`];

          sumObj[`halo${criteria}`] =
            // sum[`halo${criteria}`] +
            hierarchy[`halo${criteria}`];

          sumObj[`cannibalization${criteria}`] =
            // sum[`cannibalization${criteria}`] +
            hierarchy[`cannibalization${criteria}`];

          sumObj[`total${criteria}`] =
            // sum[`total${criteria}`] +
            hierarchy[`total${criteria}`];

          sumObj[`pullForward${criteria}`] =
            // sum[`pullForward${criteria}`] +
            hierarchy[`pullForward${criteria}`];
            sumObj[`netIncremental${criteria}`] =
            // sum[`pullForward${criteria}`] +
            hierarchy[`netIncremental${criteria}`];

          return sumObj;
        },
        initialObj
      );
      let analysisData = [
        {
          metric: 'Baseline',
          value: consolidated[`baseline${criteria}`]
        },
        {
          metric: 'Incremental',
          value: consolidated[`directIncremental${criteria}`]
        },
        {
          metric: 'Affinity',
          value: consolidated[`halo${criteria}`]
        },
        {
          metric: 'Cannibalization',
          value: consolidated[`cannibalization${criteria}`]
        },
        {
          metric: 'Pull Forward',
          // value: -((5 * consolidated[`pullForward${criteria}`]) / 100)
          value: consolidated[`pullForward${criteria}`]
        },
        {
          metric: 'Total',
          value: consolidated[`total${criteria}`]
          // ? consolidated[`total${criteria}`] -
          //   // (5 * consolidated[`pullForward${criteria}`]) / 100
          // : consolidated[`baseline${criteria}`] +
          //   consolidated[`directIncremental${criteria}`] +
          //   consolidated[`halo${criteria}`] +
          //   consolidated[`cannibalization${criteria}`] -
          //   (5 * consolidated[`pullForward${criteria}`]) / 100
        },
        {
          metric: 'Net Incremental',
          value: consolidated[`netIncremental${criteria}`]
        }
      ];
      let currencySymbol = ['Sales','Margin'].indexOf(criteria)!== -1 ? ' ($)' : '';
      let analysisGridData =  [
        {
          metric: `Baseline ${currencySymbol}`,
          value: consolidated[`baseline${criteria}`]
        },
        {
          metric: `Incremental ${currencySymbol}`,
          value: consolidated[`directIncremental${criteria}`]
        },
        {
          metric: `Affinity ${currencySymbol}`,
          value: consolidated[`halo${criteria}`]
        },
        {
          metric: `Cannibalization ${currencySymbol}`,
          value: consolidated[`cannibalization${criteria}`]
        },
        {
          metric: `Pull Forward ${currencySymbol}`,
          value: consolidated[`pullForward${criteria}`]
        },
        {
          metric: `Total ${currencySymbol}`,
          value: consolidated[`baseline${criteria}`] +
                  consolidated[`directIncremental${criteria}`] +
                  consolidated[`halo${criteria}`] +
                  consolidated[`cannibalization${criteria}`] +
                  consolidated[`pullForward${criteria}`]
        },
        {
          metric: `Net Incremental ${currencySymbol}`,
          value: consolidated[`netIncremental${criteria}`]
        }
      ];

      this.setState({ analysisData,analysisGridData, selectedTab: criteria });
    }
  };

  onGridReady = params => {
    // console.lo
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    params.api.sizeColumnsToFit();
  };
  gridResponsive=()=>{
    if(this.gridApi){
      if(this.props.articleData > 10){
        this.gridApi.setDomLayout("normal");
        document.querySelector("#promoanalaysiGrid").style.height = "300px";
      }else{
        this.gridApi.setDomLayout("autoHeight");
        document.querySelector("#promoanalaysiGrid").style.height = '';
      }
    }
    
  }
  submitGA(action,label){
    HistoricalPerformanceGA({ action, label});
  }
  render() {
    var analysisType =
      this.props.analysisType !== undefined ? this.props.analysisType : true;
    return (
      <div>
        {analysisType && (
          <React.Fragment>
            <hr className="mt-4 mb-4"></hr>
            <div className="row">
              <div className="col-md-8">
                <div className="font-size-14 font-bold">Basic Information</div>
                <div>
                  <div className="row">
                    <div className="col">
                      <span className="parameter-label">Promotion Name :</span>{' '}
                      {this.props.currentPromo.title}
                    </div>
                    <div className="col">
                      <span className="parameter-label">Promotion Type : </span>
                      {/* {
                        OFFER_TYPE_OPTIONS.filter(
                          type =>
                            type.value === this.props.currentPromo.promoType
                        )[0].label
                      } */}
                    </div>
                  </div>
                  <div className="row">
                    <div className="col">
                      <span className="parameter-label">Campaign : </span>
                      {!!this.props.currentPromo.campaign &&
                        this.props.currentPromo.campaign.name}
                    </div>
                    <div className="col">
                      <span className="parameter-label">
                        Promotion Period :
                      </span>
                      {this.props.currentPromo.startDate} to
                      {this.props.currentPromo.endDate}
                    </div>
                  </div>
                </div>
              </div>
              <div className="vertical-separator-container">
                <div className="vertical-separator"></div>
              </div>
              <div className="col-md-3">
                <div className="font-size-14 font-bold">
                  Parameters Information
                </div>
                <div>
                  <div>
                    <span className="parameter-label">
                      Target Promotion value :
                    </span>
                    {(this.props.currentPromo.promoType === 'A' ||
                      this.props.currentPromo.promoType === 'F') &&
                      this.prefix}
                    {String(
                      Math.ceil(this.props.currentPromo.promoValue)
                    ).toLocaleString()}
                    {(this.props.currentPromo.promoType === 'BxGy' ||
                      this.props.currentPromo.promoType === 'P') &&
                      this.postfix}
                  </div>
                </div>
              </div>
            </div>
            <div className="sku-group-container mt-4">
              <div className="font-size-14 font-bold sku-group-header">
                SKU Groups
              </div>
              <div>
                <DataTable
                  rowData={this.props.currentPromo.hierarchies}
                  isSizetoFit={true}
                  columnDefs={this.colDefs}
                  style={{ height: '100px', width: '100%', margin: 'auto' }}
                />
              </div>
            </div>

            <hr className="mt-4 mb-4"></hr>
            <div className="font-size-14 font-bold">Analysis</div>
          </React.Fragment>
        )}

        <div className={analysisType ? 'row ' : 'row custom-model-historic'}>
          <div className="col-md-12 modal-promo-analys">
            <div className="btn-group analyze-promo-btn-group">
            <button
            className={
              this.state.selectedTab === 'Sales'
                ? 'btn btn-outline-primary btnModal1 active'
                : 'btn btn-outline-primary btnModal1'
            }
            onClick={() => this.getHierarchyData('Sales')}
          >
            Sales
          </button>
              <button
                className={
                  this.state.selectedTab === 'Margin'
                    ? 'btn btn-outline-primary btnModal1 active'
                    : 'btn btn-outline-primary btnModal1'
                }
                onClick={() => this.getHierarchyData('Margin')}
              >
                Margin
              </button>
              <button
                className={
                  this.state.selectedTab === 'Units'
                    ? 'btn btn-outline-primary btnModal1 active'
                    : 'btn btn-outline-primary btnModal1'
                }
                onClick={() => this.getHierarchyData('Units')}
              >
                Units
              </button>
              {this.props.selectedTabPromoLevel == PROMO_LEVEL && (<button
                className={
                  this.state.selectedTab === 'Article'
                    ? 'btn btn-outline-primary btnModal1 active'
                    : 'btn btn-outline-primary btnModal1'
                }
                onClick={this.getArticleSummaryTab}
              >
                Article Summary
              </button>)}
            </div>
          </div>
          <div className="col-md-7" style={{ marginBottom: '18px' }}>
            {this.state.selectedTab !== "Article" && this.state.analysisData.length && (
              <div  style={{height: '100%', width: '100%'}}>
              <WaterfallChart
                selectedTab={this.state.selectedTab}
                currentPromo={this.props.currentPromo}
                analysisData={this.state.analysisData}
                getHierarchyData={this.getHierarchyData}
                containerProps={{height: '100%', width: '100%'}}
              />
              </div>
            )}
          </div>
          <div className="col-md-5 mb-10" style={{ marginBottom: '18px' }}>
          {this.state.selectedTab !== "Article" && this.state.analysisData.length && (
              
            <div className="articleSummaryTable" style={{height: '100%', width: '100%'}}>
          <div id='promoanalaysiGrid' className="ag-theme-balham" >
          <AgGridReact 
          columnDefs={this.analysisMetrics}
          rowData={this.state.analysisGridData}
          onGridReady={this.onGridReady} 
          domLayout="autoHeight"
          enableCellTextSelection={true}
           />
        </div>
            </div>
          )}
          </div>
        </div>
        {this.state.selectedTab == "Article" && this.props.selectedTabPromoLevel=== PROMO_LEVEL && (
          <div className="articleSummaryTable" style={{height: '100%', width: '100%'}}>
          <div id='promoanalaysiGrid' className="ag-theme-balham" >
          <AgGridReact 
          columnDefs={this.articleColumnDefs}
          rowData={this.props.articleData}
          onGridReady={this.onGridReady} 
          domLayout="autoHeight"
          enableCellTextSelection={true}
           />
        </div>
          {/* <DataTable
            isSizetoFit={true}
            columnDefs={this.articleColumnDefs}
            rowData={this.props.articleData}
            style={ {height: '300px', width: '100%'} }
          /> */}
        </div>
        )}
      </div>
    );
  }
}

export default PromoAnalysis;
