import { UPDATE_SELECTED_METRICS, SET_LOADER } from './types';
import { updateSelectedMetricsApi, fetchSelectedMetricsApi } from '../apis/common.api.';
import { PROMO_LEVEL } from '../constants'

const formatSelectedMetrics = (data) => {
  const updatedData = data.map(object => {
    if (object.headerName) {
      object.label = object.headerName;
    }
    if (object.field) {
      object.value = object.field;
    }
    return object
  })
  return updatedData;
}

export const updateSelectedMetrics = params => async dispatch => {
  try {
    const data = await updateSelectedMetricsApi(params)
    if (data) {
      dispatch({
        type: UPDATE_SELECTED_METRICS,
        payload: formatSelectedMetrics(data)
      });
    }
  } catch (error) {
    console.log('Error while updating selected metrics', error);
  }
};

export const fetchSelectedMetrics = params => async dispatch => {
  try {
    dispatch({
      type: SET_LOADER,
      payload: true
    });
    const data = await fetchSelectedMetricsApi()
    if (data) {
      let selectedMetrics = formatSelectedMetrics(data);
      if(params.selectedTab === PROMO_LEVEL){
          selectedMetrics = selectedMetrics.filter(obj=> ['Offer Name','Article Number','New Members Article %'].indexOf(obj.headerName)===-1);

      }else{
        if(selectedMetrics.filter(obj=> 'Article Number'=== obj.headerName).length === 0 ){
          selectedMetrics.unshift(
            {
              label: 'Article Number',
              value: 'articleNo',
              headerName: 'Article Number',
              field: 'articleNo'
            }
          )

        }
        selectedMetrics = selectedMetrics.filter(obj=> ["New Members Offers %"].indexOf(obj.headerName)===-1);
      }
      dispatch({
        type: UPDATE_SELECTED_METRICS,
        payload: selectedMetrics
      });
      dispatch({
        type: SET_LOADER,
        payload: false
      });
    }else{
      dispatch({
        type: SET_LOADER,
        payload: false
      });
    }
  } catch (error) {
    dispatch({
      type: SET_LOADER,
      payload: false
    });
    console.log('Error while updating selected metrics', error);
  }
};
