import {
  GET_SKUS,
  DATA_LOADING,
  SET_BOOK_NUMBERS,
  SET_SIMULATE_DATA,
  SET_PROMO_ID,
  RESET_PROMO_ID,
  SET_MERCHCAT,
  SET_PROMO_DATA,
  SET_PROMO_SATUS
} from './types';
import { skuDetails } from '../apis/skuManagement.api';
import {
  fetchCampaignList,
  fetchSimulateData,
  saveSimulate,
  fetchMerchCatValues,
  fetchPromo,
  downloadPromoData,
  downloadPromoTracker
} from '../apis/common.api.';

export const actionGetSku = payload => async dispatch => {
  dispatch({ type: DATA_LOADING, payload: true });

  try {
    const result = await skuDetails(payload);
    dispatch({ type: GET_SKUS, payload: result });
    dispatch({ type: DATA_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: DATA_LOADING, payload: false });
  }
};

export const getBookNumbersList = payload => async dispatch => {
  dispatch({ type: DATA_LOADING, payload: true });

  try {
    const data = await fetchCampaignList({
      ...payload,
      future: true,
    });

    if(data){
      let dataOptions = data.map(obj=> {
        obj.value = obj.name ; 
        obj.label = obj.name ; 
        return obj;
      });

      dispatch({ type: SET_BOOK_NUMBERS, payload: dataOptions });
    } else {
      dispatch({ type: SET_BOOK_NUMBERS, payload: [] });
    }
    dispatch({ type: DATA_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: DATA_LOADING, payload: false });
  }
};


export const dataSimulate = payload => async dispatch => {
  dispatch({ type: DATA_LOADING, payload: true });

  try {
    const data = await fetchSimulateData(payload);
    if (data) {
      dispatch({ type: SET_SIMULATE_DATA, payload: data });
    } else {
      dispatch({ type: SET_SIMULATE_DATA, payload: {} });
      
    }
    dispatch({ type: DATA_LOADING, payload: false });
  } catch (error) {
    
    dispatch({ type: DATA_LOADING, payload: false });
  }
};

export const savePromo = payload => async dispatch => {
  dispatch({ type: DATA_LOADING, payload: true });

  try {
    const data = await saveSimulate(payload);
    if (data) {
      dispatch({ type: SET_PROMO_ID, payload: data.promoId });
      dispatch({type:SET_PROMO_SATUS, payload: data.status});
    } else {
      dispatch({ type: SET_PROMO_ID, payload: null });
    }
    dispatch({ type: DATA_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: DATA_LOADING, payload: false });
  }
};
export const submitPromo = payload => async dispatch => {
  dispatch({ type: DATA_LOADING, payload: true });

  try {
    const data = await saveSimulate(payload);
    if (data) {
      dispatch({ type: SET_PROMO_ID, payload: data.promoId });
      dispatch({type:SET_PROMO_SATUS, payload: data.status});
    } else {
      dispatch({ type: SET_PROMO_ID, payload: null });
    }
    dispatch({ type: DATA_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: DATA_LOADING, payload: false });
  }
};
export const actionFetchPromo = payload => async dispatch => {
  dispatch({ type: DATA_LOADING, payload: true });
  try {
    const data = await fetchPromo(payload)
    dispatch({ type: DATA_LOADING, payload: false });
    if (data && data.length > 0) {
      dispatch({ type: SET_PROMO_ID, payload: data[0].promoId });
      dispatch({ type: SET_PROMO_DATA, payload: data[0] });
      dispatch({type:SET_PROMO_SATUS, payload: data[0].status});
    } else {
      
    }
  } catch (error) {
    dispatch({ type: DATA_LOADING, payload: true });
  }
}

export const resetPromoId = payload => async dispatch => {
  dispatch({ type: RESET_PROMO_ID, payload: null });
};

export const getCategoryList = payload => async dispatch => {
  try {
    dispatch({ type: DATA_LOADING, payload: true });
    let data = await fetchMerchCatValues(payload);
    if (data) {
      data = data.map(object => {
        return { value: object, label: object };
      })
      dispatch({ type: SET_MERCHCAT, payload: data });
    } else {
      dispatch({ type: SET_MERCHCAT, payload: [] });
    }
    dispatch({ type: DATA_LOADING, payload: false });
  } catch (error) {
    dispatch({ type: DATA_LOADING, payload: false });
  }
};

export const actionDownloadPromo = reqObj => async dispatch => {
  try {
    dispatch({ type: DATA_LOADING, payload: true });
    const result = await downloadPromoData(reqObj);
    window.open(result.data, "_self");
    dispatch({ type: DATA_LOADING, payload: false });
  } catch (err) {
    dispatch({ type: DATA_LOADING, payload: false });
  }
};

export const actionDownloadPromoTracker = reqObj => async dispatch => {
  try {
    dispatch({ type: DATA_LOADING, payload: true });
    const result = await downloadPromoTracker(reqObj);
    window.open(result.url, "_self");
    dispatch({ type: DATA_LOADING, payload: false });
  } catch (err) {
    dispatch({ type: DATA_LOADING, payload: false });
  }
};

export const actionResetPromoStatus = reqObj => dispatch => {
  
    dispatch({type: SET_PROMO_SATUS,payload: reqObj.status});
    dispatch({type: SET_PROMO_DATA,payload: reqObj.promoData});
    dispatch({type: SET_SIMULATE_DATA,payload: reqObj.simulationData});
}