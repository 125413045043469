import {GET_CAMPAIGNS_LIST, GET_OFFERS_LIST, LOADER ,RESET_CAMPAIGNS_LIST} from "./actionTypes";

const initialState = {
    campaignsList: [],
    loader: false,
    offersListView: []
}

export default function (state = initialState, action) {
    switch (action.type) {

        case LOADER:
            return {
                ...state,
                loader: action.payload
            };
            case GET_OFFERS_LIST : 
            
            return {
                ...state,
                offersListView: action.payload
            }
        case GET_CAMPAIGNS_LIST:
            let resData = action
                .payload
                .data
                .filter(object => {
                    object.value = `${object.name} - ${object.startDate} to ${object.endDate}`
                    object.label = `${object.name} - ${object.startDate} to ${object.endDate}`
                    return object;
                });
            return {
                ...state,
                campaignsList: resData
            }
            case RESET_CAMPAIGNS_LIST:
                
                return {
                    ...state,
                    campaignsList: []
                }

        default:
            return {
                ...state
            };
    }
}
